import React, { Component } from "react";
import Footer from "../layout/footer/Footer.component";
import Header from "../layout/header/Header.component";
import './live-stream.component.css'
import Book from '../../../assests/book.jpg';
import Book1 from '../../../assests/book1.jpg';
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { queryByPlaceholderText } from "@testing-library/react";
import io from 'socket.io-client';
import moment from "moment";
import authService from "../../../services/auth.service";
import constants from "../../../constants/constants";
import './streaming.component.css'
import videojs  from "video.js";
import 'video.js/dist/video-js.css';

// City
import '@videojs/themes/dist/city/index.css';

// Fantasy
import '@videojs/themes/dist/fantasy/index.css';

// Forest
import '@videojs/themes/dist/forest/index.css';

// Sea
import '@videojs/themes/dist/sea/index.css';


let imgPath="../../../assests/";


 

export default class Streaming extends React.Component {


        socket = io(constants.SOCKET_URL);
      messageSoket=io(constants.SOCKET_URL);

  constructor(props) {

    

    super(props);


    this.state = {
       author:'',
       authorimage:'',
       cover:'',
       title:'',
       views:'',
       description:'',
       status:'',
       twitter:'',
       youtube:'',
       website:'',
       chatminimize:false,
       likes:'',
       videoSrc:null,
        users:[],
        message:'',
        messages:[],
        imageUrl: null,
        name: "",
        firstName: "",
        lastName: "",
        accessToken: "",
        username:"",
        is_like:false,
        is_reported:false
        
    };

    this.submit.bind();
    this.handleChange = this.handleChange.bind(this);
    this.loadMyStreams = this.loadMyStreams.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.likeStream = this.likeStream.bind(this);
  

  }

  sendMessage(msg)
  {
    

    const queryParams=new URLSearchParams(window.location.search);
    const stream_id=queryParams.get("stream");
    let accessToekn = localStorage.getItem('accessToken');
    var authData = 'Token ' + accessToekn;
  var data={
    stream:stream_id,
    message:msg
  };
    axios
      .post('https://stock-trading-backend.itechnolabs.tech/stream-chat/', data,{
      headers: {
        "Content-Type": "application/json",
        Authorization: authData
      },
      
      })
      .then((response) => {
     
       
      }).catch((error) => {
      });
    }
    likeStream()
  {
    

    const queryParams=new URLSearchParams(window.location.search);
    const stream_id=queryParams.get("stream");
    let accessToekn = localStorage.getItem('accessToken');
    var authData = 'Token ' + accessToekn;
  var data={
    stream:stream_id,
  };
    axios
      .post('https://stock-trading-backend.itechnolabs.tech/stream-like/', data,{
      headers: {
        "Content-Type": "application/json",
        Authorization: authData
      },
      
      })
      .then((response) => {
          alert("Thanks for the like");
          this.setState({
            likes:this.state.likes+1,
            is_like:true
          })
       
      }).catch((error) => {
      });
    }
    reportStream(){
      if(window.confirm("Are you sure to report this stream ?"))
      {
        const queryParams=new URLSearchParams(window.location.search);
        const stream_id=queryParams.get("stream");
        let accessToekn = localStorage.getItem('accessToken');
        var authData = 'Token ' + accessToekn;
      var data={
        stream:stream_id,
      };
        axios
          .post('https://stock-trading-backend.itechnolabs.tech/stream-report/',data,{
          headers: {
            "Content-Type": "application/json",
            Authorization: authData
          },
          
          })
          .then((response) => {
              alert("Stream reported successfully");
              this.setState({
                is_reported:true
              })
           
          }).catch((error) => {
          });
      }
    }
  loadMyStreams(stream_id) {

    let accessToekn = localStorage.getItem('accessToken');
    var authData = 'Token ' + accessToekn;
  
    axios
      .get('https://stock-trading-backend.itechnolabs.tech/stream/'+stream_id, {
      headers: {
        "Content-Type": "application/json",
        Authorization: authData
      }
      })
      .then((response) => {
        console.log("stream data is ",response.data);
      this.setState(
        {  
        author:response.data.user.first_name,
        authorimage:response.data.user.image,
        cover:response.data.cover,
        title:response.data.title,
        views:response.data.views,
        description:response.data.long_description,
        twitter:response.data.twitter,
        youtube:response.data.youtube,
        website:response.data.website,
        likes:response.data.likes,
        status:response.data.status,
        is_like:response.data.is_like,
        is_reported:response.data.is_reported
       }
        );
       if(response.data.status==1)
       {
        // const video = document.querySelector("video");
        // video.src = response.data.video;
        // video.pause();

        videojs("my-video", {
          autoplay: false,
          controls: true,
          width:1000,
          height:500,
          sources: [{
            src: response.data.video,
            type: "video/mp4",
        
          }]
        });
       }
console.log(response.data.stream_chat);
        for(var i=0;i<response.data.stream_chat.length;i++ )
        {
          var objUser={
            name:response.data.stream_chat[i].user.first_name,
            id:response.data.stream_chat[i].id
          };
            var obj={
                text: response.data.stream_chat[i].message,
                date: response.data.stream_chat[i].created_at,
                user:objUser,
                image:response.data.stream_chat[i].user.image==null?'https://cdn-icons-png.flaticon.com/512/149/149071.png':response.data.stream_chat[i].user.image
            };
            this.setState({
              messages:[...this.state.messages,obj]
            })
        }
      
       
      }).catch((error) => {
      });
    }
  
  componentDidMount() {


    const queryParams=new URLSearchParams(window.location.search);
    const stream_id=queryParams.get("stream");
    if(stream_id=='' || stream_id=='undefined' ||  stream_id==null)
    {
      
      alert("Invalid stream");
      document.location.href= `${window.location.origin}/live-stream`;;
    }
    else
    {
      this.setState({stream_id:stream_id});
      this.loadMyStreams(stream_id);

    }



    let id = localStorage.getItem("userId"); 

console.log("local storage id is :"+localStorage.getItem("first_name"));

    let accessToken = localStorage.getItem("accessToken");
    this.setState({
      accessToken: accessToken,
    });
    this.getUserInfo(id,accessToken);


console.log("local storage is :"+localStorage.getItem("first_name"));

    if(this.state.accessToken !== "" &&
    this.state.accessToken !== null &&
    this.state.accessToken !== undefined)
    {
      this.setState({
        username: this.state.firstName
      });
    }
    else
    {
      
    }

 




    
    /*chat work start */
    this.messageSoket.on("connect", () => {
      console.log("userrname in streaming is "+this.state.username);

        this.messageSoket.emit("username", this.state.username);
     });
 
     this.messageSoket.on("users", users => {
       this.setState(
        {users: users}
        ); 
      
     });
 
     this.messageSoket.on("message", message => {
      this.setState(
        {messages: [...this.state.messages, message]}
        ); 
     });
 
     this.messageSoket.on("connected", user => {
     
        this.state.users=[this.users, user];
     });
 
     this.messageSoket.on("disconnected", id => {
      // this.setState(
      //   {users: this.state.users.filter(user => user.id !== id)}
      //   ); 


      
     });
    /*chat work end*/

    let peerConnection;
    const config = {
      iceServers: [
        { 
        "urls": "stun:stun.l.google.com:19302",
        },
        // { 
        //   "urls": "turn:TURN_IP?transport=tcp",
        //   "username": "TURN_USERNAME",
        //   "credential": "TURN_CREDENTIALS"
        // }
      ]
    };
    
    const socket = io.connect(constants.SOCKET_URL);
    const video = document.querySelector("video");
    const enableAudioButton = document.querySelector("#enable-audio");
    
    enableAudioButton.addEventListener("click", enableAudio)
    
    socket.on("offer", (id, description) => {
      
      peerConnection = new RTCPeerConnection(config);
      try
      {
      peerConnection
      .setRemoteDescription(description)
      .then(() => peerConnection.createAnswer())
      .then(sdp => peerConnection.setLocalDescription(sdp))
      .then(() => {
        socket.emit("answer", id, peerConnection.localDescription);
      });
      peerConnection.ontrack = event => {    
        //if(stream_id==this.state.stream_id)
      video.srcObject = event.streams[0];
      video.muted=true;
      video.play();
      this.setState({
        status:1
      })

    };
  }
  catch(e)
  {}
    
      peerConnection.onicecandidate = event => {
      if (event.candidate) {
        socket.emit("candidate", id, event.candidate);
      }
      };
    
    });
    
    
    socket.on("candidate", (id, candidate) => {
      peerConnection
      .addIceCandidate(new RTCIceCandidate(candidate))
      .catch(e => console.error(e));
    });

    
    socket.on("stopped", (id) => {
     document.location.reload();

    });
    
    socket.on("connect", () => {
      socket.emit("watcher");
    });
    
    socket.on("broadcaster", () => {
      console.log("iam in watcher broadcast");
      socket.emit("watcher");
    });
    
    window.onunload = window.onbeforeunload = () => {
      socket.close();
      peerConnection.close();
    };
    
    function enableAudio() {
      console.log("Enabling audio")
      video.muted = false;
    }
  
    }
  
  
    getUserInfo(id, accessToken) {
      authService
        .getUser(accessToken, id)
        .then((response) => {
       //  console.log('data is ' + response.data.first_name);
         
          if (
            response.data.first_name !== null &&
            response.data.first_name !== "" &&
            response.data.first_name !== undefined
          ) {
		//alert(response.data.first_name);
this.messageSoket.emit("username", response.data.first_name);
            this.setState({firstName: response.data.first_name});
            this.setState({username: response.data.first_name});
            if(response.data.image==null)
            this.setState({imageUrl:'https://cdn-icons-png.flaticon.com/512/149/149071.png'});
            else
            this.setState({imageUrl: response.data.image});
            

          }
  
          if (
            response.data.last_name !== null &&
            response.data.last_name !== "" &&
            response.data.last_name !== undefined
          ) {
            this.setState({
              lastName: response.data.last_name,
            });
          }
        })
        .catch((error) => {
          // if (
          //   error &&
          //   error.response &&
          //   error.response.data &&
          //   error.response.data.error !== undefined &&
          //   error.response.data.error !== null &&
          //   error.response.data.error !== ""
          // ) {
          //   toast.error(error.response.data.error, {
          //     autoClose: 6000,
          //   });
          // }
          // if (
          //   error.response.data.detail !== undefined &&
          //   error.response.data.detail !== null &&
          //   error.response.data.detail !== ""
          // ) {
          //   if (error.response.data.detail === "Invalid token.") {
          //     //  window.location.href = `${window.location.origin}/login`
          //     this.setState({ accessToken: null });
          //     // toast.error(error.response.data.detail + "Please login.", {
          //     //   autoClose: 6000,
          //     // })
          //   }
          // }
        });
    }
  
    copyUrl()
    {
      navigator.clipboard.writeText(window.location.href);
      alert("Share url copied successfully.");
    }


     submit(e){
      e.preventDefault();
      if(this.state.accessToken !== "" &&
      this.state.accessToken !== null &&
      this.state.accessToken !== undefined)
      {
        if(this.state.message!='')
        {
          this.messageSoket.emit("send",this.state.message,this.state.imageUrl);
          this.setState(
            {message: ''}
            ); 
            this.sendMessage(this.state.message);
        }
      }
      else
      {
        alert("Login to chat.");
        window.location.href = `${window.location.origin}/login`;
      }
     
      
    };

    handleChange(event) {
          this.setState(
            {message: event.target.value}
            ); 
         }
    setValue(e){
      console.log(e);
     this.state.message=e.target.value;
   };


   chatminimize(){
    if(this.state.chatminimize)
    {
      this.setState({chatminimize:false});
    }
    else
    {
      this.setState({chatminimize:true});
    }
   }



    render() {
        
        return (

<div class="remove_scroll">
<header>
            <div className="custom_container">
              <div className="row">
                <div className="col-md-12">
                  <Header />
                </div>
              </div>
            </div>
          </header>
          
            <section class="chat_outer">
   <div class="left_chat">
      <div class="video_iut"  className={this.state.status==0?'notStarted':'normal'}>
      {(() => {
        if (this.state.status==0) {
          return (
            <div className="notstartcard">
              <h4>Stream is not started yet, {this.state.author} will start the stream soon</h4>
            </div>
          )
        } 
      })()}
         <div class="">
        
            {/* <div class="liv_str">
               <span class="line_outer">
                  <span class="logoIcon-mex0TGFj">
                     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33 17" width="16" height="10">
                        <path fill="currentColor" d="M21.92 4.24a3.46 3.46 0 0 0-3.5-3.42 3.46 3.46 0 0 0-3.49 3.42 3.46 3.46 0 0 0 3.5 3.42 3.43 3.43 0 0 0 3.49-3.42zM0 1v6.5h6.5V17H13V1H0zm26.1 16h-7.66l6.9-16H33l-6.9 16z"></path>
                     </svg>
                  </span>
                  LIVE
                  <span class="time_ouyt">08:00:25</span>
               </span>
              
            </div> */}
         </div>

         <video id="my-video" className="video-js vjs-theme-fantasy cust78" style={{width:'100%',height:"400px !important"}} playsInline controls ></video>
    <button id="enable-audio" style={{display:'none'}}>Enable audio</button>
        
      </div>
      <div class="bottom_text67">
          <div class="top_ter">
            <h5>{this.state.title}</h5>
            <div class="metaInfo-rlEtdo08"><span>Live trading</span><span class="stats-mex0TGFj"><span class="divider-mex0TGFj">•</span><span>{this.state.views} views</span></span></div>
            <p>{this.state.title}</p>
			  <div className="custom_top">
         



    <ul className="navbar-nav ms-auto">
      <li className="nav-item custim_like">
      {(() => {
        if (this.state.is_like) {
          return (
            <a className="nav-link disabled" href="javascript:void(0)"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28"><path fill="currentColor" d="M14.75 6c0-.2.04-.29.05-.31.01 0 .08-.04.26-.04.56 0 1.1.31 1.52.87.42.55.67 1.29.67 1.98V11c0 .41.34.75.75.75h1.76c2.15 0 3.7 2.04 3.14 4.1l-1.02 3.74a2.25 2.25 0 0 1-2.17 1.66h-6.98l-1.31-.87a.75.75 0 0 0-.42-.13h-.25v-6.5H11c.24 0 .46-.11.6-.3l3-4c.1-.13.15-.29.15-.45V6zm.06-.32zm.26-1.53c-.4 0-.88.07-1.27.42-.4.37-.55.88-.55 1.43v2.75l-2.52 3.36V12c0-.97-.79-1.75-1.75-1.75H5c-.97 0-1.75.78-1.75 1.75v10c0 .97.78 1.75 1.75 1.75h3.98c.96 0 1.75-.78 1.75-1.75v-.25h.04l1.31.87c.13.09.27.13.42.13h7.21a3.75 3.75 0 0 0 3.62-2.77l1.01-3.73c.83-3.02-1.45-6-4.58-6h-1.01V8.5c0-1.02-.36-2.08-.98-2.9a3.44 3.44 0 0 0-2.7-1.45zM4.75 12c0-.14.11-.25.25-.25h3.98c.14 0 .25.11.25.25v10c0 .14-.11.25-.25.25H5a.25.25 0 0 1-.25-.25V12z"></path></svg> {this.state.likes}</a>
          )
        } 
        else
        {
          return(
<a className="nav-link " href="javascript:void(0)" onClick={this.likeStream}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28"><path fill="currentColor" d="M14.75 6c0-.2.04-.29.05-.31.01 0 .08-.04.26-.04.56 0 1.1.31 1.52.87.42.55.67 1.29.67 1.98V11c0 .41.34.75.75.75h1.76c2.15 0 3.7 2.04 3.14 4.1l-1.02 3.74a2.25 2.25 0 0 1-2.17 1.66h-6.98l-1.31-.87a.75.75 0 0 0-.42-.13h-.25v-6.5H11c.24 0 .46-.11.6-.3l3-4c.1-.13.15-.29.15-.45V6zm.06-.32zm.26-1.53c-.4 0-.88.07-1.27.42-.4.37-.55.88-.55 1.43v2.75l-2.52 3.36V12c0-.97-.79-1.75-1.75-1.75H5c-.97 0-1.75.78-1.75 1.75v10c0 .97.78 1.75 1.75 1.75h3.98c.96 0 1.75-.78 1.75-1.75v-.25h.04l1.31.87c.13.09.27.13.42.13h7.21a3.75 3.75 0 0 0 3.62-2.77l1.01-3.73c.83-3.02-1.45-6-4.58-6h-1.01V8.5c0-1.02-.36-2.08-.98-2.9a3.44 3.44 0 0 0-2.7-1.45zM4.75 12c0-.14.11-.25.25-.25h3.98c.14 0 .25.11.25.25v10c0 .14-.11.25-.25.25H5a.25.25 0 0 1-.25-.25V12z"></path></svg> {this.state.likes}</a>
          )
        }
      })()}
        
      </li>
     
      <li class="nav-item custom_tham">
        <a class="nav-link" href="javascript:void(0)" onClick={this.copyUrl}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28"><path fill="currentColor" d="M10.8 10.2a1 1 0 0 1 0-1.4l.7.7.7.7a1 1 0 0 1-1.4 0zm7.7 5.3l-.7-.7v-.01l.01-.02.07-.06.23-.23.77-.77 1.91-1.92c.38-.37.84-1.23.96-2.24.11-.97-.1-2-.96-2.84-1.8-1.8-3.85-1.23-4.58-.5l-2.42 2.41-1.11 1.12-.35.34-.09.1-.02.02h-.01l-.71-.7-.7-.7v-.02l.03-.02.09-.1.34-.34 1.12-1.11 2.41-2.42c1.48-1.48 4.8-2.11 7.42.5a5.33 5.33 0 0 1 1.53 4.49c-.16 1.4-.8 2.7-1.53 3.43l-1.92 1.91-.77.77-.23.23-.06.07-.02.01-.71-.7zm0 0l.7.7a1 1 0 0 1-1.4-1.4l.7.7zM7.8 11.8l.7.7.7.7v.01l-.01.02-.07.06-.23.23-.77.77-1.91 1.92a4.1 4.1 0 0 0-1.07 2.16c-.13.88.05 1.9 1.07 2.92.93.93 2.05 1.04 3.15.77a5.86 5.86 0 0 0 2.43-1.27l1.84-1.84.82-.82.25-.25.07-.06.02-.02.71.7.7.7v.01l-.02.02-.06.07-.25.25-.82.82-1.84 1.84A7.79 7.79 0 0 1 9.84 24a5.13 5.13 0 0 1-5.05-1.3 5.28 5.28 0 0 1-1.63-4.62c.2-1.41.95-2.6 1.63-3.29l1.92-1.91.77-.77.23-.23.06-.07.02-.01zm7.7 6.7l-.7-.7a1 1 0 0 1 1.4 1.4l-.7-.7zm-6.3-5.3l-.7-.7-.7-.7a1 1 0 0 1 1.4 1.4zm7.5-1a1 1 0 0 0-1.4-1.4l-4.5 4.5a1 1 0 1 0 1.4 1.4l4.5-4.5z"></path></svg></a>
      </li>
      
      <li class="nav-item dropdown cust_drop">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28"><path fill="currentColor" d="M9 14a3 3 0 1 1-6 0 3 3 0 0 1 6 0zm8 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0zm5 3a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"></path></svg>
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
        {(() => {
        if (this.state.is_reported) {
          return (
            <a class="dropdown-item disabled" href="javascript:void(0)" >You have reported stream</a>
          )
        } 
        else
        {
          return(
<a class="dropdown-item" href="javascript:void(0)" onClick={()=>this.reportStream()}>Report this stream</a>
          )
        }
      })()}
          
          {/* <a class="dropdown-item" href="#">Copy embed code</a> */}
         
        </div>
      </li>
      {/* <li class="nav-item btn_folwer">
        <a class="nav-link btn_nav" href="#">Follow</a>
      </li>
      <li class="nav-item btn_folwer">
        <a class="nav-link with_bg" href="#"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28"><path fill="currentColor" d="M3.75 14a10.25 10.25 0 0 1 18.24-6.42.75.75 0 0 0 1.17-.95A11.75 11.75 0 0 0 2.69 17.2a.75.75 0 1 0 1.44-.4c-.25-.89-.38-1.82-.38-2.79zm21.42-3.66a.75.75 0 1 0-1.43.47A10.24 10.24 0 0 1 14 24.25c-3.5 0-6.6-1.76-8.45-4.45a.75.75 0 0 0-1.24.85A11.74 11.74 0 0 0 25.75 14c0-1.28-.2-2.5-.58-3.66zm-12.6 1.13a.75.75 0 0 0-1.06 0l-3.93 3.92a.75.75 0 0 0 1.06 1.06l3.4-3.39 3.4 3.4c.28.29.76.29 1.05 0l3.93-3.93a.75.75 0 1 0-1.06-1.06l-3.4 3.4-3.4-3.4z"></path></svg>Cheer</a>
      </li> */}
      {/* <li class="nav-item close45">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 23" width="23" height="23"><path stroke="currentColor" stroke-width="1.2" d="M1 1l21 21m0-21L1 22"></path></svg>
      </li> */}
    </ul>

            </div>
            <hr></hr>
            <div class="listk">
                {this.state.description}

            </div>
            <ul class="soical_innrt56">
            {(() => {
        if (this.state.twitter!='') {
          return (
            <li className="twwiter_inner"><a href={this.state.twitter}><span className="icon-1f5iHRsw"><i className="fa-brands fa-twitter"></i></span> Twitter</a></li>
          )
        }
      })()}
       {(() => {
        if(this.state.youtube!='')
        {
         
          
          return (
            <li className="youtube_inner"><a href={this.state.youtube}><span className="icon-1f5iHRsw"><i className="fa-brands fa-youtube"></i></span> You Tube</a></li>
          )
        }
      })()}
        {(() => {
        if(this.state.website!='')
        {
          return (
            <li className="website_inner"><a href={this.state.website}><span className="icon-1f5iHRsw"><i className="fa fa-globe" aria-hidden="true"></i></span> Website</a></li>
          )
        } 
      })()}
   
             
              
               
            </ul>
            <div className="deciltion">
              
            
<p><b>Disclaimer</b></p>
<p>The information and publications are not meant to be, and do not constitute, financial, investment, trading, or other types of advice or recommendations supplied or endorsed by TradingView. Read more in the <a href="#">Terms of Use.</a></p>
            </div>
          </div>
        
      </div>
   </div>
      <div className="live_rightchat hide_chat" style={{display:this.state.chatminimize?"block":"none"}}>
	     <div className="live_chat">
	      <div className="liy7">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 27" width="24" height="27" fill="currentColor"><path fill-rule="evenodd" clip-rule="evenodd" d="M8 3.5A3.5 3.5 0 0 1 11.5 0h9A3.5 3.5 0 0 1 24 3.5v3a3.5 3.5 0 0 1-3.5 3.5h-.7c.13.48.2.98.2 1.5v.5h.5a3.5 3.5 0 0 1 3.5 3.5v11.2l-.85-.85L19.29 22H11.5a3.5 3.5 0 0 1-3.46-3H4.7L.85 22.85l-.85.86V11.5A5.5 5.5 0 0 1 5.5 6H8V3.5zM9.05 19a2.5 2.5 0 0 0 2.45 2h8.2l.15.15L23 24.29V15.5a2.5 2.5 0 0 0-2.5-2.5H20v.5a5.5 5.5 0 0 1-5.5 5.5H9.05zM19.5 9v.2a5.5 5.5 0 0 0-5-3.2H9V3.5A2.5 2.5 0 0 1 11.5 1h9A2.5 2.5 0 0 1 23 3.5v3A2.5 2.5 0 0 1 20.5 9h-1zm-14-2A4.5 4.5 0 0 0 1 11.5v9.8l3.15-3.15.14-.15H14.5a4.5 4.5 0 0 0 4.5-4.5v-2A4.5 4.5 0 0 0 14.5 7h-9z"></path></svg>
<span>Live Chat</span>
<svg onClick={()=>this.chatminimize()} className="custom_span" xmlns="http://www.w3.org/2000/svg" width="19" height="12" fill="none"><path stroke="currentColor" d="M1 8l8.5-6.5L18 8"></path></svg>
</div>
</div>
	  </div>
   <div className="live_rightchat"  style={{display:!this.state.chatminimize?"block":"none"}}>
          <div className="live_chat">
            <div className="liy7">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 27" width="24" height="27" fill="currentColor"><path fill-rule="evenodd" clip-rule="evenodd" d="M8 3.5A3.5 3.5 0 0 1 11.5 0h9A3.5 3.5 0 0 1 24 3.5v3a3.5 3.5 0 0 1-3.5 3.5h-.7c.13.48.2.98.2 1.5v.5h.5a3.5 3.5 0 0 1 3.5 3.5v11.2l-.85-.85L19.29 22H11.5a3.5 3.5 0 0 1-3.46-3H4.7L.85 22.85l-.85.86V11.5A5.5 5.5 0 0 1 5.5 6H8V3.5zM9.05 19a2.5 2.5 0 0 0 2.45 2h8.2l.15.15L23 24.29V15.5a2.5 2.5 0 0 0-2.5-2.5H20v.5a5.5 5.5 0 0 1-5.5 5.5H9.05zM19.5 9v.2a5.5 5.5 0 0 0-5-3.2H9V3.5A2.5 2.5 0 0 1 11.5 1h9A2.5 2.5 0 0 1 23 3.5v3A2.5 2.5 0 0 1 20.5 9h-1zm-14-2A4.5 4.5 0 0 0 1 11.5v9.8l3.15-3.15.14-.15H14.5a4.5 4.5 0 0 0 4.5-4.5v-2A4.5 4.5 0 0 0 14.5 7h-9z"></path></svg>
<span>Live Chat</span>
<div class="click_outer">
<span onClick={()=>this.chatminimize()}  class="clickableIcon-MlDA1cNZ"><svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="none"><path fill="currentColor" d="M7 20h14v1H7z"></path></svg></span>


</div>
          </div>
   </div>
   <div class="live_chy78">
   {this.state.messages.map(({user,date,text,image},index)=>(
     <div>
          <div class="inner_li8">
            <a href="#">
              
          <img src={image} style={{width:'50px'}} />
            <div class="inr672">
              <p>{user.name}<span class="bg_pre">Premium</span><span class="pre4">{moment(date).format('dd MM kk:mm')}</span></p>
            
              <p class="small_text56">{text}</p>

            </div>
          </a>
          </div>
          <hr></hr>
          </div>
          ))}
   </div>
   <div class="input_outr45">
     <form onSubmit={this.submit.bind(this)} id="form" >
     <input type="text" onChange={this.handleChange} value={this.state.message} placeholder="Have something to say?"></input>
     {/* <span class="ch-smiles-button off-chart" title="Insert emoticon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 586.70002 595.19999" width="15" height="14" enable-background="new 0 0 595.3 841.9"><path d="M293.4 0C131.8 0 0 131.8 0 297.6s131.8 297.6 293.4 297.6 293.3-131.8 293.3-297.6S454.9 0 293.4 0zm68 182.8h85v93.5h-85v-93.5zm-212.6 0h85v93.5h-85v-93.5zm148.8 306.1c-136 0-187.1-106.3-187.1-106.3h378.4s-55.2 106.3-191.3 106.3z"></path></svg></span> */}
     <button type="submit">Send</button>
     </form>
   </div>
   </div>
</section>    
</div>



        )
      }
  }
