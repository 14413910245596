import React, { Component } from "react";
import logoimage from "../../../../assests/FinMax-Financial.png";
import DP from "../../../../assests/defaultProfile.jpg";
import { Switch, Route, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "./Header.component.css";
import authService from "../../../../services/auth.service";
import constants from "../../../../constants/constants";
export default class Employeeheader extends Component {
  state = {
    imageUrl: null,
    name: "",
    firstName: "",
    lastName: "",
    accessToken: "",
    activeOn: "home",
  };
  constructor(props) {
    super(props);
    this.onLogout = this.onLogout.bind(this);
  }
  componentDidMount() {
    let id = localStorage.getItem("userId");
    let trial = localStorage.getItem("userTrial");
    let accessToken = localStorage.getItem("accessToken");
    this.setState({ accessToken: accessToken });
    this.getUserInfo(id, accessToken);
    var ret = window.location.href.replace(window.location.origin + "/", "");
    if (ret) {
      this.setState({
        activeOn: ret,
      });
    }
  }

  getUserInfo(id, accessToken) {
    authService
      .getUser(accessToken, id)
      .then((response) => {
       // console.log('data' + response);
        if (
          response.data.image !== null &&
          response.data.image !== "" &&
          response.data.image !== undefined
        ) {
          this.setState({
            imageUrl: constants.API_URL + response.data.image,
          });
        }

        if (
          response.data.first_name !== null &&
          response.data.first_name !== "" &&
          response.data.first_name !== undefined
        ) {
          this.setState({
            firstName: response.data.first_name,
          });
        }

        if (
          response.data.last_name !== null &&
          response.data.last_name !== "" &&
          response.data.last_name !== undefined
        ) {
          this.setState({
            lastName: response.data.last_name,
          });
        }
      })
      .catch((error) => {
        // if (
        //   error &&
        //   error.response &&
        //   error.response.data &&
        //   error.response.data.error !== undefined &&
        //   error.response.data.error !== null &&
        //   error.response.data.error !== ""
        // ) {
        //   toast.error(error.response.data.error, {
        //     autoClose: 6000,
        //   });
        // }
        // if (
        //   error.response.data.detail !== undefined &&
        //   error.response.data.detail !== null &&
        //   error.response.data.detail !== ""
        // ) {
        //   if (error.response.data.detail === "Invalid token.") {
        //     //  window.location.href = `${window.location.origin}/login`
        //     this.setState({ accessToken: null });
        //     // toast.error(error.response.data.detail + "Please login.", {
        //     //   autoClose: 6000,
        //     // })
        //   }
        // }
      });
  }
  onLogout(e) {
    e.preventDefault();
    let accessToken = localStorage.getItem("accessToken");
    authService
      .logout({}, accessToken)
      .then((response) => {
        console.log("res", response);
        if (response.status === 200) {
          toast.success("Logout Successfull.", {
            autoClose: 6000,
          });
          localStorage.clear();
          window.location.href = `${window.location.origin}/login`;
        }
      })
      .catch((error) => {
        if (
          error.response.data.error !== "" &&
          error.response.data.error !== undefined &&
          error.response.data.error !== null
        ) {
          toast.error(error.response.data.error, {
            autoClose: 6000,
          });
        }

        if (
          error.response.data.detail !== undefined &&
          error.response.data.detail !== null &&
          error.response.data.detail !== ""
        ) {
          if (error.response.data.detail === "Invalid token.") {
            this.setState({ accessToken: null });
            toast.error(error.response.data.detail + "Please login.", {
              autoClose: 6000,
            });
            window.location.href = `${window.location.origin}/login`;
          }
        }
      });
  }
  render() {
    let accessToken = localStorage.getItem("accessToken");
    const { activeOn } = this.state;

    return (
      <nav className="navbar navbar-expand-lg navbar-light custom_header_nav">
        <a className="navbar-brand" href="/">
          <img src={logoimage} alt="" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
          
            {this.state.accessToken !== "" &&
            this.state.accessToken !== null &&
            this.state.accessToken !== undefined ? (
              <li className="nav-item">
                <a
                  class="logout_a"
                  data-toggle="collapse"
                  href="#logout_dropdown"
                  role="button"
                  aria-expanded="false"
                  aria-controls="logout_dropdown"
                >
                  <img
                    src={
                      this.state.imageUrl !== "" && this.state.imageUrl !== null
                        ? this.state.imageUrl
                        : DP
                    }
                  />
                  <span>{this.state.firstName}</span>
                  <i class="fas fa-chevron-down"></i>
                </a>
                <div class="collapse" id="logout_dropdown">
                  <ul>
                    <li>
                      <Link to={"/editprofile"}>Profile</Link>
                    </li>
                    <li>
                      <Link to={"/employee-home"}>Chat</Link>
                    </li>
                    <li>
                      <a type="submit" onClick={this.onLogout}>
                        Logout
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
            ) : (
              ""
            )}
          </ul>
          {this.state.accessToken !== "" &&
          this.state.accessToken !== null &&
          this.state.accessToken !== undefined ? (
            ""
          ) : (
            <form className="form-inline my-2 my-lg-0">
              <Link to={"/login"}>
                <button
                  className="btn btn-outline-success login_btn"
                  type="submit"
                >
                  Login
                </button>
              </Link>
            </form>
          )}
        </div>
      </nav>
    );
  }
}
