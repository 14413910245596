import React, { Component } from "react";
import Footer from "../layout/footer/Footer.component";
import Header from "../layout/header/Header.component";
import './Chatsingle.component.css'
import DP from '../../../assests/dp.jpg'
import analyticsImg from '../../../assests/advanced-analytics.jpg';
import axios from "axios";
import { Link } from "react-router-dom";
import io from "socket.io-client"; 
import {FormGroup, ControlLabel, FormControl , InputGroup} from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import constants from "../../../constants/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
var globalSocket;
const socketUrl = constants.SOCKET_URL;
const nodeUrl = constants.NODE_URL;
//const mainUrl = constants.SOCKET_URL;

let imgPath="../../../assests/"

export default class Chatsupportpage extends Component {

  constructor(props) {
    super(props)
    this.state = {
        transcode: '',
        users:'',
        chat:'',
        messageText: "",
        conversation: [],
        otheruserImg: DP,
        myImg: localStorage.getItem("image"),
        actualId:'',
        otherId:'',
        previousChats:[],
        previousChat:[],
        oth:'',
        supportImg:'http://stock-trading-backend.itechnolabs.tech/static/images/blank-profile-picture-973460_1280.png',
        employeeEmpty: false
    }
  }

  componentDidMount() {

      if(localStorage.getItem("image") == ''){
          this.setState({
              myImg : 'http://stock-trading-backend.itechnolabs.tech/static/images/blank-profile-picture-973460_1280.png'
          })
      }

      let id = localStorage.getItem("userId");
      let accessToekn = localStorage.getItem('accessToken');
      
      let foodlist = '';
      var authData = 'Token '+accessToekn;
      var chatts = '';

      axios
      .get(`https://stock-trading-backend.itechnolabs.tech/employee-users/`,
            {headers: {
               "Content-Type": "application/json",
               Authorization: authData
            }},
        )
      .then((response) => {
          console.log(response.data.employee_user);

        if(Object.keys(response.data.employee_user).length != 0  ){

          this.setState({
                    oth : response.data.employee_user.id
                          }, () => {
                   console.log(this.state.oth)
                 })

        }else{

            this.setState({
              employeeEmpty:true
            }, () => {
                     console.log(this.state.oth)
                   })

}
          
      })
      .catch((error) => {
          console.log("error", error);
      });


setTimeout(function() {

 this.setState({ actualId: localStorage.getItem("userId") });

      axios
            .get(nodeUrl+'get-previous-convo',
               {"params":{
                  userid1: localStorage.getItem("userId"),
                  userid2: this.state.oth
              }},
                {
                    headers: { "Content-Type": "application/json" }
                },
              )
            .then((response) => {
                this.setState({ previousChats: response.data })
            })
            .catch((error) => {
                console.log("error", error);
            });

}.bind(this), 5000);


      this.setState({
          transcode: '1'
      })
      this.initSocket()
  }

  onSendClicked  = (e) => { 

      if(!this.state.messageText){
          return;
      }
     
      this.setState({ messageText: "" });
  } 
  initSocket = async () => {
        let socket = io.connect(socketUrl)
        globalSocket=socket;
        this.setState({ socket })
        socket.on('connect', (data) => {

            var ids = localStorage.getItem("userId")

            socket.emit('updatesocket', { id: ids }, function(dataFromServer) {
                console.log(dataFromServer);
            });

            socket.on('update-chat', (data) => { // create_debate fn will be in backend \\
                
                let person = {
                  from_user_id: data.from_user_id , 
                  message: data.message , 
                  name : data.name, 
                  to_user_id: data.to_user_id
                };
                var convo = this.state.previousChats
                convo.push(person);
                this.setState({ previousChats: convo });
            })

        }) 


        socket.on('getChat',(data) => {

            let person = {
                    from_user_id: data.from_user_id , 
                    message: data.message , 
                    name : data.name, 
                    to_user_id: data.to_user_id,
                    img : data.img
                };

                var convo = this.state.previousChats;

                convo.push(person);
                
                this.setState({ 
                    previousChats: convo 
                });

        });

    }
  onMessageInputChange = (e) => {
    
      if (e.key === "Enter" || e._reactName=="onClick" )
       {
        if(this.state.messageText=="")
        {
          alert("Please enter message to send.");
          return;
        }

          globalSocket.emit('update-chat', { message: this.state.messageText, from_user_id: localStorage.getItem("userId") , to_user_id : this.state.oth  }, function(dataFromServer) {
              console.log(dataFromServer);
            });

          let person = {
              from_user_id: localStorage.getItem("userId") , 
              message: this.state.messageText , 
              name : localStorage.getItem("name"), 
              to_user_id: this.state.oth 
          };

          var convo = this.state.previousChats
          convo.push(person);
          this.setState({ previousChats: convo });
          this.setState({ messageText: "" });
          e.target.value="";
          
          var items = document.getElementsByClassName('getAnswer');
          for (var i = 0; i < items.length; i++) {
              items[i].addEventListener('click', this.handleQuestionsClick);
          }

      }
  }

handleChange(e)
{
  this.setState({ messageText: e.target.value });
  
}
    
    render() {
        var message_second = 'message_second'; 

        var msgClass1 = 'message_first';
        var msgClass2 = 'message_first message_second';
        const listItems = this.state.previousChats.map((item, index) => (
          
  <div key={index}  class= { ( parseInt(item.from_user_id) == parseInt(this.state.actualId)) ? 'message_first message_second' :  'message_first' }>

  <div className="message_first_left ">
                        <ul>
                          <li><span>{item.message} </span></li>
                        </ul>
                      </div>
                      { (item.from_user_id == this.state.actualId) ? <img src={this.state.myImg} /> :  <img src={DP} /> }
                    
            </div>
  
)) 
        return (
            <div>
          <header>
            <div className="custom_container">
              <div className="row">
                <div className="col-md-12">
                  <Header />
                </div>
              </div>
            </div>
          </header>
           <section className="single_chat_outer">
            <div className="custom_container">
              <div className="row chat_row">
                
                <div className="col-md-12 ">
                  <div className="single_chat_right">
                    <div className="single_chat_upper">
                      <div className="signle_chat_title">Chat with Support</div>
                    </div>
                    <div className="message_right">
                  <div className="message_upper">

                   

                   { (this.state.employeeEmpty) ? 

                    "Currently we are offline" :  

                    listItems

                  }
                    
                  </div>
                  
                </div>

                <div className="message_bottom">
                    <div className="text_message_outer">
                      <input type="text" placeholder="Typing..." name="" value={this.state.messageText} onChange={(e)=>this.handleChange(e)}  onKeyDown={this.onMessageInputChange}/>
                      <div className="text_message_right">
                        <ul>
                          <li><a href="#"><i className="fa fa-paperclip"></i></a></li>
                          <li><a href="javascript:void(0)" id="btnSendMsg" onClick={(e)=>this.onMessageInputChange(e)}><i className="fa fa-paper-plane send_i"></i></a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

         
      
          <footer>
            <Footer />       
          </footer>
            </div>
        )
    }
}
