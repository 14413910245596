import React, {Component} from "react";
import Footer from "../layout/footer/Footer.component";
import Header from "../layout/header/Header.component";
import './Chart.component.css';
import { Link } from "react-router-dom";

export default class ChartPage extends Component{
    render(){
        return (
            <div>
              <header>
                <div className="custom_container">
                  <div className="row">
                    <div className="col-md-12">
                        <Header />
                    </div>
                  </div>
                </div>
              </header>

      <div className="wrapper">
        <nav id="sidebar">
            <ul className="list-unstyled components">
                <li className="active">
                    <a href="#" data-target="#bar" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle"><i class="fa-solid fa-bars"></i></a>
                    <ul class="collapse sidec_custom card-ul" id="bar">
                               <li>
                                   <a href="#">
                                     <div className="snap_left">
                                     Save layout
                                     </div>
                                     <span className="ctrl_span">Ctrl + S</span>
                                   </a>
                               </li>
                               <li>
                                   <a href="#" data-toggle="modal" data-target="#exportModal">
                                     <div className="snap_left">
                                     Export chart data
                                     </div>
                                   </a>
                               </li>
                               <li className="search_li">
                                   <a href="#" data-toggle="modal" data-target="#searchModal">
                                     <div className="snap_left">
                                     <div className="symbol_left_bar">
                                            <i class="fa-solid fa-magnifying-glass"></i>
                                            <input type="text" placeholder="Quick Search" />
                                    </div>
                                     </div>
                                     <span className="ctrl_span">Ctrl + K</span>
                                   </a>
                               </li>
                               <li>
                                   <a href="#">
                                     <div className="snap_left">
                                     Help center
                                     </div>
                                   </a>
                               </li>
                               <li>
                                   <a href="#" data-toggle="modal" data-target="#newModal">
                                     <div className="snap_left">
                                     What's new
                                     </div>
                                   </a>
                               </li>
                               <li>
                                   <a href="#">
                                     <div className="snap_left">
                                     Home
                                     </div>
                                   </a>
                               </li>
                               <li>
                                   <a href="#" data-target="#more" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle">
                                     <div className="snap_left">
                                     More
                                     </div>
                                   </a>
                                   <ul class="collapse sidec_custom side-custom2 card-ul" id="more">
                                        <li>
                                            <a href="#">
                                                <div className="snap_left">
                                                Ideas
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <div className="snap_left">
                                                Markets
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <div className="snap_left">
                                                 Scripts
                                                </div>
                                            </a>
                                        </li>
                                        </ul>
                               </li>
                               <li>
                                   <a href="#">
                                     <div className="snap_left">
                                     Dark color theme
                                     </div>
                                     <span className="ctrl_span">
                                     <label class="switch">
                                        <input type="checkbox" class="switch-input" />
                                        <span class="switch-label" data-on="On" data-off="Off"></span>
                                        <span class="switch-handle"></span>
                                     </label>
                                     </span>
                                   </a>
                               </li>
                               <li>
                                   <a href="#">
                                     <div className="snap_left">
                                     Drawings panel
                                     </div>
                                     <span className="ctrl_span">
                                     <label class="switch">
                                        <input type="checkbox" class="switch-input" />
                                        <span class="switch-label" data-on="On" data-off="Off"></span>
                                        <span class="switch-handle"></span>
                                     </label>
                                     </span>
                                   </a>
                               </li>
                               <li>
                                   <a href="#" data-target="#language" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle">
                                     <div className="snap_left">
                                     Language
                                     </div>
                                     <span className="ctrl_span">English</span>
                                   </a>
                                   <ul class="collapse sidec_custom side-custom2 card-ul" id="language">
                                        <li>
                                            <a href="#">
                                                <div className="snap_left">
                                                English
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <div className="snap_left">
                                                English
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <div className="snap_left">
                                                 English
                                                </div>
                                            </a>
                                        </li>
                                        </ul>
                               </li>
                               <li>
                                   <a href="#">
                                     <div className="snap_left">
                                     Keyboard shortcuts
                                     </div>
                                   </a>
                               </li>
                               <li>
                                   <a href="#">
                                     <div className="snap_left">
                                     Get desktop app
                                     </div>
                                   </a>
                               </li>
                               <li>
                                   <a href="#">
                                     <div className="snap_left">
                                     Sign in
                                     </div>
                                   </a>
                               </li>
                               <li>
                                   <a href="#">
                                     <div className="snap_left">
                                     Join now
                                     </div>
                                   </a>
                               </li>
                            </ul>
                </li>
                <li>
                    <a href="#" data-target="#dot" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle"><i class="fa-solid fa-circle"></i></a>
                    <div id="dot" class="collapse sidec_custom card-ul side_ul_card">
                            <ul class="card-ul">
                               <li>
                                   <a href="#"><i class="fa-solid fa-crosshairs icon_c"></i> Cross <i class="far fa-star"></i></a>
                               </li>
                               <li>
                                   <a href="#"><i class="fa-solid fa-circle icon_c"></i>Dot <i class="far fa-star"></i></a>
                               </li>
                               <li>
                                   <a href="#"><i class="fa-solid fa-arrow-pointer icon_c"></i>Arrow <i class="far fa-star"></i></a>
                               </li>
                               <li>
                                   <a href="#"><i class="fa-solid fa-eraser icon_c"></i>Eraser <i class="far fa-star"></i></a>
                               </li>
                            </ul>
                        </div>
                </li>
                <li>
                    <a href="#" data-target="#homeSubmenu" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle"><i class="fa-solid fa-indent"></i></a>
                    {/* <a href="#" data-toggle="collapse" data-target="#homeSubmenu"><i class="fa-solid fa-indent"></i></a> */}
                    <div className="collapse sidec_custom card-ul side_ul_card" id="homeSubmenu">
                        <ul class="card-ul">
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Trend Line <div className="li_rightcollapse"><span className="ctrl_span">Alt + T</span><i class="far fa-star"></i></div></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Arrow <i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Ray <i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Info Line <i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Expended Line <i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Trend Angle<i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Horizontal Line <div className="li_rightcollapse"><span className="ctrl_span">Alt + H</span><i class="far fa-star"></i></div></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Horizontal Ray <div className="li_rightcollapse"><span className="ctrl_span">Alt + J</span><i class="far fa-star"></i></div></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Vertical Line <div className="li_rightcollapse"><span className="ctrl_span">Alt + V</span><i class="far fa-star"></i></div></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Cross Line <div className="li_rightcollapse"><span className="ctrl_span">Alt + C</span><i class="far fa-star"></i></div></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Parallel Channel <i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Regression Trend <i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Flat Top/Bottom <i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Disjoint Channel <i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Anchored VWAP <i class="far fa-star"></i></a>
                            </li>
                        </ul>
                    </div>
                </li>
                <li>
                    <a href="#" data-target="#slider" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle"><i class="fa-solid fa-sliders"></i></a>
                    <div className="collapse sidec_custom card-ul side_ul_card" id="slider">
                        <ul class="card-ul">
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Fib Retracement <div className="li_rightcollapse"><span className="ctrl_span">Alt + F</span><i class="far fa-star"></i></div></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Trend-Based Fib Extension <i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Pitchfork <i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Schiff Pitchfork <i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Modified Schiff Pitchfork <i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Inside Pitchfork<i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Fib Channel <i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Fib Time Zone <i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Gann Box <i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Gann Square Fixed <i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Gann Square <i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Gann Fan <i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Fib Speed Resistance Fan <i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Trend-Based Fib Time <i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Fib Circles <i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Pitchfan <i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Fib Spiral <i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Fib Speed Resistance Arcs <i class="far fa-star"></i></a>
                            </li>
                        </ul>
                    </div>
                </li>
                <li>
                    <a href="#" data-target="#brush" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle"><i class="fa-solid fa-paintbrush"></i></a>
                    <div className="collapse sidec_custom card-ul side_ul_card" id="brush">
                        <ul class="card-ul">
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Brush <i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Highlighter <i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Rectangle <i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Ellipse <i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Path <i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Curve<i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Polyline <i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Triangle <i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Rotated Rectangle <i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Arc <i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Double Curve <i class="far fa-star"></i></a>
                            </li>
                        </ul>
                    </div>
                </li>
                <li>
                    <a href="#" data-target="#text" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle"><i class="fa-solid fa-t"></i></a>
                    <div className="collapse sidec_custom card-ul side_ul_card" id="text">
                        <ul class="card-ul">
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Text <i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Anchored Text <i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Note <i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Anchored Note <i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Signpost <i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Tweet<i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Idea <i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Callout <i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Ballon <i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Price Label <i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Price Note <i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Arrow Marker <i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Arrow Mark Left <i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Arrow Mark Right <i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Arrow Mark Up <i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Arrow Mark Down <i class="far fa-star"></i></a>
                            </li>
                        </ul>
                    </div>
                </li>
                <li>
                    <a href="#" data-target="#pattern" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle"><i class="fa-solid fa-sliders"></i></a>
                    <div className="collapse sidec_custom card-ul side_ul_card" id="pattern">
                        <ul class="card-ul">
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>XABCD Pattern <i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Cypher Pattern <i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>ABCD Pattern <i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Triangle Pattern <i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Three Drives Pattern <i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Head and Shoulders<i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Elliot Impulse Wave(12345) <i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Elliot Triangle Wave(ABCDE) <i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Elliot Triple Combo Wave(WXYXZ)<i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Elliot Correction Wave(ABC) <i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Elliot Double Combo Wave(WXY) <i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Cyclic Line <i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Time Cycles <i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Sine Line <i class="far fa-star"></i></a>
                            </li>
                        </ul>
                    </div>
                </li>
                <li>
                    <a href="#" data-target="#position" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle"><i class="fa-solid fa-sliders"></i></a>
                    <div className="collapse sidec_custom card-ul side_ul_card" id="position">
                        <ul class="card-ul">
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Long Position <i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Short Position <i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Forecast <i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Date Range <i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Price Range <i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Date and Price Range<i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Bars Pattern<i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Ghost Feed <i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Projection<i class="far fa-star"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Fixed Range Volume Profile <i class="far fa-star"></i></a>
                            </li>
                        </ul>
                    </div>
                </li>
                <li>
                    <a href="#" data-target="#emo" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle"><i class="fa-solid fa-face-smile-beam"></i></a>
                    <div className="collapse sidec_custom card-ul side_ul_card" id="emo">
                        <ul class="emo_ul">
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i></a>
                            </li>

                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i></a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i></a>
                            </li>
                        </ul>
                    </div>
                </li>
                <li>
                    <a href="#"><i class="fa-solid fa-ruler"></i></a>
                </li>
                <li>
                    <a href="#"><i class="fa-solid fa-magnifying-glass-plus"></i></a>
                </li>
                <li>
                    <a href="#" data-target="#magnet" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle"><i class="fa-solid fa-inbox"></i></a>
                    <div className="collapse sidec_custom card-ul side_ul_card" id="magnet">
                        <ul class="card-ul">
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Weak Magnet</a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Strong Magnet</a>
                            </li>
                        </ul>
                    </div>
                </li>
                <li>
                    <a href="#"><i class="fa-solid fa-face-smile-beam"></i></a>
                </li>
                <li>
                    <a href="#"><i class="fa-solid fa-pencil"></i></a>
                </li>
                <li>
                    <a href="#"><i class="fa-solid fa-lock-open"></i></a>
                </li>
                <li>
                    <a href="#" data-target="#draw" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle"><i class="fa-solid fa-eye"></i></a>
                    <div className="collapse sidec_custom card-ul side_ul_card" id="draw">
                        <ul class="card-ul">
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Hide Drawings</a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Hide Indicators</a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Hide Positions & Orders</a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Hide All</a>
                            </li>
                        </ul>
                    </div>
                </li>
                <li>
                    <a href="#" data-target="#remove" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle"><i class="fa-solid fa-trash-can"></i></a>
                    <div className="collapse sidec_custom card-ul side_ul_card" id="remove">
                        <ul class="card-ul">
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Remove Drawings</a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Remove Indicators</a>
                            </li>
                            <li>
                                <a href="#"><i class="fa-solid fa-arrow-trend-up icon_c"></i>Remove Drawings & Indicators</a>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
        </nav>
        <div id="content">
            <div className="sidebar_btn">
                <button type="button" id="sidebarCollapse" className="btn btn-info">
                    <i className="fas fa-align-left"></i>
                </button>
            </div>
            <div className="top_bar_main">
            <div className="top_bar">
              <ul>
                  <li data-toggle="tooltip" title="Symbol Search" data-custom-class="text-sm">
                      <a href="#" data-toggle="modal" data-target="#DJIAModal">DJIA-H1</a>
                  </li>
                  <li data-toggle="tooltip" title="5 Minutes">
                      <a href="#" data-toggle="collapse" data-target="#demo">5m</a>
                      <div id="demo" class="collapse collapse_custom">
                      <div className="accordian_custom">
<div class="accordion" id="faq">
                    <div class="card">
                        <div class="card-header" id="faqhead1">
                            <a href="#" class="btn btn-header-link" data-toggle="collapse" data-target="#faq1"
                            aria-expanded="true" aria-controls="faq1">SECONDS</a>
                        </div>

                        <div id="faq1" class="collapse show" aria-labelledby="faqhead1" data-parent="#faq">
                            <ul class="card-ul">
                               <li>
                                   <a href="#">1 Second <i class="far fa-star"></i></a>
                               </li>
                               <li>
                                   <a href="#">5 Second <i class="far fa-star"></i></a>
                               </li>
                               <li>
                                   <a href="#">15 Second <i class="far fa-star"></i></a>
                               </li>
                               <li>
                                   <a href="#">30 Second <i class="far fa-star"></i></a>
                               </li>
                            </ul>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" id="faqhead2">
                            <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#faq2"
                            aria-expanded="true" aria-controls="faq2">MINUTES</a>
                        </div>

                        <div id="faq2" class="collapse" aria-labelledby="faqhead2" data-parent="#faq">
                            <ul class="card-ul">
                               <li>
                                   <a href="#">1 Minute <i class="far fa-star"></i></a>
                               </li>
                               <li>
                                   <a href="#">3 Minute <i class="far fa-star"></i></a>
                               </li>
                               <li>
                                   <a href="#">5 Minute <i class="far fa-star"></i></a>
                               </li>
                               <li>
                                   <a href="#">15 Minute <i class="far fa-star"></i></a>
                               </li>
                               <li>
                                   <a href="#">30 Minute <i class="far fa-star"></i></a>
                               </li>
                               <li>
                                   <a href="#">45 Minute <i class="far fa-star"></i></a>
                               </li>
                            </ul>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" id="faqhead4">
                            <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#faq3"
                            aria-expanded="true" aria-controls="faq3">HOURS</a>
                        </div>

                        <div id="faq3" class="collapse" aria-labelledby="faqhead3" data-parent="#faq">
                            <ul class="card-ul">
                               <li>
                                   <a href="#">1 Hour <i class="far fa-star"></i></a>
                               </li>
                               <li>
                                   <a href="#">2 Hours <i class="far fa-star"></i></a>
                               </li>
                               <li>
                                   <a href="#">3 Hours <i class="far fa-star"></i></a>
                               </li>
                               <li>
                                   <a href="#">4 Hours <i class="far fa-star"></i></a>
                               </li>
                               <li>
                                   <a href="#">30 Minute <i class="far fa-star"></i></a>
                               </li>
                               <li>
                                   <a href="#">45 Minute <i class="far fa-star"></i></a>
                               </li>
                            </ul>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" id="faqhead3">
                            <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#faq4"
                            aria-expanded="true" aria-controls="faq4">DAYS</a>
                        </div>

                        <div id="faq4" class="collapse" aria-labelledby="faqhead4" data-parent="#faq">
                            <ul class="card-ul">
                               <li>
                                   <a href="#">1 Day <i class="far fa-star"></i></a>
                               </li>
                               <li>
                                   <a href="#">1 Week <i class="far fa-star"></i></a>
                               </li>
                               <li>
                                   <a href="#">1 Month <i class="far fa-star"></i></a>
                               </li>
                            </ul>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" id="faqhead5">
                            <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#faq5"
                            aria-expanded="true" aria-controls="faq5">RANGES</a>
                        </div>

                        <div id="faq5" class="collapse" aria-labelledby="faqhead5" data-parent="#faq">
                            <ul class="card-ul">
                               <li>
                                   <a href="#">1 Ranges <i class="far fa-star"></i></a>
                               </li>
                               <li>
                                   <a href="#">10 Ranges <i class="far fa-star"></i></a>
                               </li>
                               <li>
                                   <a href="#">100 Ranges <i class="far fa-star"></i></a>
                               </li>
                               <li>
                                   <a href="#">1000 Ranges <i class="far fa-star"></i></a>
                               </li>
                            </ul>
                        </div>
                    </div>
                </div>
  </div>
                     </div>
                  </li>
                  <li data-toggle="tooltip" title="Candles">
                      <a href="#" data-toggle="collapse" data-target="#line"><i class="fa-solid fa-grip-lines-vertical"></i></a>
                      <div id="line" class="collapse collapse_custom">
                            <ul class="card-ul cartsGraph">
                               <li>
                                   <a href="#">Bars <i class="far fa-star"></i></a>
                               </li>
                               <li>
                                   <a href="#">Candles <i class="far fa-star"></i></a>
                               </li>
                               <li>
                                   <a href="#">Hollow Candles <i class="far fa-star"></i></a>
                               </li>
                               <li>
                                   <a href="#">Line <i class="far fa-star"></i></a>
                               </li>
                               <li>
                                   <a href="#">Area <i class="far fa-star"></i></a>
                               </li>
                               <li>
                                   <a href="#">Baseline <i class="far fa-star"></i></a>
                               </li>
                               <li>
                                   <a href="#">Heikin Ashi <i class="far fa-star"></i></a>
                               </li>
                               <li>
                                   <a href="#">Renko <i class="far fa-star"></i></a>
                               </li>
                               <li>
                                   <a href="#">Line Break <i class="far fa-star"></i></a>
                               </li>
                               <li>
                                   <a href="#">Kagi <i class="far fa-star"></i></a>
                               </li>
                               <li>
                                   <a href="#">Point & Figure <i class="far fa-star"></i></a>
                               </li>
                               <li>
                                   <a href="#">Range <i class="far fa-star"></i></a>
                               </li>
                            </ul>
                        </div>
                  </li>
                  <li data-toggle="tooltip" title="Compare or Add Symbol">
                      <a href="#" data-toggle="modal" data-target="#compareModal"><i class="fa-solid fa-circle-plus"></i>Compare</a>
                  </li>
                  <li data-toggle="tooltip" title="Indicators, Mytrices & Stratigies">
                      <a href="#" data-toggle="modal" data-target="#indicatorModal"><i class="fa-solid fa-chart-line"></i>Indicators</a>
                  </li>
                  <li data-toggle="tooltip" title="Indicator Templates">
                      <a href="#" data-toggle="collapse" data-target="#template"><i class="fa-solid fa-table-cells-large"></i>Templates</a>
                      <div id="template" class="collapse collapse_custom template_collapse">
                            <div className="save_indicator">
                             <i class="fa-solid fa-cloud-arrow-up"></i>Save indicator template...
                            </div>
                            <div className="default-template">DEFAULT TEMPLATEs</div>
                            <ul class="card-ul">
                               <li>
                                   <a href="#">
                                       <div className="default_left_t">
                                         Bill William's 3 Lines
                                         <p>Volume, Moving Average * 3</p>
                                       </div> 
                                       <i class="far fa-star"></i>
                                    </a>
                               </li>
                               <li>
                                   <a href="#">
                                       <div className="default_left_t">
                                         Displaced EMA
                                         <p>Volume, Moving Average Exponentional</p>
                                       </div> 
                                       <i class="far fa-star"></i>
                                    </a>
                               </li>
                               <li>
                                   <a href="#">
                                       <div className="default_left_t">
                                         MA Exp Ribbon
                                         <p>Volume, Moving Average Exponentional * 8</p>
                                       </div> 
                                       <i class="far fa-star"></i>
                                    </a>
                               </li>
                               <li>
                                   <a href="#">
                                       <div className="default_left_t">
                                         Oscillators
                                         <p>Volume, Stochastic RSI, Relative Strength Index, Commodity Channel Index</p>
                                       </div> 
                                       <i class="far fa-star"></i>
                                    </a>
                               </li>
                               <li>
                                   <a href="#">
                                       <div className="default_left_t">
                                         Swing Trading
                                         <p>Zig Zag, Pivots HL, Vol</p>
                                       </div> 
                                       <i class="far fa-star"></i>
                                    </a>
                               </li>
                               <li>
                                   <a href="#">
                                       <div className="default_left_t">
                                         Volume Based
                                         <p>Volume, Commodity Channel Index, On Balance Volume, Rate Of Change, Chaikin Money Flow</p>
                                       </div> 
                                       <i class="far fa-star"></i>
                                    </a>
                               </li>
                            </ul>
                        </div>
                  </li>
                  <li data-toggle="tooltip" title="Create Alert">
                      <a href="#"><i class="fa-solid fa-alarm-plus"></i>Alert</a>
                  </li>
                  <li data-toggle="tooltip" title="Bar Replay">
                      <a href="#"><i class="fa-solid fa-angles-left"></i>Replay</a>
                  </li>
                  {/* <li data-toggle="tooltip" title="Bar Replay">
                      <a href="#"><i class="fa-solid fa-arrow-turn-down-left"></i><i class="fa-solid fa-arrow-turn-down-right"></i></a>
                  </li> */}
              </ul>

              <ul className="topbar_right_ul">
                  <li data-toggle="tooltip" title="Select Layout">
                      <a href="" data-toggle="collapse" data-target="#layout"><i class="far fa-square"></i></a>
                      <div id="layout" class="collapse collapse_custom template_collapse">
                            <ul class="card-ul">
                               <li>
                                   <a href="#">
                                       <span>1</span>
                                       <div className="sync_icons">
                                         <i class="far fa-square"></i>
                                       </div>
                                    </a>
                               </li>
                               <li>
                                   <a href="#">
                                       <span>2</span>
                                       <div className="sync_icons">
                                         <i class="far fa-square"></i>
                                         <i class="far fa-square"></i>
                                       </div>
                                    </a>
                               </li>
                               <li>
                                   <a href="#">
                                       <span>3</span>
                                       <div className="sync_icons">
                                         <i class="far fa-square"></i>
                                         <i class="far fa-square"></i>
                                         <i class="far fa-square"></i>
                                         <i class="far fa-square"></i>
                                         <i class="far fa-square"></i>
                                         <i class="far fa-square"></i>
                                       </div>
                                    </a>
                               </li>
                               <li>
                                   <a href="#">
                                       <span>4</span>
                                       <div className="sync_icons">
                                         <i class="far fa-square"></i>
                                         <i class="far fa-square"></i>
                                         <i class="far fa-square"></i>
                                         <i class="far fa-square"></i>
                                         <i class="far fa-square"></i>
                                         <i class="far fa-square"></i>
                                       </div>
                                    </a>
                               </li>
                               <li>
                                   <a href="#">
                                       <span>5</span>
                                       <div className="sync_icons">
                                         <i class="far fa-square"></i>
                                         <i class="far fa-square"></i>
                                       </div>
                                    </a>
                               </li>
                               <li>
                                   <a href="#">
                                       <span>6</span>
                                       <div className="sync_icons">
                                         <i class="far fa-square"></i>
                                         <i class="far fa-square"></i>
                                       </div>
                                    </a>
                               </li>
                            </ul>
                            <div className="save_indicator sync">
                             SYNC IN LAYOUT
                            </div>
                            <div className="sync_l">
                                <div className="sync_left">
                                    <span>Symbol</span>
                                    <i class="fa-solid fa-circle-info"></i>
                                </div>
                                <div className="sync_left">
                                    <span>Interval</span>
                                    <i class="fa-solid fa-circle-info"></i>
                                </div>
                            </div>
                            <div className="sync_l">
                                <div className="sync_left">
                                    <span>Crosshair</span>
                                    <i class="fa-solid fa-circle-info"></i>
                                </div>
                                <div className="sync_left">
                                    <span>Time</span>
                                    <i class="fa-solid fa-circle-info"></i>
                                </div>
                            </div>
                            <div className="sync_l">
                                <div className="sync_left">
                                    <span>Drawings</span>
                                    <i class="fa-solid fa-circle-info"></i>
                                </div>
                            </div>
                        </div>
                  </li>
                  <li data-toggle="tooltip" title="Save all charts">
                      <a href=""><i class="fa-solid fa-cloud-arrow-up"></i> Save</a>
                  </li>
                  <li data-toggle="tooltip" title="Chart Properties">
                      <a href="" data-toggle="modal" data-target="#settingModal"><i class="fa-solid fa-gear"></i></a>
                  </li>
                  <li data-toggle="tooltip" title="Fullscreen Mode">
                      <a href=""><i class="fa-solid fa-maximize"></i></a>
                  </li>
                  <li data-toggle="tooltip" title="Take a snapshot">
                      <a href="" data-toggle="collapse" data-target="#snap"><i class="fa-solid fa-camera"></i></a>
                      <div id="snap" class="collapse collapse_custom template_collapse">
                            <ul class="card-ul">
                               <li>
                                   <a href="#">
                                     <div className="snap_left">
                                     <i class="fa-solid fa-download"></i> 
                                     Save chart image
                                     </div>
                                     <span className="ctrl_span">Ctrl + alt + S</span>
                                   </a>
                               </li>
                               <li>
                                   <a href="#">
                                     <div className="snap_left">
                                     <i class="fa-solid fa-clone"></i>
                                     Copy chart image
                                     </div>
                                     <span className="ctrl_span">Ctrl + shift + S</span>
                                   </a>
                               </li>
                               <li>
                                   <a href="#">
                                     <div className="snap_left">
                                     <i class="fa-solid fa-link"></i> 
                                     Copy link to the chart image
                                     </div>
                                     <span className="ctrl_span">Alt + S</span>
                                   </a>
                               </li>
                               <li>
                                   <a href="#">
                                     <div className="snap_left">
                                     <i class="fa-solid fa-arrow-up-right-from-square"></i> 
                                     Open image in new tab
                                     </div>
                                   </a>
                               </li>
                               <li>
                                   <a href="#">
                                     <div className="snap_left">
                                     <i class="fa-brands fa-twitter tweet"></i>
                                     Tweet chart image
                                     </div>
                                   </a>
                               </li>
                            </ul>
                        </div>
                  </li>
                  <li>
                      <a className="start_free" href="">Start Free Trial</a>
                  </li>
              </ul>
            </div>
            </div>
        </div>
    </div>


<div className="modal modal_customs fade" id="DJIAModal" tabindex="-1" aria-labelledby="DJIAModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="DJIAModalLabel">Symbol Search</h5>
        <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
        <div className="symbol_search_bar">
            <div className="symbol_left_bar">
                <i class="fa-solid fa-magnifying-glass"></i>
                <input type="text" placeholder="Search" />
            </div>
            <div className="symbol_right_bar">
                <ul>
                    <li><a href="#"><i class="fas fa-divide"></i></a></li>
                    <li><a href="#"><i class="fas fa-plus"></i></a></li>
                    <li><a href="#"><i class="fas fa-minus"></i></a></li>
                    <li><a href="#"><i class="fas fa-xmark"></i></a></li>
                </ul>
            </div>
        </div>
        <div className="symbol_tabs">
        <ul class="nav nav-tabs cuastom_tabs" role="tablist">
            <li class="nav-item">
                <a class="nav-link active" data-toggle="tab" href="#tabs-1" role="tab">All</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#tabs-2" role="tab">Stock</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#tabs-3" role="tab">Futures</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#tabs-4" role="tab">Forex</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#tabs-5" role="tab">Crypto</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#tabs-6" role="tab">Index</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#tabs-7" role="tab">Bond</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#tabs-8" role="tab">Economy</a>
            </li>
        </ul>
        <div class="tab-content">
            <div class="tab-pane active" id="tabs-1" role="tabpanel">
                <p>First Panel</p>
            </div>
            <div class="tab-pane" id="tabs-2" role="tabpanel">
                <p>Second Panel</p>
            </div>
            <div class="tab-pane" id="tabs-3" role="tabpanel">
                <p>Third Panel</p>
            </div>
            <div class="tab-pane" id="tabs-4" role="tabpanel">
                <p>Fourth Panel</p>
            </div>
            <div class="tab-pane" id="tabs-5" role="tabpanel">
                <p>Fifth Panel</p>
            </div>
            <div class="tab-pane" id="tabs-6" role="tabpanel">
                <p>Sixth Panel</p>
            </div>
            <div class="tab-pane" id="tabs-7" role="tabpanel">
                <p>Seventh Panel</p>
            </div>
            <div class="tab-pane" id="tabs-8" role="tabpanel">
                <p>Eighth Panel</p>
            </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</div> 

<div className="modal modal_customs fade" id="indicatorModal" tabindex="-1" aria-labelledby="indicatorModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="DJIAModalLabel">Indicators, Metrics & Strategies</h5>
        <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
        <div className="symbol_search_bar">
            <div className="symbol_left_bar full_search">
            <i class="fa-solid fa-magnifying-glass"></i>
                <input type="text" placeholder="Search" />
            </div>
        </div>
        <div className="symbol_tabs indicator_tabs">
        <div className="indicator_left">
            <ul class="nav nav-tabs cuastom_tabs indicator_tab_custom" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active" data-toggle="tab" href="#i1" role="tab"><i class="fa-solid fa-user-large"></i>My Scripts</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#i2" role="tab"><i class="fa-solid fa-chart-area"></i>Technicals</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#i3" role="tab"><i class="fa-solid fa-chart-column"></i>Financials</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#i4" role="tab"><i class="fa-solid fa-note-sticky"></i>Community Scripts</a>
                </li>
            </ul>
        </div>
        <div className="tab-content indicator_right">
            <div class="tab-pane active" id="i1" role="tabpanel">
                <div className="myscripts_outer">
                  <i class="fa-solid fa-chart-line"></i>
                  <div className="script_title">Pine Script</div>
                  <p>Welcome to the TradingView custom scripting engine. It allows you to create your own custom studies or modify existing. 
                      You do not have any scripts created.</p>
                  <a className="script_btn" href="#">Create your first script now</a>
                </div>
            </div>
            <div class="tab-pane" id="i2" role="tabpanel">
            <ul class="nav nav-tabs cuastom_tabs" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active" data-toggle="tab" href="#tabs-11" role="tab">Indicators</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#tabs-21" role="tab">Strategies</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#tabs-31" role="tab">Volume profile</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#tabs-41" role="tab">Chart patterns</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#tabs-51" role="tab">Candlestick patterns</a>
                </li>
            </ul>
            <div class="tab-content">
                <div class="tab-pane active" id="tabs-11" role="tabpanel">
                    <p>First Panel</p>
                </div>
                <div class="tab-pane" id="tabs-21" role="tabpanel">
                    <p>Second Panel</p>
                </div>
                <div class="tab-pane" id="tabs-31" role="tabpanel">
                    <p>Third Panel</p>
                </div>
                <div class="tab-pane" id="tabs-41" role="tabpanel">
                    <p>Fourth Panel</p>
                </div>
                <div class="tab-pane" id="tabs-51" role="tabpanel">
                    <p>Fifth Panel</p>
                </div>
            </div>
            </div>
            <div class="tab-pane" id="i3" role="tabpanel">
                <ul class="nav nav-tabs cuastom_tabs" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" data-toggle="tab" href="#tabs-111" role="tab">Income statement</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#tabs-211" role="tab">Balance sheet</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#tabs-311" role="tab">Cash flow</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#tabs-411" role="tab">Statistics</a>
                    </li>
                </ul>
                <div class="tab-content">
                    <div class="tab-pane active" id="tabs-111" role="tabpanel">
                        <p>First Panel</p>
                    </div>
                    <div class="tab-pane" id="tabs-211" role="tabpanel">
                        <p>Second Panel</p>
                    </div>
                    <div class="tab-pane" id="tabs-311" role="tabpanel">
                        <p>Third Panel</p>
                    </div>
                    <div class="tab-pane" id="tabs-411" role="tabpanel">
                        <p>Fourth Panel</p>
                    </div>
                </div>
            </div>
            <div class="tab-pane" id="i4" role="tabpanel">
                <ul class="nav nav-tabs cuastom_tabs" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" data-toggle="tab" href="#tabs-1111" role="tab">Editors picks</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#tabs-2111" role="tab">Top</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#tabs-3111" role="tab">Trending</a>
                    </li>
                </ul>
                <div class="tab-content">
                    <div class="tab-pane active" id="tabs-1111" role="tabpanel">
                        <p>First Panel</p>
                    </div>
                    <div class="tab-pane" id="tabs-2111" role="tabpanel">
                        <p>Second Panel</p>
                    </div>
                    <div class="tab-pane" id="tabs-3111" role="tabpanel">
                        <p>Third Panel</p>
                    </div>
                </div>
            </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</div> 

<div className="modal modal_customs fade" id="settingModal" tabindex="-1" aria-labelledby="settingModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="DJIAModalLabel">Chart Setting</h5>
        <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
        <div className="symbol_search_bar">
            <div className="symbol_left_bar full_search">
            <i class="fa-solid fa-magnifying-glass"></i>
                <input type="text" placeholder="Search" />
            </div>
        </div>
        <div className="symbol_tabs indicator_tabs">
        <div className="indicator_left">
            <ul class="nav nav-tabs cuastom_tabs indicator_tab_custom" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active" data-toggle="tab" href="#i11" role="tab"><i class="fa-solid fa-user-large"></i>Symbol</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#i21" role="tab"><i class="fa-solid fa-chart-area"></i>Status Line</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#i31" role="tab"><i class="fa-solid fa-chart-column"></i>Scales</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#i41" role="tab"><i class="fa-solid fa-note-sticky"></i>Appearance</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#i51" role="tab"><i class="fa-solid fa-note-sticky"></i>Trading</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#i61" role="tab"><i class="fa-solid fa-note-sticky"></i>Events</a>
                </li>
            </ul>
        </div>
        <div className="tab-content indicator_right">
            <div class="tab-pane active" id="i11" role="tabpanel">
                <div className="myscripts_outer">
                  <i class="fa-solid fa-chart-line"></i>
                  <div className="script_title">Pine Script</div>
                  <p>Welcome to the TradingView custom scripting engine. It allows you to create your own custom studies or modify existing. 
                      You do not have any scripts created.</p>
                  <a className="script_btn" href="#">Create your first script now</a>
                </div>
            </div>
            <div class="tab-pane" id="i21" role="tabpanel">
            <ul class="nav nav-tabs cuastom_tabs" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active" data-toggle="tab" href="#tabs-11" role="tab">Indicators</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#tabs-21" role="tab">Strategies</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#tabs-31" role="tab">Volume profile</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#tabs-41" role="tab">Chart patterns</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#tabs-51" role="tab">Candlestick patterns</a>
                </li>
            </ul>
            <div class="tab-content">
                <div class="tab-pane active" id="tabs-11" role="tabpanel">
                    <p>First Panel</p>
                </div>
                <div class="tab-pane" id="tabs-21" role="tabpanel">
                    <p>Second Panel</p>
                </div>
                <div class="tab-pane" id="tabs-31" role="tabpanel">
                    <p>Third Panel</p>
                </div>
                <div class="tab-pane" id="tabs-41" role="tabpanel">
                    <p>Fourth Panel</p>
                </div>
                <div class="tab-pane" id="tabs-51" role="tabpanel">
                    <p>Fifth Panel</p>
                </div>
            </div>
            </div>
            <div class="tab-pane" id="i31" role="tabpanel">
                <ul class="nav nav-tabs cuastom_tabs" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" data-toggle="tab" href="#tabs-111" role="tab">Income statement</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#tabs-211" role="tab">Balance sheet</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#tabs-311" role="tab">Cash flow</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#tabs-411" role="tab">Statistics</a>
                    </li>
                </ul>
                <div class="tab-content">
                    <div class="tab-pane active" id="tabs-111" role="tabpanel">
                        <p>First Panel</p>
                    </div>
                    <div class="tab-pane" id="tabs-211" role="tabpanel">
                        <p>Second Panel</p>
                    </div>
                    <div class="tab-pane" id="tabs-311" role="tabpanel">
                        <p>Third Panel</p>
                    </div>
                    <div class="tab-pane" id="tabs-411" role="tabpanel">
                        <p>Fourth Panel</p>
                    </div>
                </div>
            </div>
            <div class="tab-pane" id="i41" role="tabpanel">
                <ul class="nav nav-tabs cuastom_tabs" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" data-toggle="tab" href="#tabs-1111" role="tab">Editors picks</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#tabs-2111" role="tab">Top</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#tabs-3111" role="tab">Trending</a>
                    </li>
                </ul>
                <div class="tab-content">
                    <div class="tab-pane active" id="tabs-1111" role="tabpanel">
                        <p>First Panel</p>
                    </div>
                    <div class="tab-pane" id="tabs-2111" role="tabpanel">
                        <p>Second Panel</p>
                    </div>
                    <div class="tab-pane" id="tabs-3111" role="tabpanel">
                        <p>Third Panel</p>
                    </div>
                </div>
            </div>
            <div class="tab-pane" id="i51" role="tabpanel">
                <div className="myscripts_outer">
                  <i class="fa-solid fa-chart-line"></i>
                  <div className="script_title">Pine Script</div>
                  <p>Welcome to the TradingView custom scripting engine. It allows you to create your own custom studies or modify existing. 
                      You do not have any scripts created.</p>
                  <a className="script_btn" href="#">Create your first script now</a>
                </div>
            </div>
            <div class="tab-pane" id="i61" role="tabpanel">
                <div className="myscripts_outer">
                  <i class="fa-solid fa-chart-line"></i>
                  <div className="script_title">Pine Script</div>
                  <p>Welcome to the TradingView custom scripting engine. It allows you to create your own custom studies or modify existing. 
                      You do not have any scripts created.</p>
                  <a className="script_btn" href="#">Create your first script now</a>
                </div>
            </div>
        </div>
        </div>
      </div>
      <div class="modal-footer modal_footer_custom">
        <select className="template_select">
            <option>Template</option>
        </select>
        <div className="btns_outer_footer">
            <button type="button" class="cancel_btn">Cancel</button>
            <button type="button" class="okbtn">Ok</button>
        </div>
      </div>
    </div>
  </div>
</div> 

<div className="modal modal_customs fade" id="compareModal" tabindex="-1" aria-labelledby="compareModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="DJIAModalLabel">Compare Symbol</h5>
        <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
        <div className="symbol_search_bar">
            <div className="symbol_left_bar full_search">
            <i class="fa-solid fa-magnifying-glass"></i>
                <input type="text" placeholder="Search" />
            </div>
        </div>
        <div className="symbol_tabs">

        </div>
      </div>
    </div>
  </div>
</div>

<div className="modal modal_customs export_modal fade" id="searchModal" tabindex="-1" aria-labelledby="searchModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="searchModalLabel">Search tool or function</h5>
        <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
        <div className="symbol_search_bar">
            <div className="symbol_left_bar full_search">
            <i class="fa-solid fa-magnifying-glass"></i>
                <input type="text" placeholder="Search" />
            </div>
        </div>
        <div className="export_chart_inner">
          <p className="search_p">
Type to search for drawings, functions and settings</p>
        </div>
      </div>
    </div>
  </div>
</div>

<div className="modal modal_customs export_modal fade" id="newModal" tabindex="-1" aria-labelledby="newModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="newModalLabel">What's new</h5>
        <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
        <div className="export_chart_inner newwhats_inner">
          <div className="newwhatdiv">
            <div className="newwhatdivleft">
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer 
                    took a galley of type and scrambled it to make a type specimen book.</p>
            </div>
            <div className="newwhatdivright">
                Feb 3
            </div>
          </div>
          <div className="newwhatdiv">
            <div className="newwhatdivleft">
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer 
                    took a galley of type and scrambled it to make a type specimen book.</p>
            </div>
            <div className="newwhatdivright">
                Feb 3
            </div>
          </div>
          <div className="newwhatdiv">
            <div className="newwhatdivleft">
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer 
                    took a galley of type and scrambled it to make a type specimen book.</p>
            </div>
            <div className="newwhatdivright">
                Feb 3
            </div>
          </div>
          <div className="newwhatdiv">
            <div className="newwhatdivleft">
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer 
                    took a galley of type and scrambled it to make a type specimen book.</p>
            </div>
            <div className="newwhatdivright">
                Feb 3
            </div>
          </div>
          <div className="newwhatdiv">
            <div className="newwhatdivleft">
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer 
                    took a galley of type and scrambled it to make a type specimen book.</p>
            </div>
            <div className="newwhatdivright">
                Feb 3
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div className="modal modal_customs export_modal fade" id="exportModal" tabindex="-1" aria-labelledby="exportModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="DJIAModalLabel">Export chart data</h5>
        <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
        <div className="export_chart_inner">
          <p>All information from the selected chart, including the symbol & indicators will be saved to a CSV file.</p>
          <div className="chart_e_div">
              <span>Chart</span>
              <select className="template_select">
                  <option>MYX_DLY:DJIA-H1, ID</option>
              </select>
          </div>
          <div className="chart_e_div">
              <span>Time format (UTC)</span>
              <select className="template_select">
                  <option>UNIX timestamp</option>
              </select>
          </div>
        </div>
      </div>
      <div class="modal-footer modal_footer_custom">
        <div className="btns_outer_footer">
            <button type="button" class="cancel_btn">Cancel</button>
            <button type="button" class="okbtn">Export</button>
        </div>
      </div>
    </div>
  </div>
</div>
              <footer>
                <Footer />       
              </footer>
            </div>
        )
    }
}