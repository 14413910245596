import React, { Component } from "react";
import Footer from "../layout/footer/Footer.component";
import Header from "../layout/header/Header.component";
import './Expertadvisor.component.css'
import Buy from '../../../assests/buy.png';

import { Link } from "react-router-dom";
import axios from "axios";
import constants from "../../../constants/constants";
import moment from "moment";

let imgPath="../../../assests/"
export default class RecievedOrders extends Component {

    constructor(props)
    {
        super(props);
        this.state={
            orders:[]
        }
            this.getOrders();        
    }
    
    getOrders()
	{
	  let accessToekn = localStorage.getItem('accessToken');
	  var authData = 'Token ' + accessToekn;
	  axios.get(constants.API_URL+"/advisor-transaction/?advisor_author="+localStorage.getItem('userId'),{
		headers:{
		  "Content-Type": "application/json",
		  Authorization: authData
		}
	  })
	  .then((response)=>{
		if(response.status==200 )
		{
		  this.setState({
			orders:response.data.data
		  });
		}
  
	  }).catch((error)=>{
		console.log("error", error);
	  });
	}

    render() {

        return ( 
            <div>
        <header>
            <div className="custom_container">
            <div className="row">
                <div className="col-md-12">
                <Header />
                </div>
            </div>
            </div>
        </header>
<section className="expert_ad">
    <div className="custom_container">
        <div className="row">
            <div className="col-12 text-left">
                <div className="img_outer">
                <h2>My Orders Recieved</h2>
                <table className="table">
                    <tr>
                        <th>SrNo</th>
                        <th>Title</th>
                        <th>Purchase Date</th>
                        <th>Payment Id</th>
                        <th>Payment Mode</th>
                        <th>Amount</th>
                        <th>Status</th>
                        <th>Document</th>
                        <th>Document Username</th>
                        <th>Document Password</th>
                        <th>Action</th>
                    </tr>
                    {this.state.orders.map((obj,i)=>(
                        <tr>
                            <td>{++i}</td>
                            <td><a href={"expert-advisor-result/"+obj.advisor.id}>{obj.advisor.title}</a></td>
                            <td>{moment(obj.created_at).format("dd/mm/yyyy")}</td>
                            <td>{obj.payment_id}</td>
                            <td>{obj.mode}</td>
                            <td>{obj.amount}</td>
                            <td>{obj.status}</td>
                            <td>{obj.file?(
                                <a href={obj.file} target={'_blank'}>Download<i class="fa fa-download"></i></a>
                            ):'Not uploaded yet'}</td>
                            <td>{obj.file_username?obj.file_username:'Not uploaded yet'}</td>
                            <td>{obj.file_password?obj.file_password:'Not uploaded yet'}</td>
                            <td><a href={"/expert-advisor-upload/"+obj.id}>Upload File<i class="fa fa-upload"></i></a></td>
                        </tr>
                    ))}
                   
                </table>
                </div>
            </div>
            
            </div>
        </div>

</section>

        
    
        <footer>
            <Footer />       
        </footer>
            </div>
        )
    }
}
