import { React, useEffect } from "react";
import Footer from "../layout/footer/Footer.component";
import Header from "../layout/header/Header.component";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function EconomicCalendar() {
  return (
    <div>
      <header>
        <div className="custom_container">
          <div className="row">
            <div className="col-md-12">
              <Header />
            </div>
          </div>
        </div>
      </header>

      <section className="banner_outer news_banner">
        <div className="overlay"></div>
        <div className="custom_container">
          <div className="row">
            <div className="col-md-8 offset-md-2 col-sm-12 first_div text-center">
              <div className="banner_content">
                <h2>Economic Calendar</h2>
                <hr className="banner_hr news_hr" />
                <h3>
                  <ul className="news_ul">
                    <li>
                      <Link to={"/home"}>Home</Link>
                    </li>
                    <li>
                      <i className="fal fa-angle-right"></i>
                    </li>
                    <li>
                      <Link to={"/economic"} className="active">
                        Economic Calendar
                      </Link>
                    </li>
                  </ul>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="middle_ou78">
        <div className="custom_container">
   <div className="econmic_calnder">
              <p className="p_text newS_p">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has<br /> been the industry's standard dummy text ever since the 1500s, when an unknown.
              </p>
     </div>    
   </div>
      </section>
	
      <section className="most_polupar">
        <div className="custom_container">
          <div className="row">
            <div className="col-md-12">
              <div id="economicCalendarWidget"></div>
            </div>
          </div>
        </div>
      </section>

      <footer>
        <Footer />
      </footer>
    </div>
  );
}
