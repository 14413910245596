import React, { Component } from "react";
import Footer from "../layout/footer/Footer.component";
import Header from "../layout/header/Header.component";
import moment from "moment";
import "./Chart.component.css";
import { Link, NavLink } from "react-router-dom";
import CandleChart from "./CandleChart";
import authService from "../../../services/auth.service";
import { connect } from "react-redux";
// import RenkoChart from "./RenkoChart";
import AreaChart from "./AreaChart";
import LineChart from "./LineChart";
import Highcharts from "highcharts/highstock";

import DefaultProfile from "../../../assests/defaultProfile.jpg";
import Select from 'react-select';
import CompareList from "./CompareList";
import CompareChart from "./CompareChart";
import Bar from "./BarChart";
import SymbolList from "./SymbolList";
import TimeSelect from "./TimeSelect";
import DateRangPick from "./DateRangePick";
import Moment from "moment";
import * as htmlToImage from "html-to-image";
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from "html-to-image";
import { copyImageToClipboard } from "copy-image-clipboard";
import axios from "axios";
import { createAlertNotify } from "../../../services/actions/actions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RenkoChart from './RenkoChart';
import { getData } from "./utils"
import { TypeChooser } from "react-stockcharts/lib/helper";
import { SketchPicker, AlphaPicker } from 'react-color'
import reactCSS from 'reactcss'
import $ from 'jquery'; 
import { CONSTANT } from "../../../services/constants"; 
import constants from "../../../constants/constants";
$(document).ready(function() {
  $("#sidebar").on({
    mouseenter: function () {
        $('.show_hide_on_mouse').slideDown();
    },
    mouseleave: function () {
      setTimeout(function(){
        $('.show_hide_on_mouse').slideUp(); 
      },5000);
    }
  });

  // $("#show_hide_menu").on('mouseover',function(){
  //   alert('1')
  //   $('.show_hide_on_mouse').show();
  // },function(){ alert('2')
  //     $('.show_hide_on_mouse').hide();
  // });

  $(document).on("click", function(event){
    var $trigger = $(".collapse");
    if($trigger !== event.target && !$trigger.has(event.target).length){
        $(".collapse").removeClass("show");
    }            
  });
 
  $("#searchTheKey").on('keyup', function(){
      var value = $(this).val().toLowerCase();
      $("#matchKey li").each(function () {
          if($(this).text().toLowerCase().search(value) > -1){
              $(this).show();
              //$(this).prev('.subjectName').last().show();
          }else{
              $(this).hide();
          }
      });
  })

});
class ChartPage extends Component {
     
    constructor(props) {
        super(props);
        var arr2 = localStorage.getItem("favChart");
        arr2 = arr2 ? arr2.split(",") : [];

        var arr3 = localStorage.getItem("favTemplates");
        arr3 = arr3 ? arr3.split(",") : [];

        this.state = {
          callout:false,
          balloon:false,
          arrowLeft:false,
          arrowMark:false,
          flagMark:false,
          arrowDown:false,
          public_chat_message:"",
          arrowUp:false,
          list_notifications:[],
          arrowRight:false,
          crossHair:false,
          textCallout:false, 
          symbol_key_stats:{},
          trendLine:false,
          ip: "",
          isActive: arr2,
          templatesActive: arr3,
          list_watchlist:[],
          users_list:[],
          list_watchlist_items:[],
          public_chats:[],
          symbol_performance:[],
          symbol_ask_and_bids:{},
          symbol_profile:{},
          symbol_news:[],
          eonomic_calendar:[],
          earning_calendar:[],
          operations: [],
      redoOperations: {},
          my_ideas:[],
          watchlist_id:0,
          alerts:[],
          list_us_stocks:[],
          userEmail:"",
          myStreamsList:[],
      list_forex_stock:[],
      list_crypto_stock:[],
      idea_title:"",
      idea_description:"",
      idea_link:"",
      idea_tags:"",
      idea_categories:[],
      selected_watchlist:{},
      showTool:3,
      statement_type:"quarter",
          graphType: "candlestick",
          gridShow: 1,
          modalShow: false,
          newSymbol: ["A"],
          slctSymbol: "A",
          symbolName: "Agilent Technologies Inc.",
          stock: "stock",
          multiplier: "1", 
          timespan: "day",
          startD: Moment(new Date().setDate(new Date().getDate() - 120)).format(
            "YYYY-MM-DD"
          ),
          errors: {},
          compareChart: false,
          endD: Moment(new Date()).format("YYYY-MM-DD"),
          cond_name: "",
          cond_check: "",
          cond_value: "0",
          status_line_symbol:true,
          ohlc_values: 'true',
          market_status: 'true',
          vol_values: 'true',
          sym_name_label: 'true',
          sym_last_price_label: 'true',
          bar_change_val: 'true',
          sym_highlowlast_label: 'true',
          pre_post_mkt_price: 'true',
          askBid: 'true',
          addPlusAlert: 'true',
          newSeriesIndicator: {},
          lock_price_to_bar_ratio: 'true',
          lock_price_to_bar_ratio_val: 0,
          countdownBar: 'true',
          lo_overlapping_label: 'true',
          date_format_val: "%e %b, '%y",
          scale_placement: 'right',
          symbol_variation: "0",
          option: "",
          condTime: 0,
          condDate: "",
          action_method: [],
          alert_name: "",
          alert_msg: "",
          myLayoutList: [],
          web_hook_url: "",
          set_webhook: false,
          markerPoints: '',
          displayColorPicker: false,
          count: 1,
          color: {
            r: '241',
            g: '112',
            b: '19',
            a: '1',
          },
          up_color: false,
          up_color_color:'#96DA95',
          show_up_color: {
            r: '241',
            g: '112',
            b: '19',
            a: '1',
          },
          alpha_color: {
            r: '230',
            g: '230',
            b: '230',
            a: '1',
          },
          down_color: false,
          down_color_color: '#FF8B8B',
          show_down_color: {
              r: '241',
              g: '112',
              b: '19',
              a: '1',
          },
          last_price: false,
          last_price_color: "#000",
          show_last_price: {
              r: '241',
              g: '112',
              b: '19',
              a: '1',
          },
          showHighLowerLine: false,
          line_color_color: "blue",
          get_indicator_data: 0,
          indicators:[], 
          indicator_setting_name:'',
          line_width:'',
          indicator_line_color:'#00FF00',
          get_random_number:0,
          range_limit:1000,
          operations: [],
          redoOperations: {},
          accessToken:"",
          icons: [
            {name:'fas fa-ad',code:'\uf641'},
{name:'fas fa-address-book',code:'\uf2b9'},
{name:'fas fa-address-card',code:'\uf2bb'},
{name:'fas fa-adjust',code:'\uf042'},
{name:'fas fa-air-freshener',code:'\uf5d0'},
{name:'fas fa-align-center',code:'\uf037'},
{name:'fas fa-align-justify',code:'\uf039'},
{name:'fas fa-align-left',code:'\uf036'},
{name:'fas fa-align-right',code:'\uf038'},
{name:'fas fa-allergies',code:'\uf461'},
{name:'fas fa-ambulance',code:'\uf0f9'},
{name:'fas fa-american-sign-language-interpreting',code:'\uf2a3'},
{name:'fas fa-anchor',code:'\uf13d'},
{name:'fas fa-angle-double-down',code:'\uf103'},
{name:'fas fa-angle-double-left',code:'\uf100'},
{name:'fas fa-angle-double-right',code:'\uf101'},
{name:'fas fa-angle-double-up',code:'\uf102'},
{name:'fas fa-angle-down',code:'\uf107'},
{name:'fas fa-angle-left',code:'\uf104'},
{name:'fas fa-angle-right',code:'\uf105'},
{name:'fas fa-angle-up',code:'\uf106'},
{name:'fas fa-angry',code:'\uf556'},
{name:'fas fa-ankh',code:'\uf644'},
{name:'fas fa-apple-alt',code:'\uf5d1'},
{name:'fas fa-archive',code:'\uf187'},
{name:'fas fa-archway',code:'\uf557'},
{name:'fas fa-arrow-alt-circle-down',code:'\uf358'},
{name:'fas fa-arrow-alt-circle-left',code:'\uf359'},
{name:'fas fa-arrow-alt-circle-right',code:'\uf35a'},
{name:'fas fa-arrow-alt-circle-up',code:'\uf35b'},
{name:'fas fa-arrow-circle-down',code:'\uf0ab'},
{name:'fas fa-arrow-circle-left',code:'\uf0a8'},
{name:'fas fa-arrow-circle-right',code:'\uf0a9'},
{name:'fas fa-arrow-circle-up',code:'\uf0aa'},
{name:'fas fa-arrow-down',code:'\uf063'},
{name:'fas fa-arrow-left',code:'\uf060'},
{name:'fas fa-arrow-right',code:'\uf061'},
{name:'fas fa-arrow-up',code:'\uf062'},
{name:'fas fa-arrows-alt',code:'\uf0b2'},
{name:'fas fa-arrows-alt-h',code:'\uf337'},
{name:'fas fa-arrows-alt-v',code:'\uf338'},
{name:'fas fa-assistive-listening-systems',code:'\uf2a2'},
{name:'fas fa-asterisk',code:'\uf069'},
{name:'fas fa-at',code:'\uf1fa'},
{name:'fas fa-atlas',code:'\uf558'},
{name:'fas fa-atom',code:'\uf5d2'},
{name:'fas fa-audio-description',code:'\uf29e'},
{name:'fas fa-award',code:'\uf559'},
{name:'fas fa-baby',code:'\uf77c'},
{name:'fas fa-baby-carriage',code:'\uf77d'},
{name:'fas fa-backspace',code:'\uf55a'},
{name:'fas fa-backward',code:'\uf04a'},
{name:'fas fa-bacon',code:'\uf7e5'},
{name:'fas fa-bacteria',code:'\uf959'},
{name:'fas fa-bacterium',code:'\uf95a'},
{name:'fas fa-bahai',code:'\uf666'},
{name:'fas fa-balance-scale',code:'\uf24e'},
{name:'fas fa-balance-scale-left',code:'\uf515'},
{name:'fas fa-balance-scale-right',code:'\uf516'},
{name:'fas fa-ban',code:'\uf05e'},
{name:'fas fa-band-aid',code:'\uf462'},
{name:'fas fa-barcode',code:'\uf02a'},
{name:'fas fa-bars',code:'\uf0c9'},
{name:'fas fa-baseball-ball',code:'\uf433'},
{name:'fas fa-basketball-ball',code:'\uf434'},
{name:'fas fa-bath',code:'\uf2cd'},
{name:'fas fa-battery-empty',code:'\uf244'},
{name:'fas fa-battery-full',code:'\uf240'},
{name:'fas fa-battery-half',code:'\uf242'},
{name:'fas fa-battery-quarter',code:'\uf243'},
{name:'fas fa-battery-three-quarters',code:'\uf241'},
{name:'fas fa-bed',code:'\uf236'},
{name:'fas fa-beer',code:'\uf0fc'},
{name:'fas fa-bell',code:'\uf0f3'},
{name:'fas fa-bell-slash',code:'\uf1f6'},
{name:'fas fa-bezier-curve',code:'\uf55b'},
{name:'fas fa-bible',code:'\uf647'},
{name:'fas fa-bicycle',code:'\uf206'},
{name:'fas fa-biking',code:'\uf84a'},
{name:'fas fa-binoculars',code:'\uf1e5'},
{name:'fas fa-biohazard',code:'\uf780'},
{name:'fas fa-birthday-cake',code:'\uf1fd'},
{name:'fas fa-blender',code:'\uf517'},
{name:'fas fa-blender-phone',code:'\uf6b6'},
{name:'fas fa-blind',code:'\uf29d'},
{name:'fas fa-blog',code:'\uf781'},
{name:'fas fa-bold',code:'\uf032'},
{name:'fas fa-bolt',code:'\uf0e7'},
{name:'fas fa-bomb',code:'\uf1e2'},
{name:'fas fa-bone',code:'\uf5d7'},
{name:'fas fa-bong',code:'\uf55c'},
{name:'fas fa-book',code:'\uf02d'},
{name:'fas fa-book-dead',code:'\uf6b7'},
{name:'fas fa-book-medical',code:'\uf7e6'},
{name:'fas fa-book-open',code:'\uf518'},
{name:'fas fa-book-reader',code:'\uf5da'},
{name:'fas fa-bookmark',code:'\uf02e'},
{name:'fas fa-border-all',code:'\uf84c'},
{name:'fas fa-border-none',code:'\uf850'},
{name:'fas fa-border-style',code:'\uf853'},
{name:'fas fa-bowling-ball',code:'\uf436'},
{name:'fas fa-box',code:'\uf466'},
{name:'fas fa-box-open',code:'\uf49e'},
{name:'fas fa-box-tissue',code:'\uf95b'},
{name:'fas fa-boxes',code:'\uf468'},
{name:'fas fa-braille',code:'\uf2a1'},
{name:'fas fa-brain',code:'\uf5dc'},
{name:'fas fa-bread-slice',code:'\uf7ec'},
{name:'fas fa-briefcase',code:'\uf0b1'},
{name:'fas fa-briefcase-medical',code:'\uf469'},
{name:'fas fa-broadcast-tower',code:'\uf519'},
{name:'fas fa-broom',code:'\uf51a'},
{name:'fas fa-brush',code:'\uf55d'},
{name:'fas fa-bug',code:'\uf188'},
{name:'fas fa-building',code:'\uf1ad'},
{name:'fas fa-bullhorn',code:'\uf0a1'},
{name:'fas fa-bullseye',code:'\uf140'},
{name:'fas fa-burn',code:'\uf46a'},
{name:'fas fa-bus',code:'\uf207'},
{name:'fas fa-bus-alt',code:'\uf55e'},
{name:'fas fa-business-time',code:'\uf64a'},
{name:'fas fa-calculator',code:'\uf1ec'},
{name:'fas fa-calendar',code:'\uf133'},
{name:'fas fa-calendar-alt',code:'\uf073'},
{name:'fas fa-calendar-check',code:'\uf274'},
{name:'fas fa-calendar-day',code:'\uf783'},
{name:'fas fa-calendar-minus',code:'\uf272'},
{name:'fas fa-calendar-plus',code:'\uf271'},
{name:'fas fa-calendar-times',code:'\uf273'},
{name:'fas fa-calendar-week',code:'\uf784'},
{name:'fas fa-camera',code:'\uf030'},
{name:'fas fa-camera-retro',code:'\uf083'},
{name:'fas fa-campground',code:'\uf6bb'},
{name:'fas fa-candy-cane',code:'\uf786'},
{name:'fas fa-cannabis',code:'\uf55f'},
{name:'fas fa-capsules',code:'\uf46b'},
{name:'fas fa-car',code:'\uf1b9'},
{name:'fas fa-car-alt',code:'\uf5de'},
{name:'fas fa-car-battery',code:'\uf5df'},
{name:'fas fa-car-crash',code:'\uf5e1'},
{name:'fas fa-car-side',code:'\uf5e4'},
{name:'fas fa-caravan',code:'\uf8ff'},
{name:'fas fa-caret-down',code:'\uf0d7'},
{name:'fas fa-caret-left',code:'\uf0d9'},
{name:'fas fa-caret-right',code:'\uf0da'},
{name:'fas fa-caret-square-down',code:'\uf150'},
{name:'fas fa-caret-square-left',code:'\uf191'},
{name:'fas fa-caret-square-right',code:'\uf152'},
{name:'fas fa-caret-square-up',code:'\uf151'},
{name:'fas fa-caret-up',code:'\uf0d8'},
{name:'fas fa-carrot',code:'\uf787'},
{name:'fas fa-cart-arrow-down',code:'\uf218'},
{name:'fas fa-cart-plus',code:'\uf217'},
{name:'fas fa-cash-register',code:'\uf788'},
{name:'fas fa-cat',code:'\uf6be'},
{name:'fas fa-certificate',code:'\uf0a3'},
{name:'fas fa-chair',code:'\uf6c0'},
{name:'fas fa-chalkboard',code:'\uf51b'},
{name:'fas fa-chalkboard-teacher',code:'\uf51c'},
{name:'fas fa-charging-station',code:'\uf5e7'},
{name:'fas fa-chart-area',code:'\uf1fe'},
{name:'fas fa-chart-bar',code:'\uf080'},
{name:'fas fa-chart-line',code:'\uf201'},
{name:'fas fa-chart-pie',code:'\uf200'},
{name:'fas fa-check',code:'\uf00c'},
{name:'fas fa-check-circle',code:'\uf058'},
{name:'fas fa-check-double',code:'\uf560'},
{name:'fas fa-check-square',code:'\uf14a'},
{name:'fas fa-cheese',code:'\uf7ef'},
{name:'fas fa-chess',code:'\uf439'},
{name:'fas fa-chess-bishop',code:'\uf43a'},
{name:'fas fa-chess-board',code:'\uf43c'},
{name:'fas fa-chess-king',code:'\uf43f'},
{name:'fas fa-chess-knight',code:'\uf441'},
{name:'fas fa-chess-pawn',code:'\uf443'},
{name:'fas fa-chess-queen',code:'\uf445'},
{name:'fas fa-chess-rook',code:'\uf447'},
{name:'fas fa-chevron-circle-down',code:'\uf13a'},
{name:'fas fa-chevron-circle-left',code:'\uf137'},
{name:'fas fa-chevron-circle-right',code:'\uf138'},
{name:'fas fa-chevron-circle-up',code:'\uf139'},
{name:'fas fa-chevron-down',code:'\uf078'},
{name:'fas fa-chevron-left',code:'\uf053'},
{name:'fas fa-chevron-right',code:'\uf054'},
{name:'fas fa-chevron-up',code:'\uf077'},
{name:'fas fa-child',code:'\uf1ae'},
{name:'fas fa-church',code:'\uf51d'},
{name:'fas fa-circle',code:'\uf111'},
{name:'fas fa-circle-notch',code:'\uf1ce'},
{name:'fas fa-city',code:'\uf64f'},
{name:'fas fa-clinic-medical',code:'\uf7f2'},
{name:'fas fa-clipboard',code:'\uf328'},
{name:'fas fa-clipboard-check',code:'\uf46c'},
{name:'fas fa-clipboard-list',code:'\uf46d'},
{name:'fas fa-clock',code:'\uf017'},
{name:'fas fa-clone',code:'\uf24d'},
{name:'fas fa-closed-captioning',code:'\uf20a'},
{name:'fas fa-cloud',code:'\uf0c2'},
{name:'fas fa-cloud-download-alt',code:'\uf381'},
{name:'fas fa-cloud-meatball',code:'\uf73b'},
{name:'fas fa-cloud-moon',code:'\uf6c3'},
{name:'fas fa-cloud-moon-rain',code:'\uf73c'},
{name:'fas fa-cloud-rain',code:'\uf73d'},
{name:'fas fa-cloud-showers-heavy',code:'\uf740'},
{name:'fas fa-cloud-sun',code:'\uf6c4'},
{name:'fas fa-cloud-sun-rain',code:'\uf743'},
{name:'fas fa-cloud-upload-alt',code:'\uf382'},
{name:'fas fa-cocktail',code:'\uf561'},
{name:'fas fa-code',code:'\uf121'},
{name:'fas fa-code-branch',code:'\uf126'},
{name:'fas fa-coffee',code:'\uf0f4'},
{name:'fas fa-cog',code:'\uf013'},
{name:'fas fa-cogs',code:'\uf085'},
{name:'fas fa-coins',code:'\uf51e'},
{name:'fas fa-columns',code:'\uf0db'},
{name:'fas fa-comment',code:'\uf075'},
{name:'fas fa-comment-alt',code:'\uf27a'},
{name:'fas fa-comment-dollar',code:'\uf651'},
{name:'fas fa-comment-dots',code:'\uf4ad'},
{name:'fas fa-comment-medical',code:'\uf7f5'},
{name:'fas fa-comment-slash',code:'\uf4b3'},
{name:'fas fa-comments',code:'\uf086'},
{name:'fas fa-comments-dollar',code:'\uf653'},
{name:'fas fa-compact-disc',code:'\uf51f'},
{name:'fas fa-compass',code:'\uf14e'},
{name:'fas fa-compress',code:'\uf066'},
{name:'fas fa-compress-alt',code:'\uf422'},
{name:'fas fa-compress-arrows-alt',code:'\uf78c'},
{name:'fas fa-concierge-bell',code:'\uf562'},
{name:'fas fa-cookie',code:'\uf563'},
{name:'fas fa-cookie-bite',code:'\uf564'},
{name:'fas fa-copy',code:'\uf0c5'},
{name:'fas fa-copyright',code:'\uf1f9'},
{name:'fas fa-couch',code:'\uf4b8'},
{name:'fas fa-credit-card',code:'\uf09d'},
{name:'fas fa-crop',code:'\uf125'},
{name:'fas fa-crop-alt',code:'\uf565'},
{name:'fas fa-cross',code:'\uf654'},
{name:'fas fa-crosshairs',code:'\uf05b'},
{name:'fas fa-crow',code:'\uf520'},
{name:'fas fa-crown',code:'\uf521'},
{name:'fas fa-crutch',code:'\uf7f7'},
{name:'fas fa-cube',code:'\uf1b2'},
{name:'fas fa-cubes',code:'\uf1b3'},
{name:'fas fa-cut',code:'\uf0c4'},
{name:'fas fa-database',code:'\uf1c0'},
{name:'fas fa-deaf',code:'\uf2a4'},
{name:'fas fa-democrat',code:'\uf747'},
{name:'fas fa-desktop',code:'\uf108'},
{name:'fas fa-dharmachakra',code:'\uf655'},
{name:'fas fa-diagnoses',code:'\uf470'},
{name:'fas fa-dice',code:'\uf522'},
{name:'fas fa-dice-d20',code:'\uf6cf'},
{name:'fas fa-dice-d6',code:'\uf6d1'},
{name:'fas fa-dice-five',code:'\uf523'},
{name:'fas fa-dice-four',code:'\uf524'},
{name:'fas fa-dice-one',code:'\uf525'},
{name:'fas fa-dice-six',code:'\uf526'},
{name:'fas fa-dice-three',code:'\uf527'},
{name:'fas fa-dice-two',code:'\uf528'},
{name:'fas fa-digital-tachograph',code:'\uf566'},
{name:'fas fa-directions',code:'\uf5eb'},
{name:'fas fa-disease',code:'\uf7fa'},
{name:'fas fa-divide',code:'\uf529'},
{name:'fas fa-dizzy',code:'\uf567'},
{name:'fas fa-dna',code:'\uf471'},
{name:'fas fa-dog',code:'\uf6d3'},
{name:'fas fa-dollar-sign',code:'\uf155'},
{name:'fas fa-dolly',code:'\uf472'},
{name:'fas fa-dolly-flatbed',code:'\uf474'},
{name:'fas fa-donate',code:'\uf4b9'},
{name:'fas fa-door-closed',code:'\uf52a'},
{name:'fas fa-door-open',code:'\uf52b'},
{name:'fas fa-dot-circle',code:'\uf192'},
{name:'fas fa-dove',code:'\uf4ba'},
{name:'fas fa-download',code:'\uf019'},
{name:'fas fa-drafting-compass',code:'\uf568'},
{name:'fas fa-dragon',code:'\uf6d5'},
{name:'fas fa-draw-polygon',code:'\uf5ee'},
{name:'fas fa-drum',code:'\uf569'},
{name:'fas fa-drum-steelpan',code:'\uf56a'},
{name:'fas fa-drumstick-bite',code:'\uf6d7'},
{name:'fas fa-dumbbell',code:'\uf44b'},
{name:'fas fa-dumpster',code:'\uf793'},
{name:'fas fa-dumpster-fire',code:'\uf794'},
{name:'fas fa-dungeon',code:'\uf6d9'},
{name:'fas fa-edit',code:'\uf044'},
{name:'fas fa-egg',code:'\uf7fb'},
{name:'fas fa-eject',code:'\uf052'},
{name:'fas fa-ellipsis-h',code:'\uf141'},
{name:'fas fa-ellipsis-v',code:'\uf142'},
{name:'fas fa-envelope',code:'\uf0e0'},
{name:'fas fa-envelope-open',code:'\uf2b6'},
{name:'fas fa-envelope-open-text',code:'\uf658'},
{name:'fas fa-envelope-square',code:'\uf199'},
{name:'fas fa-equals',code:'\uf52c'},
{name:'fas fa-eraser',code:'\uf12d'},
{name:'fas fa-ethernet',code:'\uf796'},
{name:'fas fa-euro-sign',code:'\uf153'},
{name:'fas fa-exchange-alt',code:'\uf362'},
{name:'fas fa-exclamation',code:'\uf12a'},
{name:'fas fa-exclamation-circle',code:'\uf06a'},
{name:'fas fa-exclamation-triangle',code:'\uf071'},
{name:'fas fa-expand',code:'\uf065'},
{name:'fas fa-expand-alt',code:'\uf424'},
{name:'fas fa-expand-arrows-alt',code:'\uf31e'},
{name:'fas fa-external-link-alt',code:'\uf35d'},
{name:'fas fa-external-link-square-alt',code:'\uf360'},
{name:'fas fa-eye',code:'\uf06e'},
{name:'fas fa-eye-dropper',code:'\uf1fb'},
{name:'fas fa-eye-slash',code:'\uf070'},
{name:'fas fa-fan',code:'\uf863'},
{name:'fas fa-fast-backward',code:'\uf049'},
{name:'fas fa-fast-forward',code:'\uf050'},
{name:'fas fa-faucet',code:'\uf905'},
{name:'fas fa-fax',code:'\uf1ac'},
{name:'fas fa-feather',code:'\uf52d'},
{name:'fas fa-feather-alt',code:'\uf56b'},
{name:'fas fa-female',code:'\uf182'},
{name:'fas fa-fighter-jet',code:'\uf0fb'},
{name:'fas fa-file',code:'\uf15b'},
{name:'fas fa-file-alt',code:'\uf15c'},
{name:'fas fa-file-archive',code:'\uf1c6'},
{name:'fas fa-file-audio',code:'\uf1c7'},
{name:'fas fa-file-code',code:'\uf1c9'},
{name:'fas fa-file-contract',code:'\uf56c'},
{name:'fas fa-file-csv',code:'\uf6dd'},
{name:'fas fa-file-download',code:'\uf56d'},
{name:'fas fa-file-excel',code:'\uf1c3'},
{name:'fas fa-file-export',code:'\uf56e'},
{name:'fas fa-file-image',code:'\uf1c5'},
{name:'fas fa-file-import',code:'\uf56f'},
{name:'fas fa-file-invoice',code:'\uf570'},
{name:'fas fa-file-invoice-dollar',code:'\uf571'},
{name:'fas fa-file-medical',code:'\uf477'},
{name:'fas fa-file-medical-alt',code:'\uf478'},
{name:'fas fa-file-pdf',code:'\uf1c1'},
{name:'fas fa-file-powerpoint',code:'\uf1c4'},
{name:'fas fa-file-prescription',code:'\uf572'},
{name:'fas fa-file-signature',code:'\uf573'},
{name:'fas fa-file-upload',code:'\uf574'},
{name:'fas fa-file-video',code:'\uf1c8'},
{name:'fas fa-file-word',code:'\uf1c2'},
{name:'fas fa-fill',code:'\uf575'},
{name:'fas fa-fill-drip',code:'\uf576'},
{name:'fas fa-film',code:'\uf008'},
{name:'fas fa-filter',code:'\uf0b0'},
{name:'fas fa-fingerprint',code:'\uf577'},
{name:'fas fa-fire',code:'\uf06d'},
{name:'fas fa-fire-alt',code:'\uf7e4'},
{name:'fas fa-fire-extinguisher',code:'\uf134'},
{name:'fas fa-first-aid',code:'\uf479'},
{name:'fas fa-fish',code:'\uf578'},
{name:'fas fa-fist-raised',code:'\uf6de'},
{name:'fas fa-flag',code:'\uf024'},
{name:'fas fa-flag-checkered',code:'\uf11e'},
{name:'fas fa-flag-usa',code:'\uf74d'},
{name:'fas fa-flask',code:'\uf0c3'},
{name:'fas fa-flushed',code:'\uf579'},
{name:'fas fa-folder',code:'\uf07b'},
{name:'fas fa-folder-minus',code:'\uf65d'},
{name:'fas fa-folder-open',code:'\uf07c'},
{name:'fas fa-folder-plus',code:'\uf65e'},
{name:'fas fa-font',code:'\uf031'},
{name:'fas fa-football-ball',code:'\uf44e'},
{name:'fas fa-forward',code:'\uf04e'},
{name:'fas fa-frog',code:'\uf52e'},
{name:'fas fa-frown',code:'\uf119'},
{name:'fas fa-frown-open',code:'\uf57a'},
{name:'fas fa-funnel-dollar',code:'\uf662'},
{name:'fas fa-futbol',code:'\uf1e3'},
{name:'fas fa-gamepad',code:'\uf11b'},
{name:'fas fa-gas-pump',code:'\uf52f'},
{name:'fas fa-gavel',code:'\uf0e3'},
{name:'fas fa-gem',code:'\uf3a5'},
{name:'fas fa-genderless',code:'\uf22d'},
{name:'fas fa-ghost',code:'\uf6e2'},
{name:'fas fa-gift',code:'\uf06b'},
{name:'fas fa-gifts',code:'\uf79c'},
{name:'fas fa-glass-cheers',code:'\uf79f'},
{name:'fas fa-glass-martini',code:'\uf000'},
{name:'fas fa-glass-martini-alt',code:'\uf57b'},
{name:'fas fa-glass-whiskey',code:'\uf7a0'},
{name:'fas fa-glasses',code:'\uf530'},
{name:'fas fa-globe',code:'\uf0ac'},
{name:'fas fa-globe-africa',code:'\uf57c'},
{name:'fas fa-globe-americas',code:'\uf57d'},
{name:'fas fa-globe-asia',code:'\uf57e'},
{name:'fas fa-globe-europe',code:'\uf7a2'},
{name:'fas fa-golf-ball',code:'\uf450'},
{name:'fas fa-gopuram',code:'\uf664'},
{name:'fas fa-graduation-cap',code:'\uf19d'},
{name:'fas fa-greater-than',code:'\uf531'},
{name:'fas fa-greater-than-equal',code:'\uf532'},
{name:'fas fa-grimace',code:'\uf57f'},
{name:'fas fa-grin',code:'\uf580'},
{name:'fas fa-grin-alt',code:'\uf581'},
{name:'fas fa-grin-beam',code:'\uf582'},
{name:'fas fa-grin-beam-sweat',code:'\uf583'},
{name:'fas fa-grin-hearts',code:'\uf584'},
{name:'fas fa-grin-squint',code:'\uf585'},
{name:'fas fa-grin-squint-tears',code:'\uf586'},
{name:'fas fa-grin-stars',code:'\uf587'},
{name:'fas fa-grin-tears',code:'\uf588'},
{name:'fas fa-grin-tongue',code:'\uf589'},
{name:'fas fa-grin-tongue-squint',code:'\uf58a'},
{name:'fas fa-grin-tongue-wink',code:'\uf58b'},
{name:'fas fa-grin-wink',code:'\uf58c'},
{name:'fas fa-grip-horizontal',code:'\uf58d'},
{name:'fas fa-grip-lines',code:'\uf7a4'},
{name:'fas fa-grip-lines-vertical',code:'\uf7a5'},
{name:'fas fa-grip-vertical',code:'\uf58e'},
{name:'fas fa-guitar',code:'\uf7a6'},
{name:'fas fa-h-square',code:'\uf0fd'},
{name:'fas fa-hamburger',code:'\uf805'},
{name:'fas fa-hammer',code:'\uf6e3'},
{name:'fas fa-hamsa',code:'\uf665'},
{name:'fas fa-hand-holding',code:'\uf4bd'},
{name:'fas fa-hand-holding-heart',code:'\uf4be'},
{name:'fas fa-hand-holding-medical',code:'\uf95c'},
{name:'fas fa-hand-holding-usd',code:'\uf4c0'},
{name:'fas fa-hand-holding-water',code:'\uf4c1'},
{name:'fas fa-hand-lizard',code:'\uf258'},
{name:'fas fa-hand-middle-finger',code:'\uf806'},
{name:'fas fa-hand-paper',code:'\uf256'},
{name:'fas fa-hand-peace',code:'\uf25b'},
{name:'fas fa-hand-point-down',code:'\uf0a7'},
{name:'fas fa-hand-point-left',code:'\uf0a5'},
{name:'fas fa-hand-point-right',code:'\uf0a4'},
{name:'fas fa-hand-point-up',code:'\uf0a6'},
{name:'fas fa-hand-pointer',code:'\uf25a'},
{name:'fas fa-hand-rock',code:'\uf255'},
{name:'fas fa-hand-scissors',code:'\uf257'},
{name:'fas fa-hand-sparkles',code:'\uf95d'},
{name:'fas fa-hand-spock',code:'\uf259'},
{name:'fas fa-hands',code:'\uf4c2'},
{name:'fas fa-hands-helping',code:'\uf4c4'},
{name:'fas fa-hands-wash',code:'\uf95e'},
{name:'fas fa-handshake',code:'\uf2b5'},
{name:'fas fa-handshake-alt-slash',code:'\uf95f'},
{name:'fas fa-handshake-slash',code:'\uf960'},
{name:'fas fa-hanukiah',code:'\uf6e6'},
{name:'fas fa-hard-hat',code:'\uf807'},
{name:'fas fa-hashtag',code:'\uf292'},
{name:'fas fa-hat-cowboy',code:'\uf8c0'},
{name:'fas fa-hat-cowboy-side',code:'\uf8c1'},
{name:'fas fa-hat-wizard',code:'\uf6e8'},
{name:'fas fa-hdd',code:'\uf0a0'},
{name:'fas fa-head-side-cough',code:'\uf961'},
{name:'fas fa-head-side-cough-slash',code:'\uf962'},
{name:'fas fa-head-side-mask',code:'\uf963'},
{name:'fas fa-head-side-virus',code:'\uf964'},
{name:'fas fa-heading',code:'\uf1dc'},
{name:'fas fa-headphones',code:'\uf025'},
{name:'fas fa-headphones-alt',code:'\uf58f'},
{name:'fas fa-headset',code:'\uf590'},
{name:'fas fa-heart',code:'\uf004'},
{name:'fas fa-heart-broken',code:'\uf7a9'},
{name:'fas fa-heartbeat',code:'\uf21e'},
{name:'fas fa-helicopter',code:'\uf533'},
{name:'fas fa-highlighter',code:'\uf591'},
{name:'fas fa-hiking',code:'\uf6ec'},
{name:'fas fa-hippo',code:'\uf6ed'},
{name:'fas fa-history',code:'\uf1da'},
{name:'fas fa-hockey-puck',code:'\uf453'},
{name:'fas fa-holly-berry',code:'\uf7aa'},
{name:'fas fa-home',code:'\uf015'},
{name:'fas fa-horse',code:'\uf6f0'},
{name:'fas fa-horse-head',code:'\uf7ab'},
{name:'fas fa-hospital',code:'\uf0f8'},
{name:'fas fa-hospital-alt',code:'\uf47d'},
{name:'fas fa-hospital-symbol',code:'\uf47e'},
{name:'fas fa-hospital-user',code:'\uf80d'},
{name:'fas fa-hot-tub',code:'\uf593'},
{name:'fas fa-hotdog',code:'\uf80f'},
{name:'fas fa-hotel',code:'\uf594'},
{name:'fas fa-hourglass',code:'\uf254'},
{name:'fas fa-hourglass-end',code:'\uf253'},
{name:'fas fa-hourglass-half',code:'\uf252'},
{name:'fas fa-hourglass-start',code:'\uf251'},
{name:'fas fa-house-damage',code:'\uf6f1'},
{name:'fas fa-house-user',code:'\uf965'},
{name:'fas fa-hryvnia',code:'\uf6f2'},
{name:'fas fa-i-cursor',code:'\uf246'},
{name:'fas fa-ice-cream',code:'\uf810'},
{name:'fas fa-icicles',code:'\uf7ad'},
{name:'fas fa-icons',code:'\uf86d'},
{name:'fas fa-id-badge',code:'\uf2c1'},
{name:'fas fa-id-card',code:'\uf2c2'},
{name:'fas fa-id-card-alt',code:'\uf47f'},
{name:'fas fa-igloo',code:'\uf7ae'},
{name:'fas fa-image',code:'\uf03e'},
{name:'fas fa-images',code:'\uf302'},
{name:'fas fa-inbox',code:'\uf01c'},
{name:'fas fa-indent',code:'\uf03c'},
{name:'fas fa-industry',code:'\uf275'},
{name:'fas fa-infinity',code:'\uf534'},
{name:'fas fa-info',code:'\uf129'},
{name:'fas fa-info-circle',code:'\uf05a'},
{name:'fas fa-italic',code:'\uf033'},
{name:'fas fa-jedi',code:'\uf669'},
{name:'fas fa-joint',code:'\uf595'},
{name:'fas fa-journal-whills',code:'\uf66a'},
{name:'fas fa-kaaba',code:'\uf66b'},
{name:'fas fa-key',code:'\uf084'},
{name:'fas fa-keyboard',code:'\uf11c'},
{name:'fas fa-khanda',code:'\uf66d'},
{name:'fas fa-kiss',code:'\uf596'},
{name:'fas fa-kiss-beam',code:'\uf597'},
{name:'fas fa-kiss-wink-heart',code:'\uf598'},
{name:'fas fa-kiwi-bird',code:'\uf535'},
{name:'fas fa-landmark',code:'\uf66f'},
{name:'fas fa-language',code:'\uf1ab'},
{name:'fas fa-laptop',code:'\uf109'},
{name:'fas fa-laptop-code',code:'\uf5fc'},
{name:'fas fa-laptop-house',code:'\uf966'},
{name:'fas fa-laptop-medical',code:'\uf812'},
{name:'fas fa-laugh',code:'\uf599'},
{name:'fas fa-laugh-beam',code:'\uf59a'},
{name:'fas fa-laugh-squint',code:'\uf59b'},
{name:'fas fa-laugh-wink',code:'\uf59c'},
{name:'fas fa-layer-group',code:'\uf5fd'},
{name:'fas fa-leaf',code:'\uf06c'},
{name:'fas fa-lemon',code:'\uf094'},
{name:'fas fa-less-than',code:'\uf536'},
{name:'fas fa-less-than-equal',code:'\uf537'},
{name:'fas fa-level-down-alt',code:'\uf3be'},
{name:'fas fa-level-up-alt',code:'\uf3bf'},
{name:'fas fa-life-ring',code:'\uf1cd'},
{name:'fas fa-lightbulb',code:'\uf0eb'},
{name:'fas fa-link',code:'\uf0c1'},
{name:'fas fa-lira-sign',code:'\uf195'},
{name:'fas fa-list',code:'\uf03a'},
{name:'fas fa-list-alt',code:'\uf022'},
{name:'fas fa-list-ol',code:'\uf0cb'},
{name:'fas fa-list-ul',code:'\uf0ca'},
{name:'fas fa-location-arrow',code:'\uf124'},
{name:'fas fa-lock',code:'\uf023'},
{name:'fas fa-lock-open',code:'\uf3c1'},
{name:'fas fa-long-arrow-alt-down',code:'\uf309'},
{name:'fas fa-long-arrow-alt-left',code:'\uf30a'},
{name:'fas fa-long-arrow-alt-right',code:'\uf30b'},
{name:'fas fa-long-arrow-alt-up',code:'\uf30c'},
{name:'fas fa-low-vision',code:'\uf2a8'},
{name:'fas fa-luggage-cart',code:'\uf59d'},
{name:'fas fa-lungs',code:'\uf604'},
{name:'fas fa-lungs-virus',code:'\uf967'},
{name:'fas fa-magic',code:'\uf0d0'},
{name:'fas fa-magnet',code:'\uf076'},
{name:'fas fa-mail-bulk',code:'\uf674'},
{name:'fas fa-male',code:'\uf183'},
{name:'fas fa-map',code:'\uf279'},
{name:'fas fa-map-marked',code:'\uf59f'},
{name:'fas fa-map-marked-alt',code:'\uf5a0'},
{name:'fas fa-map-marker',code:'\uf041'},
{name:'fas fa-map-marker-alt',code:'\uf3c5'},
{name:'fas fa-map-pin',code:'\uf276'},
{name:'fas fa-map-signs',code:'\uf277'},
{name:'fas fa-marker',code:'\uf5a1'},
{name:'fas fa-mars',code:'\uf222'},
{name:'fas fa-mars-double',code:'\uf227'},
{name:'fas fa-mars-stroke',code:'\uf229'},
{name:'fas fa-mars-stroke-h',code:'\uf22b'},
{name:'fas fa-mars-stroke-v',code:'\uf22a'},
{name:'fas fa-mask',code:'\uf6fa'},
{name:'fas fa-medal',code:'\uf5a2'},
{name:'fas fa-medkit',code:'\uf0fa'},
{name:'fas fa-meh',code:'\uf11a'},
{name:'fas fa-meh-blank',code:'\uf5a4'},
{name:'fas fa-meh-rolling-eyes',code:'\uf5a5'},
{name:'fas fa-memory',code:'\uf538'},
{name:'fas fa-menorah',code:'\uf676'},
{name:'fas fa-mercury',code:'\uf223'},
{name:'fas fa-meteor',code:'\uf753'},
{name:'fas fa-microchip',code:'\uf2db'},
{name:'fas fa-microphone',code:'\uf130'},
{name:'fas fa-microphone-alt',code:'\uf3c9'},
{name:'fas fa-microphone-alt-slash',code:'\uf539'},
{name:'fas fa-microphone-slash',code:'\uf131'},
{name:'fas fa-microscope',code:'\uf610'},
{name:'fas fa-minus',code:'\uf068'},
{name:'fas fa-minus-circle',code:'\uf056'},
{name:'fas fa-minus-square',code:'\uf146'},
{name:'fas fa-mitten',code:'\uf7b5'},
{name:'fas fa-mobile',code:'\uf10b'},
{name:'fas fa-mobile-alt',code:'\uf3cd'},
{name:'fas fa-money-bill',code:'\uf0d6'},
{name:'fas fa-money-bill-alt',code:'\uf3d1'},
{name:'fas fa-money-bill-wave',code:'\uf53a'},
{name:'fas fa-money-bill-wave-alt',code:'\uf53b'},
{name:'fas fa-money-check',code:'\uf53c'},
{name:'fas fa-money-check-alt',code:'\uf53d'},
{name:'fas fa-monument',code:'\uf5a6'},
{name:'fas fa-moon',code:'\uf186'},
{name:'fas fa-mortar-pestle',code:'\uf5a7'},
{name:'fas fa-mosque',code:'\uf678'},
{name:'fas fa-motorcycle',code:'\uf21c'},
{name:'fas fa-mountain',code:'\uf6fc'},
{name:'fas fa-mouse',code:'\uf8cc'},
{name:'fas fa-mouse-pointer',code:'\uf245'},
{name:'fas fa-mug-hot',code:'\uf7b6'},
{name:'fas fa-music',code:'\uf001'},
{name:'fas fa-network-wired',code:'\uf6ff'},
{name:'fas fa-neuter',code:'\uf22c'},
{name:'fas fa-newspaper',code:'\uf1ea'},
{name:'fas fa-not-equal',code:'\uf53e'},
{name:'fas fa-notes-medical',code:'\uf481'},
{name:'fas fa-object-group',code:'\uf247'},
{name:'fas fa-object-ungroup',code:'\uf248'},
{name:'fas fa-oil-can',code:'\uf613'},
{name:'fas fa-om',code:'\uf679'},
{name:'fas fa-otter',code:'\uf700'},
{name:'fas fa-outdent',code:'\uf03b'},
{name:'fas fa-pager',code:'\uf815'},
{name:'fas fa-paint-brush',code:'\uf1fc'},
{name:'fas fa-paint-roller',code:'\uf5aa'},
{name:'fas fa-palette',code:'\uf53f'},
{name:'fas fa-pallet',code:'\uf482'},
{name:'fas fa-paper-plane',code:'\uf1d8'},
{name:'fas fa-paperclip',code:'\uf0c6'},
{name:'fas fa-parachute-box',code:'\uf4cd'},
{name:'fas fa-paragraph',code:'\uf1dd'},
{name:'fas fa-parking',code:'\uf540'},
{name:'fas fa-passport',code:'\uf5ab'},
{name:'fas fa-pastafarianism',code:'\uf67b'},
{name:'fas fa-paste',code:'\uf0ea'},
{name:'fas fa-pause',code:'\uf04c'},
{name:'fas fa-pause-circle',code:'\uf28b'},
{name:'fas fa-paw',code:'\uf1b0'},
{name:'fas fa-peace',code:'\uf67c'},
{name:'fas fa-pen',code:'\uf304'},
{name:'fas fa-pen-alt',code:'\uf305'},
{name:'fas fa-pen-fancy',code:'\uf5ac'},
{name:'fas fa-pen-nib',code:'\uf5ad'},
{name:'fas fa-pen-square',code:'\uf14b'},
{name:'fas fa-pencil-alt',code:'\uf303'},
{name:'fas fa-pencil-ruler',code:'\uf5ae'},
{name:'fas fa-people-arrows',code:'\uf968'},
{name:'fas fa-people-carry',code:'\uf4ce'},
{name:'fas fa-pepper-hot',code:'\uf816'},
{name:'fas fa-percent',code:'\uf295'},
{name:'fas fa-percentage',code:'\uf541'},
{name:'fas fa-person-booth',code:'\uf756'},
{name:'fas fa-phone',code:'\uf095'},
{name:'fas fa-phone-alt',code:'\uf879'},
{name:'fas fa-phone-slash',code:'\uf3dd'},
{name:'fas fa-phone-square',code:'\uf098'},
{name:'fas fa-phone-square-alt',code:'\uf87b'},
{name:'fas fa-phone-volume',code:'\uf2a0'},
{name:'fas fa-photo-video',code:'\uf87c'},
{name:'fas fa-piggy-bank',code:'\uf4d3'},
{name:'fas fa-pills',code:'\uf484'},
{name:'fas fa-pizza-slice',code:'\uf818'},
{name:'fas fa-place-of-worship',code:'\uf67f'},
{name:'fas fa-plane',code:'\uf072'},
{name:'fas fa-plane-arrival',code:'\uf5af'},
{name:'fas fa-plane-departure',code:'\uf5b0'},
{name:'fas fa-plane-slash',code:'\uf969'},
{name:'fas fa-play',code:'\uf04b'},
{name:'fas fa-play-circle',code:'\uf144'},
{name:'fas fa-plug',code:'\uf1e6'},
{name:'fas fa-plus',code:'\uf067'},
{name:'fas fa-plus-circle',code:'\uf055'},
{name:'fas fa-plus-square',code:'\uf0fe'},
{name:'fas fa-podcast',code:'\uf2ce'},
{name:'fas fa-poll',code:'\uf681'},
{name:'fas fa-poll-h',code:'\uf682'},
{name:'fas fa-poo',code:'\uf2fe'},
{name:'fas fa-poo-storm',code:'\uf75a'},
{name:'fas fa-poop',code:'\uf619'},
{name:'fas fa-portrait',code:'\uf3e0'},
{name:'fas fa-pound-sign',code:'\uf154'},
{name:'fas fa-power-off',code:'\uf011'},
{name:'fas fa-pray',code:'\uf683'},
{name:'fas fa-praying-hands',code:'\uf684'},
{name:'fas fa-prescription',code:'\uf5b1'},
{name:'fas fa-prescription-bottle',code:'\uf485'},
{name:'fas fa-prescription-bottle-alt',code:'\uf486'},
{name:'fas fa-print',code:'\uf02f'},
{name:'fas fa-procedures',code:'\uf487'},
{name:'fas fa-project-diagram',code:'\uf542'},
{name:'fas fa-pump-medical',code:'\uf96a'},
{name:'fas fa-pump-soap',code:'\uf96b'},
{name:'fas fa-puzzle-piece',code:'\uf12e'},
{name:'fas fa-qrcode',code:'\uf029'},
{name:'fas fa-question',code:'\uf128'},
{name:'fas fa-question-circle',code:'\uf059'},
{name:'fas fa-quidditch',code:'\uf458'},
{name:'fas fa-quote-left',code:'\uf10d'},
{name:'fas fa-quote-right',code:'\uf10e'},
{name:'fas fa-quran',code:'\uf687'},
{name:'fas fa-radiation',code:'\uf7b9'},
{name:'fas fa-radiation-alt',code:'\uf7ba'},
{name:'fas fa-rainbow',code:'\uf75b'},
{name:'fas fa-random',code:'\uf074'},
{name:'fas fa-receipt',code:'\uf543'},
{name:'fas fa-record-vinyl',code:'\uf8d9'},
{name:'fas fa-recycle',code:'\uf1b8'},
{name:'fas fa-redo',code:'\uf01e'},
{name:'fas fa-redo-alt',code:'\uf2f9'},
{name:'fas fa-registered',code:'\uf25d'},
{name:'fas fa-remove-format',code:'\uf87d'},
{name:'fas fa-reply',code:'\uf3e5'},
{name:'fas fa-reply-all',code:'\uf122'},
{name:'fas fa-republican',code:'\uf75e'},
{name:'fas fa-restroom',code:'\uf7bd'},
{name:'fas fa-retweet',code:'\uf079'},
{name:'fas fa-ribbon',code:'\uf4d6'},
{name:'fas fa-ring',code:'\uf70b'},
{name:'fas fa-road',code:'\uf018'},
{name:'fas fa-robot',code:'\uf544'},
{name:'fas fa-rocket',code:'\uf135'},
{name:'fas fa-route',code:'\uf4d7'},
{name:'fas fa-rss',code:'\uf09e'},
{name:'fas fa-rss-square',code:'\uf143'},
{name:'fas fa-ruble-sign',code:'\uf158'},
{name:'fas fa-ruler',code:'\uf545'},
{name:'fas fa-ruler-combined',code:'\uf546'},
{name:'fas fa-ruler-horizontal',code:'\uf547'},
{name:'fas fa-ruler-vertical',code:'\uf548'},
{name:'fas fa-running',code:'\uf70c'},
{name:'fas fa-rupee-sign',code:'\uf156'},
{name:'fas fa-sad-cry',code:'\uf5b3'},
{name:'fas fa-sad-tear',code:'\uf5b4'},
{name:'fas fa-satellite',code:'\uf7bf'},
{name:'fas fa-satellite-dish',code:'\uf7c0'},
{name:'fas fa-save',code:'\uf0c7'},
{name:'fas fa-school',code:'\uf549'},
{name:'fas fa-screwdriver',code:'\uf54a'},
{name:'fas fa-scroll',code:'\uf70e'},
{name:'fas fa-sd-card',code:'\uf7c2'},
{name:'fas fa-search',code:'\uf002'},
{name:'fas fa-search-dollar',code:'\uf688'},
{name:'fas fa-search-location',code:'\uf689'},
{name:'fas fa-search-minus',code:'\uf010'},
{name:'fas fa-search-plus',code:'\uf00e'},
{name:'fas fa-seedling',code:'\uf4d8'},
{name:'fas fa-server',code:'\uf233'},
{name:'fas fa-shapes',code:'\uf61f'},
{name:'fas fa-share',code:'\uf064'},
{name:'fas fa-share-alt',code:'\uf1e0'},
{name:'fas fa-share-alt-square',code:'\uf1e1'},
{name:'fas fa-share-square',code:'\uf14d'},
{name:'fas fa-shekel-sign',code:'\uf20b'},
{name:'fas fa-shield-alt',code:'\uf3ed'},
{name:'fas fa-shield-virus',code:'\uf96c'},
{name:'fas fa-ship',code:'\uf21a'},
{name:'fas fa-shipping-fast',code:'\uf48b'},
{name:'fas fa-shoe-prints',code:'\uf54b'},
{name:'fas fa-shopping-bag',code:'\uf290'},
{name:'fas fa-shopping-basket',code:'\uf291'},
{name:'fas fa-shopping-cart',code:'\uf07a'},
{name:'fas fa-shower',code:'\uf2cc'},
{name:'fas fa-shuttle-van',code:'\uf5b6'},
{name:'fas fa-sign',code:'\uf4d9'},
{name:'fas fa-sign-in-alt',code:'\uf2f6'},
{name:'fas fa-sign-language',code:'\uf2a7'},
{name:'fas fa-sign-out-alt',code:'\uf2f5'},
{name:'fas fa-signal',code:'\uf012'},
{name:'fas fa-signature',code:'\uf5b7'},
{name:'fas fa-sim-card',code:'\uf7c4'},
{name:'fas fa-sink',code:'\uf96d'},
{name:'fas fa-sitemap',code:'\uf0e8'},
{name:'fas fa-skating',code:'\uf7c5'},
{name:'fas fa-skiing',code:'\uf7c9'},
{name:'fas fa-skiing-nordic',code:'\uf7ca'},
{name:'fas fa-skull',code:'\uf54c'},
{name:'fas fa-skull-crossbones',code:'\uf714'},
{name:'fas fa-slash',code:'\uf715'},
{name:'fas fa-sleigh',code:'\uf7cc'},
{name:'fas fa-sliders-h',code:'\uf1de'},
{name:'fas fa-smile',code:'\uf118'},
{name:'fas fa-smile-beam',code:'\uf5b8'},
{name:'fas fa-smile-wink',code:'\uf4da'},
{name:'fas fa-smog',code:'\uf75f'},
{name:'fas fa-smoking',code:'\uf48d'},
{name:'fas fa-smoking-ban',code:'\uf54d'},
{name:'fas fa-sms',code:'\uf7cd'},
{name:'fas fa-snowboarding',code:'\uf7ce'},
{name:'fas fa-snowflake',code:'\uf2dc'},
{name:'fas fa-snowman',code:'\uf7d0'},
{name:'fas fa-snowplow',code:'\uf7d2'},
{name:'fas fa-soap',code:'\uf96e'},
{name:'fas fa-socks',code:'\uf696'},
{name:'fas fa-solar-panel',code:'\uf5ba'},
{name:'fas fa-sort',code:'\uf0dc'},
{name:'fas fa-sort-alpha-down',code:'\uf15d'},
{name:'fas fa-sort-alpha-down-alt',code:'\uf881'},
{name:'fas fa-sort-alpha-up',code:'\uf15e'},
{name:'fas fa-sort-alpha-up-alt',code:'\uf882'},
{name:'fas fa-sort-amount-down',code:'\uf160'},
{name:'fas fa-sort-amount-down-alt',code:'\uf884'},
{name:'fas fa-sort-amount-up',code:'\uf161'},
{name:'fas fa-sort-amount-up-alt',code:'\uf885'},
{name:'fas fa-sort-down',code:'\uf0dd'},
{name:'fas fa-sort-numeric-down',code:'\uf162'},
{name:'fas fa-sort-numeric-down-alt',code:'\uf886'},
{name:'fas fa-sort-numeric-up',code:'\uf163'},
{name:'fas fa-sort-numeric-up-alt',code:'\uf887'},
{name:'fas fa-sort-up',code:'\uf0de'},
{name:'fas fa-spa',code:'\uf5bb'},
{name:'fas fa-space-shuttle',code:'\uf197'},
{name:'fas fa-spell-check',code:'\uf891'},
{name:'fas fa-spider',code:'\uf717'},
{name:'fas fa-spinner',code:'\uf110'},
{name:'fas fa-splotch',code:'\uf5bc'},
{name:'fas fa-spray-can',code:'\uf5bd'},
{name:'fas fa-square',code:'\uf0c8'},
{name:'fas fa-square-full',code:'\uf45c'},
{name:'fas fa-square-root-alt',code:'\uf698'},
{name:'fas fa-stamp',code:'\uf5bf'},
{name:'fas fa-star',code:'\uf005'},
{name:'fas fa-star-and-crescent',code:'\uf699'},
{name:'fas fa-star-half',code:'\uf089'},
{name:'fas fa-star-half-alt',code:'\uf5c0'},
{name:'fas fa-star-of-david',code:'\uf69a'},
{name:'fas fa-star-of-life',code:'\uf621'},
{name:'fas fa-step-backward',code:'\uf048'},
{name:'fas fa-step-forward',code:'\uf051'},
{name:'fas fa-stethoscope',code:'\uf0f1'},
{name:'fas fa-sticky-note',code:'\uf249'},
{name:'fas fa-stop',code:'\uf04d'},
{name:'fas fa-stop-circle',code:'\uf28d'},
{name:'fas fa-stopwatch',code:'\uf2f2'},
{name:'fas fa-stopwatch-20',code:'\uf96f'},
{name:'fas fa-store',code:'\uf54e'},
{name:'fas fa-store-alt',code:'\uf54f'},
{name:'fas fa-store-alt-slash',code:'\uf970'},
{name:'fas fa-store-slash',code:'\uf971'},
{name:'fas fa-stream',code:'\uf550'},
{name:'fas fa-street-view',code:'\uf21d'},
{name:'fas fa-strikethrough',code:'\uf0cc'},
{name:'fas fa-stroopwafel',code:'\uf551'},
{name:'fas fa-subscript',code:'\uf12c'},
{name:'fas fa-subway',code:'\uf239'},
{name:'fas fa-suitcase',code:'\uf0f2'},
{name:'fas fa-suitcase-rolling',code:'\uf5c1'},
{name:'fas fa-sun',code:'\uf185'},
{name:'fas fa-superscript',code:'\uf12b'},
{name:'fas fa-surprise',code:'\uf5c2'},
{name:'fas fa-swatchbook',code:'\uf5c3'},
{name:'fas fa-swimmer',code:'\uf5c4'},
{name:'fas fa-swimming-pool',code:'\uf5c5'},
{name:'fas fa-synagogue',code:'\uf69b'},
{name:'fas fa-sync',code:'\uf021'},
{name:'fas fa-sync-alt',code:'\uf2f1'},
{name:'fas fa-syringe',code:'\uf48e'},
{name:'fas fa-table',code:'\uf0ce'},
{name:'fas fa-table-tennis',code:'\uf45d'},
{name:'fas fa-tablet',code:'\uf10a'},
{name:'fas fa-tablet-alt',code:'\uf3fa'},
{name:'fas fa-tablets',code:'\uf490'},
{name:'fas fa-tachometer-alt',code:'\uf3fd'},
{name:'fas fa-tag',code:'\uf02b'},
{name:'fas fa-tags',code:'\uf02c'},
{name:'fas fa-tape',code:'\uf4db'},
{name:'fas fa-tasks',code:'\uf0ae'},
{name:'fas fa-taxi',code:'\uf1ba'},
{name:'fas fa-teeth',code:'\uf62e'},
{name:'fas fa-teeth-open',code:'\uf62f'},
{name:'fas fa-temperature-high',code:'\uf769'},
{name:'fas fa-temperature-low',code:'\uf76b'},
{name:'fas fa-tenge',code:'\uf7d7'},
{name:'fas fa-terminal',code:'\uf120'},
{name:'fas fa-text-height',code:'\uf034'},
{name:'fas fa-text-width',code:'\uf035'},
{name:'fas fa-th',code:'\uf00a'},
{name:'fas fa-th-large',code:'\uf009'},
{name:'fas fa-th-list',code:'\uf00b'},
{name:'fas fa-theater-masks',code:'\uf630'},
{name:'fas fa-thermometer',code:'\uf491'},
{name:'fas fa-thermometer-empty',code:'\uf2cb'},
{name:'fas fa-thermometer-full',code:'\uf2c7'},
{name:'fas fa-thermometer-half',code:'\uf2c9'},
{name:'fas fa-thermometer-quarter',code:'\uf2ca'},
{name:'fas fa-thermometer-three-quarters',code:'\uf2c8'},
{name:'fas fa-thumbs-down',code:'\uf165'},
{name:'fas fa-thumbs-up',code:'\uf164'},
{name:'fas fa-thumbtack',code:'\uf08d'},
{name:'fas fa-ticket-alt',code:'\uf3ff'},
{name:'fas fa-times',code:'\uf00d'},
{name:'fas fa-times-circle',code:'\uf057'},
{name:'fas fa-tint',code:'\uf043'},
{name:'fas fa-tint-slash',code:'\uf5c7'},
{name:'fas fa-tired',code:'\uf5c8'},
{name:'fas fa-toggle-off',code:'\uf204'},
{name:'fas fa-toggle-on',code:'\uf205'},
{name:'fas fa-toilet',code:'\uf7d8'},
{name:'fas fa-toilet-paper',code:'\uf71e'},
{name:'fas fa-toilet-paper-slash',code:'\uf972'},
{name:'fas fa-toolbox',code:'\uf552'},
{name:'fas fa-tools',code:'\uf7d9'},
{name:'fas fa-tooth',code:'\uf5c9'},
{name:'fas fa-torah',code:'\uf6a0'},
{name:'fas fa-torii-gate',code:'\uf6a1'},
{name:'fas fa-tractor',code:'\uf722'},
{name:'fas fa-trademark',code:'\uf25c'},
{name:'fas fa-traffic-light',code:'\uf637'},
{name:'fas fa-trailer',code:'\uf941'},
{name:'fas fa-train',code:'\uf238'},
{name:'fas fa-tram',code:'\uf7da'},
{name:'fas fa-transgender',code:'\uf224'},
{name:'fas fa-transgender-alt',code:'\uf225'},
{name:'fas fa-trash',code:'\uf1f8'},
{name:'fas fa-trash-alt',code:'\uf2ed'},
{name:'fas fa-trash-restore',code:'\uf829'},
{name:'fas fa-trash-restore-alt',code:'\uf82a'},
{name:'fas fa-tree',code:'\uf1bb'},
{name:'fas fa-trophy',code:'\uf091'},
{name:'fas fa-truck',code:'\uf0d1'},
{name:'fas fa-truck-loading',code:'\uf4de'},
{name:'fas fa-truck-monster',code:'\uf63b'},
{name:'fas fa-truck-moving',code:'\uf4df'},
{name:'fas fa-truck-pickup',code:'\uf63c'},
{name:'fas fa-tshirt',code:'\uf553'},
{name:'fas fa-tty',code:'\uf1e4'},
{name:'fas fa-tv',code:'\uf26c'},
{name:'fas fa-umbrella',code:'\uf0e9'},
{name:'fas fa-umbrella-beach',code:'\uf5ca'},
{name:'fas fa-underline',code:'\uf0cd'},
{name:'fas fa-undo',code:'\uf0e2'},
{name:'fas fa-undo-alt',code:'\uf2ea'},
{name:'fas fa-universal-access',code:'\uf29a'},
{name:'fas fa-university',code:'\uf19c'},
{name:'fas fa-unlink',code:'\uf127'},
{name:'fas fa-unlock',code:'\uf09c'},
{name:'fas fa-unlock-alt',code:'\uf13e'},
{name:'fas fa-upload',code:'\uf093'},
{name:'fas fa-user',code:'\uf007'},
{name:'fas fa-user-alt',code:'\uf406'},
{name:'fas fa-user-alt-slash',code:'\uf4fa'},
{name:'fas fa-user-astronaut',code:'\uf4fb'},
{name:'fas fa-user-check',code:'\uf4fc'},
{name:'fas fa-user-circle',code:'\uf2bd'},
{name:'fas fa-user-clock',code:'\uf4fd'},
{name:'fas fa-user-cog',code:'\uf4fe'},
{name:'fas fa-user-edit',code:'\uf4ff'},
{name:'fas fa-user-friends',code:'\uf500'},
{name:'fas fa-user-graduate',code:'\uf501'},
{name:'fas fa-user-injured',code:'\uf728'},
{name:'fas fa-user-lock',code:'\uf502'},
{name:'fas fa-user-md',code:'\uf0f0'},
{name:'fas fa-user-minus',code:'\uf503'},
{name:'fas fa-user-ninja',code:'\uf504'},
{name:'fas fa-user-nurse',code:'\uf82f'},
{name:'fas fa-user-plus',code:'\uf234'},
{name:'fas fa-user-secret',code:'\uf21b'},
{name:'fas fa-user-shield',code:'\uf505'},
{name:'fas fa-user-slash',code:'\uf506'},
{name:'fas fa-user-tag',code:'\uf507'},
{name:'fas fa-user-tie',code:'\uf508'},
{name:'fas fa-user-times',code:'\uf235'},
{name:'fas fa-users',code:'\uf0c0'},
{name:'fas fa-users-cog',code:'\uf509'},
{name:'fas fa-users-slash',code:'\uf973'},
{name:'fas fa-utensil-spoon',code:'\uf2e5'},
{name:'fas fa-utensils',code:'\uf2e7'},
{name:'fas fa-vector-square',code:'\uf5cb'},
{name:'fas fa-venus',code:'\uf221'},
{name:'fas fa-venus-double',code:'\uf226'},
{name:'fas fa-venus-mars',code:'\uf228'},
{name:'fas fa-vial',code:'\uf492'},
{name:'fas fa-vials',code:'\uf493'},
{name:'fas fa-video',code:'\uf03d'},
{name:'fas fa-video-slash',code:'\uf4e2'},
{name:'fas fa-vihara',code:'\uf6a7'},
{name:'fas fa-virus',code:'\uf974'},
{name:'fas fa-virus-slash',code:'\uf975'},
{name:'fas fa-viruses',code:'\uf976'},
{name:'fas fa-voicemail',code:'\uf897'},
{name:'fas fa-volleyball-ball',code:'\uf45f'},
{name:'fas fa-volume-down',code:'\uf027'},
{name:'fas fa-volume-mute',code:'\uf6a9'},
{name:'fas fa-volume-off',code:'\uf026'},
{name:'fas fa-volume-up',code:'\uf028'},
{name:'fas fa-vote-yea',code:'\uf772'},
{name:'fas fa-vr-cardboard',code:'\uf729'},
{name:'fas fa-walking',code:'\uf554'},
{name:'fas fa-wallet',code:'\uf555'},
{name:'fas fa-warehouse',code:'\uf494'},
{name:'fas fa-water',code:'\uf773'},
{name:'fas fa-wave-square',code:'\uf83e'},
{name:'fas fa-weight',code:'\uf496'},
{name:'fas fa-weight-hanging',code:'\uf5cd'},
{name:'fas fa-wheelchair',code:'\uf193'},
{name:'fas fa-wifi',code:'\uf1eb'},
{name:'fas fa-wind',code:'\uf72e'},
{name:'fas fa-window-close',code:'\uf410'},
{name:'fas fa-window-maximize',code:'\uf2d0'},
{name:'fas fa-window-minimize',code:'\uf2d1'},
{name:'fas fa-window-restore',code:'\uf2d2'},
{name:'fas fa-wine-bottle',code:'\uf72f'},
{name:'fas fa-wine-glass',code:'\uf4e3'},
{name:'fas fa-wine-glass-alt',code:'\uf5ce'},
{name:'fas fa-won-sign',code:'\uf159'},
{name:'fas fa-wrench',code:'\uf0ad'},
{name:'fas fa-x-ray',code:'\uf497'},
{name:'fas fa-yen-sign',code:'\uf157'},
{name:'fas fa-yin-yang',code:'\uf6ad'},
{name:'fas fa-address-book',code:'\uf2b9'},
{name:'fas fa-address-card',code:'\uf2bb'},
{name:'fas fa-angry',code:'\uf556'},
{name:'fas fa-arrow-alt-circle-down',code:'\uf358'},
{name:'fas fa-arrow-alt-circle-left',code:'\uf359'},
{name:'fas fa-arrow-alt-circle-right',code:'\uf35a'},
{name:'fas fa-arrow-alt-circle-up',code:'\uf35b'},
{name:'fas fa-bell',code:'\uf0f3'},
{name:'fas fa-bell-slash',code:'\uf1f6'},
{name:'fas fa-bookmark',code:'\uf02e'},
{name:'fas fa-building',code:'\uf1ad'},
{name:'fas fa-calendar',code:'\uf133'},
{name:'fas fa-calendar-alt',code:'\uf073'},
{name:'fas fa-calendar-check',code:'\uf274'},
{name:'fas fa-calendar-minus',code:'\uf272'},
{name:'fas fa-calendar-plus',code:'\uf271'},
{name:'fas fa-calendar-times',code:'\uf273'},
{name:'fas fa-caret-square-down',code:'\uf150'},
{name:'fas fa-caret-square-left',code:'\uf191'},
{name:'fas fa-caret-square-right',code:'\uf152'},
{name:'fas fa-caret-square-up',code:'\uf151'},
{name:'fas fa-chart-bar',code:'\uf080'},
{name:'fas fa-check-circle',code:'\uf058'},
{name:'fas fa-check-square',code:'\uf14a'},
{name:'fas fa-circle',code:'\uf111'},
{name:'fas fa-clipboard',code:'\uf328'},
{name:'fas fa-clock',code:'\uf017'},
{name:'fas fa-clone',code:'\uf24d'},
{name:'fas fa-closed-captioning',code:'\uf20a'},
{name:'fas fa-comment',code:'\uf075'},
{name:'fas fa-comment-alt',code:'\uf27a'},
{name:'fas fa-comment-dots',code:'\uf4ad'},
{name:'fas fa-comments',code:'\uf086'},
{name:'fas fa-compass',code:'\uf14e'},
{name:'fas fa-copy',code:'\uf0c5'},
{name:'fas fa-copyright',code:'\uf1f9'},
{name:'fas fa-credit-card',code:'\uf09d'},
{name:'fas fa-dizzy',code:'\uf567'},
{name:'fas fa-dot-circle',code:'\uf192'},
{name:'fas fa-edit',code:'\uf044'},
{name:'fas fa-envelope',code:'\uf0e0'},
{name:'fas fa-envelope-open',code:'\uf2b6'},
{name:'fas fa-eye',code:'\uf06e'},
{name:'fas fa-eye-slash',code:'\uf070'},
{name:'fas fa-file',code:'\uf15b'},
{name:'fas fa-file-alt',code:'\uf15c'},
{name:'fas fa-file-archive',code:'\uf1c6'},
{name:'fas fa-file-audio',code:'\uf1c7'},
{name:'fas fa-file-code',code:'\uf1c9'},
{name:'fas fa-file-excel',code:'\uf1c3'},
{name:'fas fa-file-image',code:'\uf1c5'},
{name:'fas fa-file-pdf',code:'\uf1c1'},
{name:'fas fa-file-powerpoint',code:'\uf1c4'},
{name:'fas fa-file-video',code:'\uf1c8'},
{name:'fas fa-file-word',code:'\uf1c2'},
{name:'fas fa-flag',code:'\uf024'},
{name:'fas fa-flushed',code:'\uf579'},
{name:'fas fa-folder',code:'\uf07b'},
{name:'fas fa-folder-open',code:'\uf07c'},
{name:'fas fa-frown',code:'\uf119'},
{name:'fas fa-frown-open',code:'\uf57a'},
{name:'fas fa-futbol',code:'\uf1e3'},
{name:'fas fa-gem',code:'\uf3a5'},
{name:'fas fa-grimace',code:'\uf57f'},
{name:'fas fa-grin',code:'\uf580'},
{name:'fas fa-grin-alt',code:'\uf581'},
{name:'fas fa-grin-beam',code:'\uf582'},
{name:'fas fa-grin-beam-sweat',code:'\uf583'},
{name:'fas fa-grin-hearts',code:'\uf584'},
{name:'fas fa-grin-squint',code:'\uf585'},
{name:'fas fa-grin-squint-tears',code:'\uf586'},
{name:'fas fa-grin-stars',code:'\uf587'},
{name:'fas fa-grin-tears',code:'\uf588'},
{name:'fas fa-grin-tongue',code:'\uf589'},
{name:'fas fa-grin-tongue-squint',code:'\uf58a'},
{name:'fas fa-grin-tongue-wink',code:'\uf58b'},
{name:'fas fa-grin-wink',code:'\uf58c'},
{name:'fas fa-hand-lizard',code:'\uf258'},
{name:'fas fa-hand-paper',code:'\uf256'},
{name:'fas fa-hand-peace',code:'\uf25b'},
{name:'fas fa-hand-point-down',code:'\uf0a7'},
{name:'fas fa-hand-point-left',code:'\uf0a5'},
{name:'fas fa-hand-point-right',code:'\uf0a4'},
{name:'fas fa-hand-point-up',code:'\uf0a6'},
{name:'fas fa-hand-pointer',code:'\uf25a'},
{name:'fas fa-hand-rock',code:'\uf255'},
{name:'fas fa-hand-scissors',code:'\uf257'},
{name:'fas fa-hand-spock',code:'\uf259'},
{name:'fas fa-handshake',code:'\uf2b5'},
{name:'fas fa-hdd',code:'\uf0a0'},
{name:'fas fa-heart',code:'\uf004'},
{name:'fas fa-hospital',code:'\uf0f8'},
{name:'fas fa-hourglass',code:'\uf254'},
{name:'fas fa-id-badge',code:'\uf2c1'},
{name:'fas fa-id-card',code:'\uf2c2'},
{name:'fas fa-image',code:'\uf03e'},
{name:'fas fa-images',code:'\uf302'},
{name:'fas fa-keyboard',code:'\uf11c'},
{name:'fas fa-kiss',code:'\uf596'},
{name:'fas fa-kiss-beam',code:'\uf597'},
{name:'fas fa-kiss-wink-heart',code:'\uf598'},
{name:'fas fa-laugh',code:'\uf599'},
{name:'fas fa-laugh-beam',code:'\uf59a'},
{name:'fas fa-laugh-squint',code:'\uf59b'},
{name:'fas fa-laugh-wink',code:'\uf59c'},
{name:'fas fa-lemon',code:'\uf094'},
{name:'fas fa-life-ring',code:'\uf1cd'},
{name:'fas fa-lightbulb',code:'\uf0eb'},
{name:'fas fa-list-alt',code:'\uf022'},
{name:'fas fa-map',code:'\uf279'},
{name:'fas fa-meh',code:'\uf11a'},
{name:'fas fa-meh-blank',code:'\uf5a4'},
{name:'fas fa-meh-rolling-eyes',code:'\uf5a5'},
{name:'fas fa-minus-square',code:'\uf146'},
{name:'fas fa-money-bill-alt',code:'\uf3d1'},
{name:'fas fa-moon',code:'\uf186'},
{name:'fas fa-newspaper',code:'\uf1ea'},
{name:'fas fa-object-group',code:'\uf247'},
{name:'fas fa-object-ungroup',code:'\uf248'},
{name:'fas fa-paper-plane',code:'\uf1d8'},
{name:'fas fa-pause-circle',code:'\uf28b'},
{name:'fas fa-play-circle',code:'\uf144'},
{name:'fas fa-plus-square',code:'\uf0fe'},
{name:'fas fa-question-circle',code:'\uf059'},
{name:'fas fa-registered',code:'\uf25d'},
{name:'fas fa-sad-cry',code:'\uf5b3'},
{name:'fas fa-sad-tear',code:'\uf5b4'},
{name:'fas fa-save',code:'\uf0c7'},
{name:'fas fa-share-square',code:'\uf14d'},
{name:'fas fa-smile',code:'\uf118'},
{name:'fas fa-smile-beam',code:'\uf5b8'},
{name:'fas fa-smile-wink',code:'\uf4da'},
{name:'fas fa-snowflake',code:'\uf2dc'},
{name:'fas fa-square',code:'\uf0c8'},
{name:'fas fa-star',code:'\uf005'},
{name:'fas fa-star-half',code:'\uf089'},
{name:'fas fa-sticky-note',code:'\uf249'},
{name:'fas fa-stop-circle',code:'\uf28d'},
{name:'fas fa-sun',code:'\uf185'},
{name:'fas fa-surprise',code:'\uf5c2'},
{name:'fas fa-thumbs-down',code:'\uf165'},
{name:'fas fa-thumbs-up',code:'\uf164'},
{name:'fas fa-times-circle',code:'\uf057'},
{name:'fas fa-tired',code:'\uf5c8'},
{name:'fas fa-trash-alt',code:'\uf2ed'},
{name:'fas fa-user',code:'\uf007'},
{name:'fas fa-user-circle',code:'\uf2bd'},
{name:'fas fa-window-close',code:'\uf410'},
{name:'fas fa-window-maximize',code:'\uf2d0'},
{name:'fas fa-window-minimize',code:'\uf2d1'},
{name:'fas fa-window-restore',code:'\uf2d2'},

{name:'fas fa-500px',code:'\uf26e'},
{name:'fas fa-accessible-icon',code:'\uf368'},
{name:'fas fa-accusoft',code:'\uf369'},
{name:'fas fa-acquisitions-incorporated',code:'\uf6af'},
{name:'fas fa-adn',code:'\uf170'},
{name:'fas fa-adobe',code:'\uf778'},
{name:'fas fa-adversal',code:'\uf36a'},
{name:'fas fa-affiliatetheme',code:'\uf36b'},
{name:'fas fa-airbnb',code:'\uf834'},
{name:'fas fa-algolia',code:'\uf36c'},
{name:'fas fa-alipay',code:'\uf642'},
{name:'fas fa-amazon',code:'\uf270'},
{name:'fas fa-amazon-pay',code:'\uf42c'},
{name:'fas fa-amilia',code:'\uf36d'},
{name:'fas fa-android',code:'\uf17b'},
{name:'fas fa-angellist',code:'\uf209'},
{name:'fas fa-angrycreative',code:'\uf36e'},
{name:'fas fa-angular',code:'\uf420'},
{name:'fas fa-app-store',code:'\uf36f'},
{name:'fas fa-app-store-ios',code:'\uf370'},
{name:'fas fa-apper',code:'\uf371'},
{name:'fas fa-apple',code:'\uf179'},
{name:'fas fa-apple-pay',code:'\uf415'},
{name:'fas fa-artstation',code:'\uf77a'},
{name:'fas fa-asymmetrik',code:'\uf372'},
{name:'fas fa-atlassian',code:'\uf77b'},
{name:'fas fa-audible',code:'\uf373'},
{name:'fas fa-autoprefixer',code:'\uf41c'},
{name:'fas fa-avianex',code:'\uf374'},
{name:'fas fa-aviato',code:'\uf421'},
{name:'fas fa-aws',code:'\uf375'},
{name:'fas fa-bandcamp',code:'\uf2d5'},
{name:'fas fa-battle-net',code:'\uf835'},
{name:'fas fa-behance',code:'\uf1b4'},
{name:'fas fa-behance-square',code:'\uf1b5'},
{name:'fas fa-bimobject',code:'\uf378'},
{name:'fas fa-bitbucket',code:'\uf171'},
{name:'fas fa-bitcoin',code:'\uf379'},
{name:'fas fa-bity',code:'\uf37a'},
{name:'fas fa-black-tie',code:'\uf27e'},
{name:'fas fa-blackberry',code:'\uf37b'},
{name:'fas fa-blogger',code:'\uf37c'},
{name:'fas fa-blogger-b',code:'\uf37d'},
{name:'fas fa-bluetooth',code:'\uf293'},
{name:'fas fa-bluetooth-b',code:'\uf294'},
{name:'fas fa-bootstrap',code:'\uf836'},
{name:'fas fa-btc',code:'\uf15a'},
{name:'fas fa-buffer',code:'\uf837'},
{name:'fas fa-buromobelexperte',code:'\uf37f'},
{name:'fas fa-buy-n-large',code:'\uf8a6'},
{name:'fas fa-buysellads',code:'\uf20d'},
{name:'fas fa-canadian-maple-leaf',code:'\uf785'},
{name:'fas fa-cc-amazon-pay',code:'\uf42d'},
{name:'fas fa-cc-amex',code:'\uf1f3'},
{name:'fas fa-cc-apple-pay',code:'\uf416'},
{name:'fas fa-cc-diners-club',code:'\uf24c'},
{name:'fas fa-cc-discover',code:'\uf1f2'},
{name:'fas fa-cc-jcb',code:'\uf24b'},
{name:'fas fa-cc-mastercard',code:'\uf1f1'},
{name:'fas fa-cc-paypal',code:'\uf1f4'},
{name:'fas fa-cc-stripe',code:'\uf1f5'},
{name:'fas fa-cc-visa',code:'\uf1f0'},
{name:'fas fa-centercode',code:'\uf380'},
{name:'fas fa-centos',code:'\uf789'},
{name:'fas fa-chrome',code:'\uf268'},
{name:'fas fa-chromecast',code:'\uf838'},
{name:'fas fa-cloudscale',code:'\uf383'},
{name:'fas fa-cloudsmith',code:'\uf384'},
{name:'fas fa-cloudversify',code:'\uf385'},
{name:'fas fa-codepen',code:'\uf1cb'},
{name:'fas fa-codiepie',code:'\uf284'},
{name:'fas fa-confluence',code:'\uf78d'},
{name:'fas fa-connectdevelop',code:'\uf20e'},
{name:'fas fa-contao',code:'\uf26d'},
{name:'fas fa-cotton-bureau',code:'\uf89e'},
{name:'fas fa-cpanel',code:'\uf388'},
{name:'fas fa-creative-commons',code:'\uf25e'},
{name:'fas fa-creative-commons-by',code:'\uf4e7'},
{name:'fas fa-creative-commons-nc',code:'\uf4e8'},
{name:'fas fa-creative-commons-nc-eu',code:'\uf4e9'},
{name:'fas fa-creative-commons-nc-jp',code:'\uf4ea'},
{name:'fas fa-creative-commons-nd',code:'\uf4eb'},
{name:'fas fa-creative-commons-pd',code:'\uf4ec'},
{name:'fas fa-creative-commons-pd-alt',code:'\uf4ed'},
{name:'fas fa-creative-commons-remix',code:'\uf4ee'},
{name:'fas fa-creative-commons-sa',code:'\uf4ef'},
{name:'fas fa-creative-commons-sampling',code:'\uf4f0'},
{name:'fas fa-creative-commons-sampling-plus',code:'\uf4f1'},
{name:'fas fa-creative-commons-share',code:'\uf4f2'},
{name:'fas fa-creative-commons-zero',code:'\uf4f3'},
{name:'fas fa-critical-role',code:'\uf6c9'},
{name:'fas fa-css3',code:'\uf13c'},
{name:'fas fa-css3-alt',code:'\uf38b'},
{name:'fas fa-cuttlefish',code:'\uf38c'},
{name:'fas fa-d-and-d',code:'\uf38d'},
{name:'fas fa-d-and-d-beyond',code:'\uf6ca'},
{name:'fas fa-dailymotion',code:'\uf952'},
{name:'fas fa-dashcube',code:'\uf210'},
{name:'fas fa-deezer',code:'\uf977'},
{name:'fas fa-delicious',code:'\uf1a5'},
{name:'fas fa-deploydog',code:'\uf38e'},
{name:'fas fa-deskpro',code:'\uf38f'},
{name:'fas fa-dev',code:'\uf6cc'},
{name:'fas fa-deviantart',code:'\uf1bd'},
{name:'fas fa-dhl',code:'\uf790'},
{name:'fas fa-diaspora',code:'\uf791'},
{name:'fas fa-digg',code:'\uf1a6'},
{name:'fas fa-digital-ocean',code:'\uf391'},
{name:'fas fa-discord',code:'\uf392'},
{name:'fas fa-discourse',code:'\uf393'},
{name:'fas fa-dochub',code:'\uf394'},
{name:'fas fa-docker',code:'\uf395'},
{name:'fas fa-draft2digital',code:'\uf396'},
{name:'fas fa-dribbble',code:'\uf17d'},
{name:'fas fa-dribbble-square',code:'\uf397'},
{name:'fas fa-dropbox',code:'\uf16b'},
{name:'fas fa-drupal',code:'\uf1a9'},
{name:'fas fa-dyalog',code:'\uf399'},
{name:'fas fa-earlybirds',code:'\uf39a'},
{name:'fas fa-ebay',code:'\uf4f4'},
{name:'fas fa-edge',code:'\uf282'},
{name:'fas fa-edge-legacy',code:'\uf978'},
{name:'fas fa-elementor',code:'\uf430'},
{name:'fas fa-ello',code:'\uf5f1'},
{name:'fas fa-ember',code:'\uf423'},
{name:'fas fa-empire',code:'\uf1d1'},
{name:'fas fa-envira',code:'\uf299'},
{name:'fas fa-erlang',code:'\uf39d'},
{name:'fas fa-ethereum',code:'\uf42e'},
{name:'fas fa-etsy',code:'\uf2d7'},
{name:'fas fa-evernote',code:'\uf839'},
{name:'fas fa-expeditedssl',code:'\uf23e'},
{name:'fas fa-facebook',code:'\uf09a'},
{name:'fas fa-facebook-f',code:'\uf39e'},
{name:'fas fa-facebook-messenger',code:'\uf39f'},
{name:'fas fa-facebook-square',code:'\uf082'},
{name:'fas fa-fantasy-flight-games',code:'\uf6dc'},
{name:'fas fa-fedex',code:'\uf797'},
{name:'fas fa-fedora',code:'\uf798'},
{name:'fas fa-figma',code:'\uf799'},
{name:'fas fa-firefox',code:'\uf269'},
{name:'fas fa-firefox-browser',code:'\uf907'},
{name:'fas fa-first-order',code:'\uf2b0'},
{name:'fas fa-first-order-alt',code:'\uf50a'},
{name:'fas fa-firstdraft',code:'\uf3a1'},
{name:'fas fa-flickr',code:'\uf16e'},
{name:'fas fa-flipboard',code:'\uf44d'},
{name:'fas fa-fly',code:'\uf417'},
{name:'fas fa-font-awesome',code:'\uf2b4'},
{name:'fas fa-font-awesome-alt',code:'\uf35c'},
{name:'fas fa-font-awesome-flag',code:'\uf425'},
{name:'fas fa-fonticons',code:'\uf280'},
{name:'fas fa-fonticons-fi',code:'\uf3a2'},
{name:'fas fa-fort-awesome',code:'\uf286'},
{name:'fas fa-fort-awesome-alt',code:'\uf3a3'},
{name:'fas fa-forumbee',code:'\uf211'},
{name:'fas fa-foursquare',code:'\uf180'},
{name:'fas fa-free-code-camp',code:'\uf2c5'},
{name:'fas fa-freebsd',code:'\uf3a4'},
{name:'fas fa-fulcrum',code:'\uf50b'},
{name:'fas fa-galactic-republic',code:'\uf50c'},
{name:'fas fa-galactic-senate',code:'\uf50d'},
{name:'fas fa-get-pocket',code:'\uf265'},
{name:'fas fa-gg',code:'\uf260'},
{name:'fas fa-gg-circle',code:'\uf261'},
{name:'fas fa-git',code:'\uf1d3'},
{name:'fas fa-git-alt',code:'\uf841'},
{name:'fas fa-git-square',code:'\uf1d2'},
{name:'fas fa-github',code:'\uf09b'},
{name:'fas fa-github-alt',code:'\uf113'},
{name:'fas fa-github-square',code:'\uf092'},
{name:'fas fa-gitkraken',code:'\uf3a6'},
{name:'fas fa-gitlab',code:'\uf296'},
{name:'fas fa-gitter',code:'\uf426'},
{name:'fas fa-glide',code:'\uf2a5'},
{name:'fas fa-glide-g',code:'\uf2a6'},
{name:'fas fa-gofore',code:'\uf3a7'},
{name:'fas fa-goodreads',code:'\uf3a8'},
{name:'fas fa-goodreads-g',code:'\uf3a9'},
{name:'fas fa-google',code:'\uf1a0'},
{name:'fas fa-google-drive',code:'\uf3aa'},
{name:'fas fa-google-pay',code:'\uf979'},
{name:'fas fa-google-play',code:'\uf3ab'},
{name:'fas fa-google-plus',code:'\uf2b3'},
{name:'fas fa-google-plus-g',code:'\uf0d5'},
{name:'fas fa-google-plus-square',code:'\uf0d4'},
{name:'fas fa-google-wallet',code:'\uf1ee'},
{name:'fas fa-gratipay',code:'\uf184'},
{name:'fas fa-grav',code:'\uf2d6'},
{name:'fas fa-gripfire',code:'\uf3ac'},
{name:'fas fa-grunt',code:'\uf3ad'},
{name:'fas fa-gulp',code:'\uf3ae'},
{name:'fas fa-hacker-news',code:'\uf1d4'},
{name:'fas fa-hacker-news-square',code:'\uf3af'},
{name:'fas fa-hackerrank',code:'\uf5f7'},
{name:'fas fa-hips',code:'\uf452'},
{name:'fas fa-hire-a-helper',code:'\uf3b0'},
{name:'fas fa-hooli',code:'\uf427'},
{name:'fas fa-hornbill',code:'\uf592'},
{name:'fas fa-hotjar',code:'\uf3b1'},
{name:'fas fa-houzz',code:'\uf27c'},
{name:'fas fa-html5',code:'\uf13b'},
{name:'fas fa-hubspot',code:'\uf3b2'},
{name:'fas fa-ideal',code:'\uf913'},
{name:'fas fa-imdb',code:'\uf2d8'},
{name:'fas fa-instagram',code:'\uf16d'},
{name:'fas fa-instagram-square',code:'\uf955'},
{name:'fas fa-intercom',code:'\uf7af'},
{name:'fas fa-internet-explorer',code:'\uf26b'},
{name:'fas fa-invision',code:'\uf7b0'},
{name:'fas fa-ioxhost',code:'\uf208'},
{name:'fas fa-itch-io',code:'\uf83a'},
{name:'fas fa-itunes',code:'\uf3b4'},
{name:'fas fa-itunes-note',code:'\uf3b5'},
{name:'fas fa-java',code:'\uf4e4'},
{name:'fas fa-jedi-order',code:'\uf50e'},
{name:'fas fa-jenkins',code:'\uf3b6'},
{name:'fas fa-jira',code:'\uf7b1'},
{name:'fas fa-joget',code:'\uf3b7'},
{name:'fas fa-joomla',code:'\uf1aa'},
{name:'fas fa-js',code:'\uf3b8'},
{name:'fas fa-js-square',code:'\uf3b9'},
{name:'fas fa-jsfiddle',code:'\uf1cc'},
{name:'fas fa-kaggle',code:'\uf5fa'},
{name:'fas fa-keybase',code:'\uf4f5'},
{name:'fas fa-keycdn',code:'\uf3ba'},
{name:'fas fa-kickstarter',code:'\uf3bb'},
{name:'fas fa-kickstarter-k',code:'\uf3bc'},
{name:'fas fa-korvue',code:'\uf42f'},
{name:'fas fa-laravel',code:'\uf3bd'},
{name:'fas fa-lastfm',code:'\uf202'},
{name:'fas fa-lastfm-square',code:'\uf203'},
{name:'fas fa-leanpub',code:'\uf212'},
{name:'fas fa-less',code:'\uf41d'},
{name:'fas fa-line',code:'\uf3c0'},
{name:'fas fa-linkedin',code:'\uf08c'},
{name:'fas fa-linkedin-in',code:'\uf0e1'},
{name:'fas fa-linode',code:'\uf2b8'},
{name:'fas fa-linux',code:'\uf17c'},
{name:'fas fa-lyft',code:'\uf3c3'},
{name:'fas fa-magento',code:'\uf3c4'},
{name:'fas fa-mailchimp',code:'\uf59e'},
{name:'fas fa-mandalorian',code:'\uf50f'},
{name:'fas fa-markdown',code:'\uf60f'},
{name:'fas fa-mastodon',code:'\uf4f6'},
{name:'fas fa-maxcdn',code:'\uf136'},
{name:'fas fa-mdb',code:'\uf8ca'},
{name:'fas fa-medapps',code:'\uf3c6'},
{name:'fas fa-medium',code:'\uf23a'},
{name:'fas fa-medium-m',code:'\uf3c7'},
{name:'fas fa-medrt',code:'\uf3c8'},
{name:'fas fa-meetup',code:'\uf2e0'},
{name:'fas fa-megaport',code:'\uf5a3'},
{name:'fas fa-mendeley',code:'\uf7b3'},
{name:'fas fa-microblog',code:'\uf91a'},
{name:'fas fa-microsoft',code:'\uf3ca'},
{name:'fas fa-mix',code:'\uf3cb'},
{name:'fas fa-mixcloud',code:'\uf289'},
{name:'fas fa-mixer',code:'\uf956'},
{name:'fas fa-mizuni',code:'\uf3cc'},
{name:'fas fa-modx',code:'\uf285'},
{name:'fas fa-monero',code:'\uf3d0'},
{name:'fas fa-napster',code:'\uf3d2'},
{name:'fas fa-neos',code:'\uf612'},
{name:'fas fa-nimblr',code:'\uf5a8'},
{name:'fas fa-node',code:'\uf419'},
{name:'fas fa-node-js',code:'\uf3d3'},
{name:'fas fa-npm',code:'\uf3d4'},
{name:'fas fa-ns8',code:'\uf3d5'},
{name:'fas fa-nutritionix',code:'\uf3d6'},
{name:'fas fa-odnoklassniki',code:'\uf263'},
{name:'fas fa-odnoklassniki-square',code:'\uf264'},
{name:'fas fa-old-republic',code:'\uf510'},
{name:'fas fa-opencart',code:'\uf23d'},
{name:'fas fa-openid',code:'\uf19b'},
{name:'fas fa-opera',code:'\uf26a'},
{name:'fas fa-optin-monster',code:'\uf23c'},
{name:'fas fa-orcid',code:'\uf8d2'},
{name:'fas fa-osi',code:'\uf41a'},
{name:'fas fa-page4',code:'\uf3d7'},
{name:'fas fa-pagelines',code:'\uf18c'},
{name:'fas fa-palfed',code:'\uf3d8'},
{name:'fas fa-patreon',code:'\uf3d9'},
{name:'fas fa-paypal',code:'\uf1ed'},
{name:'fas fa-penny-arcade',code:'\uf704'},
{name:'fas fa-periscope',code:'\uf3da'},
{name:'fas fa-phabricator',code:'\uf3db'},
{name:'fas fa-phoenix-framework',code:'\uf3dc'},
{name:'fas fa-phoenix-squadron',code:'\uf511'},
{name:'fas fa-php',code:'\uf457'},
{name:'fas fa-pied-piper',code:'\uf2ae'},
{name:'fas fa-pied-piper-alt',code:'\uf1a8'},
{name:'fas fa-pied-piper-hat',code:'\uf4e5'},
{name:'fas fa-pied-piper-pp',code:'\uf1a7'},
{name:'fas fa-pied-piper-square',code:'\uf91e'},
{name:'fas fa-pinterest',code:'\uf0d2'},
{name:'fas fa-pinterest-p',code:'\uf231'},
{name:'fas fa-pinterest-square',code:'\uf0d3'},
{name:'fas fa-playstation',code:'\uf3df'},
{name:'fas fa-product-hunt',code:'\uf288'},
{name:'fas fa-pushed',code:'\uf3e1'},
{name:'fas fa-python',code:'\uf3e2'},
{name:'fas fa-qq',code:'\uf1d6'},
{name:'fas fa-quinscape',code:'\uf459'},
{name:'fas fa-quora',code:'\uf2c4'},
{name:'fas fa-r-project',code:'\uf4f7'},
{name:'fas fa-raspberry-pi',code:'\uf7bb'},
{name:'fas fa-ravelry',code:'\uf2d9'},
{name:'fas fa-react',code:'\uf41b'},
{name:'fas fa-reacteurope',code:'\uf75d'},
{name:'fas fa-readme',code:'\uf4d5'},
{name:'fas fa-rebel',code:'\uf1d0'},
{name:'fas fa-red-river',code:'\uf3e3'},
{name:'fas fa-reddit',code:'\uf1a1'},
{name:'fas fa-reddit-alien',code:'\uf281'},
{name:'fas fa-reddit-square',code:'\uf1a2'},
{name:'fas fa-redhat',code:'\uf7bc'},
{name:'fas fa-renren',code:'\uf18b'},
{name:'fas fa-replyd',code:'\uf3e6'},
{name:'fas fa-researchgate',code:'\uf4f8'},
{name:'fas fa-resolving',code:'\uf3e7'},
{name:'fas fa-rev',code:'\uf5b2'},
{name:'fas fa-rocketchat',code:'\uf3e8'},
{name:'fas fa-rockrms',code:'\uf3e9'},
{name:'fas fa-rust',code:'\uf97a'},
{name:'fas fa-safari',code:'\uf267'},
{name:'fas fa-salesforce',code:'\uf83b'},
{name:'fas fa-sass',code:'\uf41e'},
{name:'fas fa-schlix',code:'\uf3ea'},
{name:'fas fa-scribd',code:'\uf28a'},
{name:'fas fa-searchengin',code:'\uf3eb'},
{name:'fas fa-sellcast',code:'\uf2da'},
{name:'fas fa-sellsy',code:'\uf213'},
{name:'fas fa-servicestack',code:'\uf3ec'},
{name:'fas fa-shirtsinbulk',code:'\uf214'},
{name:'fas fa-shopify',code:'\uf957'},
{name:'fas fa-shopware',code:'\uf5b5'},
{name:'fas fa-simplybuilt',code:'\uf215'},
{name:'fas fa-sistrix',code:'\uf3ee'},
{name:'fas fa-sith',code:'\uf512'},
{name:'fas fa-sketch',code:'\uf7c6'},
{name:'fas fa-skyatlas',code:'\uf216'},
{name:'fas fa-skype',code:'\uf17e'},
{name:'fas fa-slack',code:'\uf198'},
{name:'fas fa-slack-hash',code:'\uf3ef'},
{name:'fas fa-slideshare',code:'\uf1e7'},
{name:'fas fa-snapchat',code:'\uf2ab'},
{name:'fas fa-snapchat-ghost',code:'\uf2ac'},
{name:'fas fa-snapchat-square',code:'\uf2ad'},
{name:'fas fa-soundcloud',code:'\uf1be'},
{name:'fas fa-sourcetree',code:'\uf7d3'},
{name:'fas fa-speakap',code:'\uf3f3'},
{name:'fas fa-speaker-deck',code:'\uf83c'},
{name:'fas fa-spotify',code:'\uf1bc'},
{name:'fas fa-squarespace',code:'\uf5be'},
{name:'fas fa-stack-exchange',code:'\uf18d'},
{name:'fas fa-stack-overflow',code:'\uf16c'},
{name:'fas fa-stackpath',code:'\uf842'},
{name:'fas fa-staylinked',code:'\uf3f5'},
{name:'fas fa-steam',code:'\uf1b6'},
{name:'fas fa-steam-square',code:'\uf1b7'},
{name:'fas fa-steam-symbol',code:'\uf3f6'},
{name:'fas fa-sticker-mule',code:'\uf3f7'},
{name:'fas fa-strava',code:'\uf428'},
{name:'fas fa-stripe',code:'\uf429'},
{name:'fas fa-stripe-s',code:'\uf42a'},
{name:'fas fa-studiovinari',code:'\uf3f8'},
{name:'fas fa-stumbleupon',code:'\uf1a4'},
{name:'fas fa-stumbleupon-circle',code:'\uf1a3'},
{name:'fas fa-superpowers',code:'\uf2dd'},
{name:'fas fa-supple',code:'\uf3f9'},
{name:'fas fa-suse',code:'\uf7d6'},
{name:'fas fa-swift',code:'\uf8e1'},
{name:'fas fa-symfony',code:'\uf83d'},
{name:'fas fa-teamspeak',code:'\uf4f9'},
{name:'fas fa-telegram',code:'\uf2c6'},
{name:'fas fa-telegram-plane',code:'\uf3fe'},
{name:'fas fa-tencent-weibo',code:'\uf1d5'},
{name:'fas fa-the-red-yeti',code:'\uf69d'},
{name:'fas fa-themeco',code:'\uf5c6'},
{name:'fas fa-themeisle',code:'\uf2b2'},
{name:'fas fa-think-peaks',code:'\uf731'},
{name:'fas fa-tiktok',code:'\uf97b'},
{name:'fas fa-trade-federation',code:'\uf513'},
{name:'fas fa-trello',code:'\uf181'},
{name:'fas fa-tripadvisor',code:'\uf262'},
{name:'fas fa-tumblr',code:'\uf173'},
{name:'fas fa-tumblr-square',code:'\uf174'},
{name:'fas fa-twitch',code:'\uf1e8'},
{name:'fas fa-twitter',code:'\uf099'},
{name:'fas fa-twitter-square',code:'\uf081'},
{name:'fas fa-typo3',code:'\uf42b'},
{name:'fas fa-uber',code:'\uf402'},
{name:'fas fa-ubuntu',code:'\uf7df'},
{name:'fas fa-uikit',code:'\uf403'},
{name:'fas fa-umbraco',code:'\uf8e8'},
{name:'fas fa-uniregistry',code:'\uf404'},
{name:'fas fa-unity',code:'\uf949'},
{name:'fas fa-unsplash',code:'\uf97c'},
{name:'fas fa-untappd',code:'\uf405'},
{name:'fas fa-ups',code:'\uf7e0'},
{name:'fas fa-usb',code:'\uf287'},
{name:'fas fa-usps',code:'\uf7e1'},
{name:'fas fa-ussunnah',code:'\uf407'},
{name:'fas fa-vaadin',code:'\uf408'},
{name:'fas fa-viacoin',code:'\uf237'},
{name:'fas fa-viadeo',code:'\uf2a9'},
{name:'fas fa-viadeo-square',code:'\uf2aa'},
{name:'fas fa-viber',code:'\uf409'},
{name:'fas fa-vimeo',code:'\uf40a'},
{name:'fas fa-vimeo-square',code:'\uf194'},
{name:'fas fa-vimeo-v',code:'\uf27d'},
{name:'fas fa-vine',code:'\uf1ca'},
{name:'fas fa-vk',code:'\uf189'},
{name:'fas fa-vnv',code:'\uf40b'},
{name:'fas fa-vuejs',code:'\uf41f'},
{name:'fas fa-waze',code:'\uf83f'},
{name:'fas fa-weebly',code:'\uf5cc'},
{name:'fas fa-weibo',code:'\uf18a'},
{name:'fas fa-weixin',code:'\uf1d7'},
{name:'fas fa-whatsapp',code:'\uf232'},
{name:'fas fa-whatsapp-square',code:'\uf40c'},
{name:'fas fa-whmcs',code:'\uf40d'},
{name:'fas fa-wikipedia-w',code:'\uf266'},
{name:'fas fa-windows',code:'\uf17a'},
{name:'fas fa-wix',code:'\uf5cf'},
{name:'fas fa-wizards-of-the-coast',code:'\uf730'},
{name:'fas fa-wolf-pack-battalion',code:'\uf514'},
{name:'fas fa-wordpress',code:'\uf19a'},
{name:'fas fa-wordpress-simple',code:'\uf411'},
{name:'fas fa-wpbeginner',code:'\uf297'},
{name:'fas fa-wpexplorer',code:'\uf2de'},
{name:'fas fa-wpforms',code:'\uf298'},
{name:'fas fa-wpressr',code:'\uf3e4'},
{name:'fas fa-xbox',code:'\uf412'},
{name:'fas fa-xing',code:'\uf168'},
{name:'fas fa-xing-square',code:'\uf169'},
{name:'fas fa-y-combinator',code:'\uf23b'},
{name:'fas fa-yahoo',code:'\uf19e'},
{name:'fas fa-yammer',code:'\uf840'},
{name:'fas fa-yandex',code:'\uf413'},
{name:'fas fa-yandex-international',code:'\uf414'},
{name:'fas fa-yarn',code:'\uf7e3'},
{name:'fas fa-yelp',code:'\uf1e9'},
{name:'fas fa-yoast',code:'\uf2b1'},
{name:'fas fa-youtube',code:'\uf167'},
{name:'fas fa-youtube-square',code:'\uf431'},
{name:'fas fa-zhihu',code:'\uf63f'}
          ],
          newIcons: [
                "fab fa-500px",
                "fab fa-accessible-icon",
                "fab fa-accusoft",
                "fas fa-address-book", "far fa-address-book",
                "fas fa-address-card", "far fa-address-card",
                "fas fa-adjust",
                "fab fa-adn",
                "fab fa-adversal",
                "fab fa-affiliatetheme",
                "fab fa-algolia",
                "fas fa-align-center",
                "fas fa-align-justify",
                "fas fa-align-left",
                "fas fa-align-right",
                "fab fa-amazon",
                "fas fa-ambulance",
                "fas fa-american-sign-language-interpreting",
                "fab fa-amilia",
                "fas fa-anchor",
                "fab fa-android",
                "fab fa-angellist",
                "fas fa-angle-double-down",
                "fas fa-angle-double-left",
                "fas fa-angle-double-right",
                "fas fa-angle-double-up",
                "fas fa-angle-down",
                "fas fa-angle-left",
                "fas fa-angle-right",
                "fas fa-angle-up",
                "fab fa-angrycreative",
                "fab fa-angular",
                "fab fa-app-store",
                "fab fa-app-store-ios",
                "fab fa-apper",
                "fab fa-apple",
                "fab fa-apple-pay",
                "fas fa-archive",
                "fas fa-arrow-alt-circle-down", "far fa-arrow-alt-circle-down",
                "fas fa-arrow-alt-circle-left", "far fa-arrow-alt-circle-left",
                "fas fa-arrow-alt-circle-right", "far fa-arrow-alt-circle-right",
                "fas fa-arrow-alt-circle-up", "far fa-arrow-alt-circle-up",
                "fas fa-arrow-circle-down",
                "fas fa-arrow-circle-left",
                "fas fa-arrow-circle-right",
                "fas fa-arrow-circle-up",
                "fas fa-arrow-down",
                "fas fa-arrow-left",
                "fas fa-arrow-right",
                "fas fa-arrow-up",
                "fas fa-arrows-alt",
                "fas fa-arrows-alt-h",
                "fas fa-arrows-alt-v",
                "fas fa-assistive-listening-systems",
                "fas fa-asterisk",
                "fab fa-asymmetrik",
                "fas fa-at",
                "fab fa-audible",
                "fas fa-audio-description",
                "fab fa-autoprefixer",
                "fab fa-avianex",
                "fab fa-aviato",
                "fab fa-aws",
                "fas fa-backward",
                "fas fa-balance-scale",
                "fas fa-ban",
                "fab fa-bandcamp",
                "fas fa-barcode",
                "fas fa-bars",
                "fas fa-bath",
                "fas fa-battery-empty",
                "fas fa-battery-full",
                "fas fa-battery-half",
                "fas fa-battery-quarter",
                "fas fa-battery-three-quarters",
                "fas fa-bed",
                "fas fa-beer",
                "fab fa-behance",
                "fab fa-behance-square",
                "fas fa-bell", "far fa-bell",
                "fas fa-bell-slash", "far fa-bell-slash",
                "fas fa-bicycle",
                "fab fa-bimobject",
                "fas fa-binoculars",
                "fas fa-birthday-cake",
                "fab fa-bitbucket",
                "fab fa-bitcoin",
                "fab fa-bity",
                "fab fa-black-tie",
                "fab fa-blackberry",
                "fas fa-blind",
                "fab fa-blogger",
                "fab fa-blogger-b",
                "fab fa-bluetooth",
                "fab fa-bluetooth-b",
                "fas fa-bold",
                "fas fa-bolt",
                "fas fa-bomb",
                "fas fa-book",
                "fas fa-bookmark", "far fa-bookmark",
                "fas fa-braille",
                "fas fa-briefcase",
                "fab fa-btc",
                "fas fa-bug",
                "fas fa-building", "far fa-building",
                "fas fa-bullhorn",
                "fas fa-bullseye",
                "fab fa-buromobelexperte",
                "fas fa-bus",
                "fab fa-buysellads",
                "fas fa-calculator",
                "fas fa-calendar", "far fa-calendar",
                "fas fa-calendar-alt", "far fa-calendar-alt",
                "fas fa-calendar-check", "far fa-calendar-check",
                "fas fa-calendar-minus", "far fa-calendar-minus",
                "fas fa-calendar-plus", "far fa-calendar-plus",
                "fas fa-calendar-times", "far fa-calendar-times",
                "fas fa-camera",
                "fas fa-camera-retro",
                "fas fa-car",
                "fas fa-caret-down",
                "fas fa-caret-left",
                "fas fa-caret-right",
                "fas fa-caret-square-down", "far fa-caret-square-down",
                "fas fa-caret-square-left", "far fa-caret-square-left",
                "fas fa-caret-square-right", "far fa-caret-square-right",
                "fas fa-caret-square-up", "far fa-caret-square-up",
                "fas fa-caret-up",
                "fas fa-cart-arrow-down",
                "fas fa-cart-plus",
                "fab fa-cc-amex",
                "fab fa-cc-apple-pay",
                "fab fa-cc-diners-club",
                "fab fa-cc-discover",
                "fab fa-cc-jcb",
                "fab fa-cc-mastercard",
                "fab fa-cc-paypal",
                "fab fa-cc-stripe",
                "fab fa-cc-visa",
                "fab fa-centercode",
                "fas fa-certificate",
                "fas fa-chart-area",
                "fas fa-chart-bar", "far fa-chart-bar",
                "fas fa-chart-line",
                "fas fa-chart-pie",
                "fas fa-check",
                "fas fa-check-circle", "far fa-check-circle",
                "fas fa-check-square", "far fa-check-square",
                "fas fa-chevron-circle-down",
                "fas fa-chevron-circle-left",
                "fas fa-chevron-circle-right",
                "fas fa-chevron-circle-up",
                "fas fa-chevron-down",
                "fas fa-chevron-left",
                "fas fa-chevron-right",
                "fas fa-chevron-up",
                "fas fa-child",
                "fab fa-chrome",
                "fas fa-circle", "far fa-circle",
                "fas fa-circle-notch",
                "fas fa-clipboard", "far fa-clipboard",
                "fas fa-clock", "far fa-clock",
                "fas fa-clone", "far fa-clone",
                "fas fa-closed-captioning", "far fa-closed-captioning",
                "fas fa-cloud",
                "fas fa-cloud-download-alt",
                "fas fa-cloud-upload-alt",
                "fab fa-cloudscale",
                "fab fa-cloudsmith",
                "fab fa-cloudversify",
                "fas fa-code",
                "fas fa-code-branch",
                "fab fa-codepen",
                "fab fa-codiepie",
                "fas fa-coffee",
                "fas fa-cog",
                "fas fa-cogs",
                "fas fa-columns",
                "fas fa-comment", "far fa-comment",
                "fas fa-comment-alt", "far fa-comment-alt",
                "fas fa-comments", "far fa-comments",
                "fas fa-compass", "far fa-compass",
                "fas fa-compress",
                "fab fa-connectdevelop",
                "fab fa-contao",
                "fas fa-copy", "far fa-copy",
                "fas fa-copyright", "far fa-copyright",
                "fab fa-cpanel",
                "fab fa-creative-commons",
                "fas fa-credit-card", "far fa-credit-card",
                "fas fa-crop",
                "fas fa-crosshairs",
                "fab fa-css3",
                "fab fa-css3-alt",
                "fas fa-cube",
                "fas fa-cubes",
                "fas fa-cut",
                "fab fa-cuttlefish",
                "fab fa-d-and-d",
                "fab fa-dashcube",
                "fas fa-database",
                "fas fa-deaf",
                "fab fa-delicious",
                "fab fa-deploydog",
                "fab fa-deskpro",
                "fas fa-desktop",
                "fab fa-deviantart",
                "fab fa-digg",
                "fab fa-digital-ocean",
                "fab fa-discord",
                "fab fa-discourse",
                "fab fa-dochub",
                "fab fa-docker",
                "fas fa-dollar-sign",
                "fas fa-dot-circle", "far fa-dot-circle",
                "fas fa-download",
                "fab fa-draft2digital",
                "fab fa-dribbble",
                "fab fa-dribbble-square",
                "fab fa-dropbox",
                "fab fa-drupal",
                "fab fa-dyalog",
                "fab fa-earlybirds",
                "fab fa-edge",
                "fas fa-edit", "far fa-edit",
                "fas fa-eject",
                "fas fa-ellipsis-h",
                "fas fa-ellipsis-v",
                "fab fa-ember",
                "fab fa-empire",
                "fas fa-envelope", "far fa-envelope",
                "fas fa-envelope-open", "far fa-envelope-open",
                "fas fa-envelope-square",
                "fab fa-envira",
                "fas fa-eraser",
                "fab fa-erlang",
                "fab fa-etsy",
                "fas fa-euro-sign",
                "fas fa-exchange-alt",
                "fas fa-exclamation",
                "fas fa-exclamation-circle",
                "fas fa-exclamation-triangle",
                "fas fa-expand",
                "fas fa-expand-arrows-alt",
                "fab fa-expeditedssl",
                "fas fa-external-link-alt",
                "fas fa-external-link-square-alt",
                "fas fa-eye",
                "fas fa-eye-dropper",
                "fas fa-eye-slash", "far fa-eye-slash",
                "fab fa-facebook",
                "fab fa-facebook-f",
                "fab fa-facebook-messenger",
                "fab fa-facebook-square",
                "fas fa-fast-backward",
                "fas fa-fast-forward",
                "fas fa-fax",
                "fas fa-female",
                "fas fa-fighter-jet",
                "fas fa-file", "far fa-file",
                "fas fa-file-alt", "far fa-file-alt",
                "fas fa-file-archive", "far fa-file-archive",
                "fas fa-file-audio", "far fa-file-audio",
                "fas fa-file-code", "far fa-file-code",
                "fas fa-file-excel", "far fa-file-excel",
                "fas fa-file-image", "far fa-file-image",
                "fas fa-file-pdf", "far fa-file-pdf",
                "fas fa-file-powerpoint", "far fa-file-powerpoint",
                "fas fa-file-video", "far fa-file-video",
                "fas fa-file-word", "far fa-file-word",
                "fas fa-film",
                "fas fa-filter",
                "fas fa-fire",
                "fas fa-fire-extinguisher",
                "fab fa-firefox",
                "fab fa-first-order",
                "fab fa-firstdraft",
                "fas fa-flag", "far fa-flag",
                "fas fa-flag-checkered",
                "fas fa-flask",
                "fab fa-flickr",
                "fab fa-fly",
                "fas fa-folder", "far fa-folder",
                "fas fa-folder-open", "far fa-folder-open",
                "fas fa-font",
                "fab fa-font-awesome",
                "fab fa-font-awesome-alt",
                "fab fa-font-awesome-flag",
                "fab fa-fonticons",
                "fab fa-fonticons-fi",
                "fab fa-fort-awesome",
                "fab fa-fort-awesome-alt",
                "fab fa-forumbee",
                "fas fa-forward",
                "fab fa-foursquare",
                "fab fa-free-code-camp",
                "fab fa-freebsd",
                "fas fa-frown", "far fa-frown",
                "fas fa-futbol", "far fa-futbol",
                "fas fa-gamepad",
                "fas fa-gavel",
                "fas fa-gem", "far fa-gem",
                "fas fa-genderless",
                "fab fa-get-pocket",
                "fab fa-gg",
                "fab fa-gg-circle",
                "fas fa-gift",
                "fab fa-git",
                "fab fa-git-square",
                "fab fa-github",
                "fab fa-github-alt",
                "fab fa-github-square",
                "fab fa-gitkraken",
                "fab fa-gitlab",
                "fab fa-gitter",
                "fas fa-glass-martini",
                "fab fa-glide",
                "fab fa-glide-g",
                "fas fa-globe",
                "fab fa-gofore",
                "fab fa-goodreads",
                "fab fa-goodreads-g",
                "fab fa-google",
                "fab fa-google-drive",
                "fab fa-google-play",
                "fab fa-google-plus",
                "fab fa-google-plus-g",
                "fab fa-google-plus-square",
                "fab fa-google-wallet",
                "fas fa-graduation-cap",
                "fab fa-gratipay",
                "fab fa-grav",
                "fab fa-gripfire",
                "fab fa-grunt",
                "fab fa-gulp",
                "fas fa-h-square",
                "fab fa-hacker-news",
                "fab fa-hacker-news-square",
                "fas fa-hand-lizard", "far fa-hand-lizard",
                "fas fa-hand-paper", "far fa-hand-paper",
                "fas fa-hand-peace", "far fa-hand-peace",
                "fas fa-hand-point-down", "far fa-hand-point-down",
                "fas fa-hand-point-left", "far fa-hand-point-left",
                "fas fa-hand-point-right", "far fa-hand-point-right",
                "fas fa-hand-point-up", "far fa-hand-point-up",
                "fas fa-hand-pointer", "far fa-hand-pointer",
                "fas fa-hand-rock", "far fa-hand-rock",
                "fas fa-hand-scissors", "far fa-hand-scissors",
                "fas fa-hand-spock", "far fa-hand-spock",
                "fas fa-handshake", "far fa-handshake",
                "fas fa-hashtag",
                "fas fa-hdd", "far fa-hdd",
                "fas fa-heading",
                "fas fa-headphones",
                "fas fa-heart", "far fa-heart",
                "fas fa-heartbeat",
                "fab fa-hire-a-helper",
                "fas fa-history",
                "fas fa-home",
                "fab fa-hooli",
                "fas fa-hospital", "far fa-hospital",
                "fab fa-hotjar",
                "fas fa-hourglass", "far fa-hourglass",
                "fas fa-hourglass-end",
                "fas fa-hourglass-half",
                "fas fa-hourglass-start",
                "fab fa-houzz",
                "fab fa-html5",
                "fab fa-hubspot",
                "fas fa-i-cursor",
                "fas fa-id-badge", "far fa-id-badge",
                "fas fa-id-card", "far fa-id-card",
                "fas fa-image", "far fa-image",
                "fas fa-images", "far fa-images",
                "fab fa-imdb",
                "fas fa-inbox",
                "fas fa-indent",
                "fas fa-industry",
                "fas fa-info",
                "fas fa-info-circle",
                "fab fa-instagram",
                "fab fa-internet-explorer",
                "fab fa-ioxhost",
                "fas fa-italic",
                "fab fa-itunes",
                "fab fa-itunes-note",
                "fab fa-jenkins",
                "fab fa-joget",
                "fab fa-joomla",
                "fab fa-js",
                "fab fa-js-square",
                "fab fa-jsfiddle",
                "fas fa-key",
                "fas fa-keyboard", "far fa-keyboard",
                "fab fa-keycdn",
                "fab fa-kickstarter",
                "fab fa-kickstarter-k",
                "fas fa-language",
                "fas fa-laptop",
                "fab fa-laravel",
                "fab fa-lastfm",
                "fab fa-lastfm-square",
                "fas fa-leaf",
                "fab fa-leanpub",
                "fas fa-lemon", "far fa-lemon",
                "fab fa-less",
                "fas fa-level-down-alt",
                "fas fa-level-up-alt",
                "fas fa-life-ring", "far fa-life-ring",
                "fas fa-lightbulb", "far fa-lightbulb",
                "fab fa-line",
                "fas fa-link",
                "fab fa-linkedin",
                "fab fa-linkedin-in",
                "fab fa-linode",
                "fab fa-linux",
                "fas fa-lira-sign",
                "fas fa-list",
                "fas fa-list-alt", "far fa-list-alt",
                "fas fa-list-ol",
                "fas fa-list-ul",
                "fas fa-location-arrow",
                "fas fa-lock",
                "fas fa-lock-open",
                "fas fa-long-arrow-alt-down",
                "fas fa-long-arrow-alt-left",
                "fas fa-long-arrow-alt-right",
                "fas fa-long-arrow-alt-up",
                "fas fa-low-vision",
                "fab fa-lyft",
                "fab fa-magento",
                "fas fa-magic",
                "fas fa-magnet",
                "fas fa-male",
                "fas fa-map", "far fa-map",
                "fas fa-map-marker",
                "fas fa-map-marker-alt",
                "fas fa-map-pin",
                "fas fa-map-signs",
                "fas fa-mars",
                "fas fa-mars-double",
                "fas fa-mars-stroke",
                "fas fa-mars-stroke-h",
                "fas fa-mars-stroke-v",
                "fab fa-maxcdn",
                "fab fa-medapps",
                "fab fa-medium",
                "fab fa-medium-m",
                "fas fa-medkit",
                "fab fa-medrt",
                "fab fa-meetup",
                "fas fa-meh", "far fa-meh",
                "fas fa-mercury",
                "fas fa-microchip",
                "fas fa-microphone",
                "fas fa-microphone-slash",
                "fab fa-microsoft",
                "fas fa-minus",
                "fas fa-minus-circle",
                "fas fa-minus-square", "far fa-minus-square",
                "fab fa-mix",
                "fab fa-mixcloud",
                "fab fa-mizuni",
                "fas fa-mobile",
                "fas fa-mobile-alt",
                "fab fa-modx",
                "fab fa-monero",
                "fas fa-money-bill-alt", "far fa-money-bill-alt",
                "fas fa-moon", "far fa-moon",
                "fas fa-motorcycle",
                "fas fa-mouse-pointer",
                "fas fa-music",
                "fab fa-napster",
                "fas fa-neuter",
                "fas fa-newspaper", "far fa-newspaper",
                "fab fa-nintendo-switch",
                "fab fa-node",
                "fab fa-node-js",
                "fab fa-npm",
                "fab fa-ns8",
                "fab fa-nutritionix",
                "fas fa-object-group", "far fa-object-group",
                "fas fa-object-ungroup", "far fa-object-ungroup",
                "fab fa-odnoklassniki",
                "fab fa-odnoklassniki-square",
                "fab fa-opencart",
                "fab fa-openid",
                "fab fa-opera",
                "fab fa-optin-monster",
                "fab fa-osi",
                "fas fa-outdent",
                "fab fa-page4",
                "fab fa-pagelines",
                "fas fa-paint-brush",
                "fab fa-palfed",
                "fas fa-paper-plane", "far fa-paper-plane",
                "fas fa-paperclip",
                "fas fa-paragraph",
                "fas fa-paste",
                "fab fa-patreon",
                "fas fa-pause",
                "fas fa-pause-circle", "far fa-pause-circle",
                "fas fa-paw",
                "fab fa-paypal",
                "fas fa-pen-square",
                "fas fa-pencil-alt",
                "fas fa-percent",
                "fab fa-periscope",
                "fab fa-phabricator",
                "fab fa-phoenix-framework",
                "fas fa-phone",
                "fas fa-phone-square",
                "fas fa-phone-volume",
                "fab fa-pied-piper",
                "fab fa-pied-piper-alt",
                "fab fa-pied-piper-pp",
                "fab fa-pinterest",
                "fab fa-pinterest-p",
                "fab fa-pinterest-square",
                "fas fa-plane",
                "fas fa-play",
                "fas fa-play-circle", "far fa-play-circle",
                "fab fa-playstation",
                "fas fa-plug",
                "fas fa-plus",
                "fas fa-plus-circle",
                "fas fa-plus-square", "far fa-plus-square",
                "fas fa-podcast",
                "fas fa-pound-sign",
                "fas fa-power-off",
                "fas fa-print",
                "fab fa-product-hunt",
                "fab fa-pushed",
                "fas fa-puzzle-piece",
                "fab fa-python",
                "fab fa-qq",
                "fas fa-qrcode",
                "fas fa-question",
                "fas fa-question-circle", "far fa-question-circle",
                "fab fa-quora",
                "fas fa-quote-left",
                "fas fa-quote-right",
                "fas fa-random",
                "fab fa-ravelry",
                "fab fa-react",
                "fab fa-rebel",
                "fas fa-recycle",
                "fab fa-red-river",
                "fab fa-reddit",
                "fab fa-reddit-alien",
                "fab fa-reddit-square",
                "fas fa-redo",
                "fas fa-redo-alt",
                "fas fa-registered", "far fa-registered",
                "fab fa-rendact",
                "fab fa-renren",
                "fas fa-reply",
                "fas fa-reply-all",
                "fab fa-replyd",
                "fab fa-resolving",
                "fas fa-retweet",
                "fas fa-road",
                "fas fa-rocket",
                "fab fa-rocketchat",
                "fab fa-rockrms",
                "fas fa-rss",
                "fas fa-rss-square",
                "fas fa-ruble-sign",
                "fas fa-rupee-sign",
                "fab fa-safari",
                "fab fa-sass",
                "fas fa-save", "far fa-save",
                "fab fa-schlix",
                "fab fa-scribd",
                "fas fa-search",
                "fas fa-search-minus",
                "fas fa-search-plus",
                "fab fa-searchengin",
                "fab fa-sellcast",
                "fab fa-sellsy",
                "fas fa-server",
                "fab fa-servicestack",
                "fas fa-share",
                "fas fa-share-alt",
                "fas fa-share-alt-square",
                "fas fa-share-square", "far fa-share-square",
                "fas fa-shekel-sign",
                "fas fa-shield-alt",
                "fas fa-ship",
                "fab fa-shirtsinbulk",
                "fas fa-shopping-bag",
                "fas fa-shopping-basket",
                "fas fa-shopping-cart",
                "fas fa-shower",
                "fas fa-sign-in-alt",
                "fas fa-sign-language",
                "fas fa-sign-out-alt",
                "fas fa-signal",
                "fab fa-simplybuilt",
                "fab fa-sistrix",
                "fas fa-sitemap",
                "fab fa-skyatlas",
                "fab fa-skype",
                "fab fa-slack",
                "fab fa-slack-hash",
                "fas fa-sliders-h",
                "fab fa-slideshare",
                "fas fa-smile", "far fa-smile",
                "fab fa-snapchat",
                "fab fa-snapchat-ghost",
                "fab fa-snapchat-square",
                "fas fa-snowflake", "far fa-snowflake",
                "fas fa-sort",
                "fas fa-sort-alpha-down",
                "fas fa-sort-alpha-up",
                "fas fa-sort-amount-down",
                "fas fa-sort-amount-up",
                "fas fa-sort-down",
                "fas fa-sort-numeric-down",
                "fas fa-sort-numeric-up",
                "fas fa-sort-up",
                "fab fa-soundcloud",
                "fas fa-space-shuttle",
                "fab fa-speakap",
                "fas fa-spinner",
                "fab fa-spotify",
                "fas fa-square", "far fa-square",
                "fab fa-stack-exchange",
                "fab fa-stack-overflow",
                "fas fa-star", "far fa-star",
                "fas fa-star-half", "far fa-star-half",
                "fab fa-staylinked",
                "fab fa-steam",
                "fab fa-steam-square",
                "fab fa-steam-symbol",
                "fas fa-step-backward",
                "fas fa-step-forward",
                "fas fa-stethoscope",
                "fab fa-sticker-mule",
                "fas fa-sticky-note", "far fa-sticky-note",
                "fas fa-stop",
                "fas fa-stop-circle", "far fa-stop-circle",
                "fab fa-strava",
                "fas fa-street-view",
                "fas fa-strikethrough",
                "fab fa-stripe",
                "fab fa-stripe-s",
                "fab fa-studiovinari",
                "fab fa-stumbleupon",
                "fab fa-stumbleupon-circle",
                "fas fa-subscript",
                "fas fa-subway",
                "fas fa-suitcase",
                "fas fa-sun", "far fa-sun",
                "fab fa-superpowers",
                "fas fa-superscript",
                "fab fa-supple",
                "fas fa-sync",
                "fas fa-sync-alt",
                "fas fa-table",
                "fas fa-tablet",
                "fas fa-tablet-alt",
                "fas fa-tachometer-alt",
                "fas fa-tag",
                "fas fa-tags",
                "fas fa-tasks",
                "fas fa-taxi",
                "fab fa-telegram",
                "fab fa-telegram-plane",
                "fab fa-tencent-weibo",
                "fas fa-terminal",
                "fas fa-text-height",
                "fas fa-text-width",
                "fas fa-th",
                "fas fa-th-large",
                "fas fa-th-list",
                "fab fa-themeisle",
                "fas fa-thermometer-empty",
                "fas fa-thermometer-full",
                "fas fa-thermometer-half",
                "fas fa-thermometer-quarter",
                "fas fa-thermometer-three-quarters",
                "fas fa-thumbs-down", "far fa-thumbs-down",
                "fas fa-thumbs-up", "far fa-thumbs-up",
                "fas fa-thumbtack",
                "fas fa-ticket-alt",
                "fas fa-times",
                "fas fa-times-circle", "far fa-times-circle",
                "fas fa-tint",
                "fas fa-toggle-off",
                "fas fa-toggle-on",
                "fas fa-trademark",
                "fas fa-train",
                "fas fa-transgender",
                "fas fa-transgender-alt",
                "fas fa-trash",
                "fas fa-trash-alt", "far fa-trash-alt",
                "fas fa-tree",
                "fab fa-trello",
                "fab fa-tripadvisor",
                "fas fa-trophy",
                "fas fa-truck",
                "fas fa-tty",
                "fab fa-tumblr",
                "fab fa-tumblr-square",
                "fas fa-tv",
                "fab fa-twitch",
                "fab fa-twitter",
                "fab fa-twitter-square",
                "fab fa-typo3",
                "fab fa-uber",
                "fab fa-uikit",
                "fas fa-umbrella",
                "fas fa-underline",
                "fas fa-undo",
                "fas fa-undo-alt",
                "fab fa-uniregistry",
                "fas fa-universal-access",
                "fas fa-university",
                "fas fa-unlink",
                "fas fa-unlock",
                "fas fa-unlock-alt",
                "fab fa-untappd",
                "fas fa-upload",
                "fab fa-usb",
                "fas fa-user", "far fa-user",
                "fas fa-user-circle", "far fa-user-circle",
                "fas fa-user-md",
                "fas fa-user-plus",
                "fas fa-user-secret",
                "fas fa-user-times",
                "fas fa-users",
                "fab fa-ussunnah",
                "fas fa-utensil-spoon",
                "fas fa-utensils",
                "fab fa-vaadin",
                "fas fa-venus",
                "fas fa-venus-double",
                "fas fa-venus-mars",
                "fab fa-viacoin",
                "fab fa-viadeo",
                "fab fa-viadeo-square",
                "fab fa-viber",
                "fas fa-video",
                "fab fa-vimeo",
                "fab fa-vimeo-square",
                "fab fa-vimeo-v",
                "fab fa-vine",
                "fab fa-vk",
                "fab fa-vnv",
                "fas fa-volume-down",
                "fas fa-volume-off",
                "fas fa-volume-up",
                "fab fa-vuejs",
                "fab fa-weibo",
                "fab fa-weixin",
                "fab fa-whatsapp",
                "fab fa-whatsapp-square",
                "fas fa-wheelchair",
                "fab fa-whmcs",
                "fas fa-wifi",
                "fab fa-wikipedia-w",
                "fas fa-window-close", "far fa-window-close",
                "fas fa-window-maximize", "far fa-window-maximize",
                "fas fa-window-minimize",
                "fas fa-window-restore", "far fa-window-restore",
                "fab fa-windows",
                "fas fa-won-sign",
                "fab fa-wordpress",
                "fab fa-wordpress-simple",
                "fab fa-wpbeginner",
                "fab fa-wpexplorer",
                "fab fa-wpforms",
                "fas fa-wrench",
                "fab fa-xbox",
                "fab fa-xing",
                "fab fa-xing-square",
                "fab fa-y-combinator",
                "fab fa-yahoo",
                "fab fa-yandex",
                "fab fa-yandex-international",
                "fab fa-yelp",
                "fas fa-yen-sign",
                "fab fa-yoast",
                "fab fa-youtube"
          ]
        };
        let accessToken = localStorage.getItem("accessToken");
        this.setState({
          accessToken: accessToken,
        });
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeStatusLine = this.handleChangeStatusLine.bind(this);
        this.onSlctTym = this.onSlctTym.bind(this);
        this.onSlctDate = this.onSlctDate.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.btnTapped = this.btnTapped.bind(this);
        this.addFav = this.addFav.bind(this);
        this.loadMyCharts = this.loadMyCharts.bind(this);
        this.handleAlertClick = this.handleAlertClick.bind(this);
        this.searchIcon = this.searchIcon.bind(this);
        this.itemSelected = this.itemSelected.bind(this);
        this.insertCallout = this.insertCallout.bind(this);
        this.undoClick = this.undoClick.bind(this);
        this.redoClick = this.redoClick.bind(this);
        this.showTool = this.showTool.bind(this);
        this.insertBalloon = this.insertBalloon.bind(this);
        this.insertArrowLeft = this.insertArrowLeft.bind(this);
        this.insertArrowRight = this.insertArrowRight.bind(this);
        this.insertArrowUp = this.insertArrowUp.bind(this);
        this.insertArrowDown = this.insertArrowDown.bind(this);
        this.insertFlagMark = this.insertFlagMark.bind(this);
        this.insertArrowMark = this.insertArrowMark.bind(this);
        this.insertCrossHair = this.insertCrossHair.bind(this);
        this.insertTextCallout = this.insertTextCallout.bind(this);
        this.insertTrendLine = this.insertTrendLine.bind(this);

        this.handler = this.handler.bind(this)
      } 
      handler() {
        //alert('1')
        function randomIntFromInterval(min,max) { // min and max included 
          return Math.floor(Math.random() * (max - min + 1) + min)
        }  
        var get_random_number = randomIntFromInterval(1,100); 
        this.setState({
          get_indicator_prop_data: get_random_number
        }) 
      }
      getStockValue = async(symbol, stock, time, mult, startd, endd) => {
        try {
          const res = await axios.get(
            `${CONSTANT.API_BASE}/agg/${stock}/${symbol}/${mult}/${time}/${startd}/${endd}?limit=${this.state.range_limit}&apikey=${process.env.REACT_APP_FINAGE_API_KEY}`
          );
  
          
          var response= res.data.results;
          console.log("symbol response in open chart is",res.data.results)
          // console.log(typeof response)
          var finalArray=new Array();
          var obj = {};
  
          response.forEach((value, index) => {
            // console.log('Index: ' + index + ' Value: ' + value.h);
            finalArray.push({
                "absoluteChange":"",
                "close":value.c,
                "date":"Mon Jan 04 2010 00:00:00 GMT+0530 (India Standard Time)",
                "dividend":"",
                "high": value.h,
                "low":value.l,
                "open":value.o,
                "percentChange":"",
                "split":"",
                "volume":value.v
              })
             
            if(index==response.length){
              //finalArray.push({columns:  ['date', 'open', 'high', 'low', 'close', 'volume', 'split', 'dividend', 'absoluteChange', 'percentChange']})
            }
            finalArray['columns']=['date', 'open', 'high', 'low', 'close', 'volume', 'split', 'dividend', 'absoluteChange', 'percentChange'];
        });
          console.log("yaha result aya",response)
          this.setState({renkoData:finalArray})
      
           
        } catch (err) {
           
        }
      };

    handleThemeChange(e) { 
      const checked = e.target.checked; 
      if (checked) {
        localStorage.setItem("theme_dark", 0);
        document.body.classList.add('theme-dark9');
  
        this.setState({
          themeCheck: 1
        })
  
      } else {
  
        localStorage.setItem("theme_dark", 1);
        document.body.classList.remove('theme-dark9');
  
        this.setState({
          themeCheck: 0
        })
      }
  
      let accessToekn = localStorage.getItem('accessToken');
      var authData = 'Token ' + accessToekn;
  
      var thm = this.state.themeCheck;
  
      axios.post(`https://stock-trading-backend.itechnolabs.tech/update-theme/`,
        { theme_dark: this.state.themeCheck },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: authData
          }
        },
  
      )
        .then((response) => {
  
          toast.success('Theme changed', {
            autoClose: 3000,
          });
  
  
        }).catch((error) => {
  
        });
  
    }
    getResult = async () => {
       
      console.log('hhhsjsjkhjkhjk');
      this.getStockValue(
          this.state.slctSymbol,
          this.state.stock,
          this.state.timespan,
          this.state.multiplier,
          this.state.startD,
          this.state.endD
        )
    }

  insertArrowLeft = (e) => {
      this.setState({
          count : this.state.count + 1
      });
      this.setState({ arrowLeft: true });
  }

  insertTextCallout = (e) => {
      this.setState({
          count : this.state.count + 1
      });
      this.setState({ textCallout: true });
  }

  insertArrowRight = (e) => {
      this.setState({
          count : this.state.count + 1
      });
      this.setState({ arrowRight: true });
  }

  insertArrowUp = (e) => {
      this.setState({
          count : this.state.count + 1
      });
      this.setState({ arrowUp: true });
  }

  insertArrowDown = (e) => {
      this.setState({
          count : this.state.count + 1
      });
      this.setState({ arrowDown: true });
  }

  insertFlagMark = (e) => {
      this.setState({
          count : this.state.count + 1
      });
      this.setState({ flagMark: true });
  }

  insertArrowMark = (e) => {
      this.setState({
          count : this.state.count + 1
      });
      this.setState({ arrowMark: true });
  }

  insertArrowLeft = (e) => { 
      this.setState({
          count : this.state.count + 1
      });
      this.setState({ arrowLeft: true });
  }
  showTool = (e) => { 
    if(this.state.showTool == 3){
      this.setState({ showTool: true });
    }else{
      this.setState({ showTool: !this.state.showTool });
    }
  }

  insertCallout = (e) => {  
      this.setState({
          count : this.state.count + 1
      });
      this.setState({ callout: true });
  }

  insertBalloon = (e) => {     
      this.setState({
          count : this.state.count + 1
      });

      this.setState({ balloon: true });
  }

  insertCrossHair = (e) => {     
      this.setState({
          count : this.state.count + 1
      });

      this.setState({ crossHair: true });
  }

  insertTrendLine = (e) => {     
      this.setState({
          count : this.state.count + 1
      });
      this.setState({ trendLine: true });
  }

  itemSelected(item,code,icontype='icon') {
    
    localStorage.setItem("seticon",code);
    localStorage.setItem("icontype",icontype);
    
    $("#container").css('cursor','crosshair');
    setTimeout(function(){
    $("#container").css('cursor','default');
      localStorage.setItem("seticon",'unset');
      localStorage.setItem("icontype",'unset');
    },5000)
    
    // alert(JSON.stringify(window.getComputedStyle(e.target,'::before').content));
    // var content= 
      // this.setState({
      //     count : this.state.count + 1
      // });
      // this.setState({ markerPoints: item });
  }

  btnTapped = (value) => () => {

    getData().then(data => {
      //console.log('value',value);
      // var dd=
      this.setState({ data })
      this.setState({ graphType: value });
    })
  };
  
getWatchlist()
{

  let accessToekn = localStorage.getItem('accessToken');
  var authData = 'Token ' + accessToekn;


  axios.get(constants.API_URL+'/watchlist/',
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: authData
      }
    },

  )
    .then((response) => {

      this.setState({list_watchlist:response.data.data});
      if(response.data.data.length>0)
      {
        if(this.state.watchlist_id!=0)
        {
          this.getWatchlistDetail(response.state.selected_watchlist);
        }
        else
        {
        this.setState({selected_watchlist:response.data.data[0].id,watchlist_id:response.data.data[0].id});

          this.getWatchlistDetail(response.data.data[0]);
        }
      }


    }).catch((error) => {
      console.log("error", error);
    });
}

getWatchlistDetail(watchlist)
{
  this.setState({selected_watchlist:watchlist});
  this.setState({watchlist_id:watchlist.id});
  let accessToekn = localStorage.getItem('accessToken');
  var authData = 'Token ' + accessToekn;


  axios.get(constants.API_URL+'/watchlist/'+watchlist.id+'/',
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: authData
      }
    },

  ).then((response) => {
       this.setState({list_watchlist_items:[]})
      if(response.data.item_counts>0)
      {
        for(var i=0;i<response.data.items.length;i++)
        {
          this.getLastPriceDetail(response.data.items[i].symbol,response.data.items[i].id);
        }
      }
    }).catch((error) => {
      console.log("error", error);
    });
}


getLastPriceDetail(symbol,id)
{

  axios
    .get('https://api.finage.co.uk/last/crypto/detailed/'+symbol+'?apikey='+process.env.REACT_APP_FINAGE_API_KEY, {
      headers: {
        "Content-Type": "application/json"
      }
    })
    .then((response) => {
      var obj={
        "symbol":response.data.symbol,
        "name":response.data.name,
        "price":response.data.price,
        "change":response.data.change,
        "changePercentage":response.data.changesPercentage,
        "id":id,
      };
    
      this.setState({
        list_watchlist_items:[...this.state.list_watchlist_items,obj]
      });
        

    }).catch((error) => {
      console.log("error", error);
    });
}

  addFav = (value) => (e) => {
      var arr = localStorage.getItem("favChart");

      arr = arr ? arr.split(",") : [];

      var newId = value; //new id

      if (!arr.includes(newId)) {
        //checking weather array contain the id
        arr.push(newId); //adding to array because value doesnt exists
        this.setState({ isActive: arr });
      } else {
        arr.splice(arr.indexOf(newId), 1); //deleting
        this.setState({ isActive: arr });
      }
      localStorage.removeItem("favChart");

      localStorage.setItem("favChart", arr);

      let element = document.getElementById(e.target.id);
  };

  addFavTemplates = (value) => (e) => {
      var arr4 = localStorage.getItem("favTemplates");
      arr4 = arr4 ? arr4.split(",") : [];

      var newId = value; //new id

      if (!arr4.includes(newId)) {
        //checking weather array contain the id
        arr4.push(newId); //adding to array because value doesnt exists
        this.setState({ templatesActive: arr4 });
      } else {
        arr4.splice(arr4.indexOf(newId), 1); //deleting
        this.setState({ templatesActive: arr4 });
      }
      localStorage.removeItem("favTemplates");
      localStorage.setItem("favTemplates", arr4);
  };

  handleClick = (value) => () => {
      this.setState({ graphType: value });
  };

  handleValidation() {
  
      let errors = {};
      let formIsValid = true;

      //Name
      if (!this.state.cond_name) {
        formIsValid = false;
        errors["cond_name"] = "Condition Cannot be empty";
      }
      if (!this.state.cond_check) {
        formIsValid = false;
        errors["cond_check"] = "Condition Method Cannot be empty";
      }
      if (!this.state.option) {
        formIsValid = false;
        errors["option"] = "Option Cannot be empty";
      }
      if (!this.state.condTime) {
        formIsValid = false;
        errors["condTime"] = "Time Cannot be empty";
      }
      if (!this.state.condDate) {
        formIsValid = false;
        errors["condDate"] = "Date Cannot be empty";
      }
      if (this.state.cond_value <= 0) {
        formIsValid = false;
        errors["cond_value"] = "Condition Value should not be less than 0";
      }

      if (this.state.action_method?.length == 0) {
        formIsValid = false;
        errors["action_method"] = "Please choose atleast one method";
      }
      if (!this.state.alert_name) {
        formIsValid = false;
        errors["alert_name"] = "Alert Heading Cannot be empty";
      }
      if (!this.state.alert_msg) {
        formIsValid = false;
        errors["alert_msg"] = "Alert Message Cannot be empty";
      }
      this.setState({ errors: errors });
      return formIsValid;
  }
 redoClick() {

    if (this.state.redoOperations.length != 0) {

      if (this.state.redoOperations.operation_type == 'graph') {

        let action = {
          operation_type: 'graph',
          graph_value: this.state.redoOperations.graph_value
        };

        // var operationsPerformed = this.state.operations;
        // operationsPerformed.push(action);
        // this.setState({ operations: operationsPerformed });


        this.btnTapped(this.state.redoOperations.graph_value, true);

      } else {

        if (this.state.redoOperations.operation_type == 'time') {

          let action = {
            operation_type: 'time',
            multiplier: this.state.redoOperations.multiplier,
            timespan: this.state.redoOperations.timespan
          };

          this.onSlctTym(this.state.redoOperations.multiplier, this.state.redoOperations.timespan, true);
        }

      }

      this.setState({
        redoOperations: {}
      })

      //for (var member in this.state.redoOperations) delete this.state.redoOperations[member];

    } else {
      alert('Nothing to Redo');
    }

  }

  undoClick() {

    var lengthCurrent = this.state.operations.length;
    var currentVal = Object.values(this.state.operations)[lengthCurrent - 2];
    var redoValues = Object.values(this.state.operations)[lengthCurrent - 1];

    if (currentVal != undefined) {

      this.setState({
        currentIndex: lengthCurrent - 2
      })


      if (redoValues.operation_type == 'graph') {

        let action = {
          operation_type: 'graph',
          graph_value: redoValues.graph_value
        };


        this.setState({ redoOperations: action }, () => {
          // alert(JSON.stringify(this.state.redoOperations));
        });

      } else {

        if (redoValues.operation_type == 'time') {

          let action = {
            operation_type: 'time',
            multiplier: redoValues.multiplier,
            timespan: redoValues.timespan
          };


          this.setState({ redoOperations: action }, () => {
            //alert('hello' + JSON.stringify(this.state.redoOperations));
          });

        }

      }

      if (currentVal.operation_type == 'graph') {

        //             let action = {
        //                 operation_type: 'graph' , 
        //                 graph_value: currentVal.graph_value
        //             };


        //               this.setState({ redoOperations: action }, () => {
        //   alert(JSON.stringify(this.state.redoOperations));
        // }); 


        delete this.state.operations[lengthCurrent - 2];

        let filteredPeople = this.state.operations.filter((item) => item.index !== lengthCurrent - 2);

        this.setState({ operations: filteredPeople });

        this.btnTapped(currentVal.graph_value, false);

      } else {
        if (currentVal.operation_type == 'time') {

          delete this.state.operations[lengthCurrent - 2];

          let filteredPeople = this.state.operations.filter((item) => item.index !== lengthCurrent - 2);

          this.setState({ operations: filteredPeople });
          this.onSlctTym(currentVal.multiplier, currentVal.timespan, false);

        }
      }

    } else {
        alert('Nothing to show on undo');
    }

  }

  onLogout(e) {
      e.preventDefault();
      let accessToken = localStorage.getItem("accessToken");
      authService
      .logout({}, accessToken)
      .then((response) => {
        console.log("res", response);
        if (response.status === 200) {
          toast.success("Logout Successfull.", {
              autoClose: 6000,
          });
          localStorage.clear();
          window.location.href = `${window.location.origin}/login`;
        }
      })
      .catch((error) => {
        if (
          error.response.data.error !== "" &&
          error.response.data.error !== undefined &&
          error.response.data.error !== null
        ) {
          toast.error(error.response.data.error, {
            autoClose: 6000,
          });
        }

        if (
          error.response.data.detail !== undefined &&
          error.response.data.detail !== null &&
          error.response.data.detail !== ""
        ) {
          if (error.response.data.detail === "Invalid token.") {
            this.setState({ accessToken: null });
            toast.error(error.response.data.detail + "Please login.", {
              autoClose: 6000,
            });
            window.location.href = `${window.location.origin}/login`;
          }
        }
      });
  }


  linkSharing(e) {

    if (this.state.linkSharinge == 0) {

        this.setState({ linkSharinge: 1 }, () => {
                
                let accessToekn = localStorage.getItem('accessToken');
                var authData = 'Token ' + accessToekn;

                let chartFilter = { linkSharinge: this.state.linkSharinge, multiplier: this.state.multiplier, span: this.state.timespan, grid: this.state.gridShow, startDate: this.state.startD, endDate: this.state.end };

                axios.put(`https://stock-trading-backend.itechnolabs.tech/chart-layout/` + this.state.chart_id + `/`,
                  { names: this.state.names, symbol: this.state.slctSymbol, graph_type: this.state.graphType, chart_filter: JSON.stringify(chartFilter) },
                  {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: authData
                    }
                  },

                )
                .then((response) => {

                  let stm = 'Changed Mode';

                  if (this.state.linkSharinge == 1) {

                    stm += 'to sharing enabled';

                  }
                  else {
                    stm += 'sharing disabled';
                  }

                  toast.success(stm);


                }).catch((error) => {
                  console.log("error", error);
                });
            }); 

        


    } else {

        if (this.state.linkSharinge == 1) {

            this.setState({ linkSharinge: 0 }, () => {
                

                let accessToekn = localStorage.getItem('accessToken');
                var authData = 'Token ' + accessToekn;

                let chartFilter = { linkSharinge: this.state.linkSharinge, multiplier: this.state.multiplier, span: this.state.timespan, grid: this.state.gridShow, startDate: this.state.startD, endDate: this.state.end };

                axios.put(`https://stock-trading-backend.itechnolabs.tech/chart-layout/` + this.state.chart_id + `/`,
                  { names: this.state.names, symbol: this.state.slctSymbol, graph_type: this.state.graphType, chart_filter: JSON.stringify(chartFilter) },
                  {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: authData
                    }
                  },

                )
                .then((response) => {

                  let stm = 'Changed Mode';

                  if (this.state.linkSharinge == 1) {

                    stm += 'to sharing enabled';

                  }
                  else {
                    stm += 'sharing disabled';
                  }

                  toast.success(stm);


                }).catch((error) => {
                  console.log("error", error);
                });
            }); 

           

        }

    }

  }

  handleAutoSaveCheck(e) {



    // if (this.state.linkSharinge == 0) {

    //     this.setState({ linkSharinge: 1 }, () => {
                
    //             let accessToekn = localStorage.getItem('accessToken');
    //             var authData = 'Token ' + accessToekn;

    //             let chartFilter = { linkSharinge: this.state.linkSharinge, multiplier: this.state.multiplier, span: this.state.timespan, grid: this.state.gridShow, startDate: this.state.startD, endDate: this.state.end };

    //             axios.put(`https://stock-trading-backend.itechnolabs.tech/chart-layout/` + this.state.chart_id + `/`,
    //               { names: this.state.names, symbol: this.state.slctSymbol, graph_type: this.state.graphType, chart_filter: JSON.stringify(chartFilter) },
    //               {
    //                 headers: {
    //                   "Content-Type": "application/json",
    //                   Authorization: authData
    //                 }
    //               },

    //             )
    //             .then((response) => {

    //               let stm = 'Changed Mode';

    //               if (this.state.linkSharinge == 1) {

    //                 stm += 'to sharing enabled';

    //               }
    //               else {
    //                 stm += 'sharing disabled';
    //               }

    //               toast.success(stm);


    //             }).catch((error) => {
    //               console.log("error", error);
    //             });
    //         }); 

        


    // } else {

    //     if (this.state.linkSharinge == 1) {

    //         this.setState({ linkSharinge: 0 }, () => {
                

    //             let accessToekn = localStorage.getItem('accessToken');
    //             var authData = 'Token ' + accessToekn;

    //             let chartFilter = { linkSharinge: this.state.linkSharinge, multiplier: this.state.multiplier, span: this.state.timespan, grid: this.state.gridShow, startDate: this.state.startD, endDate: this.state.end };

    //             axios.put(`https://stock-trading-backend.itechnolabs.tech/chart-layout/` + this.state.chart_id + `/`,
    //               { names: this.state.names, symbol: this.state.slctSymbol, graph_type: this.state.graphType, chart_filter: JSON.stringify(chartFilter) },
    //               {
    //                 headers: {
    //                   "Content-Type": "application/json",
    //                   Authorization: authData
    //                 }
    //               },

    //             )
    //             .then((response) => {

    //               let stm = 'Changed Mode';

    //               if (this.state.linkSharinge == 1) {

    //                 stm += 'to sharing enabled';

    //               }
    //               else {
    //                 stm += 'sharing disabled';
    //               }

    //               toast.success(stm);


    //             }).catch((error) => {
    //               console.log("error", error);
    //             });
    //         }); 

           

    //     }

    // }

    // const checked = e.target.checked;

    // alert('checked '+ e.target.checked);

    // if (checked) {
    //   localStorage.setItem("is_auto_save", Boolean(true));
    //   this.setState({
    //     checkbox: Boolean(true)
    //   })
    // } else {

    //   localStorage.setItem("is_auto_save", Boolean(false));
    //   this.setState({
    //     checkbox: Boolean(false)
    //   })
    // }

    // let accessToekn = localStorage.getItem('accessToken');
    // var authData = 'Token ' + accessToekn;

    // axios.post(`https://stock-trading-backend.itechnolabs.tech/update-feature/`,
    //   { is_auto_save: this.state.checkbox },
    //   {
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: authData
    //     }
    //   },
    // )
    //   .then((response) => {

    //     toast.success('Autosave Status changed', {
    //       autoClose: 3000,
    //     });

    //   }).catch((error) => {

    //   });

  }
  handleAlertClick = () => {
      if (this.handleValidation()) {
          const time_stamp = moment(
            `${this.state.condDate} ${this.state.condTime}`,
            "YYYY-MM-DD HH:mm:ss"
          ).format("X");
          const action_methods = this.props
          .createAlertNotify(
            "Token " + localStorage.getItem("accessToken"),
            this.state.cond_name,
            this.state.cond_value,
            this.state.cond_check,
            this.state.option,
            time_stamp,
            this.state.action_method.toString(),
            this.state.alert_name,
            this.state.alert_msg,
            this.state.slctSymbol,
            this.state.web_hook_url,
          )
          .then(() => {
            if (this.props.stockValue.error == null) {
              alert("Your Alert has been created successfully");
            }
            document.getElementById("alertclose").click();
          });
      }
  };

  // onSlctTym(mult, time) {
  //     this.setState({
  //       multiplier: mult,
  //       timespan: time,
  //     });
  // }

  onSlctTym(mult, time, param,limit=1000) {

    this.setState({
      multiplier: mult,
      timespan: time,
      range_limit:limit
    }); 
    let action = {
      operation_type: 'time',
      multiplier: mult,
      timespan: time
    };

    if (param) {

      var operationsPerformed = this.state.operations;
      operationsPerformed.push(action);
      this.setState({ operations: operationsPerformed });

      this.setState({
        currentIndex: this.state.operations.length
      })

      // this.setState({ redoOperations : this.state.operations  })

    }

}

  handleGraphChange = (e) => { };

  visibleCanvaToDraw = (e) =>{ 
     
    let element = "<Canvas id='myCanvas' height='300' width='1144'></Canvas>"
    $(element).prependTo('.highcharts-container');
     
    //console.log(element)
    const c = document.getElementById("myCanvas");
    const ctx = c.getContext("2d"); 
    const r = 1; // draw radius
    ctx.lineWidth = r * 2;
    ctx.lineCap = "round";
    ctx.fillStyle = "black";
    var draw = false;
    var lineStart = true;
    var lastX, lastY, drawlastX, drawlastY;
    var x_val = [];
    var y_val = [];
    
    function yesDraw() { draw = true; lineStart = true }
    
    function mouseMove(e) { 
        //console.log(e)
        const bounds = c.getBoundingClientRect();
        const x = e.pageX - bounds.left - window.scrollX;
        const y = e.pageY - bounds.top - window.scrollY;
        if(draw && x > -r && x < c.width + r && y > -r && y < c.height + r){
          drawing(x,y);
        } 
    }

    function noDraw() { 
        draw = false 
        if(x_val != '' && y_val != ''){ 
            localStorage.setItem("x_coordinate", x_val);
            localStorage.setItem("y_coordinate", y_val);
        }
    }
    
    document.addEventListener("mousemove",mouseMove);
    document.addEventListener("mousedown",yesDraw);
    document.addEventListener("mouseup",noDraw);
    
    function drawing(x, y) {
          
        if(lineStart){

            lastX = x;
            lastY = y;
            lineStart = false;
        
        }
      
        ctx.beginPath();
        ctx.lineTo(lastX, lastY);
        ctx.lineTo(x, y);
        ctx.stroke();
        lastX = x;
        lastY = y;
        
        x_val.push(x);
        y_val.push(y);

    }


    function showDrawing(){ 
      let x_cord =  localStorage.getItem("x_coordinate").split(',');
      let y_cord = localStorage.getItem("y_coordinate").split(',');
      var draw_line_start = true;
      for(let count =0; count<x_cord?.length; count++){
   
          let x_data = x_cord[count];
          let y_data = y_cord[count];
  
          if(draw_line_start){
              drawlastX = x_data;
              drawlastY = y_data;
              draw_line_start = false;
          }
  
          ctx.beginPath();
          ctx.lineTo(drawlastX, drawlastY);
          ctx.lineTo(x_data, y_data);
          ctx.stroke();
          drawlastX = x_data;
          drawlastY = y_data; 
      }
    } 

    if(localStorage.getItem("x_coordinate") != null){ 
      if(localStorage.getItem("x_coordinate")?.length>0){
        showDrawing();
      }
    }

  };
    
  onSlctDate(start, end) {
      this.setState({
        startD: start,
        endD: end,
      });
  }

  isOpen = (val) => {
      this.setState({
        modalShow: val,
      });
  };

  passSymbol(value, name, stock) {
    var obj={
      "symbol_name":name,
      "symbol":value
    };
    localStorage.setItem("symbol_data",JSON.stringify(obj));

      this.setState({
        stock: stock,
        symbolName: name,
        slctSymbol: value,
        modalShow: false,
        newSymbol: [value],
      });
  }


 

  removSymbol(v) {
      var array = [...this.state.newSymbol];
      array.splice(v, 1);
      this.setState({ newSymbol: array });
  }

  compSymbol(v, n, s) {
      const existSymbol = this.state.newSymbol.includes(v);
      if (existSymbol) {
        alert("already Selected");
      } else {
        if (this.state.newSymbol?.length > 4) {
          alert("You can compare maximum 5 Symbols at a time");
        } else {
          this.setState({
            compareChart: true,
            newSymbol: [...this.state.newSymbol, v],
          });
          document.getElementById("compareClose").click();
        }
      }
  }

  handleChange = (e) => {
      //console.log(e.target.name, '000', e.target.value, 'eeeeeeee', e.target.getAttribute("values"));
      if (e.target.name != "option") {
        if (e.target.name == "cond_name") {

          this.setState({ [e.target.name]: e.target.value });
          this.setState({ cond_value: "133" });
        } else {

          this.setState({ [e.target.name]: e.target.value });
          this.setState({ cond_value: "" });
        }
      } else {
        this.setState({ [e.target.name]: e.target.getAttribute("values") });
      }
  };

  searchIcon = event => { 
      let iconsArr = this.state.icons;
      this.setState({
          icons: iconsArr.filter(iconsArr => iconsArr.includes(event.target.value))
      })
  }


  handleChangeStatusLine = (e) => {
      const { checked } = e.target
      this.setState({ [e.target.name]: checked });
  }

  //image realted parts
  statementChange(option){
    switch(option)
    {
      case "1":
        this.getIncomeStatement();
        break;
      case "2":
        this.getCashbookStatement();
        break;
      case "3":
        this.getCashflowStatement();
        break;
    }
  }
  setStatementType(type){
    this.setState({
      statement_type:type
    });
    var option=$("#dropStatement").val();
    this.statementChange(option);
  }
  tweetChart() {
      var htmlToImage = require("html-to-image");
      htmlToImage
      .toJpeg(document.getElementById("container"), { quality: 0.95 })
      .then(function (dataUrl) {
        axios
          .post(
            `https://stock-trading-backend.itechnolabs.tech/auth/save-sceenshot/`,
            { url: dataUrl }
          )
          .then((response) => {
            // alert(response.imgUrl);
            window.open(
              "http://twitter.com/share?text=Check out this chart&url=" +
              response.data.imgUrl,
              "_blank"
            );
          })
          .catch((error) => {
            //console.log("error", error);
          });
      });
  }

  handleCheckboxChange = (e) => {
      // alert(e.target.value)
      if (e.target.checked) {
        this.setState((prevState) => ({
          action_method: prevState.action_method.concat(e.target.value),
        }));
        if (e.target.value == 'web_hook_url') {
          this.setState({ set_webhook: true })

        }
      } else {
        if (e.target.value == 'web_hook_url') {
          this.setState({ set_webhook: false })

        }
        this.setState((prevState) => ({
          action_method: prevState.action_method.filter(
            (i) => i !== e.target.value
          ),
        }));
      }
  };

  loadMyCharts() {

      let accessToekn = localStorage.getItem('accessToken');
      var authData = 'Token ' + accessToekn;

      axios
      .get(`https://stock-trading-backend.itechnolabs.tech/chart-layout/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: authData
        }
      })
      .then((response) => {
        //console.log(response)
        this.setState({ myLayoutList: response.data });

        //console.log('dsfsd' + JSON.stringify(this.state.myLayoutList));

      }).catch((error) => {
        //console.log("error", error);
      });
  }

  openImageInNewTab() {
    var htmlToImage = require("html-to-image");
    htmlToImage
      .toJpeg(document.getElementById("container"), { quality: 0.95 })
      .then(function (dataUrl) {
        axios
          .post(
            `https://stock-trading-backend.itechnolabs.tech/auth/save-sceenshot/`,
            { url: dataUrl }
          )
          .then((response) => {
            window.open(response.data.imgUrl, "_blank");
          })
          .catch((error) => {
           console.log("error", error);
          });
      });
  }

  copyImageLinkClipboards() {
    var htmlToImage = require("html-to-image");
    htmlToImage
      .toJpeg(document.getElementById("container"), { quality: 0.95 })
      .then(function (dataUrl) {
        axios
          .post(
            `https://stock-trading-backend.itechnolabs.tech/auth/save-sceenshot/`,
            { url: dataUrl }
          )
          .then((response) => {
            navigator.clipboard.writeText(response.data.imgUrl);
            toast.success("Link Copied");
          })
          .catch((error) => {
            //console.log("error", error);
          });
      });
  }

  copyImageToClipboards() {
    var htmlToImage = require("html-to-image");
    htmlToImage
      .toJpeg(document.getElementById("container"), { quality: 0.95 })
      .then(function (dataUrl) {
        var img = '<img src="' + dataUrl + '">';

        //console.log('hkjhj'+dataUrl);
        copyImageToClipboard(dataUrl)
          .then(() => {
            toast.success("Image Copied");
          })
          .catch((e) => {
            toast.error(e.message);
          });
      });
  }

  handleColorClick = (variable) => {
    //alert([variable+"_hello"])
    this.setState({ [variable]: !this.state[variable] })
    //console.log(this.state)
  }; 

  addChanges = ()=>{
    //alert('sd');
    //this.setState({get_indicator_data: Math.random()}); 
    // function randomIntFromInterval(min, max) { // min and max included 
    //   return Math.floor(Math.random() * (max - min + 1) + min)
    // }

    // this.setState({get_indicator_data: randomIntFromInterval(1, 100) });
    
    //this.setState({indicator_setting_name:'as'})
  }
  addParamInIndicator = (e) =>{
    if(e.target.id){ 
      var indicator_type = e.target.id.split('|')[0]
      var indicator_field_name = e.target.id.split('|')[1] 
      var indicator_field_value = e.target.value

      var existingData = [];
      existingData = JSON.parse(localStorage.getItem('indicator'));
      existingData.map((indicator_data,i) => { 
         if(indicator_data.type === indicator_type){
            if(indicator_field_name == 'width'){
                existingData[i].lineWidth = indicator_field_value 
                this.setState({line_width: e.target.value});
            }
            if(indicator_field_name == 'color'){
              existingData[i].color = indicator_field_value 
              this.setState({indicator_line_color: e.target.value});
          }
         } 
      });
      localStorage.setItem('indicator',JSON.stringify(existingData))
    }  
  }

  refreshCheckIndicator = () => {
    $('.indicator_checkbox').prop('checked', false) 
    if(JSON.parse(localStorage.getItem('indicator')) !== null){ 
      JSON.parse(localStorage.getItem('indicator')).some(function(indicator_data){ 
        console.log('asdsadadasda',`#${indicator_data.type}_checked`);
        $(`#${indicator_data.type}_checked`).prop('checked', true) 
      })  
    }
  }

  isChecked = (indicator_type) =>{

    var seriesDataLength = JSON.parse(localStorage.getItem('indicator'))?.length
    //var seriesData = JSON.parse(localStorage.getItem('indicator'))  
    // if(seriesDataLength > 0){ 
    //   var yaxis_count = 0;
    //   seriesData.forEach(data => {  
    //       if(data.yAxis){  
    //         yaxis_count++;
    //       }
    //     }) 
    //     if(yaxis_count>5){ 
    //         setTimeout(() => {  
    //           console.log(`#${indicator_type}_checked`)
    //           $(`#${indicator_type}_checked`).prop("checked", false); 
    //           //alert('d')
    //         }, 1000); 
    //         //return false;
    //     }
    // }
    
    if(seriesDataLength>5 && seriesDataLength !== 'undefined'){
      //alert(seriesDataLength)
      return false;
    }else{
      if(JSON.parse(localStorage.getItem('indicator')) !== null){
        return JSON.parse(localStorage.getItem('indicator')).some(function(indicator_data){
          return (indicator_data.type == indicator_type)?true:false
        })  
      }
    } 
  }
  showIndicatorSetting = (event,indicator_type) =>{  
    var existingData = JSON.parse(localStorage.getItem('indicator')) 
    if(existingData !== null){
      var indicatorData = existingData.filter(indicator_key => {
        if(indicator_key.type === indicator_type){
          return indicator_key.lineWidth
        }
      })  
    }else{
      var indicatorData = [];
    }  
    if(indicatorData?.length>0){
      $('.indicator_setting_div').show();
      this.setState({indicator_setting_name:indicator_type})
      this.setState({line_width:indicatorData[0].lineWidth})
      this.setState({indicator_line_color:indicatorData[0].color})
    }else{
      alert('Please select the indicator first.')
      $('.indicator_setting_div').hide();
    }
  }
  
  handleIndicatorClick = (event,indicator_type,series_type='indicator') => { 
    //alert('1') 
    var chart_data = []
    var a_perticular_array = []
    var final_array_data = [];
    var chart_data_str;
    chart_data_str = localStorage.getItem('stock_chart_data') 
    chart_data = JSON.parse(chart_data_str);
    //console.log(typeof chart_data)
    var start_time = [];
    var close_price = [];
    var opn_price = [];
    var high_price = [];
    var low_price = [];

    for(let first_count=0;first_count<chart_data?.length;first_count++){ 
      a_perticular_array = chart_data[first_count]; 
      for(let second_count=0;second_count<a_perticular_array?.length;second_count++){ 

        var date_time_stamp = new Date(a_perticular_array[0]); 
        var actual_date_formate = date_time_stamp.getFullYear()+"-"+(date_time_stamp.getMonth()+1)+"-"+date_time_stamp.getDate()+ " "+date_time_stamp.getHours()+":"+date_time_stamp.getMinutes()+":"+date_time_stamp.getSeconds()

        start_time[first_count] = actual_date_formate; 
        close_price[first_count] = a_perticular_array[1];
        opn_price[first_count] = a_perticular_array[2];
        high_price[first_count] = a_perticular_array[3];
        low_price[first_count] = a_perticular_array[4]; 
      }
    }  
    var get_obj = {
        start_time:start_time,
        length:3, 
        close_price:close_price,
        opn_price:opn_price,
        high_price:high_price,
        low_price:low_price,
        indicator_type:indicator_type
    }
    console.log('get_obj',JSON.stringify(get_obj)) 
    var seriesDataLength = JSON.parse(localStorage.getItem('indicator'))?.length 
    if(seriesDataLength > 0 && series_type == 'indicator'){
        if(seriesDataLength>4){
            alert('Only 5 indicator selected at a time.')
            setTimeout(() => {   
                $(`input[name='${indicator_type}']:checkbox`).prop('checked',false); 
            }, 1000); 
            return false;
        } 
    }
    //alert('2')
    axios.post(`https://stock-trading-backend.itechnolabs.tech/indicator/`, get_obj,
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Token 38814e230b142d54b3803a280847aa9fcacf85b4"
      }
    },
    ).then((response) => { 
     console.log('https://stock-trading-backend.itechnolabs.tech/indicator/')
    //  console.log('adasdsadasdsa',response)
      var return_result = {};
      //alert('3')
      console.log('indicator_type',indicator_type);
      console.log('indicator_type_response',response);
      //switch(response.data.indicator_type){
      switch(indicator_type){
        case 'smma': 
            return_result = {
              type: 'smma',
              linkedTo: 'aapl',
              chartData:localStorage.getItem('smma_indicator'),
              data:response.data.result,
              indicatorCode:response.data.indicator_code,
              lineWidth:0.6,
              //yAxis:2,
              color:'#34495e',
              params: {
                period: 10,
              },
              name:'SMMA',
              addSeries:false,
              isVisible:true
            }
          break; 
        case 'beapow': 
            return_result = {
              type: 'beapow',
              linkedTo: 'aapl',
              chartData:localStorage.getItem('bearbullseries'),
              data:response.data.result,
              indicatorCode:response.data.indicator_code,
              lineWidth:0.6,
              yAxis:2,
              color:'#34495e',
              params: {
                period: 10,
              },
              name:'Bear Bull Power',
              addSeries:false,
              isVisible:true
            }
          break; 
        case 'ema':
          return_result = {
            type: response.data.indicator_type,
            linkedTo: 'aapl', 
            data:response.data.result,
            indicatorCode:response.data.indicator_code,
            lineWidth:0.6,
            color:'#990000',
            params: {
              period: 10,
            },
            name:'EMA',
            addSeries:true,
            isVisible:true
          }
          break;
        case 'acc':
          return_result = {
            type: 'abands',
            linkedTo: 'aapl', 
            data:response.data.result,
            indicatorCode:response.data.indicator_code,
            lineWidth:0.6,
            color:'#99004d',
            name:'Accelerator',
            addSeries:true,
            isVisible:true
          }
          break;
        case 'sma':
          return_result = {
            type: response.data.indicator_type,
            linkedTo: 'aapl',
            data:response.data.result,
            indicatorCode:response.data.indicator_code,
            lineWidth:0.6,
            color:'#990099',
            params: {
              period: 10,
            },
            name:'SMA',
            addSeries:true,
            isVisible:true
          }
          break;
        case 'hekashi':
            localStorage.setItem("enable_hekashi", true);
            return_result = {
              type: 'heikinashi',
              linkedTo: 'aapl',
              yAxis:3,
              chartData:localStorage.getItem('stock_chart_data'),
              data:response.data.result,
              indicatorCode:response.data.indicator_code,
              lineWidth:0.6,
              color:'#FF0000',
              params: {
                period: 10,
              },
              name:'Hekashi',
              addSeries:false,
              isVisible:true
            }
          break;
        case 'osma':
            return_result = [
              {
                indicator_name:'OSMA',
                type: "osma",
                indicatorCode:response.data.indicator_code,
                addSeries:true,
                color:'#4d0099',
                isVisible:true
              },
              {
                  type: 'ao',
                  linkedTo: 'aapl',
                  yAxis: 2,  
                  lineWidth:0.6,
                  color:'#4d0099'               
              },
              {
                  type: 'sma',
                  linkedTo: 'aapl',
                  yAxis: 2, 
                  lineWidth:0.6,
                  color:'#4d0099'                
              },
              {
                  type: 'ema',
                  linkedTo: 'aapl',
                  yAxis: 2, 
                  lineWidth:0.6,
                  color:'#4d0099'                
              }
            ]
            break;  
        case 'ma':
              return_result = {
                type: 'sma',
                linkedTo: 'aapl',
                data:response.data.result,
                indicatorCode:response.data.indicator_code,
                lineWidth:0.6,
                color:'#089171',
                params: {
                  period: 40,
                },
                name:'MA',
                addSeries:true,
                isVisible:true
              }
              break;      
        case 'rsi':
            return_result = {
              type: response.data.indicator_type,
              linkedTo: 'aapl',
              yAxis: 2,
              data:response.data.result,
              indicatorCode:response.data.indicator_code,
              lineWidth:0.6,
              color:'#366359',
              name:'RSI',
              addSeries:true,
              isVisible:true
            }
            break;  
        case 'atr':
              return_result = {
                type: response.data.indicator_type,
                linkedTo: 'aapl',
                yAxis: 1,
                data:response.data.result,
                indicatorCode:response.data.indicator_code,
                lineWidth:0.6,
                color:'#e74c3c',
                name:'ATR',
                addSeries:true,
                isVisible:true
              }
              break; 
        case 'bbands':
                return_result = {
                  type: 'bb',
                  linkedTo: 'aapl',
                  data:response.data.result,
                  indicatorCode:response.data.indicator_code,
                  lineWidth:0.6,
                  color:'#002e41',
                  name:'Bollinger Bands',
                  addSeries:true,
                  isVisible:true
                }
                break;  
        case 'zzag':
                  return_result = {
                    type: 'zigzag',
                    linkedTo: 'aapl',
                    data:[77,8,7],
                    indicatorCode:response.data.indicator_code,
                    lineWidth:0.6,
                    color:'#502e41',
                    params: {
                      period: 10,
                    },
                    name:'ZIGZAG',
                    addSeries:true,
                    isVisible:true
                  }
        break;   
        case 'macd':
            return_result = {
              type: 'macd',
              linkedTo: 'aapl',
              yAxis:2,
              indicatorCode:response.data.indicator_code,
              lineWidth:0.6,
              color:'#502ed8',
              name:'MACD',
              addSeries:true,
              isVisible:true
            }
            break;  
        case 'ichi':
              return_result = {
                type: 'ikh',
                linkedTo: 'aapl',
                //data:[77,8,7],
                indicatorCode:response.data.indicator_code,
                lineWidth:0.6,
                color:'#508bd8',
                name:'Ichimoku',
                addSeries:true,
                isVisible:true
              }
              break; 
        case 'demaT':
                return_result = [
                {
                  indicator_name:'Displaced EMA',
                  type: "ema",
                  indicatorCode:response.data.indicator_code,
                  addSeries:true,
                  color:'#1cb78a',
                  isVisible:true
                },{
                  type: "ema",
                  linkedTo: "aapl",
                  params: {
                    period: 20,
                  },lineWidth: 0.6,
                  color:'#1cb78a',
                  marker: {
                    enabled: false
                  } 
                }]
                break; 
        case 'alg':
                  return_result = [
                    {
                      indicator_name:'Aligator',
                      type: "aligator",
                      indicatorCode:response.data.indicator_code,
                      addSeries:true,
                      color:'#1c128a',
                      isVisible:true
                    },
                    {
                      type: "sma",
                      linkedTo: "aapl",
                      params: {
                        period: 5,
                      },lineWidth: 0.6,
                      color:'#1c128a',
                      marker: {
                        enabled: false
                      } 
                    },
                    {
                      type: "sma",
                      linkedTo: "aapl",
                      params: {
                        period: 8,
                      },lineWidth: 0.6,
                      color:'#1c128a',
                      marker: {
                        enabled: false
                      } 
                    },
                    {
                      type: "sma",
                      linkedTo: "aapl",
                      params: {
                        period: 13,
                      },lineWidth: 0.6,
                      color:'#1c128a',
                      marker: {
                        enabled: false
                      } 
                    }
                  ]
                  break;               
          
          case 'sar':
                return_result = {
                  type: 'psar',
                  linkedTo: 'aapl',
                  lineWidth:0.6,
                  color:'#0c8d33',
                  name:'Parabolic SAR',
                  addSeries:true,
                  isVisible:true
                }
                break;   
          // case 'macd':
          //     return_result = {
          //     type: 'macd',
          //     id: 'oscillator',
          //     linkedTo: 'aapl',
          //     yAxis: 2,
          //     lineWidth:0.6,
          //     color:'#FF0000'
          //   }
          //   break;
          // case 'rsi':
          // return_result = {
          //   type: 'rsi',
          //   id: 'oscillator',
          //   linkedTo: 'aapl',
          //   yAxis: 2,
          //   lineWidth:0.6,
          //   color:'#FF0000',
          //   name:'RSI'
          // }
          // break;
          case 'mom':
            return_result = {
              type: 'momentum',
              id: 'oscillator',
              linkedTo: 'aapl',
              yAxis: 2,
              lineWidth:0.6,
              color:'#0c4f33',
              name:'momentum',
              addSeries:true,
              isVisible:true
            }
            break;
          case 'ad':
            return_result = {
              type: 'ad', 
              linkedTo: 'aapl',
              yAxis: 2, 
              lineWidth:0.6,
              color:'#0cc577',
              name:'Accumulation Distribution',
              addSeries:true,
              isVisible:true
            }
            break;
          case 'awo':
              return_result = {
                type: 'ao', 
                linkedTo: 'aapl',
                yAxis: 2,
                lineWidth:0.6,
                color:'#f39c12',
                name:'Awesome Osilator',
                addSeries:true,
                isVisible:true
              }
            break; 
          case 'stoch':
              return_result = {
                type: 'stochastic',
                id: 'oscillator',
                linkedTo: 'aapl',
                yAxis: 2,
                lineWidth:0.6,
                color:'#96da7c',
                name:'STOCH',
                addSeries:true,
                isVisible:true
              }
            break; 
          case 'lwma':
              return_result = {
                type: 'wma',
                id: 'oscillator',
                linkedTo: 'aapl',
                //yAxis: 2,
                lineWidth:0.6,
                color:'#c7708c',
                name:'LWMA',
                addSeries:true,
                isVisible:true
              }
            break; 
            case 'bwT':
                return_result = [
                  {
                    indicator_name:'Bill Williams 3 Lines',
                    type: "ema",
                    indicatorCode:response.data.indicator_code,
                    addSeries:true,
                    color:'#1c738a',
                    isVisible:true
                  },
                  {
                    type: "sma",
                    linkedTo: "aapl",
                    params: {
                      period: 5,
                    },lineWidth: 0.6,
                    color:'#1c738a',
                    marker: {
                      enabled: false
                    } 
                  },
                  {
                    type: "sma",
                    linkedTo: "aapl",
                    params: {
                      period: 8,
                    },lineWidth: 0.6,
                    color:'#1c738a',
                    marker: {
                      enabled: false
                    } 
                  },
                  {
                    type: "sma",
                    linkedTo: "aapl",
                    params: {
                      period: 13,
                    },lineWidth: 0.6,
                    color:'#1c738a',
                    marker: {
                      enabled: false
                    } 
                  }
                ]
                break;      
        case 'maexpT':
                return_result = [
                {
                  indicator_name:'MA Exp Ribbon',
                  type: "ema",
                  indicatorCode:response.data.indicator_code,
                  addSeries:true,
                  color:'#0c48a2',
                  isVisible:true
                },
                {
                  type: "ema",
                  linkedTo: "aapl",
                  params: {
                    period: 20,
                  },lineWidth: 0.6,
                  color:'#0c48a2',
                  marker: {
                    enabled: false
                  } 
                },
                {
                  type: "ema",
                  linkedTo: "aapl",
                  params: {
                    period: 25,
                  },lineWidth: 0.6,
                  color:'#0c48a2',
                  marker: {
                    enabled: false
                  } 
                },
                {
                  type: "ema",
                  linkedTo: "aapl",
                  params: {
                    period: 30,
                  },lineWidth: 0.6,
                  color:'#0c48a2',
                  marker: {
                    enabled: false
                  } 
                },
                {
                  type: "ema",
                  linkedTo: "aapl",
                  params: {
                    period: 35,
                  },lineWidth: 0.6,
                  color:'#0c48a2',
                  marker: {
                    enabled: false
                  } 
                },
                {
                  type: "ema",
                  linkedTo: "aapl",
                  params: {
                    period: 40,
                  },lineWidth: 0.6,
                  color:'#0c48a2',
                  marker: {
                    enabled: false
                  } 
                },
                {
                  type: "ema",
                  linkedTo: "aapl",
                  params: {
                    period: 45,
                  },lineWidth: 0.6,
                  color:'#0c48a2',
                  marker: {
                    enabled: false
                  } 
                },
                {
                  type: "ema",
                  linkedTo: "aapl",
                  params: {
                    period: 50,
                  },lineWidth: 0.6,
                  color:'#0c48a2',
                  marker: {
                    enabled: false
                  } 
                },
                {
                  type: "ema",
                  linkedTo: "aapl",
                  params: {
                    period: 55,
                  },lineWidth: 0.6,
                  color:'#0c48a2',
                  marker: {
                    enabled: false
                  } 
                }]
                break; 
          case 'oscillatorsT':
              return_result = [
                {
                  indicator_name:'Oscillators',
                  type: "stochastic",
                  indicatorCode:response.data.indicator_code,
                  addSeries:true,
                  color:'#e03d4c',
                  isVisible:true
                },
                {
                    type: 'stochastic',
                    linkedTo: 'aapl',
                    yAxis: 2,  
                    lineWidth:0.6,
                    color:'#e03d4c'               
                },
                {
                    type: 'rsi',
                    linkedTo: 'aapl',
                    yAxis: 3, 
                    lineWidth:0.6,
                    color:'#e03d4c'                
                },{
                    type: 'cci',
                    linkedTo: 'aapl',
                    yAxis: 4,
                    lineWidth:0.6,
                    color:'#e03d4c'
                }
              ]
              break;  
          case 'stT':
            return_result = [
              {
                indicator_name:'Swing Trending',
                type: "ema",
                indicatorCode:response.data.indicator_code,
                addSeries:true,
                color:'#3dade0',
                isVisible:true
              },
              {
                type: 'pivotpoints',
                linkedTo: 'aapl', 
                zIndex: 0,
                lineWidth:0.6,
                color:'#3dade0',
                dataLabels: {
                    overflow: 'none',
                    crop: false,
                    y: 4,
                    style: {
                        fontSize: 9
                    }
                }        
              },
              {
                type: 'zigzag',
                linkedTo: 'aapl',
                lineWidth:0.6,
                color:'#3dade0'               
              }
            ]
          break;   
          case 'vbT':
            return_result = [
              {
                indicator_name:'Volume Based',
                type: "ema",
                indicatorCode:response.data.indicator_code,
                addSeries:true,
                color:'#f55459',
                isVisible:true
              },
              {                  
                type: 'cci',
                linkedTo: 'aapl',
                yAxis: 2,
                lineWidth:0.6,
                color:'#f55459',
                marker: {
                  enabled: false
                }              
            },
            {
              linkedTo: 'aapl',
              type: 'obv', 
              yAxis: 3,
              lineWidth:0.6,
              color:'#f55459',
              marker: {
                enabled: false
              }
            },
            {
              type: 'roc',
              linkedTo: 'aapl',
              yAxis: 4,
              lineWidth:0.6,
              color:'#f55459',
              marker: {
                enabled: false
              }
            },
            {
              type: 'cmf',
              linkedTo: 'aapl',
              yAxis: 5,
              lineWidth:0.6,
              color:'#f55459',
              marker: {
                enabled: false
              }
            }          
           ]
        break;   
        default:
          return_result = {
            type: 'stochastic',
            id: 'oscillator',
            linkedTo: 'aapl',
            yAxis: 2,
            lineWidth:0.6,
            color:'#FF0000'
          }
        break;  
      } 
      //alert('4')
       console.log('response.data',response.data)
       console.log('response',return_result)
      if(response.data.id){
        //alert('5')
        var series_data = [];
        var existingData = JSON.parse(localStorage.getItem('indicator'))
        console.log('existingData',existingData);
         

        if(existingData !== null){
          if(event.target.checked){
            existingData.push(return_result) 
          }else{
            var existingData = existingData.filter(indicator_key => indicator_key.type !== indicator_type)
            // existingData.filter(function( indicator_key ){ 
            //   return indicator_key.type !== indicator_type;
            // }); 
            console.log('existingData',existingData)
          }  
          series_data =  existingData;    
        }else{
          series_data = [return_result]; 
        }   
        function getUniqueListBy(arr, key) {
          return [...new Map(arr.map(item => [item[key], item])).values()]
        }
        series_data = getUniqueListBy(series_data, 'type')
        //indicator_setting
        console.log('existingData.lineWidth',return_result)
         
        console.log(JSON.stringify(series_data))  
 
        // Remove all template
        let existing_indicator = JSON.parse(localStorage.getItem('default_template')) 
        if(existing_indicator && existing_indicator?.length>0){ 
            localStorage.removeItem("default_template");   
            localStorage.removeItem(`${existing_indicator[0].template}`);
        } 

        var is_template_var = JSON.parse(localStorage.getItem('default_template'))
        var my_object = {};
        
        // localStorage.removeItem("default_template");
        // localStorage.removeItem("ma_template");
        // localStorage.removeItem("bill_template");
        // localStorage.removeItem("displaced_template");
        // localStorage.removeItem("oscillater_template");
        // localStorage.removeItem("swing_template");
        // localStorage.removeItem("volume_template"); 

        if(indicator_type == 'maexpT'){ 
          localStorage.removeItem("indicator"); 

          if(!is_template_var){
            is_template_var = [];
          }
          let template_data = [return_result];
          localStorage.setItem("ma_template", JSON.stringify(template_data[0]));

          my_object.template = "ma_template"; 
          is_template_var.push(my_object)
          localStorage.setItem("default_template", JSON.stringify(is_template_var));
        }else if(indicator_type == 'bwT'){ 
          localStorage.removeItem("indicator"); 

          if(!is_template_var){
            is_template_var = [];
          } 
          let template_data = [return_result];
          localStorage.setItem("bill_template", JSON.stringify(template_data[0]));
   
          my_object.template = "bill_template"; 
          is_template_var.push(my_object)
          localStorage.setItem("default_template", JSON.stringify(is_template_var)); 
        }else if(indicator_type == 'demaT'){ 
          localStorage.removeItem("indicator"); 

          if(!is_template_var){
            is_template_var = [];
          } 
          let template_data = [return_result];
          localStorage.setItem("displaced_template", JSON.stringify(template_data[0]));
  
          my_object.template = "displaced_template"; 
          is_template_var.push(my_object)
          localStorage.setItem("default_template", JSON.stringify(is_template_var)); 
        }else if(indicator_type == 'oscillatorsT'){ 
          localStorage.removeItem("indicator"); 

          if(!is_template_var){
            is_template_var = [];
          }

          let template_data = [return_result];
          localStorage.setItem("oscillater_template", JSON.stringify(template_data[0]));
  
          my_object.template = "oscillater_template"; 
          is_template_var.push(my_object)
          localStorage.setItem("default_template", JSON.stringify(is_template_var)); 
        }else if(indicator_type == 'stT'){ 
          localStorage.removeItem("indicator"); 

          if(!is_template_var){
            is_template_var = [];
          }

          let template_data = [return_result];
          localStorage.setItem("swing_template", JSON.stringify(template_data[0]));
  
          my_object.template = "swing_template"; 
          is_template_var.push(my_object)
          localStorage.setItem("default_template", JSON.stringify(is_template_var)); 
        }else if(indicator_type == 'vbT'){ 
          localStorage.removeItem("indicator"); 
          
          if(!is_template_var){
            is_template_var = [];
          }

          let template_data = [return_result];
          localStorage.setItem("volume_template", JSON.stringify(template_data[0]));
  
          my_object.template = "volume_template"; 
          is_template_var.push(my_object)
          localStorage.setItem("default_template", JSON.stringify(is_template_var)); 
        }else if(indicator_type == 'osma'){ 
          localStorage.removeItem("indicator");

          if(!is_template_var){
            is_template_var = [];
          }

          let template_data = [return_result];
          localStorage.setItem("osma_template", JSON.stringify(template_data[0]));
  
          my_object.template = "osma_template"; 
          is_template_var.push(my_object)
          localStorage.setItem("default_template", JSON.stringify(is_template_var)); 
        }else if(indicator_type == 'alg'){
          localStorage.removeItem("indicator");
          
          if(!is_template_var){
            is_template_var = [];
          }

          let template_data = [return_result];
          localStorage.setItem("aligator_template", JSON.stringify(template_data[0]));
  
          my_object.template = "aligator_template"; 
          is_template_var.push(my_object)
          localStorage.setItem("default_template", JSON.stringify(is_template_var)); 
        }else{
          localStorage.setItem("indicator", JSON.stringify(series_data));
        }
        
        //this.setState({get_indicator_data: Math.random()});          
        //alert('Response in api.')
 
      }else{        
        //this.setState({get_indicator_data: Math.random() });
        alert('No response by api. Please try again after some time.')
      }   
      //alert('6') 
      function randomIntFromInterval(min,max) { // min and max included 
        return Math.floor(Math.random() * (max - min + 1) + min)
      } 

      var get_random_number = randomIntFromInterval(1,100);
      //alert('7')
      //alert(get_random_number);
      this.setState({get_indicator_data: get_random_number });
      //alert('8')
      this.setState({indicator_setting_name:(indicator_type)?indicator_type:'NA'})
      this.setState({line_width:return_result.lineWidth})
      //alert('9')
      //$("[data-dismiss=modal]").trigger({ type: "click" }); 
     // console.log(this.state.get_indicator_data)
    }).catch((error) => {
      alert('API not reponded for this indicator. Please try again after some time.')
      window.location.reload();
    });
    

    // var timestamp = 1648094400000
    // var date = new Date(timestamp);
    // console.log(date.getTime())
    // console.log(date)

    // console.log(chart_data)
    // console.log(Highchart.series)
    // let accessToekn = localStorage.getItem('accessToken');
    // var authData = 'Token ' + accessToekn;
    // console.log(accessToekn)
    // let data = {
    //   type: 'abands',
    //   data: this.state.stockData,
    // }
    // console.log(data)
    // let fake_data = '{
    //     "start_time":"2022-05-05 11:00:00",
    //     "length":3, 
    //     "close_price":[1.1111,1.1112,1.1113,1.1114,1.1115,1.1116,1.1117],
    //     "opn_price":[1.1111,1.1112,1.1113,1.1114,1.1115,1.1116,1.1117],
    //     "high_price":[1.1111,1.1112,1.1113,1.1114,1.1115,1.1116,1.1117],
    //     "low_price":[1.1111,1.1112,1.1113,1.1114,1.1115,1.1116,1.1117],
    //     "indicator_type":"ema"
    // }';
    // this.setState({ newSeriesIndicator: data });

    // axios
    //   .get(`/fdfsdfsdfsdfsd`, {
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: authData
    //     }
    //   })
    //   .then((response) => {
    //     let data = {
    //       type: 'abands',
    //       data: this.state.stockData,
    //     }
    //     this.setState({ newSeriesIndicator: data });

    //   }).catch((error) => {
    //     console.log("error", error);
    //   });
  };
  
  handleColorClose = (variable) => {
    //this.setState({ displayColorPicker: false })
    this.setState({ [variable]: !this.state[variable] })
  };

  handleColorChange = (color) => {
    this.setState({ color: color.rgb })
  };

  handleUpColorChange = (color) => {
    this.setState({ show_up_color: color.rgb, up_color_color: color.hex })
    $('.handle_up_color_change').change();
  }

  handleUpAlphaChange = (color) => {
    this.setState({ alpha_color: color.rgb })
  }

  handleLineColorChange = (color) => {
    //console.log(color)
    this.setState({ show_line_color: color.rgb, line_color_color: color.hex })
  }

  handleDownColorChange = (color) => {
    this.setState({ show_down_color: color.rgb, down_color_color: color.hex })
    $('.handle_down_color_change').change();
  }
  handleLastlineColorChange = (color) => {
    // console.log(color.hex)
    this.setState({ show_last_price: color.rgb, last_price_color: color.hex })
    $('.handle_price_line_color_change').change();
  }
  highLowLine = (e) => {
    const checked = e.target.checked;
    // alert(checked)
    if (checked) {
      this.setState({ showHighLowerLine: true, graphType: this.state.graphType })
      // this.setState({showHighLowerLine:false,graphType:this.state.graphType})
    } else {
      this.setState({ showHighLowerLine: false, graphType: this.state.graphType })
      // this.setState({showHighLowerLine:true,graphType:this.state.graphType})
    }

    //alert(this.state.showHighLowerLine)
  };
  txtwatchlistChange(val){
    this.setState({txtwatchlistname:val});
  }
  showMoreOptionPanel(option,loginneeded=false)
  {
    
     let accessToken = localStorage.getItem("accessToken");
    
    if(loginneeded)
    {
      if(accessToken != "" &&
      accessToken != null &&
      accessToken != undefined)
      {

      }
      else
      {
        alert("Please login to access this feature.");
        return;
      }
    }
    if(option=='close')
    {
      $(".watchlist_outer").addClass("d-none");
      $("#container").removeClass("on_click");
    }
    else
    {
      $(".watchlist_outer").removeClass("d-none");
    $(".watch_inner").addClass("d-none");
    $("."+option).removeClass("d-none");
    $("#container").addClass("on_click");
  
  }
  }
  
  addWatchlist(){
    if(this.state.txtwatchlistname=='')
    {
      alert("Please enter the watchlist name ");
    }
    else
    {
      
        let accessToekn = localStorage.getItem('accessToken');
        var authData = 'Token ' + accessToekn;


        axios.post(constants.API_URL+'/watchlist/',
        {
          name:this.state.txtwatchlistname
        },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: authData
            }
          },

        )
          .then((response) => {
            toast.success("Watchlist created successfully.", {
              autoClose: 3000,
          });
          this.setState({
            txtwatchlistname:""
          });
          $("#closeWatchlistModal").trigger("click");
            this.getWatchlist();


          }).catch((error) => {
            console.log("error", error);
          });
    }
  }
  showCreateAlert() {
    // alert('hii');
    this.setState({ alertClasses: "modal modal_customs fade show", alertCss: "block" })
  }
  closeCreateAlert() {
    // alert('hii');
    this.setState({ alertClasses: "modal modal_customs fade show", alertCss: "none" })
  }

  replacer(key, value) {
    return value.replace(/[^\w\s]/gi, '');
  }

  strrep(str) {

    const lastIndexOfL = str.lastIndexOf('"');
    let removeLastL = str;

    if (lastIndexOfL !== -1) {
      removeLastL = str.slice(0, lastIndexOfL) + str.slice(lastIndexOfL + 1);
      return removeLastL;
    }

  }
  downloadPicture() {
    var htmlToImage = require("html-to-image");
    htmlToImage
      .toJpeg(document.getElementById("container"), { quality: 0.95 })
      .then(function (dataUrl) {
        var img = '<img src="' + dataUrl + '">';

        var popup = window.open();
        popup.document.write(img);
        var link = document.createElement("a");
        link.download = "image.jpeg";
        link.href = dataUrl;
        link.click();
      });
  }

  getStockDetail(){
  
     
    let cryptoSocket2= new WebSocket("wss://mZ3Zq4NXbp.finage.ws:6002/?token=SOCKET_KEY11704rx0Bfw3FcD4BaIoj64SNs9Kiy8tUCbk3sqtx3Ej7cnq")
    let subscribe2 = {
      "action": "subscribe", "symbols":this.state.slctSymbol
    }
    cryptoSocket2.onopen=()=>{
      cryptoSocket2.send(JSON.stringify(subscribe2))
  }
  cryptoSocket2.onmessage=(e)=>{
    console.log('socket-data finage',e.data)
}
cryptoSocket2.onerror=(e)=>{
    console.log('socket-data error',e.data)
}

  
}
getCurrentDate() {    
return Moment(new Date()).format("YYYY-MM-DD");
}
getPriorDate(m) {    
var d = new Date();
d.setMonth(d.getDay() - m);
return Moment(d).format("YYYY-MM-DD");
}
getStockFullDetails(){


axios
.get('https://api.finage.co.uk/last/crypto/detailed/'+this.state.slctSymbol+'?apikey='+process.env.REACT_APP_FINAGE_API_KEY, {
headers: {
  "Content-Type": "application/json"
}
})
.then((response) => {
this.setState({
  symbol_key_stats:response.data
});
console.log("fulldetails",response.data);
}).catch((error) => {
console.log("error", error);
});

}
getStockPerformance()
{
axios
.get('https://api.finage.co.uk/last/stock/changes/'+this.state.slctSymbol+'?apikey='+process.env.REACT_APP_FINAGE_API_KEY, {
headers: {
  "Content-Type": "application/json"
}
})
.then((response) => {
this.setState({
  symbol_performance:response.data
});
console.log("symbol performance",response.data);
}).catch((error) => {
console.log("error", error);
});
}
convertToInternationalCurrencySystem (labelValue) {

// Nine Zeroes for Billions
return Math.abs(Number(labelValue)) >= 1.0e+9

? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(2) + "B"
// Six Zeroes for Millions 
: Math.abs(Number(labelValue)) >= 1.0e+6

? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(2) + "M"
// Three Zeroes for Thousands
: Math.abs(Number(labelValue)) >= 1.0e+3

? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(2) + "K"

: Math.abs(Number(labelValue));

}
getAskAndBid(){
axios
.get('https://api.finage.co.uk/snapshot/stock?symbols='+this.state.slctSymbol+'?apikey='+process.env.REACT_APP_FINAGE_API_KEY, {
headers: {
"Content-Type": "application/json"
}
})
.then((response) => {
this.setState({
symbol_ask_and_bids:response.data
});
console.log("symbol performance",response.data);
}).catch((error) => {
console.log("error", error);
});
}
getDividend()
{
axios
.get('https://api.finage.co.uk/historical-dividends/'+this.state.slctSymbol+'?apikey='+process.env.REACT_APP_FINAGE_API_KEY, {
headers: {
"Content-Type": "application/json"
}
})
.then((response) => {
if(response.status==200 && response.data.length>0)
{

}
this.setState({
symbol_ask_and_bids:response.data
});
console.log("dividend history",response.data);
}).catch((error) => {
console.log("error", error);
});
}
milFormate(value){
var nf = new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2  });
return nf.format(value);
}
showChartFull()
{
  var element = $('#container').get(0);
  if (element.requestFullscreen) {
    element.requestFullscreen();
  } else if (element.mozRequestFullScreen) {
    element.mozRequestFullScreen();
  } else if (element.webkitRequestFullscreen) {
    element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
  } else if (element.msRequestFullscreen) {
    element.msRequestFullscreen();
  }
//   var index=$("#container").data('highchartsChart');
//  var chart=Highcharts.charts[0];
//   // var chart=$("#container").Highcharts();
//   Highcharts.FullScreen = function (container) {
//     console.log(container.parentNode.parentNode);
//     this.init(container.parentNode); // main div of the chart
//   };

//   Highcharts.FullScreen.prototype = {
//       init: function (container) {
//           if (container.requestFullscreen) {
//               container.requestFullscreen();
//           } else if (container.mozRequestFullScreen) {
//               container.mozRequestFullScreen();
//           } else if (container.webkitRequestFullscreen) {
//               container.webkitRequestFullscreen();
//           } else if (container.msRequestFullscreen) {
//               container.msRequestFullscreen();
//           }
//       },
//   };
//   chart.fullscreen = new Highcharts.FullScreen(chart.container);
}
getIncomeStatement()
{
  axios
  .get('https://api.finage.co.uk/income-statement/'+this.state.slctSymbol+'?limit=5&period='+this.state.statement_type+'&apikey='+process.env.REACT_APP_FINAGE_API_KEY, {
    headers: {
      "Content-Type": "application/json"
    }
  })
  .then((response) => {
    if(response.status==200 && response.data.length>0)
    {
        var categories=[];
        var RevenueData=[];
        var NetIncomeData=[];
        var ProfitMarginData=[];
        for(var i=0;i<response.data.length;i++)
        {
            categories.push(response.data[i].period+"'"+Moment(response.data[i].date).format("YY"));
            RevenueData.push(response.data[i].revenue/1000000000);
            NetIncomeData.push(response.data[i].netIncome/1000000000);
            ProfitMarginData.push(response.data[i].grossProfit/1000000000);
        }
        var Highcharts = require('highcharts');  
        
        Highcharts.chart('highchart-income-statement', {
          chart: {
              type: 'column',
              parallelAxes:true
          },
          title:{
            text:"Income Statement"
          },
          xAxis: {
              categories: categories
          },
          yAxis: {
              min: 0,
              
          },
          tooltip: {
              headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
              pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                  '<td style="padding:0"><b>{point.y:.1f} B </b></td></tr>',
              footerFormat: '</table>',
              shared: true,
              useHTML: true
          },
          plotOptions: {
              column: {
                  pointPadding: 0.2,
                  borderWidth: 0
              }
          },
          series: [
          {
              name: 'Revenue',
              data: RevenueData
      
          },
          {
            name: 'Net Income',
            data: NetIncomeData
    
        },
          {
            name: 'Profit Margin',
            data: ProfitMarginData
    
        }]
      });
    }
    
  }).catch((error) => {
    console.log("error", error);
  });
}

getCashbookStatement()
{
axios
.get('https://api.finage.co.uk/balance-sheet-statements/'+this.state.slctSymbol+'?limit=5&period='+this.state.statement_type+'&apikey='+process.env.REACT_APP_FINAGE_API_KEY, {
headers: {
"Content-Type": "application/json"
}
})
.then((response) => {
if(response.status==200 && response.data.length>0)
{
  var categories=[];
  var TotalAssetsData=[];
  var TotalLiabilitiesData=[];
  for(var i=0;i<response.data.length;i++)
  {
      categories.push(response.data[i].period+"'"+Moment(response.data[i].date).format("YY"));
      TotalAssetsData.push(response.data[i].totalAssets/1000000000);
      TotalLiabilitiesData.push(response.data[i].totalLiabilities/1000000000);
  }
  var Highcharts = require('highcharts');  
  
  Highcharts.chart('highchart-income-statement', {
    chart: {
        type: 'column',
        parallelAxes:true
    },
    title:{
      text:"Balance Sheet Statement"
    },
    xAxis: {
        categories: categories
    },
    yAxis: {
        min: 0,
        
    },
    tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y:.1f} B </b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
    },
    plotOptions: {
        column: {
            pointPadding: 0.2,
            borderWidth: 0
        }
    },
    series: [
    {
        name: 'Total Assets',
        data: TotalAssetsData

    },
    {
      name: 'Total Liabilities',
      data: TotalLiabilitiesData

  }]
});
}

}).catch((error) => {
console.log("error", error);
});
}
getCashflowStatement()
{
axios
.get('https://api.finage.co.uk/cash-flow-statement/'+this.state.slctSymbol+'?limit=5&period='+this.state.statement_type+'&apikey='+process.env.REACT_APP_FINAGE_API_KEY, {
headers: {
"Content-Type": "application/json"
}
})
.then((response) => {
if(response.status==200 && response.data.length>0)
{
  var categories=[];
  var OperatingData=[];
  var InvestingData=[];
  var FinancingData=[];
  for(var i=0;i<response.data.length;i++)
  {
      categories.push(response.data[i].period+"'"+Moment(response.data[i].date).format("YY"));
      OperatingData.push(response.data[i].operatingCashFlow/1000000);
      InvestingData.push(response.data[i].netCashUsedForInvestingActivites/1000000);
      FinancingData.push(response.data[i].otherFinancingActivites/1000000);
  }
  var Highcharts = require('highcharts');  
  
  Highcharts.chart('highchart-income-statement', {
    chart: {
        type: 'line',
        title:" "
    },
    title:{
      text:"Cash Flow Statement"
    },
    xAxis: {
        categories: categories
    },
    yAxis: {
        min: -3,
        
    },
    tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y:.1f} B </b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
    },
    plotOptions: {
        column: {
            pointPadding: 0.2,
            borderWidth: 0
        }
    },
    series: [
    {
        name: 'Operating',
        data: OperatingData

    },
    {
      name: 'Investing',
      data: InvestingData

  },
    {
      name: 'Financing',
      data: FinancingData

  }]
});
}
this.setState({
symbol_ask_and_bids:response.data
});
console.log("income statement",response.data);
}).catch((error) => {
console.log("error", error);
});
}

getStockProfile()
{
axios
.get('https://api.finage.co.uk/detail/'+this.state.stock+'/'+this.state.slctSymbol+'?apikey='+process.env.REACT_APP_FINAGE_API_KEY, {
headers: {
"Content-Type": "application/json"
}
})
.then((response) => {
if(response.status==200)
{
this.setState({symbol_profile:response.data});
}
console.log("symbol profile",response.data);
}).catch((error) => {
console.log("error", error);
});
}
getStockNews()
{
axios
.get('https://api.finage.co.uk/news/market/'+this.state.slctSymbol+'?limit=50000&apikey='+process.env.REACT_APP_FINAGE_API_KEY, {
headers: {
"Content-Type": "application/json"
}
})
.then((response) => {
if(response.status==200 && response.data.news.length>0)
{
this.setState({symbol_news:response.data.news});
}
console.log("symbol news",response.data);
}).catch((error) => {
console.log("error", error);
});
}
getEconomicCalendar()
{
axios
.get('https://api.finage.co.uk/economic-calendar?from='+this.getPriorDate(6)+'&to='+this.getCurrentDate()+'&apikey='+process.env.REACT_APP_FINAGE_API_KEY, {
headers: {
"Content-Type": "application/json"
}
})
.then((response) => {
if(response.status==200 )
{
this.setState({eonomic_calendar:response.data});
}
console.log("economic calendar",response.data);
}).catch((error) => {
console.log("error", error);
});
}
getEarningCalendar()
{
axios
.get('https://api.finage.co.uk/earning-calendar?apikey='+process.env.REACT_APP_FINAGE_API_KEY, {
headers: {
"Content-Type": "application/json"
}
})
.then((response) => {
if(response.status==200 )
{
this.setState({earning_calendar:response.data});
}
console.log("earning calendar",response.data);
}).catch((error) => {
console.log("error", error);
});
}
addIdea(){
const radio_privacy = $('input[name="radioprivacy"]:checked').val(); 
const radio_type = $('input[name="radiotype"]:checked').val(); 
const radio_strategy = $('input[name="radiostrategy"]:checked').val(); 
const cat = $('#ideaCat').val();

if(this.state.idea_title.trim()=="")
{
alert("Please enter a title.");
}
else if(this.state.idea_description.trim()=="")
{
alert("Please enter a description.");
}
else if(cat==""){
alert("Please select category");
}
else
{
const joinData = new FormData();
joinData.append("title", this.state.idea_title); 
joinData.append("description", this.state.idea_description);
joinData.append("idea_link", this.state.idea_link);
joinData.append("status", radio_privacy);
joinData.append("type", radio_type);
joinData.append("strategy",radio_strategy);
joinData.append("category", cat);
joinData.append("tags", this.state.idea_tags);
const headers = {
"Content-Type": "application/json",
Authorization: "Token "+localStorage.getItem('accessToken'),
}; 
axios.post(CONSTANT.API_BACKEND+'/idea-list/',joinData,{
headers: headers,
}).then((response) => {
  console.log(response);
  if(response.status == 201){ 
    alert("Idea published successfully.");
    this.setState({
      idea_tite:"",
      idea_description:"",
      idea_link:"",
      idea_tags:""
    });
    $("#radioPrivate").prop("checked", true);
    $("#radioTutorial").prop("checked", true);
    $("#radioNeutral").prop("checked", true);
    

    this.getMyIdeas();
    $("#closeIdeaModal").trigger("click");
  } 
}).catch((error)=> {    
}); 
}

}
removeIdea(id){
if(!window.confirm("Are you sure to remove this idea ?"))
{
return;
}
var accessToekn = localStorage.getItem('accessToken');
var authData = 'Token ' + accessToekn;
axios
.delete(constants.API_URL+"/idea-list/"+id+"/", {
headers: {
"Content-Type": "application/json",
Authorization: authData
}
})
.then((response) => {
this.getMyIdeas();
}).catch((error) => {
console.log("error", error);
});
}
getAlerts()
{
let accessToekn = localStorage.getItem('accessToken');
var authData = 'Token ' + accessToekn;
axios
.get(constants.API_URL+"/alert_notification/", {
headers: {
"Content-Type": "application/json",
Authorization: authData
}
})
.then((response) => {
if(response.status==200 )
{
this.setState({alerts:response.data.data});
}
console.log("alerts response",response.data);
}).catch((error) => {
console.log("error", error);
});
}
restartAlert(action_method,alert_id,type){
let accessToekn = localStorage.getItem('accessToken');
var authData = 'Token ' + accessToekn;
var data=new FormData();
data.append('is_notify',type);
data.append('action_method',action_method);
axios
.put(constants.API_URL+"/alert_notification/"+alert_id+"/", data,{
headers: {
"Content-Type": "application/json",
Authorization: authData
}
})
.then((response) => {
if(response.status==200 )
{
this.getAlerts();
}
console.log("alerts response",response.data);
}).catch((error) => {
console.log("error", error);
});
}


deleteAlert(alert_id){
if(!window.confirm("Are you sure to remove this alert ?"))
{
return;
}
let accessToekn = localStorage.getItem('accessToken');
var authData = 'Token ' + accessToekn;
axios
.delete(constants.API_URL+"/alert_notification/"+alert_id+"/",{
headers: {
"Content-Type": "application/json",
Authorization: authData
}
})
.then((response) => {
if(response.status==200 )
{
this.getAlerts();
}
console.log("alerts response",response.data);
}).catch((error) => {
console.log("error", error);
});
}
  getMyIdeas()
{
 
  let accessToekn = localStorage.getItem('accessToken');
  var authData = 'Token ' + accessToekn;
  axios
  .get(constants.API_URL+"/idea-list/",{
    headers: {
      "Content-Type": "application/json",
      Authorization: authData
    }
  })
  .then((response) => {
    if(response.status==200 )
    {
      this.setState({my_ideas:response.data.data});
    }
  }).catch((error) => {
    console.log("error", error);
  });
}
getMyIdeaCategories()
{
 
  let accessToekn = localStorage.getItem('accessToken');
  var authData = 'Token ' + accessToekn;
  axios
  .get(constants.API_URL+"/idea-category/",{
    headers: {
      "Content-Type": "application/json",
      Authorization: authData
    }
  })
  .then((response) => {
    if(response.status==200 )
    {
      this.setState({idea_categories:response.data.data});
    }
  }).catch((error) => {
    console.log("error", error);
  });
}
getCalculations()
  {
    this.getTechnical("RSI","1hour","technical_rsi");
    this.getTechnical("EMA","1hour","technical_ema");
    this.getTechnical("SMA","1hour","technical_sma");
   
  }
  getTechnical(type,time,label,period=200){
    axios
    .get('https://api.finage.co.uk/technical-indicator/'+type+'/'+time+'/'+this.state.slctSymbol+'?period='+period+'&apikey='+process.env.REACT_APP_FINAGE_API_KEY, {
      headers: {
        "Content-Type": "application/json"
      }
    })
    .then((response) => {
              // var Highcharts = require('highcharts');  
        if(response.status==200)
        {
          this.setState({
            [label]:response.data[0].value
          });
          if(type=="RSI")
          {
 /*---- Set technical gauge start ----*/

 Highcharts.chart("technicalgauge", {

  chart: {
      type: 'gauge',
      plotBackgroundColor: null,
      plotBackgroundImage: null,
      plotBorderWidth: 0,
      plotShadow: false
  },

  title: {
      text: 'Technical'
  },

  pane: {
      startAngle:-100,
      endAngle: 100,
      background: [{
          backgroundColor: {
              linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
              stops: [
                  [0, '#FFF'],
                  [1, '#333']
              ]
          },
          borderWidth: 0,
          outerRadius: '109%'
      }, {
          backgroundColor: {
              linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
              stops: [
                  [0, '#333'],
                  [1, '#FFF']
              ]
          },
          borderWidth: 1,
          outerRadius: '107%'
      }, {
          // default background
      }, {
          backgroundColor: '#DDD',
          borderWidth: 0,
          outerRadius: '105%',
          innerRadius: '103%'
      }]
  },

  // the value axis
  yAxis: {
      min: 0,
      max: 100,

      minorTickInterval: 'auto',
      minorTickWidth: 0,
      minorTickLength: 0,

      tickWidth: 2,
      tickPosition: 'inside',
      tickLength: 10,
      labels: {
          step: 1,
          rotation: 'auto',
         
      },
      plotBands: [{
          from: 0,
          to: 20,
          color: '#F44336' 
      }, {
          from: 20,
          to: 40,
          color: '#EF9D9D' 
      }, {
          from: 40,
          to: 60,
          color: '#D1D4DC' 
      }, {
          from: 60,
          to: 80,
          color: '#5B9CF6'  
      }, {
          from: 80,
          to: 100,
          color: '#2962FF' 
      }]
  },

  series: [{
      name: 'RSI',
      data: [this.state.technical_rsi],
  },
  ]
});
 Highcharts.chart("technicalGauge2", {

  chart: {
      type: 'gauge',
      plotBackgroundColor: null,
      plotBackgroundImage: null,
      plotBorderWidth: 0,
      plotShadow: false
  },

  title: {
      text: ' '
  },

  pane: {
      startAngle:-100,
      endAngle: 100,
      background: [{
          backgroundColor: {
              linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
              stops: [
                  [0, '#FFF'],
                  [1, '#333']
              ]
          },
          borderWidth: 0,
          outerRadius: '109%'
      }, {
          backgroundColor: {
              linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
              stops: [
                  [0, '#333'],
                  [1, '#FFF']
              ]
          },
          borderWidth: 1,
          outerRadius: '107%'
      }, {
          // default background
      }, {
          backgroundColor: '#DDD',
          borderWidth: 0,
          outerRadius: '105%',
          innerRadius: '103%'
      }]
  },

  // the value axis
  yAxis: {
      min: 0,
      max: 100,

      minorTickInterval: 'auto',
      minorTickWidth: 0,
      minorTickLength: 0,

      tickWidth: 2,
      tickPosition: 'inside',
      tickLength: 10,
      labels: {
          step: 1,
          rotation: 'auto',
         
      },
      plotBands: [{
          from: 0,
          to: 20,
          color: '#F44336' 
      }, {
          from: 20,
          to: 40,
          color: '#EF9D9D' 
      }, {
          from: 40,
          to: 60,
          color: '#D1D4DC' 
      }, {
          from: 60,
          to: 80,
          color: '#5B9CF6'  
      }, {
          from: 80,
          to: 100,
          color: '#2962FF' 
      }]
  },

  series: [{
      name: 'RSI',
      data: [40],
  },
  ]
}); 
Highcharts.chart("technicalGauge3", {

  chart: {
      type: 'gauge',
      plotBackgroundColor: null,
      plotBackgroundImage: null,
      plotBorderWidth: 0,
      plotShadow: false
  },

  title: {
      text: ' '
  },

  pane: {
      startAngle:-100,
      endAngle: 100,
      background: [{
          backgroundColor: {
              linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
              stops: [
                  [0, '#FFF'],
                  [1, '#333']
              ]
          },
          borderWidth: 0,
          outerRadius: '109%'
      }, {
          backgroundColor: {
              linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
              stops: [
                  [0, '#333'],
                  [1, '#FFF']
              ]
          },
          borderWidth: 1,
          outerRadius: '107%'
      }, {
          // default background
      }, {
          backgroundColor: '#DDD',
          borderWidth: 0,
          outerRadius: '105%',
          innerRadius: '103%'
      }]
  },

  // the value axis
  yAxis: {
      min: 0,
      max: 100,

      minorTickInterval: 'auto',
      minorTickWidth: 0,
      minorTickLength: 0,

      tickWidth: 2,
      tickPosition: 'inside',
      tickLength: 10,
      labels: {
          step: 1,
          rotation: 'auto',
         
      },
      plotBands: [{
          from: 0,
          to: 20,
          color: '#F44336' 
      }, {
          from: 20,
          to: 40,
          color: '#EF9D9D' 
      }, {
          from: 40,
          to: 60,
          color: '#D1D4DC' 
      }, {
          from: 60,
          to: 80,
          color: '#5B9CF6'  
      }, {
          from: 80,
          to: 100,
          color: '#2962FF' 
      }]
  },

  series: [{
      name: 'RSI',
      data: [20],
  },
  ]
});Highcharts.chart("technicalGauge4", {

  chart: {
      type: 'gauge',
      plotBackgroundColor: null,
      plotBackgroundImage: null,
      plotBorderWidth: 0,
      plotShadow: false
  },

  title: {
      text: ' '
  },

  pane: {
      startAngle:-100,
      endAngle: 100,
      background: [{
          backgroundColor: {
              linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
              stops: [
                  [0, '#FFF'],
                  [1, '#333']
              ]
          },
          borderWidth: 0,
          outerRadius: '109%'
      }, {
          backgroundColor: {
              linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
              stops: [
                  [0, '#333'],
                  [1, '#FFF']
              ]
          },
          borderWidth: 1,
          outerRadius: '107%'
      }, {
          // default background
      }, {
          backgroundColor: '#DDD',
          borderWidth: 0,
          outerRadius: '105%',
          innerRadius: '103%'
      }]
  },

  // the value axis
  yAxis: {
      min: 0,
      max: 100,

      minorTickInterval: 'auto',
      minorTickWidth: 0,
      minorTickLength: 0,

      tickWidth: 2,
      tickPosition: 'inside',
      tickLength: 10,
      labels: {
          step: 1,
          rotation: 'auto',
         
      },
      plotBands: [{
          from: 0,
          to: 20,
          color: '#F44336' 
      }, {
          from: 20,
          to: 40,
          color: '#EF9D9D' 
      }, {
          from: 40,
          to: 60,
          color: '#D1D4DC' 
      }, {
          from: 60,
          to: 80,
          color: '#5B9CF6'  
      }, {
          from: 80,
          to: 100,
          color: '#2962FF' 
      }]
  },

  series: [{
      name: 'RSI',
      data: [10],
  },
  ]
});
/*---- Set technical gauge  end----*/
          }
          if(type=="EMA")
          {
            var total=0;
            var count=1;
            for(var i =0;i<response.data.length;i++)
            {
              total+=parseInt(response.data[i].close);
                switch(count)
                {
                  case 10:
                    this.setState({technical_ema_10:total/10});
                    break;
                  case 20:
                    this.setState({technical_ema_20:total/20});
                    break;
                  case 30:
                    this.setState({technical_ema_30:total/30});
                    break;
                  case 50:
                    this.setState({technical_ema_50:total/50});
                    break;
                  case 100:
                    this.setState({technical_ema_100:total/100});
                    break;
                  case 200:
                    this.setState({technical_ema_200:total/100});
                    break;

                }
                count++;
            }
          }
          if(type=="SMA")
          {
            var total=0;
            var count=1;
            for(var i =0;i<response.data.length;i++)
            {

              total+=parseInt(response.data[i].close);
                switch(count)
                {
                  case 10:
                    this.setState({technical_sma_10:total/10});
                    break;
                  case 20:
                    this.setState({technical_sma_20:total/20});
                    break;
                  case 30:
                    this.setState({technical_sma_30:total/30});
                    break;
                  case 50:
                    this.setState({technical_sma_50:total/50});
                    break;
                  case 100:
                    this.setState({technical_sma_100:total/100});
                    break;
                  case 100:
                    this.setState({technical_sma_200:total/100});
                    break;

                }
                count++;
            }
          }
        }
    }).catch((error) => {
      console.log("errortechnical", error);
    });
  }
  sendPuclicChatMessage(){
    if(this.state.public_chat_message=='')
    {
      alert("Enter a message you want to send");
      return;
    }

    let accessToekn = localStorage.getItem('accessToken');
    var authData = 'Token ' + accessToekn;
    axios
    .post(constants.API_URL+"/chat/public/chat/?room=public_chat", {
      "content":this.state.public_chat_message,
      "username":this.state.userEmail
    },{
      headers: {
        "Content-Type": "application/json",
        Authorization: authData
      }
    })
    .then((response) => {
      if(response.status==200)
      {
        this.setState({public_chat_message:""});
this.getPublicChat();
      }

      console.log("Public chat response ",response.data);
    }).catch((error) => {
      console.log("error", error);
    });

  }
  getUserInfo(id, accessToken) {
    authService
      .getUser(accessToken, id)
      .then((response) => {
        if(response.status==200)
        {
            this.setState({
              userEmail:response.data.email
            })
        }
      })
      .catch((error) => {
        // if (
        //   error &&
        //   error.response &&
        //   error.response.data &&
        //   error.response.data.error !== undefined &&
        //   error.response.data.error !== null &&
        //   error.response.data.error !== ""
        // ) {
        //   toast.error(error.response.data.error, {
        //     autoClose: 6000,
        //   });
        // }
        // if (
        //   error.response.data.detail !== undefined &&
        //   error.response.data.detail !== null &&
        //   error.response.data.detail !== ""
        // ) {
        //   if (error.response.data.detail === "Invalid token.") {
        //     //  window.location.href = `${window.location.origin}/login`
        //     this.setState({ accessToken: null });
        //     // toast.error(error.response.data.detail + "Please login.", {
        //     //   autoClose: 6000,
        //     // })
        //   }
        // }
      });
  }
  componentDidMount() { 
    let accessToken = localStorage.getItem("accessToken");
    this.setState({
      accessToken: accessToken,
    });

    this.getResult();
    var obj={
      "symbol_name":this.state.symbolName,
      "symbol":this.state.slctSymbol
    };
    localStorage.setItem("symbol_data",JSON.stringify(obj));
    let accessToekn = localStorage.getItem('accessToken');
    var authData = 'Token ' + accessToekn;

    let chartFilter = { multiplier: this.state.multiplier, span: this.state.timespan, grid: this.state.gridShow, startDate: this.state.startD, endDate: this.state.end };

    axios.post(`https://stock-trading-backend.itechnolabs.tech/chart-layout/`,
      { name: this.state.name, symbol: this.state.slctSymbol, graph_type: this.state.graphType, chart_filter: JSON.stringify(chartFilter) },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: authData
        }
      },

    )
      .then((response) => {

        var url = window.location.href;

        url = url.replace('liverates', '');

        // var split = url.split("liverates/");
        // var secondSubString = split[0];


        // alert('secondSubString' + secondSubString);

        var newUrl = url + 'charts/' + response.data.id + '/';

        //window.open(newUrl, '_blank');

        //window.location.replace(newUrl);



      }).catch((error) => {
        //console.log("error", error);
      });
  }
  loadMyStreams() {

    let accessToekn = localStorage.getItem('accessToken');
    var authData = 'Token ' + accessToekn;
  
    axios
      .get(`https://stock-trading-backend.itechnolabs.tech/stream/`, {
      headers: {
        "Content-Type": "application/json"
      }
      })
      .then((response) => {
      this.setState({ myStreamsList: response.data.data });
  
       
      }).catch((error) => {
  
      });
    }
    getPublicChat()
{
  let accessToekn = localStorage.getItem('accessToken');
  var authData = 'Token ' + accessToekn;
  axios
  .get(constants.API_URL+"/chat/public/chat/?room=public_chat", {
    headers: {
      "Content-Type": "application/json",
      Authorization: authData
    }
  })
  .then((response) => {
    if(response.status==200 )
    {
      this.setState({public_chats:response.data.chat});
    }
    console.log("Public chat response ",response.data);
  }).catch((error) => {
    console.log("error", error);
  });
}
getNotifications()
{
  let accessToekn = localStorage.getItem('accessToken');
  var authData = 'Token ' + accessToekn;
  axios
  .get(constants.API_URL+"/notification/", {
    headers: {
      "Content-Type": "application/json",
      Authorization: authData
    }
  })
  .then((response) => {
    if(response.status==200 )
    {
      this.setState({list_notifications:response.data.data});
    }
    console.log("Notifications response ",response.data);
  }).catch((error) => {
    console.log("error", error);
  });
}
getUsersList()
{
  let accessToekn = localStorage.getItem('accessToken');
  var authData = 'Token ' + accessToekn;
  axios
  .get(constants.API_URL+"/chat/user_list/search", {
    headers: {
      "Content-Type": "application/json",
      Authorization: authData
    }
  })
  .then((response) => {
    if(response.status==200 )
    {
      for(var i=0;i<response.data.users.length;i++)
      {
        var obj={
          value:response.data.users[i].id,
          label:response.data.users[i].first_name
                }
        this.setState({users_list:[...this.state.users_list,obj]});
      }
      
    }
    console.log("users list response ",response.data);
  }).catch((error) => {
    console.log("error", error);
  });
}
userSelect(user)
{
  this.setState({selected_private_user:user.value});
}
sendMessage()
{
  if(this.state.selected_private_user==0)
  {
    alert("Please select user you want to send message.");
  }
  else if (this.state.private_user_message=="")
  {
    alert("Please enter a message");
  }
  else
  {
    alert("Message Sent Successfully");
  }

}
handleInputChange(e) {
  this.setState({
    [e.target.name]: e.target.value
  })
}
  componentDidMount(){
    var obj={
      "symbol_name":this.state.symbolName,
      "symbol":this.state.slctSymbol
    };
    localStorage.setItem("symbol_data",JSON.stringify(obj));
    $("#radioPrivate").prop("checked", true);
    $("#radioTutorial").prop("checked", true);
    $("#radioNeutral").prop("checked", true);

    this.getWatchlist();
  this.getStockDetail();
   this.getStockFullDetails();
     this.getStockPerformance();
     this.getAskAndBid();
     this.getIncomeStatement();
     this.getStockProfile();
     this.getStockNews();
     this.getEconomicCalendar();
     this.getEarningCalendar();
     this.getAlerts();
     
     this.getMyIdeas();
     this.getMyIdeaCategories();

  this.getStockDetail();
   this.getStockFullDetails();
     this.getStockPerformance();
     this.getAskAndBid();
     this.getStockProfile();
     this.getStockNews();
     this.getEconomicCalendar();
     this.getEarningCalendar();
      this.getAlerts();
      
     this.getMyIdeas();
     this.getMyIdeaCategories();
     this.loadMyStreams();

     this.getUsersList();
    this.getPublicChat();
    this.getNotifications();
    // setTimeout(function(){
    //   this.getCalculations();
    // },3000);
     
  
  }
  render() {

    const {
      graphType,
      gridShow,
      status_line_symbol,
      symbol_variation,
      ohlc_values,
      market_status,
      vol_values,
      sym_name_label,
      sym_last_price_label,
      bar_change_val,
      sym_highlowlast_label,
      pre_post_mkt_price,
      askBid,
      addPlusAlert,
      newSeriesIndicator,
      lock_price_to_bar_ratio,
      lock_price_to_bar_ratio_val,
      countdownBar,
      lo_overlapping_label,
      date_format_val,
      scale_placement,
      newSymbol,
      modalShow,
      slctSymbol,
      symbolName,
      stock,
      timespan,
      multiplier,
      startD,
      endD,
      fullScreen,
      compareChart,
      isActive,
      condTime,
      condDate,
      cond_value,
      action_method,
      alert_name,
      alert_msg,
      up_color_color,
      alpha_color,
      line_color_color,
      down_color_color,
      last_price_color,
      showHighLowerLine,
      markerPoints,
      count,
      callout,
      balloon,
      arrowLeft,
      arrowMark,
      flagMark,
      arrowDown,
      arrowUp,
      arrowRight,
      get_indicator_data,
      crossHair,
      textCallout,
      showTool,
      trendLine
    } = this.state;



    const styles = reactCSS({
      'default': {
        color: {
          width: '36px',
          height: '14px',
          borderRadius: '2px',
          background: `rgba(${this.state.show_up_color.r}, ${this.state.show_up_color.g}, ${this.state.show_up_color.b}, ${this.state.show_up_color.a})`,
        },
        backGroundOHLC: {
          background: `rgba(${this.state.alpha_color.r}, ${this.state.alpha_color.g}, ${this.state.alpha_color.b}, ${this.state.alpha_color.a})`,
        },
        colorDown: {
          width: '36px',
          height: '14px',
          borderRadius: '2px',
          background: `rgba(${this.state.show_down_color.r}, ${this.state.show_down_color.g}, ${this.state.show_down_color.b}, ${this.state.show_down_color.a})`,
        },
        colorLastLine: {
          width: '36px',
          height: '14px',
          borderRadius: '2px',
          background: `rgba(${this.state.show_last_price.r}, ${this.state.show_last_price.g}, ${this.state.show_last_price.b}, ${this.state.show_last_price.a})`,
        },
        swatch: {
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
      },
    });
    const listItems = this.state.myLayoutList.map((item, index) => (

      // var  url = 'charts/'+ item.id;
      <div  key={index} class="load_layout_inner">&nbsp;
        <NavLink to='/charts/e9b9c3ee-e349-4981-872a-608349feb76f'>
          <div class="laod_layout_center">
            <div class="layout_name">{item.name}</div>
          </div>
        </NavLink>
        <i class="fas fa-arrow-down-short-wide" aria-hidden="true"></i>
      </div>
    ))
    const icons = this.state.icons.map((item, index) => (

      <li class="inner_ioc"><div class="item-lit6"><i onClick={(e) =>
        this.itemSelected(item.name,item.code)
      } class={"fa "+item.name}></i>

      </div></li>

    ))
    
    return (

      <div>
        <header class="show_hide_on_mouse">
          <div className="custom_container">
            <div className="row">
              <div className="col-md-12">
                <Header />
              </div>
            </div>
          </div>
        </header>

        <div className="wrapper">
          <nav id="sidebar">
            <ul className="list-unstyled components">
            <li className="active">
                <a
                  href="#"
                  data-target="#bar"
                  data-toggle="collapse"
                  aria-expanded="false"
                  className="dropdown-toggle"
                >
                  <i class="fa-solid fa-bars"></i>
                </a>
                <ul class="collapse sidec_custom card-ul" id="bar">
                  <li>
                    <a href="#">
                      <div className="snap_left">Save layout</div>
                      <span className="ctrl_span">Ctrl + S</span>
                    </a>
                  </li>
                  <li>
                    <a href="#" data-toggle="modal" data-target="#exportModal">
                      <div className="snap_left">Export chart data</div>
                    </a>
                  </li>
                  <li className="search_li">
                    <a href="#" data-toggle="modal" data-target="#searchModal">
                      <div className="snap_left">
                        <div className="symbol_left_bar">
                          <i class="fa-solid fa-magnifying-glass"></i>
                          <input type="text" placeholder="Quick Search" />
                        </div>
                      </div>
                      <span className="ctrl_span">Ctrl + K</span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <div className="snap_left">Help center</div>
                    </a>
                  </li>
                  <li>
                    <a href="#" data-toggle="modal" data-target="#newModal">
                      <div className="snap_left">What's new</div>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <div className="snap_left">Home</div>
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      data-target="#more"
                      data-toggle="collapse"
                      aria-expanded="false"
                      className="dropdown-toggle"
                    >
                      <div className="snap_left">More</div>
                    </a>
                    <ul
                      class="collapse sidec_custom side-custom2 card-ul"
                      id="more"
                    >
                      <li>
                        <a href="#">
                          <div className="snap_left">Ideas</div>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <div className="snap_left">Markets</div>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <div className="snap_left">Scripts</div>
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="#">
                      <div className="snap_left">Dark color theme</div>
                      <span className="ctrl_span">
                        <label class="switch">
                          <input type="checkbox" class="switch-input" value="1" checked={this.state.themeCheck} onChange={(e) => this.handleThemeChange(e)} />
                          <span
                            class="switch-label"
                            data-on="On"
                            data-off="Off"
                          ></span>
                          <span class="switch-handle"></span>
                        </label>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <div className="snap_left">Drawings panel</div>
                      <span className="ctrl_span">
                        <label class="switch">
                          <input type="checkbox" class="switch-input" />
                          <span
                            class="switch-label"
                            data-on="On"
                            data-off="Off"
                          ></span>
                          <span class="switch-handle"></span>
                        </label>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      data-target="#language"
                      data-toggle="collapse"
                      aria-expanded="false"
                      className="dropdown-toggle"
                    >
                      <div className="snap_left">Language</div>
                      <span className="ctrl_span">English</span>
                    </a>
                    <ul
                      class="collapse sidec_custom side-custom2 card-ul"
                      id="language"
                    >
                      <li>
                        <a href="#">
                          <div className="snap_left">English</div>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <div className="snap_left">English</div>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <div className="snap_left">English</div>
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="#">
                      <div className="snap_left">Keyboard shortcuts</div>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <div className="snap_left">Get desktop app</div>
                    </a>
                  </li>
                  <li>
                    <a onClick={this.onLogout}>
                      <div className="snap_left">Sign Out</div>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <div className="snap_left">Join now</div>
                    </a>
                  </li>
                </ul>
              </li>
              <li class="annotation-container">
                <a
                  href="#"
                  data-target="#dot"
                  data-toggle="collapse"
                  aria-expanded="false"
                  className="dropdown-toggle"
                >
                  <i class="fa-solid fa-circle"></i>
                </a>
                <div
                  id="dot"
                  class="collapse sidec_custom card-ul side_ul_card"
                >
                  <ul class="card-ul">
                    <li onClick={this.insertCrossHair}>
                      <a>
                        <i class="fa-solid fa-crosshairs icon_c"></i> Cross{" "}
                        <i class="far fa-star"></i>
                      </a>
                    </li>
                    <li onClick={this.insertTrendLine}>
                      <a className="highcharts-segment">
                        <i class="fa-solid fa-line icon_c"></i> Trendline{" "}
                        <i class="far fa-star"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="annotation-container">
                <a href="#" data-target="#dot12" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle" >
                  <i class="fa-solid fa-list"></i>
                </a>
                <div id="dot12" class="collapse sidec_custom card-ul side_ul_card">
                  <ul class="card-ul">
                    <li>
                      <a class="highcharts-arrow-segment">
                        <i class="fa-solid fa-arrows-h icon_c"></i>Path                      
                      </a>
                    </li>
                    <li>
                      <a class=" highcharts-rectangle-annotation">
                        <i class="fa-solid fa-square icon_c"></i>rectangle                       
                      </a>
                    </li> 
                    {/* <li>
                      <a class=" highcharts-inputText-annotation">
                        <i class="fa-solid fa-square icon_c"></i>Text check                       
                      </a>
                    </li> */}
                    <li>
                      <a class=" highcharts-circle-annotation">
                        <i class="fa-solid fa-circle icon_c"></i>Circle                       
                      </a>
                    </li>  
                    <li>
                      <a class=" highcharts-ellipse-annotation">
                        <i class="fa-solid fa-circle icon_c"></i>Ellipsis                       
                      </a>
                    </li> 
                    <li>
                      <a class=" highcharts-crooked3">
                        <i class="fa-solid fa-sort-asc icon_c" style={{height:'7px'}}></i>Triangle                       
                      </a>
                    </li>   
                    <li>
                      <a class="highcharts-elliott5">
                        <i class="fa-solid fa-sort-asc icon_c" style={{height:'7px'}}></i>Polygon                       
                      </a>
                    </li>         
                  </ul>
                </div> 
              </li>
              <li class="annotation-container" onClick={this.showTool}>
                <a href="#">
                  <i class="fa-solid fa-circle-info"></i>
                </a> 
              </li>
              <li>
                <a
                  href="#"
                  data-target="#homeSubmenu"
                  data-toggle="collapse"
                  aria-expanded="false"
                  className="dropdown-toggle"
                >
                  <i class="fa-solid fa-t"></i>
                </a>
                {/* <a href="#" data-toggle="collapse" data-target="#homeSubmenu"><i class="fa-solid fa-indent"></i></a> */}
                <ul className="collapse sidec_custom" id="homeSubmenu">
                  <li onClick={(e) =>
        this.itemSelected('fa-text','text','text')}>
                    <a>Text</a>
                  </li>
                  <li onClick={(e) =>
        this.itemSelected('fa-comment-o','baloon','baloon')}>
                    <a>Balloon</a>
                  </li>
                  <li onClick={(e) =>
        this.itemSelected('fa-long-arrow-left','aml','aml')}>
                    <a>Arrow Mark Left</a>
                  </li>
                  <li onClick={(e) =>
        this.itemSelected('fa-long-arrow-right','amr','amr')}>
                    <a>Arrow Mark Right</a>
                  </li>
                  <li onClick={(e) =>
        this.itemSelected('fa-long-arrow-up','amu','amu')}>
                    <a>Arrow Mark Up</a>
                  </li>
                  <li onClick={(e) =>
        this.itemSelected('fa-long-arrow-down','amd','amd')}>
                    <a>Arrow Mark Down</a>
                  </li>
                  <li onClick={(e) =>
        this.itemSelected('fa-flag-o','amf','amf')}>
                    <a>Flag Mark</a>
                  </li>
                  <li onClick={(e) =>
        this.itemSelected('fa-long-arrow-right','am','am')}>
                    <a>Arrow Marker</a>
                  </li>
                </ul>
              </li>
              
              <li>
                <li class="custom_toggle">
                  <a
                    href="#"
                    data-target="#homeSubmenu9"
                    data-toggle="collapse"
                    aria-expanded="false"
                    className="dropdown-toggle"
                  >
                    <i class="fa-solid fa-indent"></i>
                  </a>
                  {/* <a href="#" data-toggle="collapse" data-target="#homeSubmenu"><i class="fa-solid fa-indent"></i></a> */}
                  <ul className="collapse sidec_custom to_ico" id="homeSubmenu9">
                    <li class="seach_outer">
                      <input type="search" placeholder="Search" onKeyUp={this.searchIcon} />
                      <i class="fa fa-search" aria-hidden="true"></i>
                    </li>
                    <ul class="small_icon">
                      {icons}
                    </ul>
                  </ul>
                </li>
              </li>
              <li>
                <li class="custom_toggle">
                  <a
                  data-toggle="modal" data-target="#dateRangeModal"
                    href="javascript:void(0);"
                
                  >
                    <i class="fa fa-calendar-plus-o"></i>
                  </a>
                  <div
          className="modal modal_customs fade"
          id="dateRangeModal"
          tabindex="-1"
          aria-labelledby="alertModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title text-black" id="DJIAModalLabel">
                  Date Range
                </h5>
                <button
                  type="button"
                  id="alertclose"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body custom_datarow">
              <div className="row mt-4 dateRow custom_mt">
                  <div className="col-md-4">
                    <lable style={{color:"black"}}>GridLines</lable>
                    <select
                      name="gridShow"
                      class="browser-default custom-select gridslct"
                      onChange={this.handleChange.bind(this)}
                    >
                      <option value="1" selected>
                        Show Grid
                      </option>
                      <option value="0">Hide Grid</option>
                    </select>
                  </div>
                  <div className="col-md-8 custom_cole"><lable style={{color:"black"}}>Date Range</lable> <div className="blue_outr45"><DateRangPick onSlctDate={this.onSlctDate.bind(this)} /></div></div>
               
                  
                </div>
              </div>
              <div class="modal-footer modal_footer_custom">
                <div class="btns_outer_footer custom_photo1">
                  <button type="button" class="cancel_btn" data-dismiss="modal">
                    Cancel
                  </button> 
                </div>
              </div>
            </div>
          </div>
        </div>
                  
                </li>
              </li>
            

            </ul>
          </nav>
          <div id="content">
            <div className="sidebar_btn">
              <button
                type="button"
                id="sidebarCollapse"
                className="btn btn-info"
              >
                <i className="fas fa-align-left"></i>
              </button>
            </div>
            <div className="top_bar">
              <ul>
                <li
                  data-toggle="tooltip"
                  title="Symbol Search"
                  data-custom-class="text-sm"
                >
                  <a href="#" data-toggle="modal" data-target="#DJIAModal">
                    COMPARE
                  </a>
                </li>  
                <li data-toggle="tooltip" title="5 Minutes">
                  <a href="#" data-toggle="collapse" data-target="#demo992">
                    {multiplier + " " + timespan}
                  </a>
                  <div id="demo992" class="collapse collapse_custom">
                    <div className="accordian_custom">
                      <div class="accordion" id="faq">
                        <div class="card">
                          <div class="card-header" id="faqhead2">
                            <a
                              class="btn btn-header-link collapsed"
                              data-toggle="collapse"
                              data-target="#faq2"
                              aria-expanded="true"
                              aria-controls="faq2"
                            >
                              MINUTES
                            </a>
                          </div>

                          <div
                            id="faq2"
                            class="collapse"
                            aria-labelledby="faqhead2"
                            data-parent="#faq"
                          >

<ul class="card-ul">
                              <li>
                                <a
                                  onClick={(e) => this.onSlctTym("1", "minute", true,1000)}
                                >
                                  1 Minute{" "}
                                </a>
                              </li>

                              <li>
                                <a
                                  onClick={(e) => this.onSlctTym("2", "minute", true,1000)}
                                >
                                  2 Minute{" "}
                                </a>
                              </li>
                              <li>
                                <a
                                  onClick={(e) => this.onSlctTym("5", "minute", true,1000)}
                                >
                                  5 Minute{" "}
                                </a>
                              </li>
                              <li>
                                <a
                                  onClick={(e) =>
                                    this.onSlctTym("15", "minute", true,1000)
                                  }
                                >
                                  15 Minute{" "}
                                </a>
                              </li>
                              <li>
                                <a
                                  onClick={(e) =>
                                    this.onSlctTym("30", "minute", true,1000)
                                  }
                                >
                                  30 Minute{" "}
                                </a>
                              </li>
                            </ul>        


                          </div>
                        </div>
                        <div class="card">
                          <div class="card-header" id="faqhead4">
                            <a
                              href="#"
                              class="btn btn-header-link collapsed"
                              data-toggle="collapse"
                              data-target="#faq3"
                              aria-expanded="true"
                              aria-controls="faq3"
                            >
                              HOURS
                            </a>
                          </div>

                          <div
                            id="faq3"
                            class="collapse"
                            aria-labelledby="faqhead3"
                            data-parent="#faq"
                          >
                            <ul class="card-ul">
                              <li>
                                <a onClick={(e) => this.onSlctTym("1", "hour", true,1000)}>
                                  1 Hour{" "}
                                </a>
                              </li>
                              <li>
                                <a onClick={(e) => this.onSlctTym("2", "hour", true,1000)}>
                                  2 Hours{" "}
                                </a>
                              </li>
                              <li>
                                <a onClick={(e) => this.onSlctTym("3", "hour", true,1000)}>
                                  3 Hours{" "}
                                </a>
                              </li>
                              <li>
                                <a onClick={(e) => this.onSlctTym("4", "hour", true,1000)}>
                                  4 Hours{" "}
                                </a>
                              </li>
                              <li>
                                <a onClick={(e) => this.onSlctTym("6", "hour", true,1000)}>
                                  6 Hours{" "}
                                </a>
                              </li>
                              <li>
                                <a onClick={(e) => this.onSlctTym("8", "hour", true,1000)}>
                                  8 Hours{" "}
                                </a>
                              </li>
                              <li>
                                <a
                                  onClick={(e) => this.onSlctTym("12", "hour", true,1000)}
                                >
                                  12 Hours{" "}
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="card">
                          <div class="card-header" id="faqhead3">
                            <a
                              href="#"
                              class="btn btn-header-link collapsed"
                              data-toggle="collapse"
                              data-target="#faq4"
                              aria-expanded="true"
                              aria-controls="faq4"
                            >
                              DAYS
                            </a>
                          </div>

                          <div
                            id="faq4"
                            class="collapse"
                            aria-labelledby="faqhead4"
                            data-parent="#faq"
                          >
                            <ul class="card-ul">
                              <li>
                                <a onClick={(e) => this.onSlctTym("1", "hour")}>
                                  1 Day{" "}
                                </a>
                              </li>

                              <li>
                                <a onClick={(e) => this.onSlctTym("3", "day")}>
                                  3 Days{" "}
                                </a>
                              </li>
                              <li>
                                <a onClick={(e) => this.onSlctTym("4", "day")}>
                                  4 Days{" "}
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div class="card">
                          <div class="card-header" id="faqhead5">
                            <a
                              href="#"
                              class="btn btn-header-link collapsed"
                              data-toggle="collapse"
                              data-target="#faq8"
                              aria-expanded="true"
                              aria-controls="faq5"
                            >
                              WEEK
                            </a>
                          </div>

                          <div
                            id="faq8"
                            class="collapse"
                            aria-labelledby="faqhead5"
                            data-parent="#faq"
                          >
                            <ul class="card-ul">
                              <li>
                                <a onClick={(e) => this.onSlctTym("1", "week")}>
                                  1 Week{" "}
                                </a>
                              </li>
                              <li>
                                <a onClick={(e) => this.onSlctTym("2", "week")}>
                                  2 Week{" "}
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div class="card">
                          <div class="card-header" id="faqhead5">
                            <a
                              href="#"
                              class="btn btn-header-link collapsed"
                              data-toggle="collapse"
                              data-target="#faq9"
                              aria-expanded="true"
                              aria-controls="faq5"
                            >
                              MONTH
                            </a>
                          </div>

                          <div
                            id="faq9"
                            class="collapse"
                            aria-labelledby="faqhead5"
                            data-parent="#faq"
                          >
                            <ul class="card-ul">
                              <li>
                                <a
                                  onClick={(e) => this.onSlctTym("1", "month")}
                                >
                                  1 Month{" "}
                                </a>
                              </li>
                              <li>
                                <a
                                  onClick={(e) => this.onSlctTym("2", "month")}
                                >
                                  2 Month{" "}
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div class="card">
                          <div class="card-header" id="faqhead5">
                            <a
                              href="#"
                              class="btn btn-header-link collapsed"
                              data-toggle="collapse"
                              data-target="#faqc"
                              aria-expanded="true"
                              aria-controls="faq5"
                            >
                              YEAR
                            </a>
                          </div>

                          <div
                            id="faqc"
                            class="collapse"
                            aria-labelledby="faqhead5"
                            data-parent="#faq"
                          >
                            <ul class="card-ul">
                              <li>
                                <a onClick={(e) => this.onSlctTym("1", "year")}>
                                  1 YEAR{" "}
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li data-toggle="tooltip" role="button" title={graphType}>
                  <a href="#" data-toggle="collapse" data-target="#line">
                    <i class="fa-solid fa-grip-lines-vertical"></i>
                  </a>
                  <div id="line" class="collapse collapse_custom">
                    <ul class="card-ul graphhs">
                      <li title="Bars">
                        <a>
                          <span onClick={this.btnTapped("ohlc")}>Bars </span>
                          <i
                            class={
                              this.state.isActive.includes("ohlc")
                                ? "fas  fa-star slctdstar"
                                : "far  fa-star"
                            }
                            id="ohlc"
                            onClick={this.addFav("ohlc")}
                          ></i>
                        </a>
                      </li>
                      <li title="Candles">
                        <a>
                          <span onClick={this.btnTapped("candlestick")}>
                            Candles{" "}
                          </span>
                          <i
                            id="candlestick"
                            class={
                              this.state.isActive.includes("candlestick")
                                ? "fas  fa-star slctdstar"
                                : "far  fa-star"
                            }
                            onClick={this.addFav("candlestick")}
                          ></i>
                        </a>
                      </li>
                      <li title="renko">
                        <a>
                          <span onClick={this.btnTapped("renko")}>
                            Renko
                          </span>
                          <i
                            id="renko"
                            class={
                              this.state.isActive.includes("renko")
                                ? "fas  fa-star slctdstar"
                                : "far  fa-star"
                            }
                            onClick={this.addFav("renko")}
                          ></i>
                        </a>
                      </li>
                      <li title="Line">
                        <a>
                          <span onClick={this.btnTapped("line")}>Line </span>
                          <i
                            id="line"
                            class={
                              this.state.isActive.includes("line")
                                ? "fas  fa-star slctdstar"
                                : "far  fa-star"
                            }
                            onClick={this.addFav("line")}
                          ></i>
                        </a>
                      </li>
                      <li title="Area">
                        <a>
                          <span onClick={this.btnTapped("area")}>Area </span>
                          <i
                            id="area"
                            class={
                              this.state.isActive.includes("area")
                                ? "fas  fa-star slctdstar"
                                : "far  fa-star"
                            }
                            onClick={this.addFav("area")}
                          ></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li data-toggle="tooltip" title="Compare or Add Symbol">
                  <a
                    onClick={(e) =>
                      this.setState({
                        modalShow: !modalShow,
                        compareChart: false,
                      })
                    }
                  >
                    <i class="fa-solid fa-circle-plus"></i>Symbol
                  </a>
                </li>
                <li
                  data-toggle="tooltip"
                  title="Indicators, Mytrices & Stratigies"
                >
                  <a href="#" data-toggle="modal" data-target="#indicatorModal" onClick={this.refreshCheckIndicator} >
                    <i class="fa-solid fa-chart-line"></i>Indicators
                  </a>
                </li>
                <li data-toggle="tooltip" title="Indicator Templates">
                  <a href="#" data-toggle="collapse" data-target="#template">
                    <i class="fa-solid fa-table-cells-large"></i>Templates
                  </a>
                  <div
                    id="template"
                    class="collapse collapse_custom template_collapse"
                  >
                    <div className="save_indicator">
                      <i class="fa-solid fa-cloud-arrow-up"></i>Save indicator
                      template...
                    </div>
                    <div className="default-template">DEFAULT TEMPLATEs</div>
                    <ul class="card-ul">
                      <li>
                        <a href="#">
                          <div className="default_left_t"  onClick={(e) => this.handleIndicatorClick(e,'bwT','template')} style={{ cursor:'pointer'}}>
                            Bill William's 3 Lines
                            <p>Volume, Moving Average * 3</p>
                          </div>
                          <i
                            class={
                              this.state.templatesActive.includes("bill")
                                ? "fas  fa-star slctdstar"
                                : "far  fa-star"
                            }
                            id="bill"
                            onClick={this.addFavTemplates("bill")}
                          ></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <div className="default_left_t"  onClick={(e) => this.handleIndicatorClick(e,'demaT','template')} style={{ cursor:'pointer'}}>
                            Displaced EMA
                            <p>Volume, Moving Average Exponentional</p>
                          </div>
                          <i
                            class={
                              this.state.templatesActive.includes("ema")
                                ? "fas  fa-star slctdstar"
                                : "far  fa-star"
                            }
                            id="ema"
                            onClick={this.addFavTemplates("ema")}
                          ></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <div className="default_left_t" onClick={(e) => this.handleIndicatorClick(e,'maexpT','template')} style={{ cursor:'pointer'}}>
                            MA Exp Ribbon
                            <p>Volume, Moving Average Exponentional * 8</p>
                          </div>
                          <i
                            class={
                              this.state.templatesActive.includes("Ribbon")
                                ? "fas  fa-star slctdstar"
                                : "far  fa-star"
                            }
                            id="Ribbon"
                            onClick={this.addFavTemplates("Ribbon")}
                          ></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <div className="default_left_t" onClick={(e) => this.handleIndicatorClick(e,'oscillatorsT','template')} style={{ cursor:'pointer'}}>
                            Oscillators
                            <p>
                              Volume, Stochastic RSI, Relative Strength Index,
                              Commodity Channel Index
                            </p>
                          </div>
                          <i
                            class={
                              this.state.templatesActive.includes("Oscillators")
                                ? "fas  fa-star slctdstar"
                                : "far  fa-star"
                            }
                            id="Oscillators"
                            onClick={this.addFavTemplates("Oscillators")}
                          ></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <div className="default_left_t" onClick={(e) => this.handleIndicatorClick(e,'stT','template')} style={{ cursor:'pointer'}}>
                            Swing Trading
                            <p>Zig Zag, Pivots HL, Vol</p>
                          </div>
                          <i class="far fa-star"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <div className="default_left_t" onClick={(e) => this.handleIndicatorClick(e,'vbT','template')} style={{ cursor:'pointer'}}>
                            Volume Based
                            <p>
                              Volume, Commodity Channel Index, On Balance
                              Volume, Rate Of Change, Chaikin Money Flow
                            </p>
                          </div>
                          <i class="far fa-star slctdstar"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <div className="default_left_t" onClick={(e) => this.handleIndicatorClick(e,'osma','template')} style={{ cursor:'pointer'}}>
                            OSMA
                            {/* <p>
                              Volume, Commodity Channel Index, On Balance
                              Volume, Rate Of Change, Chaikin Money Flow
                            </p> */}
                          </div>
                          <i class="far fa-star slctdstar"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <div className="default_left_t" onClick={(e) => this.handleIndicatorClick(e,'alg','template')} style={{ cursor:'pointer'}}>
                            Aligator
                            {/* <p>
                              Volume, Commodity Channel Index, On Balance
                              Volume, Rate Of Change, Chaikin Money Flow
                            </p> */}
                          </div>
                          <i class="far fa-star slctdstar"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li data-toggle="tooltip" title="Create Alert">
                  <a data-toggle="modal" data-target="#alertModal" href="#">
                    <i class="fa-solid fa-alarm-plus"></i>Alert
                  </a>
                </li>
                <li data-toggle="tooltip" title="Bar Replay">
                  <a href="#">
                    <i class="fa-solid fa-angles-left"></i>Replay
                  </a>
                </li>
                <li data-toggle="tooltip" title="Undo">
                  <a onClick={this.undoClick}>
                    <i class="fa-solid fa fa-undo"></i>
                  </a>
                </li>
                <li data-toggle="tooltip" title="Redo">
                  <a onClick={this.redoClick} >
                    <i class="fa-solid fa fa-redo"></i>
                  </a>
                </li>
                <li class="custom_drop">
        <div class="dropdown">
                  <button class="dropbtn">More</button>
                  <div class="dropdown-content">
                    <a   href="javascript:void(0);" onClick={()=>this.showMoreOptionPanel('watch-list-watchlist',true)} >Watchlist and details</a>
                    <a href="javascript:void(0);" onClick={()=>this.showMoreOptionPanel('watch-list-alert',true)}>Alerts</a>
                    <a href="javascript:void(0);" onClick={()=>this.showMoreOptionPanel('watch-list-headline')}>News</a>
                    <a href="javascript:void(0);" onClick={()=>this.showMoreOptionPanel('watch-list-data-window')}>Data Window</a>
                    {/* <a href="javascript:void(0);" onClick={()=>this.showMoreOptionPanel('watch-list-alert')}>Hotlists</a> */}
                    <a href="javascript:void(0);" onClick={()=>this.showMoreOptionPanel('watch-list-calendar')}>Calendar</a>
                    <a href="javascript:void(0);" onClick={()=>this.showMoreOptionPanel('watch-list-idea',true)}>My Ideas</a>
                    <a href="javascript:void(0);" onClick={()=>this.showMoreOptionPanel('watch-list-public-chat',true)}>Public Chat</a>
                    <a href="javascript:void(0);" onClick={()=>this.showMoreOptionPanel('watch-list-private-chat',true)}>Private Chat</a>
                    {/* <a href="javascript:void(0);">Ideas Stream</a> */}
                    <a href="javascript:void(0);" onClick={()=>this.showMoreOptionPanel('watch-list-stream')}>Streams</a>
                    <a href="javascript:void(0);" onClick={()=>this.showMoreOptionPanel('watch-list-notification',true)}>Notifications</a>
                    {/* <a href="javascript:void(0);" onClick={()=>this.showMoreOptionPanel('watch-list-alert')}>Order Panel</a> */}
                    {/* <a href="javascript:void(0);" onClick={()=>this.showMoreOptionPanel('watch-list-alert')}>DOM</a> */}
                    {/* <a href="javascript:void(0);" onClick={()=>this.showMoreOptionPanel('watch-list-alert')}>Object tree. Multi-select drawings tools</a> */}

                  </div>
                </div></li>
                {/* <li data-toggle="tooltip" title="Bar Replay">
                      <a href="#"><i class="fa-solid fa-arrow-turn-down-left"></i><i class="fa-solid fa-arrow-turn-down-right"></i></a>
                  </li> */}
              </ul>
    
              <div id="modalCreateWatchlist" class="modal fade" role="dialog">
                  <div class="modal-dialog">
                     <div class="modal-content">
                        <div class="modal-header">
                        <h4 class="modal-title">Create Watchlist</h4>
                           <button type="button" id="closeWatchlistModal" class="close" data-dismiss="modal">&times;</button>
                           
                        </div>
                        <div class="modal-body cusom_modal8">
                           <input type="text" placeholder="Enter watchlist Name" value={this.state.txtwatchlistname} onChange={(e)=>this.txtwatchlistChange(e.target.value)} />
                           <button type="button" onClick={()=>this.addWatchlist()}>Add</button>
                        </div>
                     </div>
                  </div>
               </div>
                <div className="watchlist_outer d-none" style={{background:"white !important",height:'auto !important'}}>
                 <div className="close_outer"> <button className="custom_close" type="button" onClick={()=>this.showMoreOptionPanel('close')}>&times;</button></div>
                  {/* for watchlist start */}
   <div class="watch_inner watchlist d-none watch-list-watchlist">
      <div className="top_1">
         <div className="top_icon">
            <div className="dropdown">
               <button className="dropbtn">
                  Watchlist
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 8" width="16" height="8">
                     <path fill="currentColor" d="M0 1.475l7.396 6.04.596.485.593-.49L16 1.39 14.807 0 7.393 6.122 8.58 6.12 1.186.08z"></path>
                  </svg>
               </button>
               <div className="dropdown-content custom_content2">
                  <a className="watchlist5" href="javascript:void(0)" data-target="#modalCreateWatchlist" data-toggle="modal">
                     <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28">
                        <g fill="none" fill-rule="evenodd">
                           <path stroke="currentColor" d="M13 22.5H5.5a2 2 0 0 1-2-2v-14a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2V14"></path>
                           <path stroke="currentColor" stroke-linecap="square" d="M18.5 15.5v8m-4-4h8"></path>
                           <path fill="currentColor" d="M7 8h11v1H7zm0 4h11v1H7zm0 4h5v1H7z"></path>
                        </g>
                     </svg>
                     Create new list…
                  </a>
                 
                  {/* <a href="#">
                     <span class="title-08JkF94b">
                        Red list
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 12" width="14" height="12" fill="currentColor" focusable="false" preserveAspectRatio="none">
                           <path d="M14 12l-4-6 4-6H0v12z"></path>
                        </svg>
                     </span>
                     <div className="circel_outer zer9">0</div>
                  </a> */}
                  {
                     this.state.list_watchlist.map((obj,i)=>(
                      <a href="javascript:void(0)" onClick={()=>this.getWatchlistDetail(obj)}>{obj.name}<span className="circel_outer">{obj.item_counts}</span></a>
                    ))
                  }
               </div>
            </div>
            <div className="right_icon">
               <span className="icon-9pA37sIi">
               {/* data-target="#myModal01"  */}
                  <svg data-toggle="modal"   onClick={(e) =>
                      this.setState({
                        modalShow: !modalShow,
                        compareChart: false,
                      })
                    } xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28" fill="none">
                     <path fill="currentColor" d="M7 13h7V6h1v7h7v1h-7v7h-1v-7H7v-1z"></path>
                  </svg>
               </span>
               {/* <span className="icon-9pA37sIi trin">
                  <svg data-toggle="modal" data-target="#myModal02" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28" fill="none">
                     <path d="M0 0h28v28H0z"></path>
                     <path stroke="currentColor" d="M13.5 23.5a9 9 0 0 0 9-9h-9v-9a9 9 0 0 0 0 18zM22 6l-6.5 6.5m4-8l-4 4m8 0l-4 4"></path>
                     <path stroke="currentColor" d="M24.5 12.5a9 9 0 0 0-9-9v9h9z"></path>
                  </svg>
               </span> */}
               <div id="myModal02" class="modal fade" role="dialog">
                  <div class="modal-dialog">
                     <div class="modal-content">
                        <div class="modal-header">
                           <button type="button" class="close" data-dismiss="modal">&times;</button>
                           <h4 class="modal-title">Watchlist</h4>
                           <p>Add description</p>
                        </div>
                        <div class="modal-body cusom_modal8">
                           <div class="distb_outers">
                              <h4> Symbol distribution</h4>
                              <h1>Graph Image Here</h1>
                           </div>
                           <div class="simple_ou8">
                              <h5>Symbols</h5>
                              <p>Currency in USD<label class="switch">
                                 <input type="checkbox"/>
                                 <span class="slider round"></span>
                                 </label>
                              </p>
                           </div>
                           <div class="simpe_uter">
                              <div class="precing">
                                 <span><button>Price</button><button>Financials</button></span>
                                 <p>
                                    <svg data-toggle="collapse" data-target="#demo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28">
                                       <path fill="currentColor" d="M7.5 13a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM5 14.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0zm9.5-1.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM12 14.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0zm9.5-1.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM19 14.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0z"></path>
                                    </svg>
                                 </p>
                                 <div id="demo" class="collapse">
                                    <ul>
                                       <li>Group by</li>
                                       <li>No group</li>
                                       <li>Currency</li>
                                       <li>Symbol type</li>
                                    </ul>
                                 </div>
                              </div>
                              <div class="triger">
                                 <ul>
                                    <li>
                                       <p>Ticker</p>
                                       <p>16 symbols</p>
                                    </li>
                                    <li>Last</li>
                                    <li>Chg%</li>
                                    <li>Chg</li>
                                    <li>Volume</li>
                                    <li>Avg Vol (10)</li>
                                    <li>Market Cap</li>
                                 </ul>
                              </div>
                              <div class="according_scroll">
                                 <div class="according_outer">
                                    <button data-toggle="collapse" data-target="#demo1">
                                       <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" width="18" height="18" fill="none">
                                          <path stroke="currentColor" d="M8 5l3.5 3.5L8 12"></path>
                                       </svg>
                                       Indices
                                    </button>
                                    <div id="demo1" class="collapse">
                                       <div class="triger cust7">
                                          <ul>
                                             <li>
                                                <span class="custom_jim"> ₿</span>
                                                <p class="nsg">NDQ<br /><span class="usg">US 100 INDEX</span></p>
                                             </li>
                                             <li>12599.63<span class="usd">USD</span></li>
                                             <li><span class="grey">0.41</span></li>
                                             <li><span class="grey">0.092</span></li>
                                             <li class="blank">-</li>
                                             <li class="blank">-</li>
                                             <li class="blank">-</li>
                                          </ul>
                                       </div>
                                       <div class="triger cust7">
                                          <ul>
                                             <li>
                                                <span class="custom_jim"> ₿</span>
                                                <p class="nsg">NDQ<br /><span class="usg">US 100 INDEX</span></p>
                                             </li>
                                             <li>12599.63<span class="usd">USD</span></li>
                                             <li><span class="grey">0.41</span></li>
                                             <li><span class="grey">0.092</span></li>
                                             <li class="blank">-</li>
                                             <li class="blank">-</li>
                                             <li class="blank">-</li>
                                          </ul>
                                       </div>
                                       <div class="triger cust7">
                                          <ul>
                                             <li>
                                                <span class="custom_jim"> ₿</span>
                                                <p class="nsg">NDQ<br /><span class="usg">US 100 INDEX</span></p>
                                             </li>
                                             <li>12599.63<span class="usd">USD</span></li>
                                             <li><span class="grey">0.41</span></li>
                                             <li><span class="grey">0.092</span></li>
                                             <li class="blank">-</li>
                                             <li class="blank">-</li>
                                             <li class="blank">-</li>
                                          </ul>
                                       </div>
                                       <div class="triger cust7">
                                          <ul>
                                             <li>
                                                <span class="custom_jim"> ₿</span>
                                                <p class="nsg">NDQ<br /><span class="usg">US 100 INDEX</span></p>
                                             </li>
                                             <li>12599.63<span class="usd">USD</span></li>
                                             <li><span class="grey">0.41</span></li>
                                             <li><span class="grey">0.092</span></li>
                                             <li class="blank">-</li>
                                             <li class="blank">-</li>
                                             <li class="blank">-</li>
                                          </ul>
                                       </div>
                                    </div>
                                 </div>
                                 <div class="according_outer">
                                    <button data-toggle="collapse" data-target="#demo2">
                                       <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" width="18" height="18" fill="none">
                                          <path stroke="currentColor" d="M8 5l3.5 3.5L8 12"></path>
                                       </svg>
                                       Stocks
                                    </button>
                                    <div id="demo2" class="collapse">
                                       <div class="triger cust7">
                                          <ul>
                                             <li>
                                                <span class="custom_jim"> ₿</span>
                                                <p class="nsg">NDQ<br /><span class="usg">US 100 INDEX</span></p>
                                             </li>
                                             <li>12599.63<span class="usd">USD</span></li>
                                             <li><span class="grey">0.41</span></li>
                                             <li><span class="grey">0.092</span></li>
                                             <li class="blank">-</li>
                                             <li class="blank">-</li>
                                             <li class="blank">-</li>
                                          </ul>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div class="upcoming_outer">
                                 <h4>Upcoming earnings</h4>
                                 <p>There aren't any earnings reports scheduled for the next 14 days</p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               {/* <svg data-toggle="collapse" data-target="#list5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28">
                  <path fill="currentColor" d="M7.5 13a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM5 14.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0zm9.5-1.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM12 14.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0zm9.5-1.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM19 14.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0z"></path>
               </svg> */}
               <div id="list5" class="collapse">
                  <div class="listing_buy67">
                     <div class="n_boy6">
                        <p>Share List<label class="switch"><input type="checkbox" /><span class="slider round"></span></label></p>
                     </div>
                     <div class="mul_li8">
                        <ul>
                           <li>
                              <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="none">
                                 <path stroke="currentColor" d="M8 9.5H6.5a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1V20m-8-1.5h11a1 1 0 0 0 1-1v-11a1 1 0 0 0-1-1h-11a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1z"></path>
                              </svg>
                              Make a copy…
                           </li>
                           <li>
                              <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="none">
                                 <path fill="currentColor" fill-rule="evenodd" clip-rule="evenodd" d="M17.086 6.207a2 2 0 0 1 2.828 0l1.879 1.879a2 2 0 0 1 0 2.828l-.94.94-9 9-1 1-.146.146H6v-4.707l.146-.146 1-1 9-9 .94-.94zm2.121.707a1 1 0 0 0-1.414 0l-.586.586 1.647 1.646 1.646 1.647.586-.586a1 1 0 0 0 0-1.414l-1.879-1.879zm.586 4.586L18.5 10.207 10.207 18.5l1.293 1.293 8.293-8.293zm-9 9l-1.647-1.646L7.5 17.207l-.5.5V21h3.293l.5-.5zm-2.586-4L9.5 17.793 17.793 9.5 16.5 8.207 8.207 16.5z"></path>
                              </svg>
                              Rename
                           </li>
                           <li>
                              <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="none">
                                 <path stroke="currentColor" d="M6.5 16v4.5a1 1 0 001 1h14a1 1 0 001-1V16M14.5 5V17m-4-3.5l4 4l4-4"></path>
                              </svg>
                              Import list…
                           </li>
                           <li>
                              <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="none">
                                 <path stroke="currentColor" d="M6.5 16v4.5a1 1 0 001 1h14a1 1 0 001-1V16M14.5 18V5.5m-4 4l4-4l4 4"></path>
                              </svg>
                              Export list
                           </li>
                           <li>
                              <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="none">
                                 <path stroke="currentColor" d="M5.5 16.5c1 3 7 7 11 7 2-4 2-8.5 2-8.5s-1-3.5-2-4-4.5.5-4.5.5-3 3.5-6.5 5z"></path>
                                 <path stroke="currentColor" d="M15.5 11l3-6s.5-1 1.5-.5.5 1.5.5 1.5l-3 6M12 11.5l6.5 3.5M7.5 19c2-.5 4-2.5 4-2.5m0 5.5c2-1 3-3.5 3-3.5"></path>
                              </svg>
                              Clear list
                           </li>
                           <li>
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28">
                                 <path fill="currentColor" d="M4 10h5V9H4v1zM11 10h6V9h-6v1zM24 10h-5V9h5v1zM6.5 14c-.83 0-1.5.67-1.5 1.5v3c0 .83.67 1.5 1.5 1.5h15c.83 0 1.5-.67 1.5-1.5v-3c0-.83-.67-1.5-1.5-1.5h-15zM6 15.5c0-.28.22-.5.5-.5h15c.28 0 .5.22.5.5v3a.5.5 0 0 1-.5.5h-15a.5.5 0 0 1-.5-.5v-3z"></path>
                              </svg>
                              Add section
                           </li>
                        </ul>
                        <hr>
                        </hr>
                        <div class="listy8">
                           <p>Customize columns</p>
                           <ul>
                              <li><input type="checkbox"></input>Last</li>
                              <li><input type="checkbox"></input>Change</li>
                              <li><input type="checkbox"></input>Change %</li>
                              <li><input type="checkbox"></input>Volume</li>
                              <li><input type="checkbox"></input>Extended Hours</li>
                           </ul>
                        </div>
                        <hr>
                        </hr>
                        <div class="radi_btn">
                           <p>Symbol display</p>
                           <ul>
                              <li><input type="checkbox"></input>Logo</li>
                              <li><input type="radio"></input>Ticker</li>
                              <li><input type="radio"></input>Description</li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div class="lis7">
            <ul>
               <li>Des</li>
               <li>Last</li>
               <li>Chg</li>
               <li>Chg%</li>
            </ul>
         </div>
         <div class="to_bar">
            <div class="single_inner">
               {/* <p>{this.state.selected_watchlist.name}</p> */}
               {
                this.state.list_watchlist_items.map((obj,index)=>(
                  <div class="sing_lisy">
                  <span class="custom-spnh"><span class="custom_jim"> ₿</span></span>
                  <span class="normal">{obj.symbol}<span class="circel2"></span>{obj.price}</span>
                  <span class={obj.changePercentage>0?"colr_orf":"redtt custom_yt"}>{obj.change.toFixed(2)}</span>
                  <span class={obj.changePercentage>0?"colr_orf":"redtt"}>&nbsp; &nbsp;{obj.changePercentage?(Math.round(obj.changePercentage * 100) / 100).toFixed(2):'0'}%<i onClick={()=>this.removeWatchlistItem(obj)} class="fa fa-window-close"></i></span>
               </div>
                ))
               }
               
              
            </div>
        
         </div>
         <div class="bottom_bar">
            <div class="apl_outer">
               <h5>{this.state.symbolName}<small>{this.state.symbol_key_stats.exchange}</small></h5>
               {/* <div class="righy67">
                  <svg data-toggle="collapse" data-target="#demo10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28">
                     <path fill="currentColor" d="M7.5 13a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM5 14.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0zm9.5-1.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM12 14.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0zm9.5-1.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM19 14.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0z"></path>
                  </svg>
               </div>
               <div id="demo10" class="collapse">
                  <div class="listy8 bg_ou8">
                     <p>Customize widgets</p>
                     <ul>
                        <li><input type="checkbox" />Notes</li>
                        <li><input type="checkbox" />Latest news</li>
                        <li><input type="checkbox" />Bid & Ask</li>
                        <li><input type="checkbox" />Key stats</li>
                        <li><input type="checkbox" />Earnings</li>
                     </ul>
                  </div>
               </div> */}
            </div>
            <div class="middl_text">
               <h5>
                  <a href="#">{this.state.symbolName}</a>

                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" width="18" height="18" fill="none">
                     <path stroke="currentColor" d="M8 4.5H5.5a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V10M10 4.5h3.5m0 0V8m0-3.5l-5 5"></path>
                  </svg>
               </h5>
               {
                 Object.keys(this.state.symbol_key_stats).length != 0?
                 (<> <h3>{this.state.symbol_key_stats.price} {this.state.symbol_currency} <small>{Math.round(this.state.symbol_key_stats.change*100)/100} ( { Math.round(this.state.symbol_key_stats.changesPercentage*100,2)/100 } % )</small></h3>
                 <p>{this.state.market_status?'Market Open':'Market Close'}</p></>):''
               }
              
            </div>
            <div class="add_note4">
               {/* <a href="#">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" width="18" height="18">
                     <path fill="currentColor" d="M13.2 3.5a1 1 0 0 0-1.4 0l-1.65 1.65L7.29 8a1 1 0 0 0-.29.7V11h2.3a1 1 0 0 0 .7-.3l2.85-2.85 1.65-1.64a1 1 0 0 0 0-1.42L13.2 3.5zm-.7 3.3l-1.3-1.3 1.3-1.3 1.3 1.3-1.3 1.3zm-2-.6l1.3 1.3L9.3 10H8V8.7l2.5-2.5zM5 5.5c0-.28.22-.5.5-.5h1a.5.5 0 1 0 0-1h-1C4.67 4 4 4.67 4 5.5v7c0 .83.67 1.5 1.5 1.5h7c.83 0 1.5-.67 1.5-1.5v-1a.5.5 0 0 0-1 0v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-7z"></path>
                  </svg>
                  Add Note
               </a> */}
            </div>
            {
              this.state.symbol_news.length>0?
              (
                <div class="purpal_outer">
                <p>{Moment(this.state.symbol_news[0].date).format("HH:MM, MMM DD")+" "+this.state.symbol_news[0].source+": "+this.state.symbol_news[0].title}</p>
             </div>
              )
              :(
                <></>
              )
            }
           
            {
              this.state.symbol_ask_and_bids.totalResults>0?
              (
                <>
                // ask price
                  <p>{this.state.symbol_ask_and_bids.symbol_ask_and_bids.a}</p>
                  //bid price
                  <p>{this.state.symbol_ask_and_bids.symbol_ask_and_bids.b}</p>
                  </>
              ):
              (
                  <></>
              )
            }
            <h2 className="ket_outer">Key Stats</h2>
            <table className="custom_tb85">
              <tr>
                <td>Volume </td>
                <td>{this.convertToInternationalCurrencySystem(this.state.symbol_key_stats.volume)} </td>
              </tr>
              <tr>
                <td>Average Volume (10) </td>
                <td>{this.convertToInternationalCurrencySystem(this.state.symbol_key_stats.avgVolume)} </td>
              </tr>
              <tr>
                <td>Market Capitalization </td>
                <td>{this.convertToInternationalCurrencySystem(this.state.symbol_key_stats.marketCap)} </td>
              </tr>
              <tr>
                <td>Shares Float </td>
                <td>{this.convertToInternationalCurrencySystem(this.state.symbol_key_stats.sharesOutstanding)} </td>
              </tr>
            </table>
            
             <h2 className="ket_outer">Performance</h2>
            <div className="row custom_ou78">
            <div className="col-md-3" style={{width:'auto'}}>
			<div className="perform_outer">
              <h4>{this.state.symbol_performance.cpw}%</h4>
              <p>1W</p>
			  </div>
            </div>
            <div className="col-md-3" style={{width:'auto'}}>
			<div className="perform_outer">
              <h4>{this.state.symbol_performance.cpm}%</h4>
              <p>1M</p>
            </div>
			</div>
              <div className="col-md-3" style={{width:'auto'}}>
			<div className="perform_outer">
              <h4>{this.state.symbol_performance.cpsm}%</h4>
              <p>6M</p>
            </div>
			</div>
			   <div className="col-md-3" style={{width:'auto'}}>
			<div className="perform_outer">
            
              <h4>{this.state.symbol_performance.cpy}%</h4>
              <p>1Y</p>
            </div>
            </div>
			</div>
            <h2 className="ket_outer">Income Statement </h2>
			<div className="cutom_tabs67">
            <select id="dropStatement" onChange={(e)=>this.statementChange(e.target.value)}>
            <option value={1} >Income Statement</option>
            <option value={2} >Balance Sheet Statement</option>
            <option value={3} >Cash Flow Statement</option>
            </select>
			<a href="javascript:void(0)" style={{color:this.state.statement_type=='annual'?'blue':'black'}} onClick={()=>this.setStatementType('annual')} className="custom_active7">Annual</a>
			<a href="javascript:void(0)" style={{color:this.state.statement_type=='quarter'?'blue':'black'}}  onClick={()=>this.setStatementType('quarter')} className="un_active">Quarterly</a>
			<div id="highchart-income-statement"></div>
      </div>
      <h2 className="ket_outer">Technicals </h2>
      <div style={{display:"block"}}>
      <div id="technicalgauge"></div>
      </div>
      <a href="javascript:void(0)"   data-target="#modalTechnicals" data-toggle="modal">More Technicals</a>
      <div id="modalTechnicals"  class="modal fade" role="dialog">
                  <div class="modal-dialog fullScreeenModal">
                     <div class="modal-content">
                        <div class="modal-header">
                        <h4 class="modal-title">Technicals</h4>
                           <button type="button" id="closeIdeaModal" class="close" data-dismiss="modal">&times;</button>
                           
                        </div>
                        <div class="modal-body cusom_modal8" style={{height:"100%",overflowY:"auto"}}>
                        <div className="row" >
                          <div className="col-4">
                          <h3>Oscillators</h3>
                          <div  id="technicalGauge2"></div>
                          </div>
                          <div className="col-4">
                            <h3>Summary</h3>
                          <div  id="technicalGauge3"></div>
</div>
<div className="col-4">
<h3>Moving Averages</h3>
<div  id="technicalGauge4"></div>
</div>
                          <div className="col-6">
                            <h2>OSCILLATORS</h2>
                            <table>
                              <tr>
                                <th>Name</th>
                                <th>Value</th>
                                <th>Action</th>
                              </tr>
                              <tr>
                                <td>Relative Strength index(14)</td>
                                <td>{this.state.technical_rsi}</td>
                                <td>Neutral</td>
                              </tr>
                            </table>
                          </div>
                           <div className="col-6">
                            <h2>Moving Averages</h2>
                            <table>
                              <tr>
                                <th>Name</th>
                                <th>Value</th>
                                <th>Action</th>
                              </tr>
                              <tr>
                                <td>Exponential Moving Average(10)</td>
                                <td>{this.state.technical_ema_10}</td>
                                <td>Sell</td>
                              </tr>
                               <tr>
                                <td>Simple Moving Average(10)</td>
                                <td>{this.state.technical_sma_10}</td>
                                <td>Sell</td>
                              </tr><tr>
                                <td>Exponential Moving Average(20)</td>
                                <td>{this.state.technical_ema_20}</td>
                                <td>Sell</td>
                              </tr>
                               <tr>
                                <td>Simple Moving Average(20)</td>
                                <td>{this.state.technical_sma_20}</td>
                                <td>Sell</td>
                              </tr><tr>
                                <td>Exponential Moving Average(30)</td>
                                <td>{this.state.technical_ema_30}</td>
                                <td>Sell</td>
                              </tr>
                               <tr>
                                <td>Simple Moving Average(30)</td>
                                <td>{this.state.technical_sma_30}</td>
                                <td>Sell</td>
                              </tr><tr>
                                <td>Exponential Moving Average(50)</td>
                                <td>{this.state.technical_ema_50}</td>
                                <td>Sell</td>
                              </tr>
                               <tr>
                                <td>Simple Moving Average(50)</td>
                                <td>{this.state.technical_sma_50}</td>
                                <td>Sell</td>
                              </tr>
                              <tr>
                                <td>Exponential Moving Average(100)</td>
                                <td>{this.state.technical_ema_100}</td>
                                <td>Sell</td>
                              </tr>
                               <tr>
                                <td>Simple Moving Average(100)</td>
                                <td>{this.state.technical_sma_100}</td>
                                <td>Sell</td>
                              </tr>
                              <tr>
                                <td>Exponential Moving Average(200)</td>
                                <td>{this.state.technical_ema_200}</td>
                                <td>Sell</td>
                              </tr>
                               <tr>
                                <td>Simple Moving Average(200)</td>
                                <td>{this.state.technical_sma_200}</td>
                                <td>Sell</td>
                              </tr>
                            </table>
                          </div>
                        </div>
                        </div>
                     </div>
                  </div>
               </div>
            <div class="profile_outer">
               <h5>Profile</h5>
               <div class="websui">
                  <p>
                     Website
                     <a href={this.state.symbol_profile.url}>
                       {this.state.symbol_profile.url}
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" width="18" height="18" fill="none">
                           <path stroke="currentColor" d="M8 4.5H5.5a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V10M10 4.5h3.5m0 0V8m0-3.5l-5 5"></path>
                        </svg>
                     </a>
                  </p>
               </div>
               <div class="websui">
                  <p>Employees <span class="kt"> {this.state.symbol_profile.employees/100} K</span></p>
               </div>
               <div class="p_inner">
                  <p>{this.state.symbol_profile.description}</p>
               </div>
            </div>
         </div>
      </div>
      <div className="bottom_2">
      </div>
      <div class="modal" id="myModal01">
         <div class="modal-dialog">
            <div class="modal-content">
               <div class="modal-header">
                  <h4 class="modal-title">Add symbol</h4>
                  <button type="button" class="close" data-dismiss="modal">&times;</button>
               </div>
               <div class="modal-body custm_ody">
                  <div class="search_outer">
                     <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none">
                        <path stroke="currentColor" d="M12.4 12.5a7 7 0 1 0-4.9 2 7 7 0 0 0 4.9-2zm0 0l5.101 5"></path>
                     </svg>
                     <input type="text" placeholder="Search"></input>
                  </div>
                  <div class="tabs_uter">
                     <ul class="nav nav-pills">
                        <li><a class="active" data-toggle="pill" href="#usstocks">US Stocks</a></li>
                        <li><a data-toggle="pill" href="#menu1">Forex Crypto</a></li>
                        <li><a data-toggle="pill" href="#menu2">Crypto</a></li>
                     </ul>
                     <div class="tab-content">
                        <div id="usstocks" class="tab-pane fade active show">
                           <div class="main_outer5">
                              <div class="simple_outer">
                                 <ul>
                                    <li>SYMBOL</li>
                                    <li>DESCRIPTION</li>
                                    <li class="rit_v7">
                                       <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" width="18" height="18" fill="none">
                                          <path stroke="currentColor" d="M2.5 14.5c1.68-1.26 3.7-2 6.5-2s4.91.74 6.5 2m-13-11c1.68 1.26 3.7 2 6.5 2s4.91-.74 6.5-2"></path>
                                          <circle stroke="currentColor" cx="9" cy="9" r="8.5"></circle>
                                          <path stroke="currentColor" d="M13.5 9c0 2.42-.55 4.58-1.4 6.12-.87 1.56-1.98 2.38-3.1 2.38s-2.23-.82-3.1-2.38c-.85-1.54-1.4-3.7-1.4-6.12s.55-4.58 1.4-6.12C6.77 1.32 7.88.5 9 .5s2.23.82 3.1 2.38c.85 1.54 1.4 3.7 1.4 6.12z"></path>
                                       </svg>
                                       <select>
                                          <option>All sources</option>
                                       </select>
                                    </li>
                                 </ul>
                              </div>
                              {
                                this.state.list_us_stocks.map((stock,index)=>(
                                <div class="listing">
                                    <ul>
                                        <li><span className="indr5"> <span class="custom_jim"> &#8383;</span>BTCUSDT</span></li>
                                        <li>Bitcoin / TetherUS</li>
                                        <li>
                                          <span>crypto</span>BINANCE
                                          <img src="https://s3-symbol-logo.tradingview.com/provider/binance.svg"></img>
                                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28" fill="none">
                                              <path stroke="currentColor" stroke-width="1.2" d="M8 8l13 13m0-13L8 21"></path>
                                          </svg>
                                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28" fill="none">
                                              <path fill="currentColor" fill-rule="evenodd" clip-rule="evenodd" d="M14 9.5a.5.5 0 0 0 1 0V7.02A6.5 6.5 0 0 1 20.98 13H18.5a.5.5 0 0 0 0 1h2.48A6.5 6.5 0 0 1 15 19.98V17.5a.5.5 0 0 0-1 0v2.48A6.5 6.5 0 0 1 8.02 14h2.48a.5.5 0 0 0 0-1H8.02A6.5 6.5 0 0 1 14 7.02V9.5zm1-3.48V4.5a.5.5 0 0 0-1 0v1.52A7.5 7.5 0 0 0 7.02 13H5.5a.5.5 0 0 0 0 1h1.52A7.5 7.5 0 0 0 14 20.98v1.52a.5.5 0 0 0 1 0v-1.52A7.5 7.5 0 0 0 21.98 14h1.52a.5.5 0 0 0 0-1h-1.52A7.5 7.5 0 0 0 15 6.02z"></path>
                                          </svg>
                                        </li>
                                    </ul>
                                </div>

                                ))
                              }
                              
                              
                           </div>
                        </div>
                        <div id="menu1" class="tab-pane fade">
                           <h3>Stocks</h3>
                           <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                        </div>
                        <div id="menu2" class="tab-pane fade">
                           <h3>Futures</h3>
                           <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.</p>
                        </div>
                       
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
        {/* for watchlist end */}
         {/* for headline start */}
   <div class="watch_inner  d-none watch-list-headline" >
      <div className="top_1">
        <h4>Headlines for {this.state.slctSymbol}</h4>
        <hr></hr>
        {
          this.state.symbol_news.map((news,i)=>(
          <div className="watch-list-alert-item">
            <a href={news.url} target="_blank">
            <div className="alert-list-schedule">
              <img src={this.state.symbol_profile.logo}  /> 
              <p>&nbsp;{Moment(news.date).format("MMM DD")}&nbsp;<span>{ news.source }</span> </p>
            </div>
            <h6>{news.title}</h6>
            </a>
            <hr></hr>
        </div>
          ))
        }
        
      </div>
      
   </div>
        {/* for headline end */}
         {/* for Data Window start */}
   <div class="watch_inner d-none watch-list-data-window" >
        <h6>Data Window</h6>
        <div className="watch-list-data-window-item">
            <p>Date</p>
            <p className="value">{Moment(new Date()).format("d MMM")}</p>
          </div>
          <div className="watch-list-data-window-item">
            <p>Time</p>
            <p className="value">{Moment(new Date()).format("HH:M")}</p>
          </div>
        <h6>{this.state.symbol_key_stats.symbol+","+this.state.symbol_key_stats.exchange}</h6>
        <div className="watch-list-data-window-item">
            <p>Open</p>
            <p className="value">{this.state.symbol_key_stats.open}</p>
          </div>
          <div className="watch-list-data-window-item">
            <p>High</p>
            <p className="value">{this.state.symbol_key_stats.high}</p>
          </div>
          <div className="watch-list-data-window-item">
            <p>Low</p>
            <p className="value">{this.state.symbol_key_stats.low}</p>
          </div>
          <div className="watch-list-data-window-item">
            <p>Close</p>
            <p className="value">{this.state.symbol_key_stats.close}</p>
          </div>
          <div className="watch-list-data-window-item">
            <p>Change</p>
            <p className="value">{this.state.symbol_key_stats.change}</p>
          </div>
          <div className="watch-list-data-window-item">
            <p>Vol</p>
            <p className="value">{this.state.symbol_key_stats.volume}</p>
          </div>
        <h6>Vol</h6>
        <div className="watch-list-data-window-item">
            <p>Volume</p>
            <p className="value">{this.state.symbol_key_stats.volume}</p>
          </div>
   </div>
        {/* for Data window end */}
          {/* for calendar Window start */}
   <div class="watch_inner d-none watch-list-calendar" >
   <h6>Economic Calendar</h6>
   <div className="first_scroll">
   
   <table className="custom_list6">
         <tr> 
           <td>Time</td>
           <td>Actual</td>
           <td>Forecast</td>
         </tr>
       </table> 
     <div>
      
        
        {
          this.state.eonomic_calendar.map((row,i)=>(
            <div>
              <div className="watch-list-calendar-item">
               <p className="time">{Moment(row.date).format("HH:MM")}</p>
               <p>&nbsp;{row.event}</p>
            </div>
            <div className="watch-list-calendar-item">
                <img src={row.countryFlag}/>
                <p>&nbsp;&nbsp;{row.estimate==null?'-':row.estimate+" %"}</p>
                <p>&nbsp;&nbsp;-</p>
                <p>&nbsp;&nbsp;{row.actual==null?'-':row.actual+" %"}</p>
            </div>
            <hr></hr>
            </div>

          ))
        }
        
        </div>
		</div>
        <h6>Earning Calendar</h6>
        <table className="custom_list6">
         <tr>
           <td>Symbol</td>
           <td>Company</td>
           <td>Estimate</td>
         </tr>
       </table>
        <div style={{height:'350px',overflowY:'auto'}}>
        
        {
          this.state.earning_calendar.map((row,i)=>(
            <div className="calendwe450">
            <div className="watch-list-calendar-item">
                <p>&nbsp;&nbsp;{row.symbol}</p>
                <p>&nbsp;&nbsp;-</p>
                <p>&nbsp;&nbsp;{row.estimated_eps==null?'-':row.estimated_eps+" %"}</p>
            </div>
            <hr></hr>
            </div>

          ))
        }
        
        </div>
   </div>
        {/* for calendar end */}
         {/* for alert Window start */}
   <div class="watch_inner d-none watch-list-alert" >
   <div className="alert_outer">
   <h6>Alerts</h6>
   {/* <button onClick={this.showCreateAlert}>+Add Alert</button> */}
   </div>
 


      
   {
    this.state.alerts.map((alert,index)=>(
        <div className="aleart_list">
          <h6 style={{color:"#a39090"}}>{alert.symbol+" "+alert.cond_check+" "+alert.cond_value }<span className="hide341"><i class={alert.is_notify?"fa fa-stop-circle":"fa fa-play-circle"} onClick={()=>this.restartAlert(alert.action_method,alert.id,alert.is_notify?false:true)}></i><i onClick={()=>this.deleteAlert(alert.id)} class="fa fa-times"></i></span></h6>
          <p>{alert.symbol+" "+alert.option+" "+(alert.is_notify?'Active':'Stopped') }</p>
          <hr></hr>
        </div>
    ))
   }
        

   </div>
        {/* for alert window end */}
         {/* for idea Window start */}
   <div class="watch_inner d-none watch-list-idea" >
   <div className="alert_outer">
   <h6>My Ideas</h6>
   <div className="idea_add">
   <span class="icon-9pA37sIi" data-target="#modalCreateIdea" data-toggle="modal">
    <svg data-toggle="modal" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28" fill="none"><path fill="currentColor" d="M7 13h7V6h1v7h7v1h-7v7h-1v-7H7v-1z"></path></svg></span>
   </div>
   </div>
 

{/* to be used dropdown in hotlist */}
   {/* <div className="alert56">
		<button className="custom_der5" data-toggle="collapse" data-target="#demo012">US Exchanges, Percent Change Losers</button>

<div id="demo012" className="collapse custom_demo">
<li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            USA (US Exchanges)
          </a>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><a className="dropdown-item" href="#">Percent Change Losers</a></li>
            <li><a className="dropdown-item" href="#">Percent Change Losers</a></li>
          </ul>
        </li>
</div>
   </div>     */}
   {
    <>
    <hr></hr>
    {
      this.state.my_ideas.map((obj,i)=>(
      <div className="aleart_list">
        <div style={{display:"flex",justifyContent:"space-between"}}>
        <p>{Moment(obj.created_at).format("MMM D,YYYY HH:MM")}</p>
        <i onClick={()=>this.removeIdea(obj.id)} class="fa fa-window-close" aria-hidden="true"></i>

        </div>
          <h6>{obj.title}</h6>
          <h5 style={{color:"#a39090"}}>{obj.description}</h5>
          <hr></hr>
        </div>
      ))
    }
        </>
   }
        

   </div>
        {/* for idea window end */}
          {/* for streams Window start */}
   <div class="watch_inner d-none watch-list-stream"  style={{height:"100%",overflowY:"auto",paddingBottom:"61px"}}>
   <div className="alert_outer">
   <h6>Streams</h6>
   <div className="idea_add">
    <a href="/create-stream">
   <span class="icon-9pA37sIi" >
    <svg data-toggle="modal" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28" fill="none"><path fill="currentColor" d="M7 13h7V6h1v7h7v1h-7v7h-1v-7H7v-1z"></path></svg></span>
    </a>
   </div>
   </div>
 
   
    <>
    <hr></hr>
    {
      this.state.myStreamsList.map((item,i)=>(
        <div className="aleart_list">
        <div style={{display:"flex"}}>
          <img src={item.user.image} style={{borderRadius:"50%",width:"40px",height:"40px"}} />
          <div>
        <h6>{item.user.first_name}</h6>
        <p>{Moment(item.created_at).format("dd MMM YYYY")}</p>
        </div>
        </div>
          <h6>{item.title}</h6>
          <img src={item.cover} style={{width:"200px",height:"120px",objectFit:"cover",borderRadius:"13px"}} />
          <hr></hr>
        </div>
      ))
    }
   
        </>
   
        

   </div>
        {/* for streams window end */}
         {/* for public chat start */}
   <div class="watch_inner d-none watch-list-public-chat" style={{height:"83%",overflowY:'auto'}} >
   <div className="alert_outer">
   <h6>Public Chat</h6>
   
   </div>
    <>
    <hr></hr>
    {
      this.state.public_chats.map((obj,i)=>(
        <>
         <div style={{display:"flex"}} >
        <div>
        <img src={DefaultProfile} style={{width:"42px",marginRight:"10px"}} />
        </div>
        <div>
           <div style={{display:"flex",height:"23px"}}>
        <h6 style={{color:"blue",width:"auto",paddingRight:"10px"}}>{obj.username.username}</h6>
        <p>{Moment(obj.timestamp).format("MMM D")}</p>
        </div>
          <p style={{color:"#000",margin:"0"}}>{obj.content}</p>
         
        </div>
        
        </div>
        <hr></hr>
        </>
     
      ))
    }
    <div className="row" style={{position:"absolute",bottom:"83px"}}>
      <div className="col-10">
      <input type="text" value={this.state.public_chat_message} name="public_chat_message" onChange={(e)=>this.handleInputChange(e)} placeholder="Have something to say ?" />
      </div>
      <div className="col-2">
      <i class="fa fa-arrow-circle-right" onClick={()=>this.sendPuclicChatMessage()}></i>
      </div>
    </div>
        </>
   
        

   </div>
        {/* for public chat end */}
         {/* for private chat start */}
   <div class="watch_inner d-none watch-list-private-chat" >
   <div className="alert_outer">
   <h6>Private Chat</h6>
   <div className="idea_add">
   <span class="icon-9pA37sIi" data-target="#modalPrivateChat" data-toggle="modal">
    <svg data-toggle="modal" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28" fill="none"><path fill="currentColor" d="M7 13h7V6h1v7h7v1h-7v7h-1v-7H7v-1z"></path></svg></span>
   </div>
   </div>
   {
    <>
    <hr></hr>
    {
      this.state.my_ideas.map((obj,i)=>(
      <div className="aleart_list">
        <div style={{display:"flex",justifyContent:"space-between"}}>
        <p>{Moment(obj.created_at).format("MMM D,YYYY HH:MM")}</p>
        <i onClick={()=>this.removeIdea(obj.id)} class="fa fa-window-close" aria-hidden="true"></i>

        </div>
          <h6>{obj.title}</h6>
          <h5 style={{color:"#a39090"}}>{obj.description}</h5>
          <hr></hr>
        </div>
      ))
    }
        </>
   }
        

   </div>
   <div id="modalPrivateChat"  class="modal fade" role="dialog">
                  <div class="modal-dialog">
                     <div class="modal-content">
                      
                        <div class="modal-body cusom_modal8">
                          <div className="row">
                            <div className="col-12 flex_bj">
                              <label>To:</label>
                              <Select
                                onChange={(value)=>this.userSelect(value)}
                              options={this.state.users_list.map(group => 
                  ({ label: group.label, value: group.value }) )} />
                              <label>Message</label>
                              <textarea name="private_user_message" onChange={(e)=>this.handleInputChange(e)}  className="input_bj" style={{height:"20vh"}}>{this.state.private_user_message}</textarea>
                              <button className="btn btn-grey" type="button">Close</button>
                              <button className="btn btn-success" type="button" onClick={()=>this.sendMessage()}>Send</button>
                            </div>
                         
                          </div>
                        </div>
                     </div>
                  </div>
               </div>
        {/* for private chat end */}
             {/* for notification start */}
   <div class="watch_inner d-none watch-list-notification" >
   <div className="alert_outer">
   <h6>Notifications</h6>
  
   </div>
   {
    <>
    <hr></hr>
    {
      this.state.list_notifications.map((obj,i)=>(
      <div className="aleart_list">
        <div style={{display:"flex",justifyContent:"space-between"}}>
        <p>{Moment(obj.created_on).format("MMM D")}</p>
        </div>
          <h6>{obj.heading}</h6>
          <h5 style={{color:"#a39090"}}>{obj.content}</h5>
          <hr></hr>
        </div>
      ))
    }
        </>
   }
        

   </div>
        {/* for notification end */}
</div>
<div id="modalCreateIdea"  class="modal fade" role="dialog">
                  <div class="modal-dialog fullScreeenModal">
                     <div class="modal-content">
                        <div class="modal-header">
                        <h4 class="modal-title">Publish Idea</h4>
                           <button type="button" id="closeIdeaModal" class="close" data-dismiss="modal">&times;</button>
                           
                        </div>
                        <div class="modal-body cusom_modal8">
                          <div className="row">
                            <div className="col-8 flex_bj">
                              <label>Title</label>
                              <input name="idea_title" onChange={(e)=>this.handleInputChange(e)} value={this.state.idea_title} className="input_bj" type="text" placeholder="Enter title" ></input>
                              <label>Description</label>
                              <textarea name="idea_description" onChange={(e)=>this.handleInputChange(e)}  className="input_bj" style={{height:"50vh"}}>{this.state.idea_tite}</textarea>
                              <label>Link to related ideas</label>
                              <input name="idea_link" onChange={(e)=>this.handleInputChange(e)} value={this.state.idea_link} className="input_bj" type="text" placeholder="Enter link related to ideas"></input>
                            </div>
                            <div className="col-4 flex_bj">
                              <label>Privacy Settings</label>
                              <div class="btn-group">
                                <input type="radio" class="btn-check" value="0" name="radioprivacy" id="radioPublic" autocomplete="off" />
                                <label class="btn btn-secondary radio_bj_left" for="radioPublic">Public</label>


                                <input type="radio" class="btn-check" name="radioprivacy" value="1" id="radioPrivate" autocomplete="off" />
                                <label class="btn btn-secondary radio_bj_right"  for="radioPrivate">Private</label>
                              </div>

                              <label>Type </label>
                              <div class="btn-group">
                                <input type="radio" class="btn-check" name="radiotype" value="1" id="radioAnalysis" autocomplete="off" />
                                <label class="btn btn-secondary radio_bj_left" for="radioAnalysis">Analysis</label>


                                <input type="radio" class="btn-check" name="radiotype" value="2" id="radioTutorial" autocomplete="off" />
                                <label class="btn btn-secondary radio_bj_right" for="radioTutorial">Tutorial</label>
                              </div>
                              <label>Investment Strategy </label>
                              <div class="btn-group">
                                <input type="radio" class="btn-check" value="1" name="radiostrategy"  id="radioLong" autocomplete="off" />
                                <label class="btn btn-secondary radio_bj_left" for="radioLong">Long</label>
                                <input type="radio" class="btn-check" value="2" name="radiostrategy"  id="radioNeutral" autocomplete="off" />
                                <label class="btn btn-secondary radio_bj_left" style={{borderRadius:"0"}} for="radioNeutral">Neutral</label>
                                <input type="radio" class="btn-check" value="3" name="radiostrategy" id="Sort" autocomplete="off" />
                                <label class="btn btn-secondary radio_bj_right" for="Sort">Sort</label>
                              </div>
                              <label>Category</label>
                              <select  className="input_bj"  name="category" id="ideaCat">
                                <option value="">Select category</option>
                                {
                                  this.state.idea_categories.map((obj,i)=>(
                                    <option value={obj.id}>{obj.name}</option>
                                  ))
                                }
                              </select>
                              <label>Tags</label>
                              <input name="idea_tags" onChange={(e)=>this.handleInputChange(e)} value={this.state.idea_tags} className="input_bj" type="text" placeholder="Enter Tags"></input>

                              <button type="button" onClick={()=>this.addIdea()} className="button_bj">Publish Idea</button>
                            </div>
                          </div>
                        </div>
                     </div>
                  </div>
               </div>
              <ul className="topbar_right_ul">
                <li data-toggle="tooltip" title="Select Layout">
                  <a href="" data-toggle="collapse" data-target="#layout">
                    <i class="far fa-square"></i>
                  </a>
                  <div
                    id="layout"
                    class="collapse collapse_custom template_collapse"
                  >
                    <ul class="card-ul">
                      <li>
                        <a href="#">
                          <span>1</span>
                          <div className="sync_icons">
                            <i class="far fa-square"></i>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <span>2</span>
                          <div className="sync_icons">
                            <i class="far fa-square"></i>
                            <i class="far fa-square"></i>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <span>3</span>
                          <div className="sync_icons">
                            <i class="far fa-square"></i>
                            <i class="far fa-square"></i>
                            <i class="far fa-square"></i>
                            <i class="far fa-square"></i>
                            <i class="far fa-square"></i>
                            <i class="far fa-square"></i>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <span>4</span>
                          <div className="sync_icons">
                            <i class="far fa-square"></i>
                            <i class="far fa-square"></i>
                            <i class="far fa-square"></i>
                            <i class="far fa-square"></i>
                            <i class="far fa-square"></i>
                            <i class="far fa-square"></i>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <span>5</span>
                          <div className="sync_icons">
                            <i class="far fa-square"></i>
                            <i class="far fa-square"></i>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <span>6</span>
                          <div className="sync_icons">
                            <i class="far fa-square"></i>
                            <i class="far fa-square"></i>
                          </div>
                        </a>
                      </li>
                    </ul>
                    <div className="save_indicator sync">SYNC IN LAYOUT</div>
                    <div className="sync_l">
                      <div className="sync_left">
                        <span>Symbol</span>
                        <i class="fa-solid fa-circle-info"></i>
                      </div>
                      <div className="sync_left">
                        <span>Interval</span>
                        <i class="fa-solid fa-circle-info"></i>
                      </div>
                    </div>
                    <div className="sync_l">
                      <div className="sync_left">
                        <span>Crosshair</span>
                        <i class="fa-solid fa-circle-info"></i>
                      </div>
                      <div className="sync_left">
                        <span>Time</span>
                        <i class="fa-solid fa-circle-info"></i>
                      </div>
                    </div>
                    <div className="sync_l">
                      <div className="sync_left">
                        <span>Drawings</span>
                        <i class="fa-solid fa-circle-info"></i>
                      </div>
                    </div>
                  </div>
                </li>
                <li data-toggle="tooltip" title="Save all charts">
                  <a href="" data-toggle="collapse" data-target="#save">
                    <i class="fa-solid fa-cloud-arrow-up"></i> Save
                  </a>
                  <div
                    id="save"
                    class="collapse collapse_custom template_collapse"
                  >
                    <ul class="card-ul">
                      <li>
                        <a data-toggle="modal" data-target="#renameModal" href="#">
                          <span>AutoSave On....</span>
                        </a>
                      </li>
                      <li>
                        <a data-toggle="modal" data-target="#renameModal" href="#">
                          <span>Save Now</span>
                        </a>
                      </li>
                      <li>
                        <a data-toggle="modal" data-target="#renameModal" href="#">
                          <span>Rename....</span>
                        </a>
                      </li>
                      <li>
                        <a data-toggle="modal" data-target="#renameModal" href="#">
                          <span>Make a copy....</span>
                        </a>
                      </li>
                      <li>
                      <a data-toggle="modal" data-target="#renameModal" href="#">
                          <span>New Chart Layout</span>
                        </a>
                        
                      </li>
                      <li>

                        <a data-toggle="modal" data-target="#renameModal" href="#">
                          <span>Load chart Layout</span>
                        </a>
                        
                      </li>
                    </ul>
                  </div>
                </li>
                <li data-toggle="tooltip" title="Chart Properties">
                  <a href="" data-toggle="modal" data-target="#settingModal">
                    <i class="fa-solid fa-gear"></i>
                  </a>
                </li>
                <li data-toggle="tooltip" title="Fullscreen Mode">
                  <a href="javascript:void(0);" onClick={()=>this.showChartFull()}>
                    <i class="fa-solid fa-maximize"></i>
                  </a>
                </li>
                <li data-toggle="tooltip" title="Take a snapshot">
                  <a href="" data-toggle="collapse" data-target="#snap">
                    <i class="fa-solid fa-camera"></i>
                  </a>
                  <div
                    id="snap"
                    class="collapse collapse_custom template_collapse"
                  >
                    <ul class="card-ul">
                      <li role="button">
                        <a onClick={this.downloadPicture}>
                          <div className="snap_left">
                            <i class="fa-solid fa-download"></i>
                            Save chart image
                          </div>
                        </a>
                      </li>
                      <li role="button">
                        <a onClick={this.copyImageToClipboards}>
                          <div className="snap_left">
                            <i class="fa-solid fa-clone"></i>
                            Copy chart image
                          </div>
                        </a>
                      </li>
                      <li role="button">
                        <a onClick={this.copyImageLinkClipboards}>
                          <div className="snap_left">
                            <i class="fa-solid fa-link"></i>
                            Copy link to the chart image
                          </div>
                        </a>
                      </li>
                      <li role="button">
                        <a onClick={this.openImageInNewTab}>
                          <div className="snap_left">
                            <i class="fa-solid fa-arrow-up-right-from-square"></i>
                            Open image in new tab
                          </div>
                        </a>
                      </li>
                      <li role="button">
                        <a onClick={this.tweetChart}>
                          <div className="snap_left">
                            <i class="fa-brands fa-twitter tweet"></i>
                            Tweet chart image
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <a className="start_free" href="">
                    Start Free Trial
                  </a>
                </li>
              </ul>
            </div>
{/* <button type="button" class="btn btn-primary d-none" id="showModal" data-toggle="modal" data-target="#exampleModal12">
  Launch demo modal
</button> */}
  
            <section className="contact_outer p-0">
              <div className="container-fluid">
                
                <div className="col-md-12" id="container">
                  {compareChart ? (
                    <div
                      key={
                        slctSymbol +
                        "" +
                        timespan +
                        "" +
                        multiplier +
                        "" +
                        startD +
                        "" +
                        newSymbol
                      }
                    >
                     
                      <CompareChart
                        symbol={slctSymbol}
                        newSymbol={newSymbol}
                        symbolname={symbolName}
                        removSymbol={this.removSymbol.bind(this)}
                        stock={stock}
                        time={timespan}
                        mult={multiplier}
                        graph={graphType}
                        grid={gridShow}
                        statusSymbol={status_line_symbol}
                        symbolVariation={symbol_variation}
                        ohlcValue={ohlc_values}
                        market_status={market_status}
                        volValue={vol_values}
                        sym_name_label={sym_name_label}
                        sym_last_price_label={sym_last_price_label}
                        bar_change_val={bar_change_val}
                        sym_highlowlast_label={sym_highlowlast_label}
                        pre_post_mkt_price={pre_post_mkt_price}
                        askBid={askBid}
                        up_color_color={up_color_color}
                        down_color_color={down_color_color}
                        addPlusAlert={addPlusAlert}
                        lock_price_to_bar_ratio={lock_price_to_bar_ratio}
                        lock_price_to_bar_ratio_val={lock_price_to_bar_ratio_val}
                        newSeriesIndicator={newSeriesIndicator}
                        countdownBar={countdownBar}
                        lo_overlapping_label={lo_overlapping_label}
                        date_format_val={date_format_val}
                        scale_placement={scale_placement}
                        startd={startD}
                        endd={endD}
                      />
                    </div>
                  ) : (
                    <div
                      key={
                        slctSymbol +
                        "" +
                        timespan +
                        "" +
                        multiplier +
                        "" +
                        startD +
                        "" +
                        endD
                      }
                    >
                      {(() => {
                        switch (graphType) {
                          case "candlestick":

                            return (
                              <CandleChart
                                symbol={slctSymbol}
                                symbolname={symbolName}
                                stock={stock}
                                time={timespan}
                                mult={multiplier}
                                graph={graphType}
                                grid={gridShow}
                                statusLineSymbol={status_line_symbol}
                                symbolVariation={symbol_variation}
                                ohlcValue={ohlc_values}
                                market_status={market_status}
                                volValue={vol_values}
                                sym_name_label={sym_name_label}
                                sym_last_price_label={sym_last_price_label}
                                bar_change_val={bar_change_val}
                                sym_highlowlast_label={sym_highlowlast_label}
                                pre_post_mkt_price={pre_post_mkt_price}
                                askBid={askBid}
                                addPlusAlert={addPlusAlert}
                                lock_price_to_bar_ratio={lock_price_to_bar_ratio}
                                lock_price_to_bar_ratio_val={lock_price_to_bar_ratio_val}
                                newSeriesIndicator={newSeriesIndicator}
                                countdownBar={countdownBar}
                                lo_overlapping_label={lo_overlapping_label}
                                date_format_val={date_format_val}
                                scale_placement={scale_placement}
                                startd={startD}
                                endd={endD}
                               
                                alpha_color={alpha_color}
                                up_color_color={up_color_color}
                                down_color_color={down_color_color}
                                last_price_color={last_price_color}
                                showHighLowerLine={showHighLowerLine}
                                get_indicator_prop_data={get_indicator_data}
                                marker={markerPoints}
                                count={count}
                                callout={callout}
                                balloon={balloon}
                                arrowLeft={arrowLeft}
                                arrowMark={arrowMark}
                                flagMark={flagMark}
                                arrowDown={arrowDown}
                                arrowUp={arrowUp}
                                arrowRight={arrowRight}
                                crossHair={crossHair}
                                textCallout={textCallout}
                                handler = {this.handler}
                                showTool = { showTool }
                              />
                            );
                          case "columnrange":
                            return (
                              
                              <RenkoChart
                                symbol={slctSymbol}
                                symbolname={symbolName}
                                stock={stock}
                                time={timespan}
                                mult={multiplier}
                                graph={graphType}
                                grid={gridShow}
                                startd={startD}
                                endd={endD}
                                marker={markerPoints}
                                count={count}
                                callout={callout}
                                balloon={balloon}
                                arrowLeft={arrowLeft}
                                arrowMark={arrowMark}
                                flagMark={flagMark}
                                arrowDown={arrowDown}
                                arrowUp={arrowUp}
                                arrowRight={arrowRight}
                                crossHair={crossHair}
                                textCallout={textCallout}
                              />
                            );
                          case "ohlc":
                            return (
                              <Bar
                                symbol={slctSymbol}
                                symbolname={symbolName}
                                stock={stock}
                                time={timespan}
                                mult={multiplier}
                                graph={graphType}
                                grid={gridShow}
                                startd={startD}
                                endd={endD}
                                up_color_color={up_color_color}
                                down_color_color={down_color_color}
                                last_price_color={last_price_color}
                                showHighLowerLine={showHighLowerLine}
                                marker={markerPoints}
                                count={count}
                                callout={callout}
                                balloon={balloon}
                                arrowLeft={arrowLeft}
                                arrowMark={arrowMark}
                                flagMark={flagMark}
                                arrowDown={arrowDown}
                                arrowUp={arrowUp}
                                arrowRight={arrowRight}
                                crossHair={crossHair}
                                textCallout={textCallout}
                                trendLine={trendLine}
                              />
                            );
                          case "area":
                            return (
                              <AreaChart
                                symbol={slctSymbol}
                                symbolname={symbolName}
                                stock={stock}
                                time={timespan}
                                mult={multiplier}
                                graph={graphType}
                                grid={gridShow}
                                startd={startD}
                                endd={endD}
                                marker={markerPoints}
                                count={count}
                                callout={callout}
                                balloon={balloon}
                                arrowLeft={arrowLeft}
                                arrowMark={arrowMark}
                                flagMark={flagMark}
                                arrowDown={arrowDown}
                                arrowUp={arrowUp}
                                arrowRight={arrowRight}
                                crossHair={crossHair}
                                textCallout={textCallout}
                              />
                            );
                          case "line":
                            return (
                              <LineChart
                                symbol={slctSymbol}
                                symbolname={symbolName}
                                stock={stock}
                                time={timespan}
                                mult={multiplier}
                                graph={graphType}
                                grid={gridShow}
                                startd={startD}
                                endd={endD}
                                line_color_color={line_color_color}
                                showHighLowerLine={showHighLowerLine}
                                marker={markerPoints}
                                count={count}
                                callout={callout}
                                balloon={balloon}
                                arrowLeft={arrowLeft}
                                arrowMark={arrowMark}
                                flagMark={flagMark}
                                arrowDown={arrowDown}
                                arrowUp={arrowUp}
                                arrowRight={arrowRight}
                                crossHair={crossHair}
                                textCallout={textCallout}
                              />
                            );
                          case "renko":
                            return ( 
                              <TypeChooser>
                                {type => <RenkoChart 
                                  type={type} 
                                  data={this.state.data}  
                                  symbol={slctSymbol}
                                symbolname={symbolName}
                                stock={stock}
                                time={timespan}
                                mult={multiplier}
                                graph={graphType}
                                grid={gridShow}
                                statusLineSymbol={status_line_symbol}
                                symbolVariation={symbol_variation}
                                ohlcValue={ohlc_values}
                                market_status={market_status}
                                volValue={vol_values}
                                sym_name_label={sym_name_label}
                                sym_last_price_label={sym_last_price_label}
                                bar_change_val={bar_change_val}
                                sym_highlowlast_label={sym_highlowlast_label}
                                pre_post_mkt_price={pre_post_mkt_price}
                                askBid={askBid}
                                addPlusAlert={addPlusAlert}
                                lock_price_to_bar_ratio={lock_price_to_bar_ratio}
                                lock_price_to_bar_ratio_val={lock_price_to_bar_ratio_val}
                                newSeriesIndicator={newSeriesIndicator}
                                countdownBar={countdownBar}
                                lo_overlapping_label={lo_overlapping_label}
                                date_format_val={date_format_val}
                                scale_placement={scale_placement}
                                startd={startD}
                                endd={endD}
                                up_color_color={up_color_color}
                                alpha_color={alpha_color}
                                down_color_color={down_color_color}
                                last_price_color={last_price_color}
                                showHighLowerLine={showHighLowerLine}
                                get_indicator_prop_data={get_indicator_data}
                                marker={markerPoints}
                                count={count}
                                callout={callout}
                                balloon={balloon}
                                arrowLeft={arrowLeft}
                                arrowMark={arrowMark}
                                flagMark={flagMark}
                                arrowDown={arrowDown}
                                arrowUp={arrowUp}
                                arrowRight={arrowRight}
                                crossHair={crossHair}
                                textCallout={textCallout}
                                handler = {this.handler}  
                                />}
                              </TypeChooser>
                            );
                          default:
                            return null;
                        }
                      })()}

                      {modalShow && (
                        <SymbolList
                          passSymbol={this.passSymbol.bind(this)}
                          isOpen={this.isOpen}
                          watchlist={this.state.watchlist_id}
                          getWatchlist={this.getWatchlist}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
            </section>
          </div>
        </div>

        <div
          className="modal modal_customs fade"
          id="DJIAModal"
          tabindex="-1"
          aria-labelledby="DJIAModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="DJIAModalLabel">
                  Symbol Search
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  id="compareClose"
                  data-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                {/* <div className="symbol_search_bar">
                  <div className="symbol_left_bar">
                    <i class="fa-solid fa-magnifying-glass"></i>
                    <input type="text" placeholder="Selected Symbol" />
                  </div>
                  <div className="symbol_right_bar">
                    <ul>
                      <li>
                        <a href="#">
                          <i class="fas fa-divide"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i class="fas fa-plus"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i class="fas fa-minus"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i class="fas fa-xmark"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div> */}
                <div className="symbol_tabs">
                <ul class="nav nav-tabs cuastom_tabs" role="tablist">
                    <li class="nav-item">
                      <a
                        class="nav-link active"
                        data-toggle="tab"
                        href="#tabs-1"
                        onClick={()=>this.getSymbols('us-stock')}
                        role="tab"
                      >
                        Stock
                      </a>
                    </li>
                   
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        data-toggle="tab"
                        href="#tabs-2"
                        role="tab"
                        onClick={()=>this.getSymbols('forex')}
                      >
                        Forex
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        data-toggle="tab"
                        href="#tabs-3"
                        role="tab"
                        onClick={()=>this.getSymbols('crypto')}
                      >
                        Crypto
                      </a>
                    </li>
                    
                  </ul>
                  <div class="tab-content">
                    <div class="tab-pane active" id="tabs-1" role="tabpanel">
                      <CompareList
                        compSymbol={this.compSymbol.bind(this)}
                        stock="us-stock"
                        symbol="market/us"
                        newSymbol={newSymbol}
                      />
                    </div>
                    <div class="tab-pane " id="tabs-2" role="tabpanel">
                      <CompareList
                        compSymbol={this.compSymbol.bind(this)}
                        stock="forex"
                        symbol="market/us"
                        newSymbol={newSymbol}
                      />
                    </div>
                    <div class="tab-pane active" id="tabs-3" role="tabpanel">
                      <CompareList
                        compSymbol={this.compSymbol.bind(this)}
                        stock="crypto"
                        symbol="market/us"
                        newSymbol={newSymbol}
                      />
                    </div>
                    <div class="tab-pane" id="tabs-3" role="tabpanel">
                      <p>Third Panel</p>
                    </div>
                    <div class="tab-pane" id="tabs-4" role="tabpanel">
                      <p>Fourth Panel</p>
                    </div>
                    <div class="tab-pane" id="tabs-5" role="tabpanel">
                      <p>Fifth Panel</p>
                    </div>
                    <div class="tab-pane" id="tabs-6" role="tabpanel">
                      <p>Sixth Panel</p>
                    </div>
                    <div class="tab-pane" id="tabs-7" role="tabpanel">
                      <p>Seventh Panel</p>
                    </div>
                    <div class="tab-pane" id="tabs-8" role="tabpanel">
                      <p>Eighth Panel</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal modal_customs fade"
          id="loadModal"
          tabindex="-1"
          aria-labelledby="loadModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="DJIAModalLabel">
                  Load Layout
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="symbol_search_bar">
                  <div className="symbol_left_bar full_search">
                    <i class="fa-solid fa-magnifying-glass"></i>
                    <input type="text" placeholder="Search" />
                  </div>
                </div>
                <div className="load_layout_div">
                  <div className="load_layout_inner">
                    &nbsp;
                    <div className="laod_layout_center">
                      <div className="layout_name">LAYOUT NAME</div>
                    </div>
                    <i class="fas fa-arrow-down-short-wide"></i>
                  </div>
                  {listItems}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal modal_customs fade"
          id="indicatorModal"
          tabindex="-1"
          aria-labelledby="indicatorModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="DJIAModalLabel">
                  Indicators, Metrics & Strategies
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="symbol_search_bar">
                  <div className="symbol_left_bar full_search">
                    <i class="fa-solid fa-magnifying-glass"></i>
                    <input type="text" placeholder="Search" id="searchTheKey" />
                  </div>
                </div>
                <div className="symbol_tabs indicator_tabs">
                  <div className="indicator_left">
                    <ul
                      class="nav nav-tabs cuastom_tabs indicator_tab_custom"
                      role="tablist"
                    >
                      <li class="nav-item">
                        <a
                          class="nav-link active"
                          data-toggle="tab"
                          href="#i1"
                          role="tab"
                        >
                          <i class="fa-solid fa-user-large"></i>My Scripts
                        </a>
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          data-toggle="tab"
                          href="#i2"
                          role="tab"
                        >
                          <i class="fa-solid fa-chart-area"></i>Technicals
                        </a>
                      </li>
                      {/*<li class="nav-item">
                        <a
                          class="nav-link"
                          data-toggle="tab"
                          href="#i3"
                          role="tab"
                        >
                          <i class="fa-solid fa-chart-column"></i>Financials
                        </a>
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          data-toggle="tab"
                          href="#i4"
                          role="tab"
                        >
                          <i class="fa-solid fa-note-sticky"></i>Community
                          Scripts
                        </a>
                      </li>*/}
                    </ul>
                  </div>

                  <div className="tab-content indicator_right">
                    <div class="tab-pane active" id="i1" role="tabpanel">
                      <div className="myscripts_outer">
                        <i class="fa-solid fa-chart-line"></i>
                        <div className="script_title">Pine Script</div>
                        <p>
                          Welcome to the TradingView custom scripting engine. It
                          allows you to create your own custom studies or modify
                          existing. You do not have any scripts created.
                        </p>
                        <a className="script_btn" href="#">
                          Create your first script now
                        </a>
                      </div>
                    </div>
                    <div class="tab-pane" id="i2" role="tabpanel">
                      <ul class="nav nav-tabs cuastom_tabs" role="tablist">
                        <li class="nav-item">
                          <a
                            class="nav-link active"
                            data-toggle="tab"
                            href="#tabs-11"
                            role="tab"
                          >
                            Indicators
                          </a>
                        </li>
                        {/*<li class="nav-item">
                          <a
                            class="nav-link"
                            data-toggle="tab"
                            href="#tabs-21"
                            role="tab"
                          >
                            Strategies
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            data-toggle="tab"
                            href="#tabs-31"
                            role="tab"
                          >
                            Volume profile
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            data-toggle="tab"
                            href="#tabs-41"
                            role="tab"
                          >
                            Chart patterns
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            data-toggle="tab"
                            href="#tabs-51"
                            role="tab"
                          >
                            Candlestick patterns
                          </a>
                        </li>*/}
                      </ul>
                      <div class="tab-content">
                        <div
                          class="tab-pane active"
                          id="tabs-11"
                          role="tabpanel"
                        >
                          <div className="row">
                            <div className="col-md-5">
                              <ul id="matchKey">
                              <li style={{ cursor:'pointer'}} id="indicatorName"> 
                                <input type="checkbox" name="ema" className={`indicator_checkbox ${this.isChecked('ema')? 'indicator_checked' : ''} `} id='ema_checked' onChange={(e) => this.handleIndicatorClick(e,'ema','indicator')}/> EMA  
                                {/* <i class="fa-solid fa-gear indicator_setting" onClick={(e) => this.showIndicatorSetting(e,'ema')}></i> */}
                              </li> 
                              <li style={{ cursor:'pointer'}} id="indicatorName">
                                <input type="checkbox" name="sma" className={`indicator_checkbox ${this.isChecked('sma')? 'indicator_checked' : ''} `} onChange={(e) => this.handleIndicatorClick(e,'sma')} id='sma_checked'/> SMA
                                {/* <i class="fa-solid fa-gear indicator_setting" onClick={(e) => this.showIndicatorSetting(e,'sma')} ></i> */}
                              </li>
                              <li style={{ cursor:'pointer'}} id="indicatorName">
                                <input type="checkbox" name="zzag" className={`indicator_checkbox ${this.isChecked('zigzag')? 'indicator_checked' : ''} `} onChange={(e) => this.handleIndicatorClick(e,'zzag')} id='zigzag_checked' /> ZIGZAG
                                {/* <i class="fa-solid fa-gear indicator_setting" onClick={(e) => this.showIndicatorSetting(e,'zigzag')} ></i> */}
                              </li>
                              <li style={{ cursor:'pointer'}} id="indicatorName">
                                <input type="checkbox" name="ichi" className={`indicator_checkbox ${this.isChecked('ikh')? 'indicator_checked' : ''} `} onChange={(e) => this.handleIndicatorClick(e,'ichi')} id='ikh_checked'/> Ichimoku
                                {/* <i class="fa-solid fa-gear indicator_setting" onClick={(e) => this.showIndicatorSetting(e,'ikh')} ></i> */}
                              </li>
                              <li style={{ cursor:'pointer'}} id="indicatorName">
                                <input type="checkbox" name="bbands" className={`indicator_checkbox ${this.isChecked('bb')? 'indicator_checked' : ''} `} onChange={(e) => this.handleIndicatorClick(e,'bbands')} id='bb_checked'/> Bollinger Band
                                {/* <i class="fa-solid fa-gear indicator_setting" onClick={(e) => this.showIndicatorSetting(e,'bb')} ></i> */}
                              </li>
                              <li style={{ cursor:'pointer'}} id="indicatorName">
                                <input type="checkbox" name="rsi" className={`indicator_checkbox ${this.isChecked('rsi')? 'indicator_checked' : ''} `} onChange={(e) => this.handleIndicatorClick(e,'rsi')} id='rsi_checked'/> RSI
                                {/* <i class="fa-solid fa-gear indicator_setting" onClick={(e) => this.showIndicatorSetting(e,'rsi')} ></i> */}
                              </li>
                              <li style={{ cursor:'pointer'}} id="indicatorName">
                                <input type="checkbox" name="atr" className={`indicator_checkbox ${this.isChecked('atr')? 'indicator_checked' : ''} `} onChange={(e) => this.handleIndicatorClick(e,'atr')} id='atr_checked'/> ATR
                                {/* <i class="fa-solid fa-gear indicator_setting" onClick={(e) => this.showIndicatorSetting(e,'atr')} ></i> */}
                              </li>
                              <li style={{ cursor:'pointer'}} id="indicatorName">
                                <input type="checkbox" name="sar" className={`indicator_checkbox ${this.isChecked('psar')? 'indicator_checked' : ''} `} onChange={(e) => this.handleIndicatorClick(e,'sar')} id='psar_checked'/> PSAR
                                {/* <i class="fa-solid fa-gear indicator_setting" onClick={(e) => this.showIndicatorSetting(e,'psar')} ></i> */}
                              </li>
                              <li style={{ cursor:'pointer'}} id="indicatorName">
                                <input type="checkbox" name="acc" className={`indicator_checkbox ${this.isChecked('abands')? 'indicator_checked' : ''} `} onChange={(e) => this.handleIndicatorClick(e,'acc')} id='abands_checked'/> Accelerator
                                {/* <i class="fa-solid fa-gear indicator_setting" onClick={(e) => this.showIndicatorSetting(e,'acc')} ></i> */}
                              </li>
                              <li style={{ cursor:'pointer'}} id="indicatorName">
                                <input type="checkbox" name="macd" className={`indicator_checkbox ${this.isChecked('macd')? 'indicator_checked' : ''} `} onChange={(e) => this.handleIndicatorClick(e,'macd')} id='macd_checked'/> MACD
                                {/* <i class="fa-solid fa-gear indicator_setting" onClick={(e) => this.showIndicatorSetting(e,'macd')} ></i> */}
                              </li>
                              <li style={{ cursor:'pointer'}} id="indicatorName">
                                <input type="checkbox" name="mom" className={`indicator_checkbox ${this.isChecked('momentum')? 'indicator_checked' : ''} `} onChange={(e) => this.handleIndicatorClick(e,'mom')} id='momentum_checked'/> Momentum
                                {/* <i class="fa-solid fa-gear indicator_setting" onClick={(e) => this.showIndicatorSetting(e,'momentum')} ></i> */}
                              </li>
                              <li style={{ cursor:'pointer'}} id="indicatorName">
                                <input type="checkbox" name="awo" className={`indicator_checkbox ${this.isChecked('ao')? 'indicator_checked' : ''} `} onChange={(e) => this.handleIndicatorClick(e,'awo')} id='ao_checked'/> Awesome Osilator
                                {/* <i class="fa-solid fa-gear indicator_setting" onClick={(e) => this.showIndicatorSetting(e,'ao')} ></i> */}
                              </li>
                              <li style={{ cursor:'pointer'}} id="indicatorName">
                                <input type="checkbox" name="stoch" className={`indicator_checkbox ${this.isChecked('stochastic')? 'indicator_checked' : ''} `} onChange={(e) => this.handleIndicatorClick(e,'stoch')} id='stochastic_checked'/> Stoch
                                {/* <i class="fa-solid fa-gear indicator_setting" onClick={(e) => this.showIndicatorSetting(e,'stochastic')} ></i> */}
                              </li>
                              <li style={{ cursor:'pointer'}} id="indicatorName">
                                <input type="checkbox" name="ad" className={`indicator_checkbox ${this.isChecked('ad')? 'indicator_checked' : ''} `} onChange={(e) => this.handleIndicatorClick(e,'ad')} id='ad_checked'/> Accumulation Distribution
                                {/* <i class="fa-solid fa-gear indicator_setting" onClick={(e) => this.showIndicatorSetting(e,'ad')} ></i> */}
                              </li>
                              <li style={{ cursor:'pointer'}} id="indicatorName">
                                <input type="checkbox" name="lwma" className={`indicator_checkbox ${this.isChecked('wma')? 'indicator_checked' : ''} `} onChange={(e) => this.handleIndicatorClick(e,'lwma')} id='wma_checked'/> LWMA
                                {/* <i class="fa-solid fa-gear indicator_setting" onClick={(e) => this.showIndicatorSetting(e,'wma')} ></i> */}
                              </li>
                              <li style={{ cursor:'pointer'}} id="indicatorName">
                                <input type="checkbox" name="ma" className={`indicator_checkbox ${this.isChecked('ma')? 'indicator_checked' : ''} `} onChange={(e) => this.handleIndicatorClick(e,'ma')} id='wma_checked'/> MA
                                {/* <i class="fa-solid fa-gear indicator_setting" onClick={(e) => this.showIndicatorSetting(e,'wma')} ></i> */}
                              </li>
                              {/* <li style={{ cursor:'pointer'}}>
                                <input type="checkbox" name="osma" className={`indicator_checkbox ${this.isChecked('osma')? 'indicator_checked' : ''} `} onChange={(e) => this.handleIndicatorClick(e,'osma')} id='osma_checked'/> OSMA
                                 <i class="fa-solid fa-gear indicator_setting" onClick={(e) => this.showIndicatorSetting(e,'wma')} ></i> 
                              </li>
                              <li style={{ cursor:'pointer'}}>
                                <input type="checkbox" name="alg" className={`indicator_checkbox ${this.isChecked('aligator')? 'indicator_checked' : ''} `} onChange={(e) => this.handleIndicatorClick(e,'alg')} id='aligator_checked'/> Aligator
                                {/* <i class="fa-solid fa-gear indicator_setting" onClick={(e) => this.showIndicatorSetting(e,'wma')} ></i>
                              </li> */}
                              <li style={{ cursor:'pointer'}} id="indicatorName">
                                <input type="checkbox" name="hekashi" className={`indicator_checkbox ${this.isChecked('hekashi')? 'indicator_checked' : ''} `} onChange={(e) => this.handleIndicatorClick(e,'hekashi')} id='hekashi_checked'/> Heikinashi
                                {/* <i class="fa-solid fa-gear indicator_setting" onClick={(e) => this.showIndicatorSetting(e,'wma')} ></i> */}
                              </li>
                              <li style={{ cursor:'pointer'}} id="indicatorName">
                                <input type="checkbox" name="beapow" className={`indicator_checkbox ${this.isChecked('beapow')? 'indicator_checked' : ''} `} onChange={(e) => this.handleIndicatorClick(e,'beapow')} id='beapow_checked'/> Bear/Bull Power
                                {/* <i class="fa-solid fa-gear indicator_setting" onClick={(e) => this.showIndicatorSetting(e,'wma')} ></i> */}
                              </li>
                              <li style={{ cursor:'pointer'}} id="indicatorName">
                                <input type="checkbox" name="smma" className={`indicator_checkbox ${this.isChecked('smma')? 'indicator_checked' : ''} `} onChange={(e) => this.handleIndicatorClick(e,'smma')} id='smma_checked'/> SMMA
                                {/* <i class="fa-solid fa-gear indicator_setting" onClick={(e) => this.showIndicatorSetting(e,'wma')} ></i> */}
                              </li>
                              <li style={{ cursor:'pointer'}} id="indicatorName">
                                <input type="checkbox" name="volume" className={`indicator_checkbox ${this.isChecked('volume')? 'indicator_checked' : ''} `} onChange={(e) => this.handleIndicatorClick(e,'volume')} id='volume_checked'/> Volume
                                {/* <i class="fa-solid fa-gear indicator_setting" onClick={(e) => this.showIndicatorSetting(e,'wma')} ></i> */}
                              </li>
                            
                              
                              {/* <li style={{ cursor:'pointer'}}>
                                <input type="checkbox" className={`indicator_checkbox ${this.isChecked('atr')? 'indicator_checked' : ''} `} onChange={(e) => this.handleIndicatorClick(e,'atr')} /> ATR
                                <i class="fa-solid fa-gear indicator_setting" onClick={(e) => this.showIndicatorSetting(e,'atr')} ></i>
                              </li> */}

                              {/* <li style={{ cursor:'pointer'}}>
                                <input type="checkbox" className="indicator_checkbox" onClick={() => this.handleIndicatorClick('zzag')}/> 
                                ZIGZAG</li> */}
                              {/* 
                              <li style={{ cursor:'pointer'}}>
                                <input type="checkbox" className="indicator_checkbox" onClick={() => this.handleIndicatorClick('ichi')}/> 
                                Ichimoku</li> 
                              <li style={{ cursor:'pointer'}}>
                                <input type="checkbox" className="indicator_checkbox" onClick={() => this.handleIndicatorClick('bbands')}/> 
                                Bollinger Bands</li>
                              <li style={{ cursor:'pointer'}}>
                                <input type="checkbox" className="indicator_checkbox" onClick={() => this.handleIndicatorClick('rsi')}/> 
                                RSI</li>
                              <li style={{ cursor:'pointer'}}>
                              <input type="checkbox" className="indicator_checkbox" onClick={() => this.handleIndicatorClick('atr')}/> 
                                ATR</li> */}


                              {/* <li onClick={() => this.handleIndicatorClick('lwma')} style={{ cursor:'pointer'}}>LWMA</li> */}
                              {/* <li onClick={() => this.handleIndicatorClick('vbT')} style={{ cursor:'pointer'}}>vbT</li> */}
                              {/* <li onClick={() => this.handleIndicatorClick('acc')} style={{ cursor:'pointer'}}>Accelerator</li> */}
                              {/* <li onClick={() => this.handleIndicatorClick('ad')} style={{ cursor:'pointer'}}>Accumulation Distribution</li> */}

                              {/* <li onClick={() => this.handleIndicatorClick('alg')} style={{ cursor:'pointer'}}>Aligator</li> */}
                              
                              {/* <li onClick={() => this.handleIndicatorClick('awo')} style={{ cursor:'pointer'}}>Awesome Osilator</li> */}
                              {/* <li onClick={() => this.handleIndicatorClick('bwT')} style={{ cursor:'pointer'}}>Bears Power</li> */}

                              {/* <li onClick={() => this.handleIndicatorClick('demaT')} style={{ cursor:'pointer'}}>Bulls Power</li> */}
                              {/* <li onClick={() => this.handleIndicatorClick('ma')} style={{ cursor:'pointer'}}>Moving Avg</li> */}
                              {/* <li onClick={() => this.handleIndicatorClick('hekashi')} style={{ cursor:'pointer'}}>Heikin Ashi</li> */}
                              
                              {/* <li onClick={() => this.handleIndicatorClick('macd')} style={{ cursor:'pointer'}}>MACD</li> */}

                              {/* <li onClick={() => this.handleIndicatorClick('mom')} style={{ cursor:'pointer'}}>momentum</li> */}
                              {/* <li onClick={() => this.handleIndicatorClick('oscillatorsT')} style={{ cursor:'pointer'}}>oscillatorsT</li> */}
                              {/* <li onClick={() => this.handleIndicatorClick('sar')} style={{ cursor:'pointer'}}>Parabolic SAR</li> */}
                              {/* <li onClick={() => this.handleIndicatorClick('stoch')} style={{ cursor:'pointer'}}>STOCH</li> */} 
                                {/* <li onClick={() => this.handleIndicatorClick('acc')} style={{ cursor:'pointer'}}>Accelerator</li>

                                <li onClick={() => this.handleIndicatorClick('ema')} style={{ cursor:'pointer'}}>EMA</li>
                                <li onClick={() => this.handleIndicatorClick('sma')} style={{ cursor:'pointer'}}>SMA</li>
                                <li onClick={() => this.handleIndicatorClick('lwma')} style={{ cursor:'pointer'}}>LWMA</li>
                                <li onClick={() => this.handleIndicatorClick('vbT')} style={{ cursor:'pointer'}}>vbT</li>
                                <li onClick={() => this.handleIndicatorClick('ad')} style={{ cursor:'pointer'}}>Accumulation</li> */}


                                {/* <li>Accumulation</li>
                                <li>Alligator</li>
                                <li>ATR</li>
                                <li>Awesome</li>
                                <li>Bands</li> */}
                                
                              </ul>
                            </div>
                            <div className="col-md-6 border border-2 p-2 ml-1 indicator_setting_div" style={{ display:'none'}}> 
                                <h3 className="text-center">{this.state.indicator_setting_name.toUpperCase()}</h3>
                                {/* <div className="form-group">
                                  <label for="exampleInputEmail1">Index : </label>
                                  <input type="test" className="form-control input_height" placeholder="index" value="1"/> 
                                </div>
                                <div className="form-group">
                                  <label for="exampleInputPassword1">Period : </label>
                                  <input type="text" className="form-control input_height" placeholder="period" value="20"/>
                                </div>  */}
                                <div className="form-group">
                                  <label for="exampleInputPassword1">Line width : </label>
                                  <input type="text" name="lineWidth" className="form-control input_height" placeholder="0.6" value={this.state.line_width} id={`${this.state.indicator_setting_name}|width`} onChange={(e) => this.addParamInIndicator(e)}/>
                                </div> 
                                <div className="form-group">
                                  <label for="exampleInputPassword1">Line color : </label>
                                  <input type="color" className="form-control input_height" id={`${this.state.indicator_setting_name}|color`} onChange={(e) => this.addParamInIndicator(e)} value={this.state.indicator_line_color}/>
                                </div>   
                                <button type="button" class="btn btn-dark btn-lg btn-block" data-dismiss="modal" aria-label="Close" onClick={(e)=>this.addChanges()}>Add</button>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane" id="tabs-21" role="tabpanel">
                          <p>
                            <ul>
                              {/* <li onClick={() => this.handleIndicatorClick('macd')} style={{ cursor:'pointer'}}>MACD</li> */}
                            </ul>
                          </p>
                        </div>
                        <div class="tab-pane" id="tabs-31" role="tabpanel">
                          <p>Third Panel</p>
                        </div>
                        <div class="tab-pane" id="tabs-41" role="tabpanel">
                          <p>Fourth Panel</p>
                        </div>
                        <div class="tab-pane" id="tabs-51" role="tabpanel">
                          <p>Fifth Panel</p>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane" id="i3" role="tabpanel">
                      <ul class="nav nav-tabs cuastom_tabs" role="tablist">
                        <li class="nav-item">
                          <a
                            class="nav-link active"
                            data-toggle="tab"
                            href="#tabs-111"
                            role="tab"
                          >
                            Income statement
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            data-toggle="tab"
                            href="#tabs-211"
                            role="tab"
                          >
                            Balance sheet
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            data-toggle="tab"
                            href="#tabs-311"
                            role="tab"
                          >
                            Cash flow
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            data-toggle="tab"
                            href="#tabs-411"
                            role="tab"
                          >
                            Statistics
                          </a>
                        </li>
                      </ul>
                      <div class="tab-content">
                        <div
                          class="tab-pane active"
                          id="tabs-111"
                          role="tabpanel"
                        >
                          <p>First Panel</p>
                        </div>
                        <div class="tab-pane" id="tabs-211" role="tabpanel">
                          <p>Second Panel</p>
                        </div>
                        <div class="tab-pane" id="tabs-311" role="tabpanel">
                          <p>Third Panel</p>
                        </div>
                        <div class="tab-pane" id="tabs-411" role="tabpanel">
                          <p>Fourth Panel</p>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane" id="i4" role="tabpanel">
                      <ul class="nav nav-tabs cuastom_tabs" role="tablist">
                        <li class="nav-item">
                          <a
                            class="nav-link active"
                            data-toggle="tab"
                            href="#tabs-1111"
                            role="tab"
                          >
                            Editors picks
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            data-toggle="tab"
                            href="#tabs-2111"
                            role="tab"
                          >
                            Top
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            data-toggle="tab"
                            href="#tabs-3111"
                            role="tab"
                          >
                            Trending
                          </a>
                        </li>
                      </ul>
                      <div class="tab-content">
                        <div
                          class="tab-pane active"
                          id="tabs-1111"
                          role="tabpanel"
                        >
                          <p>First Panel</p>
                        </div>
                        <div class="tab-pane" id="tabs-2111" role="tabpanel">
                          <p>Second Panel</p>
                        </div>
                        <div class="tab-pane" id="tabs-3111" role="tabpanel">
                          <p>Third Panel</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal modal_customs fade"
          id="settingModal"
          tabindex="-1"
          aria-labelledby="settingModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="DJIAModalLabel">
                  Chart Setting
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                {/* <div className="symbol_search_bar">
                  <div className="symbol_left_bar full_search">
                    <i class="fa-solid fa-magnifying-glass"></i>
                    <input type="text" placeholder="Search" />
                  </div>
                </div> */}
                <div className="symbol_tabs indicator_tabs">
                  <div className="indicator_left">
                    <ul
                      class="nav nav-tabs cuastom_tabs indicator_tab_custom"
                      role="tablist"
                    >
                      <li class="nav-item">
                        <a
                          class="nav-link active"
                          data-toggle="tab"
                          href="#i11"
                          role="tab"
                        >
                          <i class="fa-solid fa-user-large"></i>Symbol
                        </a>
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          data-toggle="tab"
                          href="#i21"
                          role="tab"
                        >
                          <i class="fa-solid fa-chart-area"></i>Status Line
                        </a>
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          data-toggle="tab"
                          href="#i31"
                          role="tab"
                        >
                          <i class="fa-solid fa-chart-column"></i>Scales
                        </a>
                      </li>
                      {/* <li class="nav-item">
                        <a
                          class="nav-link"
                          data-toggle="tab"
                          href="#i41"
                          role="tab"
                        >
                          <i class="fa-solid fa-note-sticky"></i>Appearance
                        </a>
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          data-toggle="tab"
                          href="#i51"
                          role="tab"
                        >
                          <i class="fa-solid fa-note-sticky"></i>Trading
                        </a>
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          data-toggle="tab"
                          href="#i61"
                          role="tab"
                        >
                          <i class="fa-solid fa-note-sticky"></i>Events
                        </a>
                      </li> */}
                    </ul>
                  </div>
                  <div className="tab-content indicator_right">
                    <div class="tab-pane active" id="i11" role="tabpanel">


                      {(() => {
                        switch (graphType) {
                          case "candlestick":

                            return (
                              <div className="symbol_outer">
                                {/* <label class="remebercontainer bar">Color bars based on previous close
                                  <input type="checkbox" /><span class="checkmark"></span>
                                </label> */}
                                {/* <label class="remebercontainer bar">HCL bars
                                  <input type="checkbox" class="chk_hcl_bars" /><span class="checkmark"></span>
                                </label> */}
                                <div className="up_outer">
                                  <div className="up_left">
                                    Up Color
                                  </div>
                                  <div className="up_right">
                                    <div className="">
                                    <input type="hidden" value={JSON.stringify(this.state.show_up_color)} class="handle_up_color_change" />
                                      <div style={styles.swatch} onClick={() => this.handleColorClick('up_color')}>
                                        <div style={styles.color} />
                                      </div>
                                      {this.state.up_color ? <div style={styles.popover}>
                                        <div style={styles.cover} onClick={() => this.handleColorClose('up_color')} />
                                       
                                        <SketchPicker color={this.state.show_up_color}  onChange={this.handleUpColorChange} />
                                      </div> : null}
                                    </div>
                                  </div>
                                </div>
                                <div className="up_outer">
                                  <div className="up_left">
                                    Down Color
                                  </div>
                                  <div className="up_right">
                                    <div className="">
                                    <input type="hidden" value={JSON.stringify(this.state.show_down_color)} class="handle_down_color_change" />
                                    
                                      <div style={styles.swatch} onClick={() => this.handleColorClick('down_color')}>
                                        <div style={styles.colorDown} />
                                      </div>
                                      {this.state.down_color ? <div style={styles.popover}>
                                        <div style={styles.cover} onClick={() => this.handleColorClose('down_color')} />
                                        <SketchPicker color={this.state.show_down_color} onChange={this.handleDownColorChange} />
                                      </div> : null}
                                    </div>
                                  </div>
                                </div>
                                {/* <label class="remebercontainer bar">Thin bars
                                  <input type="checkbox" /><span class="checkmark"></span>
                                </label> */}
                                <div className="up_outer">
                                  <div className="up_left">
                                    <label class="remebercontainer bar">Last Price Line
                                      <input type="checkbox" className="chk_last_price_line" onChange={this.highLowLine}  defaultChecked={true} /><span class="checkmark"></span>
                                    </label>
                                  </div>
                                  <div className="up_right">
                                    <div className=" auto_width">
                                    <input type="hidden" value={JSON.stringify(this.state.show_last_price)} class="handle_price_line_color_change" />
                                      <div style={styles.swatch} onClick={() => this.handleColorClick('last_price')}>
                                        <div style={styles.colorLastLine} />
                                      </div>
                                      {this.state.last_price ? <div style={styles.popover}>
                                        <div style={styles.cover} onClick={() => this.handleColorClose('last_price')} />
                                        <SketchPicker color={this.state.show_last_price} onChange={this.handleLastlineColorChange} />
                                      </div> : null}
                                      <span className="line_span"></span>
                                    </div>
                                  </div>
                                </div>
                                {/* <div className="up_outer">
                                  <div className="up_left">
                                    <label class="remebercontainer bar">Pre/post market price line
                                      <input type="checkbox" /><span class="checkmark"></span>
                                    </label>
                                  </div>
                                  <div className="up_right">
                                    <div className="up_rightinner">
                                      <span className="colorr"></span>
                                    </div>
                                  </div>
                                </div>
                                <label class="remebercontainer bar">High, low and average price lines
                                  <input type="checkbox" onChange={this.highLowLine} />
                                  <span class="checkmark"></span>
                                </label>
                                <div className="up_outer">
                                  <div className="up_left">
                                    <label class="remebercontainer bar">Bid and ask lines
                                      <input type="checkbox" /><span class="checkmark"></span>
                                    </label>
                                  </div>
                                  <div className="up_right">
                                    <div className="up_rightinner">
                                      <span className="colorr"></span>
                                    </div>
                                  </div>
                                </div>
                                <label class="remebercontainer bar">Adjust data for dividents
                                  <input type="checkbox" /><span class="checkmark"></span>
                                </label>
                                <div className="up_outer">
                                  <div className="up_left">
                                    <label class="remebercontainer bar">Precision
                                      <input type="checkbox" /><span class="checkmark"></span>
                                    </label>
                                  </div>
                                  <div className="up_right">
                                    <select class="template_select">
                                      <option>Default</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="up_outer">
                                  <div className="up_left">
                                    <label class="remebercontainer bar">Timezone
                                      <input type="checkbox" /><span class="checkmark"></span>
                                    </label>
                                  </div>
                                  <div className="up_right">
                                    <select class="template_select">
                                      <option>UTC</option>
                                    </select>
                                  </div>
                                </div> */}
                              </div>
                            );
                          case "columnrange":
                            return (
                              <div className="symbol_outer">
                                <label class="remebercontainer bar">Color bars based on previous close
                                  <input type="checkbox" /><span class="checkmark"></span>
                                </label>
                                <label class="remebercontainer bar">HCL bars
                                  <input type="checkbox" /><span class="checkmark"></span>
                                </label>
                                <div className="up_outer">
                                  <div className="up_left">
                                    Up Color
                                  </div>
                                  <div className="up_right">
                                    <div className="">

                                      <div style={styles.swatch} onClick={() => this.handleColorClick('up_color')}>
                                        <div style={styles.color} />
                                      </div>
                                      {this.state.up_color ? <div style={styles.popover}>
                                        <div style={styles.cover} onClick={() => this.handleColorClose('up_color')} />
                                        <SketchPicker color={this.state.show_up_color} onChange={this.handleUpColorChange} />
                                      </div> : null}
                                    </div>
                                  </div>
                                </div>
                                <div className="up_outer">
                                  <div className="up_left">
                                    Down Color
                                  </div>
                                  <div className="up_right">
                                    <div className="">
                                      <div style={styles.swatch} onClick={() => this.handleColorClick('down_color')}>
                                        <div style={styles.colorDown} />
                                      </div>
                                      {this.state.down_color ? <div style={styles.popover}>
                                        <div style={styles.cover} onClick={() => this.handleColorClose('down_color')} />
                                        <SketchPicker color={this.state.show_down_color} onChange={this.handleDownColorChange} />
                                      </div> : null}
                                    </div>
                                  </div>
                                </div>
                                <label class="remebercontainer bar">Thin bars
                                  <input type="checkbox" /><span class="checkmark"></span>
                                </label>
                                <div className="up_outer">
                                  <div className="up_left">
                                    <label class="remebercontainer bar">Last Price Line
                                      <input type="checkbox" /><span class="checkmark"></span>
                                    </label>
                                  </div>
                                  <div className="up_right">
                                    <div className=" auto_width">
                                      <div style={styles.swatch} onClick={() => this.handleColorClick('last_price')}>
                                        <div style={styles.colorLastLine} />
                                      </div>
                                      {this.state.last_price ? <div style={styles.popover}>
                                        <div style={styles.cover} onClick={() => this.handleColorClose('last_price')} />
                                        <SketchPicker color={this.state.show_last_price} onChange={this.handleLastlineColorChange} />
                                      </div> : null}
                                      <span className="line_span"></span>
                                    </div>
                                  </div>
                                </div>
                                <div className="up_outer">
                                  <div className="up_left">
                                    <label class="remebercontainer bar">Pre/post market price line
                                      <input type="checkbox" /><span class="checkmark"></span>
                                    </label>
                                  </div>
                                  <div className="up_right">
                                    <div className="up_rightinner">
                                      <span className="colorr"></span>
                                    </div>
                                  </div>
                                </div>
                                <label class="remebercontainer bar">High, low and average price lines
                                  <input type="checkbox" /><span class="checkmark"></span>
                                </label>
                                <div className="up_outer">
                                  <div className="up_left">
                                    <label class="remebercontainer bar">Bid and ask lines
                                      <input type="checkbox" /><span class="checkmark"></span>
                                    </label>
                                  </div>
                                  <div className="up_right">
                                    <div className="up_rightinner">
                                      <span className="colorr"></span>
                                    </div>
                                  </div>
                                </div>
                                <label class="remebercontainer bar">Adjust data for dividents
                                  <input type="checkbox" /><span class="checkmark"></span>
                                </label>
                                <div className="up_outer">
                                  <div className="up_left">
                                    <label class="remebercontainer bar">Precision
                                      <input type="checkbox" /><span class="checkmark"></span>
                                    </label>
                                  </div>
                                  <div className="up_right">
                                    <select class="template_select">
                                      <option>Default</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="up_outer">
                                  <div className="up_left">
                                    <label class="remebercontainer bar">Timezone
                                      <input type="checkbox" /><span class="checkmark"></span>
                                    </label>
                                  </div>
                                  <div className="up_right">
                                    <select class="template_select">
                                      <option>UTC</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            );
                          case "ohlc":
                            return (
                              <div className="symbol_outer">
                                <label class="remebercontainer bar">Color bars based on previous close
                                  <input type="checkbox" /><span class="checkmark"></span>
                                </label>
                                <label class="remebercontainer bar">HCL bars
                                  <input type="checkbox" /><span class="checkmark"></span>
                                </label>
                                <div className="up_outer">
                                  <div className="up_left">
                                    Up Color
                                  </div>
                                  <div className="up_right">
                                    <div className="">

                                      <div style={styles.swatch} onClick={() => this.handleColorClick('up_color')}>
                                        <div style={styles.color} />
                                      </div>
                                      {this.state.up_color ? <div style={styles.popover}>
                                        <div style={styles.cover} onClick={() => this.handleColorClose('up_color')} />
                                        <SketchPicker color={this.state.show_up_color} onChange={this.handleUpColorChange} />
                                      </div> : null}
                                    </div>
                                  </div>
                                </div>
                                <div className="up_outer">
                                  <div className="up_left">
                                    Down Color
                                  </div>
                                  <div className="up_right">
                                    <div className="">
                                      <div style={styles.swatch} onClick={() => this.handleColorClick('down_color')}>
                                        <div style={styles.colorDown} />
                                      </div>
                                      {this.state.down_color ? <div style={styles.popover}>
                                        <div style={styles.cover} onClick={() => this.handleColorClose('down_color')} />
                                        <SketchPicker color={this.state.show_down_color} onChange={this.handleDownColorChange} />
                                      </div> : null}
                                    </div>
                                  </div>
                                </div>
                                <label class="remebercontainer bar">Thin bars
                                  <input type="checkbox" /><span class="checkmark"></span>
                                </label>
                                <div className="up_outer">
                                  <div className="up_left">
                                    <label class="remebercontainer bar">Last Price Line
                                      <input type="checkbox" /><span class="checkmark"></span>
                                    </label>
                                  </div>
                                  <div className="up_right">
                                    <div className=" auto_width">
                                      <div style={styles.swatch} onClick={() => this.handleColorClick('last_price')}>
                                        <div style={styles.colorLastLine} />
                                      </div>
                                      {this.state.last_price ? <div style={styles.popover}>
                                        <div style={styles.cover} onClick={() => this.handleColorClose('last_price')} />
                                        <SketchPicker color={this.state.show_last_price} onChange={this.handleLastlineColorChange} />
                                      </div> : null}
                                      <span className="line_span"></span>
                                    </div>
                                  </div>
                                </div>
                                <div className="up_outer">
                                  <div className="up_left">
                                    <label class="remebercontainer bar">Pre/post market price line
                                      <input type="checkbox" /><span class="checkmark"></span>
                                    </label>
                                  </div>
                                  <div className="up_right">
                                    <div className="up_rightinner">
                                      <span className="colorr"></span>
                                    </div>
                                  </div>
                                </div>
                                <label class="remebercontainer bar">High, low and average price lines
                                  <input type="checkbox" onChange={this.highLowLine} />
                                  <span class="checkmark"></span>
                                </label>
                                <div className="up_outer">
                                  <div className="up_left">
                                    <label class="remebercontainer bar">Bid and ask lines
                                      <input type="checkbox" /><span class="checkmark"></span>
                                    </label>
                                  </div>
                                  <div className="up_right">
                                    <div className="up_rightinner">
                                      <span className="colorr"></span>
                                    </div>
                                  </div>
                                </div>
                                <label class="remebercontainer bar">Adjust data for dividents
                                  <input type="checkbox" /><span class="checkmark"></span>
                                </label>
                                <div className="up_outer">
                                  <div className="up_left">
                                    <label class="remebercontainer bar">Precision
                                      <input type="checkbox" /><span class="checkmark"></span>
                                    </label>
                                  </div>
                                  <div className="up_right">
                                    <select class="template_select">
                                      <option>Default</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="up_outer">
                                  <div className="up_left">
                                    <label class="remebercontainer bar">Timezone
                                      <input type="checkbox" /><span class="checkmark"></span>
                                    </label>
                                  </div>
                                  <div className="up_right">
                                    <select class="template_select">
                                      <option>UTC</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            );
                          case "area":
                            return (
                              <div className="symbol_outer">
                                <label class="remebercontainer bar">Color bars based on previous close
                                  <input type="checkbox" /><span class="checkmark"></span>
                                </label>
                                <label class="remebercontainer bar">HCL bars
                                  <input type="checkbox" /><span class="checkmark"></span>
                                </label>
                                <div className="up_outer">
                                  <div className="up_left">
                                    Up Color
                                  </div>
                                  <div className="up_right">
                                    <div className="">

                                      <div style={styles.swatch} onClick={() => this.handleColorClick('up_color')}>
                                        <div style={styles.color} />
                                      </div>
                                      {this.state.up_color ? <div style={styles.popover}>
                                        <div style={styles.cover} onClick={() => this.handleColorClose('up_color')} />
                                        <SketchPicker color={this.state.show_up_color} onChange={this.handleUpColorChange} />
                                      </div> : null}
                                    </div>
                                  </div>
                                </div>
                                <div className="up_outer">
                                  <div className="up_left">
                                    Down Color
                                  </div>
                                  <div className="up_right">
                                    <div className="">
                                      <div style={styles.swatch} onClick={() => this.handleColorClick('down_color')}>
                                        <div style={styles.colorDown} />
                                      </div>
                                      {this.state.down_color ? <div style={styles.popover}>
                                        <div style={styles.cover} onClick={() => this.handleColorClose('down_color')} />
                                        <SketchPicker color={this.state.show_down_color} onChange={this.handleDownColorChange} />
                                      </div> : null}
                                    </div>
                                  </div>
                                </div>
                                <label class="remebercontainer bar">Thin bars
                                  <input type="checkbox" /><span class="checkmark"></span>
                                </label>
                                <div className="up_outer">
                                  <div className="up_left">
                                    <label class="remebercontainer bar">Last Price Line
                                      <input type="checkbox" /><span class="checkmark"></span>
                                    </label>
                                  </div>
                                  <div className="up_right">
                                    <div className=" auto_width">
                                      <div style={styles.swatch} onClick={() => this.handleColorClick('last_price')}>
                                        <div style={styles.colorLastLine} />
                                      </div>
                                      {this.state.last_price ? <div style={styles.popover}>
                                        <div style={styles.cover} onClick={() => this.handleColorClose('last_price')} />
                                        <SketchPicker color={this.state.show_last_price} onChange={this.handleLastlineColorChange} />
                                      </div> : null}
                                      <span className="line_span"></span>
                                    </div>
                                  </div>
                                </div>
                                <div className="up_outer">
                                  <div className="up_left">
                                    <label class="remebercontainer bar">Pre/post market price line
                                      <input type="checkbox" /><span class="checkmark"></span>
                                    </label>
                                  </div>
                                  <div className="up_right">
                                    <div className="up_rightinner">
                                      <span className="colorr"></span>
                                    </div>
                                  </div>
                                </div>
                                <label class="remebercontainer bar">High, low and average price lines
                                  <input type="checkbox" /><span class="checkmark"></span>
                                </label>
                                <div className="up_outer">
                                  <div className="up_left">
                                    <label class="remebercontainer bar">Bid and ask lines
                                      <input type="checkbox" /><span class="checkmark"></span>
                                    </label>
                                  </div>
                                  <div className="up_right">
                                    <div className="up_rightinner">
                                      <span className="colorr"></span>
                                    </div>
                                  </div>
                                </div>
                                <label class="remebercontainer bar">Adjust data for dividents
                                  <input type="checkbox" /><span class="checkmark"></span>
                                </label>
                                <div className="up_outer">
                                  <div className="up_left">
                                    <label class="remebercontainer bar">Precision
                                      <input type="checkbox" /><span class="checkmark"></span>
                                    </label>
                                  </div>
                                  <div className="up_right">
                                    <select class="template_select">
                                      <option>Default</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="up_outer">
                                  <div className="up_left">
                                    <label class="remebercontainer bar">Timezone
                                      <input type="checkbox" /><span class="checkmark"></span>
                                    </label>
                                  </div>
                                  <div className="up_right">
                                    <select class="template_select">
                                      <option>UTC</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            );
                          case "line":
                            return (
                              <div className="symbol_outer">
                                <div className="up_outer">
                                  <div className="up_left">
                                    Line
                                  </div>
                                  <div className="up_right">
                                    <div className="">

                                      <div style={styles.swatch} onClick={() => this.handleColorClick('line_color')}>
                                        <div style={styles.color} />
                                      </div>
                                      {this.state.line_color ? <div style={styles.popover}>
                                        <div style={styles.cover} onClick={() => this.handleColorClose('line_color')} />
                                        <SketchPicker color={this.state.show_up_color} onChange={this.handleLineColorChange} />
                                      </div> : null}
                                    </div>
                                  </div>
                                </div>

                                <label class="remebercontainer bar">Thin bars
                                  <input type="checkbox" /><span class="checkmark"></span>
                                </label>
                                <div className="up_outer">
                                  <div className="up_left">
                                    <label class="remebercontainer bar">Last Price Line
                                      <input type="checkbox" /><span class="checkmark"></span>
                                    </label>
                                  </div>
                                  <div className="up_right">
                                    <div className=" auto_width">
                                      <div style={styles.swatch} onClick={() => this.handleColorClick('last_price')}>
                                        <div style={styles.colorLastLine} />
                                      </div>
                                      {this.state.last_price ? <div style={styles.popover}>
                                        <div style={styles.cover} onClick={() => this.handleColorClose('last_price')} />
                                        <SketchPicker color={this.state.show_last_price} onChange={this.handleLastlineColorChange} />
                                      </div> : null}
                                      <span className="line_span"></span>
                                    </div>
                                  </div>
                                </div>
                                <div className="up_outer">
                                  <div className="up_left">
                                    <label class="remebercontainer bar">Pre/post market price line
                                      <input type="checkbox" /><span class="checkmark"></span>
                                    </label>
                                  </div>
                                  <div className="up_right">
                                    <div className="up_rightinner">
                                      <span className="colorr"></span>
                                    </div>
                                  </div>
                                </div>
                                <label class="remebercontainer bar">High, low and average price lines
                                  <input type="checkbox" onChange={this.highLowLine} /><span class="checkmark"></span>
                                </label>
                                <div className="up_outer">
                                  <div className="up_left">
                                    <label class="remebercontainer bar">Bid and ask lines
                                      <input type="checkbox" /><span class="checkmark"></span>
                                    </label>
                                  </div>
                                  <div className="up_right">
                                    <div className="up_rightinner">
                                      <span className="colorr"></span>
                                    </div>
                                  </div>
                                </div>
                                <label class="remebercontainer bar">Adjust data for dividents
                                  <input type="checkbox" /><span class="checkmark"></span>
                                </label>
                                <div className="up_outer">
                                  <div className="up_left">
                                    <label class="remebercontainer bar">Precision
                                      <input type="checkbox" /><span class="checkmark"></span>
                                    </label>
                                  </div>
                                  <div className="up_right">
                                    <select class="template_select">
                                      <option>Default</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="up_outer">
                                  <div className="up_left">
                                    <label class="remebercontainer bar">Timezone
                                      <input type="checkbox" /><span class="checkmark"></span>
                                    </label>
                                  </div>
                                  <div className="up_right">
                                    <select class="template_select">
                                      <option>UTC</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            );
                          case "renko":
                            return (
                              <div className="symbol_outer">
                                <label class="remebercontainer bar">Color bars based on previous close
                                  <input type="checkbox" /><span class="checkmark"></span>
                                </label>
                                <label class="remebercontainer bar">HCL bars
                                  <input type="checkbox" /><span class="checkmark"></span>
                                </label>
                                <div className="up_outer">
                                  <div className="up_left">
                                    Up Color
                                  </div>
                                  <div className="up_right">
                                    <div className="">

                                      <div style={styles.swatch} onClick={() => this.handleColorClick('up_color')}>
                                        <div style={styles.color} />
                                      </div>
                                      {this.state.up_color ? <div style={styles.popover}>
                                        <div style={styles.cover} onClick={() => this.handleColorClose('up_color')} />
                                        <SketchPicker color={this.state.show_up_color} onChange={this.handleUpColorChange} />
                                      </div> : null}
                                    </div>
                                  </div>
                                </div>
                                <div className="up_outer">
                                  <div className="up_left">
                                    Down Color
                                  </div>
                                  <div className="up_right">
                                    <div className="">
                                      <div style={styles.swatch} onClick={() => this.handleColorClick('down_color')}>
                                        <div style={styles.colorDown} />
                                      </div>
                                      {this.state.down_color ? <div style={styles.popover}>
                                        <div style={styles.cover} onClick={() => this.handleColorClose('down_color')} />
                                        <SketchPicker color={this.state.show_down_color} onChange={this.handleDownColorChange} />
                                      </div> : null}
                                    </div>
                                  </div>
                                </div>
                                <label class="remebercontainer bar">Thin bars
                                  <input type="checkbox" /><span class="checkmark"></span>
                                </label>
                                <div className="up_outer">
                                  <div className="up_left">
                                    <label class="remebercontainer bar">Last Price Line
                                      <input type="checkbox" /><span class="checkmark"></span>
                                    </label>
                                  </div>
                                  <div className="up_right">
                                    <div className=" auto_width">
                                      <div style={styles.swatch} onClick={() => this.handleColorClick('last_price')}>
                                        <div style={styles.colorLastLine} />
                                      </div>
                                      {this.state.last_price ? <div style={styles.popover}>
                                        <div style={styles.cover} onClick={() => this.handleColorClose('last_price')} />
                                        <SketchPicker color={this.state.show_last_price} onChange={this.handleLastlineColorChange} />
                                      </div> : null}
                                      <span className="line_span"></span>
                                    </div>
                                  </div>
                                </div>
                                <div className="up_outer">
                                  <div className="up_left">
                                    <label class="remebercontainer bar">Pre/post market price line
                                      <input type="checkbox" /><span class="checkmark"></span>
                                    </label>
                                  </div>
                                  <div className="up_right">
                                    <div className="up_rightinner">
                                      <span className="colorr"></span>
                                    </div>
                                  </div>
                                </div>
                                <label class="remebercontainer bar">High, low and average price lines
                                  <input type="checkbox" /><span class="checkmark"></span>
                                </label>
                                <div className="up_outer">
                                  <div className="up_left">
                                    <label class="remebercontainer bar">Bid and ask lines
                                      <input type="checkbox" /><span class="checkmark"></span>
                                    </label>
                                  </div>
                                  <div className="up_right">
                                    <div className="up_rightinner">
                                      <span className="colorr"></span>
                                    </div>
                                  </div>
                                </div>
                                <label class="remebercontainer bar">Adjust data for dividents
                                  <input type="checkbox" /><span class="checkmark"></span>
                                </label>
                                <div className="up_outer">
                                  <div className="up_left">
                                    <label class="remebercontainer bar">Precision
                                      <input type="checkbox" /><span class="checkmark"></span>
                                    </label>
                                  </div>
                                  <div className="up_right">
                                    <select class="template_select">
                                      <option>Default</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="up_outer">
                                  <div className="up_left">
                                    <label class="remebercontainer bar">Timezone
                                      <input type="checkbox" /><span class="checkmark"></span>
                                    </label>
                                  </div>
                                  <div className="up_right">
                                    <select class="template_select">
                                      <option>UTC</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            );
                          default:
                            return null;
                        }
                      })()}



                    </div>
                    <div class="tab-pane" id="i21" role="tabpanel">
                      <div className="symbol_outer">
                        <div className="up_outer">
                          <div className="up_left">
                            <label class="remebercontainer bar">Symbol
                              <input
                                // onChange={this.handleChangeStatusLine.bind(this)}
                                name="status_line_symbol"
                                className="chk_symbol"
                                defaultChecked={this.state.status_line_symbol}
                                type="checkbox" /><span class="checkmark"></span>
                            </label>
                          </div>
                          <div className="up_right">
                            <select
                              name="symbol_variation"
                              class="template_select"
                             
                            >
                              <option selected value="0">Description</option>
                              <option value="1">Ticker</option>
                              <option value="2">Ticker and description</option>
                            </select>
                          </div>
                        </div>
                        {<label class="remebercontainer bar">Open market status
                          <input
                            onChange={this.handleChangeStatusLine.bind(this)}
                            name="market_status"
                            defaultChecked={this.state.market_status}
                            type="checkbox" /><span class="checkmark"></span>
                        </label>}
                        <label class="remebercontainer bar">OHLC values
                          <input
                            onChange={this.handleChangeStatusLine.bind(this)}
                            name="ohlc_values"
                            defaultChecked={this.state.ohlc_values}
                            type="checkbox" /><span class="checkmark"></span>
                        </label>
                        {<label style={{marginLeft:"20px"}} class="remebercontainer bar">Bar change values
                          <input
                            onChange={this.handleChangeStatusLine.bind(this)}
                            name="bar_change_val"
                            defaultChecked={this.state.bar_change_val}
                            type="checkbox" /><span class="checkmark"></span>
                        </label>}
                        <label class="remebercontainer bar">Volume
                          <input
                            class="vol_change"
                            name="vol_values"
                            defaultChecked={true}
                            type="checkbox" /><span class="checkmark"></span>
                        </label>
                        {/* <label class="remebercontainer bar">Indicator Titles
                          <input type="checkbox" /><span class="checkmark"></span>
                        </label>
                        <label class="remebercontainer bar argument">Indicator arguments
                          <input type="checkbox" /><span class="checkmark"></span>
                        </label>
                        <label class="remebercontainer bar">Indicator values
                          <input type="checkbox" /><span class="checkmark"></span>
                        </label> */
                          // <label class="remebercontainer bar">Background
                          //   <AlphaPicker color={this.state.alpha_color} onChange={this.handleUpAlphaChange} />
                          // </label>
                        }
                      </div>
                    </div>
                    <div class="tab-pane" id="i31" role="tabpanel">
                      <div className="symbol_outer">
                        {/* <label class="remebercontainer bar">Symbol name label
                          <input
                            onChange={this.handleChangeStatusLine.bind(this)}
                            name="sym_name_label"
                            class="chk_sym_name_label"
                            defaultChecked={this.state.sym_name_label}
                            type="checkbox" /><span class="checkmark"></span>
                        </label> */}
                        <div className="up_outer">
                          <div className="up_left">
                            <label class="remebercontainer bar">Symbol last price label
                              <input
                                onChange={this.handleChangeStatusLine.bind(this)}
                                name="sym_last_price_label"
                                class="sym_last_price_label"
                                defaultChecked={this.state.sym_last_price_label}
                                type="checkbox" /><span class="checkmark"></span>
                            </label>
                          </div>
                          {/* <div className="up_right">
                            <select class="template_select">
                              <option>Value according to scale</option>
                              <option>Price and percentage value</option>
                            </select>
                          </div> */}
                        </div>
                        <label class="remebercontainer bar">Bid and ask labels
                          <input
                            onChange={this.handleChangeStatusLine.bind(this)}
                            name="askBid"
                            class="ch_bid_ask_label"
                            defaultChecked={this.state.askBid}
                            type="checkbox" /><span class="checkmark"></span>
                        </label>
                        {/* <label class="remebercontainer bar">Pre/post market price label
                          <input
                            onChange={this.handleChangeStatusLine.bind(this)}
                            name="pre_post_mkt_price"
                            class="chk_pre_post_mkt_lbl"
                            defaultChecked={this.state.pre_post_mkt_price}
                            type="checkbox" /><span class="checkmark"></span>
                        </label> */}
                        <label class="remebercontainer bar">High, low and average price labels
                          <input
                            onChange={this.handleChangeStatusLine.bind(this)}
                            name="sym_highlowlast_label"
                            class="chk_sym_highlowlast_label"
                            defaultChecked={this.state.sym_highlowlast_label}
                            type="checkbox" /><span class="checkmark"></span>
                        </label>
                        {/* <label class="remebercontainer bar">Indicators and financials name labels
                          <input type="checkbox" /><span class="checkmark"></span>
                        </label>
                        <label class="remebercontainer bar">Indicators and financials value labels
                          <input type="checkbox" /><span class="checkmark"></span>
                        </label> */}
                        {/* <label class="remebercontainer bar">No overlapping labels
                          <input
                            onChange={this.handleChangeStatusLine.bind(this)}
                            name="lo_overlapping_label"
                            defaultChecked={this.state.lo_overlapping_label}
                            type="checkbox" /><span class="checkmark"></span>
                        </label> */}
                        <label class="remebercontainer bar">Countdown to bar close
                          <input
                            onChange={this.handleChangeStatusLine.bind(this)}
                            name="countdownBar"
                            class="chk_label_countdown"
                            defaultChecked={this.state.countdownBar}
                            type="checkbox" /><span class="checkmark"></span>
                        </label>
                        <label class="remebercontainer bar">Plus button
                          <input
                            onChange={this.handleChangeStatusLine.bind(this)}
                            name="addPlusAlert"
                            defaultChecked={this.state.addPlusAlert}
                            type="checkbox" /><span class="checkmark"></span>
                        </label>
                        {/* <div className="up_outer">
                          <div className="up_left">
                            Currency and Unit
                          </div>
                          <div className="up_right">
                            <select class="template_select">
                              <option>Always visible</option>
                              <option>Always invisible</option>
                              <option>Visible on mouse over</option>
                            </select>
                          </div>
                        </div>
                        <div className="up_outer">
                          <div className="up_left">
                            <label class="remebercontainer bar">Lock price to bar ratio
                              <input
                                onChange={this.handleChangeStatusLine.bind(this)}
                                name="lock_price_to_bar_ratio"
                                defaultChecked={this.state.lock_price_to_bar_ratio}
                                type="checkbox" /><span class="checkmark"></span>
                            </label>
                          </div>
                          <div className="up_right">
                            <input
                              disabled={!this.state.lock_price_to_bar_ratio}
                              step="0.1"
                              className="template_select"
                              onChange={this.handleChange.bind(this)}
                              name="lock_price_to_bar_ratio_val"
                              defaultChecked={this.state.lock_price_to_bar_ratio_val}
                              min="0"
                              type="number" />

                          </div>
                        </div>
                        <div className="up_outer">
                          <div className="up_left">
                            Scales placement
                          </div>
                          <div className="up_right">
                            <select class="template_select"
                              name="scale_placement"
                              onChange={this.handleChange.bind(this)}
                            >
                              <option value="left">Stack on the left</option>
                              <option selected value="right">Stack on the right</option>
                              <option value="left">Auto</option>
                            </select>
                          </div>
                        </div> */}
                        <div className="up_outer">
                          <div className="up_left">
                            Date format
                          </div>
                          <div className="up_right">
                            <select class="template_select"
                              name="date_format_val"
                              onChange={this.handleChange.bind(this)}
                            >
                              <option value="%d %b, '%y">29 Sep '97</option>
                              <option value="%b %d, %Y">Sep 29, 1997</option>
                              <option value="%b %d">Sep 29</option>
                              <option value="%Y-%m-%d">1997-09-29</option>
                              <option value="%y-%m-%d">97-09-29</option>
                              <option value="%y/%m/%d">97/09/29</option>
                              <option value="%Y/%m/%d">1997/09/29</option>
                              <option value="%d-%m-%Y">29-09-1997</option>
                              <option value="%d-%m-%y">29-09-97</option>
                              <option value="%d/%m/%y">29/09/97</option>
                              <option value="%d/%m/%Y">29/09/1997</option>
                              <option value="%m/%d/%y">09/29/97</option>
                              <option value="%m/%d/%Y">09/29/1997</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane" id="i41" role="tabpanel">
                      <div className="symbol_outer">
                        <div className="up_outer">
                          <div className="up_left">
                            Background
                          </div>
                          <div className="up_right">
                            <select class="template_select">
                              <option>Solid</option>
                              <option>Gradient</option>
                            </select>
                            <div className="up_rightinner">
                              <span className="colorr"></span>
                            </div>
                          </div>
                        </div>
                        <div className="up_outer">
                          <div className="up_left">
                            Vert grid lines
                          </div>
                          <div className="up_right">
                            <div className="up_rightinner">
                              <span className="colorr"></span>
                            </div>
                            <div className="up_rightinner">
                              <span className="colorr"></span>
                            </div>
                          </div>
                        </div>
                        <div className="up_outer">
                          <div className="up_left">
                            Horz grid lines
                          </div>
                          <div className="up_right">
                            <div className="up_rightinner">
                              <span className="colorr"></span>
                            </div>
                            <div className="up_rightinner">
                              <span className="colorr"></span>
                            </div>
                          </div>
                        </div>
                        <div className="up_outer">
                          <div className="up_left">
                            Scales text
                          </div>
                          <div className="up_right">
                            <div className="up_rightinner">
                              <span className="colorr"></span>
                            </div>
                            <div className="up_rightinner">
                              <span className="colorr"></span>
                            </div>
                          </div>
                        </div>
                        <div className="up_outer">
                          <div className="up_left">
                            Scales lines
                          </div>
                          <div className="up_right">
                            <div className="up_rightinner">
                              <span className="colorr"></span>
                            </div>
                          </div>
                        </div>
                        <div className="up_outer">
                          <div className="up_left">
                            Crosshair
                          </div>
                          <div className="up_right">
                            <div className="up_rightinner">
                              <span className="colorr"></span>
                            </div>
                            <div className="up_rightinner">
                              <span className="colorr"></span>
                            </div>
                          </div>
                        </div>
                        <div className="up_outer">
                          <div className="up_left">
                            <label class="remebercontainer bar">Watermark
                              <input type="checkbox" /><span class="checkmark"></span>
                            </label>
                          </div>
                          <div className="up_right">
                            <div className="up_rightinner auto_width">
                              <span className="colorr"></span>
                              <span className="line_span"></span>
                            </div>
                          </div>
                        </div>
                        <div className="up_outer">
                          <div className="up_left">
                            Navigation buttons
                          </div>
                          <div className="up_right">
                            <select class="template_select">
                              <option>Always visible</option>
                              <option>Always invisible</option>
                              <option>Visible on mouse over</option>
                            </select>
                          </div>
                        </div>
                        <div className="up_outer">
                          <div className="up_left">
                            Pane buttons
                          </div>
                          <div className="up_right">
                            <select class="template_select">
                              <option>Always visible</option>
                              <option>Always invisible</option>
                              <option>Visible on mouse over</option>
                            </select>
                          </div>
                        </div>
                        <div className="up_outer">
                          <div className="up_left">
                            Top Margin
                          </div>
                          <div className="up_right">
                            <input type="text" placeholder="" className="template_select" /> %
                          </div>
                        </div>
                        <div className="up_outer">
                          <div className="up_left">
                            Bottom Margin
                          </div>
                          <div className="up_right">
                            <input type="text" placeholder="" className="template_select" /> %
                          </div>
                        </div>
                        <div className="up_outer">
                          <div className="up_left">
                            Right Margin
                          </div>
                          <div className="up_right">
                            <input type="text" placeholder="" className="template_select" /> bars
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane" id="i51" role="tabpanel">
                      <div className="symbol_outer">
                        <span className="gen_span">General</span>
                        <label class="remebercontainer bar">Buy/sell buttons
                          <input type="checkbox" /><span class="checkmark"></span>
                        </label>
                        <label class="remebercontainer bar">Instant orders placement
                          <input type="checkbox" /><span class="checkmark"></span>
                        </label>
                        <label class="remebercontainer bar">Sound notifications for executions
                          <input type="checkbox" /><span class="checkmark"></span>
                        </label>
                        <label class="remebercontainer bar">Rejection notifications only
                          <input type="checkbox" /><span class="checkmark"></span>
                        </label>
                        <span className="gen_span">appearance</span>
                        <label class="remebercontainer bar">Positions
                          <input type="checkbox" /><span class="checkmark"></span>
                        </label>
                        <div className="up_outer">
                          <div className="up_left">
                            <label class="remebercontainer bar argument">Profit & loss in
                              <input type="checkbox" /><span class="checkmark"></span>
                            </label>
                          </div>
                          <div className="up_right">
                            <select class="template_select">
                              <option>money</option>
                              <option>ticks</option>
                              <option>%</option>
                            </select>
                          </div>
                        </div>
                        <label class="remebercontainer bar argument">Reverse button
                          <input type="checkbox" /><span class="checkmark"></span>
                        </label>
                        <label class="remebercontainer bar">Orders
                          <input type="checkbox" /><span class="checkmark"></span>
                        </label>
                        <label class="remebercontainer bar">Executions
                          <input type="checkbox" /><span class="checkmark"></span>
                        </label>
                        <label class="remebercontainer bar">Extended price line for positions & orders
                          <input type="checkbox" /><span class="checkmark"></span>
                        </label>
                        <div className="up_outer">
                          <div className="up_left">
                            Alignment of orders & positions
                          </div>
                          <div className="up_right">
                            <select class="template_select">
                              <option>Left</option>
                              <option>Center</option>
                              <option>Right</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane" id="i61" role="tabpanel">

                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer modal_footer_custom">
                <select className="template_select">
                  <option>Template</option>
                </select>
                <div className="btns_outer_footer">
                  <button type="button" class="cancel_btn"  data-dismiss="modal">
                    Cancel
                  </button>
                  <button type="button" class="okbtn"  data-dismiss="modal">
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal modal_customs fade"
          id="compareModal"
          tabindex="-1"
          aria-labelledby="compareModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="DJIAModalLabel">
                  Compare Symbol
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="symbol_search_bar">
                  <div className="symbol_left_bar full_search">
                    <i class="fa-solid fa-magnifying-glass"></i>
                    <input type="text" placeholder="Search" />
                  </div>
                </div>
                <div className="symbol_tabs"></div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal modal_customs fade"
          id="renameModal"
          tabindex="-1"
          aria-labelledby="renameModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  id="alertclose"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div class="export_chart_inner">
                  <div class="chart_e_div1">
                    <span>Login To access these features</span>
                  </div>
                </div>
              </div>
              <div class="modal-footer modal_footer_custom">
                <div class="btns_outer_footer">
                  <Link to={"/login"} class="cancel_btn">
                    Login
                  </Link>
                  <Link to={"/signup"} target="_blank" class="okbtn">Signup </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal modal_customs fade"
          id="alertModal"
          tabindex="-1"
          aria-labelledby="alertModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="DJIAModalLabel">
                  Create Alert
                </h5>
                <button
                  type="button"
                  id="alertclose"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div class="export_chart_inner">
                  <div class="chart_e_div">
                    <span>Condition</span>
                    <div className="right_conditon">
                      <select
                        class="template_select"
                        name="cond_name"
                        onChange={this.handleChange.bind(this)}
                      >
                        <option selected="true" disabled="disabled">
                          {slctSymbol + " (" + symbolName + ")"}
                        </option>
                        <option value="cci">CCI (20, close)</option>
                        <option value="obv">OBV (2, close)</option>
                        <option value="roc">ROC (2, close)</option>
                        <option value="cmf">CMF(12, close)</option>
                        {this.state.newSymbol.map((row, i) => (

                          <option value={row}>{row}</option>

                        ))}
                      </select>
                      <span className="error_msg" style={{ color: "red" }}>
                        {this.state.errors["cond_name"]}
                      </span>
                    </div>
                  </div>

                  <div class="chart_e_div">
                    <span>&nbsp;</span>
                    <div className="right_conditon">
                      <select
                        name="cond_check"
                        class="template_select"
                        onChange={this.handleChange.bind(this)}
                      >
                        <option value="crossing">Crossing</option>
                        <option value="crossingup">Crossing Up</option>
                        <option value="crossingdown">Crossing Down</option>
                        <option value="greaterthan">Greater Than</option>
                        <option value="lessthan">Less Than</option>
                      </select>
                      <span className="error_msg" style={{ color: "red" }}>
                        {this.state.errors["cond_check"]}
                      </span>
                    </div>
                  </div>

                  <div class="chart_e_div">
                    <span>&nbsp;</span>
                    <div class="row chat_div_row">
                      <div class="col-md-6">
                        <select class="template_select">
                          <option>Value</option>
                        </select>
                      </div>
                      <div class="col-md-6">
                        <input
                          class="custom_input"
                          type="number"
                          name="cond_value"
                          placeholder="0.000"
                          onChange={this.handleChange.bind(this)}
                          value={cond_value}
                        />
                      </div>
                      <span className="error_msg" style={{ color: "red" }}>
                        {this.state.errors["cond_value"]}
                      </span>
                    </div>
                  </div>
                  <div class="chart_e_div">
                    <span>Options</span>
                    <div className="right_conditon">
                      <div class="option_div">
                        <a
                          href="#"
                          name="option"
                          values="once"
                          onClick={this.handleChange.bind(this)}
                        >
                          Only Once
                        </a>
                        <a
                          href="#"
                          name="option"
                          values="everytime"
                          onClick={this.handleChange.bind(this)}
                        >
                          Every Time
                        </a>
                      </div>

                      <span className="error_msg" style={{ color: "red" }}>
                        {this.state.errors["option"]}
                      </span>
                    </div>
                  </div>

                  <div class="chart_e_div">
                    <span>Expiration Time</span>
                    <div class="row chat_div_row">
                      <div class="col-md-6">
                        <input
                          class="custom_input"
                          name="condTime"
                          onChange={this.handleChange.bind(this)}
                          type="time"
                          value={condTime}
                        />
                        <span className="error_msg" style={{ color: "red" }}>
                          {this.state.errors["condTime"]}
                        </span>
                      </div>
                      <div class="col-md-6">
                        <input
                          class="custom_input"
                          name="condDate"
                          onChange={this.handleChange.bind(this)}
                          type="date"
                          value={condDate}
                        />
                        <span className="error_msg" style={{ color: "red" }}>
                          {this.state.errors["condDate"]}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div class="chart_e_div">
                    <span>&nbsp;</span>
                    <div class="row chat_div_row">
                      <div class="col-md-6">
                        <label class="remebercontainer">
                          Show pop-up
                          <input
                            type="checkbox"
                            name="action_method"
                            value="popup"
                            onChange={(e) => this.handleCheckboxChange(e)}
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="chart_e_div">
                    <span>&nbsp;</span>
                    <div class="row chat_div_row">
                      <div class="col-md-6">
                        <label class="remebercontainer">
                          Send email
                          <input
                            type="checkbox"
                            name="action_method"
                            value="mail"
                            onChange={(e) => this.handleCheckboxChange(e)}
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div class="chart_e_div">
                    <span>&nbsp;</span>
                    <div class="row chat_div_row">
                      <div class="col-md-6">
                        <label class="remebercontainer">
                          Webhook URL
                          <input
                            type="checkbox"
                            name="set_webhook"
                            value="web_hook_url"
                            onChange={(e) => this.handleCheckboxChange(e)}
                          />
                          <span class="checkmark"></span>
                        </label>
                        <span className="error_msg" style={{ color: "red" }}>
                          {this.state.errors["action_method"]}
                        </span>
                      </div>
                    </div>
                  </div>
                  {
                    (this.state.set_webhook == true) &&
                    <div class="chart_e_div">
                      <span>URL</span>
                      <div className="right_conditon">
                        <input
                          class="custom_input"
                          type="text"
                          name="web_hook_url"
                          onChange={this.handleChange.bind(this)}
                        />
                        <span className="error_msg" style={{ color: "red" }}>
                          {this.state.errors["alert_name"]}
                        </span>
                      </div>
                    </div>
                  }

                  <div class="chart_e_div">
                    <span>Alert name</span>
                    <div className="right_conditon">
                      <input
                        class="custom_input"
                        type="text"
                        name="alert_name"
                        value={alert_name}
                        onChange={this.handleChange.bind(this)}
                      />
                      <span className="error_msg" style={{ color: "red" }}>
                        {this.state.errors["alert_name"]}
                      </span>
                    </div>
                  </div>
                  <div class="chart_e_div">
                    <span>Message</span>
                    <div className="right_conditon">
                      <textarea
                        class="custom_input"
                        name="alert_msg"
                        value={alert_msg}
                        onChange={this.handleChange.bind(this)}
                      >
                        {alert_msg}
                      </textarea>
                      <span className="error_msg" style={{ color: "red" }}>
                        {this.state.errors["alert_msg"]}
                      </span>
                    </div>
                  </div>

                  <div class="chart_e_div">
                    <span>&nbsp;</span>
                    <div class="row chat_div_row">
                      <div class="col-md-12">
                        <p>You can use special placeholders such as etc.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer modal_footer_custom">
                <div class="btns_outer_footer">
                  <button type="button" class="cancel_btn" data-dismiss="modal">
                    Cancel
                  </button>
                  <button
                    type="button"
                    class="okbtn"
                    onClick={this.handleAlertClick.bind()}
                  >
                    Create
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal modal_customs export_modal fade"
          id="searchModal"
          tabindex="-1"
          aria-labelledby="searchModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="searchModalLabel">
                  Search tool or function
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="symbol_search_bar">
                  <div className="symbol_left_bar full_search">
                    <i class="fa-solid fa-magnifying-glass"></i>
                    <input type="text" placeholder="Search" />
                  </div>
                </div>
                <div className="export_chart_inner">
                  <p className="search_p">
                    Type to search for drawings, functions and settings
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <canvas id="myCanvas" height="200" width="600" style={{ border: "solid 1px black"}}>Your browser does not support canvas.</canvas> */}
        <div
          className="modal modal_customs export_modal fade"
          id="newModal"
          tabindex="-1"
          aria-labelledby="newModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="newModalLabel">
                  What's new
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="export_chart_inner newwhats_inner">
                  <div className="newwhatdiv">
                    <div className="newwhatdivleft">
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book.
                      </p>
                    </div>
                    <div className="newwhatdivright">Feb 3</div>
                  </div>
                  <div className="newwhatdiv">
                    <div className="newwhatdivleft">
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book.
                      </p>
                    </div>
                    <div className="newwhatdivright">Feb 3</div>
                  </div>
                  <div className="newwhatdiv">
                    <div className="newwhatdivleft">
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book.
                      </p>
                    </div>
                    <div className="newwhatdivright">Feb 3</div>
                  </div>
                  <div className="newwhatdiv">
                    <div className="newwhatdivleft">
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book.
                      </p>
                    </div>
                    <div className="newwhatdivright">Feb 3</div>
                  </div>
                  <div className="newwhatdiv">
                    <div className="newwhatdivleft">
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book.
                      </p>
                    </div>
                    <div className="newwhatdivright">Feb 3</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal modal_customs export_modal fade"
          id="exportModal"
          tabindex="-1"
          aria-labelledby="exportModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="DJIAModalLabel">
                  Export chart data
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="export_chart_inner">
                  <p>
                    All information from the selected chart, including the
                    symbol & indicators will be saved to a CSV file.
                  </p>
                  <div className="chart_e_div">
                    <span>Chart</span>
                    <select className="template_select">
                      <option>MYX_DLY:DJIA-H1, ID</option>
                    </select>
                  </div>
                  <div className="chart_e_div">
                    <span>Time format (UTC)</span>
                    <select className="template_select">
                      <option>UNIX timestamp</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="modal-footer modal_footer_custom">
                <div className="btns_outer_footer">
                  <button type="button" class="cancel_btn">
                    Cancel
                  </button>
                  <button type="button" class="okbtn">
                    Export
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer>
          <Footer />
        </footer>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  stockValue: state.cardItems,
});

const mapDispatchToProps = (dispatch) => ({
  createAlertNotify: (
    token,
    cond_name,
    cond_value,
    cond_check,
    option,
    time_stamp,
    action_method,
    alert_name,
    alert_msg,
    symbol,
    hook_url
  ) =>
    dispatch(
      createAlertNotify(
        token,
        cond_name,
        cond_value,
        cond_check,
        option,
        time_stamp,
        action_method,
        alert_name,
        alert_msg,
        symbol,
        hook_url
      )
    ),
});
export default connect(mapStateToProps, mapDispatchToProps)(ChartPage);