import React, { Component } from "react";
import Footer from "../layout/footer/Footer.component";
import Header from "../layout/header/Header.component";
import './Expertadvisor.component.css'
import Book from '../../../assests/book.jpg';
import Meta from '../../../assests/meta.png';
import Book1 from '../../../assests/book1.jpg';
import Video from '../../../assests/video.png';
import Cart from '../../../assests/cart.png';
import Bleak from '../../../assests/bleak.png';
import Explore from '../../../assests/explore.jpg';


import { Link } from "react-router-dom";
import axios from "axios";
import constants from "../../../constants/constants";

let imgPath="../../../assests/"
export default class ExpertAdvisorResult extends Component {

	constructor(props)
	{
		super(props);
		var s = window.location.href;
        var split = s.split("expert-advisor-result/");
        var eaid = split[1];
		this.state={
			expert:{}
		};
		this.getExperts(eaid);
	}
	getExperts(eaid)
	{
	  let accessToekn = localStorage.getItem('accessToken');
	  var authData = 'Token ' + accessToekn;
	  axios.get(constants.API_URL+"/advisor/"+eaid+"/",{
		headers:{
		  "Content-Type": "application/json",
		  Authorization: authData
		}
	  })
	  .then((response)=>{
		if(response.status==200 )
		{
		  this.setState({
			expert:response.data
		  });
		}
  
	  }).catch((error)=>{
		console.log("error", error);
	  });
	}
	buyExpert(expert_id)
	{
		let accessToekn = localStorage.getItem('accessToken');
	  var authData = 'Token ' + accessToekn;
	  var formData=new FormData();
	  formData.append("advisor",expert_id);
	  formData.append("payment_id","pidXYZ123");
	  formData.append("status","paid");
	  formData.append("mode","cod");

	  axios.post(constants.API_URL+"/advisor-transaction/",formData,{
		headers:{
		  "Content-Type": "application/json",
		  Authorization: authData
		}
	  })
	  .then((response)=>{
		if(response.status==201 )
		{
		  document.location.href="/expert-advisor-buy/"+expert_id;
		}
  
	  }).catch((error)=>{
		console.log("error", error);
	  });
	}
    render() {

        return ( 
            <div>
          <header>
            <div className="custom_container">
              <div className="row">
                <div className="col-md-12">
                  <Header />
                </div>
              </div>
            </div>
          </header>
<section className="expert_ad">
	<div className="custom_container">
		<div className="row">
			<div className="col-md-6">
				<div className="img_outer">
				<img src={this.state.expert.image} />
				</div>
			</div>
			<div className="col-md-6">
				<div className="right56_outer">
					<h3>{this.state.expert.title}</h3>
					<p>{this.state.expert.description}</p>
					<div className="starr_outer">
					{
						(()=>{
							for(var i =0;i<this.state.expert.rating;i++)
							{
								return (
									<i class="fa fa-star" aria-hidden="true"></i>
								);
							}
						})
					}
					</div>
					<div className="btn_ut">
						<button className="btn" type="button" onClick={()=>this.buyExpert(this.state.expert.id)}>Buy : {this.state.expert.price} {this.state.expert.currency_pair}</button>
					</div>
					</div>
				</div>
			</div>
		</div>

</section>
<section className="middl_outer12">
<div className="custom_container">
	<div className="heading_iou7">
		<h3>Refund Policy</h3>
		<p>{this.state.expert.refund_policy} </p>
	</div>

		<div className="heading_iou7">
		<h3>License</h3>
		<p>{this.state.expert.license_no} </p>
	</div>

		<div className="heading_iou7">
		<h3>Support</h3>
		<p>{this.state.expert.support} </p>
	</div>
	<div className="row">
		<div className="col-md-6">
				<div className="heading_iou7">
		<h3>Compatible Brokers</h3>
		<p>{this.state.expert.compatible_broker} </p>
	</div>

		</div>
		<div className="col-md-6">
				<div className="heading_iou7">
		<h3>Minimum Deposit</h3>
		<p>{this.state.expert.minimum_deposit}</p>
	</div>

		</div>
		</div>
		<div className="row">
		<div className="col-md-12">
				<div className="heading_iou7">
		<h3>Selected Type</h3>
		<p>{ this.state.expert.category==1?"MT4":"MT5" } Compatibility</p>
	</div>

		</div>
		<div className="row">
		<div className="col-md-6">
				<div className="heading_iou7">
		<h3>Supported Currency Pairs</h3>
		<p>{this.state.expert.currency_pair}</p>
	</div>

		</div>
		<div className="col-md-6">
				<div className="heading_iou7">
		<h3>Chart Timeframe</h3>
		<p>{this.state.expert.chart_time_frame}</p>
	</div>

		</div>
		</div>
		{/* <div className="row">
		<div className="col-md-6">
				<div className="heading_iou7">
					<img src={Explore} />
	</div>

		</div>
		<div className="col-md-6">
				<div className="heading_iou7">
				<img src={Explore} />
	</div>

		</div>
	

		</div> */}
	</div>

</div>

</section>
          
      
          <footer>
            <Footer />       
          </footer>
            </div>
        )
    }
}
