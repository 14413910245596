import React,{Component} from "react";
import { Link } from "react-router-dom";
import { Input } from "reactstrap";
import Footer from "../layout/footer/Footer.component";
import Header from "../layout/header/Header.component";
import './community.component.css';
import io from 'socket.io-client';
import moment from "moment";
import { useEffect, useState } from "react";
import RecordRTC, { getSeekableBlob, MediaStreamRecorder } from "recordrtc";
import constants from "../../../constants/constants";
import axios from "axios";


const socket = io(constants.SOCKET_URL);
const videoElement = document.querySelector("video");
const peerConnections = {};
      const config = {
        iceServers: [
          { 
            "urls": "stun:stun.l.google.com:19302",
          },
          // { 
          //   "urls": "turn:TURN_IP?transport=tcp",
          //   "username": "TURN_USERNAME",
          //   "credential": "TURN_CREDENTIALS"
          // }
        ]
      };

     

const  CommunityPage =({})=> {


      var [author,setAuthor]=useState(' ');
      var [authorimage,setAuthorimage]=useState('');
      var [cover,setCover]=useState('');
      var [title,setTitle]=useState(' ');
      var [views,setViews]=useState('');
      var [short_description,setShortDescription]=useState('');
      var [long_description,setLongDescription]=useState('');
      var [status,setStatus]=useState(0);
      var [twitter,setTwitter]=useState('');
      var [youtube,setYoutube]=useState('');
      var [website,setWebsite]=useState('');
      var [likes,setLikes]=useState(' ');
      var [stream_date,setStreamDate]=useState(' ');
      var [startButton,setStartButton]=useState(0); // 0 means video can be started , 1 means uploading ,2 means uploading done 
      var [isPlaying,setIsPlaying]=useState(false); // 0 means video can be started , 1 means uploading ,2 means uploading done 

   const  loadMyStreams =(stream_id) =>{

            let accessToekn = localStorage.getItem('accessToken');
            var authData = 'Token ' + accessToekn;
          
            axios
              .get('https://stock-trading-backend.itechnolabs.tech/stream/'+stream_id+'/', {
              headers: {
                "Content-Type": "application/json",
                Authorization: authData
              }
              })
              .then((response) => {
               setAuthor(response.data.user.first_name);
               setAuthorimage(response.data.user.image);
               setCover(response.data.cover);
               setTitle(response.data.title);
               setViews(response.data.views);
               setTwitter(response.data.twitter);
               setYoutube(response.data.youtube);
               setWebsite(response.data.website);
               setLikes(response.data.likes);
               setStatus(response.data.status);
               setLongDescription(response.data.long_description);
               setShortDescription(response.data.short_description);
               setStreamDate(response.data.stream_date);
               
               if(response.data.status==1)
               {
                  setStartButton(2);
                  document.querySelector('video').srcObject = null;
                  document.querySelector('video').src = response.data.video;
                  document.querySelector('video').muted = false;

               }
               else
               {
                  setStartButton(0);
               }
              }).catch((error) => {
              });

              
            }

      const queryParams=new URLSearchParams(window.location.search);
      const stream_id=queryParams.get("stream");
      if(stream_id=='' || stream_id=='undefined' ||  stream_id==null)
      {
        alert("Invalid stream");
        document.location.href= `${window.location.origin}/my-streams`;;
      }
      else
      {
        loadMyStreams(stream_id);
  
      }
      
     



    









   var [isCameraPlaying,setCameraPlaying]=useState(false);
   var  [isScreenSharing,setScreenSharing]=useState(false); 
   socket.on("answer", (id, description) => {
      try
      {
         peerConnections[id].setRemoteDescription(description);
      }
      catch(error)
      {

      }
    });
    
    socket.on("watcher", id => {
       console.log("iam in watcher");
      const peerConnection = new RTCPeerConnection(config);
      peerConnections[id] = peerConnection;
    console.log(videoElement);
      let stream =  document.querySelector("video").srcObject;
      stream.getTracks().forEach(track => peerConnection.addTrack(track, stream));
    
      peerConnection.onicecandidate = event => {
        if (event.candidate) {
          socket.emit("candidate", id, event.candidate);
        }
      };
    
         peerConnection
         .createOffer()
         .then(sdp => peerConnection.setLocalDescription(sdp))
         .then(() => {
            socket.emit("offer", id, peerConnection.localDescription);
         });
    });
    
    socket.on("candidate", (id, candidate) => {
      peerConnections[id].addIceCandidate(new RTCIceCandidate(candidate));
    });
      socket.on("stopped", (id) => {
         socket.emit("stopped", id);
    });
    
    socket.on("disconnectPeer", id => {
      // peerConnections[id].close();
      delete peerConnections[id];
    });
    
    window.onunload = window.onbeforeunload = () => {
      socket.close();
    };


 
const captureBtnScreen=()=>{
   captureScreen(function(screen) {

      keepStreamActive(screen);
      captureCamera(function(camera) {
        setStatus(1);
          keepStreamActive(camera);
   
          screen.width = window.screen.width;
          screen.height = window.screen.height;
          screen.fullcanvas = true;
          
          camera.width = 320;
          camera.height = 240;
          camera.top = screen.height - camera.height;
          camera.left = screen.width - camera.width;
          
          var recorder = RecordRTC([screen, camera], {
              type: 'video',
              mimeType: 'video/mp4',
              recorderTpe:MediaStreamRecorder,
              previewStream: function(s) {
                  document.querySelector('video').muted = true;
                  document.querySelector('video').srcObject = s;
                  socket.emit("broadcaster");
              }
          });
   
          recorder.startRecording();
   
          window.stopCallback = function() {
              window.stopCallback = null;
   
              recorder.stopRecording(function() {
               setStartButton(1);
                  var blob = recorder.getBlob();
                  document.querySelector('video').srcObject = null;
                  document.querySelector('video').src = URL.createObjectURL(blob);
                  document.querySelector('video').muted = false;
                  
                  getSeekableBlob(recorder.getBlob(), function(seekableBlob) {
                     var file = new File([seekableBlob],new Date());
                  
    const queryParams=new URLSearchParams(window.location.search);
    const stream_id=queryParams.get("stream");
                  let accessToken = localStorage.getItem('accessToken');
                  var authData = 'Token ' + accessToken;
                  
                 var data=new FormData();
                 data.append('title',title);
                 data.append('short_description',short_description);
                 data.append('long_description',long_description);
                 data.append('stream_date',stream_date);
                 data.append('video',file);
                 data.append('status',1);
                
                  axios
                    .put('https://stock-trading-backend.itechnolabs.tech/stream/'+stream_id+'/',data,
                     {
                      headers: {
                        'Content-Type': 'multipart/form-data',
                        "Authorization": authData
                      }
                    })
                    .then((response) => {
              
                      if(response.status==200  )
                      {
                        alert("Stream uploaded to the server successfully.");
                        document.querySelector('video').srcObject = null;
                        document.querySelector('video').src = response.data.video;
                        document.querySelector('video').muted = false;
                        socket.emit("stopped", socket.id);
                        // document.location.reload();
                      } 
                     
              
                    }).catch((error) => {
                      Object.entries(error.response.data).map((key,value) => {
                        //document.getElementsByName(key[0]).appendChild('<span style="color:red">'+key[1]+'</span>');
                       
                    })
                    });
                 });
                  
                  

                  [screen, camera].forEach(function(stream) {
                      stream.getTracks().forEach(function(track) {
                          track.stop();
                      });
                  });
              });
          };
   
        //  window.timeout = setTimeout(window.stopCallback, 10 * 1000);
      });
   });
  
  
   if(!navigator.getDisplayMedia && !navigator.mediaDevices.getDisplayMedia) {
      var error = 'Your browser does NOT supports getDisplayMedia API.';
      document.querySelector('h1').innerHTML = error;
  
      document.querySelector('video').style.display = 'none';
      document.getElementById('btn-start-recording').style.display = 'none';
      document.getElementById('btn-stop-recording').style.display = 'none';
      throw new Error(error);
  }
}
function stopBtnScreen()
{
   if(window.confirm('Are you sure to stop the streaming ?'))
   {
      setTimeout(window.stopCallback, 1 * 1000);
   }
}

function invokeGetDisplayMedia(success, error) {
   var displaymediastreamconstraints = {
       video: {
           displaySurface: 'monitor', // monitor, window, application, browser
           logicalSurface: true,
           cursor: 'always' // never, always, motion
       }
   };

   // above constraints are NOT supported YET
   // that's why overridnig them
   displaymediastreamconstraints = {
       video: true
   };

   if(navigator.mediaDevices.getDisplayMedia) {
       navigator.mediaDevices.getDisplayMedia(displaymediastreamconstraints).then(success).catch(error);
   }
   else {
       navigator.getDisplayMedia(displaymediastreamconstraints).then(success).catch(error);
   }
}
   function keepStreamActive(stream) {
      var video = document.createElement('video');
      video.muted = true;
      video.srcObject = stream;
      video.style.display = 'none';
      (document.body || document.documentElement).appendChild(video);
  }
  function captureScreen(callback) {
   invokeGetDisplayMedia(function(screen) {
       addStreamStopListener(screen, function() {
           if(window.stopCallback) {
               window.stopCallback();
               setScreenSharing(false);
           }

       });
       callback(screen);
       setScreenSharing(true);
   }, function(error) {
       console.error(error);
       setScreenSharing(false);
       alert('Unable to capture your screen. Please check console logs.\n' + error);
   });
}

function captureCamera(cb) {
   navigator.mediaDevices.getUserMedia({audio: true, video: true}).then(cb);
   setCameraPlaying(true);
}
   


function addStreamStopListener(stream, callback) {
   stream.addEventListener('ended', function() {
       callback();
       callback = function() {};
   }, false);
   stream.addEventListener('inactive', function() {
       callback();
       callback = function() {};
   }, false);
   stream.getTracks().forEach(function(track) {
       track.addEventListener('ended', function() {
           callback();
           callback = function() {};
       }, false);
       track.addEventListener('inactive', function() {
           callback();
           callback = function() {};
       }, false);
   });
}
 

        return( 
           <>
            <header>
            <div className="custom_container">
                  <div className="row">
                     <div className="col-md-12">
                        <Header />
                     </div>
                  </div>
               </div>
               </header>
               <section className="banner_outer news_banner">
                  <div className="overlay"></div>
                  <div className="custom_container">
                     <div className="row" >
                        <div className="col-md-8 offset-md-2 col-sm-12 first_div text-center">
                           <div class="banner_content">
                              <h2>Start Stream</h2>
                              <hr class="banner_hr news_hr" />
                              <h3>
                                 <ul className="news_ul">
                                    <li>
                                       <Link to={"/home"} >
                                       Home</Link>
                                    </li>
                                    <li><i class="fal fa-angle-right"></i></li>
                                    <li>
                                       <Link to={"/community"} className="active">
                                       Start Stream</Link>
                                    </li>
                                 </ul>
                              </h3>
                           </div>
                        </div>
                     </div>
                  </div>
               </section>
               <section>
                  <div className="custom_container">
                     <div className="row" >
                        <div className="col-md-8 offset-md-2 col-sm-12 second_div text-center">
                           <p className="p_text newS_p">The markets never sleep. As the sun sets in New York, 
                            Connecty with using the Live chat and the video support. 
                           </p>
                        </div>
                     </div>
                  </div>
               </section>
               <section className="cummiti">
                                  <div className="custom_container">
                     <div className="row">
                     <div className="col-md-4">
                        <div className="cumit_outer chatBox chat67">
                  {(() => {
        if (status==0 && !isScreenSharing&&!isCameraPlaying) {
          return (
            (
               <img src={cover}  style={{width:"100%"}} />
            )
          )
        } 
        else
        {
           return(
            <video controls autoPlay playsInline style={{width:'100%'}}></video>
           )
        }
      })()}
                             
                             <h4>{title}</h4>
                             <p>{long_description}</p>
                            
                            <div className="start_str5">
                     {(() => {
        if (startButton==0) {
          return (
            (!isScreenSharing&&!isCameraPlaying? (
                    <button className="ster" onClick={captureBtnScreen}>Start Streaming </button>
             ):(
                      <button className="ster" onClick={stopBtnScreen}>Stop  Streaming </button>
             ))
          )
        } 
        else if(startButton==1)
        {
          return(
            <div className="button_ouy67"><p>Please wait while we upload your stream on server.</p> </div>
          )
        }
        else
        {
           return(
            <div className="button_ouy671 alert alert-success"> Stream Completed Successfully !!</div>
           )
        }
      })()}
                            
                            </div>
                        </div>
                     </div>
                        
                     </div>
                  </div>
               </section>
               </>
        )
}
export default CommunityPage;
