import React, { Component } from "react";
import Footer from "../layout/footer/Footer.component";
import Header from "../layout/header/Header.component";
import './Contest.component.css'
import heroImg from '../../../assests/hero-bg.jpg'
import closeUp from '../../../assests/close_up.jpg'
import { Link } from "react-router-dom";

let imgPath="../../../assests/"
export default class ContestPage extends Component {
    render() {
        return (
            <div>
          <header>
            <div className="custom_container">
              <div className="row">
                <div className="col-md-12">
                  <Header />
                </div>
              </div>
            </div>
          </header>

          <section className="banner_outer news_banner">
            <div className="overlay"></div>
                <div className="custom_container">
                <div className="row" >
                    <div className="col-md-8 offset-md-2 col-sm-12 first_div text-center">
                      <div class="banner_content">
                        <h2>Contests</h2>
                         <hr class="banner_hr news_hr" /><h3>
                         <ul className="news_ul">
                           <li><Link to={"/home"} >Home</Link></li>
                           <li><i class="fal fa-angle-right"></i></li>
                           <li><Link to={"/contest"} className="active">Contests</Link></li>
                         </ul>
                        </h3>
                      </div>
                    </div>
                </div>
            </div>
          </section>
          <section>
            <div className="custom_container">
                <div className="row" >
                    <div className="col-md-8 offset-md-2 col-sm-12 second_div text-center">
                        <h3 className="title_main">
                        Forex Trading <b>Contests</b>
                        </h3>
                        <p className="p_text newS_p">Participate in exciting forex trading contests where thousands of dollars in prizes are paid monthly, 
                        sponsored by the world’s most prestigious forex brokers.</p>
                    </div>
                </div>
            </div>
          </section>

          <section className="technical_assistance">
             <div className="custom_container">
               <div className="row">
                   <div className="col-md-8 offset-md-2 col-sm-12">
                        <h3 className="title_main">
                        The next competition starts on <br />
                         02:30:55  18/Jan/2022
                        </h3>
                        <div className="sign_up_tagline text-center">
                        <h3><a><b>Don’t miss out!</b></a></h3>
                        </div>
                        <Link to={"/signup"}><button type="button" class="btn btn-dark signup_btn signup">Sign up</button></Link>
                   </div>
               </div>
             </div>
            </section>


          <section>
            <div className="custom_container">
                <div className="row" >
                    <div className="col-md-8 offset-md-2 col-sm-12 second_div text-center">
                        <h3 className="title_main">
                        Why should I be <br /> <b>interested?</b>
                        </h3>
                        <p className="p_text newS_p">Participating in demo forex trading competitions is the 
                        only risk-free way to profit from the forex market. There is no need to deposit money to begin. 
                        When the contest starts, you’ll be given access to a demo account loaded with funds. You get to put your skills to the test, and if you outperform all other participants, you’ll be the
                         winner of one of our lucrative prizes.</p>
                    </div>
                </div>
            </div>
          </section>

          <section className="technical_assistance">
             <div className="custom_container">
               <div className="row">
                   <div className="col-md-8 offset-md-2 col-sm-12">
                        <h3 className="title_main">
                        <b>Sign up</b> for the next <b>Contest</b>
                        </h3>
                        <p className="p_text">You can apply to participate in the upcoming competition sponsored by 
                        international forex broker FxPro. The objective is to grow your demo trading account balance from $10,000 to be 
                        larger than your opponents. 
                        The total prize money is $1,500, with $800 going to first place.</p>
                        <h3 className="title_main register_time">
                        The next competition starts on 18/Jan/2022 at midnight. You have until 02:30  18/Jan/2022 to register. 
                        </h3>
                        <Link to={"/signup"}><button type="button" class="btn btn-dark signup_btn signup">Register Now</button></Link>
                   </div>
               </div>
             </div>
            </section>

            <section>
                <div className="custom_container">
                       <div className="row align-items-center">
                           <div className="col-md-6">
                           <div className="img_part">
                           <img src={heroImg} /> 
                           </div>   
                           </div>
                           <div className="col-md-6">
                              <div className="description_part">
                               <div className="title_part">
                               <h3 className="title_main">How does it <b>work?</b></h3>
                               </div>
                               <div className="detail_part">
                                   <p className="p_text">The objective of every competition is to trade successfully and earn more profit than your competitors. After the competition ends, the trader at the top of the leader board is declared the winner. As we collaborate with many different brokers, each contest could have different rules. It’s a good idea to check the rules each time in case of any variations.</p>
                               </div>
                               </div>
                           </div>
                       </div>
                       <div className="row align-items-center mt-5">
                       <div className="col-md-6">
                           <div className="description_part left_desc">
                              <div className="title_part">
                              <h3 className="title_main">What are the <b>prizes?</b></h3>
                               </div>
                               <div className="detail_part">
                                   <p className="p_text">Every competition offers different prizes, and who wins the prizes may vary, depending on the sponsor. The largest prize we’ve given was $900! Sometimes its winner takes all, sometimes prizes are given to first, second and third place. </p>
                               </div>
                            </div>
                       </div>
                       <div className="col-md-6">
                        <div className="img_part">
                        <img src={closeUp} />
                        </div> 
                       </div>
                       </div>
                       <div className="row align-items-center">
                           <div className="col-md-6">
                           <div className="img_part">
                           <img src={heroImg} /> 
                           </div>   
                           </div>
                           <div className="col-md-6">
                              <div className="description_part">
                               <div className="title_part">
                               <h3 className="title_main">What are the <b>benefits?</b></h3>
                               </div>
                               <div className="detail_part">
                               <ul class="info_span">
                                 <li><span class="dot_span"></span><span class="privcay_content_span">Find out if you’re a natural-born trader. You might have a valuable talent.</span></li>
                                 <li><span class="dot_span"></span><span class="privcay_content_span">Be motivated to succeed without risking your own money.</span></li>
                                 <li><span class="dot_span"></span><span class="privcay_content_span">Boost your confidence as a trader if you’re experiencing a losing streak.</span></li>
                                 <li><span class="dot_span"></span><span class="privcay_content_span">Alter your mindset as a trader by detaching yourself from your account balance and think about the bigger picture.</span></li>
                               </ul>
                               </div>
                               </div>
                           </div>
                       </div>
                </div>
                </section>

        <section className="most_polupar">
            <div className="custom_container">
                <div className="row">
                    <div className="col-md-8 offset-md-2 col-sm-12 second_div text-center">
                        <h3 className="title_main">
                        Frequently asked <b>Questions</b>
                        </h3>
                    </div>
                </div>

                <div className="row">
                  <div className="col-md-8 offset-md-2 col-sm-12">                   
                    <div class="custom_accordian">
                      <div class="accordion" id="accordionExample">
                        <div class="card">
                          <div class="card-header" id="headingOne">
                            <h2 class="mb-0">
                              <button type="button" class="btn btn-link" data-toggle="collapse" data-target="#collapseOne"><span>Do I get to keep the profits I make in the contest?</span><i class="fa fa-angle-down"></i></button>
                            </h2>
                          </div>
                          <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                            <div class="card-body">
                              <p>No. As you’re trading with a demo account, any profit or loss in the trading account isn’t real. It is just a simulation. </p>
                            </div>
                          </div>
                        </div>
                        <div class="card">
                          <div class="card-header" id="headingTwo">
                            <h2 class="mb-0">
                              <button type="button" class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo"><span>Am I obligated to start trading if I am successful in the competition?</span><i class="fa fa-angle-down"></i></button>
                            </h2>
                          </div>
                          <div id="collapseTwo" class="collapse show" aria-labelledby="headingTwo" data-parent="#accordionExample">
                            <div class="card-body">
                              <p>Second Tab Content</p>
                            </div>
                          </div>
                        </div>
                        <div class="card">
                          <div class="card-header" id="headingThree">
                            <h2 class="mb-0">
                              <button type="button" class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree"><span>Am I obligated to start trading if I am successful in the competition?</span><i class="fa fa-angle-down"></i></button>
                            </h2>
                          </div>
                          <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                            <div class="card-body">
                              <p>Third Tab Content</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-8 offset-md-2 col-sm-12 second_div text-center">
                    <button type="button" class="btn btn-dark signup_btn">View All</button>
                  </div>
                </div>
            </div>
        </section>

      
          <footer>
            <Footer />       
          </footer>
            </div>
        )
    }
}
