import React, { Component } from "react";
import Footer from "../layout/footer/Footer.component";
import Header from "../layout/header/Header.component";
import "./live-stream.component.css";
import Book from "../../../assests/book.jpg";
import Book1 from "../../../assests/book1.jpg";
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import io from "socket.io-client";
import $ from 'jquery';
import './CreateStream.component.css'
import constants from "../../../constants/constants";
import ReactDatePicker from "react-datepicker";
import { DateRangePicker } from "react-date-range";
import moment from "moment";
let imgPath = "../../../assests/";


export default class CreateStream extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: "",
      coverpic: "",
      shortdescription: "",
      longdescription: "",
      streamdate: "",
      twitter: "",
      youtube: "",
      website: "",
      imageData:"",
      isUpdating:false,
      stream_id:0
    };
	this.submit.bind();
  this.state.isUpdating=false;
}
  

handleUserInput(e) {
  const name = e.target.name;
  const value = e.target.value;
  this.setState({ [name]: value });
  }
 
  componentDidMount(){
    this.state.isUpdating=false;
    const queryParams=new URLSearchParams(window.location.search);
    const stream_id=queryParams.get("stream");
    if(stream_id=='' || stream_id=='undefined' ||  stream_id==null)
    {
      this.setState({isUpdating:false,stream_id:0});
    }
    else
    {
      this.setState({isUpdating:true,stream_id:stream_id});
      this.loadMyStreams(stream_id);

    }
  }
	
	  
  loadMyStreams(stream_id) {

    let accessToekn = localStorage.getItem('accessToken');
    var authData = 'Token ' + accessToekn;
  
    axios
      .get('https://stock-trading-backend.itechnolabs.tech/stream/'+stream_id, {
      headers: {
        "Content-Type": "application/json",
        Authorization: authData
      }
      })
      .then((response) => {
      this.setState(
        {  
          title:response.data.title,
          shortdescription:response.data.short_description,
        longdescription:response.data.long_description,
        streamdate:response.data.stream_date,
        twitter:response.data.twitter,
        youtube:response.data.youtube,
        website:response.data.website,
       }
        );
        $("[ name='shortdescription']").html(response.data.short_description);
        $("[ name='longdescription']").html(response.data.short_description);
        $("#editImage").attr("src",response.data.cover);
      
       
      }).catch((error) => {
      });
    }

  imageHandleChange = (file) => { 
    if(file.length>0)
    {

    var fname = file[0].name;
    var re = /(\.jpg|\.jpeg|\.png)$/i;
    if (!re.exec(fname)) {
        alert("File extension not supported. You must use .png, .jpeg or .jpg");
        $('.cover').val('');
    }else{
     
      this.setState({imageData:file[0]})
    }
  }

} 


	submit(event){
    $("input").removeClass("invalid");
    $("texarea").removeClass("invalid");
    $(".inpuy7").find("p").html('');
		event.preventDefault();
		let accessToken = localStorage.getItem('accessToken');
    var authData = 'Token ' + accessToken;
    var input =document.querySelector('input[type="file"]');
   var data=new FormData();
   data.append('title',this.state.title);
   if(this.state.imageData)
   data.append('cover',this.state.imageData);
   data.append('short_description',this.state.shortdescription);
   data.append('long_description',this.state.longdescription);
   data.append('stream_date',this.state.streamdate);
   data.append('twitter',this.state.twitter);
   data.append('youtube',this.state.youtube);
   data.append('website',this.state.website);
   if(this.state.isUpdating)
   {
    axios
    .put(constants.API_URL+`/stream/`+this.state.stream_id+'/',data,
     {
      headers: {
        'Content-Type': 'multipart/form-data',
        "Authorization": authData
      }
    })
    .then((response) => {

      if(response.status==200)
      {
        alert("Stream updated successfully, Go to my streams to check");
        document.location.href="/my-streams";
      } 
      else
      {
       
      }

    }).catch((error) => {
      Object.entries(error.response.data).map((key,value) => {
        $("."+key[0]).addClass("invalid");
        $("."+key[0]).parent().find("p").html(key[1]);
       console.log(key[0]);
    })
    });
   }
   else
   {
    axios
      .post(`https://stock-trading-backend.itechnolabs.tech/stream/`,data,
       {
        headers: {
          'Content-Type': 'multipart/form-data',
          "Authorization": authData
        }
      })
      .then((response) => {

        if(response.status==201)
        {
          alert("Stream created successfully, Go to my streams to check");
          document.location.href="/my-streams";
        } 
        else
        {
         
        }

      }).catch((error) => {
        Object.entries(error.response.data).map((key,value) => {
          $("."+key[0]).addClass("invalid");
          $("."+key[0]).parent().find("p").html(key[1]);
         console.log(key[0]);
      })
      });
    }
	  };
    disablePastDt(current){
      var selectedDate = current.target.value;
      var selectedDate = new Date(selectedDate);
      var now = new Date();
      now.setHours(0,0,0,0);
      if (selectedDate < now) {
        alert("Invalid date , please select a valid future date");
      } else {
        this.setState({streamdate:current.target.value});
      }
     
    }
  render() {
    return (
      <div>
        <header>
          <div className="custom_container">
            <div className="row">
              <div className="col-md-12">
                <Header />
              </div>
            </div>
          </div>
        </header>

        <section className="banner_outer news_banner">
          <div className="overlay"></div>
          <div className="custom_container">
            <div className="row">
              <div className="col-md-8 offset-md-2 col-sm-12 first_div text-center">
                <div class="banner_content">
                  <h2> {this.state.isUpdating?"Update Stream":"Create Stream"}</h2>
                  <hr class="banner_hr news_hr" />
                  <h3>
                    <ul className="news_ul">
                      <li>
                        <Link to={"/home"}>Home</Link>
                      </li>
                      <li>
                        <i class="fal fa-angle-right"></i>
                      </li>
                      <li>
                        <Link to={"/outlook"} className="active">
                          {this.state.isUpdating?"Update Stream":"Create Stream"}
                        </Link>
                      </li>
                    </ul>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="form_outer">
          <div class="container">
            <div class="row">
              <div class="col-md-8 offset-md-2">
                <form onSubmit={this.submit.bind(this)}  class="custom_form5" encType="multipart/form-data">
                  <div class="inpuy7 ">
                    <label>Title*</label>
                    <input
                    className="title"
                      type="text"
                      name="title"
                      onChange={(event) => this.handleUserInput(event)}
                      value={this.state.title}
                    />
                    <p style={{color:"red"}}></p>
                  </div>
                  <div class="inpuy7 ">
                    <label>Cover Pic*</label>
                    <img src="" id="editImage" style={{display:this.state.isUpdating?'block':'none',width:'100px'}} />
                    <input
                    onChange={(e)=>this.imageHandleChange(e.target.files)}
                    className="cover"
                      type="file"
                      name="coverpic"
                     
                    />
                      <p style={{color:"red"}}></p>
                  </div>
                  <div class="inpuy7 ">
                    <label>short description*</label>
                    <textarea
                    className="short_description"
                      name="shortdescription"
                      onChange={(event) => this.handleUserInput(event)}
                    >{this.state.shortdescription}</textarea>
                    <p style={{color:"red"}}></p>
                  </div>
                  <div class="inpuy7  ">
                    <label>long description* </label>
                    <textarea
                    className="long_description"
                      name="longdescription"
                      onChange={(event) => this.handleUserInput(event)}
                    >
                      {this.state.longdescription}
                    </textarea>
                    <p style={{color:"red"}}></p>
                  </div>
                  <div class="form-group inpuy7 ">
                    <label>stream date*</label>
                    <div class="input-group date" id="datetimepicker1">
                   
                      <input
                        type="date"
                        className="stream_date"
                        onChange={(event) => this.disablePastDt(event)}
                        value={this.state.streamdate}
                        id="birthday"
                        name="streamdate"
                      />
                    </div>
                    <p style={{color:"red"}}></p>
                  </div>
                  <div class="inpuy7 ">
                    <label>twitter</label>
                    <input
                      type="text"
                      name="twitter"
                      className="twitter"
                      onChange={(event) => this.handleUserInput(event)}
                      value={this.state.twitter}
                    />
                     <p style={{color:"red"}}></p>
                  </div>
                  <div class="inpuy7 ">
                    <label>youtube</label>
                    <input
                      type="text"
                      name="youtube"
                      className="youtube"
                      onChange={(event) => this.handleUserInput(event)}
                      value={this.state.youtube}
                    />
                     <p style={{color:"red"}}></p>
                  </div>
                  <div class="inpuy7 ">
                    <label>website</label>
                    <input
                      type="text"
                      className="website"
                      name="website"
                      onChange={(event) => this.handleUserInput(event)}
                      value={this.state.website}
                    />
                     <p style={{color:"red"}}></p>
                  </div>
                  <div class="btn_create">
                    <button type="submit"  >{this.state.isUpdating?'Update Stream':'Create Stream'}</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>

        <footer>
          <Footer />
        </footer>
      </div>
    );
  }
}
