import React, { Component } from "react";
import Footer from "../layout/footer/Footer.component";
import Employeeheader from "../layout/header/Employeeheader.component";
import './Chatsingle.component.css'
import DP from '../../../assests/dp.jpg'
import analyticsImg from '../../../assests/advanced-analytics.jpg';
import axios from "axios";
import { Link } from "react-router-dom";
import io from "socket.io-client"; 
import {FormGroup, ControlLabel, FormControl , InputGroup} from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import Chatting from "../chat/Chatting.component.js";
import constants from "../../../constants/constants";
var globalSocket;
const socketUrl = constants.SOCKET_URL;
const nodeAppUrl = constants.NODE_URL;

let imgPath="../../../assests/"

export default class Employeepage extends Component {

  constructor(props) {
      super(props)
      this.state = {
          transcode: '',
          users:'',
          chat:'',
          messageText: "",
          conversation: [],
          converationsUserss: [],
          firstID:'',
          myID:'',
          previousChats:[]
      }
  }

  componentDidMount() {

    setTimeout(function() {

      let id = localStorage.getItem("userId");

      this.setState({
            myID : id
        })

      const config = {
          headers: {
              "Content-type": "application/json",
          }
      };

      /* get previous conversation */
      

      /* get previous conversation */

      

      let accessToekn = localStorage.getItem('accessToken');

      let foodlist = '';
      var authData = 'Token '+accessToekn;
      var chatts = '';

      axios
      .get(`https://stock-trading-backend.itechnolabs.tech/chat/my/history/`,
            {headers: {
               "Content-Type": "application/json",
               Authorization: authData
            }},
        )
      .then((response) => {


     //   console.log('response.data' + JSON.stringify(response.data.data[0].id));

        this.setState({
            firstID : response.data.data[0].id
        });
        
        axios
      .get(nodeAppUrl + "get-previous-convo", 
        {"params":{
                  userid1:  parseInt(this.state.myID),
                  userid2: this.state.firstID
              }},
        config)
      .then((response) => {
          this.setState({ previousChats: response.data })
      })
      .catch((error) => {
          console.log("error", error);
      });

          this.setState({
              converationsUserss: response.data.data
          })
          //this.state.converationsUserss = response.data;

          // response.data.chats.map( result => {

          //     chatts += '<li><div className="chat_li_inner"><div className="chat_li_center"><div className="message_t"><b>'+result.first_name+'</b></div></div></div></li>'
          // })

          this.setState({
              chat: chatts
          })
      })
      .catch((error) => {
          console.log("error", error);
      });


       this.setState({
        transcode: '1'
      })
   


}.bind(this), 5000);
    this.setState({
          transcode: '1'
      })
      this.initSocket()

     
  }

    onClickPerson = (e) => {

        localStorage.setItem('firstID', e.currentTarget.id);
        
        this.setState({ firstID: e.currentTarget.id });
          const config = {
              headers: {
                  "Content-type": "application/json",
              }
          };

        axios
      .get(nodeAppUrl + "get-previous-convo", 
        {"params":{
                  userid1:  parseInt(this.state.myID),
                  userid2: this.state.firstID
              }}

        , config)
      .then((response) => {

        this.setState({
            previousChats : response.data
          }, () => {
   console.log(this.state.previousChats)
 })

        //  this.setState({ previousChats: response.data })

          console.log('pp chats' + JSON.stringify(this.state.previousChats));
      })
      .catch((error) => {
          console.log("error", error);
      });
 this.initSocket()
    }

  initSocket = async () => {

         let socket = io.connect(socketUrl)
        globalSocket=socket;
        this.setState({ socket })
       
         // var socketStates = this.state.conversation;

         //console.log('socketStates ++ '+socketStates);

         var ids = localStorage.getItem("userId");

         //localStorage.setItem('conversation', this.state.conversation);


        socket.on('connect', (data ) => {

           // console.log('data' + this.state.myID);

            var ids = localStorage.getItem("userId")

            var updatedId = localStorage.getItem('firstID');

            socket.emit('updatesocket', { id: ids }, function(dataFromServer) {
                console.log(dataFromServer);
            });


            socket.on('getChat',(data) => {


              let person = {
                    from_user_id: data.from_user_id , 
                    message: data.message , 
                    name : data.name, 
                    to_user_id: data.to_user_id,
                    img : data.img
                };

                var convo = this.state.previousChats;

                convo.push(person);
                
                this.setState({ 
                    previousChats: convo 
                });

            })

            socket.on('update-message',(data) => { // create_debate fn will be in backend 
                
                // console.log('wtfffffffff');
                
                let person = {
                  from_user_id: data.from_user_id , 
                  message: data.message , 
                  name : data.name, 
                  to_user_id: data.to_user_id
                };

                //  this.setState({        
                //   conversation: person
                // })

                //console.log('dfdfdfd'+ this.state);

                
                // var arra_data = localStorage.getItem("conversation");
                // console.log('arra_data ' +  arra_data);
                // arra_data.push(person);
                // this.setState({ conversation: this.state.conversation });

            })
        }) 
  }

  onMessageInputChange = (e) => {

      if (e.key === "Enter") {
         
          globalSocket.emit('update-chat', { message: e.target.value , from_user_id: localStorage.getItem("userId") , to_user_id : this.state.firstID   }, function(dataFromServer) {
              console.log(dataFromServer);
          });

          let person = {
              from_user_id: localStorage.getItem("userId") , 
              message: e.target.value , 
              name : localStorage.getItem("name"), 
              to_user_id: this.state.firstID 
          };
          
          var convo = this.state.previousChats;
          convo.push(person);
          this.setState({ previousChats: convo });
      }
  }

    render() {
        var message_second = 'message_second'; 
        var msgClass1 = 'message_first';
        var msgClass2 = 'message_first message_second';
        const listItems = this.state.previousChats.map((item, index) => (
          
  <div key ={index} class= { ( parseInt(item.from_user_id) != parseInt(this.state.myID) ) ? 'message_first' :  'message_first message_second' }>

  <div className="message_first_left ">
                        <ul>
                          <li><span>{item.message} </span></li>
                        </ul>
                      </div>
                      { (parseInt(item.from_user_id) != parseInt(this.state.myID)) ? <img src={this.state.myImg} /> :  <img src={DP} /> }
                    
            </div>  
)) 
        return (

            <div>
          <header>
            <div className="custom_container">
              <div className="row">
                <div className="col-md-12">
                  <Employeeheader />
                </div>
              </div>
            </div>
          </header>
          
          <section className="single_chat_outer">
            <div className="custom_container">
              <div className="row chat_row">
                <div className="col-md-4 chat_four_col">
                  <div className="single_chat_left">
                    
                    <div className="chat_listing">
                      <ul className="chat_listing_ul">
                        
                      {this.state.converationsUserss.map(d => {
           return(
              <li id={d.id}  key={d.id} onClick={this.onClickPerson}>
              <div class="chat_li_inner">
      <div class="chat_li_left">
        <img src={d.image} />
        <span class="away"></span>
        </div>
      <div class="chat_li_center">
        <div class="message_t"><b>{d.name}</b></div>
      </div>
    </div>

              </li>
           )}
         )}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-md-8 chat_eight_col">
                  <div className="single_chat_right">
                    <div className="single_chat_upper">
                      <div className="signle_chat_title">Customer Care Executive</div>
                    </div>
                    <div className="message_right">
                  <div className="message_upper">
                    <div className="message_upper" data-myId={this.state.myID} data-otherId={this.state.firstID}>
                    {listItems}
                  </div>
                  </div>
                  
                </div>

                <div className="message_bottom">
                    <div className="text_message_outer">

                      <input 
                  type="text"
                  onKeyDown={this.onMessageInputChange}
                  placeholder="Type a message here (Limit 3000 characters)..."
                  ref="messageTextBox"
                  className="messageTextBox"
                  maxLength="3000"
                  autoFocus
                />
                      
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        
      
          <footer>
            <Footer />       
          </footer>
            </div>
        )
    }
}
