import React, { Component } from "react";
import "./LiveRates.component.css";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { connect } from "react-redux";
import {
  getStockValue,
  getCompareSymbol,
} from "../../../services/actions/actions";
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);

class CompareChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stockData: [],
      stockLoad: false,
      stockError: "",
      compareSymb: this.props.newSymbol.toString(),
    };
  }

  handleRemove(param) {
    
    this.props.removSymbol(param);
  }

  getResult(){
    console.log('a')
    this.props
    .getCompareSymbol(
      this.props.newSymbol,
      this.props.stock,
      this.props.time,
      this.props.mult,
      this.props.startd,
      this.props.endd
    )
    .then(() => {
      if (this.props.stockValue.error == null && this.props.stockValue.compStock!=null) {
        const links = this.props.stockValue.compStock;
        const stockArray = [];

        const newData = [];
        const colors = ["#000", "#ff0000", "rgb(10 64 217)", "rgb(4 84 28)"];
        
        links.map((item, i) => {
          if (i == 0) {
            item.results.map(({ o, h, l, c, v, t }) =>
              stockArray.push([t, o, h, l, c])
            );
            newData.push({
              type: this.props.graph,
              name: this.props.symbol + "(" + this.props.symbolname + ")",
              plotOptions: {
                candlestick: {
                  upColor: this.props.down_color_color,
              upLineColor:this.props.down_color_color,
              lineColor:this.props.up_color_color
                },
              },
              data: stockArray,
              tooltip: {
                valueDecimals: 2,
              },
            });
          } else {
            const stockFullArray = [];
            item.results.map(({ o, h, l, c, v, t }) =>
              stockFullArray.push([t, h])
            );

            newData.push({
              type: "line",
              name: this.props.newSymbol[i],
              color: colors[i],
              data: stockFullArray,
              tooltip: {
                valueDecimals: 2,
              },
            });
          }
        });
       
        console.log("new data us",newData);
        this.setState(
          {
            stockData: newData,
          },
          () => {
            this.setState({ stockLoad: true });
          }
        );
      } else {
        alert("No data found for selected symbol.");
        this.handleRemove(this.props.newSymbol.length-1);
        this.setState({
          stockError: this.props.stockValue.error,
        });
      }
    });
  }

  componentDidMount() {
    this.getResult()
    setInterval(() => {
        this.getResult()
      }, 300000);
  }

  render() {
    const { stockError, compareSymb, stockData } = this.state;
    let rangeSelected = 1;
    if (this.props.time == "minute" || this.props.time == "hour") {
      rangeSelected = 0;
    }
    const data = [
      [1645160400000, 142.71],
      [1645506000000, 140.71],
      [1645592400000, 132.71],
      [1645678800000, 138.71],
      [1645765200000, 140.21],
    ];

    Highcharts.theme = {
      // some styling
    };

    const options = {
      chart: {
        type: "candlestick",
        height: 1400,
        pointPadding:0.04,
              groupPadding:0
      },
      plotOptions: {
        candlestick: {
          color: this.props.up_color_color,
              upColor: this.props.down_color_color,
              upLineColor:this.props.down_color_color,
              lineColor:this.props.up_color_color
        },
      },
      title: {
        text: "Compared Stock (" + compareSymb + ")",
      },
      xAxis: {
        gridLineWidth: this.props.grid,
      },
      rangeSelector: {
        enabled:false,
        allButtonsEnabled: true,
        buttons: [
          {
            type: "hour",
            count: 24,
            text: "Hour",
            dataGrouping: {
              units: [["minute", [1]]],
            },
          },
          {
            type: "all",
            text: "All",
            dataGrouping: {
              units: [["day", [1]]],
            },
          },
        ],
        inputEnabled: false,
        buttonTheme: {
          width: 40,
        },
        selected: rangeSelected,
      },
      yAxis: {
        
        lineWidth: 2,
        opposite: false,
        minRange: 0.001,
        gridLineWidth: this.props.grid,
      },
      exporting: {
        enabled: true,
        buttons: {
          menuItems: [
            "viewFullscreen",
            "printChart",
            "separator",
            "downloadPNG",
            "downloadJPEG",
            "downloadPDF",
            "downloadSVG",
          ],
        },
      },

      series: stockData,
    };
    //Highcharts.setOptions(Highcharts.theme);
    const colors = ["#000", "#ff0000", "rgb(10 64 217)", "rgb(4 84 28)"];
    return (
      <div>
        <div className="row">
          {this.props.newSymbol &&
            this.props.newSymbol.map((i, k) => (
              <div className="col-md-1">
                <span style={{ color: colors[k] }}>
                  <b>{i}</b>
                </span>
                <i
                  role="button"
                  className="fa fa-trash text-danger ml-2"
                  onClick={() => this.handleRemove(k)}
                ></i>
              </div>
            ))}
        </div>
        {this.state.stockLoad ? (
          <HighchartsReact
            highcharts={Highcharts}
            constructorType={"stockChart"}
            options={options}
          />
        ) : (
          stockError
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  stockValue: state.cardItems,
});

const mapDispatchToProps = (dispatch) => ({
  getStockValue: (symbol, stock, time, mult, startd, endd) =>
    dispatch(getStockValue(symbol, stock, time, mult, startd, endd)),
  getCompareSymbol: (symbol, stock, time, mult, startd, endd) =>
    dispatch(getCompareSymbol(symbol, stock, time, mult, startd, endd)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CompareChart);
