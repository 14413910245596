import axios from "axios";
import constants from "../constants/constants";
require("dotenv").config();
//  const API_URL = process.env.REACT_API_URL;

const API_URL = constants.API_URL;

const login = (payload) => {
  const config = {
    headers: {
      "Content-type": "application/json",
    },
  };

  const body = JSON.stringify(payload);

  return axios.post(API_URL + "/auth/login/?role=normal", body, config);
};

const employeeLogin = (payload) => {
  const config = {
    headers: {
      "Content-type": "application/json",
    },
  };

  const body = JSON.stringify(payload);

  return axios.post(API_URL + "/auth/login/?role=employee", body, config);
};


const socialLogin = (payload) => {
  const config = {
    headers: {
      "Content-type": "application/json",
    },
  };

  const body = JSON.stringify(payload);

  return axios.post(API_URL + "/auth/social/", body, config);
};

const signup = (payload) => {
  const config = {
    headers: {
      "Content-type": "application/json",
    },
  };

  const body = JSON.stringify(payload);

  return axios.post(API_URL + "/auth/registration/", body, config);
};

const getUser = (accessToken, id) => {
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: "Token " + accessToken,
    },
  };

  return axios.get(API_URL + "/auth/userdetails/" + id+'/', config);
};

const updateUser = (payload, accessToken, id) => {
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: "Token " + accessToken,
    },
  };

  const body = payload;
  console.log("body", body);
  return axios.post(API_URL + "/auth/userdetailsupdate/" + id+'/', body, config);
};

const forgetPassword = (payload) => {
  const config = {
    headers: {
      "Content-type": "application/json",
    },
  };

  const body = JSON.stringify(payload);

  return axios.post(API_URL + "/auth/password_reset/", body, config);
};
const resetPassword = (payload) => {
  const config = {
    headers: {
      "Content-type": "application/json",
    },
  };

  const body = JSON.stringify(payload);

  return axios.post(API_URL + "/auth/password_reset/confirm/", body, config);
};
const changePassword = (payload, accessToken) => {
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: "Token " + accessToken,
    },
  };

  const body = JSON.stringify(payload);

  return axios.put(API_URL + "/auth/change-password/", body, config);
};

const logout = (payload, accessToken) => {
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: "Token " + accessToken,
    },
  };

  const body = JSON.stringify(payload);

  return axios.post(API_URL + "/auth/logout/", body, config);
};

export default {
  login,
  socialLogin,
  signup,
  getUser,
  updateUser,
  forgetPassword,
  resetPassword,
  changePassword,
  logout,
  employeeLogin
};
