import React, { Component } from "react";
import Footer from "../layout/footer/Footer.component";
import Header from "../layout/header/Header.component";
import './Expertadvisor.component.css'
import Buy from '../../../assests/buy.png';
import $ from 'jquery';

import { Link } from "react-router-dom";
import axios from "axios";
import constants from "../../../constants/constants";

let imgPath="../../../assests/"
export default class UploadFiles extends Component {

    constructor(props)
    {
        super(props);
        var s = window.location.href;
    var split = s.split("expert-advisor-upload/");
    var eaid = split[1];
    this.getExperts(eaid);

    this.state={
        eaid:eaid,
        expert:{},
        file:"",
        file_username:"",
        file_password:""
    }
    this.imageHandleChange.bind();
    }
    getExperts(eaid)
{
  let accessToekn = localStorage.getItem('accessToken');
  var authData = 'Token ' + accessToekn;
  axios.get(constants.API_URL+"/advisor/"+eaid+"/",{
    headers:{
      "Content-Type": "application/json",
      Authorization: authData
    }
  })
  .then((response)=>{
    if(response.status==200 )
    {
      this.setState({
        expert:response.data
      });
    }

  }).catch((error)=>{
    console.log("error", error);
  });
}
handleInput(e)
{
    this.setState({
        [e.name]:e.value
    });
}
updateFiles()
{
    if(this.state.file=="")
    {
        alert("Please select a file you want to upload.");
    }
    else if(this.state.file_username=="")
    {
        alert("Please enter username.");
    }
    else if(this.state.file_password=="")
    {
        alert("Please enter a password");
    }
    else
    {
        var formData=new FormData();
        formData.append("file",this.state.file);
        formData.append("file_username",this.state.file_username);
        formData.append("file_password",this.state.file_password);
        let accessToekn = localStorage.getItem('accessToken');
        var authData = 'Token ' + accessToekn;
        axios.put (constants.API_URL+"/advisor-transaction/"+this.state.eaid+"/",formData,{
            headers:{
            "Content-Type": "application/json",
            Authorization: authData
            }
        })
        .then((response)=>{
            if(response.status==200)
            {
                alert("Details updated successfulyl");
                document.location.href="/expert-advisor-orders-recieved";
            }

        }).catch((error)=>{
            console.log("error", error);
        });
    }
}
imageHandleChange = (file) => { 
    if(file.length>0)
    {

    var fname = file[0].name;
    var re = /(\.jpg|\.jpeg|\.png)$/i;
    if (false/*!re.exec(fname)*/) {
        alert("File extension not supported. You must use .png, .jpeg or .jpg");
        $('.image').val('');
    }else{
        this.setState({file:file[0]});
    }
  }

} 
    render() {

        return ( 
            <div>
        <header>
            <div className="custom_container">
            <div className="row">
                <div className="col-md-12">
                <Header />
                </div>
            </div>
            </div>
        </header>
<section className="expert_ad">
    <div className="custom_container">
        <div className="row" style={{justifyContent:"center"}}>
            <div className="col-12 text-left w-50">
                <div className="img_outer">
                <h4 className="text-center">Upload Files for {this.state.expert.title}</h4>
                    <div className="form-group">
                        <label>Select File</label>
                        <input onChange={(e)=>this.imageHandleChange(e.target.files)}  type="file" className="form-control" name="file" id="file" />
                    </div>
                    <div className="form-group">
                        <label>Enter Username</label>
                        <input type="text" onChange={(e)=>this.handleInput(e.target)} placeholder="File username" className="form-control" name="file_username" id="file_username" />
                    </div>
                    <div className="form-group">
                        <label>Enter Password</label>
                        <input type="text" onChange={(e)=>this.handleInput(e.target)} placeholder="File password" className="form-control" name="file_password" id="file_password" />
                    </div>
                    <button type="button" onClick={()=>this.updateFiles()}  className="btn btn-primary" style={{float:"right"}}>Update File</button>
                </div>
            </div>
            
            </div>
        </div>

</section>

        
    
        <footer>
            <Footer />       
        </footer>
            </div>
        )
    }
}
