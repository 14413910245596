import React, {Component} from "react";
import Footer from "../layout/footer/Footer.component";
import Header from "../layout/header/Header.component";
import heroImg from '../../../assests/hero-bg.jpg'
import closeUp from '../../../assests/close_up.jpg'
import analyticsImg from '../../../assests/advanced-analytics.jpg'
import accountImg from '../../../assests/account_statement.jpg'
import marketupdateImg from '../../../assests/market_update.jpg'
import knowledgeImg from '../../../assests/knowledge.jpg'
import learnImg from '../../../assests/value-stocks.jpg'
import { Link } from "react-router-dom";

export default class AboutUs extends Component{
    render(){
        return (
            <div>
                <header>
                <div className="custom_container">
                <div className="row">
                    <div className="col-md-12">
                        <Header />
                    </div>
                </div>
                </div>
                </header>
                <section className="banner_outer">
                    <div className="overlay"></div>
                <div className="custom_container">
                <div className="row" >
                    <div className="col-md-12 first_div text-center">
                        <div className="banner_content">
                        <h2>About Us</h2>
                        <hr className="banner_hr"></hr>
                        <h3>
                            <p>Your Guide, <b>Resource</b> & Partner  in</p>
                            <p>Forex Trading and <b>Stock Investment</b></p>                            
                        </h3>
                        </div>
                    </div>
                </div>
                </div>
                </section>
                <section>
                <div className="custom_container">
                <div className="row" >
                    <div className="col-md-8 offset-md-2 col-sm-12 second_div text-center">
                        <h3 className="title_main">
                            About <b>Finmax</b>
                        </h3>
                        <p className="p_text">Finmax Financial is a comprehensive web-based platform for foreign currency trading, stock market, bonds, securities, and shares investment working to 
                        assist both novice and professional investors to the best of our abilities. </p>
                        <button type="button" class="btn btn-dark signup_btn"><Link to={"/signup"} style={{textDecoration:"none" ,color: "inherit" }}>Sign up for Free</Link></button>
                    </div>
                </div>
                </div>
                </section>
                <section>
                <div className="custom_container">
                       <div className="row align-items-center">
                           <div className="col-md-6">
                           <div className="img_part">
                           <img src={heroImg} /> 
                           </div>   
                           </div>
                           <div className="col-md-6">
                              <div className="description_part">
                               <div className="title_part">
                               <h3 className="title_main">What <b>We Do</b></h3>
                               </div>
                               <div className="detail_part">
                                   <p className="p_text">Finmax Financial works as a global junction, offering plenty of free resources and tools to help you make 
                                   the most viable investment decisions in the stock and currency markets. </p>
                                   <ul className="we_do">
                                       <li>We produce and disseminate high-quality reports on daily changes in global ﬁnancial exchanges. </li>
                                       <li>We generate detailed technical, economic, and timely analyses and a closer view of live currency quotes. </li>
                                       <li>We also compute and analyze market trends with simplified and detailed explanations of economic, political, and technical scenarios that influence market changes.</li>
                                   </ul>
                               </div>
                               </div>
                           </div>
                       </div>
                       <div className="row align-items-center mt-5">
                       <div className="col-md-6">
                           <div className="description_part left_desc">
                              <div className="title_part">
                              <h3 className="title_main">What <b>We Offer</b></h3>
                               </div>
                               <div className="detail_part">
                                   <p className="p_text">Finmax Financial is a hub of Forex trading tools where we 
                                   offer our users plenty of valuable resources, data, information, real-time exchange rates, trend analyses, 
                                   global currency market news, community networking with professionals, and a technological solution in the shape of 
                                   customizable Signals Service, available as both a free tool for more innovative investment moves and a premium service where 
                                   you can increase your chances of much higher profits. </p>
                               </div>
                            </div>
                       </div>
                       <div className="col-md-6">
                        <div className="img_part">
                        <img src={closeUp} />
                        </div> 
                       </div>
                       </div>
                       <div className="row align-items-center">
                           <div className="col-md-6">
                           <div className="img_part">
                           <img src={heroImg} /> 
                           </div>   
                           </div>
                           <div className="col-md-6">
                              <div className="description_part">
                               <div className="title_part">
                               <h3 className="title_main">How We <b>Help</b></h3>
                               </div>
                               <div className="detail_part">
                                   <p className="p_text">Being a global market, Forex trading is always up and running. With Finmax, you have a partner who can stay up to date and gather information. At the same time, you rest to catch-up in the ever-changing realm of currency trading and stocks, helping you make more informed and conscious 
                                   investment decisions utilizing the immense power of knowledge.</p>
                               </div>
                               </div>
                           </div>
                       </div>
                </div>
                </section>
            <section className="technical_assistance">
             <div className="custom_container">
               <div className="row">
                   <div className="col-md-8 offset-md-2 col-sm-12">
                        <h3 className="title_main">
                        <b>We Are</b> Always There for<br></br>
                            <b>Technical Assistance</b>
                        </h3>
                        <p className="p_text">For technical assistance and trading operations, our technical team might require read-only access to your trading account. Our experts can assist you in making viable financial decisions.</p>
                        <div className="sign_up_tagline text-center">
                        <h3><a>Start Trading NOW!</a></h3>
                        </div>
                        <Link to={"/signup"}><button type="button" class="btn btn-dark signup_btn signup">Sign up</button></Link>
                   </div>
               </div>
             </div>
            </section>
            <footer>
                   <Footer />       
            </footer>
            </div>
        )
    }
}