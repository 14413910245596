import React, { Component } from "react";
import 'bootstrap';
import "./LiveRates.component.css";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { connect } from "react-redux";
import { getStockValue } from "../../../services/actions/actions";
import * as $ from 'jquery';
import { SketchPicker, AlphaPicker , ChromePicker  } from 'react-color';
import reactCSS from 'reactcss';
import more from "highcharts/highcharts-more";  
import draggable from "highcharts/modules/draggable-points";  
import CustomEvents from "highcharts-custom-events";
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);
require("highcharts/modules/draggable-points")(Highcharts);
CustomEvents(Highcharts); 
class LineChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
          stockData: [],
          allData:[], 
          textcolor:"#FFFFFF",
          stockLoad: false,
          stockError: "",
          backgroundColor:"#FFFFFF",
          lineColor:"#000000",
          hignlinevalue:"",
          lowerlinevalue:"",
          showModal:false,
          background: '#fff',
          displayColorPicker: false,
          fontSize:2,
          seriesData:[],
          initialTime: Math.floor(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000) /1000),
          seriArr:[],
          lock:true,  
          dayArray:[],
          neUpdateOption:{},
        displayBackgroundColorPicker: false,  
        displayBorderColorPicker: false,
          color: {
            r: '241',
            g: '112',
            b: '19',
            a: '1',
          },
          lock:true,
          showalert:false,
          icons: [
            "fab fa-500px",
            "fab fa-accessible-icon",
            "fab fa-accusoft",
            "fas fa-address-book", "far fa-address-book",
            "fas fa-address-card", "far fa-address-card",
            "fas fa-adjust",
            "fab fa-adn",
            "fab fa-adversal",
            "fab fa-affiliatetheme",
            "fab fa-algolia",
            "fas fa-align-center",
            "fas fa-align-justify",
            "fas fa-align-left",
            "fas fa-align-right",
            "fab fa-amazon",
            "fas fa-ambulance",
            "fas fa-american-sign-language-interpreting",
            "fab fa-amilia",
            "fas fa-anchor",
            "fab fa-android",
            "fab fa-angellist",
            "fas fa-angle-double-down",
            "fas fa-angle-double-left",
            "fas fa-angle-double-right",
            "fas fa-angle-double-up",
            "fas fa-angle-down",
            "fas fa-angle-left",
            "fas fa-angle-right",
            "fas fa-angle-up",
            "fab fa-angrycreative",
            "fab fa-angular",
            "fab fa-app-store",
            "fab fa-app-store-ios",
            "fab fa-apper",
            "fab fa-apple",
            "fab fa-apple-pay",
            "fas fa-archive",
            "fas fa-arrow-alt-circle-down", "far fa-arrow-alt-circle-down",
            "fas fa-arrow-alt-circle-left", "far fa-arrow-alt-circle-left",
            "fas fa-arrow-alt-circle-right", "far fa-arrow-alt-circle-right",
            "fas fa-arrow-alt-circle-up", "far fa-arrow-alt-circle-up",
            "fas fa-arrow-circle-down",
            "fas fa-arrow-circle-left",
            "fas fa-arrow-circle-right",
            "fas fa-arrow-circle-up",
            "fas fa-arrow-down",
            "fas fa-arrow-left",
            "fas fa-arrow-right",
            "fas fa-arrow-up",
            "fas fa-arrows-alt",
            "fas fa-arrows-alt-h",
            "fas fa-arrows-alt-v",
            "fas fa-assistive-listening-systems",
            "fas fa-asterisk",
            "fab fa-asymmetrik",
            "fas fa-at",
            "fab fa-audible",
            "fas fa-audio-description",
            "fab fa-autoprefixer",
            "fab fa-avianex",
            "fab fa-aviato",
            "fab fa-aws",
            "fas fa-backward",
            "fas fa-balance-scale",
            "fas fa-ban",
            "fab fa-bandcamp",
            "fas fa-barcode",
            "fas fa-bars",
            "fas fa-bath",
            "fas fa-battery-empty",
            "fas fa-battery-full",
            "fas fa-battery-half",
            "fas fa-battery-quarter",
            "fas fa-battery-three-quarters",
            "fas fa-bed",
            "fas fa-beer",
            "fab fa-behance",
            "fab fa-behance-square",
            "fas fa-bell", "far fa-bell",
            "fas fa-bell-slash", "far fa-bell-slash",
            "fas fa-bicycle",
            "fab fa-bimobject",
            "fas fa-binoculars",
            "fas fa-birthday-cake",
            "fab fa-bitbucket",
            "fab fa-bitcoin",
            "fab fa-bity",
            "fab fa-black-tie",
            "fab fa-blackberry",
            "fas fa-blind",
            "fab fa-blogger",
            "fab fa-blogger-b",
            "fab fa-bluetooth",
            "fab fa-bluetooth-b",
            "fas fa-bold",
            "fas fa-bolt",
            "fas fa-bomb",
            "fas fa-book",
            "fas fa-bookmark", "far fa-bookmark",
            "fas fa-braille",
            "fas fa-briefcase",
            "fab fa-btc",
            "fas fa-bug",
            "fas fa-building", "far fa-building",
            "fas fa-bullhorn",
            "fas fa-bullseye",
            "fab fa-buromobelexperte",
            "fas fa-bus",
            "fab fa-buysellads",
            "fas fa-calculator",
            "fas fa-calendar", "far fa-calendar",
            "fas fa-calendar-alt", "far fa-calendar-alt",
            "fas fa-calendar-check", "far fa-calendar-check",
            "fas fa-calendar-minus", "far fa-calendar-minus",
            "fas fa-calendar-plus", "far fa-calendar-plus",
            "fas fa-calendar-times", "far fa-calendar-times",
            "fas fa-camera",
            "fas fa-camera-retro",
            "fas fa-car",
            "fas fa-caret-down",
            "fas fa-caret-left",
            "fas fa-caret-right",
            "fas fa-caret-square-down", "far fa-caret-square-down",
            "fas fa-caret-square-left", "far fa-caret-square-left",
            "fas fa-caret-square-right", "far fa-caret-square-right",
            "fas fa-caret-square-up", "far fa-caret-square-up",
            "fas fa-caret-up",
            "fas fa-cart-arrow-down",
            "fas fa-cart-plus",
            "fab fa-cc-amex",
            "fab fa-cc-apple-pay",
            "fab fa-cc-diners-club",
            "fab fa-cc-discover",
            "fab fa-cc-jcb",
            "fab fa-cc-mastercard",
            "fab fa-cc-paypal",
            "fab fa-cc-stripe",
            "fab fa-cc-visa",
            "fab fa-centercode",
            "fas fa-certificate",
            "fas fa-chart-area",
            "fas fa-chart-bar", "far fa-chart-bar",
            "fas fa-chart-line",
            "fas fa-chart-pie",
            "fas fa-check",
            "fas fa-check-circle", "far fa-check-circle",
            "fas fa-check-square", "far fa-check-square",
            "fas fa-chevron-circle-down",
            "fas fa-chevron-circle-left",
            "fas fa-chevron-circle-right",
            "fas fa-chevron-circle-up",
            "fas fa-chevron-down",
            "fas fa-chevron-left",
            "fas fa-chevron-right",
            "fas fa-chevron-up",
            "fas fa-child",
            "fab fa-chrome",
            "fas fa-circle", "far fa-circle",
            "fas fa-circle-notch",
            "fas fa-clipboard", "far fa-clipboard",
            "fas fa-clock", "far fa-clock",
            "fas fa-clone", "far fa-clone",
            "fas fa-closed-captioning", "far fa-closed-captioning",
            "fas fa-cloud",
            "fas fa-cloud-download-alt",
            "fas fa-cloud-upload-alt",
            "fab fa-cloudscale",
            "fab fa-cloudsmith",
            "fab fa-cloudversify",
            "fas fa-code",
            "fas fa-code-branch",
            "fab fa-codepen",
            "fab fa-codiepie",
            "fas fa-coffee",
            "fas fa-cog",
            "fas fa-cogs",
            "fas fa-columns",
            "fas fa-comment", "far fa-comment",
            "fas fa-comment-alt", "far fa-comment-alt",
            "fas fa-comments", "far fa-comments",
            "fas fa-compass", "far fa-compass",
            "fas fa-compress",
            "fab fa-connectdevelop",
            "fab fa-contao",
            "fas fa-copy", "far fa-copy",
            "fas fa-copyright", "far fa-copyright",
            "fab fa-cpanel",
            "fab fa-creative-commons",
            "fas fa-credit-card", "far fa-credit-card",
            "fas fa-crop",
            "fas fa-crosshairs",
            "fab fa-css3",
            "fab fa-css3-alt",
            "fas fa-cube",
            "fas fa-cubes",
            "fas fa-cut",
            "fab fa-cuttlefish",
            "fab fa-d-and-d",
            "fab fa-dashcube",
            "fas fa-database",
            "fas fa-deaf",
            "fab fa-delicious",
            "fab fa-deploydog",
            "fab fa-deskpro",
            "fas fa-desktop",
            "fab fa-deviantart",
            "fab fa-digg",
            "fab fa-digital-ocean",
            "fab fa-discord",
            "fab fa-discourse",
            "fab fa-dochub",
            "fab fa-docker",
            "fas fa-dollar-sign",
            "fas fa-dot-circle", "far fa-dot-circle",
            "fas fa-download",
            "fab fa-draft2digital",
            "fab fa-dribbble",
            "fab fa-dribbble-square",
            "fab fa-dropbox",
            "fab fa-drupal",
            "fab fa-dyalog",
            "fab fa-earlybirds",
            "fab fa-edge",
            "fas fa-edit", "far fa-edit",
            "fas fa-eject",
            "fas fa-ellipsis-h",
            "fas fa-ellipsis-v",
            "fab fa-ember",
            "fab fa-empire",
            "fas fa-envelope", "far fa-envelope",
            "fas fa-envelope-open", "far fa-envelope-open",
            "fas fa-envelope-square",
            "fab fa-envira",
            "fas fa-eraser",
            "fab fa-erlang",
            "fab fa-etsy",
            "fas fa-euro-sign",
            "fas fa-exchange-alt",
            "fas fa-exclamation",
            "fas fa-exclamation-circle",
            "fas fa-exclamation-triangle",
            "fas fa-expand",
            "fas fa-expand-arrows-alt",
            "fab fa-expeditedssl",
            "fas fa-external-link-alt",
            "fas fa-external-link-square-alt",
            "fas fa-eye",
            "fas fa-eye-dropper",
            "fas fa-eye-slash", "far fa-eye-slash",
            "fab fa-facebook",
            "fab fa-facebook-f",
            "fab fa-facebook-messenger",
            "fab fa-facebook-square",
            "fas fa-fast-backward",
            "fas fa-fast-forward",
            "fas fa-fax",
            "fas fa-female",
            "fas fa-fighter-jet",
            "fas fa-file", "far fa-file",
            "fas fa-file-alt", "far fa-file-alt",
            "fas fa-file-archive", "far fa-file-archive",
            "fas fa-file-audio", "far fa-file-audio",
            "fas fa-file-code", "far fa-file-code",
            "fas fa-file-excel", "far fa-file-excel",
            "fas fa-file-image", "far fa-file-image",
            "fas fa-file-pdf", "far fa-file-pdf",
            "fas fa-file-powerpoint", "far fa-file-powerpoint",
            "fas fa-file-video", "far fa-file-video",
            "fas fa-file-word", "far fa-file-word",
            "fas fa-film",
            "fas fa-filter",
            "fas fa-fire",
            "fas fa-fire-extinguisher",
            "fab fa-firefox",
            "fab fa-first-order",
            "fab fa-firstdraft",
            "fas fa-flag", "far fa-flag",
            "fas fa-flag-checkered",
            "fas fa-flask",
            "fab fa-flickr",
            "fab fa-fly",
            "fas fa-folder", "far fa-folder",
            "fas fa-folder-open", "far fa-folder-open",
            "fas fa-font",
            "fab fa-font-awesome",
            "fab fa-font-awesome-alt",
            "fab fa-font-awesome-flag",
            "fab fa-fonticons",
            "fab fa-fonticons-fi",
            "fab fa-fort-awesome",
            "fab fa-fort-awesome-alt",
            "fab fa-forumbee",
            "fas fa-forward",
            "fab fa-foursquare",
            "fab fa-free-code-camp",
            "fab fa-freebsd",
            "fas fa-frown", "far fa-frown",
            "fas fa-futbol", "far fa-futbol",
            "fas fa-gamepad",
            "fas fa-gavel",
            "fas fa-gem", "far fa-gem",
            "fas fa-genderless",
            "fab fa-get-pocket",
            "fab fa-gg",
            "fab fa-gg-circle",
            "fas fa-gift",
            "fab fa-git",
            "fab fa-git-square",
            "fab fa-github",
            "fab fa-github-alt",
            "fab fa-github-square",
            "fab fa-gitkraken",
            "fab fa-gitlab",
            "fab fa-gitter",
            "fas fa-glass-martini",
            "fab fa-glide",
            "fab fa-glide-g",
            "fas fa-globe",
            "fab fa-gofore",
            "fab fa-goodreads",
            "fab fa-goodreads-g",
            "fab fa-google",
            "fab fa-google-drive",
            "fab fa-google-play",
            "fab fa-google-plus",
            "fab fa-google-plus-g",
            "fab fa-google-plus-square",
            "fab fa-google-wallet",
            "fas fa-graduation-cap",
            "fab fa-gratipay",
            "fab fa-grav",
            "fab fa-gripfire",
            "fab fa-grunt",
            "fab fa-gulp",
            "fas fa-h-square",
            "fab fa-hacker-news",
            "fab fa-hacker-news-square",
            "fas fa-hand-lizard", "far fa-hand-lizard",
            "fas fa-hand-paper", "far fa-hand-paper",
            "fas fa-hand-peace", "far fa-hand-peace",
            "fas fa-hand-point-down", "far fa-hand-point-down",
            "fas fa-hand-point-left", "far fa-hand-point-left",
            "fas fa-hand-point-right", "far fa-hand-point-right",
            "fas fa-hand-point-up", "far fa-hand-point-up",
            "fas fa-hand-pointer", "far fa-hand-pointer",
            "fas fa-hand-rock", "far fa-hand-rock",
            "fas fa-hand-scissors", "far fa-hand-scissors",
            "fas fa-hand-spock", "far fa-hand-spock",
            "fas fa-handshake", "far fa-handshake",
            "fas fa-hashtag",
            "fas fa-hdd", "far fa-hdd",
            "fas fa-heading",
            "fas fa-headphones",
            "fas fa-heart", "far fa-heart",
            "fas fa-heartbeat",
            "fab fa-hire-a-helper",
            "fas fa-history",
            "fas fa-home",
            "fab fa-hooli",
            "fas fa-hospital", "far fa-hospital",
            "fab fa-hotjar",
            "fas fa-hourglass", "far fa-hourglass",
            "fas fa-hourglass-end",
            "fas fa-hourglass-half",
            "fas fa-hourglass-start",
            "fab fa-houzz",
            "fab fa-html5",
            "fab fa-hubspot",
            "fas fa-i-cursor",
            "fas fa-id-badge", "far fa-id-badge",
            "fas fa-id-card", "far fa-id-card",
            "fas fa-image", "far fa-image",
            "fas fa-images", "far fa-images",
            "fab fa-imdb",
            "fas fa-inbox",
            "fas fa-indent",
            "fas fa-industry",
            "fas fa-info",
            "fas fa-info-circle",
            "fab fa-instagram",
            "fab fa-internet-explorer",
            "fab fa-ioxhost",
            "fas fa-italic",
            "fab fa-itunes",
            "fab fa-itunes-note",
            "fab fa-jenkins",
            "fab fa-joget",
            "fab fa-joomla",
            "fab fa-js",
            "fab fa-js-square",
            "fab fa-jsfiddle",
            "fas fa-key",
            "fas fa-keyboard", "far fa-keyboard",
            "fab fa-keycdn",
            "fab fa-kickstarter",
            "fab fa-kickstarter-k",
            "fas fa-language",
            "fas fa-laptop",
            "fab fa-laravel",
            "fab fa-lastfm",
            "fab fa-lastfm-square",
            "fas fa-leaf",
            "fab fa-leanpub",
            "fas fa-lemon", "far fa-lemon",
            "fab fa-less",
            "fas fa-level-down-alt",
            "fas fa-level-up-alt",
            "fas fa-life-ring", "far fa-life-ring",
            "fas fa-lightbulb", "far fa-lightbulb",
            "fab fa-line",
            "fas fa-link",
            "fab fa-linkedin",
            "fab fa-linkedin-in",
            "fab fa-linode",
            "fab fa-linux",
            "fas fa-lira-sign",
            "fas fa-list",
            "fas fa-list-alt", "far fa-list-alt",
            "fas fa-list-ol",
            "fas fa-list-ul",
            "fas fa-location-arrow",
            "fas fa-lock",
            "fas fa-lock-open",
            "fas fa-long-arrow-alt-down",
            "fas fa-long-arrow-alt-left",
            "fas fa-long-arrow-alt-right",
            "fas fa-long-arrow-alt-up",
            "fas fa-low-vision",
            "fab fa-lyft",
            "fab fa-magento",
            "fas fa-magic",
            "fas fa-magnet",
            "fas fa-male",
            "fas fa-map", "far fa-map",
            "fas fa-map-marker",
            "fas fa-map-marker-alt",
            "fas fa-map-pin",
            "fas fa-map-signs",
            "fas fa-mars",
            "fas fa-mars-double",
            "fas fa-mars-stroke",
            "fas fa-mars-stroke-h",
            "fas fa-mars-stroke-v",
            "fab fa-maxcdn",
            "fab fa-medapps",
            "fab fa-medium",
            "fab fa-medium-m",
            "fas fa-medkit",
            "fab fa-medrt",
            "fab fa-meetup",
            "fas fa-meh", "far fa-meh",
            "fas fa-mercury",
            "fas fa-microchip",
            "fas fa-microphone",
            "fas fa-microphone-slash",
            "fab fa-microsoft",
            "fas fa-minus",
            "fas fa-minus-circle",
            "fas fa-minus-square", "far fa-minus-square",
            "fab fa-mix",
            "fab fa-mixcloud",
            "fab fa-mizuni",
            "fas fa-mobile",
            "fas fa-mobile-alt",
            "fab fa-modx",
            "fab fa-monero",
            "fas fa-money-bill-alt", "far fa-money-bill-alt",
            "fas fa-moon", "far fa-moon",
            "fas fa-motorcycle",
            "fas fa-mouse-pointer",
            "fas fa-music",
            "fab fa-napster",
            "fas fa-neuter",
            "fas fa-newspaper", "far fa-newspaper",
            "fab fa-nintendo-switch",
            "fab fa-node",
            "fab fa-node-js",
            "fab fa-npm",
            "fab fa-ns8",
            "fab fa-nutritionix",
            "fas fa-object-group", "far fa-object-group",
            "fas fa-object-ungroup", "far fa-object-ungroup",
            "fab fa-odnoklassniki",
            "fab fa-odnoklassniki-square",
            "fab fa-opencart",
            "fab fa-openid",
            "fab fa-opera",
            "fab fa-optin-monster",
            "fab fa-osi",
            "fas fa-outdent",
            "fab fa-page4",
            "fab fa-pagelines",
            "fas fa-paint-brush",
            "fab fa-palfed",
            "fas fa-paper-plane", "far fa-paper-plane",
            "fas fa-paperclip",
            "fas fa-paragraph",
            "fas fa-paste",
            "fab fa-patreon",
            "fas fa-pause",
            "fas fa-pause-circle", "far fa-pause-circle",
            "fas fa-paw",
            "fab fa-paypal",
            "fas fa-pen-square",
            "fas fa-pencil-alt",
            "fas fa-percent",
            "fab fa-periscope",
            "fab fa-phabricator",
            "fab fa-phoenix-framework",
            "fas fa-phone",
            "fas fa-phone-square",
            "fas fa-phone-volume",
            "fab fa-pied-piper",
            "fab fa-pied-piper-alt",
            "fab fa-pied-piper-pp",
            "fab fa-pinterest",
            "fab fa-pinterest-p",
            "fab fa-pinterest-square",
            "fas fa-plane",
            "fas fa-play",
            "fas fa-play-circle", "far fa-play-circle",
            "fab fa-playstation",
            "fas fa-plug",
            "fas fa-plus",
            "fas fa-plus-circle",
            "fas fa-plus-square", "far fa-plus-square",
            "fas fa-podcast",
            "fas fa-pound-sign",
            "fas fa-power-off",
            "fas fa-print",
            "fab fa-product-hunt",
            "fab fa-pushed",
            "fas fa-puzzle-piece",
            "fab fa-python",
            "fab fa-qq",
            "fas fa-qrcode",
            "fas fa-question",
            "fas fa-question-circle", "far fa-question-circle",
            "fab fa-quora",
            "fas fa-quote-left",
            "fas fa-quote-right",
            "fas fa-random",
            "fab fa-ravelry",
            "fab fa-react",
            "fab fa-rebel",
            "fas fa-recycle",
            "fab fa-red-river",
            "fab fa-reddit",
            "fab fa-reddit-alien",
            "fab fa-reddit-square",
            "fas fa-redo",
            "fas fa-redo-alt",
            "fas fa-registered", "far fa-registered",
            "fab fa-rendact",
            "fab fa-renren",
            "fas fa-reply",
            "fas fa-reply-all",
            "fab fa-replyd",
            "fab fa-resolving",
            "fas fa-retweet",
            "fas fa-road",
            "fas fa-rocket",
            "fab fa-rocketchat",
            "fab fa-rockrms",
            "fas fa-rss",
            "fas fa-rss-square",
            "fas fa-ruble-sign",
            "fas fa-rupee-sign",
            "fab fa-safari",
            "fab fa-sass",
            "fas fa-save", "far fa-save",
            "fab fa-schlix",
            "fab fa-scribd",
            "fas fa-search",
            "fas fa-search-minus",
            "fas fa-search-plus",
            "fab fa-searchengin",
            "fab fa-sellcast",
            "fab fa-sellsy",
            "fas fa-server",
            "fab fa-servicestack",
            "fas fa-share",
            "fas fa-share-alt",
            "fas fa-share-alt-square",
            "fas fa-share-square", "far fa-share-square",
            "fas fa-shekel-sign",
            "fas fa-shield-alt",
            "fas fa-ship",
            "fab fa-shirtsinbulk",
            "fas fa-shopping-bag",
            "fas fa-shopping-basket",
            "fas fa-shopping-cart",
            "fas fa-shower",
            "fas fa-sign-in-alt",
            "fas fa-sign-language",
            "fas fa-sign-out-alt",
            "fas fa-signal",
            "fab fa-simplybuilt",
            "fab fa-sistrix",
            "fas fa-sitemap",
            "fab fa-skyatlas",
            "fab fa-skype",
            "fab fa-slack",
            "fab fa-slack-hash",
            "fas fa-sliders-h",
            "fab fa-slideshare",
            "fas fa-smile", "far fa-smile",
            "fab fa-snapchat",
            "fab fa-snapchat-ghost",
            "fab fa-snapchat-square",
            "fas fa-snowflake", "far fa-snowflake",
            "fas fa-sort",
            "fas fa-sort-alpha-down",
            "fas fa-sort-alpha-up",
            "fas fa-sort-amount-down",
            "fas fa-sort-amount-up",
            "fas fa-sort-down",
            "fas fa-sort-numeric-down",
            "fas fa-sort-numeric-up",
            "fas fa-sort-up",
            "fab fa-soundcloud",
            "fas fa-space-shuttle",
            "fab fa-speakap",
            "fas fa-spinner",
            "fab fa-spotify",
            "fas fa-square", "far fa-square",
            "fab fa-stack-exchange",
            "fab fa-stack-overflow",
            "fas fa-star", "far fa-star",
            "fas fa-star-half", "far fa-star-half",
            "fab fa-staylinked",
            "fab fa-steam",
            "fab fa-steam-square",
            "fab fa-steam-symbol",
            "fas fa-step-backward",
            "fas fa-step-forward",
            "fas fa-stethoscope",
            "fab fa-sticker-mule",
            "fas fa-sticky-note", "far fa-sticky-note",
            "fas fa-stop",
            "fas fa-stop-circle", "far fa-stop-circle",
            "fab fa-strava",
            "fas fa-street-view",
            "fas fa-strikethrough",
            "fab fa-stripe",
            "fab fa-stripe-s",
            "fab fa-studiovinari",
            "fab fa-stumbleupon",
            "fab fa-stumbleupon-circle",
            "fas fa-subscript",
            "fas fa-subway",
            "fas fa-suitcase",
            "fas fa-sun", "far fa-sun",
            "fab fa-superpowers",
            "fas fa-superscript",
            "fab fa-supple",
            "fas fa-sync",
            "fas fa-sync-alt",
            "fas fa-table",
            "fas fa-tablet",
            "fas fa-tablet-alt",
            "fas fa-tachometer-alt",
            "fas fa-tag",
            "fas fa-tags",
            "fas fa-tasks",
            "fas fa-taxi",
            "fab fa-telegram",
            "fab fa-telegram-plane",
            "fab fa-tencent-weibo",
            "fas fa-terminal",
            "fas fa-text-height",
            "fas fa-text-width",
            "fas fa-th",
            "fas fa-th-large",
            "fas fa-th-list",
            "fab fa-themeisle",
            "fas fa-thermometer-empty",
            "fas fa-thermometer-full",
            "fas fa-thermometer-half",
            "fas fa-thermometer-quarter",
            "fas fa-thermometer-three-quarters",
            "fas fa-thumbs-down", "far fa-thumbs-down",
            "fas fa-thumbs-up", "far fa-thumbs-up",
            "fas fa-thumbtack",
            "fas fa-ticket-alt",
            "fas fa-times",
            "fas fa-times-circle", "far fa-times-circle",
            "fas fa-tint",
            "fas fa-toggle-off",
            "fas fa-toggle-on",
            "fas fa-trademark",
            "fas fa-train",
            "fas fa-transgender",
            "fas fa-transgender-alt",
            "fas fa-trash",
            "fas fa-trash-alt", "far fa-trash-alt",
            "fas fa-tree",
            "fab fa-trello",
            "fab fa-tripadvisor",
            "fas fa-trophy",
            "fas fa-truck",
            "fas fa-tty",
            "fab fa-tumblr",
            "fab fa-tumblr-square",
            "fas fa-tv",
            "fab fa-twitch",
            "fab fa-twitter",
            "fab fa-twitter-square",
            "fab fa-typo3",
            "fab fa-uber",
            "fab fa-uikit",
            "fas fa-umbrella",
            "fas fa-underline",
            "fas fa-undo",
            "fas fa-undo-alt",
            "fab fa-uniregistry",
            "fas fa-universal-access",
            "fas fa-university",
            "fas fa-unlink",
            "fas fa-unlock",
            "fas fa-unlock-alt",
            "fab fa-untappd",
            "fas fa-upload",
            "fab fa-usb",
            "fas fa-user", "far fa-user",
            "fas fa-user-circle", "far fa-user-circle",
            "fas fa-user-md",
            "fas fa-user-plus",
            "fas fa-user-secret",
            "fas fa-user-times",
            "fas fa-users",
            "fab fa-ussunnah",
            "fas fa-utensil-spoon",
            "fas fa-utensils",
            "fab fa-vaadin",
            "fas fa-venus",
            "fas fa-venus-double",
            "fas fa-venus-mars",
            "fab fa-viacoin",
            "fab fa-viadeo",
            "fab fa-viadeo-square",
            "fab fa-viber",
            "fas fa-video",
            "fab fa-vimeo",
            "fab fa-vimeo-square",
            "fab fa-vimeo-v",
            "fab fa-vine",
            "fab fa-vk",
            "fab fa-vnv",
            "fas fa-volume-down",
            "fas fa-volume-off",
            "fas fa-volume-up",
            "fab fa-vuejs",
            "fab fa-weibo",
            "fab fa-weixin",
            "fab fa-whatsapp",
            "fab fa-whatsapp-square",
            "fas fa-wheelchair",
            "fab fa-whmcs",
            "fas fa-wifi",
            "fab fa-wikipedia-w",
            "fas fa-window-close", "far fa-window-close",
            "fas fa-window-maximize", "far fa-window-maximize",
            "fas fa-window-minimize",
            "fas fa-window-restore", "far fa-window-restore",
            "fab fa-windows",
            "fas fa-won-sign",
            "fab fa-wordpress",
            "fab fa-wordpress-simple",
            "fab fa-wpbeginner",
            "fab fa-wpexplorer",
            "fab fa-wpforms",
            "fas fa-wrench",
            "fab fa-xbox",
            "fab fa-xing",
            "fab fa-xing-square",
            "fab fa-y-combinator",
            "fab fa-yahoo",
            "fab fa-yandex",
            "fab fa-yandex-international",
            "fab fa-yelp",
            "fas fa-yen-sign",
            "fab fa-yoast",
            "fab fa-youtube"
      ],
        newIcons: [
          "fab fa-500px",
            "fab fa-accessible-icon",
            "fab fa-accusoft",
            "fas fa-address-book", "far fa-address-book",
            "fas fa-address-card", "far fa-address-card",
            "fas fa-adjust",
            "fab fa-adn",
            "fab fa-adversal",
            "fab fa-affiliatetheme",
            "fab fa-algolia",
            "fas fa-align-center",
            "fas fa-align-justify",
            "fas fa-align-left",
            "fas fa-align-right",
            "fab fa-amazon",
            "fas fa-ambulance",
            "fas fa-american-sign-language-interpreting",
            "fab fa-amilia",
            "fas fa-anchor",
            "fab fa-android",
            "fab fa-angellist",
            "fas fa-angle-double-down",
            "fas fa-angle-double-left",
            "fas fa-angle-double-right",
            "fas fa-angle-double-up",
            "fas fa-angle-down",
            "fas fa-angle-left",
            "fas fa-angle-right",
            "fas fa-angle-up",
            "fab fa-angrycreative",
            "fab fa-angular",
            "fab fa-app-store",
            "fab fa-app-store-ios",
            "fab fa-apper",
            "fab fa-apple",
            "fab fa-apple-pay",
            "fas fa-archive",
            "fas fa-arrow-alt-circle-down", "far fa-arrow-alt-circle-down",
            "fas fa-arrow-alt-circle-left", "far fa-arrow-alt-circle-left",
            "fas fa-arrow-alt-circle-right", "far fa-arrow-alt-circle-right",
            "fas fa-arrow-alt-circle-up", "far fa-arrow-alt-circle-up",
            "fas fa-arrow-circle-down",
            "fas fa-arrow-circle-left",
            "fas fa-arrow-circle-right",
            "fas fa-arrow-circle-up",
            "fas fa-arrow-down",
            "fas fa-arrow-left",
            "fas fa-arrow-right",
            "fas fa-arrow-up",
            "fas fa-arrows-alt",
            "fas fa-arrows-alt-h",
            "fas fa-arrows-alt-v",
            "fas fa-assistive-listening-systems",
            "fas fa-asterisk",
            "fab fa-asymmetrik",
            "fas fa-at",
            "fab fa-audible",
            "fas fa-audio-description",
            "fab fa-autoprefixer",
            "fab fa-avianex",
            "fab fa-aviato",
            "fab fa-aws",
            "fas fa-backward",
            "fas fa-balance-scale",
            "fas fa-ban",
            "fab fa-bandcamp",
            "fas fa-barcode",
            "fas fa-bars",
            "fas fa-bath",
            "fas fa-battery-empty",
            "fas fa-battery-full",
            "fas fa-battery-half",
            "fas fa-battery-quarter",
            "fas fa-battery-three-quarters",
            "fas fa-bed",
            "fas fa-beer",
            "fab fa-behance",
            "fab fa-behance-square",
            "fas fa-bell", "far fa-bell",
            "fas fa-bell-slash", "far fa-bell-slash",
            "fas fa-bicycle",
            "fab fa-bimobject",
            "fas fa-binoculars",
            "fas fa-birthday-cake",
            "fab fa-bitbucket",
            "fab fa-bitcoin",
            "fab fa-bity",
            "fab fa-black-tie",
            "fab fa-blackberry",
            "fas fa-blind",
            "fab fa-blogger",
            "fab fa-blogger-b",
            "fab fa-bluetooth",
            "fab fa-bluetooth-b",
            "fas fa-bold",
            "fas fa-bolt",
            "fas fa-bomb",
            "fas fa-book",
            "fas fa-bookmark", "far fa-bookmark",
            "fas fa-braille",
            "fas fa-briefcase",
            "fab fa-btc",
            "fas fa-bug",
            "fas fa-building", "far fa-building",
            "fas fa-bullhorn",
            "fas fa-bullseye",
            "fab fa-buromobelexperte",
            "fas fa-bus",
            "fab fa-buysellads",
            "fas fa-calculator",
            "fas fa-calendar", "far fa-calendar",
            "fas fa-calendar-alt", "far fa-calendar-alt",
            "fas fa-calendar-check", "far fa-calendar-check",
            "fas fa-calendar-minus", "far fa-calendar-minus",
            "fas fa-calendar-plus", "far fa-calendar-plus",
            "fas fa-calendar-times", "far fa-calendar-times",
            "fas fa-camera",
            "fas fa-camera-retro",
            "fas fa-car",
            "fas fa-caret-down",
            "fas fa-caret-left",
            "fas fa-caret-right",
            "fas fa-caret-square-down", "far fa-caret-square-down",
            "fas fa-caret-square-left", "far fa-caret-square-left",
            "fas fa-caret-square-right", "far fa-caret-square-right",
            "fas fa-caret-square-up", "far fa-caret-square-up",
            "fas fa-caret-up",
            "fas fa-cart-arrow-down",
            "fas fa-cart-plus",
            "fab fa-cc-amex",
            "fab fa-cc-apple-pay",
            "fab fa-cc-diners-club",
            "fab fa-cc-discover",
            "fab fa-cc-jcb",
            "fab fa-cc-mastercard",
            "fab fa-cc-paypal",
            "fab fa-cc-stripe",
            "fab fa-cc-visa",
            "fab fa-centercode",
            "fas fa-certificate",
            "fas fa-chart-area",
            "fas fa-chart-bar", "far fa-chart-bar",
            "fas fa-chart-line",
            "fas fa-chart-pie",
            "fas fa-check",
            "fas fa-check-circle", "far fa-check-circle",
            "fas fa-check-square", "far fa-check-square",
            "fas fa-chevron-circle-down",
            "fas fa-chevron-circle-left",
            "fas fa-chevron-circle-right",
            "fas fa-chevron-circle-up",
            "fas fa-chevron-down",
            "fas fa-chevron-left",
            "fas fa-chevron-right",
            "fas fa-chevron-up",
            "fas fa-child",
            "fab fa-chrome",
            "fas fa-circle", "far fa-circle",
            "fas fa-circle-notch",
            "fas fa-clipboard", "far fa-clipboard",
            "fas fa-clock", "far fa-clock",
            "fas fa-clone", "far fa-clone",
            "fas fa-closed-captioning", "far fa-closed-captioning",
            "fas fa-cloud",
            "fas fa-cloud-download-alt",
            "fas fa-cloud-upload-alt",
            "fab fa-cloudscale",
            "fab fa-cloudsmith",
            "fab fa-cloudversify",
            "fas fa-code",
            "fas fa-code-branch",
            "fab fa-codepen",
            "fab fa-codiepie",
            "fas fa-coffee",
            "fas fa-cog",
            "fas fa-cogs",
            "fas fa-columns",
            "fas fa-comment", "far fa-comment",
            "fas fa-comment-alt", "far fa-comment-alt",
            "fas fa-comments", "far fa-comments",
            "fas fa-compass", "far fa-compass",
            "fas fa-compress",
            "fab fa-connectdevelop",
            "fab fa-contao",
            "fas fa-copy", "far fa-copy",
            "fas fa-copyright", "far fa-copyright",
            "fab fa-cpanel",
            "fab fa-creative-commons",
            "fas fa-credit-card", "far fa-credit-card",
            "fas fa-crop",
            "fas fa-crosshairs",
            "fab fa-css3",
            "fab fa-css3-alt",
            "fas fa-cube",
            "fas fa-cubes",
            "fas fa-cut",
            "fab fa-cuttlefish",
            "fab fa-d-and-d",
            "fab fa-dashcube",
            "fas fa-database",
            "fas fa-deaf",
            "fab fa-delicious",
            "fab fa-deploydog",
            "fab fa-deskpro",
            "fas fa-desktop",
            "fab fa-deviantart",
            "fab fa-digg",
            "fab fa-digital-ocean",
            "fab fa-discord",
            "fab fa-discourse",
            "fab fa-dochub",
            "fab fa-docker",
            "fas fa-dollar-sign",
            "fas fa-dot-circle", "far fa-dot-circle",
            "fas fa-download",
            "fab fa-draft2digital",
            "fab fa-dribbble",
            "fab fa-dribbble-square",
            "fab fa-dropbox",
            "fab fa-drupal",
            "fab fa-dyalog",
            "fab fa-earlybirds",
            "fab fa-edge",
            "fas fa-edit", "far fa-edit",
            "fas fa-eject",
            "fas fa-ellipsis-h",
            "fas fa-ellipsis-v",
            "fab fa-ember",
            "fab fa-empire",
            "fas fa-envelope", "far fa-envelope",
            "fas fa-envelope-open", "far fa-envelope-open",
            "fas fa-envelope-square",
            "fab fa-envira",
            "fas fa-eraser",
            "fab fa-erlang",
            "fab fa-etsy",
            "fas fa-euro-sign",
            "fas fa-exchange-alt",
            "fas fa-exclamation",
            "fas fa-exclamation-circle",
            "fas fa-exclamation-triangle",
            "fas fa-expand",
            "fas fa-expand-arrows-alt",
            "fab fa-expeditedssl",
            "fas fa-external-link-alt",
            "fas fa-external-link-square-alt",
            "fas fa-eye",
            "fas fa-eye-dropper",
            "fas fa-eye-slash", "far fa-eye-slash",
            "fab fa-facebook",
            "fab fa-facebook-f",
            "fab fa-facebook-messenger",
            "fab fa-facebook-square",
            "fas fa-fast-backward",
            "fas fa-fast-forward",
            "fas fa-fax",
            "fas fa-female",
            "fas fa-fighter-jet",
            "fas fa-file", "far fa-file",
            "fas fa-file-alt", "far fa-file-alt",
            "fas fa-file-archive", "far fa-file-archive",
            "fas fa-file-audio", "far fa-file-audio",
            "fas fa-file-code", "far fa-file-code",
            "fas fa-file-excel", "far fa-file-excel",
            "fas fa-file-image", "far fa-file-image",
            "fas fa-file-pdf", "far fa-file-pdf",
            "fas fa-file-powerpoint", "far fa-file-powerpoint",
            "fas fa-file-video", "far fa-file-video",
            "fas fa-file-word", "far fa-file-word",
            "fas fa-film",
            "fas fa-filter",
            "fas fa-fire",
            "fas fa-fire-extinguisher",
            "fab fa-firefox",
            "fab fa-first-order",
            "fab fa-firstdraft",
            "fas fa-flag", "far fa-flag",
            "fas fa-flag-checkered",
            "fas fa-flask",
            "fab fa-flickr",
            "fab fa-fly",
            "fas fa-folder", "far fa-folder",
            "fas fa-folder-open", "far fa-folder-open",
            "fas fa-font",
            "fab fa-font-awesome",
            "fab fa-font-awesome-alt",
            "fab fa-font-awesome-flag",
            "fab fa-fonticons",
            "fab fa-fonticons-fi",
            "fab fa-fort-awesome",
            "fab fa-fort-awesome-alt",
            "fab fa-forumbee",
            "fas fa-forward",
            "fab fa-foursquare",
            "fab fa-free-code-camp",
            "fab fa-freebsd",
            "fas fa-frown", "far fa-frown",
            "fas fa-futbol", "far fa-futbol",
            "fas fa-gamepad",
            "fas fa-gavel",
            "fas fa-gem", "far fa-gem",
            "fas fa-genderless",
            "fab fa-get-pocket",
            "fab fa-gg",
            "fab fa-gg-circle",
            "fas fa-gift",
            "fab fa-git",
            "fab fa-git-square",
            "fab fa-github",
            "fab fa-github-alt",
            "fab fa-github-square",
            "fab fa-gitkraken",
            "fab fa-gitlab",
            "fab fa-gitter",
            "fas fa-glass-martini",
            "fab fa-glide",
            "fab fa-glide-g",
            "fas fa-globe",
            "fab fa-gofore",
            "fab fa-goodreads",
            "fab fa-goodreads-g",
            "fab fa-google",
            "fab fa-google-drive",
            "fab fa-google-play",
            "fab fa-google-plus",
            "fab fa-google-plus-g",
            "fab fa-google-plus-square",
            "fab fa-google-wallet",
            "fas fa-graduation-cap",
            "fab fa-gratipay",
            "fab fa-grav",
            "fab fa-gripfire",
            "fab fa-grunt",
            "fab fa-gulp",
            "fas fa-h-square",
            "fab fa-hacker-news",
            "fab fa-hacker-news-square",
            "fas fa-hand-lizard", "far fa-hand-lizard",
            "fas fa-hand-paper", "far fa-hand-paper",
            "fas fa-hand-peace", "far fa-hand-peace",
            "fas fa-hand-point-down", "far fa-hand-point-down",
            "fas fa-hand-point-left", "far fa-hand-point-left",
            "fas fa-hand-point-right", "far fa-hand-point-right",
            "fas fa-hand-point-up", "far fa-hand-point-up",
            "fas fa-hand-pointer", "far fa-hand-pointer",
            "fas fa-hand-rock", "far fa-hand-rock",
            "fas fa-hand-scissors", "far fa-hand-scissors",
            "fas fa-hand-spock", "far fa-hand-spock",
            "fas fa-handshake", "far fa-handshake",
            "fas fa-hashtag",
            "fas fa-hdd", "far fa-hdd",
            "fas fa-heading",
            "fas fa-headphones",
            "fas fa-heart", "far fa-heart",
            "fas fa-heartbeat",
            "fab fa-hire-a-helper",
            "fas fa-history",
            "fas fa-home",
            "fab fa-hooli",
            "fas fa-hospital", "far fa-hospital",
            "fab fa-hotjar",
            "fas fa-hourglass", "far fa-hourglass",
            "fas fa-hourglass-end",
            "fas fa-hourglass-half",
            "fas fa-hourglass-start",
            "fab fa-houzz",
            "fab fa-html5",
            "fab fa-hubspot",
            "fas fa-i-cursor",
            "fas fa-id-badge", "far fa-id-badge",
            "fas fa-id-card", "far fa-id-card",
            "fas fa-image", "far fa-image",
            "fas fa-images", "far fa-images",
            "fab fa-imdb",
            "fas fa-inbox",
            "fas fa-indent",
            "fas fa-industry",
            "fas fa-info",
            "fas fa-info-circle",
            "fab fa-instagram",
            "fab fa-internet-explorer",
            "fab fa-ioxhost",
            "fas fa-italic",
            "fab fa-itunes",
            "fab fa-itunes-note",
            "fab fa-jenkins",
            "fab fa-joget",
            "fab fa-joomla",
            "fab fa-js",
            "fab fa-js-square",
            "fab fa-jsfiddle",
            "fas fa-key",
            "fas fa-keyboard", "far fa-keyboard",
            "fab fa-keycdn",
            "fab fa-kickstarter",
            "fab fa-kickstarter-k",
            "fas fa-language",
            "fas fa-laptop",
            "fab fa-laravel",
            "fab fa-lastfm",
            "fab fa-lastfm-square",
            "fas fa-leaf",
            "fab fa-leanpub",
            "fas fa-lemon", "far fa-lemon",
            "fab fa-less",
            "fas fa-level-down-alt",
            "fas fa-level-up-alt",
            "fas fa-life-ring", "far fa-life-ring",
            "fas fa-lightbulb", "far fa-lightbulb",
            "fab fa-line",
            "fas fa-link",
            "fab fa-linkedin",
            "fab fa-linkedin-in",
            "fab fa-linode",
            "fab fa-linux",
            "fas fa-lira-sign",
            "fas fa-list",
            "fas fa-list-alt", "far fa-list-alt",
            "fas fa-list-ol",
            "fas fa-list-ul",
            "fas fa-location-arrow",
            "fas fa-lock",
            "fas fa-lock-open",
            "fas fa-long-arrow-alt-down",
            "fas fa-long-arrow-alt-left",
            "fas fa-long-arrow-alt-right",
            "fas fa-long-arrow-alt-up",
            "fas fa-low-vision",
            "fab fa-lyft",
            "fab fa-magento",
            "fas fa-magic",
            "fas fa-magnet",
            "fas fa-male",
            "fas fa-map", "far fa-map",
            "fas fa-map-marker",
            "fas fa-map-marker-alt",
            "fas fa-map-pin",
            "fas fa-map-signs",
            "fas fa-mars",
            "fas fa-mars-double",
            "fas fa-mars-stroke",
            "fas fa-mars-stroke-h",
            "fas fa-mars-stroke-v",
            "fab fa-maxcdn",
            "fab fa-medapps",
            "fab fa-medium",
            "fab fa-medium-m",
            "fas fa-medkit",
            "fab fa-medrt",
            "fab fa-meetup",
            "fas fa-meh", "far fa-meh",
            "fas fa-mercury",
            "fas fa-microchip",
            "fas fa-microphone",
            "fas fa-microphone-slash",
            "fab fa-microsoft",
            "fas fa-minus",
            "fas fa-minus-circle",
            "fas fa-minus-square", "far fa-minus-square",
            "fab fa-mix",
            "fab fa-mixcloud",
            "fab fa-mizuni",
            "fas fa-mobile",
            "fas fa-mobile-alt",
            "fab fa-modx",
            "fab fa-monero",
            "fas fa-money-bill-alt", "far fa-money-bill-alt",
            "fas fa-moon", "far fa-moon",
            "fas fa-motorcycle",
            "fas fa-mouse-pointer",
            "fas fa-music",
            "fab fa-napster",
            "fas fa-neuter",
            "fas fa-newspaper", "far fa-newspaper",
            "fab fa-nintendo-switch",
            "fab fa-node",
            "fab fa-node-js",
            "fab fa-npm",
            "fab fa-ns8",
            "fab fa-nutritionix",
            "fas fa-object-group", "far fa-object-group",
            "fas fa-object-ungroup", "far fa-object-ungroup",
            "fab fa-odnoklassniki",
            "fab fa-odnoklassniki-square",
            "fab fa-opencart",
            "fab fa-openid",
            "fab fa-opera",
            "fab fa-optin-monster",
            "fab fa-osi",
            "fas fa-outdent",
            "fab fa-page4",
            "fab fa-pagelines",
            "fas fa-paint-brush",
            "fab fa-palfed",
            "fas fa-paper-plane", "far fa-paper-plane",
            "fas fa-paperclip",
            "fas fa-paragraph",
            "fas fa-paste",
            "fab fa-patreon",
            "fas fa-pause",
            "fas fa-pause-circle", "far fa-pause-circle",
            "fas fa-paw",
            "fab fa-paypal",
            "fas fa-pen-square",
            "fas fa-pencil-alt",
            "fas fa-percent",
            "fab fa-periscope",
            "fab fa-phabricator",
            "fab fa-phoenix-framework",
            "fas fa-phone",
            "fas fa-phone-square",
            "fas fa-phone-volume",
            "fab fa-pied-piper",
            "fab fa-pied-piper-alt",
            "fab fa-pied-piper-pp",
            "fab fa-pinterest",
            "fab fa-pinterest-p",
            "fab fa-pinterest-square",
            "fas fa-plane",
            "fas fa-play",
            "fas fa-play-circle", "far fa-play-circle",
            "fab fa-playstation",
            "fas fa-plug",
            "fas fa-plus",
            "fas fa-plus-circle",
            "fas fa-plus-square", "far fa-plus-square",
            "fas fa-podcast",
            "fas fa-pound-sign",
            "fas fa-power-off",
            "fas fa-print",
            "fab fa-product-hunt",
            "fab fa-pushed",
            "fas fa-puzzle-piece",
            "fab fa-python",
            "fab fa-qq",
            "fas fa-qrcode",
            "fas fa-question",
            "fas fa-question-circle", "far fa-question-circle",
            "fab fa-quora",
            "fas fa-quote-left",
            "fas fa-quote-right",
            "fas fa-random",
            "fab fa-ravelry",
            "fab fa-react",
            "fab fa-rebel",
            "fas fa-recycle",
            "fab fa-red-river",
            "fab fa-reddit",
            "fab fa-reddit-alien",
            "fab fa-reddit-square",
            "fas fa-redo",
            "fas fa-redo-alt",
            "fas fa-registered", "far fa-registered",
            "fab fa-rendact",
            "fab fa-renren",
            "fas fa-reply",
            "fas fa-reply-all",
            "fab fa-replyd",
            "fab fa-resolving",
            "fas fa-retweet",
            "fas fa-road",
            "fas fa-rocket",
            "fab fa-rocketchat",
            "fab fa-rockrms",
            "fas fa-rss",
            "fas fa-rss-square",
            "fas fa-ruble-sign",
            "fas fa-rupee-sign",
            "fab fa-safari",
            "fab fa-sass",
            "fas fa-save", "far fa-save",
            "fab fa-schlix",
            "fab fa-scribd",
            "fas fa-search",
            "fas fa-search-minus",
            "fas fa-search-plus",
            "fab fa-searchengin",
            "fab fa-sellcast",
            "fab fa-sellsy",
            "fas fa-server",
            "fab fa-servicestack",
            "fas fa-share",
            "fas fa-share-alt",
            "fas fa-share-alt-square",
            "fas fa-share-square", "far fa-share-square",
            "fas fa-shekel-sign",
            "fas fa-shield-alt",
            "fas fa-ship",
            "fab fa-shirtsinbulk",
            "fas fa-shopping-bag",
            "fas fa-shopping-basket",
            "fas fa-shopping-cart",
            "fas fa-shower",
            "fas fa-sign-in-alt",
            "fas fa-sign-language",
            "fas fa-sign-out-alt",
            "fas fa-signal",
            "fab fa-simplybuilt",
            "fab fa-sistrix",
            "fas fa-sitemap",
            "fab fa-skyatlas",
            "fab fa-skype",
            "fab fa-slack",
            "fab fa-slack-hash",
            "fas fa-sliders-h",
            "fab fa-slideshare",
            "fas fa-smile", "far fa-smile",
            "fab fa-snapchat",
            "fab fa-snapchat-ghost",
            "fab fa-snapchat-square",
            "fas fa-snowflake", "far fa-snowflake",
            "fas fa-sort",
            "fas fa-sort-alpha-down",
            "fas fa-sort-alpha-up",
            "fas fa-sort-amount-down",
            "fas fa-sort-amount-up",
            "fas fa-sort-down",
            "fas fa-sort-numeric-down",
            "fas fa-sort-numeric-up",
            "fas fa-sort-up",
            "fab fa-soundcloud",
            "fas fa-space-shuttle",
            "fab fa-speakap",
            "fas fa-spinner",
            "fab fa-spotify",
            "fas fa-square", "far fa-square",
            "fab fa-stack-exchange",
            "fab fa-stack-overflow",
            "fas fa-star", "far fa-star",
            "fas fa-star-half", "far fa-star-half",
            "fab fa-staylinked",
            "fab fa-steam",
            "fab fa-steam-square",
            "fab fa-steam-symbol",
            "fas fa-step-backward",
            "fas fa-step-forward",
            "fas fa-stethoscope",
            "fab fa-sticker-mule",
            "fas fa-sticky-note", "far fa-sticky-note",
            "fas fa-stop",
            "fas fa-stop-circle", "far fa-stop-circle",
            "fab fa-strava",
            "fas fa-street-view",
            "fas fa-strikethrough",
            "fab fa-stripe",
            "fab fa-stripe-s",
            "fab fa-studiovinari",
            "fab fa-stumbleupon",
            "fab fa-stumbleupon-circle",
            "fas fa-subscript",
            "fas fa-subway",
            "fas fa-suitcase",
            "fas fa-sun", "far fa-sun",
            "fab fa-superpowers",
            "fas fa-superscript",
            "fab fa-supple",
            "fas fa-sync",
            "fas fa-sync-alt",
            "fas fa-table",
            "fas fa-tablet",
            "fas fa-tablet-alt",
            "fas fa-tachometer-alt",
            "fas fa-tag",
            "fas fa-tags",
            "fas fa-tasks",
            "fas fa-taxi",
            "fab fa-telegram",
            "fab fa-telegram-plane",
            "fab fa-tencent-weibo",
            "fas fa-terminal",
            "fas fa-text-height",
            "fas fa-text-width",
            "fas fa-th",
            "fas fa-th-large",
            "fas fa-th-list",
            "fab fa-themeisle",
            "fas fa-thermometer-empty",
            "fas fa-thermometer-full",
            "fas fa-thermometer-half",
            "fas fa-thermometer-quarter",
            "fas fa-thermometer-three-quarters",
            "fas fa-thumbs-down", "far fa-thumbs-down",
            "fas fa-thumbs-up", "far fa-thumbs-up",
            "fas fa-thumbtack",
            "fas fa-ticket-alt",
            "fas fa-times",
            "fas fa-times-circle", "far fa-times-circle",
            "fas fa-tint",
            "fas fa-toggle-off",
            "fas fa-toggle-on",
            "fas fa-trademark",
            "fas fa-train",
            "fas fa-transgender",
            "fas fa-transgender-alt",
            "fas fa-trash",
            "fas fa-trash-alt", "far fa-trash-alt",
            "fas fa-tree",
            "fab fa-trello",
            "fab fa-tripadvisor",
            "fas fa-trophy",
            "fas fa-truck",
            "fas fa-tty",
            "fab fa-tumblr",
            "fab fa-tumblr-square",
            "fas fa-tv",
            "fab fa-twitch",
            "fab fa-twitter",
            "fab fa-twitter-square",
            "fab fa-typo3",
            "fab fa-uber",
            "fab fa-uikit",
            "fas fa-umbrella",
            "fas fa-underline",
            "fas fa-undo",
            "fas fa-undo-alt",
            "fab fa-uniregistry",
            "fas fa-universal-access",
            "fas fa-university",
            "fas fa-unlink",
            "fas fa-unlock",
            "fas fa-unlock-alt",
            "fab fa-untappd",
            "fas fa-upload",
            "fab fa-usb",
            "fas fa-user", "far fa-user",
            "fas fa-user-circle", "far fa-user-circle",
            "fas fa-user-md",
            "fas fa-user-plus",
            "fas fa-user-secret",
            "fas fa-user-times",
            "fas fa-users",
            "fab fa-ussunnah",
            "fas fa-utensil-spoon",
            "fas fa-utensils",
            "fab fa-vaadin",
            "fas fa-venus",
            "fas fa-venus-double",
            "fas fa-venus-mars",
            "fab fa-viacoin",
            "fab fa-viadeo",
            "fab fa-viadeo-square",
            "fab fa-viber",
            "fas fa-video",
            "fab fa-vimeo",
            "fab fa-vimeo-square",
            "fab fa-vimeo-v",
            "fab fa-vine",
            "fab fa-vk",
            "fab fa-vnv",
            "fas fa-volume-down",
            "fas fa-volume-off",
            "fas fa-volume-up",
            "fab fa-vuejs",
            "fab fa-weibo",
            "fab fa-weixin",
            "fab fa-whatsapp",
            "fab fa-whatsapp-square",
            "fas fa-wheelchair",
            "fab fa-whmcs",
            "fas fa-wifi",
            "fab fa-wikipedia-w",
            "fas fa-window-close", "far fa-window-close",
            "fas fa-window-maximize", "far fa-window-maximize",
            "fas fa-window-minimize",
            "fas fa-window-restore", "far fa-window-restore",
            "fab fa-windows",
            "fas fa-won-sign",
            "fab fa-wordpress",
            "fab fa-wordpress-simple",
            "fab fa-wpbeginner",
            "fab fa-wpexplorer",
            "fab fa-wpforms",
            "fas fa-wrench",
            "fab fa-xbox",
            "fab fa-xing",
            "fab fa-xing-square",
            "fab fa-y-combinator",
            "fab fa-yahoo",
            "fab fa-yandex",
            "fab fa-yandex-international",
            "fab fa-yelp",
            "fas fa-yen-sign",
            "fab fa-yoast",
            "fab fa-youtube"
        ]
    };
   
    this.searchIcon = this.searchIcon.bind(this);
    this.handleFontChange = this.handleFontChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleUnlock = this.handleUnlock.bind(this);
    this.handleLock = this.handleLock.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this); 
    this.makeTextItalic = this.makeTextItalic.bind(this); 
    this.makeTextBold = this.makeTextBold.bind(this); 
    this.handleWidthChange = this.handleWidthChange.bind(this); 
    this.handleColorClick = this.handleColorClick.bind(this); 
    this.handleCrossWidthChange = this.handleCrossWidthChange.bind(this);
  }

handleBackgroundClick = () => {
        this.setState({ displayBackgroundColorPicker: !this.state.displayBackgroundColorPicker })
    };

    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    };

    

    handleBackgroundColor = (color) => {
        this.setState({ displayBackgroundColorPicker: color.rgb });
        var id = '#'+$('#exmModel').val()+' '+'.extraIcon';
        $(id).css('background-color', color.hex);
    };
    handleBorderColor = (color) => {
        this.setState({ displayBorderColorPicker: color.rgb });
        var id = '#'+$('#exmModel').val()+' '+'.extraIcon';
        $(id).css('border', '2px solid '+color.hex);
    };
    handleBorderClose = () => {
        this.setState({ displayBorderColorPicker: false });
    };
    handleBackgroundClose = () => {
        this.setState({ displayBackgroundColorPicker: false });
    };

  handleTrendClick = () => {  
        alert('dfd'+ this.state.displayTrendPicker);  
        this.setState({displayTrendColorPicker: true})  
    };  
    handleTrendClose = () => {  
        this.setState({ displayTrendColorPicker: false }) 
    };  
    handleWidthChange = (event) => {  
        $('.highcharts-trendline-series path').css('stroke-width',event.target.value);  
    };

    handleCrossWidthChange = (event) => { 
        //handle cross width change   
        //alert('width change');  
        $('.highcharts-crosshair').css('stroke-width',event.target.value);  
    } 
    handleCrossWidthColorChange = (event) => {  
        //handle cross width change   
        alert('width change');  
        $('.highcharts-crosshair path').css('stroke','#FFA500');  
    }

    makeTextBold = (event) => { 
        var id = '#'+$('#exmModel').val()+' '+'.extraIcon'; 
        $(id).css('font-weight','bold');  
    };  
    handleColorClick = (variable) => {  
        this.setState({ [variable]: !this.state[variable] })  
    };  
      
    makeTextItalic = (event) => { 
        var id = '#'+$('#exmModel').val()+' '+'.extraIcon'; 
        $(id).css('font-style','Italic'); 
    };  
      
    handleTextChange = (event) => { 
        var id = '#'+$('#exmModel').val()+' '+'.extraIcon'; 
        $(id).text(event.target.value); 
    };  
    
    handleFontChange = (event) => { 
        var id = '#'+$('#exmModel').val()+' '+'.extraIcon'; 
        $(id).css('font-size', event.target.value+'px');  
    };  
    handleBorderClick = () => { 
        this.setState({ displayBorderColorPicker: !this.state.displayBorderColorPicker }) 
    };  
    
  handleUnlock = () => {
      this.setState({
        lock: false
      })
  };

  handleLock = () => {
      this.setState({
        lock: true
      })
  };



  handleDelete = () => {
      alert('You have clicked on delete button.');
      var id = '#'+$('#exmModel').val()+' '+'.extraIcon';
      $(id).css('display', 'none');
  };

  searchIcon = event => {

      let iconsArr = this.state.icons; 
    
      this.setState({
          icons: iconsArr.filter(iconsArr => iconsArr.includes(event.target.value))
      })

  }

  handleFontChange = (event) => {
      var id = '#'+$('#exmModel').val()+' '+'.extraIcon';
      $(id).css('font-size', event.target.value+'px');
  };

  handleClick = () => {
      this.setState({ displayColorPicker: !this.state.displayColorPicker })
  };

  handleClose = () => {
      this.setState({ displayColorPicker: false });
  };

  handleChange = (color) => {
      this.setState({ background: color.hex });
       var id = '#'+$('#exmModel').val()+' '+'.extraIcon';
      $(id).css('color', this.state.background);
  };
  getResult(){

    this.props
    .getStockValue(
      this.props.symbol,
      this.props.stock,
      this.props.time,
      this.props.mult,
      this.props.startd,
      this.props.endd
    )
    .then(() => {
      if (this.props.stockValue.error == null) {
          const stockArray = [];
          const monthNames = ["Jan", "Feb", "March", "April", "May", "June",
  "July", "Aug", "Sep", "Oct", "Nov", "Dec"
];


          var dayArray=[];
          var day="";
          this.props.stockValue.stockList  && this.props.stockValue.stockList.results &&
          this.props.stockValue.stockList.results.map(({ v, t,o }) =>{  
              let get_converted_time = new Date(t).toString().substring(4, 15) 
             var month=monthNames[new Date(t).getMonth()];
              stockArray.push([get_converted_time, o,month])
              // if(day=="" || day!=month)
              // {
              //   day=month;
              //   dayArray.push(month);
              // }
              // else
              // {
                console.log(new Date(t));
                dayArray.push((new Date(t).getDate()+' '+month));
              // }
            }
          );
          //console.log('dayArray',dayArray)
          this.setState({
            dayArray:dayArray
          })
          console.log(dayArray);
          // $("#container").mousemove(function(e){
           
          //   // var chart = Highcharts.charts[0];
          //   // var xaxis = chart.xAxis[0];
          //   // var yaxis = chart.yAxis[0];
          //   // console.log("X axis is "+xaxis+" and yaxis is"+yaxis);
          //   console.log("stock array is "+this.props.stockValue.stockList.results);
           
      
          // });

        this.setState(
          {
            stockData: stockArray,
          },
          () => {
            this.setState({ stockLoad: true });
          }
        );
        this.drawHighLine(stockArray);
      } else {
        this.setState({
          stockError: this.props.stockValue.error,
        });
      }
    });
  }


  componentDidMount() {
    
   

    //alert('1');

    this.getResult();

    const { stockError, stockData } = this.state;
    let rangeSelected = 1;

    // setInterval(() => {
    //     this.getResult()
    // }, 3000);

    setTimeout(() => {
     
      this.getResult()   
      const options = {
        chart: {
          type: "line",
          height: 700, 
         // marginBottom: -50,
          alignTicks: false,
          renderTo: 'chartContainer',
          zoomType: 'x',
          borderColor: '#a1a1a1',
          panning: false,
          backgroundColor: this.state.backgroundColor,
          events: {
            load: function(){
                 this.mapZoom(0.1);   
            }
        }
        }, 
        navigator: {
          enabled: false
        },
        plotOptions: {
            series: {
                color: this.props.line_color_color,
            }
        },
        title: {
          text: this.props.symbolname,
        },
        stockTools: {
          gui: {
            enabled: false,
            //buttons: ['indicators', 'separator', 'simpleShapes', 'lines', 'crookedLines', 'measure', 'advanced', 'toggleAnnotations', 'separator', 'verticalLabels', 'flags', 'separator', 'zoomChange', 'fullScreen', 'typeChange', 'separator', 'currentPriceIndicator', 'saveChart'],
            definitions: {
              inputText: {
                className: 'highcharts-inputText-annotation',
                symbol: 'label.svg'
              }
            }
          }
        },
        navigator:{
          enabled:false
        },
      rangeSelector: {
        enabled:false,
        allButtonsEnabled: true,
        buttons: [
          {
            type: "hour",
            count: 24,
            text: "Hour",
            dataGrouping: {
              units: [["minute", [1]]],
            },
          },
          {
            type: "all",
            text: "All",
            dataGrouping: {
              units: [["day", [1]]],
            },
          },
        ],
        inputEnabled: false,
        buttonTheme: {
          width: 40,
        },
        selected: rangeSelected,
      },
      xAxis: {
        tickInterval: 1,
        offset: -840,
        categories:this.state.dayArray,
        gridLineWidth: this.props.grid,
        type: 'category',
        labels:{
          rotation: 0,
          y: 280
        },
          // scrollbar: {
          //     enabled: true
          // },
         
      },
      yAxis: {
        min: 0,
        max: 450  ,
        margin: [0,0,0,0],
        padding: [0,0,0,0],
        type: 'category',
        labels: { 
          x: -10, 
          useHTML: true,
        },
        gridLineWidth: this.props.grid,
        plotLines: [{
            color: '#FF0000',
            width: 2,
            value: this.state.hignlinevalue
        },
        {
            color: '#FF0000',
            width: 2,
            value: this.state.lowerlinevalue
        }]
      },
      exporting: {
        enabled: true,
        buttons: {
          menuItems: [
            "viewFullscreen",
            "printChart",
            "separator",
            "downloadPNG",
            "downloadJPEG",
            "downloadPDF",
            "downloadSVG",
          ],
        },
      },
      series: [
        {
            type: 'line',
            name: 'Data',
            data: this.state.stockData,
            lineWidth : 0.8,   
        } 
      ]
    };
    
    this.setState({
        seriesData : options
    })
    }, 3000);
    
    $('body').on('click', '.extraIcon', function() {
        $('#exmModel').val($(this).parent().attr('id'));
        window.$('#exampleModal').modal('show');
    });
  }

//   static getDerivedStateFromProps(nextProps, prevState) {
//   if(nextProps.marker !== prevState.marker ) {
//     console.log('nextProps.marker'+ nextProps.marker + 'prevState.marker'+prevState.marker);
//   }
// }
handleMouseOver()
{
  console.log("stock data",this.state.stockData);
}

  componentDidUpdate(prevProps){

      //console.log('nextProps.marker'+ prevProps.marker + 'prevState.marker'+ this.props.marker);

          //put code of addition here 
          //console.log('series data' + JSON.stringify(this.state.seriesData['series'])); 

          // var seriesArry = this.state.seriesData['series']; 
          // var time = [this.state.initialTime + 59045738];

          // for(var i = 1; i < 10; i++) {
          //     time[i] = time[i-1] + 1500;
          // }

          // // console.log("state initialTime time " + this.state.initialTime);
          // // console.log("initial time " + time);

          // var data1 = [[time[0], 10],[time[1], 20],[time[2], 30],[time[3], 40],[time[4], 50],[time[5], 40],[time[6], 30],[time[7], 20],[time[8], 10],[time[9], 0]];
          // for(var i = 1; i < 10; i++) {
          //     time[i] = time[i-1] + 1500;
          // }

          // //console.log(data1);

          // this.setState({
          //     initialTime: this.state.initialTime + 59045738  
          // })



      /** Arrow mark (slant line) **/

      if(this.props.arrowMark){

          if(prevProps.arrowMark !== this.props.arrowMark) {

            var seriesArry = this.state.seriesData['series']; 
            var time = [this.state.initialTime + 59045738];

            for(var i = 1; i < 10; i++) {
                time[i] = time[i-1] + 1500;
            }

            //var data1 = [[time[0], 10],[time[1], 20],[time[2], 30],[time[3], 40],[time[4], 50],[time[5], 40],[time[6], 30],[time[7], 20],[time[8], 10],[time[9], 0]];
            
            var data1 = [[time[0], 10]];

            for(var i = 1; i < 10; i++) {
                time[i] = time[i-1] + 1500;
            }

            this.setState({
                initialTime: this.state.initialTime + 59045738  
            });

            seriesArry.push({
                type: 'scatter',
                cursor: 'move',
                draggableX: true,
                draggableY: true,
                dragDrop: {
                  draggableY: true,
                  draggableX: true
                },
                data: data1,
                name:'data'+this.props.count,
                pointStart: time[0],
                yAxis: 0,
                dataLabels: {
                  enabled: true,
                  useHTML: true,
                  format: "<div id='data"+this.props.count+"' class='left_arrow down_triger myDisv data"+this.props.count+"' data-id='"+this.props.count+"'><i class='extraIcon fa fa-long-arrow-down' aria-hidden='true'></i></div>",
                  y: 15
                },
                marker: {
                    enabled: false
                }
            })
         
            var optionsArray = this.state.seriesData ; 
            optionsArray['series'] = seriesArry;

            this.setState({
                seriesData : optionsArray
            });

          }

      }

      /** end arrow mark (slant line) **/


      /*** flag mark ****/
      else if(this.props.flagMark){

          if(prevProps.flagMark !== this.props.flagMark) {

            var seriesArry = this.state.seriesData['series']; 
            var time = [this.state.initialTime + 59045738];

            for(var i = 1; i < 10; i++) {
                time[i] = time[i-1] + 1500;
            }

            //var data1 = [[time[0], 10],[time[1], 20],[time[2], 30],[time[3], 40],[time[4], 50],[time[5], 40],[time[6], 30],[time[7], 20],[time[8], 10],[time[9], 0]];
            
            var data1 = [[time[0], 10]];

            for(var i = 1; i < 10; i++) {
                time[i] = time[i-1] + 1500;
            }

            this.setState({
                initialTime: this.state.initialTime + 59045738  
            });

            seriesArry.push({
                type: 'scatter',
                cursor: 'move',
                draggableX: true,
                draggableY: true,
                dragDrop: {
                  draggableY: true,
                  draggableX: true
                },
                data: data1,
                name:'data'+this.props.count,
                pointStart: time[0],
                yAxis: 0,
                dataLabels: {
                  enabled: true,
                  useHTML: true,
                  format: "<div id='data"+this.props.count+"' class=' flag_aarow myDisv data"+this.props.count+"' data-id='"+this.props.count+"' ><i class='extraIcon fa fa-flag' aria-hidden='true'></i></div>",
                  y: 15
                },
                marker: {
                    enabled: false
                }
            })
         
            var optionsArray = this.state.seriesData ; 
            optionsArray['series'] = seriesArry;

            this.setState({
                seriesData : optionsArray
            });

            //console.log('seriesData '+ this.state.seriesData);
          }

      }
      /*** end flag mark  **/


      /*** Balloon ****/
      else if(this.props.balloon){

          if(prevProps.balloon !== this.props.balloon) {

            var seriesArry = this.state.seriesData['series']; 
            var time = [this.state.initialTime + 59045738];

            for(var i = 1; i < 10; i++) {
                time[i] = time[i-1] + 1500;
            }

            //var data1 = [[time[0], 10],[time[1], 20],[time[2], 30],[time[3], 40],[time[4], 50],[time[5], 40],[time[6], 30],[time[7], 20],[time[8], 10],[time[9], 0]];
            
            var data1 = [[time[0], 10]];

            for(var i = 1; i < 10; i++) {
                time[i] = time[i-1] + 1500;
            }

            this.setState({
                initialTime: this.state.initialTime + 59045738  
            });

            seriesArry.push({
                type: 'scatter',
                cursor: 'move',
                draggableX: true,
                draggableY: true,
                dragDrop: {
                  draggableY: true,
                  draggableX: true
                },
                data: data1,
                name:'data'+this.props.count,
                pointStart: time[0],
                yAxis: 0,
                dataLabels: {
                  enabled: true,
                  useHTML: true,
                  format: "<div id='data"+this.props.count+"' class=' comment_outer myDisv data"+this.props.count+"' data-id='"+this.props.count+"' ><p class='extraIcon balloon_text'>Comment</p></div>",
                  y: 15
                },
                marker: {
                    enabled: false
                }
            })
         
            var optionsArray = this.state.seriesData ; 
            optionsArray['series'] = seriesArry;

            this.setState({
                seriesData : optionsArray
            });

            //console.log('seriesData '+ this.state.seriesData);
          }

      }
      /*** end ballon **/
      
      /**  arrow left **/
      else if(this.props.arrowLeft){

          if(prevProps.arrowLeft !== this.props.arrowLeft) {

            var seriesArry = this.state.seriesData['series']; 
            var time = [this.state.initialTime + 59045738];

            for(var i = 1; i < 10; i++) {
                time[i] = time[i-1] + 1500;
            }

            //var data1 = [[time[0], 10],[time[1], 20],[time[2], 30],[time[3], 40],[time[4], 50],[time[5], 40],[time[6], 30],[time[7], 20],[time[8], 10],[time[9], 0]];
            
            var data1 = [[time[0], 10]];

            for(var i = 1; i < 10; i++) {
                time[i] = time[i-1] + 1500;
            }

            this.setState({
                initialTime: this.state.initialTime + 59045738  
            });

            seriesArry.push({
                type: 'scatter',
                cursor: 'move',
                draggableX: true,
                draggableY: true,
                dragDrop: {
                  draggableY: true,
                  draggableX: true
                },
                data: data1,
                name:'data'+this.props.count,
                pointStart: time[0],
                yAxis: 0,
                dataLabels: {
                  enabled: true,
                  useHTML: true,
                  format: "<div id='data"+this.props.count+"' class=' left_arrow data"+this.props.count+"' ><i class='fa fa-long-arrow-left extraIcon' aria-hidden='true'></i></div> ",
                  y: 15
                },
                marker: {
                    enabled: false
                }
            })
         
            var optionsArray = this.state.seriesData ; 
            optionsArray['series'] = seriesArry;

            this.setState({
                seriesData : optionsArray
            });

            //console.log('seriesData '+ this.state.seriesData);
          }

      }
      /** end arrow left **/


      /*** Arrow up ****/
      else if(this.props.arrowUp){

          if(prevProps.arrowUp !== this.props.arrowUp) {

            var seriesArry = this.state.seriesData['series']; 
            var time = [this.state.initialTime + 59045738];

            for(var i = 1; i < 10; i++) {
                time[i] = time[i-1] + 1500;
            }

            //var data1 = [[time[0], 10],[time[1], 20],[time[2], 30],[time[3], 40],[time[4], 50],[time[5], 40],[time[6], 30],[time[7], 20],[time[8], 10],[time[9], 0]];
            
            var data1 = [[time[0], 10]];

            for(var i = 1; i < 10; i++) {
                time[i] = time[i-1] + 1500;
            }

            this.setState({
                initialTime: this.state.initialTime + 59045738  
            });

            seriesArry.push({
                type: 'scatter',
                cursor: 'move',
                draggableX: true,
                draggableY: true,
                dragDrop: {
                  draggableY: true,
                  draggableX: true
                },
                data: data1,
                name:'data'+this.props.count,
                pointStart: time[0],
                yAxis: 0,
                dataLabels: {
                  enabled: true,
                  useHTML: true,
                  format: "<div id='data"+this.props.count+"' class='left_arrow top_arrow data"+this.props.count+"' data-id='"+this.props.count+"' ><i class='fa fa-long-arrow-up extraIcon' aria-hidden='true'></i></div>",
                  y: 15
                },
                marker: {
                    enabled: false
                }
            })
         
            var optionsArray = this.state.seriesData ; 
            optionsArray['series'] = seriesArry;

            this.setState({
                seriesData : optionsArray
            });

            //console.log('seriesData '+ this.state.seriesData);
          }

      }

      /*** end arrow up **/



      /**  arrow right **/
      else if(this.props.arrowRight){

          if(prevProps.arrowRight !== this.props.arrowRight) {

           var seriesArry = this.state.seriesData['series']; 
            var time = [this.state.initialTime + 59045738];

            for(var i = 1; i < 10; i++) {
                time[i] = time[i-1] + 1500;
            }

            //var data1 = [[time[0], 10],[time[1], 20],[time[2], 30],[time[3], 40],[time[4], 50],[time[5], 40],[time[6], 30],[time[7], 20],[time[8], 10],[time[9], 0]];
            
            var data1 = [[time[0], 10]];

            for(var i = 1; i < 10; i++) {
                time[i] = time[i-1] + 1500;
            }

            this.setState({
                initialTime: this.state.initialTime + 59045738  
            });

            seriesArry.push({
                type: 'scatter',
                cursor: 'move',
                draggableX: true,
                draggableY: true,
                dragDrop: {
                  draggableY: true,
                  draggableX: true
                },
                data: data1,
                name:'data'+this.props.count,
                pointStart: time[0],
                yAxis: 0,
                dataLabels: {
                  enabled: true,
                  useHTML: true,
                  format: "<div id='data"+this.props.count+"' class=' left_arrow right_arrow data"+this.props.count+"'><i class='extraIcon fa fa-long-arrow-right' aria-hidden='true'></i></div>",
                  y: 15
                },
                marker: {
                    enabled: false
                }
            })
         
            var optionsArray = this.state.seriesData ; 
            optionsArray['series'] = seriesArry;

            this.setState({
                seriesData : optionsArray
            });

            //console.log('seriesData '+ this.state.seriesData);
          }

      }
      /**  end arrow right **/

      /**  start arrow down **/
      else if(this.props.arrowDown){

          if(prevProps.arrowDown !== this.props.arrowDown) {

            var seriesArry = this.state.seriesData['series']; 
            var time = [this.state.initialTime + 59045738];

            for(var i = 1; i < 10; i++) {
                time[i] = time[i-1] + 1500;
            }

            //var data1 = [[time[0], 10],[time[1], 20],[time[2], 30],[time[3], 40],[time[4], 50],[time[5], 40],[time[6], 30],[time[7], 20],[time[8], 10],[time[9], 0]];
            
            var data1 = [[time[0], 10]];

            for(var i = 1; i < 10; i++) {
                time[i] = time[i-1] + 1500;
            }

            this.setState({
                initialTime: this.state.initialTime + 59045738  
            });

            seriesArry.push({
                type: 'scatter',
                cursor: 'move',
                draggableX: true,
                draggableY: true,
                dragDrop: {
                  draggableY: true,
                  draggableX: true
                },
                data: data1,
                name:'data'+this.props.count,
                pointStart: time[0],
                yAxis: 0,
                dataLabels: {
                  enabled: true,
                  useHTML: true,
                  format: "<div id='data"+this.props.count+"' class=' left_arrow down_arrow data"+this.props.count+"'><i class='fa fa-long-arrow-down extraIcon' aria-hidden='true'></i></div>",
                  y: 15
                },
                marker: {
                    enabled: false
                }
            });
         
            var optionsArray = this.state.seriesData ; 
            optionsArray['series'] = seriesArry;

            this.setState({
                seriesData : optionsArray
            });

            //console.log('seriesData '+ this.state.seriesData);
          }

      }
      /**  end arrow down **/

      else if(this.props.callout){

          if(prevProps.callout !== this.props.callout) {


            console.log(' Inside calloutsss ');

            var seriesArry = this.state.seriesData['series']; 
            var time = [this.state.initialTime + 59045738];

            for(var i = 1; i < 10; i++) {
                time[i] = time[i-1] + 1500;
            }

            //var data1 = [[time[0], 10],[time[1], 20],[time[2], 30],[time[3], 40],[time[4], 50],[time[5], 40],[time[6], 30],[time[7], 20],[time[8], 10],[time[9], 0]];
            
            var data1 = [[time[0], 10]];

            for(var i = 1; i < 10; i++) {
                time[i] = time[i-1] + 1500;
            }

            this.setState({
                initialTime: this.state.initialTime + 59045738  
            });

            seriesArry.push({
                type: 'scatter',
                cursor: 'move',
                draggableX: true,
                draggableY: true,
                dragDrop: {
                  draggableY: true,
                  draggableX: true
                },
                data: data1,
                name:'data'+this.props.count,
                pointStart: time[0],
                yAxis: 0,
                dataLabels: {
                  enabled: true,
                  useHTML: true,
                  format: "<div id='data"+this.props.count+"' class=' left_arrow map_outer myDisv data"+this.props.count+"' data-id='"+this.props.count+"' ><i class='extraIcon fa fa-map-marker' aria-hidden='true'></i><div class='hover_show'><p>the readable content of a page when looking at its layout.</p></div></div>",
                  y: 15
                },
                marker: {
                    enabled: false
                }
            })
         
            var optionsArray = this.state.seriesData ; 
            optionsArray['series'] = seriesArry;

            this.setState({
                seriesData : optionsArray
            });

            console.log('seriesData - '+ JSON.stringify(this.state.seriesData));
          }

      }else{

          if (prevProps.marker !== this.props.marker) {


            var seriesArry = this.state.seriesData['series']; 
            var time = [this.state.initialTime + 59045738];

            for(var i = 1; i < 10; i++) {
                time[i] = time[i-1] + 1500;
            }

            //var data1 = [[time[0], 10],[time[1], 20],[time[2], 30],[time[3], 40],[time[4], 50],[time[5], 40],[time[6], 30],[time[7], 20],[time[8], 10],[time[9], 0]];
            
            var data1 = [[time[0], 10]];

            for(var i = 1; i < 10; i++) {
                time[i] = time[i-1] + 1500;
            }

            this.setState({
                initialTime: this.state.initialTime + 59045738  
            });

              seriesArry.push({
                  type: 'scatter',
                  dragDrop: {
                    draggableY: true,
                    draggableX: true
                  },
                  data: data1,
                  name:'data'+this.props.count,
                  pointStart: time[0],
                  yAxis: 0,
                  dataLabels: {
                    enabled: true,
                    useHTML: true,
                    format: "<div id='data"+this.props.count+"' class='myDisv data"+this.props.count+"'  data-id='"+this.props.count+"' data-toggle='modal' data-target='#exampleModal'><i class='extraIcon fa fa-solid  "+ this.props.marker +" ' ></i></div>",
                    y: 15
                  },
                  marker: {
                      enabled: false
                  }
              });

              //console.log('State pushed' + JSON.stringify(seriesArry));

              var optionsArray = this.state.seriesData ; 
              optionsArray['series'] = seriesArry;

              this.setState({
                seriesData : optionsArray
              });

            //console.log( 'State pushed --' + JSON.stringify(this.state.seriesData));
          }

      }
      
      this.drawHighLine(this.state.stockData);

  }
 

  drawHighLine(stockData){
    
    var arr = stockData;
    const groupAndSort = arr => {
     const res = [];
     const map = Object.create(null);
     Array.prototype.forEach.call(arr, item => {
        item.forEach(el => {
           if (!(el in map)) {
              map[el] = [];
              res.push(map[el]);
           };
           map[el].push(el);
        });
     });
     res.sort((a, b) => {
        return a[0] - b[0];
     });
     return res;
  };

  var alldata = groupAndSort(arr);
  var mid = parseInt(alldata.length)/2
  var high = alldata[mid-1];
  var low = alldata[0];
 
  if(this.props.showHighLowerLine==true){
    if(this.state.hignlinevalue==""){
      this.setState({hignlinevalue:high,lowerlinevalue:low})
    }
  }else{
    if(this.state.hignlinevalue!=""){
      this.setState({hignlinevalue:high,lowerlinevalue:low}) 
    }
  }
 
  }

  render() {

    (function(H) {
        function symbolWrap(proceed, symbol, x, y, w, h, options) {
          if (symbol.indexOf('text:') === 0) {
            var text = symbol.split(':')[1],
              svgElem = this.text(text, x, y)
              .attr({
                translateY: h,
                translateX: -1
              })
              .css({
                //fontFamily: 'Font Awesome 5 Free',
                fontSize: h * 4,
                'font-weight': 900
              });

            if (svgElem.renderer.isVML) {
              svgElem.fillSetter = function(value, key, element) {
                element.style.color = H.Color(value).get('rgb');
              };
            }
            return svgElem;
          }
          return proceed.apply(this, [].slice.call(arguments, 1));
        }

        H.wrap(H.SVGRenderer.prototype, 'symbol', symbolWrap);
        if (H.VMLRenderer) {
          H.wrap(H.VMLRenderer.prototype, 'symbol', symbolWrap);
        }

        // Load the font for SVG files also
        H.wrap(H.Chart.prototype, 'getSVG', function(proceed) {
          var svg = proceed.call(this);
          svg = '<?xml-stylesheet type="text/css" ' +
            'href="http://netdna.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css" ?>' +
            svg;
          return svg;
        });
    }(Highcharts));

    const { stockError, stockData , seriesData } = this.state;

    console.log('seriesData '+ typeof(this.state.seriesData));

    let rangeSelected = 1;

    if (this.props.time == "minute" || this.props.time == "hour") {
      rangeSelected = 0;
    }

    Highcharts.theme = {
      // some styling
    };


    const options2 = this.state.seriesData;

// this.setState({
//   stockLoad : true
// })



    // options['series'].push({
    //       type: 'scatter',
    //       dragDrop: {
    //         draggableY: true,
    //         draggableX: true
    //       },
    //       data: data4,
    //       name:'marker',
    //       yAxis: 0,
    //       dataLabels: {
    //           enabled: true,
    //           useHTML: true,
    //           format: '<div class="mDisv" data-toggle="modal" data-target="#exampleModal"><i class="extraIcon fa fa-solid fa-music" ></i></div>',
    //           y: 15
    //       },
    //       marker: {
    //           enabled: false
    //       }
    //     });

    // const styles = reactCSS({
    //   'default': {
    //     color: {
    //       width: '36px',
    //       height: '14px',
    //       borderRadius: '2px',
    //       background: `rgba(${ this.state.color.r }, ${ this.state.color.g }, ${ this.state.color.b }, ${ this.state.color.a })`,
    //     },
    //     swatch: {
    //       padding: '5px',
    //       background: '#fff',
    //       borderRadius: '1px',
    //       boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    //       display: 'inline-block',
    //       cursor: 'pointer',
    //     },
    //     popover: {
    //       position: 'absolute',
    //       zIndex: '2',
    //     },
    //     cover: {
    //       position: 'fixed',
    //       top: '0px',
    //       right: '0px',
    //       bottom: '0px',
    //       left: '0px',
    //     },
    //   },
    // });
    console.log('this.state.seriesData',this.state.seriesData)
    delete this.state.seriesData.plotOptions
    const stylesBorder = reactCSS({
'default': {
color: {
width: '36px',
height: '14px',
borderRadius: '2px',
background: `rgba(${ this.state.displayBorderColorPicker.r }, ${ this.state.displayBorderColorPicker.g }, ${ this.state.displayBorderColorPicker.b }, ${ this.state.displayBorderColorPicker.a })`,
},
swatch: {
padding: '5px',
background: '#fff',
borderRadius: '1px',
boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
display: 'inline-block',
cursor: 'pointer',
},
popover: {
position: 'absolute',
zIndex: '2',
},
cover: {
position: 'fixed',
top: '0px',
right: '0px',
bottom: '0px',
left: '0px',
},
},
});
const stylesBackground = reactCSS({
'default': {
color: {
width: '36px',
height: '14px',
borderRadius: '2px',
background: `rgba(${ this.state.displayBackgroundColorPicker.r }, ${ this.state.displayBackgroundColorPicker.g }, ${ this.state.displayBackgroundColorPicker.b }, ${ this.state.displayBackgroundColorPicker.a })`,
},
swatch: {
padding: '5px',
background: '#fff',
borderRadius: '1px',
boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
display: 'inline-block',
cursor: 'pointer',
},
popover: {
position: 'absolute',
zIndex: '2',
},
cover: {
position: 'fixed',
top: '0px',
right: '0px',
bottom: '0px',
left: '0px',
},
},
});
const styles = reactCSS({
'default': {
color: {
width: '36px',
height: '14px',
borderRadius: '2px',
background: `rgba(${ this.state.displayColorPicker.r }, ${ this.state.displayColorPicker.g }, ${ this.state.displayColorPicker.b }, ${ this.state.displayColorPicker.a })`,
},
swatch: {
padding: '5px',
background: '#fff',
borderRadius: '1px',
boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
display: 'inline-block',
cursor: 'pointer',
},
popover: {
position: 'absolute',
zIndex: '2',
},
cover: {
position: 'fixed',
top: '0px',
right: '0px',
bottom: '0px',
left: '0px',
},
},
});

const popover = {
      position: 'absolute',
      zIndex: '2',
    }
    const cover = {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    }
    console.log("seriesdatais",this.state.seriesData);
    
    return (
      <div className="mb-4 mt-4">
        {true ? (
          <HighchartsReact
            highcharts={Highcharts}
            constructorType={"stockChart"}
            options={this.state.seriesData}
            //options={this.state.neUpdateOption}
            allowChartUpdate = { true }
            immutable = { false }
            updateArgs = { [true, true, true] }
          />
        ) : (
          stockError
        )}
 
<button type="button" class="btn btn-primary d-none" id="showModalCrossHair" data-toggle="modal" data-target="#exampleModalCrossHairModal">
    Launch demo modal
</button>


<div class="modal fade" id="exampleModalCrossHairModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      Cross Hair
      <div class="modal-body">
        <ul class="custom_bosy">
                   <li>
                      <span>
                         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28" fill="none" stroke="currentColor">
                            <path stroke-linecap="round" d="M15.5 18.5h6m-3 3v-6"></path>
                            <rect width="6" height="6" rx="1.5" x="6.5" y="6.5"></rect>
                            <rect width="6" height="6" rx="1.5" x="15.5" y="6.5"></rect>
                            <rect width="6" height="6" rx="1.5" x="6.5" y="15.5"></rect>
                         </svg>
                      </span>
                   </li>
                   <li class="blanku"></li>
                   <li>
                      <select onChange={this.handleCrossWidthChange}>
                         <option value="2">2 px</option>
                         <option value="3">3 px</option>
                         <option value="4">4 px</option>
                         <option value="5">5 px</option>
                         <option value="6">6 px</option>
                         <option value="7">7 px</option>
                         <option value="8">8 px </option>
                         <option value="9">9 px</option>
                         <option value="10">10 px</option>
                      </select>
                   </li>
                   <li class="blanku"></li>
                    <li class="blanku">
                        
                        <span className="line_span"></span>
                    </li>
                   
                   <li onClick={ this.handleDelete } ><span class="icon-9pA37sIi"><i class="fa fa-trash" aria-hidden="true"></i></span></li>
                </ul>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>



<button type="button" class="btn btn-primary d-none" id="showModal" data-toggle="modal" data-target="#exampleModal12">
    Launch demo modal
</button>


<div class="modal fade" id="exampleModal12" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      
      <div class="modal-body">
        <ul class="custom_bosy">
                   <li>
                      <span>
                         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28" fill="none" stroke="currentColor">
                            <path stroke-linecap="round" d="M15.5 18.5h6m-3 3v-6"></path>
                            <rect width="6" height="6" rx="1.5" x="6.5" y="6.5"></rect>
                            <rect width="6" height="6" rx="1.5" x="15.5" y="6.5"></rect>
                            <rect width="6" height="6" rx="1.5" x="6.5" y="15.5"></rect>
                         </svg>
                      </span>
                   </li>
                   <li class="blanku"></li>
                   <li>
                      <select onChange={this.handleWidthChange}>
                         <option value="2">2 px</option>
                         <option value="3">3 px</option>
                         <option value="4">4 px</option>
                         <option value="5">5 px</option>
                         <option value="6">6 px</option>
                         <option value="7">7 px</option>
                         <option value="8">8 px </option>
                         <option value="9">9 px</option>
                         <option value="10">10 px</option>
                      </select>
                   </li>
                   <li class="blanku"></li>
                    <li class="blanku">
                        <div>
                            <button onClick={ this.handleTrendClick }>Pick Color</button>
                            <div style={ popover }>
                                <div style={ cover } onClick={ this.handleTrendClose }/>
                                <ChromePicker />
                            </div>
                        </div>
                        <span className="line_span"></span>
                    </li>
                   
                   <li onClick={ this.handleDelete } ><span class="icon-9pA37sIi"><i class="fa fa-trash" aria-hidden="true"></i></span></li>
                </ul>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>


<div class="modal" id="exampleModal" >
<div class="modal-dialog">
<div class="modal-content">
<div class="modal-body">
<input type="hidden"  id="exmModel" />

<input type="hidden" id="isCallout" value="0" />


<ul class="custom_bosy">
   <li>
      <span>
         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28" fill="none" stroke="currentColor">
            <path stroke-linecap="round" d="M15.5 18.5h6m-3 3v-6"></path>
            <rect width="6" height="6" rx="1.5" x="6.5" y="6.5"></rect>
            <rect width="6" height="6" rx="1.5" x="15.5" y="6.5"></rect>
            <rect width="6" height="6" rx="1.5" x="6.5" y="15.5"></rect>
         </svg>
      </span>
   </li>
   <li class="blanku"></li>
   <li>
      <select  onChange={this.handleFontChange}>
         <option value="10">10</option>
         <option value="14">14</option>
         <option value="18">18</option>
         <option value="20">20</option>
         <option value="24">24</option>
         <option value="28">28</option>
         <option value="30">30</option>
         <option value="32">32</option>
         <option value="34">34</option>
         <option value="36">36</option>
         <option value="40">40</option>
      </select>
   </li>
   <li class="blanku"></li>
   <li class="blanku">
      <div style={ styles.swatch } onClick={ this.handleClick }>
      <div style={ styles.color } />
      </div>
      { this.state.displayColorPicker ? 
      <div style={ styles.popover }>
      <div style={ styles.cover } onClick={ this.handleClose }/>
         <SketchPicker color={ this.state.color } onChange={ this.handleChange } />
      </div>
      : null }
      <span className="line_span"></span>
   </li>
   <li>
      <svg data-toggle="modal" data-target="#myModal901" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28" fill="none">
         <path fill="currentcolor" fill-rule="evenodd" clip-rule="evenodd" d="M13 5.5c0-.28.22-.5.5-.5h1c.28 0 .5.22.5.5V7.05l.4.09c.9.18 1.73.53 2.46 1.02l.34.23.29-.3.81-.8c.2-.2.52-.2.71 0l.7.7.36-.35-.35.35c.2.2.2.51 0 .7l-.82.82-.29.29.23.34c.49.73.84 1.57 1.02 2.46l.08.4H22.5c.28 0 .5.22.5.5v1a.5.5 0 0 1-.5.5H20.95l-.09.4c-.18.9-.53 1.73-1.02 2.46l-.23.34.3.29.8.81c.2.2.2.52 0 .71l-.7.7a.5.5 0 0 1-.7 0l-.82-.8-.29-.3-.34.23c-.73.49-1.57.84-2.46 1.02l-.4.08V22.5a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5V20.95l-.4-.09a6.96 6.96 0 0 1-2.46-1.02l-.34-.23-.29.3-.81.8.35.36-.35-.35a.5.5 0 0 1-.71 0l-.7-.71a.5.5 0 0 1 0-.7l-.36-.36.35.35.82-.81.29-.29-.23-.34a6.96 6.96 0 0 1-1.02-2.46l-.08-.4H5.5a.5.5 0 0 1-.5-.5v-1c0-.28.22-.5.5-.5H7.05l.09-.4c.18-.9.53-1.73 1.02-2.46l.23-.34-.3-.29-.8-.81a.5.5 0 0 1 0-.71l.7-.7c.2-.2.51-.2.7 0l.82.8.29.3.34-.23a6.96 6.96 0 0 1 2.46-1.02l.4-.08V5.5zm.5-1.5c-.83 0-1.5.67-1.5 1.5v.75c-.73.2-1.43.48-2.06.86l-.54-.53a1.5 1.5 0 0 0-2.12 0l-.7.7a1.5 1.5 0 0 0 0 2.12l.53.54A7.95 7.95 0 0 0 6.25 12H5.5c-.83 0-1.5.67-1.5 1.5v1c0 .83.67 1.5 1.5 1.5h.75c.2.73.48 1.43.86 2.06l-.53.54a1.5 1.5 0 0 0 0 2.12l.7.7a1.5 1.5 0 0 0 2.12 0l.54-.53c.63.38 1.33.67 2.06.86v.75c0 .83.67 1.5 1.5 1.5h1c.83 0 1.5-.67 1.5-1.5v-.75a7.95 7.95 0 0 0 2.06-.86l.54.53a1.5 1.5 0 0 0 2.12 0l.7-.7a1.5 1.5 0 0 0 0-2.12l-.53-.54c.38-.63.67-1.33.86-2.06h.75c.83 0 1.5-.67 1.5-1.5v-1c0-.83-.67-1.5-1.5-1.5h-.75a7.95 7.95 0 0 0-.86-2.06l.53-.54a1.5 1.5 0 0 0 0-2.12l-.7-.7a1.5 1.5 0 0 0-2.12 0l-.54.53A7.95 7.95 0 0 0 16 6.25V5.5c0-.83-.67-1.5-1.5-1.5h-1zM12 14a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm2-3a3 3 0 1 0 0 6 3 3 0 0 0 0-6z"></path>
      </svg>
   </li>
   { this.state.lock ? 
   <li onClick={ this.handleUnlock } ><span class="icon-9pA37sIi"><i class="lockk fa-solid fa-lock"></i></span></li>
   :
   <li onClick={ this.handleLock } ><span class="icon-9pA37sIi"><i class="unlock fa fa-unlock" aria-hidden="true"></i></span></li>
   }
   <li onClick={ this.handleDelete } ><span class="icon-9pA37sIi"><i class="fa fa-trash" aria-hidden="true"></i></span></li>
</ul>
<div class="modal fade" id="myModal901" role="dialog">
<div class="modal-dialog">
   <input type="hidden" id="type" />
   <div class="modal-content">
      <div class="modal-header custom_head">
         <h5>
            Text
            <span class="editIcon-a798UFpk" data-name="edit" data-role="button">
               <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="none">
                  <path fill="currentColor" fill-rule="evenodd" clip-rule="evenodd" d="M17.086 6.207a2 2 0 0 1 2.828 0l1.879 1.879a2 2 0 0 1 0 2.828l-.94.94-9 9-1 1-.146.146H6v-4.707l.146-.146 1-1 9-9 .94-.94zm2.121.707a1 1 0 0 0-1.414 0l-.586.586 1.647 1.646 1.646 1.647.586-.586a1 1 0 0 0 0-1.414l-1.879-1.879zm.586 4.586L18.5 10.207 10.207 18.5l1.293 1.293 8.293-8.293zm-9 9l-1.647-1.646L7.5 17.207l-.5.5V21h3.293l.5-.5zm-2.586-4L9.5 17.793 17.793 9.5 16.5 8.207 8.207 16.5z"></path>
               </svg>
            </span>
         </h5>
         <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body custm_uy">
         <ul class="nav nav-tabs">
            <li class="nav-item">
               <a class="nav-link active" data-toggle="tab" href="#menu1">Text</a>
            </li>
            <li class="nav-item">
               <a class="nav-link" data-toggle="tab" href="#menu2">Visibility</a>
            </li>
         </ul>
         <div class="tab-content">
            <div id="menu1" class="container tab-pane active">
               <div class="text_ouer">
                  <div class="lit_text">
                     <div style={ styles.swatch } onClick={ this.handleClick }>
                        <div style={ styles.color } />
                        </div>
                        { this.state.displayColorPicker ? 
                        <div style={ styles.popover }>
                           <div style={ styles.cover } onClick={ this.handleClose }/>
                              <SketchPicker color={ this.state.color } onChange={ this.handleChange } />
                           </div>
                           : null }
                           <select  onChange={this.handleFontChange}>
                              <option value="10">10</option>
                              <option value="14">14</option>
                              <option value="18">18</option>
                              <option value="20">20</option>
                              <option value="24">24</option>
                              <option value="28">28</option>
                              <option value="30">30</option>
                              <option value="32">32</option>
                              <option value="34">34</option>
                              <option value="36">36</option>
                              <option value="40">40</option>
                           </select>
                           <span class="blod" onClick={ this.makeTextBold }>B</span>
                           <span class="ite" onClick={ this.makeTextItalic }>I</span>  
                        </div>
                        <div class="text_area" onKeyDown={ this.handleTextChange } >
                           <textarea>
                           Text
                           </textarea>
                        </div>
                        <div class="background_inner">
                           <span class="inpuy6">
                           <input type="checkbox"></input>Background
                           </span>
                           <div style={ stylesBackground.swatch } onClick={ this.handleBackgroundClick }>
                              <div style={ stylesBackground.color } />
                              </div>
                              { this.state.displayBackgroundColorPicker ? 
                              <div style={ stylesBackground.popover }>
                                 <div style={ stylesBackground.cover } onClick={ this.handleBackgroundClose }/>
                                    <SketchPicker color={ this.state.colorBackground } onChange={ this.handleBackgroundColor } />
                                 </div>
                                 : null }
                              </div>
                              <div class="border_inner">
                                 <span class="inpuy6">
                                 <input type="checkbox"></input>
                                 Border</span><span class="bachy67"></span>
                                 <div style={ stylesBorder.swatch } onClick={ this.handleBorderClick }>
                                    <div style={ stylesBorder.color } />
                                    </div>
                                    { this.state.displayBorderColorPicker ? 
                                    <div style={ stylesBorder.popover }>
                                       <div style={ stylesBorder.cover } onClick={ this.handleBorderClose }/>
                                          <SketchPicker color={ this.state.colorBorder } onChange={ this.handleBorderColor } />
                                       </div>
                                       : null }
                                    </div>
                                 </div>
                              </div>
                              <div id="menu2" class="container tab-pane">
                                 <div class="scr6">
                                    <div class="range_ist">
                                       <input class="check8" type="checkbox" /><span>Second</span>
                                       <input type="number" class="number_outer" />
                                       <input type="range" class="form-range" id="customRange1" />
                                       <input type="number" class="number_outer afetr4" />
                                    </div>
                                    <div class="range_ist">
                                       <input class="check8" type="checkbox" /><span>Minutes</span>
                                       <input type="number" class="number_outer" />
                                       <input type="range" class="form-range" id="customRange1" />
                                       <input type="number" class="number_outer afetr4" />
                                    </div>
                                    <div class="range_ist">
                                       <input class="check8" type="checkbox" /><span>Hours</span>
                                       <input type="number" class="number_outer" />
                                       <input type="range" class="form-range" id="customRange1" />
                                       <input type="number" class="number_outer afetr4" />
                                    </div>
                                    <div class="range_ist">
                                       <input class="check8" type="checkbox" /><span>Days</span>
                                       <input type="number" class="number_outer" />
                                       <input type="range" class="form-range" id="customRange1" />
                                       <input type="number" class="number_outer afetr4" />
                                    </div>
                                    <div class="range_ist">
                                       <input class="check8" type="checkbox" /><span>Weeks</span>
                                       <input type="number" class="number_outer" />
                                       <input type="range" class="form-range" id="customRange1" />
                                       <input type="number" class="number_outer afetr4" />
                                    </div>
                                    <div class="range_ist">
                                       <input class="check8" type="checkbox" /><span>Months</span>
                                       <input type="number" class="number_outer" />
                                       <input type="range" class="form-range" id="customRange1" />
                                       <input type="number" class="number_outer afetr4" />
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <hr>
                           </hr>
                           <div class="bootom_outer">
                              <select>
                                 <option>Template</option>
                              </select>
                              <div class="ok_ight">
                                 <button class="custom_cancel" data-dismiss="modal">Cancel</button>
                                 <button class="custom_ok" data-dismiss="modal">ok</button>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
    );
  }
}

const mapStateToProps = (state) => ({
  stockValue: state.cardItems,
});

const mapDispatchToProps = (dispatch) => ({
  getStockValue: (symbol, stock, time, mult, startd, endd) =>
    dispatch(getStockValue(symbol, stock, time, mult, startd, endd)),
});
export default connect(mapStateToProps, mapDispatchToProps)(LineChart);
