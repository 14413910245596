import React, { Component } from "react";
import Footer from "../layout/footer/Footer.component";
import Header from "../layout/header/Header.component";
import './Privacy.component.css'
import { Link } from "react-router-dom";

let imgPath="../../../assests/"
export default class PrivacyPage extends Component {
    render() {
        return (
            <div>
          <header>
            <div className="custom_container">
              <div className="row">
                <div className="col-md-12">
                  <Header />
                </div>
              </div>
            </div>
          </header>

          <section className="banner_outer">
            <div className="overlay"></div>
                <div className="custom_container">
                <div className="row" >
                    <div className="col-md-8 offset-md-2 col-sm-12 first_div text-center">
                      <div class="banner_content">
                        <h2>Privacy Policy</h2>
                         <hr class="banner_hr news_hr" /><h3>
                         <ul className="news_ul">
                           <li><Link to={"/home"} >Home</Link></li>
                           <li><i class="fal fa-angle-right"></i></li>
                           <li><Link to={"/news"} className="active">Privacy Policy</Link></li>
                         </ul>
                        </h3>
                      </div>
                    </div>
                </div>
            </div>
          </section>
          <section>
            <div className="custom_container">
                <div className="row" >
                    <div className="col-md-8 offset-md-2 col-sm-12 second_div text-center">
                        <p className="p_text newS_p">This policy applies to our web portal, mobile apps, and any other tools hosted by Finmax Financial from here on referred to as 'company,' 'website, 'us' or 'this portal.'</p>
                    </div>
                </div>
            </div>
          </section>
          <section className="most_polupar">
            <div className="custom_container">
                    <div className="row">
                    <div className="col-md-8 offset-md-2 col-sm-12 second_div text-center">
                        <h3 className="title_main">
                        Disclaimer
                        </h3>
                        <p className="p_text">Finmax Financial reserves the right to alter, delete or add any or all of the clauses in this privacy policy.  By using this website, you are hereby accepting the policy and all its updates in the future. To keep yourself abreast of the changes, kindly keep reviewing this page from time to time.</p>
                        <div className="policy_update">This policy was last updated on 13th March 2021</div>
                    </div>
                </div>
            </div>
          </section>

          <section className="user_information_outer">
            <div className="custom_container">
                    <div className="row">
                    <div className="col-md-12 second_div text-left">
                        <h3 className="title_main user_info">
                        User <b>Information</b>
                        </h3>
                        <p className="p_text">Finmax Financial reserves the right to alter, delete or add any or all of the clauses in this privacy policy.  By using this website, you are hereby accepting the policy and all its updates in the future. To keep yourself abreast of the changes, kindly keep reviewing this page from time to time.</p>
                        <p className="p_text"><b>Some of the information collected when you use our services includes:</b></p>
                        <ul className="info_span">
                          <li><span className="dot_span"></span><span className="privcay_content_span">Name, age, gender, and other identification details.</span></li>
                          <li><span className="dot_span"></span><span className="privcay_content_span">Contact information.</span></li>
                          <li><span className="dot_span"></span><span className="privcay_content_span">Location information: you have the option to disable location services on your device.</span></li>
                          <li><span className="dot_span"></span><span className="privcay_content_span">Any other personal information that you add when using any of our services includes networking, community discussions, contact forms, trading account connection, etc.</span></li>
                          <li><span className="dot_span"></span><span className="privcay_content_span">Access info includes Device ID or unique identifier, device type, unique device token, etc.</span></li>
                          <li><span className="dot_span"></span><span className="privcay_content_span">Computer statistics like traffic to and from the sites, referral URL, ad data, your IP address, your browsing history, and your weblog are also collected.</span></li>
                        </ul>
                    </div>
                </div>
            </div>
          </section>


          <section className="most_polupar user_information_outer">
            <div className="custom_container">
                    <div className="row">
                    <div className="col-md-12 second_div text-left">
                        <h3 className="title_main user_info">
                        Cookies <b>Policy</b>
                        </h3>
                        <p className="p_text">We work with Google Analytics using cookies to analyze your use of our website. Data gathered like browser type/version, operating system used, referrer URL, hostname of the accessing computer, etc., is stored on Google's server.</p>
                        <ul className="info_span">
                          <li><span className="dot_span"></span><span className="privcay_content_span">Traffic log cookies are used to track the pages you visit and tailor our services for your optimal experience.
</span></li>
                          <li><span className="dot_span"></span><span className="privcay_content_span">Data is removed after analysis.</span></li>
                          <li><span className="dot_span"></span><span className="privcay_content_span">You can choose to decline cookies, but it may prevent you from accessing our website's full features.</span></li>
                        </ul>
                        <p className="p_text"><b>Cookies data is gathered for the following purposes:</b></p>
                        <ul className="info_span">
                          <li><span className="dot_span"></span><span className="privcay_content_span">Improved customer service.</span></li>
                          <li><span className="dot_span"></span><span className="privcay_content_span">Promotions about new products, special offers, etc.</span></li>
                          <li><span className="dot_span"></span><span className="privcay_content_span">Personalized advertising and marketing.</span></li>
                          <li><span className="dot_span"></span><span className="privcay_content_span">Identifying and preventing fraudulent or illegal logins or activities.</span></li>
                        </ul>
                    </div>
                </div>
            </div>
          </section>

          <section className="user_information_outer">
            <div className="custom_container">
                    <div className="row">
                    <div className="col-md-12 second_div text-left">
                        <h3 className="title_main user_info">
                        User Rights <b>to Data </b>
                        </h3>
                        <p className="p_text"><b>With regards to personal data collected, you can: </b></p>
                        <ul className="info_span">
                          <li><span className="dot_span"></span><span className="privcay_content_span">Access your information.</span></li>
                          <li><span className="dot_span"></span><span className="privcay_content_span">Request to delete or correct.</span></li>
                          <li><span className="dot_span"></span><span className="privcay_content_span">Restrict the processing of information.</span></li>
                          <li><span className="dot_span"></span><span className="privcay_content_span">Withdraw your permission for consent-based processing of your personal data without affecting the lawfulness of processing based on consent before your withdrawal.</span></li>
                        </ul>
                        <p className="p_text">You may exercise any of the above-mentioned legal rights by contacting us.</p>
                    </div>
                </div>
            </div>
          </section>

          <section className="most_polupar user_information_outer">
            <div className="custom_container">
                    <div className="row">
                    <div className="col-md-12 second_div text-left">
                        <h3 className="title_main user_info">
                        Information <b>Sharing</b> 
                        </h3>
                        <p className="p_text">Your personal information may be shared within Finmax products or to some third party if required to provide certain services, fulfill legal obligations, marketing, and advertising activities, or detect fraud. </p>
                        <ul className="info_span">
                          <li><span className="dot_span"></span><span className="privcay_content_span">No information is sold or shared with third parties for their marketing purposes without your prior consent.
</span></li>
                          <li><span className="dot_span"></span><span className="privcay_content_span">In case of a merger or acquisition, we may transfer or assign the information we have collected from you to the involved parties. </span></li>
                          <li><span className="dot_span"></span><span className="privcay_content_span">If you visit another website linked to our website, please note that we cannot take responsibility for protecting your data visiting another site. You must read their privacy statements before using them. </span></li>
                        </ul>
                    </div>
                </div>
            </div>
          </section>

          <section className="user_information_outer">
            <div className="custom_container">
                    <div className="row">
                    <div className="col-md-12 second_div text-left">
                        <h3 className="title_main user_info">
                         Data <b>Expiry</b>
                        </h3>
                        <p className="p_text">The user data we gather is retained and stored on our servers for as long as we deem necessary to provide services that you use; or for certain other purposes, e.g., legal compliances, conflict resolutions, and policy enforcement.</p>
                    </div>
                </div>
            </div>
          </section>

          <section className="most_polupar user_information_outer">
            <div className="custom_container">
                    <div className="row">
                    <div className="col-md-12 second_div text-left">
                        <h3 className="title_main user_info">
                        Data <b>Protection</b> 
                        </h3>
                        <p className="p_text">Finmax Financial does its best to protect your data and personal information.</p>
                        <ul className="info_span">
                          <li><span className="dot_span"></span><span className="privcay_content_span">We use secure server software (SSL) and multiple firewalls to protect user information from unauthorized access, discovery, modification, or deletion. 
</span></li>
                          <li><span className="dot_span"></span><span className="privcay_content_span">Your personal information that is not 'public' can only be accessed by Finmax employees or third-parties on a "need to know" basis. </span></li>
                          <li><span className="dot_span"></span><span className="privcay_content_span">We adhere to all the industry standards and best practices to protect the personal information we have received. </span></li>
                        </ul>
                        <p className="p_text">Please be aware that there is no single transmission method or electronic storage of data that can guarantee 100% security. Hence, even though we use best practices and state-of-the-art means to protect your user data, we cannot guarantee its absolute security.</p>
                    </div>
                </div>
            </div>
          </section>
      
          <footer>
            <Footer />       
          </footer>
            </div>
        )
    }
}
