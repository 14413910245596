import React, { Component } from "react";
import FacebookLogin from 'react-facebook-login';
import './ChangePassword.component.css';
import Header from "../layout/header/Header.component";

import { render } from 'react-dom';
import { BrowserRouter as Router, Route, Routes, useSearchParams} from 'react-router-dom'
import Constants from "../../../constants/constants";
import axios from 'axios';
import authService from "../../../services/auth.service";
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input'
import CheckButton from "react-validation/build/button";
import { ToastContainer, toast } from 'react-toastify';
import { isEmail } from "validator";

require('dotenv').config()

export default class ChangePassword extends Component {
  state = {
    newPassword:'',
    oldPassword:'',
    showNewPassword:false,
    showOldPassword:false,
    showConfirmPassword:false,
    confirmPassword:'',
  };
  constructor(props){
    super(props);
    this.onChangeNewPassword=this.onChangeNewPassword.bind(this);
    this.onChangeOldPassword=this.onChangeOldPassword.bind(this)
    this.onChangeConfirmPassword=this.onChangeConfirmPassword.bind(this)
    this.handleShowNewPassword=this.handleShowNewPassword.bind(this)
    this.handleShowOldPassword=this.handleShowOldPassword.bind(this)
    this.handleShowConfirmPassword=this.handleShowConfirmPassword.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.formRef = React.createRef()
   this.checkBtnRef = React.createRef()
  }
  componentDidMount(){
   
  }
  onChangeNewPassword(e){
    this.setState({newPassword:e.target.value})
  }
  onChangeOldPassword(e){
    this.setState({oldPassword:e.target.value})
  }
  onChangeConfirmPassword(e){
    this.setState({confirmPassword:e.target.value})
  }
  handleShowNewPassword(){
  
    this.setState({
      showNewPassword:!this.state.showNewPassword
    })
  }
  handleShowOldPassword(){
  
    this.setState({
      showOldPassword:!this.state.showOldPassword
    })
  }
  handleShowConfirmPassword(){
  
    this.setState({
      showConfirmPassword:!this.state.showConfirmPassword
    })
  }
  onSubmit(e){
    e.preventDefault();
    this.formRef.current.validateAll();
    if(this.checkBtnRef.current.context._errors.length === 0 && this.state.token !==''){
     let accessToken=localStorage.getItem('accessToken')
        let payload={
       old_password:this.state.oldPassword,
       new_password:this.state.newPassword
     }
     authService.changePassword(payload,accessToken)
     .then((response)=>{
       if(response.status===200 || response.status===201){
        toast.success("Password changed successfully",{
          autoClose:6000
        })
        localStorage.clear()
        window.location.href = `${window.location.origin}/login`
       }
      

     })
     .catch((error)=>{
      if(error.response.data.error !=='' &&  error.response.data.error!==undefined && error.response.data.error !==null){
        toast.error(error.response.data.error, {
          autoClose: 6000,
  
        })
      }
      
      if(error.response.data.detail !==undefined && error.response.data.detail !==null && error.response.data.detail !==''){
        
        
        if(error.response.data.detail ==="Invalid token."){
           
          this.setState({accessToken:null})
          toast.error(error.response.data.detail + "Please login.", {
            autoClose: 6000,
    
          })
           window.location.href = `${window.location.origin}/login`
        }
      }
     })
    }
  }
  render() {
    const checkPassword=value=>{
      if(value.toString().trim().length < 8){
        return (<p style={{ color: 'red' }}>
          Password Should contain minimum 8 characters.
        </p>
        ); 
      }else{
        var regularExpression = Constants.PASSWORD_VALIDATION_REGEX;
        let result=regularExpression.test(value)
        if(result ===false){
          return (<p style={{ color: 'red' }}>
          Password must have atleast one digit,one special character like $,@,%,#,-,_,^,!, one small letter and one capital letter.
        </p>)
        }
      }
    }
    const confirmPassword =value=>{
      if(value !== this.state.newPassword){
        return (<p style={{color:'red'}}>
          Confirm password should be same as password.
          </p>
        );
      }
    }
    const required = value => {
      if (!value) {
        return (
          <p style={{color:'red'}}>
          This field is required
          </p>
        );
      }
    };
    
    return (
      <div>

          <header>
            <div className="custom_container">
              <div className="row">
                <div className="col-md-12">
                  <Header />
                </div>
              </div>
            </div>
          </header>

          <section>
            <div className="custom_container">
              <div className="row">
                <div className="col-md-12">
                  <div className="login_outer">
                     <div className="row">
                       <div className="col-md-6 offset-md-3 col-sm-12">
                         <div className="login_inner">
                           <Form ref={this.formRef} onSubmit={this.onSubmit}>
                           <div className="sign_in_title">Change Password</div>
                           <label className="custom_label">Old Password</label>
                           <div className="password_input">
                             <div>
                               <Input className="custom_input" type={this.state.showOldPassword ? "text" :"password"} placeholder="*********" value={this.state.oldPassword} onChange={this.onChangeOldPassword} validations={[required,checkPassword]} />
                             </div>
                             {
                          this.state.showOldPassword ==false 
                          ? (<i className="fal fa-eye" onClick={this.handleShowOldPassword}></i>)
                          :(<i className="fal fa-eye-slash" onClick={this.handleShowOldPassword}></i>)
                        }
                           </div>
                           <label className="custom_label">New Password</label>
                           <div className="password_input">
                             <div>
                               <Input className="custom_input" type={this.state.showNewPassword ? "text" :"password"} placeholder="*********" value={this.state.newPassword} onChange={this.onChangeNewPassword} validations={[required,checkPassword]} />
                             </div>
                             {
                          this.state.showNewPassword ==false 
                          ? (<i className="fal fa-eye" onClick={this.handleShowNewPassword}></i>)
                          :(<i className="fal fa-eye-slash" onClick={this.handleShowNewPassword}></i>)
                        }
                           </div>
                           <label className="custom_label">Confirm Password</label>
                           <div className="password_input">
                             <div>
                               <Input className="custom_input" type={this.state.showConfirmPassword ? "text" :"password"} placeholder="*********" value={this.state.confirmPassword} onChange={this.onChangeConfirmPassword} validations={[required,confirmPassword]} />
                             </div>
                             {
                          this.state.showConfirmPassword ==false 
                          ? (<i class="fal fa-eye" onClick={this.handleShowConfirmPassword}></i>)
                          :(<i class="fal fa-eye-slash" onClick={this.handleShowConfirmPassword}></i>)
                        }
                           </div>
                           <a className="signin_btn" type="submit" onClick={this.onSubmit}>Change Password</a>
                           <CheckButton style={{ display: "none" }} ref={this.checkBtnRef} />
                           </Form>
                         </div>
                       </div>
                     </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
      </div>
    )
  }
}