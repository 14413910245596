import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getSymbolList,
  getSearchSymb,
} from "../../../services/actions/actions";
import Table from "react-bootstrap/Table";
class CompareList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pgid: 1,
      symbollist: "",
      searchlisting: "",
      loading: true,
      searchSymb: "",
      loadauto: true,
    };

    this.handleSearch = this.handleSearch.bind(this);
    this.symbolist = this.symbolist.bind(this);
  }

  componentDidMount() {
    console.log('hii')
    this.symbolist(this.props.stock, this.state.pgid);
  }

  /*get symbol */
  handleClick = (symbol, symbolname) => {
    // alert('hi')
    this.props.compSymbol(symbol, symbolname, "stock");
  };

  handleSearch = (e) => {
    this.setState(
      {
        searchSymb: e.target.value,
      },
      () => {
        if (e.target.value) {
          this.props
            .getSearchSymbol(this.props.symbol, this.state.searchSymb)
            .then(() => {
              this.setState({
                loadauto: false,
                searchlisting: this.props.searchlist
              //  symbollist: this.props.searchlist,
              });
            });
        } else {
          this.setState({ loadauto: true });
        }
      }
    );
  };

  symbolist(a, b) {
    this.props.getSymbolListHandler(a, b).then(() => {
      console.log(this.state.symbollist)
      console.log('this.state.symbollist')
      this.setState({
        symbollist: [...this.state.symbollist, this.props.symbolList],
        loadauto: true,
      });
    });
  }

  /*scroll movement*/
  handleScroll = (e) => {
    if (this.state.loadauto) {
      const bottom =
        e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
      if (bottom && this.props.page > this.state.pgid) {
        this.setState(
          {
            pgid: this.state.pgid + 1,
            loading: true,
          },
          () => {
            this.symbolist(this.props.stock, this.state.pgid);
          }
        );
      } else {
        this.setState({ loading: false });
      }
    }
  };
  handlChange = (event) => {
        // this.props.parentCallback(event.target.myname.value);
        // event.preventDefault();
  }
  render() {
    const { loadauto, symbollist, searchlisting, loading, searchSymb } =
      this.state;

    if (loadauto) {
      //console.log('symbollist',symbollist.length)
      if(symbollist?.length > 0 && symbollist[0] !== ''){
        //alert('1');
       // console.log('symbollist_1',symbollist)
      var rows = 
        symbollist &&
        symbollist.map((symbol, i) =>
          symbollist[i].map((symbol) => (
            <tr key={symbol.symbol}>
              <td
                onClick={this.handleClick.bind(
                  this,
                  symbol.symbol,
                  symbol.name
                )}
              >
                {symbol.symbol}
              </td>
              <td>{symbol.name}</td>
            </tr>
          ))
        );
      }else{
        //alert('2');
        console.log('symbollist_2',symbollist)
      }
    } else {
      var rows =  searchlisting && searchlisting.map((item) => (
        <tr key={item.symbol}>
          {this.props.stock === "us-stock" ? (
            <>
              <td
                onClick={this.handleClick.bind(
                  this,
                  item.symbol,
                  item.description
                )}
              >
                {item.symbol}
              </td>
              <td>{item.description}</td>
            </>
          ) : (
            <>
              {this.props.stock === "forex" ? (
                <>
                  <td
                    onClick={this.handleClick.bind(
                      this,
                      item.symbol,
                      item.from + " " + item.to
                    )}
                  >
                    {item.symbol}
                  </td>
                  <td>{item.from + " " + item.to} </td>{" "}
                </>
              ) : (
                <>
                  <td
                    onClick={this.handleClick.bind(
                      this,
                      item.symbol,
                      item.name
                    )}
                  >
                    {item.symbol}
                  </td>
                  <td>{item.description??''}</td>
                </>
              )}
            </>
          )}
        </tr>
      ));
     
    }
    return (
      <div className="stocklisting" onScroll={this.handleScroll}>
        <input
          type="text"
          placeholder={"Search Symbol By Keywords"}
          className="form-control mb-4"
          name="searchSymb"
          value={searchSymb}
          onChange={this.handleSearch}
        />
        <Table stripped bordered hover size="sm">
          <thead>
            <tr>
              <th width="70">Symbol</th>
              <th width="170">Description</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
        {loading && loadauto ? (
          <p className="text-center">Loading..</p>
        ) : loadauto ? (
          <p className="text-center">Loading...</p>
        ) : (
          <p className="text-center">Not found Please try another keyword.</p>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  symbolList: state.cardItems.symbolList.symbols,
  page: state.cardItems.symbolList.totalPage,
  searchlist: state.cardItems.searchsymb,
});

const mapDispatchToProps = (dispatch) => ({
  getSymbolListHandler: (stock, pgid) => dispatch(getSymbolList(stock, pgid)),
  getSearchSymbol: (stock, search) => dispatch(getSearchSymb(stock, search)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CompareList);
