import React, {Component} from "react";

import logoImg from '../../../../assests/FinMax-Financial.png';
import logodark from "../../../../assests/darkenlogo.png";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import loading from '../../../../assests/dotted.gif'
import avatar from '../../../../assests/avtar.a29af747cd3ca7673cad.png';
import constants from "../../../../constants/constants";
const mainUrl = constants.APP_URL;
export default class Footer extends Component{

    constructor(props) {
        super(props);
        this.state = {
            innerContent: "",
            category:'',
            moreData:'',
            cateegorry:'',
            showResults:false,
            topCategory:[],
            html:'',
            typeQuestion:false,
            questions:[],
            autosuggest:'',
            questionsId:[],
            input_value:"",
            ip:''
        }

        if (localStorage.getItem("name") === null) {
            var colors = [];
            localStorage.setItem('name', JSON.stringify(colors))
        }
        
    }

    getAnswers = (e) => {
        alert("h");
        var html =  this.state.html+'<br>';
        html +=  e.target.getAttribute("data-answer");
        this.setState({html : html });
        var objDiv = document.getElementById("chat_messages");
        objDiv.scrollTop = objDiv.scrollHeight;

    }

    handleQuestionsClick = (e) => {
        var html = this.state.html ; 

        document.getElementById('typeQuestion').style.display = "none";

        html += '<div class="chat_second">'+e.target.innerHTML+'<img src="https://stock-trading-backend.itechnolabs.tech/static/images/blank-profile-picture-973460_1280.png"></div>';

        html += '<div class="message_first message_second"><div class="message_first_left "><p>'+e.target.getAttribute("data-answer")+ '</p></div><img src="'+avatar+'"></div>';

        localStorage.setItem('question' , this.state.questionsId[e.target.innerHTML]);

        localStorage.setItem('answer' , e.target.getAttribute("data-answer"));

        this.setState({
            html : html
        });

        this.setState({
            typeQuestion : false
        });

        this.setState({
            autosuggest : ''
        });

        let accessToekn = localStorage.getItem('accessToken');
        var authData = 'Token '+accessToekn;

        axios
          .post(`https://stock-trading-backend.itechnolabs.tech/bot-user/save-message/`+localStorage.getItem('category')+`/`+localStorage.getItem('subcategory')+`/`+localStorage.getItem('question')+`/`, { answer: localStorage.getItem('answer') , ip :this.state.ip },{headers: {
               "Content-Type": "application/json",
               Authorization: authData
            }})
          .then((response) => {
                localStorage.removeItem('category');
                localStorage.removeItem('subcategory');
                localStorage.removeItem('question');
                localStorage.removeItem('answer');
        })
        .catch((error) => {
            console.log("error", error);
        });
       this.setState({
        input_value:''
       })
           
        var objDiv = document.getElementById("chat_messages");
        objDiv.scrollTop = objDiv.scrollHeight;
    }

    handleSubQuestionClick = (e) => {

        this.setState({
            typeQuestion : true
        });

       
        localStorage.setItem('subcategory', e.target.getAttribute("data-id"));

        var html =  this.state.html +'<div class="chat_second">'+e.target.innerHTML+'<img src="https://stock-trading-backend.itechnolabs.tech/static/images/blank-profile-picture-973460_1280.png"></div>';

        html +=  '<br><div id="typeQuestion">Please type your Question </div><br>';

        this.setState({html : html});

       var questionAnswer = new Object();
       var questionsId = new Object();

        axios
          .get(`https://stock-trading-backend.itechnolabs.tech/chat/question/`+e.target.getAttribute("data-mainid")+`/`+e.target.getAttribute("data-id")+`/`)
          .then((response) => {
            response.data.questions.forEach((item, i) => {
                questionAnswer[item.question] = item.answer;
                questionsId[item.question] = item.id;
            });

            this.setState({
                questions : questionAnswer,
                questionsId : questionsId
            })

           var autosuggests = '';

            Object.keys(this.state.questions).forEach((item, i) => {
               // autosuggests += '<button type="button" class="btn btn-dark getAnswer"  data-answer="'+this.state.questions[item]+'" >'+item+'</button>';   
            });

            var items = document.getElementsByClassName('getAnswer');
            for (var i = 0; i < items.length; i++) {
                items[i].addEventListener('click', this.handleQuestionsClick);
            }

            this.setState({
                autosuggest : autosuggests
            })

        })
        .catch((error) => {
            
        });
        var objDiv = document.getElementById("chat_messages");
        objDiv.scrollTop = objDiv.scrollHeight;
    };

    handleSubClick = (e) => {

        var html =  this.state.html +'<br><div class="message_first_left">Please select sub category<br>';

        axios
          .get(`https://stock-trading-backend.itechnolabs.tech/chat/subcategory/`+e.target.getAttribute("data-id")+`/`)
          .then((response) => {
            response.data.categories.forEach((item, i) => {
                html += '<button type="button" class="btn btn-dark dgdfgdgd" data-mainid='+e.target.getAttribute("data-id")+' data-id='+item.id+' >'+item.name+'</button>&nbsp';   
            });

            html += '</div>';

            this.setState({html : html});

            var items = document.getElementsByClassName('dgdfgdgd');
            for (var i = 0; i < items.length; i++) {
                items[i].addEventListener('click', this.handleSubQuestionClick);
            }

        })
        .catch((error) => {
            console.log("error", error);
        });
    };


    handleClick = (e) => {

        var html =  this.state.html +'<div class="chat_second">'+e.target.innerHTML+'<img src="https://stock-trading-backend.itechnolabs.tech/static/images/blank-profile-picture-973460_1280.png"></div>';

        html +=  '<div class="message_first message_second"><div class="message_first_left "><p>Please select category</p>';
        html += '<div class="buttons_div">';
        axios
          .get(`https://stock-trading-backend.itechnolabs.tech/chat/subcategory/`+e.target.getAttribute("data-id")+`/`)
          .then((response) => {

            response.data.categories.forEach((item, i) => {
                html += '<button type="button" class="btn btn-dark subquescategory" data-mainid='+e.target.getAttribute("data-id")+' data-id='+item.id+' >'+item.name+'</button>&nbsp';   
            });

            html += '</div></div><img src="'+avatar+'"></div>';
            this.setState({html : html});

            var items = document.getElementsByClassName('subquescategory');

            localStorage.setItem('category' , e.target.getAttribute("data-id"));

            for (var i = 0; i < items.length; i++){
                items[i].addEventListener('click', this.handleSubQuestionClick);
            }
        })
        .catch((error) => {
            console.log("error", error);
        });

    };


    componentDidMount() {

        //document.body.classList.add('wtff');

        var html = '';


        axios.get('https://geolocation-db.com/json/').then((res) => {

            //alert(res.data.IPv4);
            this.setState({
                ip : res.data.IPv4
            });


                
        }).catch((error) => {
            console.log("error", error);
        });

        

        // setTimeout(function() {

        // axios
        //   .get(`https://stock-trading-backend.itechnolabs.tech/bot-user-conversation/get-messages/`,{ params: {ip:this.state.ip} })
        //   .then((response) => {

        //     response.data.messages.forEach((item, i) => {
        //         html += '<button type="button" class="btn btn-dark " >'+item.category__name+'</button>&nbsp';  
        //         html += '<button type="button" class="btn btn-dark " >'+item.subcategory__name+'</button>&nbsp';  
        //         html += '<button type="button" class="btn btn-dark " >'+item.question__question+'</button>&nbsp';
        //         html += item.answer;  
        //     });
            
        //     this.setState({html:html});

        // })
        // .catch((error) => {
        //     console.log("error", error);
        // });


        // }.bind(this), 5000);

        var html2 = '<div class="message_first message_second"><div class="message_first_left "><p>Hi , My name is "Botname". <br>Please select one of the following options</p><div class="buttons_div">';

        axios
          .get(`https://stock-trading-backend.itechnolabs.tech/chat/category/list/`)
          .then((response) => {

            response.data.categories.forEach((item, i) => {
                html2 += '<button type="button" class="btn btn-dark foo" data-id='+item.id+' >'+item.name+'</button>&nbsp';   
            });


            html2 += '</div></div><img src="'+avatar+'"></div>';
            
            var htm = this.state.html + html2; 

            this.setState({
                html : htm 
            });

            var items = document.getElementsByClassName('foo');
            for(var i = 0; i < items.length; i++){
                items[i].addEventListener('click', this.handleClick);
            }

        })
        .catch((error) => {
            console.log("error", error);
        });




    }

    fetchData = (value) => (e) => { 
        axios.get(`https://stock-trading-backend.itechnolabs.tech/bot-user-conversation/get-messages/`)
            .then((response) => {
                console.log(response.data);
            
        })
        .catch((error) => {
            console.log("error", error);
        });
    };

    switchFunction = (e) => {

        var x = document.getElementById("myDIV");
       
        if (window.getComputedStyle(x).display == "block") {
            document.getElementById('myDIV').style.display = "none"; 
        }else{
            document.getElementById('myDIV').style.display = "block";
        }
        
        // axios
        //     .get(`https://stock-trading-backend.itechnolabs.tech/bot-user-conversation/get-messages/`)
        //     .then((response) => {

        //         var obj = response.data.messages;
        //         console.log(' messages-- ' + response.data.messages);

        //     })
        //     .catch((error) => {
        //         console.log("error", error);
        //     });
    };

    callQuestionApi = (value) => (e) => { 

        axios
          .post(`https://stock-trading-backend.itechnolabs.tech/chat/subcategory/`+value+`/`)
          .then((response) => {
                this.setState({ category: response.data.category });
                this.setState({ innerContent: response.data.qustionlist });
                this.setState({ cateegorry: value });
                this.setState({ related: 'show' });

                document.getElementsByClassName("related").style.display = "block";
                
            })
            .catch((error) => {
                console.log("error", error);
            });
    };

  

    callCategoryQuestionApi = (value) => (e) => { 
        axios
          .post(`https://stock-trading-backend.itechnolabs.tech/user-responses/send/`, { message: value , category: this.state.category })
          .then((response) => {
              console.log(response.data.botmsg);
              var innerAllData = response.data.botmsg ;
              this.setState({ moreData: innerAllData });
          })
          .catch((error) => {
             console.log("error", error);
          });
    };

    onMessageInputChange = (e) => {
        this.setState({
            input_value:e.value
        })
        if(this.state.input_value=='' && typeof(e.key) == 'undefined'  )
        {
            alert("Please enter a message");
            return;
        }
        if (e.key === "Enter" || typeof(e.key) == 'undefined') {
            if(this.state.input_value!='')
            this.setState({ showResults: true });
        }else{

            
            let result;
            var element =  document.getElementById('autovalue');
           
            if (typeof(element) != 'undefined' && element != null){

                var autoValue = document.getElementById('autovalue').value;
                autoValue = autoValue.toLowerCase();
                var autosuggests = '';

                Object.keys(this.state.questions).forEach((item, i) => {

                    var item2 = item.toLowerCase();
                    if( item2.includes(autoValue) ){
                        autosuggests += '<button type="button" class="btn btn-dark getAnswer"  data-answer="'+this.state.questions[item]+'" >'+item+'</button>&nbsp';   
                    }
                    
                });

                this.setState({
                    autosuggest : autosuggests
                })
            }

                var parent = document.getElementById("myInputautocomplete-list");
                console.info("parent childs are",parent.childNodes.length);
                
                var items = document.getElementsByClassName('getAnswer');
           
                for (var i = 0; i < items.length; i++) {
                    items[i].addEventListener('click', this.handleQuestionsClick);
                }

           

        }
    }

    onAutoSuggest = (e) => {

        let result;

        var autoValue = document.getElementById('autovalue').value;

        autoValue = autoValue.toLowerCase();

        var autosuggests = '';

        Object.keys(this.state.questions).forEach((item, i) => {

            var item2 = item.toLowerCase();
            if( item2.includes(autoValue) ){
                autosuggests += '<button type="button" class="btn btn-dark getAnswer"  data-answer="'+this.state.questions[item]+'" >'+item+'</button>&nbsp';   
            }
            
        });

        this.setState({
            autosuggest : autosuggests
        })

        var items = document.getElementsByClassName('getAnswer');
        for (var i = 0; i < items.length; i++) {
            items[i].addEventListener('click', this.handleQuestionsClick);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.showResults == true) {
            setTimeout(function() {
                  window.location.replace(mainUrl+'support/');
            }, 5000);
        }
    }

    render(){
        return (
                       <div className="footer text-center">
                <div className="custom_container">
                    <div className="logo_area col-md-12">
                        <img className="white_them" src={logoImg} /> 
						<img className="daerk_thems" src={logodark} />
                    </div>
                    <div className="list_options">
                        <ul class="list-inline">
                            <li class="list-inline-item"><Link to={"/privacy"} >Privacy Policy</Link></li>
                            <li class="list-inline-item"><Link to={"/terms"}>Terms of User</Link></li>
                            <li class="list-inline-item"><Link to={"/contactus"} >Contact Us</Link></li>
                            <li class="list-inline-item"><Link to={"/about"}>About Us</Link></li>
                        </ul>
                    </div>
                </div>
                <hr className="footer_hr" />
                    <div className="custom_container">
                        <div className="copyright_area">
                            <p>© 2015-2021 Finmax Financial</p>
                        </div>
                    </div>
                    
                    <button style={{display:window.location.pathname=='/liverates'?'none':''}} class="chat_icon" onClick={this.switchFunction}><i class="fas fa-message"></i></button>
                <div id="myDIV" >
                    <div class="chat_header">
                        <span onClick={this.switchFunction} class="chat_dot"><i class="fa-solid fa-xmark"></i></span>
                    </div>
                    <div class="chat_body">

                        <div class="chat_messages" id="chat_messages" dangerouslySetInnerHTML={{__html: this.state.html}} ></div>
                        <div class="autocompleteQuestions" dangerouslySetInnerHTML={{__html: this.state.Questions}} ></div>

                            { this.state.showResults ? 
                                                    <div class="supportExecutive">
                                                    <img style={{height: "60px"}} class="redirectionGif" src={loading} />
                                                        <h5>Now you are being redirected to the support team .</h5>
                                                        
                                                    </div>       
                                                      : null }
                                
                    </div>


                    <div class="autocomplete">


<div id="myInputautocomplete-list" class="autocomplete-items"  dangerouslySetInnerHTML={{__html: this.state.autosuggest}}>
     
</div>
                    
                           { this.state.typeQuestion ?  <div class="input_outer"><input id="autovalue" autocomplete="off" class="type_input" onKeyDown={(e)=>this.onMessageInputChange(e)} value={this.state.input_value} type="text" placeholder="Type here..." name=""></input>
                            <a href="javascript:void(0)"  onClick={(e)=>this.onMessageInputChange(e)}><i class="fas fa-paper-plane"></i></a>
                             

                            </div> : <div class="input_outer"><input class="type_input" onKeyDown={(e)=>this.onMessageInputChange(e)} type="text" placeholder="Type here..." name="" value={this.state.input_value} ></input>
                            <a href="javascript:void(0)" onClick={(e)=>this.onMessageInputChange(e)} ><i class="fas fa-paper-plane"></i></a></div> }
                    

                        </div>

                    </div>
            </div>
	

        )
    }
}
