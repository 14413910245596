import React, { Component } from "react";
import Footer from "../layout/footer/Footer.component";
import Header from "../layout/header/Header.component";
import './Chat.component.css'
import Explore from '../../../assests/explore.jpg';
import analyticsImg from '../../../assests/advanced-analytics.jpg';
import accountImg from '../../../assests/account_statement.jpg';
import knowledgeImg from '../../../assests/knowledge.jpg'
import learnImg from '../../../assests/value-stocks.jpg'
import { Redirect, Route , Link} from 'react-router-dom';

let imgPath="../../../assests/"
export default class ChatPage extends Component {
    render() {
        return (
            <div>
          <header>
            <div className="custom_container">
              <div className="row">
                <div className="col-md-12">
                  <Header />
                </div>
              </div>
            </div>
          </header>

          <section className="banner_outer news_banner">
            <div className="overlay"></div>
                <div className="custom_container">
                <div className="row" >
                    <div className="col-md-8 offset-md-2 col-sm-12 first_div text-center">
                      <div class="banner_content">
                        <h2>Chat</h2>
                         <hr class="banner_hr news_hr" /><h3>
                         <ul className="news_ul">
                           <li><a href="#">Home</a></li>
                           <li><i class="fal fa-angle-right"></i></li>
                           <li><a className="active" href="#">Chat</a></li>
                         </ul>
                        </h3>
                      </div>
                    </div>
                </div>
            </div>
          </section>
          <section>
            <div className="custom_container">
                <div className="row" >
                    <div className="col-md-8 offset-md-2 col-sm-12 second_div text-center">
                    <h3 class="title_main">Forex Chat <b>Room</b></h3>
                        <p className="p_text newS_p">Trading is a solitary activity. Spending hours alone with nothing but your thoughts and 
                        your charts can get very lonely. Do you think you have a great trade idea, but you’re not sure if it’s an amazing one? Did your last trade fail, but you’re not sure why? Did you hear about a great strategy or indicator but not sure if it truly works? These are the types of things you can discuss with your fellow traders in our Forex chart room. 
                        We have separate rooms for discussing Forex, stocks, gold, and cryptocurrencies. Join the conversation at any time.</p>
                    </div>
                </div>
            </div>
          </section>
          <section className="most_polupar">
            <div className="custom_container">
                    <div className="row">
                    <div className="col-md-8 offset-md-2 col-sm-12 second_div text-center">
                        <h3 className="title_main">
                        <b>5 Types</b> of Chat
                        </h3>
                        <p className="p_text newS_p">Take a look at some of our features that will help you make more informed, 
                        intelligent and timely investment decisions.</p>
                    </div>
                </div>
                <div className="row popular_row">
                      <div className="col-md-6">
                          <div className="advance_analytic">

  
                           <Link to={"/singlechat/forex"} className="nav-link" params={{ room: "forex" }}>
                              <img src={analyticsImg} />
                              <p>Forex</p>
                              </Link>
                          </div>
                      </div>
                      <div className="col-md-6">
                        <div className="advance_analytic">


                        <Link to={"/singlechat/stock"} >
    <img src={accountImg} />
                            <p>Stocks</p>
    </Link>

                        </div>
                      </div>
                  </div>
                <div className="row popular_row">
                      <div className="col-md-4">
                          <div className="advance_analytic">
                          <Link to={"/singlechat/gold"} className="nav-link" params={{ room: "gold" }}>
                            <img src={Explore} />
                            <p>Gold</p>
                            </Link>
                          </div>
                      </div>
                      <div className="col-md-4">
                        <div className="advance_analytic">
                        <Link to={"/singlechat/oil"} className="nav-link" params={{ room: "oil" }}>
                            <img src={knowledgeImg} />
                            <p>Oil</p>
                            </Link>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="advance_analytic">
                        <Link to={"/singlechat/crypto"} className="nav-link" params={{ room: "crypto" }}>
                            <img src={learnImg} />
                            <p>Crypto</p>
                            </Link>
                        </div>
                      </div>
                  </div>
                </div>
          </section>
      
          <footer>
            <Footer />       
          </footer>
            </div>
        )
    }
}
