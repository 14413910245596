import React, { Component } from "react";
import Footer from "../layout/footer/Footer.component";
import Header from "../layout/header/Header.component";
import './Chatsingle.component.css'
import DP from '../../../assests/dp.jpg'
import analyticsImg from '../../../assests/advanced-analytics.jpg';
//import { API_BASE_URL,SOCKET_URL } from "../constants/AppConstants";
import { Link } from "react-router-dom";
import constants from "../../../constants/constants";
import io from "socket.io-client"; 
import axios from "axios";
var globalSocket;
const socketUrl = constants.SOCKET_URL;
const nodeUrl = constants.NODE_URL;
let imgPath="../../../assests/"
export default class Chatting extends Component {

    constructor(props) {
        super(props)
        this.state = {
            transcode: '',
            user1:[],
            user2:[],
            previousChat:[],
            otheruserImg: DP,
            myImg:'',
            actualId:''
        }
    }

  componentDidMount() {


        let id = localStorage.getItem("userId");
        var authData = 'Token '+localStorage.getItem("accessToken");
        this.setState({
             myImg: localStorage.getItem("image"),
             actualId: id
        });
      
      this.setState({
        transcode: '1'
      })
//     //  setTimeout(() => {

//         // alert('this.props.myid' + this.props.myid);

//         // alert('this.props.otherId' + this.props.otherId);


//          /***** user 1  ****/

//          var url2 = 'https://stock-trading-backend.itechnolabs.tech/auth/userdetails/'+this.props.myid+'/';
//           axios
//             .get(url2,
               
//                   {headers: {
//                      "Content-Type": "application/json",
//                      Authorization: authData
//                   }},
//               )
//             .then((response) => {
//                 console.log(response.data);

//             })
//             .catch((error) => {
//                 console.log("error", error);
//             });
// /***** user 2 ***/

// var url1 = 'https://stock-trading-backend.itechnolabs.tech/auth/userdetails/'+this.props.otherId+'/';
//             axios
//             .get(url1,{headers: {
//                     "Content-Type": "application/json",
//                      Authorization: authData
//                   }},
//               )
//             .then((response) => {
//                 console.log(response.data);
//             })
//             .catch((error) => {
//                 console.log("error", error);
//             });

// /***** user 3 ****/
            axios
            .get(nodeUrl+'get-previous-convo',
               {"params":{
                  userid1: this.props.myid,
                  userid2: this.props.otherId
              }},
                {
                    headers: { "Content-Type": "application/json" }
                },
              )
            .then((response) => {
                this.setState({ previousChat: response.data });
            })
            .catch((error) => {
                console.log("error", error);
            });


//       //}, 1000);
      

  }


        componentDidUpdate(prevProps, prevState) {
            if (prevProps.otherId !== this.props.otherId) {

                var authData = 'Token '+localStorage.getItem("accessToken");
                var url = 'https://stock-trading-backend.itechnolabs.tech/auth/userdetails/'+this.props.myid+'/';
                /***** user 1  ****/
                axios
                .get(url,{headers: {
                        "Content-Type": "application/json",
                         Authorization: authData
                    }},
                )
                .then((response) => {
                    console.log(response.data);

                })
                .catch((error) => {
                    console.log("error", error);
                });
                /***** user 2 ***/

                var urlOther = 'https://stock-trading-backend.itechnolabs.tech/auth/userdetails/'+this.props.myid+'/';
                axios
                .get(urlOther,{headers: {
                         "Content-Type": "application/json",
                         Authorization: authData
                      }},
                  )
                .then((response) => {
                    console.log(response.data);
                })
                .catch((error) => {
                    console.log("error", error);
                });

                /***** user 3 ****/
                axios
                .get(nodeUrl+'get-previous-convo',
                   {"params":{
                      userid1: this.props.myid,
                      userid2: this.props.otherId
                  }},
                      {headers: {
                         "Content-Type": "application/json"
                      }},
                  )
                .then((response) => {
                    this.setState({ previousChat: response.data });
                })
                .catch((error) => {
                    console.log("error", error);
                });
            }
        }

  // componentDidUpdate(prevProps, prevState) {

  //   const { something } = this.props.myid;
  //   if(  (prevProps.myid != this.props.myid) ||  (prevProps.otherId != this.props.otherId) ){

  //       var authData = 'Token '+localStorage.getItem("accessToken");

  //       var url = 'https://stock-trading-backend.itechnolabs.tech/auth/userdetails/'+this.props.myid+'/';
  //         /***** user 1  ****/
  //         axios
  //           .get(url,{headers: {
  //                   "Content-Type": "application/json",
  //                    Authorization: authData
  //                 }},
  //             )
  //           .then((response) => {
  //               console.log(response.data);

  //           })
  //           .catch((error) => {
  //               console.log("error", error);
  //           });
  //           /***** user 2 ***/

  //           var urlOther = 'https://stock-trading-backend.itechnolabs.tech/auth/userdetails/'+this.props.myid+'/';
  //           axios
  //           .get(urlOther,{headers: {
  //                    "Content-Type": "application/json",
  //                    Authorization: authData
  //                 }},
  //             )
  //           .then((response) => {
  //               console.log(response.data);
  //           })
  //           .catch((error) => {
  //               console.log("error", error);
  //           });

  //           /***** user 3 ****/
  //           axios
  //           .get(nodeUrl+'get-previous-convo',
  //              {"params":{
  //                 userid1: this.props.myid,
  //                 userid2: this.props.otherId
  //             }},
  //                 {headers: {
  //                    "Content-Type": "application/json"
  //                 }},
  //             )
  //           .then((response) => {
  //               //console.log(response.data);
  //               this.setState({ previousChat: response.data });
  //           })
  //           .catch((error) => {
  //               console.log("error", error);
  //           });

  //       } 

      
  // }
 
  initSocket = async () => {
    let socket = io.connect(socketUrl)
    globalSocket = socket;
    this.setState({ socket })
    socket.on('connect',function (data) { 

        var ids = localStorage.getItem("userId")

        socket.emit('updatesocket', { id: ids }, function(dataFromServer) {
            console.log(dataFromServer);
        });


        socket.on('update-message',(data) => { // create_debate fn will be in backend 
                
                // console.log('wtfffffffff');
                
                let person = {
                  from_user_id: data.from_user_id , 
                  message: data.message , 
                  name : data.name, 
                  to_user_id: data.to_user_id
                };

                //  this.setState({        
                //   conversation: person
                // })

                //console.log('dfdfdfd'+ this.state);

                
                // var arra_data = localStorage.getItem("conversation");
                // console.log('arra_data ' +  arra_data);
                // arra_data.push(person);
                // this.setState({ conversation: this.state.conversation });

            })


    
        // socket.on('create_debate',(data)=>{ // create_debate fn will be in backend 
        //   console.log(data)
        // })


      // socket.emit('chat-message-to-user', { userid: ids }, function(dataFromServer) {
      //     console.log(dataFromServer);
      // });

    })
}
    render() {

        var message_second = 'message_second'; 
        var msgClass1 = 'message_first';
        var msgClass2 = 'message_first message_second';

        const listItems = this.state.previousChat.map((item, index) => (


  <div key ={index} class= { ( parseInt(item.from_user_id) == parseInt(this.state.actualId) ) ? 'message_first' :  'message_first message_second' }>


  <div class="message_first_left">
                        <ul>
                          <li><span>{item.message}</span></li>
                        </ul>
                      </div>

                      { (parseInt(item.from_user_id) == parseInt(this.state.actualId)) ? <img src={this.state.myImg} /> :  <img src={DP} /> }
                      

            </div>

)) 

        return (

<div>
{listItems}
</div>
        )
    }
}
