import React, { Component } from "react";
import Footer from "../layout/footer/Footer.component";
import Header from "../layout/header/Header.component";
import './Chatsingle.component.css'
import Chatting from "./Chatting.component.js";
import DP from '../../../assests/dp.jpg'
import analyticsImg from '../../../assests/advanced-analytics.jpg';
import axios from "axios";
import { Redirect, Route , Link , useParams  } from 'react-router-dom';
import io from "socket.io-client"; 
import {FormGroup, ControlLabel, FormControl , InputGroup} from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import $ from 'jquery';
import constants from "../../../constants/constants";
var globalSocket;
const socketUrl = constants.SOCKET_URL;
const nodeUrl = constants.NODE_URL;
let imgPath="../../../assests/";

function withRouter(Component) {
  function ComponentWithRouter(props) {
    let params = useParams()
    return <Component {...props} params={params} />
  }
  return ComponentWithRouter
}

export default class ChatSinglePage extends Component {

    constructor(props) {
        super(props);

        var s = window.location.href;
        var split = s.split("singlechat/");
        var secondSubString = split[1];

        this.state = {
              transcode: '',
              users:[],
              chat:'',
              messageText: "",
              firstID:'',
              myID:localStorage.getItem('image'),
              conversation: [],
              previousChat:[],
              previousChats:[],
              groupParameter : secondSubString,
              isgroup: true,
              myImg:localStorage.getItem("image"),
              supportImg:'http://stock-trading-backend.itechnolabs.tech/static/images/blank-profile-picture-973460_1280.png'
        }
    }

    componentDidMount() {

        //alert('cddfd'+ localStorage.getItem("image"));

        if(localStorage.getItem("image") == ''){

            //alert('cddfd'+ localStorage.getItem("image"))
            this.setState({
                myImg : 'http://stock-trading-backend.itechnolabs.tech/static/images/blank-profile-picture-973460_1280.png'
            })

        }

        let id = localStorage.getItem("userId");

        this.setState({
            myID : id
        });

        let accessToekn = localStorage.getItem('accessToken');

        let foodlist = '';
        var authData = 'Token '+accessToekn;
        var chatts = '';
        var customid = '';

        setTimeout(function() {

            this.setState({ actualId: localStorage.getItem("userId") });

            axios.get(nodeUrl+'group-chat',
                {"params":{
                    group: this.state.groupParameter
                }},
                {
                    headers: { "Content-Type": "application/json" }
                },
            ).then((response) => {
                this.setState({ previousChats: response.data })
            }).catch((error) => {
                console.log("error", error);
            });

        }.bind(this), 5000);

        axios
        .get(`https://stock-trading-backend.itechnolabs.tech/chat/user-list/gold/`,
              {
                headers: {
                   "Content-Type": "application/json",
                   Authorization: authData
              }
            },
          )
        .then((response) => {
            this.setState({ users: response.data.chats });
            this.setState({ firstID: response.data.chats[0].id });
            this.setState({
                chat: chatts
            })
        })
        .catch((error) => {
            console.log("error", error);
        });

        this.setState({
            transcode: '1'
        })

        this.initSocket()
    }

    onClickPerson = (e) => {
        this.setState({ firstID: e.currentTarget.id });
        this.setState({ isgroup: false });

        axios.get(nodeUrl+'get-previous-convo',
           {"params":{
              userid1: localStorage.getItem("userId"),
              userid2: e.currentTarget.id
            }},
            {
                headers: { "Content-Type": "application/json" }
            },
        ).then((response) => {
            this.setState({ previousChats: response.data })
        })
        .catch((error) => {
            console.log("error", error);
        });
    }

    groupChat = (e) => {
        this.setState({ isgroup: true });

        axios.get(nodeUrl+'group-chat',
            {"params":{
                group: this.state.groupParameter
            }},
            {
                headers: { "Content-Type": "application/json" }
            },
        ).then((response) => {
            this.setState({ previousChats: response.data })
        }).catch((error) => {
            console.log("error", error);
        });
    }

    onSendClicked  = (e) => { 

        if (!this.state.messageText) {
            return;
        }
       
        this.setState({ messageText: "" });
    } 

    onMessageInputChange = (e) => {
      //console.log($('#message_type').val())
      var message_val = $('#message_type').val()
      if(message_val != ''){
        //if (e.key === "Enter") {

            this.setState({ messageText: message_val });

            //alert('sdfsd');

            if(this.state.isgroup){ 

                globalSocket.emit('update-group-chat', { img: this.state.myImg , name: localStorage.getItem("name")  , message: message_val , sender_id: localStorage.getItem("userId") , group_id : this.state.groupParameter }, function(dataFromServer) {
                    console.log(dataFromServer);
                });

                let person = {
                    sender_id: localStorage.getItem("userId") , 
                    message: message_val , 
                    name : localStorage.getItem("name"), 
                    img: this.state.myImg,  
                    group_id: this.state.groupParameter 
                };


                //alert('hsdfbkhdsgbksdgksdj');
                var convo = this.state.previousChats;
                convo.push(person);
                this.setState({ previousChats: convo });

            }else{
                globalSocket.emit('update-chat', { img: this.state.myImg , message: message_val , from_user_id: localStorage.getItem("userId") , to_user_id : this.state.firstID  }, function(dataFromServer) {
                    console.log(dataFromServer);
                });

                let person = {
                    from_user_id: localStorage.getItem("userId") , 
                    message: message_val , 
                    name : localStorage.getItem("name"), 
                    to_user_id: this.state.firstID , 
                    img: this.state.myImg
                };

                var convo = this.state.previousChats;
                convo.push(person);
                this.setState({ previousChats: convo });
            
            }
        //}
        $('#message_type').val('')
        }
    }

    initSocket = async () => {

        console.log('cccccccc');
        let socket = io.connect(socketUrl)
        globalSocket = socket;
        this.setState({ socket })

        socket.on('connect', (data) => {

            console.log('sdsdsdsd');

            var ids = localStorage.getItem("userId")

            socket.emit('updatesocket', { id: ids }, function(dataFromServer) {
                console.log(dataFromServer);
            });

        });

        
        socket.on('getGroupChat',(data) => {

            if( (data.group_id == this.state.groupParameter )  && ( data.sender_id !=  this.state.actualId ) && (this.state.isgroup) ){

                let person = {
                    sender_id: data.sender_id , 
                    message: data.message , 
                    name : data.name, 
                    group_id: data.group_id,
                    img: data.img,
                    name: data.name
                };

                var convo = this.state.previousChats;
                convo.push(person);
                this.setState({ previousChats: convo });

            }

        }); 

        socket.on('getChat',(data) => { // create_debate fn will be in backend \\

            if( (  data.to_user_id ==  this.state.actualId  ) && ( !this.state.isgroup ) ){

                let person = {
                    from_user_id: data.from_user_id , 
                    message: data.message , 
                    name : data.name, 
                    to_user_id: data.to_user_id,
                    img : data.img
                };

                var convo = this.state.previousChats;

                convo.push(person);
                
                this.setState({ 
                    previousChats: convo 
                });

            }
        })
    }

    render() {
        const myId = localStorage.getItem("userId");
        var message_second = 'message_second'; 

        var msgClass1 = 'message_first';
        var msgClass2 = 'message_first message_second';

        const listItems = this.state.previousChats.map((item, index) => (
          
            <div key={index} class= {   ( ( (this.state.isgroup) && ( parseInt(item.sender_id) == parseInt(this.state.actualId)) )  || ( (!this.state.isgroup) && ( parseInt(item.from_user_id) == parseInt(this.state.actualId)) )   )   ? 'message_first message_second' :  'message_first' }>
                <div className="message_first_left">
                    <ul>
                        <li><span>{item.message} </span></li>
                    </ul>
                </div>
                { ( ( (this.state.isgroup) && ( parseInt(item.sender_id) == parseInt(this.state.actualId)) )  || ( (!this.state.isgroup) && ( parseInt(item.from_user_id) == parseInt(this.state.actualId)) )   ) ? <img src={this.state.myImg} /> :  <img src={item.img} /> }
            </div>
        )) 

        return (
            <div>
          <header>
            <div className="custom_container">
              <div className="row">
                <div className="col-md-12">
                  <Header />
                </div>
              </div>
            </div>
          </header>

          <section className="single_chat_outer">
            <div className="custom_container">
              <div className="row chat_row">
                <div className="col-md-4 chat_four_col">
                  <div className="single_chat_left">
                    
                    <div className="chat_listing">
                      <ul className="chat_listing_ul">

                      <li onClick={this.groupChat}>
                    <div class="chat_li_inner">
      
                      <div class="chat_li_center">
                        <div class="message_t"><b>Group Chat</b></div>
                      </div>
                </div>

              </li>
                      {this.state.users.map(d => {
           return(
              <li id={d.id}  key={d.id} onClick={this.onClickPerson}>
              <div class="chat_li_inner">
      <div class="chat_li_left">

      { ( d.image == '')  ? <img src={this.state.supportImg} /> :  <img src={d.image} /> }

      
        <span class="away"></span>
        </div>
      <div class="chat_li_center">
        <div class="message_t"><b>{d.name}</b></div>
      </div>
    </div>

              </li>
           )}
         )}

                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-md-8 chat_eight_col">
                  <div className="single_chat_right">
                    <div className="single_chat_upper">
                      <div className="signle_chat_title">Conversations here </div>
                    </div>
                    <div className="message_right">
                        <div className="message_upper" data-myId={this.state.myID} data-otherId={this.state.firstID}>

                           {listItems}

                        </div>

                  
                    </div>

                <div className="message_bottom">
                    <div className="text_message_outer">
                      <input type="text" placeholder="Typing..." name="" id="message_type"/>
                      <div className="text_message_right">
                        <ul>
                          {/* <li><a href="#"><i class="fa-solid fa-paper-plane"></i></a></li> */}
                          <li><i className="fa fa-paper-plane send_i send_message" onClick={this.onMessageInputChange}></i></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <footer>
            <Footer />       
          </footer>
            </div>
        )
    }
}
