import React, { Component } from "react";
import Footer from "../layout/footer/Footer.component";
import Header from "../layout/header/Header.component";
import './Result.component.css'
import B1 from '../../../assests/b1.png';
import B2 from '../../../assests/b2.png';
import B3 from '../../../assests/b3.png';
import Explore from '../../../assests/explore.jpg';
import analyticsImg from '../../../assests/advanced-analytics.jpg';
import accountImg from '../../../assests/account_statement.jpg';
import Graph from '../../../assests/graph.jpg';
import F1 from '../../../assests/f1.png';
import E1 from '../../../assests/e1.jpg';
import E2 from '../../../assests/e2.jpg';
import E3 from '../../../assests/e3.jpg';
import E4 from '../../../assests/e4.jpg';
import S1 from '../../../assests/s1.jpg';
import S2 from '../../../assests/s2.jpg';
import S3 from '../../../assests/s3.jpg';
import S4 from '../../../assests/s4.jpg';
import O1 from '../../../assests/o1.jpg';
import Verfication from '../../../assests/verfication.png';
import Heart from '../../../assests/heart.png';
import Graf1 from '../../../assests/graf1.png';
import Graf2 from '../../../assests/graf2.png';
import Tab2 from '../../../assests/tab2.png';
import Tab1 from '../../../assests/tab1.png';
import Tab3 from '../../../assests/tab3.png';
import R1 from '../../../assests/r1.png';
import R2 from '../../../assests/r2.png';
import R3 from '../../../assests/r3.png';
import R4 from '../../../assests/r4.png';
import R5 from '../../../assests/r5.png';
import P1 from '../../../assests/p1.png';
import P2 from '../../../assests/p2.png';

import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";


let imgPath="../../../assests/"
export default class Result extends Component {

  constructor(props) {
    super(props);
    this.state = {
      code: "",
      errorMessage: "",
      accessToken: "",
      email: "",
      stocks:[],
      forex:[],
      crypto:[],
    };
    
  }
  componentDidMount() {
    this.getLocation();
  }

  getLocation() {
    axios
      .get("https://api.finage.co.uk/news/market/AMZN?apikey="+process.env.REACT_APP_FINAGE_API_KEY)
      .then((response) => {
        if (
          response
        ) {
          let stocksData = response.data.news;
          this.setState({
            stocks: stocksData,
          });

          console.log(stocksData)
        }
      })
      .catch((error) => {
        console.log(error);
      });

      axios
      .get("https://api.finage.co.uk/news/market/GBP?apikey="+process.env.REACT_APP_FINAGE_API_KEY)
      .then((response) => {
        if (
          response
        ) {
          let stocksData = response.data.news;
          this.setState({
            forex: stocksData,
          });

          console.log(stocksData)
        }
      })
      .catch((error) => {
        console.log(error);
      });

      axios
      .get("https://api.finage.co.uk/news/market/BTC?apikey="+process.env.REACT_APP_FINAGE_API_KEY)
      .then((response) => {
        if (
          response
        ) {
          let stocksData = response.data.news;
          this.setState({
            crypto: stocksData,
          });

          console.log(stocksData)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
    render() {

      function formatDate(argument) {
 
        var created= moment(argument).utc().format('MM/DD/YYYY')
        return created;
      }
        return (
            <div>
               <header>
               <div className="custom_container">
                  <div className="row">
                     <div className="col-md-12">
                        <Header />
                     </div>
                  </div>
               </div>
               </header>
               <section className="banner_outer news_banner">
                  <div className="overlay"></div>
                  <div className="custom_container">
                     <div className="row" >
                        <div className="col-md-8 offset-md-2 col-sm-12 first_div text-center">
                           <div class="banner_content">
                              <h2>News</h2>
                              <hr class="banner_hr news_hr" />
                              <h3>
                                 <ul className="news_ul">
                                    <li>
                                       <Link to={"/home"} >
                                       Home</Link>
                                    </li>
                                    <li><i class="fal fa-angle-right"></i></li>
                                    <li>
                                       <Link to={"/news"} className="active">
                                       News</Link>
                                    </li>
                                 </ul>
                              </h3>
                           </div>
                        </div>
                     </div>
                  </div>
               </section>
               <section className="resut_outer">
					<div className="container">
						<div className="blck_strips">
							<h5>Darwinx - Live - ISD - 1:200 - Meta Trader 5</h5>
							<span><img src={Verfication}/><img src={Heart} /></span>
						</div>
						<div className="row">
							<div className="col-md-4">
								<div className="list5">
								<div className="table-responsive">
									<table className="table">
									<tr>
									<td><span className="light_gry">Balance:</span></td>
									<td><span className="light_dark">$220,424.52</span></td>
									<td></td>
									</tr>
									<tr>
									<td><span className="light_gry">Balance:</span></td>
									<td><span className="light_dark">$220,424.52</span></td>
									<td> <span className="lgreen">+100%</span></td>
									</tr>
										<tr>
									<td><span className="light_gry">Balance:</span></td>
									<td><span className="light_dark">$220,424.52</span></td>
									<td> <span className="reed">+100%</span></td>
									</tr>
										<tr>
									<td><span className="light_gry">Balance:</span></td>
									<td><span className="light_dark">$220,424.52</span></td>
									<td> <span className="lgreen">+100%</span></td>
									</tr>
									</table>
								</div>
								</div>
							</div>
							<div className="col-md-2">
								<div className="list5 small_list5">
								<div className="table-responsive">
									<table className="table">
									<tr>
									<td><span className="light_gry">Deposits:</span></td>
									<td><span className="light_dark">$1000</span></td>
							
									</tr>
									<tr>
									<td><span className="light_gry">Withdrawals:</span></td>
									<td><span className="light_dark">$100</span></td>
							
									</tr>
									<tr>
									<td><span className="light_gry">Last Update:</span></td>
									<td><span className="light_dark">1hrs</span></td>
							
									</tr>
									<tr>
									<td><span className="light_gry">Vievs:</span></td>
									<td><span className="light_dark">200</span></td>
							
									</tr>
								
								
									</table>
									</div>
								</div>
								</div>
								<div className="col-md-4">
								<div className="list5">
								<div className="table-responsive">
									<table className="table">
									<tr>
									<td><span className="light_gry">Today:</span></td>
									<td><span className="light_dark">$10,424.52</span></td>
									<td> <span className="lgreen">+110%</span></td>
									</tr>
									<tr>
									<td><span className="light_gry">This week:</span></td>
									<td><span className="light_dark">$220,424.52</span></td>
									<td> <span className="lgreen">+110%</span></td>
									</tr>
										<tr>
									<td><span className="light_gry">This Month:</span></td>
									<td><span className="light_dark">$300,424.52</span></td>
									<td> <span className="reed">+20%</span></td>
									</tr>
										<tr>
									<td><span className="light_gry">This Year:</span></td>
									<td><span className="light_dark">$700,424.52</span></td>
									<td> <span className="lgreen">+80%</span></td>
									</tr>
									</table>
								</div>
								</div>
							</div>
							<div className="col-md-2">
								<div className="list5 small_list5">
								<div className="table-responsive">
									<table className="table">
									<tr>
									<td><span className="light_gry">Deposits:</span></td>
									<td><span className="light_dark">$1000</span></td>
							
									</tr>
									<tr>
									<td><span className="light_gry">Withdrawals:</span></td>
									<td><span className="light_dark">$100</span></td>
							
									</tr>
									<tr>
									<td><span className="light_gry">Last Update:</span></td>
									<td><span className="light_dark">1hrs</span></td>
							
									</tr>
									<tr>
									<td><span className="light_gry">Vievs:</span></td>
									<td><span className="light_dark">200</span></td>
							
									</tr>
								
								
									</table>
									</div>
								</div>
								</div>	
								
								
							
						</div>
						<div className="img_outer1">
						<img src={Graf1}/>
						</div>
						<div className="img_outer1">
						<img src={Graf2}/>
						</div>
						<div className="tabs_outer">
							<ul className="nav nav-tabs">
  <li><a data-toggle="tab" href="#home">Trades</a></li>
  <li><a data-toggle="tab" href="#menu1">History</a></li>
  <li><a className="active" data-toggle="tab" href="#menu2">Summary</a></li>
</ul>

<div className="tab-content">
  <div id="home" className="tab-pane fade">
   <h3>Trades</h3>
   
  </div>
  <div id="menu1" className="tab-pane fade">
    <h3>Summary</h3>
  </div> 
  <div id="menu2" className="tab-pane fade in active">
    <div className="table_inner">
		<div className="table-responsive">
			<table className="table">
  <thead>
    <tr>
      <th scope="col"></th>
      <th scope="col"></th>
      <th scope="col">Longs</th>
	    <th scope="col"></th>
		  <th scope="col"></th>
		  <th>Shorts</th>
		   <th scope="col"></th>
		  <th scope="col"></th>
		   <th scope="col"></th>
		  <th scope="col"></th>
   
    </tr>
  </thead>
  <tbody>
    <tr>

      <td>Currency</td>
      <td>Trades</td>
      <td>Pips</td>
	    <td>Profit ($)</td>
		  <td>Trades</td>
		    <td>Pips</td>
			<td>Profit ($)</td>
			<td>Total Trades</td>
			<td>Total Pips</td>
			<td>Total Profit ($)</td>
    </tr>
    <tr>

      <td><span>AUDCAD</span></td>
      <td><span>1</span></td>
      <td><span className="red3">-13.5</span></td>
	 <td><span className="red3">-13.5</span></td>
		 <td><span>0</span></td>
		  <td><span className="blue14">0.0</span></td>
		   <td><span className="blue14">0.0</span></td>
	
		 <td><span>0</span></td>
		 	    <td><span className="red3">-13.5</span></td>
					    <td><span className="red3">-13.5</span></td>
	
    </tr>
	  <tr>

      <td><span>AUDCAD</span></td>
      <td><span>1</span></td>
       <td><span className="blue14">0.0</span></td>
		   <td><span className="blue14">0.0</span></td>
		 <td><span>0</span></td>
		
		    <td><span className="red3">-13.5</span></td>
	 <td><span className="red3">-13.5</span></td>
	
		 <td><span>0</span></td>
		 	    <td><span className="red3">-13.5</span></td>
					    <td><span className="red3">-13.5</span></td>
	
    </tr>
   <tr>

      <td><span>AUDCAD</span></td>
      <td><span>1</span></td>
      <td><span className="red3">-13.5</span></td>
	 <td><span className="red3">-13.5</span></td>
		 <td><span>0</span></td>
		  <td><span className="blue14">0.0</span></td>
		   <td><span className="blue14">0.0</span></td>
	
		 <td><span>0</span></td>
		 	    <td><span className="red3">-13.5</span></td>
					    <td><span className="red3">-13.5</span></td>
	
    </tr>
	  <tr>

      <td><span>AUDCAD</span></td>
      <td><span>1</span></td>
       <td><span className="blue14">0.0</span></td>
		   <td><span className="blue14">0.0</span></td>
		 <td><span>0</span></td>
		
		    <td><span className="red3">-13.5</span></td>
	 <td><span className="red3">-13.5</span></td>
	
		 <td><span>0</span></td>
		 	    <td><span className="red3">-13.5</span></td>
					    <td><span className="red3">-13.5</span></td>
	
    </tr>
	   <tr>

      <td><span>AUDCAD</span></td>
      <td><span>1</span></td>
      <td><span className="red3">-13.5</span></td>
	 <td><span className="red3">-13.5</span></td>
		 <td><span>0</span></td>
		  <td><span className="blue14">0.0</span></td>
		   <td><span className="blue14">0.0</span></td>
	
		 <td><span>0</span></td>
		 	    <td><span className="red3">-13.5</span></td>
					    <td><span className="red3">-13.5</span></td>
	
    </tr>
	  <tr>

      <td><span>AUDCAD</span></td>
      <td><span>1</span></td>
       <td><span className="blue14">0.0</span></td>
		   <td><span className="blue14">0.0</span></td>
		 <td><span>0</span></td>
		
		    <td><span className="red3">-13.5</span></td>
	 <td><span className="red3">-13.5</span></td>
	
		 <td><span>0</span></td>
		 	    <td><span className="red3">-13.5</span></td>
					    <td><span className="red3">-13.5</span></td>
	
    </tr>
	   <tr>

      <td><span>AUDCAD</span></td>
      <td><span>1</span></td>
      <td><span className="red3">-13.5</span></td>
	 <td><span className="red3">-13.5</span></td>
		 <td><span>0</span></td>
		  <td><span className="blue14">0.0</span></td>
		   <td><span className="blue14">0.0</span></td>
	
		 <td><span>0</span></td>
		 	    <td><span className="red3">-13.5</span></td>
					    <td><span className="red3">-13.5</span></td>
	
    </tr>
	  <tr>

      <td><span>AUDCAD</span></td>
      <td><span>1</span></td>
       <td><span className="blue14">0.0</span></td>
		   <td><span className="blue14">0.0</span></td>
		 <td><span>0</span></td>
		
		    <td><span className="red3">-13.5</span></td>
	 <td><span className="red3">-13.5</span></td>
	
		 <td><span>0</span></td>
		 	    <td><span className="red3">-13.5</span></td>
					    <td><span className="red3">-13.5</span></td>
	
    </tr>
	   <tr>

      <td><span>AUDCAD</span></td>
      <td><span>1</span></td>
      <td><span className="red3">-13.5</span></td>
	 <td><span className="red3">-13.5</span></td>
		 <td><span>0</span></td>
		  <td><span className="blue14">0.0</span></td>
		   <td><span className="blue14">0.0</span></td>
	
		 <td><span>0</span></td>
		 	    <td><span className="red3">-13.5</span></td>
					    <td><span className="red3">-13.5</span></td>
	
    </tr>
	  <tr>

      <td><span>AUDCAD</span></td>
      <td><span>1</span></td>
       <td><span className="blue14">0.0</span></td>
		   <td><span className="blue14">0.0</span></td>
		 <td><span>0</span></td>
		
		    <td><span className="red3">-13.5</span></td>
	 <td><span className="red3">-13.5</span></td>
	
		 <td><span>0</span></td>
		 	    <td><span className="red3">-13.5</span></td>
					    <td><span className="red3">-13.5</span></td>
	
    </tr>
	   <tr>

      <td><span>AUDCAD</span></td>
      <td><span>1</span></td>
      <td><span className="red3">-13.5</span></td>
	 <td><span className="red3">-13.5</span></td>
		 <td><span>0</span></td>
		  <td><span className="blue14">0.0</span></td>
		   <td><span className="blue14">0.0</span></td>
	
		 <td><span>0</span></td>
		 	    <td><span className="red3">-13.5</span></td>
					    <td><span className="red3">-13.5</span></td>
	
    </tr>
  </tbody>
</table>
		</div>
	</div>
  </div>
</div>
						</div>
												<div className="tabs_outer">
							<ul className="nav nav-tabs">
  <li><a className="" data-toggle="tab" href="#drew">Draw Down</a></li>
</ul>

<div className="tab-content">
  <div id="drew" className="tab-pane fade in active">
     <div className="img_outer1">
						<img src={Tab3}/>
						</div>
   <div className="img_outer1">
						<img src={Tab1}/>
						</div>
						
						 <div className="img_outer1">
					<div className="table_inner45">
							<div className="table-responsive">
  <table className="table">
  <tr>
  <td><span>Gross Profit:</span></td>
  <td><span>256.35</span></td>
  <td><span>Gross loss:</span></td>
  <td><span>858.01</span></td>
  <td><span>Total Net Profit:</span></td>
  <td><span>1658.38</span></td>
  </tr>
    <tr>
  <td><span>Gross Profit:</span></td>
  <td><span>256.35</span></td>
  <td><span>Gross loss:</span></td>
  <td><span>858.01</span></td>
  <td><span>Total Net Profit:</span></td>
  <td><span>1658.38</span></td>
  </tr>
    <tr>
  <td><span>Gross Profit:</span></td>
  <td><span>256.35</span></td>
  <td><span>Gross loss:</span></td>
  <td><span>858.01</span></td>
  <td><span>Total Net Profit:</span></td>
  <td><span>1658.38</span></td>
  </tr>
    <tr>
  <td><span>Gross Profit:</span></td>
  <td><span>256.35</span></td>
  <td><span>Gross loss:</span></td>
  <td><span>858.01</span></td>
  <td><span>Total Net Profit:</span></td>
  <td><span>1658.38</span></td>
  </tr>
    <tr>
  <td><span>Gross Profit:</span></td>
  <td><span>256.35</span></td>
  <td><span>Gross loss:</span></td>
  <td><span>858.01</span></td>
  <td><span>Total Net Profit:</span></td>
  <td><span>1658.38</span></td>
  </tr>
    <tr>
  <td><span>Gross Profit:</span></td>
  <td><span>256.35</span></td>
  <td><span>Gross loss:</span></td>
  <td><span>858.01</span></td>
  <td><span>Total Net Profit:</span></td>
  <td><span>1658.38</span></td>
  </tr>
    
  </table>
</div>
					</div>
						</div>
						
						
						
						<div className="img_outer1">
						<img src={Tab2}/>
						</div>
						<div className="img_outer1">
						<img src={Tab2}/>
						</div>
						<div className="selct_outer">
							<select>
							<option>More Result</option>
							</select>
						</div>
   
  </div>
  </div>
  </div>
  <div className="row">
	<div className="col-md-10 offset-md-1">
	<div className="row custom_width">
		<div className="col-md-2">
			<div className="inner_box">
				<img src={R1} />
				<p>In 01/07/2022 At 10:00 AM GMT.<br />
About: how we can finance.</p>
			</div>
		</div>
		<div className="col-md-2">
			<div className="inner_box">
				<img src={R2} />
				<p>In 01/07/2022 At 10:00 AM GMT.<br />
About: how we can finance.</p>
			</div>
		</div>
			<div className="col-md-2">
			<div className="inner_box">
				<img src={R3} />
				<p>In 01/07/2022 At 10:00 AM GMT.<br />
About: how we can finance.</p>
			</div>
		</div>
			<div className="col-md-2">
			<div className="inner_box">
				<img src={R4} />
				<p>In 01/07/2022 At 10:00 AM GMT.<br />
About: how we can finance.</p>
			</div>
		</div>
		<div className="col-md-2">
			<div className="inner_box">
				<img src={R5} />
				<p>In 01/07/2022 At 10:00 AM GMT.<br />
About: how we can finance.</p>
			</div>
		</div>
			
		
		
	</div>
	</div>
  </div>
    <div className="row">
	<div className="col-md-10 offset-md-1">
  <div className="row">
  
	<div className="col-md-3">
		<div className="button_outer4">
		<img src={P1}/>
		<a href="#" className="line_outer18">pepperstone</a>
		<a href="#">Registration</a>
		</div>
	</div>

  	<div className="col-md-3">
		<div className="button_outer4">
		<img src={P2}/>
		<a href="#" className="line_outer18">darwinex</a>
		<a href="#">Registration</a>
		</div>
	</div>
	

  	<div className="col-md-3">
		<div className="button_outer4">
		<img src={P1}/>
		<a href="#" className="line_outer18">pepperstone</a>
		<a href="#">Registration</a>
		</div>
	</div>
	

  	<div className="col-md-3">
		<div className="button_outer4">
		<img src={P2}/>
		<a href="#" className="line_outer18">pepperstone</a>
		<a href="#">Registration</a>
		</div>
	</div>
	
  </div>
 
  </div>
  </div>

						
					</div>
			   </section>
               <footer>
               <Footer />       
               </footer>
            </div>
        )
    }
}
