import React, {Component} from "react";
import './Editprofile.component.css'
import Header from "../layout/header/Header.component";
import { ToastContainer, toast } from 'react-toastify';
import { isEmail } from "validator";
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import Select from 'react-validation/build/select';
import CheckButton from "react-validation/build/button";
import authService from "../../../services/auth.service";
import axios from "axios";
import constants from "../../../constants/constants";
import { Link } from "react-router-dom";
import DP from '../../../assests/defaultProfile.jpg'
export default class EditProfile extends Component{
  state = {
    errorMessage: '',
    email: '',
    name: '',
    password: '',
    confirmPassword:'',
    firstName:'',
    lastName:'',
    gender:'',
    userType:'',
    experience:'',
    agreedTerm:false,
    showPassword:false,
    showConfirmPassword:false,
    isShowFillExperience:false,
    agreedTermErrorShow:false,
    country:'',
    invalidImage: null,
    selectedFile: null,
    imageUrl: null,
    isUpdated:false,
    isSocialLogin:false,
    accessToken:''
  };
  constructor(props) {
    super(props);
    this.onChangeUserType = this.onChangeUserType.bind(this);
    this.onChangeEmail =this.onChangeEmail.bind(this);
    this.onChangePassword=this.onChangePassword.bind(this);
    this.onChangeConfirmPassword=this.onChangeConfirmPassword.bind(this)
    this.onChangeFirstName=this.onChangeFirstName.bind(this)
    this.onChangeLastName=this.onChangeLastName.bind(this)
    this.onChangeGender=this.onChangeGender.bind(this)
   this.onChangeExperience=this.onChangeExperience.bind(this)
   this.onCheckedTerm=this.onCheckedTerm.bind(this)
   this.onChangeFile=this.onChangeFile.bind(this)
   this.handleShowPassword=this.handleShowPassword.bind(this)
   this.handleShowConfirmPassword=this.handleShowConfirmPassword.bind(this)
   
   this.onSubmit = this.onSubmit.bind(this)
   this.onSubmitProfilePic = this.onSubmitProfilePic.bind(this)
   this.formRef=React.createRef()
   this.checkBtnRef=React.createRef()
   this.inputFile=React.createRef(null)
   this.reader = new FileReader();

  }
  componentDidMount(){
    let id=localStorage.getItem('userId')
    let accessToken=localStorage.getItem('accessToken')
    let isSocialLogin=localStorage.getItem('isSocialLogin')
    if(isSocialLogin === "true"){

     this.setState({isSocialLogin:true})
    }
    this.getUserInfo(id,accessToken)
  }
  componentDidUpdate(){
    
  }
  
  onChangeFile(e){
    const imageFile = e.target.files[0];
 
    if (!imageFile) {
      this.setState({ invalidImage: 'Please select image.' });
      return false;
    }
 
    if (!imageFile.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      this.setState({ invalidImage: 'Please select valid image.' });
      return false;
    }
    this.reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        this.setState({ selectedFile: imageFile, invalidImage: null });
        console.log('image',this.state.selectedFile)
      };
      img.onerror = () => {
        this.setState({ invalidImage: 'Invalid image content.' });
        return false;
      };
      img.src = e.target.result;
      this.setState({
        imageUrl:img.src
      })
    };
    this.reader.readAsDataURL(imageFile);
  }
  onChangeUserType(event) {
    this.setState({
      userType: event.currentTarget.value
    });
    console.log("currect user type",this.state.userType)
    
  }
  onChangeEmail(e){
    this.setState({email:e.target.value})
   
  }
  onChangePassword(e){
    this.setState({password:e.target.value})
  }
  onChangeConfirmPassword(e){
    this.setState({confirmPassword:e.target.value})
  }
  onChangeFirstName(e){
    this.setState({firstName:e.target.value})
  }
  onChangeLastName(e){
    this.setState({lastName:e.target.value})
  }
  onChangeGender(e){
    this.setState({gender:e.target.value})
  }
  onChangeExperience(e){
    this.setState({experience:e.target.value})
  }
  onCheckedTerm(e){
   
   if(e.target.checked === true){ 
     this.setState({agreedTerm :true})
     this.setState({agreedTermErrorShow:false})
   }else{
    this.setState({agreedTerm :false})
   }
  }
  handleShowPassword(){
  
    this.setState({
      showPassword:!this.state.showPassword
    })
  }
  handleShowConfirmPassword(){
  
    this.setState({
      showConfirmPassword:!this.state.showConfirmPassword
    })
  }
  getUserInfo(id,accessToken){

    authService.getUser(accessToken,id)
    .then((response)=>{
      
      if(response.data.image !==null && response.data.image !=='' && response.data.image!==undefined){
        this.setState({
          imageUrl:response.data.image
        })
      }

      if(response.data.country !==null && response.data.country !=='' && response.data.country!==undefined){
        this.setState({
          country:response.data.country
        })
      }
        if(response.data.first_name !==null && response.data.first_name !=='' && response.data.first_name !==undefined){
          this.setState({
            firstName:response.data.first_name,
            
          })
        }
        
         
        if(response.data.last_name !==null && response.data.last_name !=='' && response.data.last_name !==undefined){  
          this.setState({
            lastName:response.data.last_name,
           
          })
        }
        
        
          if(response.data.gender !==null && response.data.gender !=='' && response.data.gender !==undefined){
          this.setState({
            gender:response.data.gender
          })
        }
       
        
        if(response.data.user_type !==null && response.data.user_type !=='' && response.data.user_type !==undefined){
          this.setState({
            userType:response.data.user_type
          })
         
        }
        
        if(response.data.email !==null && response.data.email !=='' && response.data.email !==undefined){
          this.setState({
            email:response.data.email,
            
          })
        }
        
        if(response.data.experience !==null && response.data.experience !=='' && response.data.experience !==undefined){
          
          this.setState({
            experience:response.data.experience
          })
        }
        
        
      })
    .catch((error)=>{
      if(error.response.data.error !=='' &&  error.response.data.error!==undefined && error.response.data.error !==null){
        toast.error(error.response.data.error, {
          autoClose: 6000,
  
        })
      }
      
      if(error.response.data.detail !==undefined && error.response.data.detail !==null && error.response.data.detail !==''){
        
        
        if(error.response.data.detail ==="Invalid token."){
           
          this.setState({accessToken:null})
          toast.error(error.response.data.detail + "Please login.", {
            autoClose: 6000,
    
          })
           window.location.href = `${window.location.origin}/login`
        }
      }
    })
    
  }
  
  async onSubmitProfilePic(e){
    // e.preventDefault();
    this.inputFile.current.click();
    
    }
 async onSubmit(e){
    e.preventDefault();
    this.formRef.current.validateAll() 
    if(this.checkBtnRef.current.context._errors.length === 0 && this.state.invalidImage === null){
      let payload=new FormData()
      if(this.state.email !=='' && this.state.email !==null && this.state.email !==undefined){
        payload.append('email',this.state.email)
      
       } 
      
       if(this.state.selectedFile !=='' && this.state.selectedFile !==null && this.state.selectedFile !==undefined){
        payload.append('image', this.state.selectedFile, this.state.selectedFile.name); 
       
       } 
       if(this.state.firstName !=='' && this.state.firstName !==null && this.state.firstName !==undefined){
        payload.append('first_name',this.state.firstName)
        
      } 
      if(this.state.lastName !=='' && this.state.lastName !==null && this.state.lastName !==undefined){
        payload.append('last_name',this.state.lastName)
        
      } 
      if(this.state.userType !=='' && this.state.userType !==null && this.state.userType !==undefined){
        payload.append('user_type',this.state.userType)
        
      } 
      if(this.state.gender !=='' && this.state.gender !==null && this.state.gender !==undefined){
       payload.append('gender',this.state.gender)
        
      } 
      if(this.state.country !=='' && this.state.country !==null && this.state.country !==undefined){
        payload.append('country',this.state.country)
      
      }
      if(this.state.experience !=='' && this.state.experience !==null && this.state.experience !==undefined){
        payload.append('experience',this.state.experience)
      
      }
      let id=localStorage.getItem('userId');
      let accessToken=localStorage.getItem('accessToken')
    
      authService.updateUser(payload,accessToken,id)
      .then((response)=>{
        
        if(response.status===200 || response.status===201){
          toast.success("Profile Update Successfull", {
            autoClose: 6000,
          });
         
        }
        // this.getUserInfo(id,accessToken)
        window.location.reload()
      }) 
      .catch((error)=>{
        
        if(error.response.data.error !=='' &&  error.response.data.error!==undefined && error.response.data.error !==null){
          toast.error(error.response.data.error, {
            autoClose: 6000,
    
          })
        }
        
        if(error.response.data.detail !==undefined && error.response.data.detail !==null && error.response.data.detail !==''){
          
          
          if(error.response.data.detail ==="Invalid token."){
             
            this.setState({accessToken:null})
            toast.error(error.response.data.detail + "Please login.", {
              autoClose: 6000,
      
            })
             window.location.href = `${window.location.origin}/login`
          }
        }
      })
      }
    }
  
  
  render(){
    const required = value => {
      if (!value) {
        return (
          <p style={{color:'red'}}>
          This field is required
          </p>
        );
      }
    };
    
    const email = value => {
      if (!isEmail(value)) {
        return (<p style={{color:'red'}}>
          `${value} is not a valid email.`
          </p>
        );
      }
    };
    const confirmPassword =value=>{
      if(value !== this.state.password){
        return (<p style={{color:'red'}}>
          `Confirm password should be same as password.`
          </p>
        );
      }
    }
        return (
        <div>
          <header>
            <div className="custom_container">
              <div className="row">
                <div className="col-md-12">
                  <Header imageUrl={this.state.imageUrl} firstName={this.state.firstName} lastName={this.state.lastName} />
                </div>
              </div>
            </div>
          </header>

          <section>
            <div className="custom_container">
              <div className="row">
                <div className="col-md-12">
                  <div className="edit_profile_outer">
                     <div className="row">
                       <div className="col-md-4">
                       <div class="single_jobleft">
                          <div className="sign_in_title">Edit Profile</div>
                          <div class="upload_profile_div">
                            <img src={this.state.imageUrl !=='' && this.state.imageUrl !==null ? this.state.imageUrl : DP} />
                            <i class="fas fa-camera"></i>
                            <span>Upload Profile Picture</span>
                            
                          </div>
                          <a class="edit_a" type="submit" onClick={this.onSubmitProfilePic}>
                          <input type="file" name="" ref={this.inputFile} onChange={this.onChangeFile} style={{ display: "none" }} />
                            <i class="fas fa-pencil-alt"></i></a>
                          {this.state.invalidImage && <p className="error" style={{color:"red"}}>{this.state.invalidImage}</p>}
                        </div>
                       </div>
                       <div className="col-md-8">
                       <Form ref={this.formRef} onSubmit={this.onSubmit}>
                     
                         <div className="login_inner">
                           
                           <div className="row">
                             <div className="col-md-6">
                                  <label className="custom_label">Email Address</label>
                                  <Input className="custom_input" type="text" placeholder="Email Address" value={this.state.email} onChange={this.onChangeEmail} validations={[email]} />   
                             </div>
                             <div className="col-md-6">
                                  <label className="custom_label">Country</label>
                                  <Input className="custom_input" type="text" placeholder="Country" value={this.state.country} readOnly />   
                             </div>
                           </div>
                           <div className="row">
                              <div className="col-md-6">
                              <label className="custom_label">First Name</label>
                              <Input className="custom_input" type="text" placeholder="First Name" value={this.state.firstName} onChange={this.onChangeFirstName} />
                              </div>
                          
                              <div className="col-md-6">
                              <label className="custom_label">Last Name</label>
                              <Input className="custom_input" type="text" placeholder="Last Name" value={this.state.lastName} onChange={this.onChangeLastName} />
                              </div>
                              
                            </div>
                            <div className="row">
                             <div className="col-md-12">
                                  <label className="custom_label">Gender</label>
                                  <Select className="custom_input" value={this.state.gender} onChange={this.onChangeGender}>
                                  <option disabled value={''}>Select gender</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    <option value="other">Other</option>
                                  </Select>
                             </div>
                             </div>
                           <div className="row im_row">
                             <div className="col-md-12">
                                <label className="custom_label">I am</label>
                                <div className="radio_custom">
                                <label className="radio_container">Investor
                                  <input type="radio" checked="checked" name="radio" value="investor" onChange={this.onChangeUserType}  checked={this.state.userType === "investor"} />
                                  <span className="checkmark"></span>
                                </label>
                                <label className="radio_container">Trader
                                  <input type="radio" checked="checked" name="radio" value="trader" onChange={this.onChangeUserType} checked={this.state.userType === "trader"} />
                                  <span className="checkmark"></span>
                                </label>
                                <label className="radio_container">Technical
                                  <input type="radio" checked="checked" name="radio" value="technical" onChange={this.onChangeUserType} checked={this.state.userType === "technical"}/>
                                  <span className="checkmark"></span>
                                </label>
                                <label className="radio_container">Freelancer
                                  <input type="radio" checked="checked" name="radio" value="freelancer" onChange={this.onChangeUserType} checked={this.state.userType === "freelancer"}/>
                                  <span className="checkmark"></span>
                                </label>
                                </div>
                             </div>
                           </div>
                           {
                             this.state.userType !=="investor" ? (
                              <div className="row">
                              <div className="col-md-12">
                              <label className="custom_label">Experience</label>
                              <Select className="custom_input" value={this.state.experience} onChange={this.onChangeExperience}>
                                <option disabled value={''}>Years of experience</option>
                                <option value={"0-1"}>0 month to 1 year</option>
                                <option value={"1-5"}>1 to 5 years</option>
                                <option value={"5-10"}>5 to 10 years</option>
                                <option value={"10-15"}>10 to 15 years</option>
                                <option value={"15-20"}>15 month to 20 years</option>
                                <option value={"20-30"}>More than 20 years</option>
                              </Select>       
                         </div>                            
                          </div>
                             ):''
                           }
                             
                           <div className="row">
                             <div className="col-md-12 text-left btn_c_u">
                              <a className="signin_btn updateprofile" type="submit" onClick={this.onSubmit}>Update Profile</a>
                             {this.state.isSocialLogin === false ? (<Link to={"/changePassword"} className="signin_btn updateprofile chnge_pwd_btn" type="submit">Change Password</Link>) :''}
                              
                             </div>
                           </div>
                         </div>
                         <CheckButton style={{ display: "none" }} ref={this.checkBtnRef} />
                         </Form>
                         
                       </div>
                       
                     </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
            </div>
        )
    }
}