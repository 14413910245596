import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Moment from "moment";

export default function DateRangePick(props) {
  // const [startDate, setStartDate] = useState(new Date("2022/01/08"));
  const [startDate, setStartDate] = useState(
    new Date().setDate(new Date().getDate() - 60)
  );
  const [endDate, setEndDate] = useState(new Date());

  const handleSelect = (d, check) => {
    if (check == "start") {
      setStartDate(d);
      props.onSlctDate(
        Moment(d).format("YYYY-MM-DD"),
        Moment(endDate).format("YYYY-MM-DD")
      );
    } else {
      setEndDate(d);
      props.onSlctDate(
        Moment(startDate).format("YYYY-MM-DD"),
        Moment(d).format("YYYY-MM-DD")
      );
    }
  };

  return (
    <>
      <div className="start_date_pick">
        <DatePicker
          selected={startDate}
          // onChange={(date) => setStartDate(date)}
          onChange={(date) => handleSelect(date, "start")}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          className="date_pick"
          maxDate={new Date()}
        />
      </div>
      <div className="end_date_pick mt-2">
        <DatePicker
          className="date_pick"
          selected={endDate}
          onChange={(date) => handleSelect(date, "end")}
          // onChange={(date) => setEndDate(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          maxDate={new Date()}
        />
      </div>
    </>
  );
}
