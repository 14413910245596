import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import StockList from "./StockList";
import Paper from "@material-ui/core/Paper";

import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";

export default class SymbolList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stockVal: "us-stock",
      stock: "stock",
      searchsym: "market/us",
    };
  }
  getSymbol(value, val) {
    this.props.passSymbol(value, val, this.state.stock);
  }
  componentDidMount() {}

  render() {
    const { stockVal, searchsym } = this.state;
    return (
      <>
        <Modal show={true} className="compareModal">
          <Modal.Body>
            <Tabs
              value={stockVal}
              textColor="primary"
              indicatorColor="primary"
              className="mb-3 modalBtn"
            >
              <Tab
                value="us-stock"
                label="US Stocks"
                className="usButton"
                onClick={() =>
                  this.setState({
                    stockVal: "us-stock",
                    stock: "stock",
                    searchsym: "market/us",
                  })
                }
              />
              <Tab
                value="forex"
                label="Forex"
                onClick={() =>
                  this.setState({
                    stockVal: "forex",
                    stock: "forex",
                    searchsym: "currency",
                  })
                }
              />
              <Tab
                label="Crypto"
                value="crypto"
                onClick={() =>
                  this.setState({
                    stockVal: "crypto",
                    stock: "crypto",
                    searchsym: "cryptocurrency",
                  })
                }
              />
            </Tabs>
            <div key={stockVal}>
              <StockList
                getSymbol={this.getSymbol.bind(this)}
                stock={stockVal}
                symbol={searchsym}
                watchlist={this.props.watchlist}
                getWatchlist={this.props.getWatchlist}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-primary bclosebtn"
              onClick={(e) => this.props.isOpen(false)}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
