import React, {Component} from "react";
import './LandingPage.component.css'
import Header from "../layout/header/Header.component";
import Footer from "../layout/footer/Footer.component";
import B1 from '../../../assests/b1.png';
import B2 from '../../../assests/b2.png';
import B3 from '../../../assests/b3.png';
import Explore from '../../../assests/explore.jpg';
import analyticsImg from '../../../assests/advanced-analytics.jpg';
import accountImg from '../../../assests/account_statement.jpg';
import Graph from '../../../assests/graph.jpg';
import F1 from '../../../assests/f1.png';
import E1 from '../../../assests/e1.jpg';
import E2 from '../../../assests/e2.jpg';
import E3 from '../../../assests/e3.jpg';
import E4 from '../../../assests/e4.jpg';
import S1 from '../../../assests/s1.jpg';
import S2 from '../../../assests/s2.jpg';
import S3 from '../../../assests/s3.jpg';
import S4 from '../../../assests/s4.jpg';
import R1 from '../../../assests/r1.jpg';
import R2 from '../../../assests/r2.jpg';
import O1 from '../../../assests/o1.jpg';

export default class LandingPage extends Component{
    render(){
        return (
            <div>
          <header>
            <div className="custom_container">
              <div className="row">
                <div className="col-md-12">
                  <Header />
                </div>
              </div>
            </div>
          </header>

          <section className="banner_outer landing_banner">
            <div className="overlay"></div>
                <div className="custom_container">
                <div className="row" >
                    <div className="col-md-8 offset-md-2 col-sm-12 first_div text-center">
                        <div className="banner_content">
                        <h2>Lorem Ipsum Dummy Text</h2>
                        <p className="p_banner">Donec urna felis, tincidunt ullamcorper eleifend nec, rhoncus non sapien. 
                            Sed ultricies pharetra facilisis. Cras malesuada libero at urna interdum feugiat.</p>
                        {/* <ul className="banner_ul">
                            <li>
                                <img src={B1} />
                                <div className="li_right">
                                    <div className="li_first_title">btcusd</div>
                                    <span className="first_span">-2.68%</span>
                                </div>
                            </li>
                            <li>
                                <img src={B2} />
                                <div className="li_right">
                                    <div className="li_first_title">tsla</div>
                                    <span className="second_span">+1.64%</span>
                                </div>
                            </li>
                            <li>
                                <img src={B3} />
                                <div className="li_right">
                                    <div className="li_first_title">aapl</div>
                                    <span className="third_span">+2.28%</span>
                                </div>
                            </li>
                        </ul> */}

                        </div>
                    </div>
                </div>
            </div>
          </section>
          <section className="darknes_color">
            <div className="custom_container">
                <div className="row" >
                    <div className="col-md-8 offset-md-2 col-sm-12 second_div text-center">
                        <h3 className="title_main">
                        Lorem ipsum dolor sit amet<br></br>
                            <b>consectetur adipiscing</b>
                        </h3>
                        <p className="p_text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius, diam id pharetra egestas, felis neque vulputate quam, bibendum iaculis mauris justo id tellus. 
                        Nulla consequat metus ac quam eleifend, nec dignissim justo egestas. </p>
                        <button type="button" class="btn btn-dark signup_btn">Explore features</button>
                    </div>
                </div>
            </div>
          </section>
          <section>
            <div className="custom_container">
                <div className="row" >
                    <div className="col-md-12">
                       <div className="explore_outer">
                         <img src={Explore} />
                       </div>
                    </div>
                </div>
            </div>
          </section>
          <section className="most_polupar">
            <div className="custom_container">
                <div className="row">
                    <div className="col-md-8 offset-md-2 col-sm-12 second_div text-center">
                        <h3 className="title_main">
                        Most Popular<br></br>
                            <b>News</b>
                        </h3>
                        <p className="p_text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius, diam id pharetra egestas, felis neque vulputate quam, bibendum iaculis mauris justo id tellus. </p>
                    </div>
                    </div>
                    <div className="row">
                    <div className="col-md-8 offset-md-2 col-sm-12 second_div text-center">
                        <h3 className="title_main">
                        <b>Forex</b>
                        </h3>
                    </div>
                </div>
                <div className="row popular_row">
                      <div className="col-md-6">
                          <div className="advance_analytic">
                            <img src={analyticsImg} />
                            <p>Lorem ipsum</p>
                          </div>
                      </div>
                      <div className="col-md-6">
                        <div className="advance_analytic">
                            <img src={accountImg} />
                            <p>Lorem ipsum</p>
                        </div>
                      </div>
                  </div>
                  <div className="row">
                    <div className="col-md-8 offset-md-2 col-sm-12 second_div text-center">
                        <h3 className="title_main">
                        Stock <b>Markets</b>
                        </h3>
                    </div>
                </div>
                <div className="row popular_row">
                      <div className="col-md-6">
                          <div className="advance_analytic">
                            <img src={analyticsImg} />
                            <p>Lorem ipsum</p>
                          </div>
                      </div>
                      <div className="col-md-6">
                        <div className="advance_analytic">
                            <img src={accountImg} />
                            <p>Lorem ipsum</p>
                        </div>
                      </div>
                  </div>
                  <div className="row">
                    <div className="col-md-8 offset-md-2 col-sm-12 second_div text-center">
                        <h3 className="title_main">
                        <b>Cryptocurrencies</b>
                        </h3>
                    </div>
                </div>
                <div className="row popular_row">
                      <div className="col-md-6">
                          <div className="advance_analytic">
                            <img src={analyticsImg} />
                            <p>Lorem ipsum</p>
                          </div>
                      </div>
                      <div className="col-md-6">
                        <div className="advance_analytic">
                            <img src={accountImg} />
                            <p>Lorem ipsum</p>
                        </div>
                      </div>
                  </div>
                </div>
          </section>
          <section className="darknes_color">
            <div className="custom_container">
                <div className="row">
                    <div className="col-md-8 offset-md-2 col-sm-12 second_div text-center">
                        <h3 className="title_main">
                        Market
                            <b>Summary</b>
                        </h3>
                        <p className="p_text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius, diam id pharetra egestas, felis neque vulputate quam, bibendum iaculis mauris justo id tellus. </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-5">
                      <div className="graph_outer">
                        <img src={Graph} />
                      </div>
                    </div>
                    <div className="col-md-7">
                      <div className="market_summary_outer">
                        <div className="market_summary_div">
                          <div className="summary_left">
                           <img src={F1} />
                           <div className="summary_left_right">
                             <div className="summary_title">S&P 500</div>
                             <p>S&P 500 E-MINI</p>
                           </div>
                          </div>
                          <div className="summary_center">
                            <p>4661.50</p>
                          </div>
                          <div className="summary_right">
                            <span>-0.12%</span>
                            <span>-5.50</span>
                          </div>
                        </div>
                        <div className="market_summary_div">
                          <div className="summary_left">
                           <img src={F1} />
                           <div className="summary_left_right">
                             <div className="summary_title">S&P 500</div>
                             <p>S&P 500 E-MINI</p>
                           </div>
                          </div>
                          <div className="summary_center">
                            <p>4661.50</p>
                          </div>
                          <div className="summary_right">
                            <span>-0.12%</span>
                            <span>-5.50</span>
                          </div>
                        </div>
                        <div className="market_summary_div">
                          <div className="summary_left">
                           <img src={F1} />
                           <div className="summary_left_right">
                             <div className="summary_title">S&P 500</div>
                             <p>S&P 500 E-MINI</p>
                           </div>
                          </div>
                          <div className="summary_center">
                            <p>4661.50</p>
                          </div>
                          <div className="summary_right">
                            <span>-0.12%</span>
                            <span>-5.50</span>
                          </div>
                        </div>
                        <div className="market_summary_div">
                          <div className="summary_left">
                           <img src={F1} />
                           <div className="summary_left_right">
                             <div className="summary_title">S&P 500</div>
                             <p>S&P 500 E-MINI</p>
                           </div>
                          </div>
                          <div className="summary_center">
                            <p>4661.50</p>
                          </div>
                          <div className="summary_right">
                            <span>-0.12%</span>
                            <span>-5.50</span>
                          </div>
                        </div>
                        <a href="#" className="view_indicies">View More Indices<i class="fa fa-chevron-right"></i></a>
                      </div>
                    </div>
                </div>
            </div>
          </section>
          <section className="most_polupar">
            <div className="custom_container">
                <div className="row">
                    <div className="col-md-8 offset-md-2 col-sm-12 second_div text-center">
                        <h3 className="title_main">
                            <b>Brokers</b>
                        </h3>
                        <p className="p_text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius, diam id pharetra egestas, felis neque vulputate quam, bibendum iaculis mauris justo id tellus. </p>
                    </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="broker_outer">
                      <div className="broker_div">
                        <div className="broker_left">
                           <img src={E1} />
                           <div className="summary_left_right">
                             <div className="summary_title">TradeStation</div>
                             <p>Crypto, Stocks, Futures</p>
                           </div>
                        </div>
                        <div className="broker_center">
                          <div id="full-stars-example-two">
                              <span className="four_span">4.0</span>
                              <div class="rating-group">
                                  <input disabled checked class="rating__input rating__input--none" name="rating3" id="rating3-none" value="0" type="radio" />
                                  <label aria-label="1 star" class="rating__label" for="rating3-1"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                                  <input class="rating__input" name="rating3" id="rating3-1" value="1" type="radio" />
                                  <label aria-label="2 stars" class="rating__label" for="rating3-2"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                                  <input class="rating__input" name="rating3" id="rating3-2" value="2" type="radio" />
                                  <label aria-label="3 stars" class="rating__label" for="rating3-3"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                                  <input class="rating__input" name="rating3" id="rating3-3" value="3" type="radio" />
                                  <label aria-label="4 stars" class="rating__label" for="rating3-4"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                                  <input class="rating__input" name="rating3" id="rating3-4" value="4" type="radio" />
                                  <label aria-label="5 stars" class="rating__label" for="rating3-5"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                                  <input class="rating__input" name="rating3" id="rating3-5" value="5" type="radio" />
                              </div>
                          </div>
                        </div>
                        <div className="broker_right">
                          <a className="open_account" href="#">Open Account</a>
                        </div>
                      </div>
                      <div className="broker_div">
                        <div className="broker_left">
                           <img src={E2} />
                           <div className="summary_left_right">
                             <div className="summary_title">Currency.com</div>
                             <p>Crypto, Stocks, Futures</p>
                           </div>
                        </div>
                        <div className="broker_center">
                          <div id="full-stars-example-two">
                              <span className="four_span">4.0</span>
                              <div class="rating-group">
                                  <input disabled checked class="rating__input rating__input--none" name="rating3" id="rating3-none" value="0" type="radio" />
                                  <label aria-label="1 star" class="rating__label" for="rating3-1"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                                  <input class="rating__input" name="rating3" id="rating3-1" value="1" type="radio" />
                                  <label aria-label="2 stars" class="rating__label" for="rating3-2"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                                  <input class="rating__input" name="rating3" id="rating3-2" value="2" type="radio" />
                                  <label aria-label="3 stars" class="rating__label" for="rating3-3"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                                  <input class="rating__input" name="rating3" id="rating3-3" value="3" type="radio" />
                                  <label aria-label="4 stars" class="rating__label" for="rating3-4"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                                  <input class="rating__input" name="rating3" id="rating3-4" value="4" type="radio" />
                                  <label aria-label="5 stars" class="rating__label" for="rating3-5"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                                  <input class="rating__input" name="rating3" id="rating3-5" value="5" type="radio" />
                              </div>
                          </div>
                        </div>
                        <div className="broker_right">
                          <a className="open_account" href="#">Open Account</a>
                        </div>
                      </div>
                      <div className="broker_div">
                        <div className="broker_left">
                           <img src={E3} />
                           <div className="summary_left_right">
                             <div className="summary_title">FXCM</div>
                             <p>Crypto, Stocks, Futures</p>
                           </div>
                        </div>
                        <div className="broker_center">
                          <div id="full-stars-example-two">
                              <span className="four_span">4.0</span>
                              <div class="rating-group">
                                  <input disabled checked class="rating__input rating__input--none" name="rating3" id="rating3-none" value="0" type="radio" />
                                  <label aria-label="1 star" class="rating__label" for="rating3-1"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                                  <input class="rating__input" name="rating3" id="rating3-1" value="1" type="radio" />
                                  <label aria-label="2 stars" class="rating__label" for="rating3-2"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                                  <input class="rating__input" name="rating3" id="rating3-2" value="2" type="radio" />
                                  <label aria-label="3 stars" class="rating__label" for="rating3-3"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                                  <input class="rating__input" name="rating3" id="rating3-3" value="3" type="radio" />
                                  <label aria-label="4 stars" class="rating__label" for="rating3-4"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                                  <input class="rating__input" name="rating3" id="rating3-4" value="4" type="radio" />
                                  <label aria-label="5 stars" class="rating__label" for="rating3-5"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                                  <input class="rating__input" name="rating3" id="rating3-5" value="5" type="radio" />
                              </div>
                          </div>
                        </div>
                        <div className="broker_right">
                          <a className="open_account" href="#">Open Account</a>
                        </div>
                      </div>
                      <div className="broker_div">
                        <div className="broker_left">
                           <img src={E4} />
                           <div className="summary_left_right">
                             <div className="summary_title">Gemini</div>
                             <p>Crypto, Stocks, Futures</p>
                           </div>
                        </div>
                        <div className="broker_center">
                          <div id="full-stars-example-two">
                              <span className="four_span">4.0</span>
                              <div class="rating-group">
                                  <input disabled checked class="rating__input rating__input--none" name="rating3" id="rating3-none" value="0" type="radio" />
                                  <label aria-label="1 star" class="rating__label" for="rating3-1"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                                  <input class="rating__input" name="rating3" id="rating3-1" value="1" type="radio" />
                                  <label aria-label="2 stars" class="rating__label" for="rating3-2"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                                  <input class="rating__input" name="rating3" id="rating3-2" value="2" type="radio" />
                                  <label aria-label="3 stars" class="rating__label" for="rating3-3"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                                  <input class="rating__input" name="rating3" id="rating3-3" value="3" type="radio" />
                                  <label aria-label="4 stars" class="rating__label" for="rating3-4"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                                  <input class="rating__input" name="rating3" id="rating3-4" value="4" type="radio" />
                                  <label aria-label="5 stars" class="rating__label" for="rating3-5"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                                  <input class="rating__input" name="rating3" id="rating3-5" value="5" type="radio" />
                              </div>
                          </div>
                        </div>
                        <div className="broker_right">
                          <a className="open_account" href="#">Open Account</a>
                        </div>
                      </div>
                      <div className="broker_div last_broker_div">
                        <div className="broker_left">
                           <img src={E1} />
                           <div className="summary_left_right">
                             <div className="summary_title">TradeStation</div>
                             <p>Crypto, Stocks, Futures</p>
                           </div>
                        </div>
                        <div className="broker_center">
                          <div id="full-stars-example-two">
                              <span className="four_span">4.0</span>
                              <div class="rating-group">
                                  <input disabled checked class="rating__input rating__input--none" name="rating3" id="rating3-none" value="0" type="radio" />
                                  <label aria-label="1 star" class="rating__label" for="rating3-1"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                                  <input class="rating__input" name="rating3" id="rating3-1" value="1" type="radio" />
                                  <label aria-label="2 stars" class="rating__label" for="rating3-2"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                                  <input class="rating__input" name="rating3" id="rating3-2" value="2" type="radio" />
                                  <label aria-label="3 stars" class="rating__label" for="rating3-3"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                                  <input class="rating__input" name="rating3" id="rating3-3" value="3" type="radio" />
                                  <label aria-label="4 stars" class="rating__label" for="rating3-4"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                                  <input class="rating__input" name="rating3" id="rating3-4" value="4" type="radio" />
                                  <label aria-label="5 stars" class="rating__label" for="rating3-5"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                                  <input class="rating__input" name="rating3" id="rating3-5" value="5" type="radio" />
                              </div>
                          </div>
                        </div>
                        <div className="broker_right">
                          <a className="open_account" href="#">Open Account</a>
                        </div>
                      </div>
                      <div className="view_brokers">
                       <a href="#" className="view_indicies">View More Brokers<i class="fal fa-chevron-right"></i></a>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </section>
          <section className="outlook_section">
            <div className="custom_container">
                <div className="row">
                    <div className="col-md-8 offset-md-2 col-sm-12 second_div text-center">
                        <h3 className="title_main">
                            <b>My Outlook</b>
                        </h3>
                        <p className="p_text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius, diam id pharetra egestas, felis neque vulputate quam, bibendum iaculis mauris justo id tellus. </p>
                    </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="advance_analytic">
                      <img src={O1} />
                      <p>Lorem ipsum</p>
                    </div>
                    <ul className="outlook_ul">
                      <li><i class="fal fa-heart"></i>Like(15)</li>
                      <li><i class="fal fa-comment"></i>Comment(15)</li>
                      <li><i class="fal fa-share"></i>Share</li>
                    </ul>
                  </div>
                  <div className="col-md-4">
                    <div className="advance_analytic">
                      <img src={O1} />
                      <p>Lorem ipsum</p>
                    </div>
                    <ul className="outlook_ul">
                      <li><i class="fal fa-heart"></i>Like(15)</li>
                      <li><i class="fal fa-comment"></i>Comment(15)</li>
                      <li><i class="fal fa-share"></i>Share</li>
                    </ul>
                  </div>
                  <div className="col-md-4">
                    <div className="advance_analytic">
                      <img src={O1} />
                      <p>Lorem ipsum</p>
                    </div>
                    <ul className="outlook_ul">
                      <li><i class="fal fa-heart"></i>Like(15)</li>
                      <li><i class="fal fa-comment"></i>Comment(15)</li>
                      <li><i class="fal fa-share"></i>Share</li>
                    </ul>
                  </div>
                </div>
            </div>
          </section>
          <section className="most_polupar">
            <div className="custom_container">
                <div className="row">
                    <div className="col-md-8 offset-md-2 col-sm-12 second_div text-center">
                        <h3 className="title_main">
                            <b>Stocks</b>
                        </h3>
                        <p className="p_text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius, diam id pharetra egestas, felis neque vulputate quam, bibendum iaculis mauris justo id tellus. </p>
                    </div>
                </div>
                <div className="row">
                  <div className="col-md-8 offset-md-2 col-sm-12">
                    <div className="tabs_custom">
                        <ul class="nav nav-tabs" role="tablist">
                            <li class="nav-item">
                              <a class="nav-link active" data-toggle="tab" href="#tabs-1" role="tab">Active</a>
                            </li>
                            <li class="nav-item">
                              <a class="nav-link" data-toggle="tab" href="#tabs-2" role="tab">Gainers</a>
                            </li>
                            <li class="nav-item">
                              <a class="nav-link" data-toggle="tab" href="#tabs-3" role="tab">Losers</a>
                            </li>
                          </ul>
                          <div class="tab-content">
                            <div class="tab-pane active" id="tabs-1" role="tabpanel">
                            <div className="market_summary_outer">
                            <div className="market_summary_div">
                              <div className="summary_left">
                              <img src={S1} />
                              <div className="summary_left_right">
                                <div className="summary_title">CNTX</div>
                                <p>CONTEXT THERAPEUTICS INC.</p>
                              </div>
                              </div>
                              <div className="summary_center">
                                <p>6.2</p>
                              </div>
                              <div className="summary_right">
                                <span>+24.21</span>
                                <span>%</span>
                              </div>
                            </div>
                            <div className="market_summary_div">
                              <div className="summary_left">
                              <img src={S2} />
                              <div className="summary_left_right">
                                <div className="summary_title">IINN</div>
                                <p>INSPIRA TECHNOLOGIES OXY....</p>
                              </div>
                              </div>
                              <div className="summary_center">
                                <p>6.2</p>
                              </div>
                              <div className="summary_right">
                                <span>+24.21</span>
                                <span>%</span>
                              </div>
                            </div>
                            <div className="market_summary_div">
                              <div className="summary_left">
                              <img src={S3} />
                              <div className="summary_left_right">
                                <div className="summary_title">LCID</div>
                                <p>LUCID GROUP, INC.</p>
                              </div>
                              </div>
                              <div className="summary_center">
                                <p>6.2</p>
                              </div>
                              <div className="summary_right">
                                <span>+24.21</span>
                                <span>%</span>
                              </div>
                            </div>
                            <div className="market_summary_div">
                              <div className="summary_left">
                              <img src={S4} />
                              <div className="summary_left_right">
                                <div className="summary_title">EDU</div>
                                <p>NEW ORIENTAL EDUCATION </p>
                              </div>
                              </div>
                              <div className="summary_center">
                                <p>6.2</p>
                              </div>
                              <div className="summary_right">
                                <span>+24.21</span>
                                <span>%</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="tab-pane" id="tabs-2" role="tabpanel">
                          <p>Second Panel</p>
                        </div>
                        <div class="tab-pane" id="tabs-3" role="tabpanel">
                          <p>Third Panel</p>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                  <div className="view_brokers">
                       <a href="#" className="view_indicies">More Active Stocks<i class="fal fa-chevron-right"></i></a>
                      </div>
                  </div>
                </div>
            </div>
          </section>
          <section className="technical_assistance">
             <div className="custom_container">
               <div className="row">
                   <div className="col-md-8 offset-md-2 col-sm-12">
                        <h3 className="title_main">
                            <b>Reviews</b>
                        </h3>
                        <p className="p_text">For technical assistance and trading operations, our technical team might require read-only access to 
                        your trading account. Our experts can assist you in making viable financial decisions.</p>
                   </div>
               </div>
               <div className="row">
                   <div className="col-md-12">
                      <div className="review_outer">
                      <div className="swiper mySwiper">
                        <div className="swiper-wrapper">
                          <div className="swiper-slide">
                            <div className="review_outer_inner">
                              <img src={R1} />
                              <div id="full-stars-example-two">
                                  <div class="rating-group">
                                      <input disabled checked class="rating__input rating__input--none" name="rating3" id="rating3-none" value="0" type="radio" />
                                      <label aria-label="1 star" class="rating__label" for="rating3-1"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                                      <input class="rating__input" name="rating3" id="rating3-1" value="1" type="radio" />
                                      <label aria-label="2 stars" class="rating__label" for="rating3-2"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                                      <input class="rating__input" name="rating3" id="rating3-2" value="2" type="radio" />
                                      <label aria-label="3 stars" class="rating__label" for="rating3-3"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                                      <input class="rating__input" name="rating3" id="rating3-3" value="3" type="radio" />
                                      <label aria-label="4 stars" class="rating__label" for="rating3-4"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                                      <input class="rating__input" name="rating3" id="rating3-4" value="4" type="radio" />
                                      <label aria-label="5 stars" class="rating__label" for="rating3-5"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                                      <input class="rating__input" name="rating3" id="rating3-5" value="5" type="radio" />
                                  </div>
                              </div>
                              <div className="summary_title">Lorem ipsum</div>
                              <p>Lorem ipsum dolor sit consectetur adipiscing elit. Maecenas varius, diam id pharetra.</p>
                            </div>
                            </div>
                            <div className="swiper-slide">
                            <div className="review_outer_inner">
                              <img src={R2} />
                              <div id="full-stars-example-two">
                                  <div class="rating-group">
                                      <input disabled checked class="rating__input rating__input--none" name="rating3" id="rating3-none" value="0" type="radio" />
                                      <label aria-label="1 star" class="rating__label" for="rating3-1"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                                      <input class="rating__input" name="rating3" id="rating3-1" value="1" type="radio" />
                                      <label aria-label="2 stars" class="rating__label" for="rating3-2"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                                      <input class="rating__input" name="rating3" id="rating3-2" value="2" type="radio" />
                                      <label aria-label="3 stars" class="rating__label" for="rating3-3"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                                      <input class="rating__input" name="rating3" id="rating3-3" value="3" type="radio" />
                                      <label aria-label="4 stars" class="rating__label" for="rating3-4"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                                      <input class="rating__input" name="rating3" id="rating3-4" value="4" type="radio" />
                                      <label aria-label="5 stars" class="rating__label" for="rating3-5"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                                      <input class="rating__input" name="rating3" id="rating3-5" value="5" type="radio" />
                                  </div>
                              </div>
                              <div className="summary_title">EDU</div>
                              <p>Lorem ipsum dolor sit consectetur adipiscing elit. Maecenas varius, diam id pharetra.</p>
                            </div>
                            </div>
                            <div className="swiper-slide">
                            <div className="review_outer_inner">
                              <img src={R1} />
                              <div id="full-stars-example-two">
                                  <div class="rating-group">
                                      <input disabled checked class="rating__input rating__input--none" name="rating3" id="rating3-none" value="0" type="radio" />
                                      <label aria-label="1 star" class="rating__label" for="rating3-1"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                                      <input class="rating__input" name="rating3" id="rating3-1" value="1" type="radio" />
                                      <label aria-label="2 stars" class="rating__label" for="rating3-2"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                                      <input class="rating__input" name="rating3" id="rating3-2" value="2" type="radio" />
                                      <label aria-label="3 stars" class="rating__label" for="rating3-3"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                                      <input class="rating__input" name="rating3" id="rating3-3" value="3" type="radio" />
                                      <label aria-label="4 stars" class="rating__label" for="rating3-4"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                                      <input class="rating__input" name="rating3" id="rating3-4" value="4" type="radio" />
                                      <label aria-label="5 stars" class="rating__label" for="rating3-5"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                                      <input class="rating__input" name="rating3" id="rating3-5" value="5" type="radio" />
                                  </div>
                              </div>
                              <div className="summary_title">EDU</div>
                              <p>Lorem ipsum dolor sit consectetur adipiscing elit. Maecenas varius, diam id pharetra.</p>
                            </div>
                          </div>
                          <div className="swiper-slide">
                            <div className="review_outer_inner">
                              <img src={R1} />
                              <div id="full-stars-example-two">
                                  <div class="rating-group">
                                      <input disabled checked class="rating__input rating__input--none" name="rating3" id="rating3-none" value="0" type="radio" />
                                      <label aria-label="1 star" class="rating__label" for="rating3-1"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                                      <input class="rating__input" name="rating3" id="rating3-1" value="1" type="radio" />
                                      <label aria-label="2 stars" class="rating__label" for="rating3-2"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                                      <input class="rating__input" name="rating3" id="rating3-2" value="2" type="radio" />
                                      <label aria-label="3 stars" class="rating__label" for="rating3-3"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                                      <input class="rating__input" name="rating3" id="rating3-3" value="3" type="radio" />
                                      <label aria-label="4 stars" class="rating__label" for="rating3-4"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                                      <input class="rating__input" name="rating3" id="rating3-4" value="4" type="radio" />
                                      <label aria-label="5 stars" class="rating__label" for="rating3-5"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                                      <input class="rating__input" name="rating3" id="rating3-5" value="5" type="radio" />
                                  </div>
                              </div>
                              <div className="summary_title">EDU</div>
                              <p>Lorem ipsum dolor sit consectetur adipiscing elit. Maecenas varius, diam id pharetra.</p>
                            </div>
                          </div>
                         </div>
                        <div class="swiper-pagination"></div>
                      </div>
                      </div>
                   </div>
               </div>
             </div>
          </section>
          <footer>
            <Footer />       
          </footer>
            </div>
        )
    }
}