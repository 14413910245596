import React, { Component } from "react";
import Constants from "../../../constants/constants";
import FacebookLogin from 'react-facebook-login';
import './ResetPassword.component.css';
import Header from "../layout/header/Header.component";

import { LinkedIn,LinkedInPopUp  } from 'react-linkedin-login-oauth2';

import linkedin from 'react-linkedin-login-oauth2/assets/linkedin.png';
import GoogleLogin from 'react-google-login';

import { render } from 'react-dom';
import { BrowserRouter as Router, Route, Routes, useSearchParams} from 'react-router-dom'

import axios from 'axios';
import authService from "../../../services/auth.service";
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input'
import CheckButton from "react-validation/build/button";
import { ToastContainer, toast } from 'react-toastify';
import { isEmail } from "validator";

require('dotenv').config()

export default class ResetPassword extends Component {
  state = {
    password:'',
    confirmPassword:'',
    showPassword:false,
    showConfirmPassword:false,
    token:'',
    accessToken:''
  };
  constructor(props){
    super(props);
    this.onChangePassword=this.onChangePassword.bind(this);
    this.onChangeConfirmPassword=this.onChangeConfirmPassword.bind(this)
    this.handleShowPassword=this.handleShowPassword.bind(this)
    this.handleShowConfirmPassword=this.handleShowConfirmPassword.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.formRef = React.createRef()
   this.checkBtnRef = React.createRef()
  }
  componentDidMount(){
    let searchUrl=window.location.search
  let param=new URLSearchParams(searchUrl)  
 let token=param.get("token")
this.setState({token:token})
  }
  onChangePassword(e){
    this.setState({password:e.target.value})
  }
  onChangeConfirmPassword(e){
    this.setState({confirmPassword:e.target.value})
  }
  handleShowPassword(){
  
    this.setState({
      showPassword:!this.state.showPassword
    })
  }
  handleShowConfirmPassword(){
  
    this.setState({
      showConfirmPassword:!this.state.showConfirmPassword
    })
  }
  onSubmit(e){
    e.preventDefault();
    this.formRef.current.validateAll();
    if(this.checkBtnRef.current.context._errors.length === 0 && this.state.token !==''){
     let payload={
       token:this.state.token,
       password:this.state.password
     }
     authService.resetPassword(payload)
     .then((response)=>{
      toast.success("Password changed successfully",{
        autoClose:6000
      })

       window.location.href=`${window.location.origin}/login`

     })
     .catch((error)=>{
      if(error.response.data.error !=='' &&  error.response.data.error!==undefined && error.response.data.error !==null){
        toast.error(error.response.data.error, {
          autoClose: 6000,
  
        })
      }
      
      if(error.response.data.detail !==undefined && error.response.data.detail !==null && error.response.data.detail !==''){
        
        
        if(error.response.data.detail ==="Invalid token."){
           
          this.setState({accessToken:null,token:null})
          toast.error(error.response.data.detail + "Please login.", {
            autoClose: 6000,
    
          })
           window.location.href = `${window.location.origin}/login`
        }else{
          toast.error(error.response.data.detail, {
            autoClose: 6000,
    
          })
        }

      }
     })
    }
  }
  render() {
    const required = value => {
      if (!value) {
        return (
          <p style={{color:'red'}}>
          This field is required
          </p>
        );
      }
    };
    const confirmPassword =value=>{
      console.log(this.state.newpassword)
      console.log("value ",value)
      if(value !== this.state.password){
        return (<p style={{color:'red'}}>
          Confirm password should be same as password.
          </p>
        );
      }
    }
    const checkPassword=value=>{
      if(value.toString().trim().length < 8){
        return (<p style={{ color: 'red' }}>
          Password Should contain minimum 8 characters.
        </p>
        ); 
      }else{
        var regularExpression = Constants.PASSWORD_VALIDATION_REGEX;
        let result=regularExpression.test(value)
        if(result ===false){
          return (<p style={{ color: 'red' }}>
          Password must have atleast one digit,one special character like $,@,%,#,-,_,^,!, one small letter and one capital letter.
        </p>)
        }
      }
    }
      
    return (
      <div>

          <header>
            <div className="custom_container">
              <div className="row">
                <div className="col-md-12">
                  <Header />
                </div>
              </div>
            </div>
          </header>

          <section>
            <div className="custom_container">
              <div className="row">
                <div className="col-md-12">
                  <div className="login_outer">
                     <div className="row">
                       <div className="col-md-6 offset-md-3 col-sm-12">
                         <div className="login_inner">
                           <Form ref={this.formRef} onSubmit={this.onSubmit}>
                           <div className="sign_in_title">Reset Password</div>
                           <label className="custom_label">Password</label>
                           <div className="password_input">
                             <div>
                               <Input className="custom_input" type={this.state.showPassword ? "text" :"password"} placeholder="*********" value={this.state.password} onChange={this.onChangePassword} validations={[required,checkPassword]} />
                             </div>
                             {
                          this.state.showPassword ==false 
                          ? (<i class="fal fa-eye" onClick={this.handleShowPassword}></i>)
                          :(<i class="fal fa-eye-slash" onClick={this.handleShowPassword}></i>)
                        }
                           </div>
                           <label className="custom_label">Confirm Password</label>
                           <div className="password_input">
                             <div>
                               <Input className="custom_input" type={this.state.showConfirmPassword ? "text" :"password"} placeholder="*********" value={this.state.confirmPassword} onChange={this.onChangeConfirmPassword} validations={[required,confirmPassword]} />
                             </div>
                             {
                          this.state.showConfirmPassword ==false 
                          ? (<i class="fal fa-eye" onClick={this.handleShowConfirmPassword}></i>)
                          :(<i class="fal fa-eye-slash" onClick={this.handleShowConfirmPassword}></i>)
                        }
                           </div>
                           <a className="signin_btn" href="" onClick={this.onSubmit}>Reset Password</a>
                           <CheckButton style={{ display: "none" }} ref={this.checkBtnRef} />
                           </Form>
                         </div>
                       </div>
                     </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
      </div>
    )
  }
}