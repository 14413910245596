import React, { Component } from "react";
import FacebookLogin from 'react-facebook-login';
import './ForgotPassword.component.css';
import Header from "../layout/header/Header.component";

import { LinkedIn,LinkedInPopUp  } from 'react-linkedin-login-oauth2';

import linkedin from 'react-linkedin-login-oauth2/assets/linkedin.png';
import GoogleLogin from 'react-google-login';

import { render } from 'react-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import authService from "../../../services/auth.service";
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input'
import CheckButton from "react-validation/build/button";
import { ToastContainer, toast } from 'react-toastify';
import { isEmail } from "validator";
import axios from 'axios';

require('dotenv').config()
export default class ForgotPassword extends Component {
  state={
    email:''
  };
  constructor(props){
   super(props);
   this.onChangeEmail = this.onChangeEmail.bind(this);
   this.onSubmit=this.onSubmit.bind(this);
   this.formRef = React.createRef()
   this.checkBtnRef = React.createRef()
  }
  onChangeEmail(e) {
    this.setState({ email: e.target.value })
    
  }
  onSubmit(e){
    e.preventDefault()
    this.formRef.current.validateAll()
    alert('1')
    if(this.checkBtnRef.current.context._errors.length === 0 ){
      let payload={}
      payload.email=this.state.email;
      authService.forgetPassword(payload)
      .then((response)=>{
         if(response.status=200){
          toast.success("Please check your registred email for reset password",{
            autoClose:6000
          })
         }
      })
      .catch((error)=>{
       console.log(error.response)
       toast.error(error.response.data.error,{
        autoClose: 6000,

       })
      })
    }
  }
  render() {
    const required = value => {
      if (!value) {
        return (
          <p style={{ color: 'red' }}>
            This field is required
          </p>
        );
      }
    };

    const email = value => {
      if (!isEmail(value)) {
        return (<p style={{ color: 'red' }}>
          {value} is not a valid email.
        </p>
        );
      }
    };
    return (
      <div>

          <header>
            <div className="custom_container">
              <div className="row">
                <div className="col-md-12">
                  <Header />
                </div>
              </div>
            </div>
          </header>

          <section>
            <div className="custom_container">
              <div className="row">
                <div className="col-md-12">
                  <div className="login_outer">
                     <div className="row">
                       <div className="col-md-6 offset-md-3 col-sm-12">
                         <div className="login_inner">
                           <Form ref={this.formRef} onSubmit={this.onSubmit}>
                           <div className="sign_in_title">Forgot Password</div>
                           <label className="custom_label">Email or User Name</label>
                           <Input className="custom_input" type="text" placeholder="Email Address" value={this.state.email} onChange={this.onChangeEmail} validations={[required, email]} />
                           <a className="signin_btn" type="submit" onClick={this.onSubmit}>Forgot Password</a>
                           <CheckButton style={{ display: "none" }} ref={this.checkBtnRef} />
                          </Form>
                         </div>
                       </div>
                     </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
      </div>
    )
  }
}