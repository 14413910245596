import React, { Component } from "react";
import "./LiveRates.component.css";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { connect } from "react-redux";
import { getStockValue } from "../../../services/actions/actions";
import { isThisISOWeek } from "date-fns";
import HCStock from "highcharts/modules/stock"; 
import HCDragPanes from "highcharts/modules/drag-panes";
import HCTools from "highcharts/modules/stock-tools";
import HCAnnot from "highcharts/modules/annotations";
import HCAnnotAdv from "highcharts/modules/annotations-advanced";
import HCPriceInd from "highcharts/modules/price-indicator";
import HCFullScreen from "highcharts/modules/full-screen";
import HCArrows from "highcharts/modules/arrow-symbols";
import HCBoost from "highcharts/modules/boost";
import HCBoostCanvas from "highcharts/modules/boost-canvas";
import HCData from "highcharts/modules/data";
import highchartsMore from 'highcharts/highcharts-more';
import CustomEvents from "highcharts-custom-events";
import reactCSS from 'reactcss';
import { SketchPicker, AlphaPicker , ChromePicker  } from 'react-color';
import $ from 'jquery';

import { func } from "prop-types";
import { id } from "date-fns/locale";
HCAnnot(Highcharts);
HCAnnotAdv(Highcharts);
HCStock(Highcharts);
HCDragPanes(Highcharts);
HCTools(Highcharts);
HCPriceInd(Highcharts);
HCFullScreen(Highcharts);
HCArrows(Highcharts);
HCBoost(Highcharts);
HCBoostCanvas(Highcharts);
HCData(Highcharts);
highchartsMore(Highcharts);

require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);
// Load Highcharts modules
require('highcharts/indicators/indicators')(Highcharts)
require('highcharts/indicators/indicators-all')(Highcharts)
require('highcharts/indicators/ema')(Highcharts)
// require('highcharts/indicators/sma')(Highcharts) 
require('highcharts/indicators/zigzag')(Highcharts)
require('highcharts/indicators/dema')(Highcharts)
require('highcharts/indicators/rsi')(Highcharts)
require('highcharts/indicators/atr')(Highcharts)
require('highcharts/indicators/pivot-points')(Highcharts)
require('highcharts/indicators/macd')(Highcharts)
require('highcharts/indicators/acceleration-bands')(Highcharts)
require('highcharts/indicators/bollinger-bands')(Highcharts)
require('highcharts/indicators/ichimoku-kinko-hyo')(Highcharts)

require('highcharts/modules/accessibility')(Highcharts)
require('highcharts/modules/map')(Highcharts)
require("highcharts/modules/draggable-points")(Highcharts);
CustomEvents(Highcharts); 

// require("highcharts/modules/annotations")(Highcharts);
class RenkoChart extends Component {
  constructor(props) {
	console.log('props',props)
    super(props); 
    this.state = {
      stockData: [],
      volume: [],
      stockLoad: false,
      stockError: "",
      candleDown: "#F83535",
      candleup: "#6FCC5B",
      backgroundColor: "#F3D95C",
      hignlinevalue: "",
      lowerlinevalue: "",
      showHighLowerLine: false,
      allData: [],
      lastLineValue: "",
      background_color: 'rgba(241 ,112 , 19 ,1 )',
      backgroundColor:"#FFFFFF",
      lineColor:"#000000",
      hignlinevalue:"",
      lowerlinevalue:"",
      showModal:false,
      background: '#fff',
      displayColorPicker: false,
      fontSize:2,
      seriesData:[],
      initialTime:1647963362298,
      seriArr:[],
      lock:true,
      showRandom:0,
      isIndicatorDivDisplay:false,
      color: {
        r: '241',
        g: '112',
        b: '19',
        a: '1',
      },
      showalert:false,
      icons: [
          'fa-500px',
      'fa-address-book',
      'fa-address-book-o',
      'fa-address-card',
      'fa-address-card-o',
      'fa-adjust',
      'fa-adn',
      'fa-align-center',
      'fa-align-justify',
      'fa-align-left',
      'fa-align-right',
      'fa-amazon',
      'fa-ambulance',
      'fa-american-sign-language-interpreting',
      'fa-anchor',
      'fa-android',
      'fa-angellist',
      'fa-angle-double-down',
      'fa-angle-double-left',
      'fa-angle-double-right',
      'fa-angle-double-up',
      'fa-angle-down',
      'fa-angle-left',
      'fa-angle-right',
      'fa-angle-up',
      'fa-apple',
      'fa-archive',
      'fa-area-chart',
      'fa-arrow-circle-down',
      'fa-arrow-circle-left',
      'fa-arrow-circle-o-down',
      'fa-arrow-circle-o-left',
      'fa-arrow-circle-o-right',
      'fa-arrow-circle-o-up',
      'fa-arrow-circle-right',
      'fa-arrow-circle-up',
      'fa-arrow-down',
      'fa-arrow-left',
      'fa-arrow-right',
      'fa-arrow-up',
      'fa-arrows',
      'fa-arrows-alt',
      'fa-arrows-h',
      'fa-arrows-v',
      'fa-asl-interpreting',
      'fa-assistive-listening-systems',
      'fa-asterisk',
      'fa-at',
      'fa-audio-description',
      'fa-automobile',
      'fa-backward',
      'fa-balance-scale',
      'fa-ban',
      'fa-bandcamp',
      'fa-bank',
      'fa-bar-chart',
      'fa-bar-chart-o',
      'fa-barcode',
      'fa-bars',
      'fa-bath',
      'fa-bathtub',
      'fa-battery',
      'fa-battery-0',
      'fa-battery-1',
      'fa-battery-2',
      'fa-battery-3',
      'fa-battery-4',
      'fa-battery-empty',
      'fa-battery-full',
      'fa-battery-half',
      'fa-battery-quarter',
      'fa-battery-three-quarters',
      'fa-bed',
      'fa-beer',
      'fa-behance',
      'fa-behance-square',
      'fa-bell',
      'fa-bell-o',
      'fa-bell-slash',
      'fa-bell-slash-o',
      'fa-bicycle',
      'fa-binoculars',
      'fa-birthday-cake',
      'fa-bitbucket',
      'fa-bitbucket-square',
      'fa-bitcoin',
      'fa-black-tie',
      'fa-blind',
      'fa-bluetooth',
      'fa-bluetooth-b',
      'fa-bold',
      'fa-bolt',
      'fa-bomb',
      'fa-book',
      'fa-bookmark',
      'fa-bookmark-o',
      'fa-braille',
      'fa-briefcase',
      'fa-btc',
      'fa-bug',
      'fa-building',
      'fa-building-o',
      'fa-bullhorn',
      'fa-bullseye',
      'fa-bus',
      'fa-buysellads',
      'fa-cab',
      'fa-calculator',
      'fa-calendar',
      'fa-calendar-check-o',
      'fa-calendar-minus-o',
      'fa-calendar-o',
      'fa-calendar-plus-o',
      'fa-calendar-times-o',
      'fa-camera',
      'fa-camera-retro',
      'fa-car',
      'fa-caret-down',
      'fa-caret-left',
      'fa-caret-right',
      'fa-caret-square-o-down',
      'fa-caret-square-o-left',
      'fa-caret-square-o-right',
      'fa-caret-square-o-up',
      'fa-caret-up',
      'fa-cart-arrow-down',
      'fa-cart-plus',
      'fa-cc',
      'fa-cc-amex',
      'fa-cc-diners-club',
      'fa-cc-discover',
      'fa-cc-jcb',
      'fa-cc-mastercard',
      'fa-cc-paypal',
      'fa-cc-stripe',
      'fa-cc-visa',
      'fa-certificate',
      'fa-chain',
      'fa-chain-broken',
      'fa-check',
      'fa-check-circle',
      'fa-check-circle-o',
      'fa-check-square',
      'fa-check-square-o',
      'fa-chevron-circle-down',
      'fa-chevron-circle-left',
      'fa-chevron-circle-right',
      'fa-chevron-circle-up',
      'fa-chevron-down',
      'fa-chevron-left',
      'fa-chevron-right',
      'fa-chevron-up',
      'fa-child',
      'fa-chrome',
      'fa-circle',
      'fa-circle-o',
      'fa-circle-o-notch',
      'fa-circle-thin',
      'fa-clipboard',
      'fa-clock-o',
      'fa-clone',
      'fa-close',
      'fa-cloud',
      'fa-cloud-download',
      'fa-cloud-upload',
      'fa-cny',
      'fa-code',
      'fa-code-fork',
      'fa-codepen',
      'fa-codiepie',
      'fa-coffee',
      'fa-cog',
      'fa-cogs',
      'fa-columns',
      'fa-comment',
      'fa-comment-o',
      'fa-commenting',
      'fa-commenting-o',
      'fa-comments',
      'fa-comments-o',
      'fa-compass',
      'fa-compress',
      'fa-connectdevelop',
      'fa-contao',
      'fa-copy',
      'fa-copyright',
      'fa-creative-commons',
      'fa-credit-card',
      'fa-credit-card-alt',
      'fa-crop',
      'fa-crosshairs',
      'fa-css3',
      'fa-cube',
      'fa-cubes',
      'fa-cut',
      'fa-cutlery',
      'fa-dashboard',
      'fa-dashcube',
      'fa-database',
      'fa-deaf',
      'fa-deafness',
      'fa-dedent',
      'fa-delicious',
      'fa-desktop',
      'fa-deviantart',
      'fa-diamond',
      'fa-digg',
      'fa-dollar',
      'fa-dot-circle-o',
      'fa-download',
      'fa-dribbble',
      'fa-drivers-license',
      'fa-drivers-license-o',
      'fa-dropbox',
      'fa-drupal',
      'fa-edge',
      'fa-edit',
      'fa-eercast',
      'fa-eject',
      'fa-ellipsis-h',
      'fa-ellipsis-v',
      'fa-empire',
      'fa-envelope',
      'fa-envelope-o',
      'fa-envelope-open',
      'fa-envelope-open-o',
      'fa-envelope-square',
      'fa-envira',
      'fa-eraser',
      'fa-etsy',
      'fa-eur',
      'fa-euro',
      'fa-exchange',
      'fa-exclamation',
      'fa-exclamation-circle',
      'fa-exclamation-triangle',
      'fa-expand',
      'fa-expeditedssl',
      'fa-external-link',
      'fa-external-link-square',
      'fa-eye',
      'fa-eye-slash',
      'fa-eyedropper',
      'fa-fa',
      'fa-facebook',
      'fa-facebook-f',
      'fa-facebook-official',
      'fa-facebook-square',
      'fa-fast-backward',
      'fa-fast-forward',
      'fa-fax',
      'fa-feed',
      'fa-female',
      'fa-fighter-jet',
      'fa-file',
      'fa-file-archive-o',
      'fa-file-audio-o',
      'fa-file-code-o',
      'fa-file-excel-o',
      'fa-file-image-o',
      'fa-file-movie-o',
      'fa-file-o',
      'fa-file-pdf-o',
      'fa-file-photo-o',
      'fa-file-picture-o',
      'fa-file-powerpoint-o',
      'fa-file-sound-o',
      'fa-file-text',
      'fa-file-text-o',
      'fa-file-video-o',
      'fa-file-word-o',
      'fa-file-zip-o',
      'fa-files-o',
      'fa-film',
      'fa-filter',
      'fa-fire',
      'fa-fire-extinguisher',
      'fa-firefox',
      'fa-first-order',
      'fa-flag',
      'fa-flag-checkered',
      'fa-flag-o',
      'fa-flash',
      'fa-flask',
      'fa-flickr',
      'fa-floppy-o',
      'fa-folder',
      'fa-folder-o',
      'fa-folder-open',
      'fa-folder-open-o',
      'fa-font',
      'fa-font-awesome',
      'fa-fonticons',
      'fa-fort-awesome',
      'fa-forumbee',
      'fa-forward',
      'fa-foursquare',
      'fa-free-code-camp',
      'fa-frown-o',
      'fa-futbol-o',
      'fa-gamepad',
      'fa-gavel',
      'fa-gbp',
      'fa-ge',
      'fa-gear',
      'fa-gears',
      'fa-genderless',
      'fa-get-pocket',
      'fa-gg',
      'fa-gg-circle',
      'fa-gift',
      'fa-git',
      'fa-git-square',
      'fa-github',
      'fa-github-alt',
      'fa-github-square',
      'fa-gitlab',
      'fa-gittip',
      'fa-glass',
      'fa-glide',
      'fa-glide-g',
      'fa-globe',
      'fa-google',
      'fa-google-plus',
      'fa-google-plus-circle',
      'fa-google-plus-official',
      'fa-google-plus-square',
      'fa-google-wallet',
      'fa-graduation-cap',
      'fa-gratipay',
      'fa-grav',
      'fa-group',
      'fa-h-square',
      'fa-hacker-news',
      'fa-hand-grab-o',
      'fa-hand-lizard-o',
      'fa-hand-o-down',
      'fa-hand-o-left',
      'fa-hand-o-right',
      'fa-hand-o-up',
      'fa-hand-paper-o',
      'fa-hand-peace-o',
      'fa-hand-pointer-o',
      'fa-hand-rock-o',
      'fa-hand-scissors-o',
      'fa-hand-spock-o',
      'fa-hand-stop-o',
      'fa-handshake-o',
      'fa-hard-of-hearing',
      'fa-hashtag',
      'fa-hdd-o',
      'fa-header',
      'fa-headphones',
      'fa-heart',
      'fa-heart-o',
      'fa-heartbeat',
      'fa-history',
      'fa-home',
      'fa-hospital-o',
      'fa-hotel',
      'fa-hourglass',
      'fa-hourglass-1',
      'fa-hourglass-2',
      'fa-hourglass-3',
      'fa-hourglass-end',
      'fa-hourglass-half',
      'fa-hourglass-o',
      'fa-hourglass-start',
      'fa-houzz',
      'fa-html5',
      'fa-i-cursor',
      'fa-id-badge',
      'fa-id-card',
      'fa-id-card-o',
      'fa-ils',
      'fa-image',
      'fa-imdb',
      'fa-inbox',
      'fa-indent',
      'fa-industry',
      'fa-info',
      'fa-info-circle',
      'fa-inr',
      'fa-instagram',
      'fa-institution',
      'fa-internet-explorer',
      'fa-intersex',
      'fa-ioxhost',
      'fa-italic',
      'fa-joomla',
      'fa-jpy',
      'fa-jsfiddle',
      'fa-key',
      'fa-keyboard-o',
      'fa-krw',
      'fa-language',
      'fa-laptop',
      'fa-lastfm',
      'fa-lastfm-square',
      'fa-leaf',
      'fa-leanpub',
      'fa-legal',
      'fa-lemon-o',
      'fa-level-down',
      'fa-level-up',
      'fa-life-bouy',
      'fa-life-buoy',
      'fa-life-ring',
      'fa-life-saver',
      'fa-lightbulb-o',
      'fa-line-chart',
      'fa-link',
      'fa-linkedin',
      'fa-linkedin-square',
      'fa-linode',
      'fa-linux',
      'fa-list',
      'fa-list-alt',
      'fa-list-ol',
      'fa-list-ul',
      'fa-location-arrow',
      'fa-lock',
      'fa-long-arrow-down',
      'fa-long-arrow-left',
      'fa-long-arrow-right',
      'fa-long-arrow-up',
      'fa-low-vision',
      'fa-magic',
      'fa-magnet',
      'fa-mail-forward',
      'fa-mail-reply',
      'fa-mail-reply-all',
      'fa-male',
      'fa-map',
      'fa-map-marker',
      'fa-map-o',
      'fa-map-pin',
      'fa-map-signs',
      'fa-mars',
      'fa-mars-double',
      'fa-mars-stroke',
      'fa-mars-stroke-h',
      'fa-mars-stroke-v',
      'fa-maxcdn',
      'fa-meanpath',
      'fa-medium',
      'fa-medkit',
      'fa-meetup',
      'fa-meh-o',
      'fa-mercury',
      'fa-microchip',
      'fa-microphone',
      'fa-microphone-slash',
      'fa-minus',
      'fa-minus-circle',
      'fa-minus-square',
      'fa-minus-square-o',
      'fa-mixcloud',
      'fa-mobile',
      'fa-mobile-phone',
      'fa-modx',
      'fa-money',
      'fa-moon-o',
      'fa-mortar-board',
      'fa-motorcycle',
      'fa-mouse-pointer',
      'fa-music',
      'fa-navicon',
      'fa-neuter',
      'fa-newspaper-o',
      'fa-object-group',
      'fa-object-ungroup',
      'fa-odnoklassniki',
      'fa-odnoklassniki-square',
      'fa-opencart',
      'fa-openid',
      'fa-opera',
      'fa-optin-monster',
      'fa-outdent',
      'fa-pagelines',
      'fa-paint-brush',
      'fa-paper-plane',
      'fa-paper-plane-o',
      'fa-paperclip',
      'fa-paragraph',
      'fa-paste',
      'fa-pause',
      'fa-pause-circle',
      'fa-pause-circle-o',
      'fa-paw',
      'fa-paypal',
      'fa-pencil',
      'fa-pencil-square',
      'fa-pencil-square-o',
      'fa-percent',
      'fa-phone',
      'fa-phone-square',
      'fa-photo',
      'fa-picture-o',
      'fa-pie-chart',
      'fa-pied-piper',
      'fa-pied-piper-alt',
      'fa-pied-piper-pp',
      'fa-pinterest',
      'fa-pinterest-p',
      'fa-pinterest-square',
      'fa-plane',
      'fa-play',
      'fa-play-circle',
      'fa-play-circle-o',
      'fa-plug',
      'fa-plus',
      'fa-plus-circle',
      'fa-plus-square',
      'fa-plus-square-o',
      'fa-podcast',
      'fa-power-off',
      'fa-print',
      'fa-product-hunt',
      'fa-puzzle-piece',
      'fa-qq',
      'fa-qrcode',
      'fa-question',
      'fa-question-circle',
      'fa-question-circle-o',
      'fa-quora',
      'fa-quote-left',
      'fa-quote-right',
      'fa-ra',
      'fa-random',
      'fa-ravelry',
      'fa-rebel',
      'fa-recycle',
      'fa-reddit',
      'fa-reddit-alien',
      'fa-reddit-square',
      'fa-refresh',
      'fa-registered',
      'fa-remove',
      'fa-renren',
      'fa-reorder',
      'fa-repeat',
      'fa-reply',
      'fa-reply-all',
      'fa-resistance',
      'fa-retweet',
      'fa-rmb',
      'fa-road',
      'fa-rocket',
      'fa-rotate-left',
      'fa-rotate-right',
      'fa-rouble',
      'fa-rss',
      'fa-rss-square',
      'fa-rub',
      'fa-ruble',
      'fa-rupee',
      'fa-s15',
      'fa-safari',
      'fa-save',
      'fa-scissors',
      'fa-scribd',
      'fa-search',
      'fa-search-minus',
      'fa-search-plus',
      'fa-sellsy',
      'fa-send',
      'fa-send-o',
      'fa-server',
      'fa-share',
      'fa-share-alt',
      'fa-share-alt-square',
      'fa-share-square',
      'fa-share-square-o',
      'fa-shekel',
      'fa-sheqel',
      'fa-shield',
      'fa-ship',
      'fa-shirtsinbulk',
      'fa-shopping-bag',
      'fa-shopping-basket',
      'fa-shopping-cart',
      'fa-shower',
      'fa-sign-in',
      'fa-sign-language',
      'fa-sign-out',
      'fa-signal',
      'fa-signing',
      'fa-simplybuilt',
      'fa-sitemap',
      'fa-skyatlas',
      'fa-skype',
      'fa-slack',
      'fa-sliders',
      'fa-slideshare',
      'fa-smile-o',
      'fa-snapchat',
      'fa-snapchat-ghost',
      'fa-snapchat-square',
      'fa-snowflake-o',
      'fa-soccer-ball-o',
      'fa-sort',
      'fa-sort-alpha-asc',
      'fa-sort-alpha-desc',
      'fa-sort-amount-asc',
      'fa-sort-amount-desc',
      'fa-sort-asc',
      'fa-sort-desc',
      'fa-sort-down',
      'fa-sort-numeric-asc',
      'fa-sort-numeric-desc',
      'fa-sort-up',
      'fa-soundcloud',
      'fa-space-shuttle',
      'fa-spinner',
      'fa-spoon',
      'fa-spotify',
      'fa-square',
      'fa-square-o',
      'fa-stack-exchange',
      'fa-stack-overflow',
      'fa-star',
      'fa-star-half',
      'fa-star-half-empty',
      'fa-star-half-full',
      'fa-star-half-o',
      'fa-star-o',
      'fa-steam',
      'fa-steam-square',
      'fa-step-backward',
      'fa-step-forward',
      'fa-stethoscope',
      'fa-sticky-note',
      'fa-sticky-note-o',
      'fa-stop',
      'fa-stop-circle',
      'fa-stop-circle-o',
      'fa-street-view',
      'fa-strikethrough',
      'fa-stumbleupon',
      'fa-stumbleupon-circle',
      'fa-subscript',
      'fa-subway',
      'fa-suitcase',
      'fa-sun-o',
      'fa-superpowers',
      'fa-superscript',
      'fa-support',
      'fa-table',
      'fa-tablet',
      'fa-tachometer',
      'fa-tag',
      'fa-tags',
      'fa-tasks',
      'fa-taxi',
      'fa-telegram',
      'fa-television',
      'fa-tencent-weibo',
      'fa-terminal',
      'fa-text-height',
      'fa-text-width',
      'fa-th',
      'fa-th-large',
      'fa-th-list',
      'fa-themeisle',
      'fa-thermometer',
      'fa-thermometer-0',
      'fa-thermometer-1',
      'fa-thermometer-2',
      'fa-thermometer-3',
      'fa-thermometer-4',
      'fa-thermometer-empty',
      'fa-thermometer-full',
      'fa-thermometer-half',
      'fa-thermometer-quarter',
      'fa-thermometer-three-quarters',
      'fa-thumb-tack',
      'fa-thumbs-down',
      'fa-thumbs-o-down',
      'fa-thumbs-o-up',
      'fa-thumbs-up',
      'fa-ticket',
      'fa-times',
      'fa-times-circle',
      'fa-times-circle-o',
      'fa-times-rectangle',
      'fa-times-rectangle-o',
      'fa-tint',
      'fa-toggle-down',
      'fa-toggle-left',
      'fa-toggle-off',
      'fa-toggle-on',
      'fa-toggle-right',
      'fa-toggle-up',
      'fa-trademark',
      'fa-train',
      'fa-transgender',
      'fa-transgender-alt',
      'fa-trash',
      'fa-trash-o',
      'fa-tree',
      'fa-trello',
      'fa-tripadvisor',
      'fa-trophy',
      'fa-truck',
      'fa-try',
      'fa-tty',
      'fa-tumblr',
      'fa-tumblr-square',
      'fa-turkish-lira',
      'fa-tv',
      'fa-twitch',
      'fa-twitter',
      'fa-twitter-square',
      'fa-umbrella',
      'fa-underline',
      'fa-undo',
      'fa-universal-access',
      'fa-university',
      'fa-unlink',
      'fa-unlock',
      'fa-unlock-alt',
      'fa-unsorted',
      'fa-upload',
      'fa-usb',
      'fa-usd',
      'fa-user',
      'fa-user-circle',
      'fa-user-circle-o',
      'fa-user-md',
      'fa-user-o',
      'fa-user-plus',
      'fa-user-secret',
      'fa-user-times',
      'fa-users',
      'fa-vcard',
      'fa-vcard-o',
      'fa-venus',
      'fa-venus-double',
      'fa-venus-mars',
      'fa-viacoin',
      'fa-viadeo',
      'fa-viadeo-square',
      'fa-video-camera',
      'fa-vimeo',
      'fa-vimeo-square',
      'fa-vine',
      'fa-vk',
      'fa-volume-control-phone',
      'fa-volume-down',
      'fa-volume-off',
      'fa-volume-up',
      'fa-warning',
      'fa-wechat',
      'fa-weibo',
      'fa-weixin',
      'fa-whatsapp',
      'fa-wheelchair',
      'fa-wheelchair-alt',
      'fa-wifi',
      'fa-wikipedia-w',
      'fa-window-close',
      'fa-window-close-o',
      'fa-window-maximize',
      'fa-window-minimize',
      'fa-window-restore',
      'fa-windows',
      'fa-won',
      'fa-wordpress',
      'fa-wpbeginner',
      'fa-wpexplorer',
      'fa-wpforms',
      'fa-wrench',
      'fa-xing',
      'fa-xing-square',
      'fa-y-combinator',
      'fa-y-combinator-square',
      'fa-yahoo',
      'fa-yc',
      'fa-yc-square',
      'fa-yelp',
      'fa-yen',
      'fa-yoast',
      'fa-youtube',
      'fa-youtube-play',
      'fa-youtube-square'
    ],
    newIcons: [
      'fa-500px',
      'fa-address-book',
      'fa-address-book-o',
      'fa-address-card',
      'fa-address-card-o',
      'fa-adjust',
      'fa-adn',
      'fa-align-center',
      'fa-align-justify',
      'fa-align-left',
      'fa-align-right',
      'fa-amazon',
      'fa-ambulance',
      'fa-american-sign-language-interpreting',
      'fa-anchor',
      'fa-android',
      'fa-angellist',
      'fa-angle-double-down',
      'fa-angle-double-left',
      'fa-angle-double-right',
      'fa-angle-double-up',
      'fa-angle-down',
      'fa-angle-left',
      'fa-angle-right',
      'fa-angle-up',
      'fa-apple',
      'fa-archive',
      'fa-area-chart',
      'fa-arrow-circle-down',
      'fa-arrow-circle-left',
      'fa-arrow-circle-o-down',
      'fa-arrow-circle-o-left',
      'fa-arrow-circle-o-right',
      'fa-arrow-circle-o-up',
      'fa-arrow-circle-right',
      'fa-arrow-circle-up',
      'fa-arrow-down',
      'fa-arrow-left',
      'fa-arrow-right',
      'fa-arrow-up',
      'fa-arrows',
      'fa-arrows-alt',
      'fa-arrows-h',
      'fa-arrows-v',
      'fa-asl-interpreting',
      'fa-assistive-listening-systems',
      'fa-asterisk',
      'fa-at',
      'fa-audio-description',
      'fa-automobile',
      'fa-backward',
      'fa-balance-scale',
      'fa-ban',
      'fa-bandcamp',
      'fa-bank',
      'fa-bar-chart',
      'fa-bar-chart-o',
      'fa-barcode',
      'fa-bars',
      'fa-bath',
      'fa-bathtub',
      'fa-battery',
      'fa-battery-0',
      'fa-battery-1',
      'fa-battery-2',
      'fa-battery-3',
      'fa-battery-4',
      'fa-battery-empty',
      'fa-battery-full',
      'fa-battery-half',
      'fa-battery-quarter',
      'fa-battery-three-quarters',
      'fa-bed',
      'fa-beer',
      'fa-behance',
      'fa-behance-square',
      'fa-bell',
      'fa-bell-o',
      'fa-bell-slash',
      'fa-bell-slash-o',
      'fa-bicycle',
      'fa-binoculars',
      'fa-birthday-cake',
      'fa-bitbucket',
      'fa-bitbucket-square',
      'fa-bitcoin',
      'fa-black-tie',
      'fa-blind',
      'fa-bluetooth',
      'fa-bluetooth-b',
      'fa-bold',
      'fa-bolt',
      'fa-bomb',
      'fa-book',
      'fa-bookmark',
      'fa-bookmark-o',
      'fa-braille',
      'fa-briefcase',
      'fa-btc',
      'fa-bug',
      'fa-building',
      'fa-building-o',
      'fa-bullhorn',
      'fa-bullseye',
      'fa-bus',
      'fa-buysellads',
      'fa-cab',
      'fa-calculator',
      'fa-calendar',
      'fa-calendar-check-o',
      'fa-calendar-minus-o',
      'fa-calendar-o',
      'fa-calendar-plus-o',
      'fa-calendar-times-o',
      'fa-camera',
      'fa-camera-retro',
      'fa-car',
      'fa-caret-down',
      'fa-caret-left',
      'fa-caret-right',
      'fa-caret-square-o-down',
      'fa-caret-square-o-left',
      'fa-caret-square-o-right',
      'fa-caret-square-o-up',
      'fa-caret-up',
      'fa-cart-arrow-down',
      'fa-cart-plus',
      'fa-cc',
      'fa-cc-amex',
      'fa-cc-diners-club',
      'fa-cc-discover',
      'fa-cc-jcb',
      'fa-cc-mastercard',
      'fa-cc-paypal',
      'fa-cc-stripe',
      'fa-cc-visa',
      'fa-certificate',
      'fa-chain',
      'fa-chain-broken',
      'fa-check',
      'fa-check-circle',
      'fa-check-circle-o',
      'fa-check-square',
      'fa-check-square-o',
      'fa-chevron-circle-down',
      'fa-chevron-circle-left',
      'fa-chevron-circle-right',
      'fa-chevron-circle-up',
      'fa-chevron-down',
      'fa-chevron-left',
      'fa-chevron-right',
      'fa-chevron-up',
      'fa-child',
      'fa-chrome',
      'fa-circle',
      'fa-circle-o',
      'fa-circle-o-notch',
      'fa-circle-thin',
      'fa-clipboard',
      'fa-clock-o',
      'fa-clone',
      'fa-close',
      'fa-cloud',
      'fa-cloud-download',
      'fa-cloud-upload',
      'fa-cny',
      'fa-code',
      'fa-code-fork',
      'fa-codepen',
      'fa-codiepie',
      'fa-coffee',
      'fa-cog',
      'fa-cogs',
      'fa-columns',
      'fa-comment',
      'fa-comment-o',
      'fa-commenting',
      'fa-commenting-o',
      'fa-comments',
      'fa-comments-o',
      'fa-compass',
      'fa-compress',
      'fa-connectdevelop',
      'fa-contao',
      'fa-copy',
      'fa-copyright',
      'fa-creative-commons',
      'fa-credit-card',
      'fa-credit-card-alt',
      'fa-crop',
      'fa-crosshairs',
      'fa-css3',
      'fa-cube',
      'fa-cubes',
      'fa-cut',
      'fa-cutlery',
      'fa-dashboard',
      'fa-dashcube',
      'fa-database',
      'fa-deaf',
      'fa-deafness',
      'fa-dedent',
      'fa-delicious',
      'fa-desktop',
      'fa-deviantart',
      'fa-diamond',
      'fa-digg',
      'fa-dollar',
      'fa-dot-circle-o',
      'fa-download',
      'fa-dribbble',
      'fa-drivers-license',
      'fa-drivers-license-o',
      'fa-dropbox',
      'fa-drupal',
      'fa-edge',
      'fa-edit',
      'fa-eercast',
      'fa-eject',
      'fa-ellipsis-h',
      'fa-ellipsis-v',
      'fa-empire',
      'fa-envelope',
      'fa-envelope-o',
      'fa-envelope-open',
      'fa-envelope-open-o',
      'fa-envelope-square',
      'fa-envira',
      'fa-eraser',
      'fa-etsy',
      'fa-eur',
      'fa-euro',
      'fa-exchange',
      'fa-exclamation',
      'fa-exclamation-circle',
      'fa-exclamation-triangle',
      'fa-expand',
      'fa-expeditedssl',
      'fa-external-link',
      'fa-external-link-square',
      'fa-eye',
      'fa-eye-slash',
      'fa-eyedropper',
      'fa-fa',
      'fa-facebook',
      'fa-facebook-f',
      'fa-facebook-official',
      'fa-facebook-square',
      'fa-fast-backward',
      'fa-fast-forward',
      'fa-fax',
      'fa-feed',
      'fa-female',
      'fa-fighter-jet',
      'fa-file',
      'fa-file-archive-o',
      'fa-file-audio-o',
      'fa-file-code-o',
      'fa-file-excel-o',
      'fa-file-image-o',
      'fa-file-movie-o',
      'fa-file-o',
      'fa-file-pdf-o',
      'fa-file-photo-o',
      'fa-file-picture-o',
      'fa-file-powerpoint-o',
      'fa-file-sound-o',
      'fa-file-text',
      'fa-file-text-o',
      'fa-file-video-o',
      'fa-file-word-o',
      'fa-file-zip-o',
      'fa-files-o',
      'fa-film',
      'fa-filter',
      'fa-fire',
      'fa-fire-extinguisher',
      'fa-firefox',
      'fa-first-order',
      'fa-flag',
      'fa-flag-checkered',
      'fa-flag-o',
      'fa-flash',
      'fa-flask',
      'fa-flickr',
      'fa-floppy-o',
      'fa-folder',
      'fa-folder-o',
      'fa-folder-open',
      'fa-folder-open-o',
      'fa-font',
      'fa-font-awesome',
      'fa-fonticons',
      'fa-fort-awesome',
      'fa-forumbee',
      'fa-forward',
      'fa-foursquare',
      'fa-free-code-camp',
      'fa-frown-o',
      'fa-futbol-o',
      'fa-gamepad',
      'fa-gavel',
      'fa-gbp',
      'fa-ge',
      'fa-gear',
      'fa-gears',
      'fa-genderless',
      'fa-get-pocket',
      'fa-gg',
      'fa-gg-circle',
      'fa-gift',
      'fa-git',
      'fa-git-square',
      'fa-github',
      'fa-github-alt',
      'fa-github-square',
      'fa-gitlab',
      'fa-gittip',
      'fa-glass',
      'fa-glide',
      'fa-glide-g',
      'fa-globe',
      'fa-google',
      'fa-google-plus',
      'fa-google-plus-circle',
      'fa-google-plus-official',
      'fa-google-plus-square',
      'fa-google-wallet',
      'fa-graduation-cap',
      'fa-gratipay',
      'fa-grav',
      'fa-group',
      'fa-h-square',
      'fa-hacker-news',
      'fa-hand-grab-o',
      'fa-hand-lizard-o',
      'fa-hand-o-down',
      'fa-hand-o-left',
      'fa-hand-o-right',
      'fa-hand-o-up',
      'fa-hand-paper-o',
      'fa-hand-peace-o',
      'fa-hand-pointer-o',
      'fa-hand-rock-o',
      'fa-hand-scissors-o',
      'fa-hand-spock-o',
      'fa-hand-stop-o',
      'fa-handshake-o',
      'fa-hard-of-hearing',
      'fa-hashtag',
      'fa-hdd-o',
      'fa-header',
      'fa-headphones',
      'fa-heart',
      'fa-heart-o',
      'fa-heartbeat',
      'fa-history',
      'fa-home',
      'fa-hospital-o',
      'fa-hotel',
      'fa-hourglass',
      'fa-hourglass-1',
      'fa-hourglass-2',
      'fa-hourglass-3',
      'fa-hourglass-end',
      'fa-hourglass-half',
      'fa-hourglass-o',
      'fa-hourglass-start',
      'fa-houzz',
      'fa-html5',
      'fa-i-cursor',
      'fa-id-badge',
      'fa-id-card',
      'fa-id-card-o',
      'fa-ils',
      'fa-image',
      'fa-imdb',
      'fa-inbox',
      'fa-indent',
      'fa-industry',
      'fa-info',
      'fa-info-circle',
      'fa-inr',
      'fa-instagram',
      'fa-institution',
      'fa-internet-explorer',
      'fa-intersex',
      'fa-ioxhost',
      'fa-italic',
      'fa-joomla',
      'fa-jpy',
      'fa-jsfiddle',
      'fa-key',
      'fa-keyboard-o',
      'fa-krw',
      'fa-language',
      'fa-laptop',
      'fa-lastfm',
      'fa-lastfm-square',
      'fa-leaf',
      'fa-leanpub',
      'fa-legal',
      'fa-lemon-o',
      'fa-level-down',
      'fa-level-up',
      'fa-life-bouy',
      'fa-life-buoy',
      'fa-life-ring',
      'fa-life-saver',
      'fa-lightbulb-o',
      'fa-line-chart',
      'fa-link',
      'fa-linkedin',
      'fa-linkedin-square',
      'fa-linode',
      'fa-linux',
      'fa-list',
      'fa-list-alt',
      'fa-list-ol',
      'fa-list-ul',
      'fa-location-arrow',
      'fa-lock',
      'fa-long-arrow-down',
      'fa-long-arrow-left',
      'fa-long-arrow-right',
      'fa-long-arrow-up',
      'fa-low-vision',
      'fa-magic',
      'fa-magnet',
      'fa-mail-forward',
      'fa-mail-reply',
      'fa-mail-reply-all',
      'fa-male',
      'fa-map',
      'fa-map-marker',
      'fa-map-o',
      'fa-map-pin',
      'fa-map-signs',
      'fa-mars',
      'fa-mars-double',
      'fa-mars-stroke',
      'fa-mars-stroke-h',
      'fa-mars-stroke-v',
      'fa-maxcdn',
      'fa-meanpath',
      'fa-medium',
      'fa-medkit',
      'fa-meetup',
      'fa-meh-o',
      'fa-mercury',
      'fa-microchip',
      'fa-microphone',
      'fa-microphone-slash',
      'fa-minus',
      'fa-minus-circle',
      'fa-minus-square',
      'fa-minus-square-o',
      'fa-mixcloud',
      'fa-mobile',
      'fa-mobile-phone',
      'fa-modx',
      'fa-money',
      'fa-moon-o',
      'fa-mortar-board',
      'fa-motorcycle',
      'fa-mouse-pointer',
      'fa-music',
      'fa-navicon',
      'fa-neuter',
      'fa-newspaper-o',
      'fa-object-group',
      'fa-object-ungroup',
      'fa-odnoklassniki',
      'fa-odnoklassniki-square',
      'fa-opencart',
      'fa-openid',
      'fa-opera',
      'fa-optin-monster',
      'fa-outdent',
      'fa-pagelines',
      'fa-paint-brush',
      'fa-paper-plane',
      'fa-paper-plane-o',
      'fa-paperclip',
      'fa-paragraph',
      'fa-paste',
      'fa-pause',
      'fa-pause-circle',
      'fa-pause-circle-o',
      'fa-paw',
      'fa-paypal',
      'fa-pencil',
      'fa-pencil-square',
      'fa-pencil-square-o',
      'fa-percent',
      'fa-phone',
      'fa-phone-square',
      'fa-photo',
      'fa-picture-o',
      'fa-pie-chart',
      'fa-pied-piper',
      'fa-pied-piper-alt',
      'fa-pied-piper-pp',
      'fa-pinterest',
      'fa-pinterest-p',
      'fa-pinterest-square',
      'fa-plane',
      'fa-play',
      'fa-play-circle',
      'fa-play-circle-o',
      'fa-plug',
      'fa-plus',
      'fa-plus-circle',
      'fa-plus-square',
      'fa-plus-square-o',
      'fa-podcast',
      'fa-power-off',
      'fa-print',
      'fa-product-hunt',
      'fa-puzzle-piece',
      'fa-qq',
      'fa-qrcode',
      'fa-question',
      'fa-question-circle',
      'fa-question-circle-o',
      'fa-quora',
      'fa-quote-left',
      'fa-quote-right',
      'fa-ra',
      'fa-random',
      'fa-ravelry',
      'fa-rebel',
      'fa-recycle',
      'fa-reddit',
      'fa-reddit-alien',
      'fa-reddit-square',
      'fa-refresh',
      'fa-registered',
      'fa-remove',
      'fa-renren',
      'fa-reorder',
      'fa-repeat',
      'fa-reply',
      'fa-reply-all',
      'fa-resistance',
      'fa-retweet',
      'fa-rmb',
      'fa-road',
      'fa-rocket',
      'fa-rotate-left',
      'fa-rotate-right',
      'fa-rouble',
      'fa-rss',
      'fa-rss-square',
      'fa-rub',
      'fa-ruble',
      'fa-rupee',
      'fa-s15',
      'fa-safari',
      'fa-save',
      'fa-scissors',
      'fa-scribd',
      'fa-search',
      'fa-search-minus',
      'fa-search-plus',
      'fa-sellsy',
      'fa-send',
      'fa-send-o',
      'fa-server',
      'fa-share',
      'fa-share-alt',
      'fa-share-alt-square',
      'fa-share-square',
      'fa-share-square-o',
      'fa-shekel',
      'fa-sheqel',
      'fa-shield',
      'fa-ship',
      'fa-shirtsinbulk',
      'fa-shopping-bag',
      'fa-shopping-basket',
      'fa-shopping-cart',
      'fa-shower',
      'fa-sign-in',
      'fa-sign-language',
      'fa-sign-out',
      'fa-signal',
      'fa-signing',
      'fa-simplybuilt',
      'fa-sitemap',
      'fa-skyatlas',
      'fa-skype',
      'fa-slack',
      'fa-sliders',
      'fa-slideshare',
      'fa-smile-o',
      'fa-snapchat',
      'fa-snapchat-ghost',
      'fa-snapchat-square',
      'fa-snowflake-o',
      'fa-soccer-ball-o',
      'fa-sort',
      'fa-sort-alpha-asc',
      'fa-sort-alpha-desc',
      'fa-sort-amount-asc',
      'fa-sort-amount-desc',
      'fa-sort-asc',
      'fa-sort-desc',
      'fa-sort-down',
      'fa-sort-numeric-asc',
      'fa-sort-numeric-desc',
      'fa-sort-up',
      'fa-soundcloud',
      'fa-space-shuttle',
      'fa-spinner',
      'fa-spoon',
      'fa-spotify',
      'fa-square',
      'fa-square-o',
      'fa-stack-exchange',
      'fa-stack-overflow',
      'fa-star',
      'fa-star-half',
      'fa-star-half-empty',
      'fa-star-half-full',
      'fa-star-half-o',
      'fa-star-o',
      'fa-steam',
      'fa-steam-square',
      'fa-step-backward',
      'fa-step-forward',
      'fa-stethoscope',
      'fa-sticky-note',
      'fa-sticky-note-o',
      'fa-stop',
      'fa-stop-circle',
      'fa-stop-circle-o',
      'fa-street-view',
      'fa-strikethrough',
      'fa-stumbleupon',
      'fa-stumbleupon-circle',
      'fa-subscript',
      'fa-subway',
      'fa-suitcase',
      'fa-sun-o',
      'fa-superpowers',
      'fa-superscript',
      'fa-support',
      'fa-table',
      'fa-tablet',
      'fa-tachometer',
      'fa-tag',
      'fa-tags',
      'fa-tasks',
      'fa-taxi',
      'fa-telegram',
      'fa-television',
      'fa-tencent-weibo',
      'fa-terminal',
      'fa-text-height',
      'fa-text-width',
      'fa-th',
      'fa-th-large',
      'fa-th-list',
      'fa-themeisle',
      'fa-thermometer',
      'fa-thermometer-0',
      'fa-thermometer-1',
      'fa-thermometer-2',
      'fa-thermometer-3',
      'fa-thermometer-4',
      'fa-thermometer-empty',
      'fa-thermometer-full',
      'fa-thermometer-half',
      'fa-thermometer-quarter',
      'fa-thermometer-three-quarters',
      'fa-thumb-tack',
      'fa-thumbs-down',
      'fa-thumbs-o-down',
      'fa-thumbs-o-up',
      'fa-thumbs-up',
      'fa-ticket',
      'fa-times',
      'fa-times-circle',
      'fa-times-circle-o',
      'fa-times-rectangle',
      'fa-times-rectangle-o',
      'fa-tint',
      'fa-toggle-down',
      'fa-toggle-left',
      'fa-toggle-off',
      'fa-toggle-on',
      'fa-toggle-right',
      'fa-toggle-up',
      'fa-trademark',
      'fa-train',
      'fa-transgender',
      'fa-transgender-alt',
      'fa-trash',
      'fa-trash-o',
      'fa-tree',
      'fa-trello',
      'fa-tripadvisor',
      'fa-trophy',
      'fa-truck',
      'fa-try',
      'fa-tty',
      'fa-tumblr',
      'fa-tumblr-square',
      'fa-turkish-lira',
      'fa-tv',
      'fa-twitch',
      'fa-twitter',
      'fa-twitter-square',
      'fa-umbrella',
      'fa-underline',
      'fa-undo',
      'fa-universal-access',
      'fa-university',
      'fa-unlink',
      'fa-unlock',
      'fa-unlock-alt',
      'fa-unsorted',
      'fa-upload',
      'fa-usb',
      'fa-usd',
      'fa-user',
      'fa-user-circle',
      'fa-user-circle-o',
      'fa-user-md',
      'fa-user-o',
      'fa-user-plus',
      'fa-user-secret',
      'fa-user-times',
      'fa-users',
      'fa-vcard',
      'fa-vcard-o',
      'fa-venus',
      'fa-venus-double',
      'fa-venus-mars',
      'fa-viacoin',
      'fa-viadeo',
      'fa-viadeo-square',
      'fa-video-camera',
      'fa-vimeo',
      'fa-vimeo-square',
      'fa-vine',
      'fa-vk',
      'fa-volume-control-phone',
      'fa-volume-down',
      'fa-volume-off',
      'fa-volume-up',
      'fa-warning',
      'fa-wechat',
      'fa-weibo',
      'fa-weixin',
      'fa-whatsapp',
      'fa-wheelchair',
      'fa-wheelchair-alt',
      'fa-wifi',
      'fa-wikipedia-w',
      'fa-window-close',
      'fa-window-close-o',
      'fa-window-maximize',
      'fa-window-minimize',
      'fa-window-restore',
      'fa-windows',
      'fa-won',
      'fa-wordpress',
      'fa-wpbeginner',
      'fa-wpexplorer',
      'fa-wpforms',
      'fa-wrench',
      'fa-xing',
      'fa-xing-square',
      'fa-y-combinator',
      'fa-y-combinator-square',
      'fa-yahoo',
      'fa-yc',
      'fa-yc-square',
      'fa-yelp',
      'fa-yen',
      'fa-yoast',
      'fa-youtube',
      'fa-youtube-play',
      'fa-youtube-square'
],
    indicator_setting_name:'',
    add_series_data : 0,
    is_indicator_delete : 0,
    store_chart : {},
    allowChartUpdate : ''

    };

    this.searchIcon = this.searchIcon.bind(this);
    this.handleFontChange = this.handleFontChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleUnlock = this.handleUnlock.bind(this);
    this.handleLock = this.handleLock.bind(this);

    // if(this.props.get_indicator_prop_data){ 
    //   //this.render();
    //   console.log('length',JSON.parse(localStorage.getItem('indicator'))?.length)
    //   if(JSON.parse(localStorage.getItem('indicator'))?.length){
    //     this.setState({isIndicatorDivDisplay:true}) 
    //   }
    //   //this.setState({showRandom:Math.random()}) 
    //   console.log('random_number',Math.random())
    //   //alert('as');
    // }
    this.getResult();
  }

  showCode = (indicator_code) =>{
    $('#python_code').text(indicator_code)  
    $('.python_code_editor').toggle()
  }

  removeIndicator = (index) =>{  
    let existing_indicator_arr = JSON.parse(localStorage.getItem('indicator')) 
    existing_indicator_arr.splice(index, 1);  
    if(existing_indicator_arr?.length>0){
      localStorage.setItem("indicator", JSON.stringify(existing_indicator_arr));
    }else{
      localStorage.setItem("indicator", JSON.stringify(existing_indicator_arr)); 
    }
    $(`#indicator_tool_tip_${index}`).remove(); 
    this.setState({is_indicator_delete:Math.random()}) 
  }
  showHideIndicator = (index)=>{
      this.setState({allowChartUpdate:true})
       let existing_indicator_arr = JSON.parse(localStorage.getItem('indicator')) 
    existing_indicator_arr.splice(index, 1); 
    localStorage.setItem("indicator", JSON.stringify(existing_indicator_arr)); 
    this.setState({is_indicator_delete:true})
  }

  removeMaTemplate = (index) =>{ 
    localStorage.removeItem("ma_template"); 
    this.setState({is_indicator_delete:true})
  }
  removeBillTemplate = (index) =>{ 
    localStorage.removeItem("bill_template"); 
    this.setState({is_indicator_delete:true})
  }
  removeDisplacedTemplate = (index) =>{ 
    localStorage.removeItem("displaced_template"); 
    this.setState({is_indicator_delete:true})
  }

  showIndicatorSetting = (e) =>{  
    // Show hide indicator tooltip start  
      let is_active_class  = $(e.target).parent().prop('className').split(' ')[1]
      console.log('is_active_class',is_active_class)
      if(is_active_class == undefined){
        $('.tooltip_custom').removeClass('active')
        $(e.target).parent().addClass('active')  
      }else{
        $('.tooltip_custom').removeClass('active')
        $(e.target).parent().removeClass('active')
      }
    // Show hide indicator tooltip end
  }
  handleUnlock = () => {
    this.setState({
      lock: false
    })
  // alert('dd');
  //   var id = '#'+$('#exmModel').val()+' '+'.unlock';
  //   $(id).css('display', 'none');
  };

  handleLock = () => {

      this.setState({
        lock: true
      })

    // alert('dd++');
    //   var id = '#'+$('#exmModel').val()+' '+'.lockk';
    //   $(id).css('display', 'none');
  };



  handleDelete = () => {
      alert('You have clicked on delete button.');
      var id = '#'+$('#exmModel').val()+' '+'.extraIcon';
      $(id).css('display', 'none');
  };

  searchIcon = event => {

      let iconsArr = this.state.icons; 
      this.setState({
          icons: iconsArr.filter(iconsArr => iconsArr.includes(event.target.value))
      })

  }

  handleFontChange = (event) => {
      var id = '#'+$('#exmModel').val()+' '+'.extraIcon';
      $(id).css('font-size', event.target.value+'px');
  };

  handleClick = () => {
      this.setState({ displayColorPicker: !this.state.displayColorPicker })
  };

  handleClose = () => {
      this.setState({ displayColorPicker: false });
  };

  handleChange = (color) => {
      this.setState({ background: color.hex });
      var id = '#'+$('#exmModel').val()+' '+'.extraIcon';
      $(id).css('color', this.state.background);
  };
  showIndicatorDiv = () => {
    $('.custom_indicator_div').show(); 
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
    //$('.main_indicator_view').hide();
  }
  showTemplateDiv = () => {
    var template_list = JSON.parse(localStorage.getItem('default_template'));
    template_list.map((data)=>{
      if(data.template == 'displaced_template'){
        $('.displaced_template_div').show();
      }else if(data.template == 'bill_template'){
        $('.bill_template_div').show()
      }else if(data.template == 'ma_template'){
        $('.ma_template_div').show();
      }else if(data.template == 'oscillater_template'){
        $('.oscillater_template_div').show();
      }else if(data.template == 'ma_template'){
        $('.ma_template_div').show();
      }else if(data.template == 'swing_template'){
        $('.swing_template_div').show();
      }
    })
    
    this.setState({ displayColorPicker: false });
  }


  componentDidMount() { 
      this.getResult();
      let ohlcValue = this.props.ohlcValue
      let barChangeVal = this.props.bar_change_val
      let marketStatus = this.props.market_status
      let volValue = this.props.volValue
      let prePostMktPrice = (this.props.pre_post_mkt_price === 'true' || this.props.pre_post_mkt_price === true) ? '120' : '';
      let loOverlappingLabel = (this.props.lo_overlapping_label === 'true' || this.props.lo_overlapping_label === true) ? false : true;
      
      let plusButton = (this.props.addPlusAlert === 'true' || this.props.addPlusAlert === true) ? true : false;
      var myElemOHLC = document.getElementById('ohlc_vals');
      var myElemVol = document.getElementById('vol_vals');
      var l_change = document.getElementById('l_change');
      var market_status = document.getElementById('market_status');

      let rangeSelected = 1;
      if (this.props.time == "minute" || this.props.time == "hour") {
          rangeSelected = 0;
      }
  
      const options = {
          chart: {
            events:{
              load: function (e) { 
                //this.getResult();
                var chart = this;
                chart.stockTools.showhideBtn.click();
              }
            },
          type: "candlestick",
          //height: 1700,
          //backgroundColor: this.state.backgroundColor,
        },
          title: {
            text: (this.props.statusLineSymbol === true || this.props.statusLineSymbol === 'true') ? this.getConditionalSymbolData(this.props.symbolVariation) : '',
          },
          xAxis: {

              crosshair: {
                text: 'asdasdas',
                snap: false,
                value: '3434',
                label: {
                    enabled: true,
                    format: '{value:.1f}' // One decimal
                }
              },
              labels: {
                allowOverlap: loOverlappingLabel
              },
              type: 'datetime',
              dateTimeLabelFormats: {
                day: '%b %e'
              }
          },
          navigator: { 
            enabled: false
          },
          plotOptions: {
            candlestick: {
              color: this.props.up_color_color,
              upColor: this.props.down_color_color
            },
            series: {
              color: '#FF0000',
              name: (this.props.statusLineSymbol === true || this.props.statusLineSymbol === 'true') ? this.getConditionalSymbolData(this.props.symbolVariation) : '',
              point: {
                events: {
                  mouseOver: function () {
                    // console.log('ji');
                    var chart = this.series.chart;
                    if (!chart.lbl) {
                      chart.lbl = chart.renderer.label('').attr({
                        padding: 10,
                        r: 10,
                        fill: this.state.background_color
                      }).css({
                        color: '#FFFFFF',
                        background: "#000000"
                      }).add();
                    } else {
                      chart.lbl.fill = "green";
                    }
                    // console.log('this');
                    // console.log(chart.lbl.fill);
                    console.log('ddddd', chart);
                    // console.log(this);
                    // console.log('this');
                    if (this.options.high) {
                      // console.log('object----' + ohlcValue);
                      if (ohlcValue === 'true' || ohlcValue === true) {
                        // console.log('object----' + JSON.stringify(this));
                        chart.lbl
                          .show()
                          .attr({
                            text: '<span style="fill:green" id="market_status">â—</span>  <div id="ohlc_vals">O: <div class="l_open">' + this.open + '</div>  H: <div class="l_high">' + this.high + '</div>  L: <div class="l_low">' + this.low + '</div>  C: <div class="l_close">' + this.y + '</div>  <div id="l_change">âˆ’0.40 (âˆ’0.81%)</div></div>'
                          });
                      }
                    } else {
                      if (volValue === 'true' || volValue === true) {
                        chart.lbl
                          .show()
                          .attr({
                            text: 'Vol: <div class="l_volume">' + this.y
                          });
                      }
                    }

                  }
                }
              },
              events: {
                mouseOut: function () {
                  if (this.chart.lbl) {
                    // this.chart.lbl.hide();
                  }
                }
              }
            }
          },
          tooltip: {
            enabled: true,
            formatter: function () {
              const selectedFormat = document.getElementById("dateFormat").value;
              return ['<b>' + Highcharts.dateFormat('' + selectedFormat + ' %H:%M', this.x) + '</b>'].concat();
            }
          },
          stockTools: {
            gui: {
              enabled: false,
              //buttons: ['indicators', 'separator', 'simpleShapes', 'lines', 'crookedLines', 'measure', 'advanced', 'toggleAnnotations', 'separator', 'verticalLabels', 'flags', 'separator', 'zoomChange', 'fullScreen', 'typeChange', 'separator', 'currentPriceIndicator', 'saveChart'],
              definitions: {
                inputText: {
                  className: 'highcharts-inputText-annotation',
                  symbol: 'label.svg'
                }
              }
            }
          },
          navigation: {
            annotationsOptions: {
              shapeOptions: {
                fill: 'rgba(0, 0, 0, 0)',
                stroke: 'rgba(0, 0, 0, 1)',
                strokeWidth: 2,
                borderColor: 'gray',
                type: 'rect',
                borderWidth: 2
              }
            },
            bindingsClassName: 'annotation-container',

            bindings: {
              inputText: {
                className: 'highcharts-inputText-annotation',
                start: function (e) {

                  const xvalueAn = e.chartX - this.chart.plotLeft;
                  const yvalueAn = e.chartY - this.chart.plotTop;
                  var coords = this.chart.pointer.getCoordinates(e),
                    x = coords.xAxis[0].value,
                    y = coords.yAxis[0].value,
                    navigation = this.chart.options.navigation,
                    controlPoints = [{
                      positioner: function (target) {
                        if (!target.graphic.placed) {
                          return {
                            x: 0,
                            y: -9e7
                          };
                        }
                        return {
                          x: x -
                            this.graphic.width / 2,
                          y: y -
                            this.graphic.height / 2
                        };
                      },
                      events: {
                        drag: function (e, target) {
                          var xy = this.mouseMoveToTranslation(e);
                          target.translate(xy.x, xy.y);
                          target.annotation.labels[0].options =
                            target.options;
                          target.redraw(false);
                        }
                      }
                    }];
                  var _self = this;
                  var labelTextForm = document.getElementById('labelTextForm');
                  var onclick = function () {
                    _self.chart.addAnnotation(Highcharts.merge({
                      langKey: 'label',
                      labelOptions: {
                        format: labelTextForm.querySelector('#labelTextInput').value,
                        shape: 'rect'
                      },
                      labels: [{
                        point: {
                          xAxis: 0,
                          yAxis: 0,
                          x: x,
                          y: y
                        },
                        overflow: 'none',
                        crop: false,
                        text: labelTextForm.querySelector('#labelTextInput').value,
                        controlPoints: controlPoints
                      }]
                    }, navigation
                      .annotationsOptions, navigation
                        .bindings
                        .labelAnnotation
                      .annotationsOptions));
                  };

                  Highcharts.css(
                    labelTextForm, {
                    top: (e.pageY + 15) + 'px',
                    left: e.pageX + 'px',
                    display: 'block'
                  }
                  );

                  var unbinder = Highcharts.addEvent(
                    labelTextForm.querySelector('#labelTextButton'),
                    'click',
                    function () {
                      onclick();

                      Highcharts.css(labelTextForm, {
                        display: 'none'
                      });

                      unbinder();
                    }
                  )

                  return true;
                }
              },
              triangleAnnotation: {
                className: 'highcharts-triangle-annotation',
                start: function(e) {         
                  var coords = this.chart.pointer.getCoordinates(e),
                    navigation = this.chart.options.navigation
                    console.log(navigation)
                    return this.chart.addAnnotation(
                    Highcharts.merge({
                        langKey: 'Triangle',
                        shapes: [{
                          type: 'path',
                          points: [
                            {
                              x: 440,
                              y: 72
                            }, 
                            {
                              x: 410,
                              y: 45
                            }, 
                            {
                              x: 470,
                              y: 45
                            }, 
                            {
                              x: 440,
                              y: 72
                            }
                          ],
                          fill: 'transparent',                   
                          stroke: "black",
                          strokeWidth: 2,
                        }],                 
                      },
                      navigation
                      .annotationsOptions                
                    )
                  );
                }
              },
              rectangleAnnotation: {
                start: function (e) {

                  var coords = this.chart.pointer.getCoordinates(e),
                    navigation = this.chart.options.navigation,
                    x = coords.xAxis[0].value,
                    y = coords.yAxis[0].value,
                    controlPoints = [{
                      positioner: function (annotation) {
                        var xy = Highcharts.Annotation.MockPoint
                          .pointToPixels(
                            annotation.shapes[0].points[2]
                          );

                        return {
                          x: xy.x - 4,
                          y: xy.y - 4
                        };
                      },
                      events: {
                        drag: function (
                          target
                        ) {
                          var coords = this.chart.pointer.getCoordinates(e),
                            x = coords.xAxis[0].value,
                            y = coords.yAxis[0].value,
                            shape = target.options.shapes[0],
                            points = shape.points;

                          // Top right point
                          points[1].x = x;
                          // Bottom right point (cursor position)
                          points[2].x = x;
                          points[2].y = y;
                          // Bottom left
                          points[3].y = y;

                          target.options.shapes[0].points = points;

                          target.redraw(false);
                        }
                      }
                    }];

                  return this.chart.addAnnotation(
                    Highcharts.merge({
                      langKey: 'rectangle',
                      shapes: [{
                        type: 'path',
                        points: [{
                          xAxis: 0,
                          yAxis: 0,
                          x: x,
                          y: y
                        }, {
                          xAxis: 0,
                          yAxis: 0,
                          x: x,
                          y: y
                        }, {
                          xAxis: 0,
                          yAxis: 0,
                          x: x,
                          y: y
                        }, {
                          xAxis: 0,
                          yAxis: 0,
                          x: x,
                          y: y
                        }, {
                          // workaround: add one more point:
                          xAxis: 0,
                          yAxis: 0,
                          x: x,
                          y: y
                        }]
                      }],
                      controlPoints: controlPoints
                    },
                      navigation
                        .annotationsOptions,
                      navigation.bindings
                        .rectangleAnnotation
                        .annotationsOptions
                    )
                  );
                }
              }
            }
          },
          yAxis: [{
            maxPadding: this.props.lock_price_to_bar_ratio_val,
            labels: {
              allowOverlap: loOverlappingLabel
            },
            clickOnCrosshair: function () {
              console.log(this);
            },
            crosshair: {

              snap: false,
              width: 0.5,
              color: 'black',
              label: {
                backgroundColor: 'rgb(255,0,0)',
                events: {
                  dblclick: function () {
                    console.log('a')
                  },
                  click: function () {
                    console.log('b')
                  },
                  contextmenu: function () {
                    console.log('c')
                  }
                },
                formatter: function (value) {

                  return (plusButton === 'true' || plusButton === true) ? '<span id="' + value + '" class="plusAddAlert fa fa-solid fa-eye">Add Alert  ' + value + '</span><div id="myDIV">This is my DIV element.</div >' : '';
                },
                enabled: plusButton,
                padding: 8
              }
            },
            plotLines: [{
              color: 'red',
              width: 2,
              value: this.state.hignlinevalue,
              label: {
                align: this.props.scale_placement,
                x: -20,
                text: '<div class="high_value_plot plot_line_dyn">' + this.toggleHighLine(this.props.sym_highlowlast_label) + '</div>'
              }
            },
            {
              color: 'red',
              width: 2,
              value: this.state.lowerlinevalue,
              label: {
                align: this.props.scale_placement,
                x: -20,
                text: '<div class="high_value_plot plot_line_dyn">' + this.toggleLowerLine(this.props.sym_highlowlast_label) + '</div>'
              }
            },
            {

              color: 'red',
              width: 2,
              value: this.state.lastLineValue,
              label: {
                align: this.props.scale_placement,
                x: -20,
                text: '<div class="high_value_plot plot_line_dyn">' + this.toggleLastLine(this.props.sym_highlowlast_label) + '</div>'
              }
            },
            //@todo - Rohit Saini- Make it dynamic
            {
              color: 'red',
              width: 2,
              value: 135,
              label: {
                style: {
                  backgroundColor: 'red',
                  color: 'blue',
                  fontWeight: 'bold'
                },
                align: this.props.scale_placement,
                x: -10,
                text: '<div class="high_value_plot plot_line_dyn">' + this.toggleTicker(this.props.sym_name_label) + ':' + this.toggleLastPrice(this.props.sym_last_price_label) + '<br/>' + this.toggleLastPricePer(true) + '<br/>' + this.toggleLastPriceCountDown(this.props.countdownBar) + '</div>'
              }
            },
            {
              color: 'red',
              width: 0,
              value: 120,
              label: {
                align: this.props.scale_placement,
                x: -10,
                text: '<div class="high_value_plot plot_line_dyn">' + prePostMktPrice + '</div>'
              }
            },
            {
              color: 'red',
              width: 0,
              value: 140,
              label: {
                align: this.props.scale_placement,
                x: -10,
                text: (this.props.askBid) ? '<div class="high_value_plot plot_line_dyn">Ask 140</div>' : '',
              }
            },
            {
              color: 'red',
              width: 0,
              value: 130,
              label: {
                align: this.props.scale_placement,
                x: -10,
                text: (this.props.askBid) ? '<div class="high_value_plot plot_line_dyn">Bid 130</div>' : '',
              }

            }

            ],
            lineWidth: 2,
            opposite: this.returnScalePosition(),
            minRange: 0.001,
            gridLineWidth: this.props.grid,

            labels: {
              align: 'right',
              x: -3
            },
            title: {
              text: 'OHLC'
            },
            height: '60%',
            resize: {
              enabled: true
            }
          }, {
            lineWidth: 2,
            opposite: this.returnScalePosition(),
            minRange: 0.001,

            gridLineWidth: this.props.grid,
            labels: {
              align: 'right',
              x: -3
            },
            title: {
              text: 'Volume'
            },
            top: '65%',
            height: '35%',
            offset: 0
          },{
            top: '80%',
            height: '10%'
          }
          ],
          exporting: {
            enabled: true,
            buttons: {
              menuItems: [
                "viewFullscreen",
                "printChart",
                "separator",
                "downloadPNG",
                "downloadJPEG",
                "downloadPDF",
                "downloadSVG",
              ],
            },
          }, 
          series: [
            {
              id: 'aapl',
              type: this.props.graph,
              name: (this.props.statusLineSymbol === true || this.props.statusLineSymbol === 'true') ? this.getConditionalSymbolData(this.props.symbolVariation) : '',
              data: this.state.stockData,
              // data: data,
              useOhlcData: false,
              tooltip: {
                  valueDecimals: 2,
              },
              dataGrouping: {},
            }
          ],
    };

    this.setState({
        seriesData : options
    })

    
    setTimeout(() => { 
		//alert('1');
      this.getResult();   

      console.log('this.state.stockData '+ this.state.stockData);

      let ohlcValue = this.props.ohlcValue
      let barChangeVal = this.props.bar_change_val
      let marketStatus = this.props.market_status
      let volValue = this.props.volValue
      let prePostMktPrice = (this.props.pre_post_mkt_price === 'true' || this.props.pre_post_mkt_price === true) ? '120' : '';
      let loOverlappingLabel = (this.props.lo_overlapping_label === 'true' || this.props.lo_overlapping_label === true) ? false : true;
      
      let plusButton = (this.props.addPlusAlert === 'true' || this.props.addPlusAlert === true) ? true : false;
      var myElemOHLC = document.getElementById('ohlc_vals');
      var myElemVol = document.getElementById('vol_vals');
      var l_change = document.getElementById('l_change');
      var market_status = document.getElementById('market_status');

      let rangeSelected = 1;
      if (this.props.time == "minute" || this.props.time == "hour") {
          rangeSelected = 0;
      } 
      const options = {
        chart: {
          
          type: "candlestick",
          height: 700,
          events: { 
            load: function (e) {
              var chart = this;
              chart.stockTools.showhideBtn.click();

              $('.abc').on('DOMSubtreeModified click', function(){
                setTimeout(function (chart_update){ 
                var seriesData = JSON.parse(localStorage.getItem('indicator'))
                if(seriesData !== null){
                  if(seriesData?.length>0){
                    seriesData.forEach(function(data){
                      chart_update.addSeries({
                          type: data.type,
                          linkedTo: 'aapl',
                          //id: 'overlay',
                          lineWidth: 0.6,
                          color:'green',
                          marker: {
                            enabled: false
                          } 
                        });
                    });
                  }
                }
              },1000,chart); 
  
              setTimeout(function (chart_update){ 
                var seriesData = JSON.parse(localStorage.getItem('oscilator'))
                if(seriesData != null){ 
                    seriesData.forEach(function(data){                   
                    chart_update.addSeries({ 
                        type: data.type,
                        linkedTo: 'aapl',
                        //id: 'overlay',
                        lineWidth: 0.6,
                        color:'green',
                        marker: {
                          enabled: false
                        } 
                    });
                  })
                }
              },1000,chart);
              
              setTimeout(function (chart_update){ 
                var seriesData = JSON.parse(localStorage.getItem('ma_template'))
                if(seriesData !== null){
                  if(seriesData?.length>0){ 
                    seriesData.forEach(function(data,index){
                      if(index>0){
                        chart_update.addSeries(data) 
                      }
                    });
                  }
                }
              },1000,chart);
  
              setTimeout(function (chart_update){ 
                var seriesData = JSON.parse(localStorage.getItem('bill_template'))
                if(seriesData !== null){
                  if(seriesData?.length>0){ 
                    seriesData.forEach(function(data,index){
                      if(index>0){
                        chart_update.addSeries(data) 
                      }
                    });
                  }
                }
              },1000,chart);
  
              setTimeout(function (chart_update){ 
                var seriesData = JSON.parse(localStorage.getItem('displaced_template'))
                console.log('displaced_template')
                console.log(seriesData)
                if(seriesData !== null){
                  if(seriesData?.length>0){ 
                    seriesData.forEach(function(data,index){
                      if(index>0){
                        chart_update.addSeries(data) 
                      }
                    });
                  }
                }
              },1000,chart);
  
              }); 
               
              $('.abc').trigger('click');
              document.getElementById('oscillators').addEventListener('change', function (e) {
                  var series = chart.get('oscillator');
                 alert('aa')
                  //if (series) {
                      //series.remove(false);
                      chart.addSeries(
                        {
                          type: 'rsi',
                          id: 'oscillator',
                          linkedTo: 'aapl',
                          yAxis: 1
                      }
                    );
                      
                  //}
              });
             // this.reflow()
            }
          }
          //backgroundColor: this.state.backgroundColor,
        },
          title: {
            text: (this.props.statusLineSymbol === true || this.props.statusLineSymbol === 'true') ? this.getConditionalSymbolData(this.props.symbolVariation) : '',
          },
          xAxis: {

            crosshair: {
              text: 'asdasdas',
              snap: false,
              value: '3434',
              label: {
                enabled: true,
                format: '{value:.1f}' // One decimal
              }
            },

            labels: {
              allowOverlap: loOverlappingLabel
            },
            type: 'datetime',
            dateTimeLabelFormats: {
              day: '%e of %b'
            }
          },
          rangeSelector: {
            enabled:false,
            allButtonsEnabled: true,
            buttons: [{
              type: 'month',
              count: 1,
              text: '1m',
              events: {
                  click: function() {
                      //alert('Clicked button');
                  }
              }
          }, {
              type: 'month',
              count: 3,
              text: '3m'
          }, {
              type: 'month',
              count: 6,
              text: '6m'
          }, {
              type: 'ytd',
              text: 'YTD'
          }, {
              type: 'year',
              count: 1,
              text: '1y'
          }, {
              type: 'all',
              text: 'All'
          }],
            inputEnabled: false,
            buttonTheme: {
              width: 40,
            },
            selected: rangeSelected,
          },
          plotOptions: {
            candlestick: {
              color: this.props.up_color_color,
              upColor: this.props.down_color_color
            },
            series: {
              color: '#FF0000',
              //name: (this.props.statusLineSymbol === true || this.props.statusLineSymbol === 'true') ? this.getConditionalSymbolData(this.props.symbolVariation) : '',
              point: {
                events: {
                  mouseOver: function () {
                    // console.log('ji');
                    var chart = this.series.chart;
                    if (!chart.lbl) {
                      chart.lbl = chart.renderer.label('').attr({
                        padding: 10,
                        r: 10,
                        fill: this.state.background_color
                      }).css({
                        color: '#FFFFFF',
                        background: "#000000"
                      }).add();
                    } else {



                      chart.lbl.fill = "green";
                    }
                    // console.log('this');
                    // console.log(chart.lbl.fill);
                    console.log('ddddd', chart);
                    // console.log(this);
                    // console.log('this');
                    if (this.options.high) {
                      // console.log('object----' + ohlcValue);
                      if (ohlcValue === 'true' || ohlcValue === true) {
                        // console.log('object----' + JSON.stringify(this));
                        chart.lbl
                          .show()
                          .attr({
                            text: '<span style="fill:green" id="market_status"> </span>  <div id="ohlc_vals">O: <div class="l_open">' + this.open + '</div>  H: <div class="l_high">' + this.high + '</div>  L: <div class="l_low">' + this.low + '</div>  C: <div class="l_close">' + this.y + '</div>  <div id="l_change">âˆ’0.40 (âˆ’0.81%)</div></div>'
                          });
                      }
                    } else {
                      if (volValue === 'true' || volValue === true) {
                        chart.lbl
                          .show()
                          .attr({
                            text: 'Vol: <div class="l_volume">' + this.y
                          });
                      }
                    }

                  }
                }
              },
              events: {
                mouseOut: function () {
                  if (this.chart.lbl) {
                    // this.chart.lbl.hide();
                  }
                }
              }
            } 
          },
          tooltip: {
            enabled: true,
            formatter: function () {
              const selectedFormat = document.getElementById("dateFormat").value;
              return ['<b>' + Highcharts.dateFormat('' + selectedFormat + ' %H:%M', this.x) + '</b>'].concat();
            }
          },
          stockTools: {
            gui: {
              enabled: true,
              //buttons: ['indicators', 'separator', 'simpleShapes', 'lines', 'crookedLines', 'measure', 'advanced', 'toggleAnnotations', 'separator', 'verticalLabels', 'flags', 'separator', 'zoomChange', 'fullScreen', 'typeChange', 'separator', 'currentPriceIndicator', 'saveChart'],
              definitions: {
                inputText: {
                  className: 'highcharts-inputText-annotation',
                  symbol: 'label.svg'
                }
              }
            }
          },
          navigation: {
            annotationsOptions: {
              shapeOptions: {
                fill: 'rgba(0, 0, 0, 0)',
                stroke: 'rgba(0, 0, 0, 1)',
                strokeWidth: 2,
                borderColor: 'gray',
                type: 'rect',
                borderWidth: 2
              }
            },
            bindingsClassName: 'annotation-container',

            bindings: {
              inputText: {
                className: 'highcharts-inputText-annotation',
                start: function (e) {

                  const xvalueAn = e.chartX - this.chart.plotLeft;
                  const yvalueAn = e.chartY - this.chart.plotTop;
                  var coords = this.chart.pointer.getCoordinates(e),
                    x = coords.xAxis[0].value,
                    y = coords.yAxis[0].value,
                    navigation = this.chart.options.navigation,
                    controlPoints = [{
                      positioner: function (target) {
                        if (!target.graphic.placed) {
                          return {
                            x: 0,
                            y: -9e7
                          };
                        }
                        return {
                          x: x -
                            this.graphic.width / 2,
                          y: y -
                            this.graphic.height / 2
                        };
                      },
                      events: {
                        drag: function (e, target) {
                          var xy = this.mouseMoveToTranslation(e);
                          target.translate(xy.x, xy.y);
                          target.annotation.labels[0].options =
                            target.options;
                          target.redraw(false);
                        }
                      }
                    }];
                  var _self = this;
                  var labelTextForm = document.getElementById('labelTextForm');
                  var onclick = function () {
                    _self.chart.addAnnotation(Highcharts.merge({
                      langKey: 'label',
                      labelOptions: {
                        format: labelTextForm.querySelector('#labelTextInput').value,
                        shape: 'rect'
                      },
                      labels: [{
                        point: {
                          xAxis: 0,
                          yAxis: 0,
                          x: x,
                          y: y
                        },
                        overflow: 'none',
                        crop: false,
                        text: labelTextForm.querySelector('#labelTextInput').value,
                        controlPoints: controlPoints
                      }]
                    }, navigation
                      .annotationsOptions, navigation
                        .bindings
                        .labelAnnotation
                      .annotationsOptions));
                  };

                  Highcharts.css(
                    labelTextForm, {
                    top: (e.pageY + 15) + 'px',
                    left: e.pageX + 'px',
                    display: 'block'
                  }
                  );

                  var unbinder = Highcharts.addEvent(
                    labelTextForm.querySelector('#labelTextButton'),
                    'click',
                    function () {
                      onclick();

                      Highcharts.css(labelTextForm, {
                        display: 'none'
                      });

                      unbinder();
                    }
                  )

                  return true;
                }
              },
              rectangleAnnotation: {
                start: function (e) {

                  var coords = this.chart.pointer.getCoordinates(e),
                    navigation = this.chart.options.navigation,
                    x = coords.xAxis[0].value,
                    y = coords.yAxis[0].value,
                    controlPoints = [{
                      positioner: function (annotation) {
                        var xy = Highcharts.Annotation.MockPoint
                          .pointToPixels(
                            annotation.shapes[0].points[2]
                          );

                        return {
                          x: xy.x - 4,
                          y: xy.y - 4
                        };
                      },
                      events: {
                        drag: function (
                          target
                        ) {
                          var coords = this.chart.pointer.getCoordinates(e),
                            x = coords.xAxis[0].value,
                            y = coords.yAxis[0].value,
                            shape = target.options.shapes[0],
                            points = shape.points;

                          // Top right point
                          points[1].x = x;
                          // Bottom right point (cursor position)
                          points[2].x = x;
                          points[2].y = y;
                          // Bottom left
                          points[3].y = y;

                          target.options.shapes[0].points = points;

                          target.redraw(false);
                        }
                      }
                    }];

                  return this.chart.addAnnotation(
                    Highcharts.merge({
                      langKey: 'rectangle',
                      shapes: [{
                        type: 'path',
                        points: [{
                          xAxis: 0,
                          yAxis: 0,
                          x: x,
                          y: y
                        }, {
                          xAxis: 0,
                          yAxis: 0,
                          x: x,
                          y: y
                        }, {
                          xAxis: 0,
                          yAxis: 0,
                          x: x,
                          y: y
                        }, {
                          xAxis: 0,
                          yAxis: 0,
                          x: x,
                          y: y
                        }, {
                          // workaround: add one more point:
                          xAxis: 0,
                          yAxis: 0,
                          x: x,
                          y: y
                        }]
                      }],
                      controlPoints: controlPoints
                    },
                      navigation
                        .annotationsOptions,
                      navigation.bindings
                        .rectangleAnnotation
                        .annotationsOptions
                    )
                  );
                }
              }
            }
          },
          yAxis1: [{
            height: '60%'
          }, {
              top: '60%',
              height: '20%'
          }, {
              top: '80%',
              height: '10%'
          }],
          yAxis1: [{
            maxPadding: this.props.lock_price_to_bar_ratio_val,
            labels: {
              allowOverlap: loOverlappingLabel
            },
            clickOnCrosshair: function () {
              console.log(this);
            },
            crosshair: {

              snap: false,
              width: 0.5,
              color: 'black',
              label: {
                backgroundColor: 'rgb(255,0,0)',
                events: {
                  dblclick: function () {
                    console.log('a')
                  },
                  click: function () {
                    console.log('b')
                  },
                  contextmenu: function () {
                    console.log('c')
                  }
                },
                formatter: function (value) {

                  return (plusButton === 'true' || plusButton === true) ? '<span id="' + value + '" class="plusAddAlert fa fa-solid fa-eye">Add Alert  ' + value + '</span><div id="myDIV">This is my DIV element.</div >' : '';
                },
                enabled: plusButton,
                padding: 8
              }
            },
            plotLines: [{
              color: 'red',
              width: 2,
              value: this.state.hignlinevalue,
              label: {
                align: this.props.scale_placement,
                x: -20,
                text: '<div class="high_value_plot plot_line_dyn">' + this.toggleHighLine(this.props.sym_highlowlast_label) + '</div>'
              }
            },
            {
              color: 'red',
              width: 2,
              value: this.state.lowerlinevalue,
              label: {
                align: this.props.scale_placement,
                x: -20,
                text: '<div class="high_value_plot plot_line_dyn">' + this.toggleLowerLine(this.props.sym_highlowlast_label) + '</div>'
              }
            },
            {

              color: 'red',
              width: 2,
              value: this.state.lastLineValue,
              label: {
                align: this.props.scale_placement,
                x: -20,
                text: '<div class="high_value_plot plot_line_dyn">' + this.toggleLastLine(this.props.sym_highlowlast_label) + '</div>'
              }
            },
            //@todo - Rohit Saini- Make it dynamic
            {
              color: 'red',
              width: 2,
              value: 135,
              label: {
                style: {
                  backgroundColor: 'red',
                  color: 'blue',
                  fontWeight: 'bold'
                },
                align: this.props.scale_placement,
                x: -10,
                text: '<div class="high_value_plot plot_line_dyn">' + this.toggleTicker(this.props.sym_name_label) + ':' + this.toggleLastPrice(this.props.sym_last_price_label) + '<br/>' + this.toggleLastPricePer(true) + '<br/>' + this.toggleLastPriceCountDown(this.props.countdownBar) + '</div>'
              }
            },
            {
              color: 'red',
              width: 0,
              value: 120,
              label: {
                align: this.props.scale_placement,
                x: -10,
                text: '<div class="high_value_plot plot_line_dyn">' + prePostMktPrice + '</div>'
              }
            },
            {
              color: 'red',
              width: 0,
              value: 140,
              label: {
                align: this.props.scale_placement,
                x: -10,
                text: (this.props.askBid) ? '<div class="high_value_plot plot_line_dyn">Ask 140</div>' : '',
              }
            },
            {
              color: 'red',
              width: 0,
              value: 130,
              label: {
                align: this.props.scale_placement,
                x: -10,
                text: (this.props.askBid) ? '<div class="high_value_plot plot_line_dyn">Bid 130</div>' : '',
              }

            }

            ],
            lineWidth: 2,
            opposite: this.returnScalePosition(),
            minRange: 0.001,
            gridLineWidth: this.props.grid,

            labels: {
              align: 'right',
              x: -3
            },
            title: {
              text: 'OHLC'
            },
            height: '60%',
            resize: {
              enabled: true
            }
          }, {
            lineWidth: 2,
            opposite: this.returnScalePosition(),
            minRange: 0.001,

            gridLineWidth: this.props.grid,
            labels: {
              align: 'right',
              x: -3
            },
            title: {
              text: 'Volume'
            },
            top: '65%',
            height: '35%',
            offset: 0
          }
          ],
          exporting: {
            enabled: true,
            buttons: {
              menuItems: [
                "viewFullscreen",
                "printChart",
                "separator",
                "downloadPNG",
                "downloadJPEG",
                "downloadPDF",
                "downloadSVG",
              ],
            },
          },
          navigator: {
            enabled: true
          },
          xAxis: {
            type: 'datetime',
            min: new Date().setDate(new Date().getDate() - 60),
            max: new Date().getTime(new Date().getDate() -1)
          },
          series: [
            {
              id: 'aapl',
              type: this.props.graph,
              name: (this.props.statusLineSymbol === true || this.props.statusLineSymbol === 'true') ? this.getConditionalSymbolData(this.props.symbolVariation) : '',
              data: this.state.stockData,
              // data: data,
              useOhlcData: false,
              tooltip: {
                  valueDecimals: 2,
              },
              dataGrouping: {},
            },
            {
                type: 'column',
                name: 'Volume',
                yAxis: 1,
                color:'#8087E8',
                data: this.state.volume,
                dataGrouping: {},
            },
            // {
            //   type:'line',
            //   linkedTo:'aapl',
            //   data:[0, 0, 119.57666666666667, 119.57333333333334, 119.47666666666667, 119.53666666666668, 119.60333333333334, 119.93, 122.01, 122.39666666666666, 121.54333333333334, 118.48333333333333, 116.75666666666666, 114.91666666666667, 115.56333333333333, 116.87333333333333, 119.90333333333332, 121.17333333333333, 121.39999999999999, 121.84333333333332, 123.59666666666668, 125.26666666666667, 126.32666666666667, 124.42666666666666, 124.29333333333334, 124.86000000000001, 127.23, 126.57666666666667, 126.14666666666669, 126.65333333333335, 127.85666666666668, 127.94333333333334, 126.84666666666668, 125.2, 121.40000000000002, 118.03333333333335, 116.57000000000001, 115.67666666666666, 115.27, 114.32666666666667, 113.94666666666667, 113.89]
            // } 
          ],
    };

    this.setState({
        seriesData : options
    })

    }, 5000);

  
    $('body').on('click', '.extraIcon', function() {
        $('#exmModel').val($(this).parent().attr('id'));
        window.$('#exampleModal').modal('show');
    });


    $('body').on('click', '.plusAddAlert', function () {
      let value = $(this).attr('id');

      $("input[name='cond_value']").val(value);
      window.$('#alertModal').modal('show');
      // this.iconClicked();
      //   // alert('nhjj');
      //   // this.setState({
      //   //   showModal:true
      //   // })
    });

  }
  AddIndicatorName(indicator_name){
      this.setState({
          indicator_setting_name:indicator_name
      });
  }
  componentDidUpdate(prevProps){
    if(this.props.get_indicator_prop_data !== 0){   
      if(JSON.parse(localStorage.getItem('default_template'))?.length > 0){
          $('#template_display_div').show();
          $('.template_div_text').text(`Click to apply ${(JSON.parse(localStorage.getItem('default_template')))?JSON.parse(localStorage.getItem('default_template'))?.length:0} default template`) 
      }
      if(JSON.parse(localStorage.getItem('indicator'))?.length > 0){ 
        $('#indicator_display_div').show();
        $('.indicator_div_text').text(`Click to apply ${(JSON.parse(localStorage.getItem('indicator')))?JSON.parse(localStorage.getItem('indicator'))?.length:0} Indicator`)
      } 
      this.render(); 
    }

    //console.log(this.props.newSeriesIndicator, 'sssssseeeeccccc');
    const alphaMines = 'rgba(' + this.props.alpha_color.r + ',' + this.props.alpha_color.g + ',' + this.props.alpha_color.b + ',' + this.props.alpha_color.a + ')';
    
    //const alphaMines = 'rgb(241,112,19,1)';

    $(".highcharts-label-box").attr("fill", alphaMines);
    if (this.state.background_color != alphaMines) {
      // console.log('hi')
      this.setState({ background_color: alphaMines })
    }
    var array = this.state.allData
    var arrayColumn = (array, column) => {
      return array.map(item => item[column]);
    };
    var highValues = arrayColumn(array, 'h');
    highValues.sort(function (a, b) {
      return b - a;
    });

    var array1 = this.state.allData
    var arrayColumn1 = (array1, column) => {
      return array.map(item => item[column]);
    };
    var lowerValues = arrayColumn1(array1, 'l');
    lowerValues.sort(function (a, b) {
      return a - b;
    });


    if (this.props.showHighLowerLine == true) {
      if (this.state.hignlinevalue == "") {
        this.setState({ hignlinevalue: highValues[0], lowerlinevalue: lowerValues[0], lastLineValue: highValues[highValues?.length - 1] })
      }


    } else if (this.props.showlastPriceLine == true) {

      if (this.state.lastLineValue == "") {
        this.setState({ lastLineValue: highValues[highValues?.length - 1] })
      }

    } else {
      if (this.state.hignlinevalue != "") {
        this.setState({ hignlinevalue: '', lowerlinevalue: '' })

      }
    }


    /* Arrow mark (slant line) */

      if(this.props.arrowMark){

          if(prevProps.arrowMark !== this.props.arrowMark) {

            var seriesArry = this.state.seriesData['series']; 
          var time = [this.state.initialTime + 59045738];

          for(var i = 1; i < 10; i++) {
              time[i] = time[i-1] + 1500;
          }

          // console.log("state initialTime time " + this.state.initialTime);
          // console.log("initial time " + time);

          var data1 = [[time[0], 10],[time[1], 20],[time[2], 30],[time[3], 40],[time[4], 50],[time[5], 40],[time[6], 30],[time[7], 20],[time[8], 10],[time[9], 0]];
          for(var i = 1; i < 10; i++) {
              time[i] = time[i-1] + 1500;
          }

          //console.log(data1);

          this.setState({
              initialTime: this.state.initialTime + 59045738  
          })

            seriesArry.push({
                type: 'scatter',
                cursor: 'move',
                draggableX: true,
                draggableY: true,
                dragDrop: {
                  draggableY: true,
                  draggableX: true
                },
                data: data1,
                name:'data'+this.props.count,
                pointStart: time[0],
                yAxis: 0,
                dataLabels: {
                  enabled: true,
                  useHTML: true,
                  format: "<div id='data"+this.props.count+"' class='left_arrow down_triger myDisv data"+this.props.count+"' data-id='"+this.props.count+"'><i class='extraIcon fa fa-long-arrow-down' aria-hidden='true'></i></div>",
                  y: 15
                },
                marker: {
                    enabled: false
                }
            })
         
            var optionsArray = this.state.seriesData ; 
            optionsArray['series'] = seriesArry;

            this.setState({
                seriesData : optionsArray
            });

          }

      }

      /* end arrow mark (slant line) */


      /* flag mark **/
      if(this.props.flagMark){

          if(prevProps.flagMark !== this.props.flagMark) {

            var seriesArry = this.state.seriesData['series']; 
          var time = [this.state.initialTime + 59045738];

          for(var i = 1; i < 10; i++) {
              time[i] = time[i-1] + 1500;
          }

          // console.log("state initialTime time " + this.state.initialTime);
          // console.log("initial time " + time);

          var data1 = [[time[0], 10],[time[1], 20],[time[2], 30],[time[3], 40],[time[4], 50],[time[5], 40],[time[6], 30],[time[7], 20],[time[8], 10],[time[9], 0]];
          for(var i = 1; i < 10; i++) {
              time[i] = time[i-1] + 1500;
          }

          //console.log(data1);

          this.setState({
              initialTime: this.state.initialTime + 59045738  
          })

            seriesArry.push({
                type: 'scatter',
                cursor: 'move',
                draggableX: true,
                draggableY: true,
                dragDrop: {
                  draggableY: true,
                  draggableX: true
                },
                data: data1,
                name:'data'+this.props.count,
                pointStart: time[0],
                yAxis: 0,
                dataLabels: {
                  enabled: true,
                  useHTML: true,
                  format: "<div id='data"+this.props.count+"' class=' flag_aarow myDisv data"+this.props.count+"' data-id='"+this.props.count+"' ><i class='extraIcon fa fa-flag' aria-hidden='true'></i></div>",
                  y: 15
                },
                marker: {
                    enabled: false
                }
            })
         
            var optionsArray = this.state.seriesData ; 
            optionsArray['series'] = seriesArry;

            this.setState({
                seriesData : optionsArray
            });

            //console.log('seriesData '+ this.state.seriesData);
          }

      }
      /* end flag mark  **/


      /* Balloon **/
      if(this.props.balloon){

          if(prevProps.balloon !== this.props.balloon) {

            var seriesArry = this.state.seriesData['series']; 
          var time = [this.state.initialTime + 59045738];

          for(var i = 1; i < 10; i++) {
              time[i] = time[i-1] + 1500;
          }

          // console.log("state initialTime time " + this.state.initialTime);
          // console.log("initial time " + time);

          var data1 = [[time[0], 10],[time[1], 20],[time[2], 30],[time[3], 40],[time[4], 50],[time[5], 40],[time[6], 30],[time[7], 20],[time[8], 10],[time[9], 0]];
          for(var i = 1; i < 10; i++) {
              time[i] = time[i-1] + 1500;
          }

          //console.log(data1);

          this.setState({
              initialTime: this.state.initialTime + 59045738  
          })

            seriesArry.push({
                type: 'scatter',
                cursor: 'move',
                draggableX: true,
                draggableY: true,
                dragDrop: {
                  draggableY: true,
                  draggableX: true
                },
                data: data1,
                name:'data'+this.props.count,
                pointStart: time[0],
                yAxis: 0,
                dataLabels: {
                  enabled: true,
                  useHTML: true,
                  format: "<div id='data"+this.props.count+"' class=' comment_outer myDisv data"+this.props.count+"' data-id='"+this.props.count+"' ><p class='extraIcon balloon_text'>Comment</p></div>",
                  y: 15
                },
                marker: {
                    enabled: false
                }
            })
         
            var optionsArray = this.state.seriesData ; 
            optionsArray['series'] = seriesArry;

            this.setState({
                seriesData : optionsArray
            });

            //console.log('seriesData '+ this.state.seriesData);
          }

      }
      /* end ballon **/
      
      /*  arrow left */
      if(this.props.arrowLeft){

          if(prevProps.arrowLeft !== this.props.arrowLeft) {

            var seriesArry = this.state.seriesData['series']; 
          var time = [this.state.initialTime + 59045738];

          for(var i = 1; i < 10; i++) {
              time[i] = time[i-1] + 1500;
          }

          // console.log("state initialTime time " + this.state.initialTime);
          // console.log("initial time " + time);

          var data1 = [[time[0], 10],[time[1], 20],[time[2], 30],[time[3], 40],[time[4], 50],[time[5], 40],[time[6], 30],[time[7], 20],[time[8], 10],[time[9], 0]];
          for(var i = 1; i < 10; i++) {
              time[i] = time[i-1] + 1500;
          }

          //console.log(data1);

          this.setState({
              initialTime: this.state.initialTime + 59045738  
          })

            seriesArry.push({
                type: 'scatter',
                cursor: 'move',
                draggableX: true,
                draggableY: true,
                dragDrop: {
                  draggableY: true,
                  draggableX: true
                },
                data: data1,
                name:'data'+this.props.count,
                pointStart: time[0],
                yAxis: 0,
                dataLabels: {
                  enabled: true,
                  useHTML: true,
                  format: "<div id='data"+this.props.count+"' class=' left_arrow data"+this.props.count+"' ><i class='fa fa-long-arrow-left extraIcon' aria-hidden='true'></i></div> ",
                  y: 15
                },
                marker: {
                    enabled: false
                }
            })
         
            var optionsArray = this.state.seriesData ; 
            optionsArray['series'] = seriesArry;

            this.setState({
                seriesData : optionsArray
            });

            //console.log('seriesData '+ this.state.seriesData);
          }

      }
      /* end arrow left */


      /* Arrow up **/
      if(this.props.arrowUp){

          if(prevProps.arrowUp !== this.props.arrowUp) {

            var seriesArry = this.state.seriesData['series']; 
          var time = [this.state.initialTime + 59045738];

          for(var i = 1; i < 10; i++) {
              time[i] = time[i-1] + 1500;
          }

          // console.log("state initialTime time " + this.state.initialTime);
          // console.log("initial time " + time);

          var data1 = [[time[0], 10],[time[1], 20],[time[2], 30],[time[3], 40],[time[4], 50],[time[5], 40],[time[6], 30],[time[7], 20],[time[8], 10],[time[9], 0]];
          for(var i = 1; i < 10; i++) {
              time[i] = time[i-1] + 1500;
          }

          //console.log(data1);

          this.setState({
              initialTime: this.state.initialTime + 59045738  
          })

            seriesArry.push({
                type: 'scatter',
                cursor: 'move',
                draggableX: true,
                draggableY: true,
                dragDrop: {
                  draggableY: true,
                  draggableX: true
                },
                data: data1,
                name:'data'+this.props.count,
                pointStart: time[0],
                yAxis: 0,
                dataLabels: {
                  enabled: true,
                  useHTML: true,
                  format: "<div id='data"+this.props.count+"' class='left_arrow top_arrow data"+this.props.count+"' data-id='"+this.props.count+"' ><i class='fa fa-long-arrow-up extraIcon' aria-hidden='true'></i></div>",
                  y: 15
                },
                marker: {
                    enabled: false
                }
            })
         
            var optionsArray = this.state.seriesData ; 
            optionsArray['series'] = seriesArry;

            this.setState({
                seriesData : optionsArray
            });

            //console.log('seriesData '+ this.state.seriesData);
          }

      }

      /* end arrow up **/



      /*  arrow right */
      if(this.props.arrowRight){

          if(prevProps.arrowRight !== this.props.arrowRight) {

           var seriesArry = this.state.seriesData['series']; 
          var time = [this.state.initialTime + 59045738];

          for(var i = 1; i < 10; i++) {
              time[i] = time[i-1] + 1500;
          }

          // console.log("state initialTime time " + this.state.initialTime);
          // console.log("initial time " + time);

          var data1 = [[time[0], 10],[time[1], 20],[time[2], 30],[time[3], 40],[time[4], 50],[time[5], 40],[time[6], 30],[time[7], 20],[time[8], 10],[time[9], 0]];
          for(var i = 1; i < 10; i++) {
              time[i] = time[i-1] + 1500;
          }

          //console.log(data1);

          this.setState({
              initialTime: this.state.initialTime + 59045738  
          })

            seriesArry.push({
                type: 'scatter',
                cursor: 'move',
                draggableX: true,
                draggableY: true,
                dragDrop: {
                  draggableY: true,
                  draggableX: true
                },
                data: data1,
                name:'data'+this.props.count,
                pointStart: time[0],
                yAxis: 0,
                dataLabels: {
                  enabled: true,
                  useHTML: true,
                  format: "<div id='data"+this.props.count+"' class=' left_arrow right_arrow data"+this.props.count+"'><i class='extraIcon fa fa-long-arrow-right' aria-hidden='true'></i></div>",
                  y: 15
                },
                marker: {
                    enabled: false
                }
            })
         
            var optionsArray = this.state.seriesData ; 
            optionsArray['series'] = seriesArry;

            this.setState({
                seriesData : optionsArray
            });

            //console.log('seriesData '+ this.state.seriesData);
          }

      }
      /*  end arrow right */

      /*  start arrow down */
      if(this.props.arrowDown){

          if(prevProps.arrowDown !== this.props.arrowDown) {

            var seriesArry = this.state.seriesData['series']; 
          var time = [this.state.initialTime + 59045738];

          for(var i = 1; i < 10; i++) {
              time[i] = time[i-1] + 1500;
          }

          // console.log("state initialTime time " + this.state.initialTime);
          // console.log("initial time " + time);

          var data1 = [[time[0], 10],[time[1], 20],[time[2], 30],[time[3], 40],[time[4], 50],[time[5], 40],[time[6], 30],[time[7], 20],[time[8], 10],[time[9], 0]];
          for(var i = 1; i < 10; i++) {
              time[i] = time[i-1] + 1500;
          }

          //console.log(data1);

          this.setState({
              initialTime: this.state.initialTime + 59045738  
          })

            seriesArry.push({
                type: 'scatter',
                cursor: 'move',
                draggableX: true,
                draggableY: true,
                dragDrop: {
                  draggableY: true,
                  draggableX: true
                },
                data: data1,
                name:'data'+this.props.count,
                pointStart: time[0],
                yAxis: 0,
                dataLabels: {
                  enabled: true,
                  useHTML: true,
                  format: "<div id='data"+this.props.count+"' class=' left_arrow down_arrow data"+this.props.count+"'><i class='fa fa-long-arrow-down extraIcon' aria-hidden='true'></i></div>",
                  y: 15
                },
                marker: {
                    enabled: false
                }
            });
         
            var optionsArray = this.state.seriesData ; 
            optionsArray['series'] = seriesArry;

            this.setState({
                seriesData : optionsArray
            });

            //console.log('seriesData '+ this.state.seriesData);
          }

      }
      /*  end arrow down */

      if(this.props.callout){

          if(prevProps.callout !== this.props.callout) {


            var seriesArry = this.state.seriesData['series']; 
          var time = [this.state.initialTime + 59045738];

          for(var i = 1; i < 10; i++) {
              time[i] = time[i-1] + 1500;
          }

          // console.log("state initialTime time " + this.state.initialTime);
          // console.log("initial time " + time);

          var data1 = [[time[0], 10],[time[1], 20],[time[2], 30],[time[3], 40],[time[4], 50],[time[5], 40],[time[6], 30],[time[7], 20],[time[8], 10],[time[9], 0]];
          for(var i = 1; i < 10; i++) {
              time[i] = time[i-1] + 1500;
          }

          //console.log(data1);

          this.setState({
              initialTime: this.state.initialTime + 59045738  
          })

            seriesArry.push({
                type: 'scatter',
                cursor: 'move',
                draggableX: true,
                draggableY: true,
                dragDrop: {
                  draggableY: true,
                  draggableX: true
                },
                data: data1,
                name:'data'+this.props.count,
                pointStart: time[0],
                yAxis: 0,
                dataLabels: {
                  enabled: true,
                  useHTML: true,
                  format: "<div id='data"+this.props.count+"' class=' left_arrow map_outer myDisv data"+this.props.count+"' data-id='"+this.props.count+"' ><i class='extraIcon fa fa-map-marker' aria-hidden='true'></i><div class='hover_show'><p>the readable content of a page when looking at its layout.</p></div></div>",
                  y: 15
                },
                marker: {
                    enabled: false
                }
            })
         
            var optionsArray = this.state.seriesData ; 
            optionsArray['series'] = seriesArry;

            this.setState({
                seriesData : optionsArray
            });

            //console.log('seriesData '+ this.state.seriesData);
          }

      }else{


    if (prevProps.marker !== this.props.marker) {

          console.log('nextProps.marker'+ prevProps.marker + 'prevState.marker'+ this.props.marker);

          console.log('series -- dfsdfs' + JSON.stringify(this.state.seriesData)); 

          console.log('series data' + JSON.stringify(this.state.seriesData['series'])); 

          var seriesArry = this.state.seriesData['series']; 
          var time = [this.state.initialTime + 59045738];

          for(var i = 1; i < 10; i++) {
              time[i] = time[i-1] + 1500;
          }

          // console.log("state initialTime time " + this.state.initialTime);
          // console.log("initial time " + time);

          var data1 = [[time[0], 10],[time[1], 20],[time[2], 30],[time[3], 40],[time[4], 50],[time[5], 40],[time[6], 30],[time[7], 20],[time[8], 10],[time[9], 0]];
          for(var i = 1; i < 10; i++) {
              time[i] = time[i-1] + 1500;
          }

          //console.log(data1);

          this.setState({
              initialTime: this.state.initialTime + 59045738  
          })

          seriesArry.push({
              type: 'scatter',
              dragDrop: {
                draggableY: true,
                draggableX: true
              },
              data: data1,
              name:'data'+this.props.count,
              pointStart: time[0],
              yAxis: 0,
              dataLabels: {
                enabled: true,
                useHTML: true,
                format: "<div id='data"+this.props.count+"' class='myDisv data"+this.props.count+"'  data-id='"+this.props.count+"' data-toggle='modal' data-target='#exampleModal'><i class='extraIcon fa fa-solid  "+ this.props.marker +" ' ></i></div>",
                y: 15
              },
              marker: {
                  enabled: false
              }
          })

          var optionsArray = this.state.seriesData ; 
          optionsArray['series'] = seriesArry;

          this.setState({
            seriesData : optionsArray
          });

          console.log('New series data' + JSON.stringify(this.state.seriesData));

      }

    }
  }
 
 
  drawHighLine(stockData) {

    var arr = stockData;
    const groupAndSort = arr => {
      const res = [];
      const map = Object.create(null);
      Array.prototype.forEach.call(arr, item => {
        item.forEach(el => {
          if (!(el in map)) {
            map[el] = [];
            res.push(map[el]);
          };
          map[el].push(el);
        });
      });
      res.sort((a, b) => {
        return a[0] - b[0];
      });
      return res;
    };
    //console.log('jj')
    var alldata = groupAndSort(arr);
    var mid = parseInt(alldata?.length) / 2
    // console.log(alldata)
    var high = alldata[mid - 1];
    var low = alldata[0];
    // alert(high)

    this.setState({ hignlinevalue: high, lowerlinevalue: low })
    //console.log(groupAndSort(arr));
  }

  addParamInIndicator = (e) =>{ 
    if(e.target.id){ 
      var indicator_type = e.target.id.split('|')[0]
      var indicator_field_name = e.target.id.split('|')[1] 
      var indicator_field_value = e.target.value

      var existingData = [];
      existingData = JSON.parse(localStorage.getItem('indicator'));
      existingData.map((indicator_data,i) => { 
         if(indicator_data.type === indicator_type){
            if(indicator_field_name == 'width'){
                existingData[i].lineWidth = indicator_field_value 
                this.setState({line_width: e.target.value});
            }
            if(indicator_field_name == 'color'){
              existingData[i].color = indicator_field_value 
              this.setState({indicator_line_color: e.target.value});
          }
         } 
      });
      localStorage.setItem('indicator',JSON.stringify(existingData))
    }  
  }

  getConditionalSymbolData(symbolVariation) {
    switch (symbolVariation) {
      case '0':
        return this.props.symbolname;

      case '1':
        return this.props.symbol;

      case '2':
        return this.props.symbol + "(" + this.props.symbolname + ")"


      default:
        return this.props.symbol + "(" + this.props.symbolname + ")"

    }
  }


  toggleTicker(syb) {
    return (syb === true || syb === 'true') ? this.props.symbol : ''
  }

  toggleLastPrice(syb) {
    return (syb === true || syb === 'true') ? (this.props.last_price) ? this.props.last_price : '130' : ''
  }

  toggleLastPricePer(syb) {
    return (syb === true || syb === 'true') ? '-3.70%' : ''
  }

  toggleLastPriceCountDown(syb) {
    return (syb === true || syb === 'true') ? '130' : ''
  }

  toggleHighLine(syb) {
    return (syb === true || syb === 'true') ? 'High: ' + this.state.hignlinevalue : ''
  }
  toggleLowerLine(syb) {
    return (syb === true || syb === 'true') ? 'Low: ' + this.state.lowerlinevalue : ''
  }
  toggleLastLine(syb) {
    return (syb === true || syb === 'true') ? 'Avg: ' + this.state.lastLineValue : ''
  }

  getDateFormat() {
    return this.props.date_format_val;
  }

  returnScalePosition() {
    return (this.props.scale_placement === 'left') ? false : true;
  }
  async getResult() { 
	  //alert('1');
    this.props
      .getStockValue(
        this.props.symbol,
        this.props.stock,
        this.props.time,
        this.props.mult,
        this.props.startd,
        this.props.endd
      )
      .then(() => {
        if (this.props.stockValue.error == null) {
          const stockArray = [];
          const volumeArray = [];
          if (this.props.stockValue.stockList) {

            this.props.stockValue.stockList.results &&
              this.props.stockValue.stockList.results.map(
                ({ o, h, l, c, v, t }) => stockArray.push([t, o, h, l, c, ])
              );
            localStorage.setItem('stock_chart_data', JSON.stringify(stockArray))
            this.props.stockValue.stockList.results.map(
              ({ o, h, l, c, v, t }) => volumeArray.push([t, v])
            );
			//alert('12');
			//console.log('volumeArray',volumeArray)
			localStorage.setItem('renko_chart_data', JSON.stringify(volumeArray))

            const array = this.props.stockValue.stockList.results
            const arrayColumn = (array, column) => {
              return array.map(item => item[column]);
            };
            var highValues = arrayColumn(array, 'h');
            // console.log(highValues)
            // console.log('highValues')
            highValues.sort(function (a, b) {
              return b - a;
            });
            const array1 = this.props.stockValue.stockList.results
            const arrayColumn1 = (array1, column) => {
              return array.map(item => item[column]);
            };
            var lowerValues = arrayColumn1(array1, 'l');
            lowerValues.sort(function (a, b) {
              return a - b;
            });

            if (this.props.showHighLowerLine == true) {
              // alert('hii'); 
              this.setState({ hignlinevalue: highValues[0], lowerlinevalue: lowerValues[0], lastLineValue: highValues[highValues?.length - 1] })

            } else {
              this.setState({ hignlinevalue: '', lowerlinevalue: '' })
            }
            // console.log('stockArray',stockArray);
            // console.log('volumeArray',volumeArray);
            // console.log('this.props.stockValue.stockList.results',this.props.stockValue.stockList.results);
			console.log('stockArray',stockArray)
            this.setState(
              {
                stockData: stockArray,
                allData: this.props.stockValue.stockList.results,
                volume: volumeArray
              },
              () => {
                this.setState({ stockLoad: true });
              }
            );

			console.log('stockArray111',this.state)
          }
        }
      })
  }  
  render() {
    console.log('render')
    const { stockError, background_color } = this.state;
    let rangeSelected = 1;
    if (this.props.time == "minute" || this.props.time == "hour") {
      rangeSelected = 0;
    }
    const data = [[1642514400000, 142.71, 142.71, 142.71, 142.71]];
    Highcharts.theme = {
      // some styling
    };
    const options2 = this.state.seriesData;
    console.log('options2')
    console.log(options2)
    let ohlcValue = this.props.ohlcValue
    let barChangeVal = this.props.bar_change_val
    let marketStatus = this.props.market_status
    let volValue = this.props.volValue
    let prePostMktPrice = (this.props.pre_post_mkt_price === 'true' || this.props.pre_post_mkt_price === true) ? '120' : '';
    let barChangeValue = (this.props.bar_change_val === 'true' || this.props.bar_change_val === true) ? '−0.40 (−0.81%)' : '';
    let loOverlappingLabel = (this.props.lo_overlapping_label === 'true' || this.props.lo_overlapping_label === true) ? false : true;
    let plusButton = (this.props.addPlusAlert === 'true' || this.props.addPlusAlert === true) ? true : false;
    var myElemOHLC = document.getElementById('ohlc_vals');
    var myElemVol = document.getElementById('vol_vals');
    var l_change = document.getElementById('l_change');
    var market_status = document.getElementById('market_status');
    const styles = reactCSS({
      'default': {
        color: {
          width: '36px',
          height: '14px',
          borderRadius: '2px',
          background: `rgba(${ this.state.color.r }, ${ this.state.color.g }, ${ this.state.color.b }, ${ this.state.color.a })`,
        },
        swatch: {
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
      },
    });

    if (market_status !== null) {
      if (marketStatus === 'true' || marketStatus === true) {
        market_status.style.display = 'block';
      } else {
        market_status.style.display = 'none';
      }
    }

    if (l_change !== null) {
      if (barChangeVal === 'true' || barChangeVal === true) {
        l_change.style.display = 'block';
      } else {
        l_change.style.display = 'none';
      }
    }

    if (myElemOHLC !== null) {
      if (ohlcValue === 'true' || ohlcValue === true) {
        myElemOHLC.style.display = 'block';
      } else {
        myElemOHLC.style.display = 'none';
      }
    }

    if (myElemVol !== null) {
      if (volValue === 'true' || volValue === true) {
        myElemVol.style.display = 'block';
      } else {
        myElemVol.style.display = 'none';
      }
    }

    console.log(this.state.background_color)
    console.log('this.props.alpha_color')
    const goldmine = this.props.date_format_val;
    const alphaMine = 'rgba(' + this.props.alpha_color.r + ',' + this.props.alpha_color.g + ',' + this.props.alpha_color.b + ',' + this.props.alpha_color.a + ')';
  
    Highcharts.Annotation.prototype.addClipPaths = function () { };
    Highcharts.Annotation.types.crookedLine.prototype.setClipAxes = function () { };
    
    Highcharts.setOptions({
      lang: {
        stockTools: {
          gui: {
            inputText: 'Text annotation from input'
          }
        },
        navigation: {
          popup: {
            inputText: 'Input text'
          }
        }
      }
    });

    if (market_status !== null) {
      if (marketStatus === 'true' || marketStatus === true) {
        market_status.style.display = 'block';
      } else {
        market_status.style.display = 'none';
      }
    }

    if (l_change !== null) {
      if (barChangeVal === 'true' || barChangeVal === true) {
        l_change.style.display = 'block';
      } else {
        l_change.style.display = 'none';
      }
    }

    if (myElemOHLC !== null) {
      if (ohlcValue === 'true' || ohlcValue === true) {
        myElemOHLC.style.display = 'block';
      } else {
        myElemOHLC.style.display = 'none';
      }
    }

    if (myElemVol !== null) {
      if (volValue === 'true' || volValue === true) {
        myElemVol.style.display = 'block';
      } else {
        myElemVol.style.display = 'none';
      }
    }

    
    //const goldmine = this.props.date_format_val;
    //const alphaMine = 'rgba(' + this.props.alpha_color.r + ',' + this.props.alpha_color.g + ',' + this.props.alpha_color.b + ',' + this.props.alpha_color.a + ')';
    // var chartSeries = this.state.add_series_data;
    const options = {
      chart: {
        type: "candlestick",
        height: 700,
        events: { 
          load: function (e) {
            alert('q')
            var chart = this;
            chart.showLoading('Simulating Ajax ...');
            $('.abc').on('DOMSubtreeModified click', function(){
              setTimeout(function (chart_update){ 
              var seriesData = JSON.parse(localStorage.getItem('indicator'));
          chart.stockTools.showhideBtn.click();

              if(seriesData !== null){
                if(seriesData?.length>0){
                  seriesData.forEach(function(data){
                    chart_update.addSeries({
                        type: data.type,
                        linkedTo: 'aapl',
                        //id: 'overlay',
                        lineWidth: 0.6,
                        color:'green',
                        marker: {
                          enabled: false
                        } 
                      });
                  });
                }
              }
            },1000,chart); 

            setTimeout(function (chart_update){ 
              var seriesData = JSON.parse(localStorage.getItem('oscilator'))
              if(seriesData != null){ 
                  seriesData.forEach(function(data){                   
                  chart_update.addSeries({ 
                      type: data.type,
                      linkedTo: 'aapl',
                      //id: 'overlay',
                      lineWidth: 0.6,
                      color:'green',
                      marker: {
                        enabled: false
                      } 
                  });
                })
              }
            },1000,chart);
            
            setTimeout(function (chart_update){ 
              var seriesData = JSON.parse(localStorage.getItem('ma_template'))
              if(seriesData !== null){
                if(seriesData?.length>0){ 
                  seriesData.forEach(function(data,index){
                    if(index>0){
                      chart_update.addSeries(data) 
                    }
                  });
                }
              }
            },1000,chart);

            setTimeout(function (chart_update){ 
              var seriesData = JSON.parse(localStorage.getItem('bill_template'))
              if(seriesData !== null){
                if(seriesData?.length>0){ 
                  seriesData.forEach(function(data,index){
                    if(index>0){
                      chart_update.addSeries(data) 
                    }
                  });
                }
              }
            },1000,chart);

            setTimeout(function (chart_update){ 
              var seriesData = JSON.parse(localStorage.getItem('displaced_template'))
              console.log('displaced_template')
              console.log(seriesData)
              if(seriesData !== null){
                if(seriesData?.length>0){ 
                  seriesData.forEach(function(data,index){
                    if(index>0){
                      chart_update.addSeries(data) 
                    }
                  });
                }
              }
            },1000,chart);

            }); 
             
            $('.abc').trigger('click');
            document.getElementById('oscillators').addEventListener('change', function (e) {
                var series = chart.get('oscillator');
               alert('aa')
                //if (series) {
                    //series.remove(false);
                    chart.addSeries(
                      {
                        type: 'rsi',
                        id: 'oscillator',
                        linkedTo: 'aapl',
                        yAxis: 1
                    }
                  );
                    
                //}
            });
           // this.reflow()
          }
        }
        //backgroundColor: this.state.backgroundColor,
      },

      // plotOptions: {
      //   candlestick: {
      //     color: this.props.up_color_color,
      //     upColor: this.props.down_color_color
      //   },
      //   series: {
      //     color: '#FF0000'
      //   }
      // },
      title: {
        text: (this.props.statusLineSymbol === true || this.props.statusLineSymbol === 'true') ? this.getConditionalSymbolData(this.props.symbolVariation) : '',
      },
      // xAxis: {
      //   gridLineWidth: this.props.grid,
      // },

      xAxis: {

        crosshair: {
          text: 'asdasdas',
          snap: false,
          value: '3434',
          label: {
            enabled: true,
            format: '{value:.1f}' // One decimal
          }
        },

        labels: {
          allowOverlap: loOverlappingLabel
        },
        type: 'datetime',
        dateTimeLabelFormats: {
          day: '%e of %b'
        }
      },

      rangeSelector: {
        enabled:false,
       
      },
      plotOptions: {
        candlestick: {
          color: this.props.up_color_color,
          upColor: this.props.down_color_color
        },
        series: {
          color: '#FF0000',
          name: (this.props.statusLineSymbol === true || this.props.statusLineSymbol === 'true') ? this.getConditionalSymbolData(this.props.symbolVariation) : '',
          point: {
            events: {
              mouseOver: function () {
                // console.log('ji');

                var chart = this.series.chart;
                if (!chart.lbl) {
                  chart.lbl = chart.renderer.label('').attr({
                    padding: 10,
                    r: 10,
                    fill: background_color
                  }).css({
                    color: '#000000',
                    background: "#000000"
                  }).add();
                } else {



                  chart.lbl.fill = "green";
                }
                // console.log('this');
                // console.log(chart.lbl.fill);
                console.log('ddddd', chart);
                // console.log(this);
                // console.log('this');
                if (this.options.high) {
                  // console.log('object----' + ohlcValue);
                  if (ohlcValue === 'true' || ohlcValue === true) {
                    // console.log('object----' + JSON.stringify(this));
                    chart.lbl
                      .show()
                      .attr({
                        text: '<span style="fill:green" id="market_status">●</span>  <div id="ohlc_vals">O: <div class="l_open">' + this.open + '</div>  H: <div class="l_high">' + this.high + '</div>  L: <div class="l_low">' + this.low + '</div>  C: <div class="l_close">' + this.y + '</div>  <div id="l_change">' + barChangeValue + '</div></div>'
                      });
                  }
                } else {
                  if (volValue === 'true' || volValue === true) {
                    chart.lbl
                      .show()
                      .attr({
                        text: 'Vol: <div class="l_volume">' + this.y
                      });
                  }
                }

              }
            }
          },
          events: {
            mouseOut: function () {
              if (this.chart.lbl) {
                // this.chart.lbl.hide();
              }
            }
          }
        }
      },
      tooltip: {
        split: false,
        formatter: function () {
            var point = this.point,
            chart = point.series.chart,
            pointIndex = point.index,
            date = Highcharts.dateFormat('%b %e, %H:%M', this.x),
            volumePoint = chart.series[1].points[pointIndex];
            const selectedFormat = document.getElementById("dateFormat").value;
            var textR = ''
            textR += ['<b>Date: ' + Highcharts.dateFormat('' + selectedFormat + ' %H:%M', this.x) + '</b>'].concat();

            if (volValue === 'true' || volValue === true) {
              if(volumePoint.y != undefined){
                textR += '<br/><span id="volumess"> Volumes: ' + volumePoint.y + '</span>';
              }
            } else {

            }
          return textR;

        }
      },
      stockTools: {
        gui: {
          enabled: true,
          //buttons: ['thresholds', 'separator', 'simpleShapes', 'lines', 'crookedLines', 'measure', 'advanced', 'toggleAnnotations', 'separator', 'verticalLabels', 'flags', 'separator', 'zoomChange', 'fullScreen', 'typeChange', 'separator', 'currentPriceIndicator', 'saveChart'],
          definitions: {
            inputText: {
                className: 'highcharts-inputText-annotation',
                symbol: 'label.svg'
            },
            thresholds: {
                className: 'highcharts-threshold-annotation',
                symbol: 'horizontal-line.svg'
            }
          }
        }
      },

      navigation: {
        annotationsOptions: {
          shapeOptions: {
            fill: 'rgba(0, 0, 0, 0)',
            stroke: 'rgba(0, 0, 0, 1)',
            strokeWidth: 2,
            borderColor: 'gray',
            type: 'rect',
            borderWidth: 2
          }
        },
        bindingsClassName: 'annotation-container',

        bindings: {
          thresholds: {
              className: 'highcharts-threshold-annotation',
              init: function(e) {
                  alert("test alert 2");
              },
              // start: function(event) {
              //   var chart = this.chart,
              //     x = chart.xAxis[0].toValue(event.chartX),
              //     y = chart.yAxis[0].toValue(event.chartY),
              //     colors = chart.options.colors,
              //     series = chart.series[0],
              //     zones = series.userOptions.zones || [];

              //   chart.customColorIndex = chart.customColorIndex || 1;

              //   chart.customColorIndex++;

              //   if (
              //     chart.customColorIndex === colors?.length
              //   ) {
              //     chart.customColorIndex = 1;
              //   }

              //   zones.push({
              //     color: colors[chart.customColorIndex],
              //     value: y
              //   });
              //   chart.addAnnotation({
              //     langKey: 'thresholds',
              //     zoneIndex: zones?.length - 1,
              //     type: 'infinityLine',
              //     draggable: 'y',
              //     events: {
              //       drag: function(e) {
              //         var newZones = series.userOptions.zones;
              //         newZones[this.userOptions.zoneIndex].value = chart.yAxis[0].toValue(e.chartY);
              //         chart.series[0].update({
              //             zones: newZones
              //         });
              //       }
              //     },
              //     typeOptions: {
              //       type: 'horizontalLine',
              //       points: [{
              //         x: x,
              //         y: y
              //       }]
              //     }
              //   });

              //   chart.series[0].update({
              //     zones: zones
              //   });
              // }
          },
          
          inputText: {
            className: 'highcharts-inputText-annotation',
            start: function (e) {

              const xvalueAn = e.chartX - this.chart.plotLeft;
              const yvalueAn = e.chartY - this.chart.plotTop;
              var coords = this.chart.pointer.getCoordinates(e),
                x = coords.xAxis[0].value,
                y = coords.yAxis[0].value,
                navigation = this.chart.options.navigation,
                controlPoints = [{
                  positioner: function (target) {
                    if (!target.graphic.placed) {
                      return {
                        x: 0,
                        y: -9e7
                      };
                    }
                    return {
                      x: x -
                        this.graphic.width / 2,
                      y: y -
                        this.graphic.height / 2
                    };
                  },
                  events: {
                    drag: function (e, target) {
                      var xy = this.mouseMoveToTranslation(e);
                      target.translate(xy.x, xy.y);
                      target.annotation.labels[0].options =
                        target.options;
                      target.redraw(false);
                    }
                  }
                }];
              var _self = this;
              var labelTextForm = document.getElementById('labelTextForm');
              var onclick = function () {
                _self.chart.addAnnotation(Highcharts.merge({
                  langKey: 'label',
                  labelOptions: {
                    format: labelTextForm.querySelector('#labelTextInput').value,
                    shape: 'rect'
                  },
                  labels: [{
                    point: {
                      xAxis: 0,
                      yAxis: 0,
                      x: x,
                      y: y
                    },
                    overflow: 'none',
                    crop: false,
                    text: labelTextForm.querySelector('#labelTextInput').value,
                    controlPoints: controlPoints
                  }]
                }, navigation
                  .annotationsOptions, navigation
                    .bindings
                    .labelAnnotation
                  .annotationsOptions));
              };

              Highcharts.css(
                labelTextForm, {
                top: (e.pageY + 15) + 'px',
                left: e.pageX + 'px',
                display: 'block'
              }
              );

              var unbinder = Highcharts.addEvent(
                labelTextForm.querySelector('#labelTextButton'),
                'click',
                function () {
                  onclick();

                  Highcharts.css(labelTextForm, {
                    display: 'none'
                  });

                  unbinder();
                }
              )

              return true;
            }
          },
          rectangleAnnotation: {
            start: function (e) {

              var coords = this.chart.pointer.getCoordinates(e),
                navigation = this.chart.options.navigation,
                x = coords.xAxis[0].value,
                y = coords.yAxis[0].value,
                controlPoints = [{
                  positioner: function (annotation) {
                    var xy = Highcharts.Annotation.MockPoint
                      .pointToPixels(
                        annotation.shapes[0].points[2]
                      );

                    return {
                      x: xy.x - 4,
                      y: xy.y - 4
                    };
                  },
                  events: {
                    drag: function (
                      target
                    ) {
                      var coords = this.chart.pointer.getCoordinates(e),
                        x = coords.xAxis[0].value,
                        y = coords.yAxis[0].value,
                        shape = target.options.shapes[0],
                        points = shape.points;

                      // Top right point
                      points[1].x = x;
                      // Bottom right point (cursor position)
                      points[2].x = x;
                      points[2].y = y;
                      // Bottom left
                      points[3].y = y;

                      target.options.shapes[0].points = points;

                      target.redraw(false);
                    }
                  }
                }];

              return this.chart.addAnnotation(
                Highcharts.merge({
                  langKey: 'rectangle',
                  shapes: [{
                    type: 'path',
                    points: [{
                      xAxis: 0,
                      yAxis: 0,
                      x: x,
                      y: y
                    }, {
                      xAxis: 0,
                      yAxis: 0,
                      x: x,
                      y: y
                    }, {
                      xAxis: 0,
                      yAxis: 0,
                      x: x,
                      y: y
                    }, {
                      xAxis: 0,
                      yAxis: 0,
                      x: x,
                      y: y
                    }, {
                      // workaround: add one more point:
                      xAxis: 0,
                      yAxis: 0,
                      x: x,
                      y: y
                    }]
                  }],
                  controlPoints: controlPoints
                },
                  navigation
                    .annotationsOptions,
                  navigation.bindings
                    .rectangleAnnotation
                    .annotationsOptions
                )
              );
            }
          }
        }
      },
      yAxis: [{
        maxPadding: this.props.lock_price_to_bar_ratio_val,
        labels: {
          allowOverlap: loOverlappingLabel
        },
        clickOnCrosshair: function () {
          console.log(this);
        },
        crosshair: {
          useHTML: true,
          snap: false,
          width: 0.5,
          color: 'black',
          label: {
            useHTML: true,
            backgroundColor: 'rgb(255,0,0)',
            events: {
              dblclick: function () {
                console.log('a')
              },
              click: function () {
                console.log('b')
              },
              contextmenu: function () {
                console.log('c')
              }
            },
            formatter: function (value) {
              return (plusButton === 'true' || plusButton === true) ? '<i class="fa-solid fa-square-plus"></i><span id="' + Number(value).toFixed(2) + '" class="plusAddAlert fa fa-solid fa-eye">Add Alert  ' + Number(value).toFixed(2) + '</span><div id="myDIV">This is my DIV element.</div >' : '';
            },
            enabled: plusButton,
            padding: 8
          }
        },
        plotLines: [
          {
            value: 125,
            color: 'rgb(100, 100, 100)',
            dashStyle: 'Dot',
            width: 2,
            zIndex: 5,
            label: {
              text: 'M: 34.9',
              rotation: 0,
              align: this.props.scale_placement,
              style: {
                overflow: 'justify',
                color: 'rgb(80, 80, 80)',
                // fontSize: '15px'
              },
              // y: -49,
              // x: -50
            }
          },
          {
            color: 'red',
            width: 2,
            value: 125,
            padding: 40,
            label: {
              padding: 40,
              align: this.props.scale_placement,
              text: 'Plot line',
              style: {
                overflow: 'justify',
                text: 'Plot line',
                y: -10

              }
            }
          }, {
            color: 'red',
            width: 2,
            value: this.state.hignlinevalue,
            label: {
              style: {
                width: '400px',
                'min-width': '350px'
              },
              align: this.props.scale_placement,
              x: -20,
              text: '<div class="high_value_plot plot_line_dyn">' + this.toggleHighLine(this.props.sym_highlowlast_label) + '</div>'
            }
          },
          {
            color: 'red',
            width: 2,
            value: this.state.lowerlinevalue,
            label: {
              align: this.props.scale_placement,
              x: -20,
              text: '<div class="high_value_plot plot_line_dyn">' + this.toggleLowerLine(this.props.sym_highlowlast_label) + '</div>'
            }
          },
          {

            color: 'red',
            width: 2,
            value: this.state.lastLineValue,
            label: {
              align: this.props.scale_placement,
              x: -20,
              text: '<div class="high_value_plot plot_line_dyn">' + this.toggleLastLine(this.props.sym_highlowlast_label) + '</div>'
            }
          },
          //@todo - Rohit Saini- Make it dynamic
          {
            color: 'red',
            width: 2,
            value: 135,
            label: {

              style: {
                backgroundColor: 'red',
                color: 'blue',
                fontWeight: 'bold'
              },
              align: this.props.scale_placement,
              x: -10,
              text: '<div class="high_value_plot plot_line_dyn" style="background:red;">' + this.toggleTicker(this.props.sym_name_label) + ':' + this.toggleLastPrice(this.props.sym_last_price_label) + '<br/>' + this.toggleLastPricePer(true) + '<br/>' + this.toggleLastPriceCountDown(this.props.countdownBar) + '</div>'
            }
          },
          {
            color: 'red',
            width: 0,
            value: 120,
            label: {
              align: this.props.scale_placement,
              x: -10,
              text: '<div class="high_value_plot plot_line_dyn">' + prePostMktPrice + '</div>'
            }
          },
          {
            color: 'red',
            width: 0,
            value: 140,
            label: {
              align: this.props.scale_placement,
              x: -10,
              text: (this.props.askBid) ? '<div class="high_value_plot plot_line_dyn">Ask 140</div>' : '',
            }
          },
          {
            color: 'red',
            width: 0,
            value: 130,
            label: {
              align: this.props.scale_placement,
              x: -10,
              text: (this.props.askBid) ? '<div class="high_value_plot plot_line_dyn">Bid 130</div>' : '',
            }

          }

        ],
        lineWidth: 2,
        opposite: this.returnScalePosition(),
        minRange: 0.001,
        gridLineWidth: this.props.grid,

        labels: {
          align: 'right',
          x: -3
        },
        title: {
          text: 'OHLC'
        },
        height: '60%',
        resize: {
          enabled: true
        }
      }, {
        lineWidth: 2,
        opposite: this.returnScalePosition(),
        minRange: 0.001,

        gridLineWidth: this.props.grid,
        labels: {
          align: 'right',
          x: -3
        },
        title: {
          text: 'Volume'
        },
        top: '65%',
        height: '35%',
        offset: 0
      }
      ],
      exporting: {
        enabled: true,
        buttons: {
          menuItems: [
            "viewFullscreen",
            "printChart",
            "separator",
            "downloadPNG",
            "downloadJPEG",
            "downloadPDF",
            "downloadSVG",
          ],
        },
      },
      xAxis: {
        type: 'datetime',
        min: new Date().setDate(new Date().getDate() - 60),
        max: new Date().getTime(new Date().getDate() -1)
      },
      series: [
        {
          id: 'aapl',
          type: this.props.graph,
          name: (this.props.statusLineSymbol === true || this.props.statusLineSymbol === 'true') ? this.getConditionalSymbolData(this.props.symbolVariation) : '',
          data: this.state.stockData,
          // data: data,
          useOhlcData: false,
          tooltip: {
            valueDecimals: 2,
          },
          dataGrouping: {},
        },
        {
          type: 'column',
          name: 'Volume', 
          yAxis: 1,
          data: this.state.volume,
          dataGrouping: {}
        }
        
      ]
    };
    
    console.log('wwwwwwwwwwwwww')
    //console.log($('#highcharts-rkenjx4-0').highcharts())
       // if(this.props.get_indicator_prop_data != '')        
        // {
        //   var seriesData = this.props.get_indicator_prop_data
        //   var chart = Highcharts.Chart;
        //     options.series[
        //       {
        //       seriesData
        //     }];
        // }
    // console.log('options.chart')
    // console.log(Highcharts.Chart)
    Highcharts.Annotation.prototype.addClipPaths = function () { };
    Highcharts.Annotation.types.crookedLine.prototype.setClipAxes = function () { };
 
    Highcharts.setOptions({
      lang: {
        stockTools: {
          gui: {
            inputText: 'Text annotation from input'
          }
        },
        navigation: {
          popup: {
            inputText: 'Input text'
          }
        }
      }
    });

    Highcharts.setOptions({
      legend: {
        enabled: true
      },
      rangeSelector: {
          selected: 2
      },
      yAxis: [{
          height: '60%'
      }, {
          top: '60%',
          height: '20%'
      }, {
          top: '80%',
          height: '20%'
      }],
      plotOptions: {
          series: {
              showInLegend: true,
              accessibility: {
                  exposeAsGroupOnly: true
              }
          }
      }, 
      chart: {
      type: "candlestick",
      height: 700, 
      //zoomType: 'x',
      panning: true,
      panKey: 'shift',
      events: {
        redraw: function(event) {
          var chart = this;  
          setTimeout(() => {  
            chart.hideLoading(); 
          }, 2000);
          
        },
        load: function (e) { 
          var chart = this;
          chart.stockTools.showhideBtn.click();

          var zoomRatio = 1;
          var lastX;
          var lastY;
          var mouseDown;

          var setZoom = function(chart) {
            console.log('as',chart);
            var xMin = chart.xAxis[0].getExtremes().dataMin;
            var xMax = chart.xAxis[0].getExtremes().dataMax;
            var yMin = chart.yAxis[0].getExtremes().dataMin;
            var yMax = chart.yAxis[0].getExtremes().dataMax;
           
            chart.xAxis[0].setExtremes(xMin + (1 - zoomRatio) * xMax, xMax * zoomRatio);
            chart.yAxis[0].setExtremes(yMin + (1 - zoomRatio) * yMax, yMax * zoomRatio);
         };

          $('.highcharts-root').bind('mousewheel', function(event) {
            event.preventDefault(); 
            if(event.deltaY > 0) {
              if (zoomRatio > 0.7) {
                  zoomRatio = zoomRatio - 0.1;
                  setZoom(chart);
              }
  
            }else if (event.deltaY < 0) {
              zoomRatio = zoomRatio + 0.1;
              setZoom(chart);
            }
            setZoom(chart);

            console.log('asdasdsaa', event.deltaY, event.deltaFactor, event.originalEvent.deltaMode, event.originalEvent.wheelDelta);
          });

          chart.showLoading("<div class='spinner-border' style='margin-top:-200px' role='status'><span class='sr-only'>Loading...</span></div>"); 

          $(document).on('click','.show_indicator_div',function(e){ 
            $('.custom_indicator_div').show();
          }); 
          $(document).on('click','.show_hide_indicator_setting',function(e){ 
             window.$('#indicator_setting').modal('show');
          });
          $(document).on('click','.hide_indicator_setting_modal',function(e){ 
            window.$('#indicator_setting').modal('hide');
         }); 
          
          $(document).on('click','.show_template_div',function(e){ 
            var template_list = JSON.parse(localStorage.getItem('default_template'));
            template_list.map((data)=>{
              if(data.template == 'displaced_template'){
                $('.displaced_template_div').show();
              }else if(data.template == 'bill_template'){
                $('.bill_template_div').show()
              }else if(data.template == 'ma_template'){
                $('.ma_template_div').show();
              }else if(data.template == 'oscillater_template'){
                $('.oscillater_template_div').show();
              }else if(data.template == 'ma_template'){
                $('.ma_template_div').show();
              }else if(data.template == 'swing_template'){
                $('.swing_template_div').show();
              }else if(data.template == 'volume_template'){
                $('.volume_template_div').show();
              }
            })
          }); 
          $(document).on('click','.remove_template',function(e){  
            var template_series_id = parseInt( e.target.id.split('|')[0]);
            var template_series_name = e.target.id.split('|')[1];
            let existing_indicator_arr = JSON.parse(localStorage.getItem(template_series_name)) 
            
            existing_indicator_arr.splice(template_series_id, 1);  
            if(existing_indicator_arr?.length>0){
              localStorage.setItem(template_series_name, JSON.stringify(existing_indicator_arr));
            }else{
              localStorage.setItem(template_series_name, JSON.stringify(existing_indicator_arr)); 
            }
 
            $(`#template_tool_tip_${template_series_id}`).remove();  
            
            if(template_series_name == 'ma_template'){
              var remove_template_series_id = template_series_id+2; 
              for(var i = 2; i < remove_template_series_id+5; i++){
                const remove_chart_series = chart.series[i]; 
                remove_chart_series.setVisible(!remove_chart_series.visible) 
              }
              localStorage.removeItem("ma_template");
            }
            if(template_series_name == 'bill_template'){
              var remove_template_series_id = template_series_id+2; 
              for(var i = 2; i < remove_template_series_id+3; i++){
                const remove_chart_series = chart.series[i]; 
                remove_chart_series.setVisible(!remove_chart_series.visible) 
              }
              localStorage.removeItem("bill_template");
            }
            if(template_series_name == 'displaced_template'){
              var remove_template_series_id = template_series_id+2; 
              for(var i = 2; i < remove_template_series_id+3; i++){
                const remove_chart_series = chart.series[i]; 
                remove_chart_series.setVisible(!remove_chart_series.visible) 
              }
              localStorage.removeItem("displaced_template");
            }
            if(template_series_name == 'oscillater_template'){
              var remove_template_series_id = template_series_id+2; 
              for(var i = 2; i < remove_template_series_id+3; i++){
                const remove_chart_series = chart.series[i]; 
                remove_chart_series.setVisible(!remove_chart_series.visible) 
              }
              localStorage.removeItem("oscillater_template");
            }
            if(template_series_name == 'swing_template'){
              var remove_template_series_id = template_series_id+2; 
              for(var i = 2; i < remove_template_series_id+3; i++){
                const remove_chart_series = chart.series[i]; 
                remove_chart_series.setVisible(!remove_chart_series.visible) 
              }
              localStorage.removeItem("swing_template");
            }

            if(template_series_name == 'volume_template'){
              var remove_template_series_id = template_series_id+2; 
              for(var i = 2; i < remove_template_series_id+3; i++){
                const remove_chart_series = chart.series[i]; 
                remove_chart_series.setVisible(!remove_chart_series.visible) 
              }
              localStorage.removeItem("volume_template");
            }
 
            if(JSON.parse(localStorage.getItem(template_series_name))?.length == 0){
              $(`.oscillater_template_div`).hide(); 
              $(`#template_display_div`).hide();
              //location.reload();
            }
            $('.template_div_text').text(`Click to apply ${(JSON.parse(localStorage.getItem('default_template')))?JSON.parse(localStorage.getItem('default_template'))?.length:0} default template`) 
          });

          $(document).on('click','#clear_all_template',function(e){ 
            let existing_default_template = JSON.parse(localStorage.getItem('default_template')) 
            let existing_bill_template = JSON.parse(localStorage.getItem('bill_template')) 
            let existing_displaced_template = JSON.parse(localStorage.getItem('displaced_template')) 
            let existing_ma_template = JSON.parse(localStorage.getItem('ma_template')) 
            let existing_oscillater_template = JSON.parse(localStorage.getItem('oscillater_template')) 
            let existing_swing_template = JSON.parse(localStorage.getItem('swing_template')) 
            let existing_volume_template = JSON.parse(localStorage.getItem('volume_template')) 

            if(existing_default_template?.length>0){
              localStorage.removeItem("default_template");
            }
            if(existing_bill_template?.length>0){
              localStorage.removeItem("bill_template");
            }
            if(existing_displaced_template?.length>0){
              localStorage.removeItem("displaced_template");
            }
            if(existing_ma_template?.length>0){
              localStorage.removeItem("ma_template");
            }
            if(existing_oscillater_template?.length>0){
              localStorage.removeItem("oscillater_template");
            }
            if(existing_swing_template?.length>0){
              localStorage.removeItem("swing_template");
            }
            if(existing_volume_template?.length>0){
              localStorage.removeItem("volume_template");
            }
            if(chart.series?.length>0){
              var seriesLength = chart.series?.length;  
              for(var i = seriesLength-1; i > 1; i--){
                chart.series[i].remove();
              }
            }   
            $(`#template_display_div`).hide();
            $(`.displaced_template_div`).hide();
            $(`.bill_template_div`).hide();
            $(`.ma_template_div`).hide(); 
            $(`.oscillater_template_div`).hide(); 
            $(`.swing_template_div`).hide(); 
            $(`.volume_template_div`).hide(); 
          });
          
          $(document).on('click','.remove_volume',function(e){  
              const remove_chart_series = chart.series[1];  
              remove_chart_series.setVisible(!remove_chart_series.visible) 
              if(!remove_chart_series.visible){
                chart.yAxis[0].update({
                  height: '80%'
                })
                chart.yAxis[1].update({
                  height: '0%',
                  top: '100%',
                  title: {
                    text: null
                  }
                })  
              }else{ 
                chart.yAxis[0].update({
                  height: '60%'
                })
                chart.yAxis[1].update({
                  height: '35%',
                  top: '65%',
                  title: {
                    text: 'Volume'
                  }
                }) 
              }
              
              console.log('remove_chart_series.visible',remove_chart_series.visible)
              if(remove_chart_series.visible == false){
                 $('#volume_style').text('Show Volume'); 
                 $('#volume_style').removeClass('bg-danger').addClass('bg-success'); 
              }else{
                 $('#volume_style').text('Hide Volume');
                 $('#volume_style').removeClass('bg-success').addClass('bg-danger'); 
              }
          });

          $(document).on('click','.remove_indicator',function(e){
            var indicator_series_id = parseInt(e.target.id);
            let existing_indicator_arr = JSON.parse(localStorage.getItem('indicator')) 
            existing_indicator_arr.splice(indicator_series_id, 1);  
            if(existing_indicator_arr?.length>0){
              localStorage.setItem("indicator", JSON.stringify(existing_indicator_arr));
            }else{
              localStorage.setItem("indicator", JSON.stringify(existing_indicator_arr)); 
            }
            $(`#indicator_tool_tip_${indicator_series_id}`).remove(); 

            var remove_indicator_series_id = indicator_series_id+2;  
            const remove_chart_series = chart.series[remove_indicator_series_id]; 
            remove_chart_series.setVisible(!remove_chart_series.visible)  
 
            if(JSON.parse(localStorage.getItem('indicator'))?.length == 0){
              $(`.custom_indicator_div`).hide(); 
              $(`#indicator_display_div`).hide();
              //location.reload();
            }
            $('.indicator_div_text').text(`Click to apply ${(JSON.parse(localStorage.getItem('indicator')))?JSON.parse(localStorage.getItem('indicator'))?.length:0} Indicator`)
          });
          
          $(document).on('click','#clear_all_indicator',function(e){
            let existing_indicator = JSON.parse(localStorage.getItem('indicator'))  
            if(existing_indicator && existing_indicator?.length>0){
               localStorage.removeItem("indicator");
            } 
            if(chart.series?.length){
              var seriesLength = chart.series?.length; 
              console.log('seriesLength',seriesLength)
              for(var i = seriesLength-1; i > 1; i--){
                chart.series[i].remove();
              }
            }   
            $(`.custom_indicator_div`).hide(); 
            $(`#indicator_display_div`).hide(); 
          });
           
          $(document).on('click','.show_hide_indicator',function(e){  
            // Show hide eye icon in indicator 
            if($(this).hasClass('fa-eye')){
              $(this).removeClass('fa-eye');
              $(this).addClass('fa-eye-slash');
            }else{
              $(this).removeClass('fa-eye-slash');
              $(this).addClass('fa-eye');
            } 
            var indicator_series_id = parseInt(e.target.id)+2;  
            const chart_series = chart.series[indicator_series_id]; 
            chart_series.setVisible(!chart_series.visible) 
          });

          $("#indicator_display_div_modal").on("click",function(e){ 
            function maintainIndicator(chart_chart){  
                // start - Delete all series expect candle and volume
                if(chart_chart.series?.length){
                  var seriesLength = chart_chart.series?.length; 
                  console.log('seriesLength',seriesLength)
                  for(var i = seriesLength-1; i > 1; i--){
                    chart_chart.series[i].remove();
                  }
                }
                // start - Delete all series expect candle and volume
                var seriesDataLength = JSON.parse(localStorage.getItem('indicator'))?.length
                var seriesData = JSON.parse(localStorage.getItem('indicator'))  
                if(seriesDataLength > 0){   
                   seriesData.forEach(data => {  
                     console.log('data.data',data.data) 
                    if(data.yAxis){  
                        chart_chart.addSeries({
                          type: data.type,
                          linkedTo: 'aapl',
                          yAxis: 2,
                          lineWidth: data.lineWidth,
                          color:data.color,
                          marker: {
                            enabled: false
                          } 
                        }); 
                    }else{ 
                        chart_chart.addSeries({
                          type: data.type,
                          linkedTo: 'aapl', 
                          data:data.data,
                          lineWidth: data.lineWidth,
                          color:data.color,
                          params: {
                            period: 10
                          },
                          marker: {
                            enabled: false
                          } 
                        });  
                    }
                   });   
                }  
                console.log('chart_chart.series',chart_chart.series)
            }
            
            setTimeout(() => {  
              maintainIndicator(chart); 
            }, 1000);
            
          });

          //$("#abc").on("DOMSubtreeModified",function(e){ 
          $("#indicator_display_div").on("click",function(e){ 
            function maintainIndicator(chart_chart){  
                // start - Delete all series expect candle and volume
                if(chart_chart.series?.length){
                  var seriesLength = chart_chart.series?.length; 
                  console.log('seriesLength',seriesLength)
                  for(var i = seriesLength-1; i > 1; i--){
                    chart_chart.series[i].remove();
                  }
                }
                // start - Delete all series expect candle and volume
                var seriesDataLength = JSON.parse(localStorage.getItem('indicator'))?.length
                var seriesData = JSON.parse(localStorage.getItem('indicator'))  
                if(seriesDataLength > 0){   
                   seriesData.forEach(data => {  
                     console.log('data.data',data.data) 
                    if(data.yAxis){  
                        chart_chart.addSeries({
                          type: data.type,
                          linkedTo: 'aapl',
                          yAxis: 2,
                          lineWidth: data.lineWidth,
                          color:data.color,
                          marker: {
                            enabled: false
                          } 
                        }); 
                    }else{ 
                        chart_chart.addSeries({
                          type: data.type,
                          linkedTo: 'aapl', 
                          data:data.data,
                          lineWidth: data.lineWidth,
                          color:data.color,
                          params: {
                            period: 10
                          },
                          marker: {
                            enabled: false
                          } 
                        }); 
                        // chart_chart.addSeries({
                        //   name:'custom',
                        //   type: 'line',
                        //   linkedTo: 'aapl',
                        //   data: [110,120,130,140],
                        //   lineWidth: data.lineWidth,
                        //   color:data.color
                        // }); 
                    }
                   });   
                }  
                console.log('chart_chart.series',chart_chart.series)
            }
            // function displayTemplate(){
            //     setTimeout(function (chart_update){ 
            //       var seriesData = JSON.parse(localStorage.getItem('ma_template'))
            //       if(seriesData !== null){
            //         if(seriesData?.length>0){ 
            //           seriesData.forEach(function(data,index){
            //             if(index>0){
            //               chart_update.addSeries(data) 
            //             }
            //           });
            //         }
            //       }
            //     },1000,chart);

            //     setTimeout(function (chart_update){ 
            //       var seriesData = JSON.parse(localStorage.getItem('bill_template'))
            //       if(seriesData !== null){
            //         if(seriesData?.length>0){ 
            //           seriesData.forEach(function(data,index){
            //             if(index>0){
            //               chart_update.addSeries(data) 
            //             }
            //           });
            //         }
            //       }
            //     },1000,chart);

            //     setTimeout(function (chart_update){ 
            //       var seriesData = JSON.parse(localStorage.getItem('displaced_template'))
            //       console.log('displaced_template')
            //       console.log(seriesData)
            //       if(seriesData !== null){
            //         if(seriesData?.length>0){
            //           seriesData.forEach(function(data,index){
            //             if(index>0){
            //               chart_update.addSeries(data) 
            //             }
            //           });
            //         }
            //       }
            //     },1000,chart);
            // }

            setTimeout(() => {  
              maintainIndicator(chart);
              //displayTemplate(chart);
            }, 1000);
            
          });

          $("#template_display_div").on("click",function(e){ 
            function displayTemplate(){
                setTimeout(function (chart_update){ 
                  var seriesData = JSON.parse(localStorage.getItem('ma_template'))
                  if(seriesData !== null){
                    if(seriesData?.length>0){ 
                      seriesData.forEach(function(data,index){
                        if(index>0){
                          chart_update.addSeries(data) 
                        }
                      });
                    }
                  }
                },1000,chart);

                setTimeout(function (chart_update){ 
                  var seriesData = JSON.parse(localStorage.getItem('bill_template'))
                  if(seriesData !== null){
                    if(seriesData?.length>0){ 
                      seriesData.forEach(function(data,index){
                        if(index>0){
                          chart_update.addSeries(data) 
                        }
                      });
                    }
                  }
                },1000,chart);

                setTimeout(function (chart_update){ 
                  var seriesData = JSON.parse(localStorage.getItem('displaced_template')) 
                  if(seriesData !== null){
                    if(seriesData?.length>0){ 
                      seriesData.forEach(function(data,index){
                        if(index>0){
                          chart_update.addSeries(data) 
                        }
                      });
                    }
                  }
                },1000,chart);

                setTimeout(function (chart_update){ 
                  var seriesData = JSON.parse(localStorage.getItem('oscillater_template')) 
                  if(seriesData !== null){
                    if(seriesData?.length>0){ 
                      seriesData.forEach(function(data,index){
                        if(index>0){
                          chart_update.addSeries(data) 
                        }
                      });
                    }
                  }
                },1000,chart);

                setTimeout(function (chart_update){ 
                  var seriesData = JSON.parse(localStorage.getItem('swing_template')) 
                  if(seriesData !== null){
                    if(seriesData?.length>0){ 
                      seriesData.forEach(function(data,index){
                        if(index>0){
                          chart_update.addSeries(data) 
                        }
                      });
                    }
                  }
                },1000,chart);

                setTimeout(function (chart_update){ 
                  var seriesData = JSON.parse(localStorage.getItem('volume_template')) 
                  if(seriesData !== null){
                    if(seriesData?.length>0){ 
                      seriesData.forEach(function(data,index){
                        if(index>0){
                          chart_update.addSeries(data) 
                        }
                      });
                    }
                  }
                },1000,chart);
            }

            setTimeout(() => {   
              displayTemplate(chart);
            }, 1000); 
          });

        }
      },
      rangeSelector: {
        selected: 1,
      },
      //backgroundColor: this.state.backgroundColor,
    }});
    Highcharts.setOptions(Highcharts.theme);
    //console.log('JSON.parse(localStorage.getItem())',typeof JSON.parse(localStorage.getItem('indicator')))
    //console.log('sdasda', JSON.parse(localStorage.getItem('indicator')) )
    //console.log('sdasda12',JSON.parse(localStorage.getItem('indicator'))?.length )
    //console.log('neel',JSON.parse(localStorage.getItem('default_template')))
    // Highcharts.getJSON('https://demo-live-data.highcharts.com/aapl-ohlc.json', function (data) {

    //   // create the chart
      // Highcharts.stockChart('container', {
      //     rangeSelector: {
      //         selected: 1
      //     },
  
      //     title: {
      //         text: 'AAPL Stock Price'
      //     },
  
      //     series: [{
      //         type: 'candlestick',
      //         name: 'AAPL Stock Price',
      //         data: data,
      //     } ]
      // });
    //});
     
    // delete options2.chart;
    // delete options2.exporting;
     //delete options2.stockTools;
     //delete options2.plotOptions;
    // delete options2.rangeSelector;
    // delete options2.stockTools;
    // delete options2.title;
    // delete options2.tooltip;
    // delete options2.xAxis; 
    // delete options2.yAxis1; 
    
    console.log('option2',options2)

	function linearDataToRenko(data, change) {
		console.log('data',data)
		var renkoData = [],
		  prevPrice = data[0][1],
		  prevTrend = 0, // 0 - no trend, 1 - uptrend, 2 - downtrend
		  length = data.length,
		  i = 1;
	  
	  
		for (; i < length; i++) {
		  if (data[i][1] - data[i - 1][1] > change) {
			// Up trend
	  
			if (prevTrend === 2) {
			  prevPrice += change;
			}
	  
			renkoData.push({
			  x: data[i][0],
			  y: prevPrice,
			  low: prevPrice,
			  high: prevPrice + change
			});
	  
			prevPrice += change;
			prevTrend = 1;
			
		  } else if (Math.abs(data[i][1] - data[i - 1][1]) > change) {
	  
			if (prevTrend === 1) {
			  prevPrice -= change;
			}
			// Down trend
			renkoData.push({
			  x: data[i][0],
			  y: prevPrice,
			  low: prevPrice - change,
			  high: prevPrice,
			  color: 'black'
			});
	  
			prevPrice -= change;
			prevTrend = 2;
		  }
		} 
		console.log('renkoData',renkoData)
		return renkoData;
	  }
	  let tempData = [];
    
	var renko_data = JSON.parse(localStorage.getItem('renko_chart_data'))
	// console.log('asdsada',renko_data[0][0])
	// console.log('asdsada',renko_data[0][1])
	for (var i = 0; i < renko_data.length; i++) {
	tempData.push([
		new Date(renko_data[i][0]).getTime(),
		parseFloat(renko_data[i][1]),
	]);
	}
	tempData.reverse(); 
	console.log('tempData',tempData)
	var renkoOption = {
    chart:{
      height: 500,  
      events: { 
          load: function (e) {
            $('#type').hide()
            $("#container label").text('');
            this.stockTools.showhideBtn.click();
          }
        }
    },
    title: {
      text: (this.props.statusLineSymbol === true || this.props.statusLineSymbol === 'true') ? this.getConditionalSymbolData(this.props.symbolVariation) : '',
    },
    navigator: { 
      enabled: false
    },
    xAxis: {
      type: 'datetime',
      min: new Date().setDate(new Date().getDate() - 60),
      max: new Date().getTime(new Date().getDate() -1)
    },
		series: [{
			name: 'aapl',
			type: 'columnrange',
			fillColor: 'transparent',
			turboThreshold: 0,
			groupPadding: 0,
			pointPadding: 0,
			borderWidth: 1,
			data: linearDataToRenko(tempData, 1),
			dataGrouping: {
				enabled: false
			} 
		}],
		rangeSelector : {
      enabled:false,
			selected : 0,
			inputEnabled: false
		},
		navigator: {
            enabled: false
        },
		yAxis: [{  
			height: '100%'
		}, {
			top: '60%',
			height: '20%'
		}, {
			top: '80%',
			height: '20%'
		}],
	}
	console.log('renkoOption',renkoOption)
    //alert('11')
    return (
      <div className="mb-4 mt-4">   
        {this.state.stockLoad ? (
          <div id="container">
          <HighchartsReact
            highcharts={Highcharts}
            constructorType={"stockChart"}
            options={renkoOption}
			      allowChartUpdate = { true }
            immutable = { false }
            updateArgs = { [true, true, true] } 
          />
          </div>
        ) : (
          stockError
        )}

      <div class="python_code_editor" style={{ display:'none'}}>  
        <pre><code id="python_code"></code></pre>
      </div>

      <div class="modal" id="indicator_setting" >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body"> 
              <div className="col-md-12 border border-2 p-2 ml-1 indicator_setting_div" > 
                  <h3 className="text-center">{this.state.indicator_setting_name.toUpperCase()}</h3>
                  {/* <div className="form-group">
                    <label for="exampleInputEmail1">Index : </label>
                    <input type="test" className="form-control input_height" placeholder="index" value="1"/> 
                  </div>
                  <div className="form-group">
                    <label for="exampleInputPassword1">Period : </label>
                    <input type="text" className="form-control input_height" placeholder="period" value="20"/>
                  </div>  */}
                  <div className="form-group">
                    <label for="exampleInputPassword1">Line width : </label>
                    <input type="text" name="lineWidth" className="form-control input_height" placeholder="0.6" value={this.state.line_width} id={`${this.state.indicator_setting_name}|width`} onChange={(e) => this.addParamInIndicator(e)}/>
                  </div> 
                  <div className="form-group">
                    <label for="exampleInputPassword1">Line color : </label>
                    <input type="color" className="form-control input_height" id={`${this.state.indicator_setting_name}|color`} onChange={(e) => this.addParamInIndicator(e)} value={this.state.indicator_line_color}/>
                  </div>   
                  <button type="button" class="btn btn-dark btn-lg btn-block hide_indicator_setting_modal" id="indicator_display_div_modal" data-dismiss="modal" aria-label="Close">Add</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal" id="exampleModal" >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body">
            <input type="hidden"  id="exmModel" />
                <ul class="custom_bosy">
                    <li>
                      <span>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28" fill="none" stroke="currentColor">
                          <path stroke-linecap="round" d="M15.5 18.5h6m-3 3v-6"></path>
                          <rect width="6" height="6" rx="1.5" x="6.5" y="6.5"></rect>
                          <rect width="6" height="6" rx="1.5" x="15.5" y="6.5"></rect>
                          <rect width="6" height="6" rx="1.5" x="6.5" y="15.5"></rect>
                          </svg>
                      </span>
                    </li>
                    <li class="blanku"></li>
                    <li>
                        <select onChange={this.handleFontChange}>
                          <option value="10">10</option>
                          <option value="14">14</option>
                          <option value="18">18</option>
                          <option value="20">20</option>
                          <option value="24">24</option>
                          <option value="28">28</option>
                          <option value="30">30</option>
                          <option value="32">32</option>
                          <option value="34">34</option>
                          <option value="36">36</option>
                          <option value="40">40</option>
                        </select>
                    </li>
                    <li class="blanku"></li>
                    <li class="blanku">
                        <div style={ styles.swatch } onClick={ this.handleClick }>
                          <div style={ styles.color } />
                        </div>
                        { this.state.displayColorPicker ? <div style={ styles.popover }>
                          <div style={ styles.cover } onClick={ this.handleClose }/>
                          <SketchPicker color={ this.state.color } onChange={ this.handleChange } />
                        </div> : null }
                    </li>{ this.state.lock ? 
                              <li onClick={ this.handleUnlock } ><span class="icon-9pA37sIi"><i class="lockk fa-solid fa-lock"></i></span></li>
                            :
                              <li onClick={ this.handleLock } ><span class="icon-9pA37sIi"><i class="unlock fa fa-unlock" aria-hidden="true"></i></span></li>
                            }

                              <li onClick={ this.handleDelete } ><span class="icon-9pA37sIi"><i class="fa fa-trash" aria-hidden="true"></i></span></li>
                          </ul>
            </div>
          </div>
        </div>
      </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  stockValue: state.cardItems,
});

const mapDispatchToProps = (dispatch) => ({
  getStockValue: (symbol, stock, time, mult, startd, endd) =>
    dispatch(getStockValue(symbol, stock, time, mult, startd, endd)),
});
export default connect(mapStateToProps, mapDispatchToProps)(RenkoChart);