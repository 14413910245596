import React, { Component } from "react";
import Footer from "../layout/footer/Footer.component";
import Header from "../layout/header/Header.component";
import './Contactus.component.css';
import Robot from '../../../assests/robot.png'
import { Link } from "react-router-dom";

let imgPath="../../../assests/"
export default class ContactPage extends Component {
    render() {
        return (
            <div>
          <header>
            <div className="custom_container">
              <div className="row">
                <div className="col-md-12">
                  <Header />
                </div>
              </div>
            </div>
          </header>

          <section className="banner_outer">
            <div className="overlay"></div>
                <div className="custom_container">
                <div className="row" >
                    <div className="col-md-8 offset-md-2 col-sm-12 first_div text-center">
                      <div class="banner_content">
                        <h2>Contact Us</h2>
                         <hr class="banner_hr news_hr" /><h3>
                         <ul className="news_ul">
                           <li><Link to={"/home"} >Home</Link></li>
                           <li><i class="fa fa-angle-right"></i></li>
                           <li><Link to={"/news"} className="active">Contact Us</Link></li>
                         </ul>
                        </h3>
                      </div>
                    </div>
                </div>
            </div>
          </section>

          <section className="contact_outer">
            <div className="custom_container">
                    <div className="row">
                    <div className="col-md-6">
                        <div className="contact_left">
                        <p className="p_text"><b>If you have any questions regarding our operations or trading in general, feel free to refer all your queries to:</b></p>
                        <ul className="info_span">
                          <li><span className="dot_span"></span><span className="privcay_content_span">Need to report a connectivity issue at our portal?</span></li>
                          <li><span className="dot_span"></span><span className="privcay_content_span">Need more information about one of our features like signals premium service? </span></li>
                          <li><span className="dot_span"></span><span className="privcay_content_span">ADVERTISE WITH US.</span></li>
                          <li><span className="dot_span"></span><span className="privcay_content_span">Other?</span></li>
                        </ul>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="contact_right">
                        <h3 className="title_main user_info">
                        Thank you for <b>visiting us!</b>
                        </h3>
                        <div className="row">
                          <div className="col-md-12">
                            <label className="custom_label">Name</label>
                            <input className="custom_input" type="text" placeholder="John" />
                            <label className="custom_label">Email</label>
                            <input className="custom_input" type="text" placeholder="John@gmail.com" />
                            <label className="custom_label">Message</label>
                            <textarea className="custom_input" type="text" placeholder="Message Here.."></textarea>
                            <img className="robot_img" src={Robot} />
                          </div>
                        </div>
                        <div className="row">
                             <div className="col-md-12 text-left btn_c_u">
                              <a className="signin_btn updateprofile" type="submit" onClick={this.onSubmit}>Submit</a>                             
                             </div>
                           </div>
                        </div>
                    </div>
                </div>
            </div>
          </section>
      
          <footer>
            <Footer />       
          </footer>
            </div>
        )
    }
}
