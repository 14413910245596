import React, { Component } from "react";
import Footer from "../layout/footer/Footer.component";
import Header from "../layout/header/Header.component";
import "./HomePage.component.css";
import heroImg from "../../../assests/hero-bg.jpg";
import closeUp from "../../../assests/close_up.jpg";
import analyticsImg from "../../../assests/advanced-analytics.jpg";
import accountImg from "../../../assests/account_statement.jpg";
import marketupdateImg from "../../../assests/market_update.jpg";
import knowledgeImg from "../../../assests/knowledge.jpg";
import learnImg from "../../../assests/value-stocks.jpg";
import { Link } from "react-router-dom";

let imgPath = "../../../assests/";
export default class HomePage extends Component {
  componentDidMount() {}

  render() {
    return (
      <div>
        <header>
          <div className="custom_container">
            <div className="row">
              <div className="col-md-12">
                <Header />
              </div>
            </div>
          </div>
        </header>
        <section className="banner_outer">
          <div className="overlay"></div>
          <div className="custom_container">
            <div className="row">
              <div className="col-md-12 first_div text-center">
                <div className="banner_content">
                  <h2>Finmax Financial</h2>
                  <hr className="banner_hr"></hr>
                  <h3>
                    <p>
                      Trade in the <b>Forex</b> and <b>Stock</b>
                    </p>
                    <p>
                      <b>Market</b> with <b>Greater Reliability</b>
                    </p>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="custom_container">
            <div className="row">
              <div className="col-md-8 offset-md-2 col-sm-12 second_div text-center">
                <h3 className="title_main">
                  Smart Trading Tools for<br></br>
                  <b>Valueable Investment</b>
                </h3>
                <p className="p_text">
                  Forex trading may not be your strong suit yet, but with Finmax
                  Financial you can take advantage of cutting-edge technology to
                  help make more innovative investments and increase your
                  chances to garner higher profits.
                </p>
                <button type="button" class="btn btn-dark signup_btn">
                  <Link
                    to={"/signup"}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    Sign up for Free
                  </Link>
                </button>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="custom_container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="img_part">
                  <img src={heroImg} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="description_part">
                  <div className="title_part">
                    <h3 className="title_main">
                      Resourceful <b>Signals</b>
                    </h3>
                  </div>
                  <div className="detail_part">
                    <p className="p_text">
                      Trading signals, or simply signals, are pre-determined
                      thresholds, limits, or triggers to buy or sell a stock
                      option, based on pre-set conditions, e.g., earning reports
                      and volume surges, or more complex signals, computed using
                      existing signals. Access the reliable signals through
                      Finmax to up your trading game.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row align-items-center mt-5">
              <div className="col-md-6">
                <div className="description_part left_desc">
                  <div className="title_part">
                    <h3 className="title_main">
                      Secure <b>Accounts</b>
                    </h3>
                  </div>
                  <div className="detail_part">
                    <p className="p_text">
                      Access your accounts with greater security. We ensure the
                      security of your personal and financial information. Your
                      account security is our top priority. You will never be
                      asked to reveal your login details to us.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="img_part">
                  <img src={closeUp} />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="custom_container">
            <div className="row">
              <div className="col-md-8 offset-md-2 col-sm-12 second_div text-center">
                <h3 className="title_main">
                  <b>Powerful Tools</b> to Up <br></br>
                  Your <b>Trading Game</b>
                </h3>
                <p className="p_text">
                  Take a look at some of our features that will help you make
                  more informed, intelligent and timely investment decisions.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="advance_analytic">
                  <img src={analyticsImg} />
                  <p>Advanced Analytics</p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="advance_analytic">
                  <img src={accountImg} />
                  <p>Account statement publishing</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="advance_analytic">
                  <img src={knowledgeImg} />
                  <p>Helpful Knowledgebase</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="advance_analytic">
                  <img src={learnImg} />
                  <p>Learning for Improvement</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="advance_analytic">
                  <img src={marketupdateImg} />
                  <p>Latest Market Updates</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="technical_assistance">
          <div className="custom_container">
            <div className="row">
              <div className="col-md-8 offset-md-2 col-sm-12">
                <h3 className="title_main">
                  <b>We Are</b> Always There for<br></br>
                  <b>Technical Assistance</b>
                </h3>
                <p className="p_text">
                  For technical assistance and trading operations, our technical
                  team might require read-only access to your trading account.
                  Our experts can assist you in making viable financial
                  decisions.
                </p>
                <div className="sign_up_tagline text-center">
                  <h3>
                    <a>Start Trading NOW!</a>
                  </h3>
                </div>
                <Link to={"/signup"}>
                  <button type="button" class="btn btn-dark signup_btn signup">
                    Sign up
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </section>
        <footer>
          <Footer />
        </footer>
      </div>
    );
  }
}
