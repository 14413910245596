import logo from "./logo.svg";
import React, { Component, Fragment, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./components/common/home/HomePage.component";
import Login from "./components/common/login/Login.component";
import Employeelogin from "./components/common/login/Employeelogin.component";
import EmployeePage from "./components/common/employee-chat/Employeepage.component";
import SignUp from "./components/common/sign_up/Signup.component";
import ForgotPassword from "./components/common/forgot_password/ForgotPassword.component";
import ResetPassword from "./components/common/reset_password/ResetPassword.component";
import LandingPage from "./components/common/home/LandingPage.component";
import AboutUs from "./components/common/about_us/AboutUs.component";
import ChartPage from "./components/common/chart/Chart.component";
import Chatsyle from "./components/common/chat/Chatsyle.component";
import PageNotFound from "./components/common/page_not_found/pageNotFound.component";
import NewsPage from "./components/common/news/News.component";
import ContestPage from "./components/common/contest/Contest.component";
import PremiumPage from "./components/common/premium/Premium.component";
import BrokerPage from "./components/common/brokers/Broker.component";
import PrivacyPage from "./components/common/privacy/Privacy.component";
import TermsPage from "./components/common/terms/Terms.component";
import ContactPage from "./components/common/contactus/Contactus.component";
import EditProfile from "./components/common/profile/Editprofile.component";
import ChatPage from "./components/common/chat/Chat.component";
import ChatSinglePage from "./components/common/chat/Chatsingle.component";
import Chatsupportpage from "./components/common/chat/Chatsupport.component";
import singlePage from "./components/common/chat/Single.component";
import OutlookPage from "./components/common/outlook/Outlook.component"; 
import EconomicCalendar from "./components/common/calendar/EconomicCalendar";
import EbookPage from "./components/common/e-book/Ebook.component";
import ExpertAdvisor from "./components/common/expert_advisor/Expertadvisor.component";
import LiveRates from "./components/common/live_rates/Chart";
import LiveStream from "./components/common/livestream/LiveStream.component";
import Streaming from "./components/common/livestream/Streaming.component";
import Chart from "./components/common/live_rates/LiveRates.componet";
//import OpenChart from "./components/common/live_rates/OpenChart.componet";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import PrivateRoute from "./utills/PrivateRoute";
import { ToastContainer, toast } from "react-toastify";
import ChangePassword from "./components/common/change_password/ChangePassword.component";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "./App.css";
import CommunityPage from "./components/common/community/comunity.component";
import HostStream from "./components/common/hoststream/HostStream.component";
import CreateStream from "./components/common/livestream/CreateStream.component";
import MyStreams from "./components/common/livestream/MyStreams.component";
import ExpertAdvisorInput from "./components/common/expert_advisor/Expertadvisorinput.component";
import ExpertAdvisorResult from "./components/common/expert_advisor/Expertadvisorresult.component";
import ExpertAdvisorBuy from "./components/common/expert_advisor/ExpertAdvisorBuy.component";
import MyOrders from "./components/common/expert_advisor/MyOrders.component";
import RecievedOrders from "./components/common/expert_advisor/RecievedOrders.component";
import UploadFiles from "./components/common/expert_advisor/UploadFiles.component";
import Result from "./components/common/result/Result.component";


function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
        <Route exact path="/chat" element={<ChatPage />}></Route>
        <Route path="/singlechat/:id" element={<ChatSinglePage />} ></Route>
        <Route path="/charts/:id" element={<LiveRates />} ></Route>
            <Route
              exact
              path="/Chatsyle"
              element={<Chatsyle />}
            ></Route>

            <Route
              exact
              path="/support"
              element={<Chatsupportpage />}
            ></Route>

            <Route
              exact
              path="/live-stream"
              element={<LiveStream />}
            ></Route>

<Route
              exact
              path="/host-stream"
              element={<HostStream />}
            ></Route>

            <Route
              exact
              path="/streaming"
              element={<Streaming />}
            ></Route>
            <Route
              exact
              path="/create-stream"
              element={<CreateStream />}
            ></Route>
            <Route
              exact
              path="/my-streams"
              element={<MyStreams />}
            ></Route>

          <Route element={<PrivateRoute />}>
            
            
            <Route exact path="/outlook" element={<OutlookPage />}></Route>

            <Route
              exact
              path="/changepassword"
              element={<ChangePassword />}
            ></Route>
            <Route exact path="/editprofile" element={<EditProfile />}></Route>
          </Route>
          <Route exact path="/ebook" element={<EbookPage />}></Route>
          <Route exact path="/home" element={<HomePage />}></Route>

          <Route exact path="/employee_login" element={<Employeelogin />}></Route>

          <Route exact path="/employee-home" element={<EmployeePage />}></Route>
          <Route exact path="/about" element={<AboutUs />}></Route>
          <Route exact path="/login" element={<Login />}></Route>
          
          <Route exact path="/signup" element={<SignUp />}></Route>
          <Route
            exact
            path="/forgotpassword"
            element={<ForgotPassword />}
          ></Route>
          <Route
            exact
            path="/resetpassword"
            element={<ResetPassword />}
          ></Route>
          <Route exact path="/" element={<LandingPage />}></Route>
          <Route exact path="/linkedin" element={<LinkedInCallback />}></Route>
          <Route exact path="/news" element={<NewsPage />}></Route>
          <Route exact path="/community" element={<CommunityPage />}></Route>
          <Route exact path="/contest" element={<ContestPage />}></Route>

          <Route exact path="/premium" element={<PremiumPage />}></Route>
          <Route
            exact
            path="/economic-calendar"
            element={<EconomicCalendar />}
          ></Route>
          <Route
            exact
            path="/expert-advisor"
            element={<ExpertAdvisor />}
          ></Route>
           <Route
            exact
            path="/expert-advisor-buy/:id"
            element={<ExpertAdvisorBuy />}
          ></Route>
          <Route
            exact
            path="/expert-advisor-orders"
            element={<MyOrders />}
          ></Route>
           <Route
            exact
            path="/expert-advisor-orders-recieved"
            element={<RecievedOrders />}
          ></Route>
          <Route
            exact
            path="/expert-advisor-input"
            element={<ExpertAdvisorInput />}
          ></Route>
            <Route
            exact
            path="/expert-advisor-upload/:id"
            element={<UploadFiles />}
          ></Route>
          <Route
            exact
            path="/expert-advisor-result/:id"
            element={<ExpertAdvisorResult />}
          ></Route>
          <Route exact path="/brokers" element={<BrokerPage />}></Route>
          <Route exact path="/liverates" element={<LiveRates  />}></Route>
          <Route exact path="/chart" element={<Chart />}></Route>
          <Route exact path="/privacy" element={<PrivacyPage />}></Route>
          <Route exact path="/terms" element={<TermsPage />}></Route>
          <Route exact path="/chartpage" element={<ChartPage />}></Route>
          <Route exact path="/contactus" element={<ContactPage />}></Route>
          <Route path="*" element={<PageNotFound />}></Route>
		   <Route path="/result" element={<Result />}></Route>
		  
		  
Result
        </Routes>
        <ToastContainer />
      </div>
    </Router>
  );
}

export default App;
