import React, { Component } from "react";
import Footer from "../layout/footer/Footer.component";
import Header from "../layout/header/Header.component";
import './News.component.css'
import B1 from '../../../assests/b1.png';
import B2 from '../../../assests/b2.png';
import B3 from '../../../assests/b3.png';
import Explore from '../../../assests/explore.jpg';
import analyticsImg from '../../../assests/advanced-analytics.jpg';
import accountImg from '../../../assests/account_statement.jpg';
import Graph from '../../../assests/graph.jpg';
import F1 from '../../../assests/f1.png';
import E1 from '../../../assests/e1.jpg';
import E2 from '../../../assests/e2.jpg';
import E3 from '../../../assests/e3.jpg';
import E4 from '../../../assests/e4.jpg';
import S1 from '../../../assests/s1.jpg';
import S2 from '../../../assests/s2.jpg';
import S3 from '../../../assests/s3.jpg';
import S4 from '../../../assests/s4.jpg';
import R1 from '../../../assests/r1.jpg';
import R2 from '../../../assests/r2.jpg';
import O1 from '../../../assests/o1.jpg';
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";


let imgPath="../../../assests/"
export default class NewsPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      code: "",
      errorMessage: "",
      accessToken: "",
      email: "",
      stocks:[],
      forex:[],
      crypto:[],
    };
    
  }
  componentDidMount() {
    this.getLocation();
  }

  getLocation() {
    axios
      .get("https://api.finage.co.uk/news/market/AMZN?apikey="+process.env.REACT_APP_FINAGE_API_KEY)
      .then((response) => {
        if (
          response
        ) {
          let stocksData = response.data.news;
          this.setState({
            stocks: stocksData,
          });

          console.log(stocksData)
        }
      })
      .catch((error) => {
        console.log(error);
      });

      axios
      .get("https://api.finage.co.uk/news/market/GBP?apikey="+process.env.REACT_APP_FINAGE_API_KEY)
      .then((response) => {
        if (
          response
        ) {
          let stocksData = response.data.news;
          this.setState({
            forex: stocksData,
          });

          console.log(stocksData)
        }
      })
      .catch((error) => {
        console.log(error);
      });

      axios
      .get("https://api.finage.co.uk/news/market/BTC?apikey="+process.env.REACT_APP_FINAGE_API_KEY)
      .then((response) => {
        if (
          response
        ) {
          let stocksData = response.data.news;
          this.setState({
            crypto: stocksData,
          });

          console.log(stocksData)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
    render() {

      function formatDate(argument) {
 
        var created= moment(argument).utc().format('MM/DD/YYYY')
        return created;
      }
        return (
            <div>
               <header>
               <div className="custom_container">
                  <div className="row">
                     <div className="col-md-12">
                        <Header />
                     </div>
                  </div>
               </div>
               </header>
               <section className="banner_outer news_banner">
                  <div className="overlay"></div>
                  <div className="custom_container">
                     <div className="row" >
                        <div className="col-md-8 offset-md-2 col-sm-12 first_div text-center">
                           <div class="banner_content">
                              <h2>News</h2>
                              <hr class="banner_hr news_hr" />
                              <h3>
                                 <ul className="news_ul">
                                    <li>
                                       <Link to={"/home"} >
                                       Home</Link>
                                    </li>
                                    <li><i class="fa fa-angle-right"></i></li>
                                    <li>
                                       <Link to={"/news"} className="active">
                                       News</Link>
                                    </li>
                                 </ul>
                              </h3>
                           </div>
                        </div>
                     </div>
                  </div>
               </section>
               <section>
                  <div className="custom_container">
                     <div className="row" >
                        <div className="col-md-8 offset-md-2 col-sm-12 second_div text-center">
                           <p className="p_text newS_p">The markets never sleep. As the sun sets in New York, 
                              it’s rising in Sydney. With so much information out there, it’s hard to know what’s relevant or significant. 
                              Our journalists have their finger on the pulse and cherry pick the most important stories for our readers. 
                              Follow our latest news reports and stay informed on major events.
                           </p>
                        </div>
                     </div>
                  </div>
               </section>
               <section className="most_polupar">
                  <div className="custom_container">
                     <div className="row">
                        <div className="col-md-8 offset-md-2 col-sm-12 second_div text-center">
                           <h3 className="title_main">
                              <b>Forex</b>
                           </h3>
                        </div>
                     </div>
                     <div className="row popular_row">
                        { 

                          this.state.forex.map((symbol) => (

                            <div className="col-md-6">
                               <div className="advance_analytic">
                                  <img src={analyticsImg} />
                                  <p> <a href={symbol.url} action="_blank">{symbol.description}</a></p>
                                  <span className="date_span">{formatDate(symbol.date)}</span>
                               </div>
                            </div>
                        ))
                        }
                     </div>
                     <div className="row">
                        <div className="col-md-8 offset-md-2 col-sm-12 second_div text-center">
                           <h3 className="title_main">
                              Stock <b>Markets</b>
                           </h3>
                        </div>
                     </div>
                     <div className="row popular_row">
                        { 

                          this.state.stocks.map((symbol) => (

                            <div className="col-md-6">
                               <div className="advance_analytic">
                                  <img src={analyticsImg} />
                                  <p> <a href={symbol.url} action="__blank">{symbol.description}</a></p>
                                  <span className="date_span">{formatDate(symbol.date)}</span>
                               </div>
                            </div>
                        ))
                        }

                     </div>
                     <div className="row">
                        <div className="col-md-8 offset-md-2 col-sm-12 second_div text-center">
                           <h3 className="title_main">
                              <b>Cryptocurrencies</b>
                           </h3>
                        </div>
                     </div>
                     <div className="row popular_row">
                        { 

                          this.state.crypto.map((symbol) => (

                            <div className="col-md-6">
                               <div className="advance_analytic">
                                  <img src={analyticsImg} />
                                  <p> <a href={symbol.url} action="_blank">{symbol.description}</a></p>
                                  <span className="date_span">{formatDate(symbol.date)}</span>
                               </div>
                            </div>
                        ))
                        }
                     </div>
                  </div>
               </section>
               <footer>
               <Footer />       
               </footer>
            </div>
        )
    }
}
