import React, { Component } from "react";
import Footer from "../layout/footer/Footer.component";
import Header from "../layout/header/Header.component";
import './live-stream.component.css'
import Book from '../../../assests/book.jpg';
import Book1 from '../../../assests/book1.jpg';
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import io from 'socket.io-client';
let imgPath="../../../assests/";



export default class LiveStream extends Component {

  constructor(props) {

    super(props);

    this.state = {
		myStreamsList:[]
    };
	this.loadMyStreams = this.loadMyStreams.bind(this);
	this.loadMyStreams();
  }

  loadMyStreams() {

	let accessToekn = localStorage.getItem('accessToken');
	var authData = 'Token ' + accessToekn;

	axios
	  .get(`https://stock-trading-backend.itechnolabs.tech/stream/`, {
		headers: {
		  "Content-Type": "application/json"
		}
	  })
	  .then((response) => {
		this.setState({ myStreamsList: response.data.data });
		console.log("response is "+JSON.stringify(this.state.myStreamsList));

	   
	  }).catch((error) => {

		console.log("error", JSON.stringify(this.state.myStreamsList));
	  });
  }




    render() {
		const listItems = this.state.myStreamsList.map((item, index) => (

			<div class="col-md-4">
				
			<div class="box_outer45">    
				<a href={"/streaming?stream="+item.id}>
				<h5>{item.title}</h5>
				{/* <div class="liv_str">
					<span class="line_outer"><span class="logoIcon-mex0TGFj"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33 17" width="16" height="10"><path fill="currentColor" d="M21.92 4.24a3.46 3.46 0 0 0-3.5-3.42 3.46 3.46 0 0 0-3.49 3.42 3.46 3.46 0 0 0 3.5 3.42 3.43 3.43 0 0 0 3.49-3.42zM0 1v6.5h6.5V17H13V1H0zm26.1 16h-7.66l6.9-16H33l-6.9 16z"></path></svg></span>LIVE</span>
				</div> */}
					
				<div class="video_outer5">
<img src={item.cover} />
</div>
				<div class="profile_inner">
				<div class="inner_pry">
			
					<img style={{}} src={item.user.image} />
					<p>{item.user.first_name}</p>
					
				</div>
				<div class="right67"><span class="stats-mex0TGFj"><span>{item.views} views</span><span class="divider-mex0TGFj">•</span></span></div>
					
				</div>
				<div class="discription_outer">	
					<p>{item.short_description}</p>
				</div>
			</a>
			</div>
			</div>
		))
        return (
            <div>
          <header>
            <div className="custom_container">
              <div className="row">
                <div className="col-md-12">
                  <Header />
                </div>
              </div>
            </div>
          </header>

          <section className="banner_outer news_banner">
            <div className="overlay"></div>
                <div className="custom_container">
                <div className="row" >
                    <div className="col-md-8 offset-md-2 col-sm-12 first_div text-center">
                      <div class="banner_content">
                        <h2>Live Streams</h2>
                         <hr class="banner_hr news_hr" /><h3>
                         <ul className="news_ul">
                           <li><Link to={"/home"} >Home</Link></li>
                           <li><i class="fa fa-angle-right"></i></li>
                           <li><Link to={"/live-stream"} className="active" >Live Streams</Link></li>
                         </ul>
                        </h3>
                      </div>
                    </div>
                </div>
            </div>
          </section>
		 
        
     
          <section class="listing_stream top_strew">
			<div class="container">
				<div class="row">
					<div class="steam_outer">
						<h4><a href="#">Live streams</a><span class="icon-AsudVACm"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 14" width="8" height="14"><path fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linecap="square" stroke-width="2" d="M2 2l4 5-4 5"></path></svg></span></h4>
						
					</div>
				</div>
				<div class="row">
					
				{listItems}


				</div>
			</div>
            
          </section>
        
         
 
          <footer>
            <Footer />       
          </footer>
            </div>
        )
    }
}
