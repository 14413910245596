import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./Signup.component.css";
import Header from "../layout/header/Header.component";
import { ToastContainer, toast } from "react-toastify";
import Loader from "react-loader-spinner";
import { isEmail } from "validator";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Select from "react-validation/build/select";
import CheckButton from "react-validation/build/button";

import authService from "../../../services/auth.service";
import Constants from "../../../constants/constants";
import axios from "axios";

export default class SignUp extends Component {
  state = {
    errorMessage: "",
    email: "",
    name: "",
    password: "",
    confirmPassword: "",
    firstName: "",
    lastName: "",
    gender: "",
    userType: "investor",
    experience: "",
    agreedTerm: false,
    showPassword: false,
    showConfirmPassword: false,
    isShowFillExperience: false,
    agreedTermErrorShow: false,
    country: "",
  };
  constructor(props) {
    super(props);
    this.onChangeUserType = this.onChangeUserType.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangeConfirmPassword = this.onChangeConfirmPassword.bind(this);
    this.onChangeFirstName = this.onChangeFirstName.bind(this);
    this.onChangeLastName = this.onChangeLastName.bind(this);
    this.onChangeGender = this.onChangeGender.bind(this);
    this.onChangeExperience = this.onChangeExperience.bind(this);
    this.onCheckedTerm = this.onCheckedTerm.bind(this);
    this.handleShowPassword = this.handleShowPassword.bind(this);
    this.handleShowConfirmPassword = this.handleShowConfirmPassword.bind(this);
    this.onSignup = this.onSignup.bind(this);
    this.formRef = React.createRef();
    this.checkBtnRef = React.createRef();
  }
  onChangeUserType(event) {
    this.setState({
      userType: event.currentTarget.value,
    });
  }
  onChangeEmail(e) {
    this.setState({ email: e.target.value });
  }
  onChangePassword(e) {
    this.setState({ password: e.target.value });
  }
  onChangeConfirmPassword(e) {
    this.setState({ confirmPassword: e.target.value });
  }
  onChangeFirstName(e) {
    this.setState({ firstName: e.target.value });
  }
  onChangeLastName(e) {
    this.setState({ lastName: e.target.value });
  }
  onChangeGender(e) {
    this.setState({ gender: e.target.value });
  }
  onChangeExperience(e) {
    this.setState({ experience: e.target.value });
  }
  onCheckedTerm(e) {
    if (e.target.checked === true) {
      this.setState({ agreedTerm: true });
      this.setState({ agreedTermErrorShow: false });
    } else {
      this.setState({ agreedTerm: false });
    }
  }
  handleShowPassword() {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  }
  handleShowConfirmPassword() {
    this.setState({
      showConfirmPassword: !this.state.showConfirmPassword,
    });
  }
  async onSignup(e) {
    e.preventDefault();

    this.formRef.current.validateAll();

    if (this.state.agreedTerm !== true) {
      this.setState({
        agreedTermErrorShow: true,
      });
    }
    if (
      this.checkBtnRef.current.context._errors.length === 0 &&
      this.state.agreedTerm === true
    ) {
      //call get location api
      await axios
        .get("https://ipapi.co/json")
        .then((response) => {
          if (
            response.data.country_name !== "" &&
            response.data.country_name !== undefined &&
            response.data.country_name !== null
          ) {
            let country = response.data.country_name;
            this.setState({
              country: country,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });

      let payload = {};
      if (this.state.userType === "investor") {
        payload.experience = "null";
      } else {
        payload.experience = this.state.experience;
      }
      payload.user_type = this.state.userType;
      payload.email = this.state.email;
      payload.first_name = this.state.firstName;
      payload.password1 = this.state.password;
      payload.password2 = this.state.confirmPassword;
      payload.country = this.state.country;
      payload.gender = this.state.gender;
      if (this.state.lastName !== "") {
        payload.last_name = this.state.lastName;
        payload.username =
          this.state.firstName + "_" + this.state.lastName + "123";
      } else {
        payload.usename = this.state.firstName + "123";
      }
      authService
        .signup(payload)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            localStorage.setItem("userId", response.data.id);
            toast.success(response.data.msg, {
              autoClose: 6000,
            });
            setInterval(function () {
              window.location.href = `${window.location.origin}/login`;
            }, 3000);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.error, {
            autoClose: 6000,
          });
        });
    }
  }

  render() {
    const required = (value) => {
      if (!value) {
        return <p style={{ color: "red" }}>This field is required</p>;
      }
    };

    const email = (value) => {
      if (!isEmail(value)) {
        return <p style={{ color: "red" }}>{value} is not a valid email.</p>;
      }
    };
    const confirmPassword = (value) => {
      if (value !== this.state.password) {
        return (
          <p style={{ color: "red" }}>
            `Confirm password should be same as password.`
          </p>
        );
      }
    };
    const checkPassword = (value) => {
      if (value.toString().trim().length < 8) {
        return (
          <p style={{ color: "red" }}>
            Password Should contain minimum 8 characters.
          </p>
        );
      } else {
        var regularExpression = Constants.PASSWORD_VALIDATION_REGEX;
        let result = regularExpression.test(value);
        if (result === false) {
          return (
            <p style={{ color: "red" }}>
              Password must have atleast one digit,one special character like
              $,@,%,#,-,_,^,!, one small letter and one capital letter.
            </p>
          );
        }
      }
    };
    return (
      <div>
        <header>
          <div className="custom_container">
            <div className="row">
              <div className="col-md-12">
                <Header />
              </div>
            </div>
          </div>
        </header>

        <section>
          <div className="custom_container">
            <div className="row">
              <div className="col-md-12">
                <div className="login_outer">
                  <div className="row">
                    <div className="col-md-8 offset-md-2 col-sm-12 signup_col">
                      <Form ref={this.formRef} onSubmit={this.onSignup}>
                        <div className="login_inner">
                          <div className="sign_in_title">Sign Up</div>
                          <div className="row">
                            <div className="col-md-6">
                              <label className="custom_label">
                                Email Address
                              </label>
                              <Input
                                className="custom_input"
                                type="text"
                                placeholder="Email Address"
                                value={this.state.email}
                                onChange={this.onChangeEmail}
                                validations={[required, email]}
                              />
                            </div>
                            <div className="col-md-6">
                              <label className="custom_label">
                                Create Password
                              </label>
                              <div className="password_input">
                                <Input
                                  className="custom_input"
                                  type={
                                    this.state.showPassword
                                      ? "text"
                                      : "password"
                                  }
                                  placeholder="*********"
                                  value={this.state.password}
                                  onChange={this.onChangePassword}
                                  validations={[required, checkPassword]}
                                />
                                {this.state.showPassword == false ? (
                                  <i
                                    class="fa fa-eye"
                                    onClick={this.handleShowPassword}
                                  ></i>
                                ) : (
                                  <i
                                    class="fa fa-eye-slash"
                                    onClick={this.handleShowPassword}
                                  ></i>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <label className="custom_label">
                                Confirm Password
                              </label>
                              <div className="password_input">
                                <Input
                                  className="custom_input"
                                  type={
                                    this.state.showConfirmPassword
                                      ? "text"
                                      : "password"
                                  }
                                  placeholder="*********"
                                  value={this.state.confirmPassword}
                                  onChange={this.onChangeConfirmPassword}
                                  validations={[required, confirmPassword]}
                                />
                                {this.state.showConfirmPassword == false ? (
                                  <i
                                    class="fa fa-eye"
                                    onClick={this.handleShowConfirmPassword}
                                  ></i>
                                ) : (
                                  <i
                                    class="fa fa-eye-slash"
                                    onClick={this.handleShowConfirmPassword}
                                  ></i>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="row">
                                <div className="col-md-6">
                                  <label className="custom_label">
                                    First Name
                                  </label>
                                  <Input
                                    className="custom_input"
                                    type="text"
                                    placeholder="First Name"
                                    value={this.state.firstName}
                                    onChange={this.onChangeFirstName}
                                    validations={[required]}
                                  />
                                </div>

                                <div className="col-md-6">
                                  <label className="custom_label">
                                    Last Name
                                  </label>
                                  <Input
                                    className="custom_input"
                                    type="text"
                                    placeholder="Last Name"
                                    value={this.state.lastName}
                                    onChange={this.onChangeLastName}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <label className="custom_label">I am</label>
                              <div className="radio_custom">
                                <label className="radio_container">
                                  Investor
                                  <input
                                    type="radio"
                                    checked="checked"
                                    name="radio"
                                    value="investor"
                                    onChange={this.onChangeUserType}
                                    checked={this.state.userType === "investor"}
                                  />
                                  <span className="checkmark"></span>
                                </label>
                                <label className="radio_container">
                                  Trader
                                  <input
                                    type="radio"
                                    checked="checked"
                                    name="radio"
                                    value="trader"
                                    onChange={this.onChangeUserType}
                                    checked={this.state.userType === "trader"}
                                  />
                                  <span className="checkmark"></span>
                                </label>
                                <label className="radio_container">
                                  Technical
                                  <input
                                    type="radio"
                                    checked="checked"
                                    name="radio"
                                    value="technical"
                                    onChange={this.onChangeUserType}
                                    checked={
                                      this.state.userType === "technical"
                                    }
                                  />
                                  <span className="checkmark"></span>
                                </label>
								
								 <label className="radio_container">
                                  Freelancer
                                  <input
                                    type="radio"
                                    checked="checked"
                                    name="radio"
                                    value="freelancer"
                                    onChange={this.onChangeUserType}
                                    checked={
                                      this.state.userType === "freelancer"
                                    }
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <label className="custom_label">Gender</label>
                              <Select
                                className="custom_input"
                                value={this.state.gender}
                                onChange={this.onChangeGender}
                              >
                                <option disabled value={""}>
                                  Select gender
                                </option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                                <option value="other">Other</option>
                              </Select>
                            </div>
                            {this.state.userType !== "investor" ? (
                              <div className="col-md-6">
                                <label className="custom_label">
                                  Experience
                                </label>
                                <Select
                                  className="custom_input"
                                  value={this.state.experience}
                                  validations={[required]}
                                  onChange={this.onChangeExperience}
                                >
                                  <option disabled value={""}>
                                    Years of experience
                                  </option>
                                  <option value={"0-1"}>
                                    0 month to 1 year
                                  </option>
                                  <option value={"1-5"}>1 to 5 years</option>
                                  <option value={"5-10"}>5 to 10 years</option>
                                  <option value={"10-15"}>
                                    10 to 15 years
                                  </option>
                                  <option value={"15-20"}>
                                    15 month to 20 years
                                  </option>
                                  <option value={"20-30"}>
                                    More than 20 years
                                  </option>
                                </Select>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="remember_outer">
                            <div className="remember_left">
                              <label class="remebercontainer">
                                I have read and agree to the{" "}
                                <Link to={"/terms"}>Terms of Use</Link> as well
                                as <Link to={"/privacy"}>Privacy Policy</Link>
                                <input
                                  type="checkbox"
                                  onChange={this.onCheckedTerm}
                                  checked={this.state.agreedTerm}
                                />
                                <span class="checkmark"></span>
                              </label>

                              {this.state.agreedTermErrorShow ? (
                                <p style={{ color: "red" }}>
                                  Please accept term and conditions to move
                                  forward.
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-8 offset-md-2 col-sm-12">
                              <a
                                className="signin_btn"
                                type="submit"
                                onClick={this.onSignup}
                              >
                                Sign Up
                              </a>
                              <div className="not_account">
                                Already have an account? &nbsp;
                                <a className="a_forgot" href="/login">
                                  Sign In
                                </a>
                              </div>
                              {/* <div className="or_outer">
                                <hr />
                                <span className="or_span">OR</span>
                              </div>
                              <ul className="login_ul">
                                <li><a href="#"><i class="fab fa-google-plus-g"></i></a></li>
                                <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                              </ul> */}
                            </div>
                          </div>
                        </div>
                        <CheckButton
                          style={{ display: "none" }}
                          ref={this.checkBtnRef}
                        />
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
