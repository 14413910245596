import React, { Component } from "react";
import Highcharts from "highcharts";
import Highcharts2 from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import Footer from "../layout/footer/Footer.component";
import Header from "../layout/header/Header.component";
import axios from "axios";
import moment from "moment";
import { Button, Modal, ThemeProvider } from "react-bootstrap";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
import { copyImageToClipboard } from 'copy-image-clipboard'
require("dotenv").config();

class LiveRates extends Component {
  state = {
    symbol: "",
    type: "",
    exchange: "",
    data: [],
    timespan: "",
    fromDate: moment().subtract(60, "days").format("YYYY-MM-DD"),
    toDate: moment().format("YYYY-MM-DD"),
    limit: 1000,
    chartType: "candlestick",
    timespan: "day",
    multiplier: "1",
    symbolName: "ADAUSD",
    symbolType: "crypto",
    ChartFor: "Stock",
    stockSymbolList: [],
    forexSymbolList: [],
    cryptoSymbolList: [],
    symbolList: [],
    stockPage: 1,
    forexPage: 1,
    cryptoPage: 1,
    totalStockPage: "",
    totalForexPage: "",
    totalCryptoPage: "",
    showModal: false,
    listingFor: "us-stock",
    isLoading: false,
    searchedSymobl: "",
    searchedDataAvailable: true,
    cryptoSymbolResult: [],
    forexSymbolResult: [],
    usStockSymbolResult: [],
    priviousSymbolName: "",
    priviousSymbolType: "",
    connectionCreate: false,
  };
  constructor(props) {

    alert('dfd');
    super(props);
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.onScrollUsStockListing = this.onScrollUsStockListing.bind(this);
    this.onScrollForexListing = this.onScrollForexListing.bind(this);
    this.onScrollCryptoListing = this.onScrollCryptoListing.bind(this);
    this.onSearchCryptoSymbol = this.onSearchCryptoSymbol.bind(this);
    this.onSearchForexSymbol = this.onSearchForexSymbol.bind(this);
    this.onSearchUSStockSymbol = this.onSearchUSStockSymbol.bind(this);
    this.onChangeChartType = this.onChangeChartType.bind(this);
    this.onChangeTime = this.onChangeTime.bind(this);
    this.fullmode = this.fullmode.bind(this);
  }
  fullmode = (e) => {
    Highcharts.FullScreen = function (container) {
      this.init(container.parentNode); // main div of the chart
    };
    Highcharts.FullScreen.prototype = {
      init: function (container) {
        if (container.requestFullscreen) {
          container.requestFullscreen();
        } else if (container.mozRequestFullScreen) {
          container.mozRequestFullScreen();
        } else if (container.webkitRequestFullscreen) {
          container.webkitRequestFullscreen();
        } else if (container.msRequestFullscreen) {
          container.msRequestFullscreen();
        }
      },
    };
  };
  createSocketConnection(type, symbol) {
    // if(type === 'stock'){
    //   this.usSocket=new WebSocket("wss://xs68rzvrjn.finage.ws:7003/?token=SOCKET_KEY11704rx0Bfw3FcD4BaIoj64SNs9Kiy8tUCbk3sqtx3Ej7cnq")

    // }else if(type ==='forex'){
    //   this.forexSocket= new WebSocket("wss://w29hxx2ndd.finage.ws:8001/?token=SOCKET_KEY11704rx0Bfw3FcD4BaIoj64SNs9Kiy8tUCbk3sqtx3Ej7cnq")
    // }
    // else{
    this.cryptoSocket = new WebSocket(
      "wss://mZ3Zq4NXbp.finage.ws:6002/?token=SOCKET_KEY11704rx0Bfw3FcD4BaIoj64SNs9Kiy8tUCbk3sqtx3Ej7cnq"
    );
    // }

    this.setState({
      connectionCreate: true,
    });
  }
  closeSocketConnection(type, symbol) {
    if (type === "stock") {
      this.usSocket.close();
    } else if (type === "forex") {
      this.forexSocket.close();
    } else {
      // console.log("crypto", this.cryptoSocket);
      this.cryptoSocket.close();
    }
    this.setState({
      connectionCreate: false,
    });
  }
  getArrgegateBars(
    symbolType,
    symbolName,
    multiplier,
    timestpan,
    fromDate,
    toDate
  ) {
    axios
      .get(
        `https://api.finage.co.uk/agg/${symbolType}/${symbolName}/${multiplier}/${timestpan}/${fromDate}/${toDate}/?apikey=${process.env.REACT_APP_FINAGE_API_KEY}`
      )
      .then((response) => {
        console.log("symbol response is", response);
        if (
          response.data.results !== undefined &&
          response.data.results !== null &&
          response.data.results !== ""
        ) {
          let arr = [];
          if (this.state.chartType !== "line") {
            for (let d of response.data.results) {
              let ob1 = {
                x: d.t,
                open: d.o,
                high: d.h,
                low: d.l,
                close: d.c,
              };
              arr.push(ob1);
            }
          } else {
            for (let d of response.data.results) {
              let arr1 = [d.t, d.c];
              arr.push(arr1);
            }
          }

          this.setState(
            {
              data: arr,
            },
            async () => {
              //  await this.getCurrentPrice(symbolType,symbolName,'open')
            }
          );
        } else {
          alert("Failed to update chart");
          this.setState({
            symbolName: this.state.priviousSymbolName,
            symbolType: this.state.priviousSymbolType,
          });
        }
      })
      .catch((error) => {
        // console.log("error", error);
      });
  }
  componentDidMount() {
    // let cryptoSocket= new WebSocket("wss://mZ3Zq4NXbp.finage.ws:6002/?token=SOCKET_KEY11704rx0Bfw3FcD4BaIoj64SNs9Kiy8tUCbk3sqtx3Ej7cnq")

    // let subscribe = {
    //       "action": "subscribe", "symbols": this.state.symbolName
    //     }
    //     cryptoSocket.onopen=()=>{
    //       cryptoSocket.send(JSON.stringify(subscribe))
    //   }
    //      cryptoSocket.onmessage=(e)=>{
    //         console.log('socket-data finage',e.data)
    //     }
    //    setTimeout(() => {
    //      console.log("in a set timeout")
    //     cryptoSocket.close()
    //     let cryptoSocket2= new WebSocket("wss://mZ3Zq4NXbp.finage.ws:6002/?token=SOCKET_KEY11704rx0Bfw3FcD4BaIoj64SNs9Kiy8tUCbk3sqtx3Ej7cnq")
    //     let subscribe2 = {
    //       "action": "subscribe", "symbols": "AAVEBTC"
    //     }
    //     cryptoSocket2.onopen=()=>{
    //       cryptoSocket2.send(JSON.stringify(subscribe2))
    //   }
    //   cryptoSocket2.onmessage=(e)=>{
    //     console.log('socket',e.data)
    // }
    //    }, 10000);

    this.getArrgegateBars(
      this.state.symbolType,
      this.state.symbolName,
      this.state.multiplier,
      this.state.timespan,
      this.state.fromDate,
      this.state.toDate
    );
    this.getForexSymbolList();
    this.getStockSymbolList();
    this.getCryptoSymbolList();
  }
  setUsStockListing() {
    this.setState({
      listingFor: "us-stock",
    });
  }
  setForexListing() {
    this.setState({
      listingFor: "forex",
    });
  }
  setCryptoListing() {
    this.setState({
      listingFor: "crypto",
    });
  }
  onChangeChartType(e) {
    this.setState(
      {
        chartType: e.target.value,
      },
      () => {
        this.getArrgegateBars(
          this.state.symbolType,
          this.state.symbolName,
          this.state.multiplier,
          this.state.timespan,
          this.state.fromDate,
          this.state.toDate
        );
      }
    );
  }
  onChangeTime(e) {
    this.setState(
      {
        multiplier: e.target[e.target.selectedIndex].getAttribute("data-const"),
        timespan: e.target[e.target.selectedIndex].getAttribute("data-val"),
        fromDate: moment().subtract(60, "days").format("YYYY-MM-DD"),
      },
      () => {
        this.getArrgegateBars(
          this.state.symbolType,
          this.state.symbolName,
          this.state.multiplier,
          this.state.timespan,
          this.state.fromDate,
          this.state.toDate
        );
      }
    );
  }
  onScrollUsStockListing(event) {
    let { scrollTop, clientHeight, scrollHeight } = event.currentTarget;

    if (Math.round(scrollHeight - scrollTop) === clientHeight) {
      if (this.state.stockPage <= this.state.totalStockPage) {
        this.setState(
          {
            stockPage: this.state.stockPage + 1,
          },
          () => {
            this.getStockSymbolList();
          }
        );
      }
    }
  }
  onScrollForexListing(event) {
    let { scrollTop, clientHeight, scrollHeight } = event.currentTarget;

    if (Math.round(scrollHeight - scrollTop) === clientHeight) {
      if (this.state.forexPage <= this.state.totalForexPage) {
        this.setState(
          {
            forexPage: this.state.forexPage + 1,
          },
          () => {
            this.getForexSymbolList();
          }
        );
      }
    }
  }

  onScrollCryptoListing(event) {
    let { scrollTop, clientHeight, scrollHeight } = event.currentTarget;

    if (Math.round(scrollHeight - scrollTop) === clientHeight) {
      if (this.state.cryptoPage <= this.state.totalCryptoPage) {
        this.setState(
          {
            cryptoPage: this.state.cryptoPage + 1,
          },
          () => {
            this.getCryptoSymbolList();
          }
        );
      }
    }
  }

  getForexSymbolList() {
    this.setState({
      isLoading: true,
    });
    axios
      .get(
        `https://api.finage.co.uk/symbol-list/forex?page=${this.state.forexPage}&apikey=${process.env.REACT_APP_FINAGE_API_KEY}`
      )
      .then((response) => {
        {
          this.setState({
            forexPage: parseInt(response.data.page),
            totalForexPage: parseInt(response.data.totalPage),
          });
        }
        this.setState({
          forexSymbolList: [
            ...this.state.forexSymbolList,
            ...response.data.symbols,
          ],
          isLoading: false,
        });
      })
      .catch((error) => {
        // console.log("error", error);
      });
  }
  getCryptoSymbolList() {
    this.setState({
      isLoading: true,
    });
    axios
      .get(
        `https://api.finage.co.uk/symbol-list/crypto?page=${this.state.cryptoPage}&apikey=${process.env.REACT_APP_FINAGE_API_KEY}`
      )
      .then((response) => {
        {
          this.setState({
            cryptoPage: parseInt(response.data.page),
            totalCryptoPage: parseInt(response.data.totalPage),
          });
          this.setState({
            cryptoSymbolList: [
              ...this.state.cryptoSymbolList,
              ...response.data.symbols,
            ],
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        // console.log("error", error);
      });
  }
  getStockSymbolList() {
    this.setState({
      isLoading: true,
    });
    axios
      .get(
        `https://api.finage.co.uk/symbol-list/us-stock?page=${this.state.stockPage}&apikey=${process.env.REACT_APP_FINAGE_API_KEY}`
      )
      .then((response) => {
        {
          this.setState({
            stockPage: parseInt(response.data.page),
            totalStockPage: parseInt(response.data.totalPage),
          });
          this.setState({
            stockSymbolList: [
              ...this.state.stockSymbolList,
              ...response.data.symbols,
            ],
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        // console.log("error", error);
      });
  }
  closeModal() {
    this.setState({
      showModal: false,
    });
  }
  openModal() {
    this.setState({
      showModal: true,
    });
  }
  onSearchCryptoSymbol(e) {
    this.setState(
      {
        searchedSymobl: e.target.value,
        searchedDataAvailable: true,
      },
      async () => {
        // console.log("search", this.state.searchedSymobl);
        if (this.state.searchedSymobl.trim() !== "") {
          await this.searchSymbol(
            "cryptocurrency",
            this.state.searchedSymobl.toLocaleLowerCase(),
            10
          );
        } else {
          this.setState({
            cryptoSymbolResult: [],
          });
        }
      }
    );
  }
  onSearchForexSymbol(e) {
    this.setState(
      {
        searchedSymobl: e.target.value,
        searchedDataAvailable: true,
      },
      () => {
        // console.log("search", this.state.searchedSymobl);
        if (this.state.searchedSymobl.trim() !== "") {
          this.searchSymbol(
            "currency",
            this.state.searchedSymobl.toLocaleLowerCase(),
            10
          );
        } else {
          this.setState({
            forexSymbolResult: [],
          });
        }
      }
    );
  }
  onSearchUSStockSymbol(e) {
    this.setState(
      {
        searchedSymobl: e.target.value,
        searchedDataAvailable: true,
      },
      async () => {
        // console.log("search", this.state.searchedSymobl);
        if (this.state.searchedSymobl.trim() !== "") {
          await this.searchStockSymbol(
            "us",
            this.state.searchedSymobl.toLocaleLowerCase(),
            10
          );
        } else {
          this.setState({
            usStockSymbolResult: [],
          });
        }
      }
    );
  }
  searchSymbol(type, symbol, limit) {
    //   this.setState({
    //     symbolList:[]
    //   },()=>{
    axios
      .get(
        `https://api.finage.co.uk/search/${type}/${symbol}?limit=${limit}&apikey=${process.env.REACT_APP_FINAGE_API_KEY}`
      )
      .then((response) => {
        console.log("res", response);
        if (response.data.results.length > 0) {
          if (type === "cryptocurrency") {
            this.setState(
              {
                cryptoSymbolResult: [],
              },
              () => {
                this.setState({
                  cryptoSymbolResult: [
                    ...this.state.cryptoSymbolResult,
                    ...response.data.results,
                  ],
                });
              }
            );
          }
          if (type === "currency") {
            this.setState(
              {
                forexSymbolResult: [],
              },
              () => {
                this.setState({
                  forexSymbolResult: [
                    ...this.state.forexSymbolResult,
                    ...response.data.results,
                  ],
                });
              }
            );
          }

          // console.log("search result", this.state.forexSymbolResult);
        } else {
          if (type === "cryptocurrency") {
            this.setState({
              cryptoSymbolResult: [],
              searchedDataAvailable: false,
            });
          }

          if (type === "currency") {
            this.setState({
              forexSymbolResult: [],
              searchedDataAvailable: false,
            });
          }
        }
      })
      .catch((error) => {
        // console.log(error);
      });
    //   })
  }
  searchStockSymbol(type, symbol, limit) {
    axios
      .get(
        `https://api.finage.co.uk/search/market/${type}/${symbol}?limit=${limit}&apikey=${process.env.REACT_APP_FINAGE_API_KEY}`
      )
      .then((response) => {
        // console.log("res", response);
        if (response.data.results.length > 0) {
          this.setState(
            {
              usStockSymbolResult: [],
            },
            () => {
              this.setState({
                usStockSymbolResult: [
                  ...this.state.usStockSymbolResult,
                  ...response.data.results,
                ],
              });
            }
          );
        } else {
          this.setState({
            usStockSymbolResult: [],
            searchedDataAvailable: false,
          });
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  getCurrentPrice(type, symbol, checkConnection) {
    // console.log("in a getCurrentPrice", type);
    // if any subscription happen first do unsubscribe
    if (type === "stock") {
      let subscribe = {
        action: "subscribe",
        symbols: symbol,
      };
      this.usSocket.onopen = () => {
        this.usSocket.send(JSON.stringify(subscribe));
      };
      this.usSocket.onmessage = (e) => {
        // console.log("socket", e.data);
      };
    } else if (type === "forex") {
      let subscribe = {
        action: "subscribe",
        symbols: symbol,
      };

      this.forexSocket.onopen = () => {
        this.forexSocket.send(JSON.stringify(subscribe));
      };
      this.forexSocket.onmessage = (e) => {
        // console.log("socket", e.data);
      };
    } else {
      //  if(this.state.connectionCreate === true){

      //   this.closeSocketConnection(this.state.priviousSymbolType,this.state.priviousSymbolName)
      //   this.createSocketConnection(type,symbol)
      // }else{

      //   this.createSocketConnection(type,symbol)

      //  }
      let cryptoConnection = new WebSocket(
        "wss://mZ3Zq4NXbp.finage.ws:6002/?token=SOCKET_KEY11704rx0Bfw3FcD4BaIoj64SNs9Kiy8tUCbk3sqtx3Ej7cnq"
      );
      if (this.state.connectionCreate === false) {
        this.setState({
          connectionCreate: true,
        });
      } else {
        cryptoConnection.onmessage = (e) => {
          let subscribe2 = {
            action: "unsubscribe",
            symbols: this.state.priviousSymbolName,
          };
          cryptoConnection.send(JSON.stringify(subscribe));
          // cryptoConnection.close()
        };
      }
      let subscribe = {
        action: "subscribe",
        symbols: symbol,
      };
      cryptoConnection.onopen = () => {
        cryptoConnection.send(JSON.stringify(subscribe));
      };

      cryptoConnection.onmessage = (e) => {
        // console.log("socket", e.data);
      };
    }
  }
  changeSymbol(symbolType, symbolName, multiplier, timespan, fromDate, toDate) {
    this.setState(
      {
        priviousSymbolName: this.state.symbolName,
        priviousSymbolType: this.state.symbolType,
        symbolType: symbolType,
        symbolName: symbolName,
        multiplier: multiplier,
        timespan: timespan,
        fromDate: fromDate,
        toDate: toDate,
        showModal: false,
      },
      async () => {
        await this.getArrgegateBars(
          this.state.symbolType,
          this.state.symbolName,
          this.state.multiplier,
          this.state.timespan,
          this.state.fromDate,
          this.state.toDate
        );
      }
    );
  }


  // async function copyImg(src) {
  //  const img = await fetch(src);
  //  const imgBlob = await img.blob();
  //  try {
  //     navigator.clipboard.write([
  //       new ClipboardItem({
  //           'image/png': imgBlob, // change image type accordingly
  //       })
  //     ]);
  //   } catch (error) {
  //       console.error(error);
  //   }
  // }


//image realted parts 

  downloadPicture() {
    var htmlToImage = require('html-to-image');
    htmlToImage.toJpeg(document.getElementById('container'), { quality: 0.95 })
    .then(function (dataUrl) {

    var img = '<img src="'+dataUrl+'">';

    console.log('hkjhj'+dataUrl);
    copyImageToClipboard(
  dataUrl,
)
  .then(() => {
    console.log('Image Copied')
  })
  .catch((e) => {
    console.log('Error: ', e.message)
  })

  });
  }


  

  //end image save /copy/ exec/  related 

  render() {
    const { counter, reset } = this.props;
    console.log(this.state.data);
    const options = {
      title: {
        text: this.state.symbolType + " : " + this.state.symbolName,
      },

      rangeSelector: {
        selected: 1,
      },
      series: [
        {
          data: this.state.data,
          type: this.state.chartType,
        },
      ],
    };

    return (
      <div>
        <header>
          <div className="custom_container">
            <div className="row">
              <div className="col-md-12">
                <Header />
              </div>
            </div>
          </div>
        </header>
        <div className="col-md-12">
          <button className="btn btn-success" onClick={this.openModal}>
            {this.state.symbolName}
          </button>

          <button id="dfdf" onClick={this.downloadPicture}>
            Click picture
          </button>

          <button id="btn" onClick={this.fullmode}>
            Show full screen
          </button>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="col-md-6">
              <label className="custom_label">Charts</label>
              <select
                className="custom_input"
                value={this.state.chartType}
                onChange={this.onChangeChartType}
              >
                <option disabled value={""}>
                  Select Chart
                </option>
                <option value={"candlestick"}>Candlestick</option>
                <option value={"ohlc"}>Bar</option>
                <option value={"line"}>Line</option>
              </select>
            </div>
            <div className="col-md-6">
              <label className="custom_label">Time</label>
              <select
                className="custom_input"
                value={this.state.multiplier + " " + this.state.timespan}
                onChange={this.onChangeTime}
              >
                <option disabled value={""}>
                  Select Timespan
                </option>
                <option data-const="1" data-val="minute" value={"1 minute"}>
                  1 Minute
                </option>
                <option data-const="2" data-val="minute" value={"2 minute"}>
                  2 Minutes
                </option>
                <option data-const="5" data-val="minute" value={"5 minute"}>
                  5 Minutes
                </option>
                <option data-const="15" data-val="minute" value={"15 minute"}>
                  15 Minutes
                </option>
                <option data-const="30" data-val="minute" value={"30 minute"}>
                  30 Minutes
                </option>
                <option data-const="1" data-val="hour" value={"1 hour"}>
                  1 Hour
                </option>
                <option data-const="2" data-val="hour" value={"2 hour"}>
                  2 Hours
                </option>
                <option data-const="3" data-val="hour" value={"3 hour"}>
                  3 Hours
                </option>
                <option data-const="4" data-val="hour" value={"4 hour"}>
                  4 Hours
                </option>
                <option data-const="6" data-val="hour" value={"6 hour"}>
                  6 Hours
                </option>
                <option data-const="8" data-val="hour" value={"8 hour"}>
                  8 Hours
                </option>
                <option data-const="12" data-val="hour" value={"12 hour"}>
                  12 Hours
                </option>
                <option data-const="1" data-val="day" value={"1 day"}>
                  1 Day
                </option>
                <option data-const="3" data-val="day" value={"3 day"}>
                  3 Days
                </option>
                <option data-const="5" data-val="day" value={"5 day"}>
                  4 Days
                </option>
                <option data-const="1" data-val="week" value={"1 week"}>
                  1 Week
                </option>
                <option data-const="2" data-val="week" value={"2 week"}>
                  2 Weeks
                </option>
                <option data-const="1" data-val="month" value={"1 month"}>
                  1 Month
                </option>
                <option data-const="6" data-val="month" value={"6 month"}>
                  6 Months
                </option>
                <option data-const="1" data-val="year" value={"1 year"}>
                  1 Year
                </option>
              </select>
            </div>
          </div>
        </div>

        <div className="col-md-12" id="container">
          <HighchartsReact
            highcharts={Highcharts2}
            constructorType={"stockChart"}
            options={options}
          />
        </div>

        <Modal
          show={this.state.showModal}
          onHide={this.closeModal}
          backdrop="static"
          className="custom_modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Symbols</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Tabs defaultActiveKey="us-stock">
              <Tab eventKey="us-stock" title="US Stocks">
                <div className="col-md-12 search_area">
                  <input
                    className="custom_input"
                    type="text"
                    value={this.state.searchedSymobl}
                    placeholder="Search US Stock Symbol.."
                    onChange={this.onSearchUSStockSymbol}
                  />
                </div>
                <div
                  className="col-md-12 result_area"
                  style={{ height: "400px", overflow: "auto" }}
                  onScroll={this.onScrollUsStockListing}
                >
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Symbol</th>
                        <th scope="col">
                          Description{this.state.searchedDataAvailable}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.searchedSymobl.trim() !== "" &&
                      this.state.searchedDataAvailable == false ? (
                        <tr>
                          <td>No data available</td>
                          <td>No data available</td>
                        </tr>
                      ) : this.state.usStockSymbolResult.length > 0 &&
                        this.state.searchedSymobl.trim() !== "" ? (
                        this.state.usStockSymbolResult.map((value, key) => {
                          return (
                            <tr
                              key={key}
                              onClick={() => {
                                this.changeSymbol(
                                  "stock",
                                  value.symbol,
                                  this.state.multiplier,
                                  this.state.timespan,
                                  this.state.fromDate,
                                  this.state.toDate
                                );
                              }}
                            >
                              <td>{value.symbol}</td>
                              <td>{value.description}</td>
                            </tr>
                          );
                        })
                      ) : this.state.stockSymbolList.length > 0 ? (
                        this.state.stockSymbolList.map((value, key) => {
                          return (
                            <tr
                              key={key}
                              onClick={() => {
                                this.changeSymbol(
                                  "stock",
                                  value.symbol,
                                  this.state.multiplier,
                                  this.state.timespan,
                                  this.state.fromDate,
                                  this.state.toDate
                                );
                              }}
                            >
                              <td>{value.symbol}</td>
                              <td>{value.name}</td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td>No data available</td>
                          <td>No data available</td>
                        </tr>
                      )}
                    </tbody>
                    {this.state.isLoading &&
                    this.state.stockSymbolList.length > 0 ? (
                      <tbody>
                        <td></td>
                        <td>Loading....</td>
                      </tbody>
                    ) : (
                      ""
                    )}
                  </table>
                </div>
              </Tab>
              <Tab eventKey="forex" title="Forex">
                <div className="col-md-12 search_area">
                  <input
                    className="custom_input"
                    value={this.state.searchedSymobl}
                    type="text"
                    placeholder="Search Forex Symbol.."
                    onChange={this.onSearchForexSymbol}
                  />
                </div>
                <div
                  className="col-md-12 result_area"
                  style={{ height: "400px", overflow: "auto" }}
                  onScroll={this.onScrollForexListing}
                >
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Symbol</th>
                        <th scope="col">Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.searchedSymobl.trim() !== "" &&
                      this.state.searchedDataAvailable == false ? (
                        <tr>
                          <td>No data available</td>
                          <td>No data available</td>
                        </tr>
                      ) : this.state.forexSymbolResult.length > 0 &&
                        this.state.searchedSymobl.trim() !== "" ? (
                        this.state.forexSymbolResult.map((value, key) => {
                          return (
                            <tr
                              key={key}
                              onClick={() => {
                                this.changeSymbol(
                                  "forex",
                                  value.symbol,
                                  this.state.multiplier,
                                  this.state.timespan,
                                  this.state.fromDate,
                                  this.state.toDate
                                );
                              }}
                            >
                              <td>{value.symbol}</td>
                              <td>
                                {value.from} - {value.to}
                              </td>
                            </tr>
                          );
                        })
                      ) : this.state.forexSymbolList.length > 0 ? (
                        this.state.forexSymbolList.map((value, key) => {
                          return (
                            <tr
                              key={key}
                              onClick={() => {
                                this.changeSymbol(
                                  "forex",
                                  value.symbol,
                                  this.state.multiplier,
                                  this.state.timespan,
                                  this.state.fromDate,
                                  this.state.toDate
                                );
                              }}
                            >
                              <td>{value.symbol}</td>
                              <td>{value.name}</td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td>No data available</td>
                          <td>No data available</td>
                        </tr>
                      )}
                    </tbody>
                    {this.state.isLoading &&
                    this.state.stockSymbolList.length > 0 ? (
                      <tbody>
                        <td></td>
                        <td>Loading....</td>
                      </tbody>
                    ) : (
                      ""
                    )}
                  </table>
                </div>
              </Tab>
              <Tab eventKey="crypto" title="Crypto">
                <div className="col-md-12 search_area">
                  <input
                    className="custom_input"
                    value={this.state.searchedSymobl}
                    type="text"
                    placeholder="Search Crypto Symbol.."
                    onChange={this.onSearchCryptoSymbol}
                  />
                </div>
                <div
                  className="col-md-12 result_area"
                  style={{ height: "600px", overflow: "auto" }}
                  onScroll={this.onScrollCryptoListing}
                >
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Symbol</th>
                        <th scope="col">Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.searchedSymobl.trim() !== "" &&
                      this.state.searchedDataAvailable == false ? (
                        <tr>
                          <td>No data available</td>
                          <td>No data available</td>
                        </tr>
                      ) : this.state.cryptoSymbolResult.length > 0 &&
                        this.state.searchedSymobl.trim() !== "" ? (
                        this.state.cryptoSymbolResult.map((value, key) => {
                          return (
                            <tr
                              key={key}
                              onClick={() => {
                                this.changeSymbol(
                                  "crypto",
                                  value.symbol,
                                  this.state.multiplier,
                                  this.state.timespan,
                                  this.state.fromDate,
                                  this.state.toDate
                                );
                              }}
                            >
                              <td>{value.symbol}</td>
                              <td>{value.name}</td>
                            </tr>
                          );
                        })
                      ) : this.state.cryptoSymbolList.length > 0 ? (
                        this.state.cryptoSymbolList.map((value, key) => {
                          return (
                            <tr
                              key={key}
                              onClick={() => {
                                this.changeSymbol(
                                  "crypto",
                                  value.symbol,
                                  this.state.multiplier,
                                  this.state.timespan,
                                  this.state.fromDate,
                                  this.state.toDate
                                );
                              }}
                            >
                              <td>{value.symbol}</td>
                              <td>{value.name}</td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td>No data available</td>
                          <td>No data available</td>
                        </tr>
                      )}
                    </tbody>
                    {this.state.isLoading ? (
                      <tbody>
                        <td></td>
                        <td>Loading....</td>
                      </tbody>
                    ) : (
                      ""
                    )}
                  </table>
                </div>
              </Tab>
            </Tabs>
          </Modal.Body>
        </Modal>

        <footer>
          <Footer />
        </footer>
      </div>
    );
  }
}

export default LiveRates;
