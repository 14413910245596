import { CONSTANT } from "../constants";
const initialState = {
  symbolList: [],
  error: [],
  stockList: [],
  userIp: [],
  searchsymb: [],
  favList: [],
  compStock: [],
  alert_list: [],
};
export default function cardItems(state = initialState, action) {
  switch (action.type) {
    case CONSTANT.GET_SYMBOL_LIST:
      return {
        ...state.symbolList,
        symbolList: action.data,
      };
    case CONSTANT.GET_SEARCH_SYMBOL:
      return {
        ...state,
        searchsymb: action.data,
      };
    case CONSTANT.GET_ALERT_LIST:
      return {
        ...state,
        alert_list: action.data,
        error: action.error,
      };
    case CONSTANT.GET_COMP_STOCK_LIST:
      return {
        ...state,
        compStock: action.data,
      };
    case CONSTANT.GET_USER_IP:
      return {
        ...state,
        userIp: action.data,
      };
    case CONSTANT.GET_FAV_LIST:
      return {
        ...state,
        favList: action.data,
      };
    case CONSTANT.GET_STOCK_LIST:
      return {
        ...state,
        stockList: action.data,
        error: action.error,
      };
    default:
      return state;
  }
}
