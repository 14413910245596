	import React, { Component } from "react";
	import Footer from "../layout/footer/Footer.component";
	import Header from "../layout/header/Header.component";
	import './Expertadvisor.component.css'
	import Buy from '../../../assests/buy.png';


	import { Link } from "react-router-dom";
	import axios from "axios";
	import constants from "../../../constants/constants";

	let imgPath="../../../assests/"
	export default class ExpertAdvisorBuy extends Component {

		constructor(props)
		{
			super(props);
			var s = window.location.href;
        var split = s.split("expert-advisor-buy/");
        var eaid = split[1];
		this.state={
			expert:{}
		};
		this.getExperts(eaid);
			
		}
		getExperts(eaid)
	{
	  let accessToekn = localStorage.getItem('accessToken');
	  var authData = 'Token ' + accessToekn;
	  axios.get(constants.API_URL+"/advisor/"+eaid+"/",{
		headers:{
		  "Content-Type": "application/json",
		  Authorization: authData
		}
	  })
	  .then((response)=>{
		if(response.status==200 )
		{
		  this.setState({
			expert:response.data
		  });
		}
  
	  }).catch((error)=>{
		console.log("error", error);
	  });
	}
		render() {

			return ( 
				<div>
			<header>
				<div className="custom_container">
				<div className="row">
					<div className="col-md-12">
					<Header />
					</div>
				</div>
				</div>
			</header>
	<section className="expert_ad">
		<div className="custom_container">
			<div className="row">
				<div className="col-12 text-center">
					<div className="img_outer">
					<img src={Buy} style={{width:"200px"}} />
					<h4>{this.state.expert.title}</h4>
					<h2> {this.state.expert.category=="1"?'Indicator':"Expert Adviser"} purchased successfully</h2>
					<a href="/expert-advisor-orders">Click here to see all your orders</a>
					</div>
				</div>
				
				</div>
			</div>

	</section>

			
		
			<footer>
				<Footer />       
			</footer>
				</div>
			)
		}
	}
