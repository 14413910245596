import React, { Component } from "react";
import Footer from "../layout/footer/Footer.component";
import Header from "../layout/header/Header.component";
import "./Premium.component.css";
import heroImg from "../../../assests/hero-bg.jpg";
import closeUp from "../../../assests/close_up.jpg";
import R1 from "../../../assests/r1.jpg";
import R2 from "../../../assests/r2.jpg";
import { Link } from "react-router-dom";

let imgPath = "../../../assests/";
export default class ContestPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      trial: localStorage.getItem("userTrial"),
      trialday: localStorage.getItem("remainDay"),
    };
  }
  componentDidMount() {}
  render() {
    const { trial } = this.state;
    return (
      <div>
        <header>
          <div className="custom_container">
            <div className="row">
              <div className="col-md-12">
                <Header />
              </div>
            </div>
          </div>
        </header>

        <section className="banner_outer news_banner">
          <div className="overlay"></div>
          <div className="custom_container">
            <div className="row">
              <div className="col-md-8 offset-md-2 col-sm-12 first_div text-center">
                <div class="banner_content">
                  <h2>Premium</h2>
                  <hr class="banner_hr news_hr" />
                  <h3>
                    <ul className="news_ul">
                      <li>
                        <Link to={"/home"}>Home</Link>
                      </li>
                      <li>
                        <i class="fal fa-angle-right"></i>
                      </li>
                      <li>
                        <Link to={"/premium"} className="active">
                          Premium
                        </Link>
                      </li>
                    </ul>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="custom_container">
            <div className="row">
              <div className="col-md-8 offset-md-2 col-sm-12 second_div text-center">
                {/* <h3 className="title_main">
                        Premium Trader <b>Package</b>
                        </h3> */}
                <p className="p_text newS_p">
                  Unlock your potential and discover new trading opportunities
                  with the FinMax Financial charting suite. Access revolutionary
                  technical analysis capabilities and take your charting
                  experience to the next level.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="most_polupar">
          <div className="custom_container">
            {/* <div className="row" >
                            <div className="col-md-8 offset-md-2 col-sm-12 second_div text-center">
                                <h3 className="title_main package_title">
                                What’s in the <b>Package?</b>
                                </h3>
                            </div>
                        </div> */}
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="img_part">
                  <img src={heroImg} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="description_part">
                  <div className="title_part">
                    <h3 className="title_main">
                      The ultimate charting <b>application</b>
                    </h3>
                  </div>
                  <div className="detail_part">
                    <p className="p_text">
                      Most brokers and exchanges offer the same outdated trading
                      platforms and fail to keep up with the needs of traders.
                      That’s why Chief Financials has created a modern charting
                      application for any trader to analyse the markets.
                    </p>

                    <p className="p_text">
                      Once you have identified your next trading opportunity
                      using a suite of advanced analysis tools, you just open
                      your brokers’ trading platform and enter the order.
                    </p>
                    <p className="p_text">
                      <b>
                        You should try the FinMax Financial charting application
                        if you are:
                      </b>
                    </p>
                    <ul class="info_span">
                      <li>
                        <span class="dot_span"></span>
                        <span class="privcay_content_span">
                          A forex trader and tired of MetaTrader 4 and
                          MetaTrader 5
                        </span>
                      </li>
                      <li>
                        <span class="dot_span"></span>
                        <span class="privcay_content_span">
                          A cryptocurrency trader and feel your exchange
                          platform is too basic
                        </span>
                      </li>
                      <li>
                        <span class="dot_span"></span>
                        <span class="privcay_content_span">
                          A stock trader and don’t want to pay for data from
                          every stock exchange
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="row align-items-center mt-5">
              <div className="col-md-6">
                <div className="description_part left_desc">
                  <div className="title_part">
                    <h3 className="title_main">
                      FinMax Financial <b>Pricing Plans</b>
                    </h3>
                  </div>
                  <div className="detail_part">
                    <p className="p_text">
                      If you’re considering using the FinMax Financial charting
                      suite, you won’t be disappointed. Whether you choose the
                      Silver, Gold or Platinum plan, you’ll have access to the
                      same premium charting application, news and insights. Our
                      plans are designed for traders with different needs, and
                      the higher plans are designed for the most demanding and
                      active traders.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="img_part">
                  <img src={closeUp} />
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="img_part">
                  <img src={heroImg} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="description_part">
                  <div className="title_part">
                    <h3 className="title_main">
                      Every premium member <b>gets</b>
                    </h3>
                  </div>
                  <div className="detail_part">
                    <ul class="info_span">
                      <li>
                        <span class="dot_span"></span>
                        <span class="privcay_content_span">
                          Access to an advanced charting application with
                          exclusive features
                        </span>
                      </li>
                      <li>
                        <span class="dot_span"></span>
                        <span class="privcay_content_span">
                          Analyse thousands of markets from across the globe,
                          including forex, stocks, cryptocurrencies and more
                        </span>
                      </li>
                      <li>
                        <span class="dot_span"></span>
                        <span class="privcay_content_span">
                          Price alert notifications via email, SMS and from your
                          desktop
                        </span>
                      </li>
                      <li>
                        <span class="dot_span"></span>
                        <span class="privcay_content_span">
                          Signal and price alert notifications early with our
                          early warning system which notifies you
                        </span>
                      </li>
                      <li>
                        <span class="dot_span"></span>
                        <span class="privcay_content_span">
                          Real-time market news and economic events
                        </span>
                      </li>
                      <li>
                        <span class="dot_span"></span>
                        <span class="privcay_content_span">
                          Discover potential trends and key levels with
                          automatic Fibo and Gaan indicators
                        </span>
                      </li>
                      <li>
                        <span class="dot_span"></span>
                        <span class="privcay_content_span">
                          Popular chart drawing tools such as trend lines, rays,
                          text and various annotations
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="review_sec">
          <div className="custom_container">
            <div className="row">
              <div className="col-md-8 offset-md-2 col-sm-12">
                <h3 className="title_main">
                  Premium subscription <b>plans</b>
                </h3>
                <p className="p_text">
                  Choose the plan that meets your needs and start analysing the
                  markets like never before.
                </p>
                <p className="p_plan">Pay annually and save 20%.</p>
                <p className="p_plan">Pay Six Months and save 12%.</p>
                <p className="p_plan">Pay Three Months and save 10%.</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="plan_div">
                  <div className="plan_div_inner">
                    <div className="plan_title">Silver</div>
                    <div className="plan_price">
                      $12.50 <sub>\ month</sub>
                    </div>
                  </div>
                  <div className="plan_ul">
                    <ul class="info_span">
                      <li>
                        <span class="dot_span"></span>
                        <span class="privcay_content_span">
                          Open 3 charts in one window
                        </span>
                      </li>
                      <li>
                        <span class="dot_span"></span>
                        <span class="privcay_content_span">
                          Apply up to 5 indicators per
                        </span>
                      </li>
                      <li>
                        <span class="dot_span"></span>
                        <span class="privcay_content_span">Chart</span>
                      </li>
                      <li>
                        <span class="dot_span"></span>
                        <span class="privcay_content_span">
                          Fixed time frame menu
                        </span>
                      </li>
                      <li>
                        <span class="dot_span"></span>
                        <span class="privcay_content_span">
                          Limited historical data
                        </span>
                      </li>
                      <li>
                        <span class="dot_span"></span>
                        <span class="privcay_content_span">
                          Cannot save chart templates
                        </span>
                      </li>
                      <li>
                        <span class="dot_span"></span>
                        <span class="privcay_content_span">
                          Up to 50 notifications per month
                        </span>
                      </li>
                    </ul>
                    {trial == 0 && trial ? (
                      <a className="plan_btn" href="#">
                        Start Free Trial
                      </a>
                    ) : (
                      ""
                    )}
                    {trial && trial == 1 ? (
                      <a className="plan_btn" href="#">
                        Buy Silver Plan
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="plan_div center_div">
                  <div className="plan_div_inner">
                    <div className="plan_title">Gold</div>
                    <div className="plan_price">
                      $26.80 <sub>\ month</sub>
                    </div>
                  </div>
                  <div className="plan_ul">
                    <ul class="info_span">
                      <li>
                        <span class="dot_span"></span>
                        <span class="privcay_content_span">
                          Open 5 charts in one window
                        </span>
                      </li>
                      <li>
                        <span class="dot_span"></span>
                        <span class="privcay_content_span">
                          Apply up to 10 indicators per chart
                        </span>
                      </li>
                      <li>
                        <span class="dot_span"></span>
                        <span class="privcay_content_span">
                          Custom timeframe intervals above one-minute
                        </span>
                      </li>
                      <li>
                        <span class="dot_span"></span>
                        <span class="privcay_content_span">
                          Limited historical data
                        </span>
                      </li>
                      <li>
                        <span class="dot_span"></span>
                        <span class="privcay_content_span">
                          Save up to 20 chart templates
                        </span>
                      </li>
                      <li>
                        <span class="dot_span"></span>
                        <span class="privcay_content_span">
                          Up to 200 notifications per month
                        </span>
                      </li>
                      <li>
                        <span class="dot_span"></span>
                        <span class="privcay_content_span">
                          Premium support
                        </span>
                      </li>
                    </ul>
                    {trial == 0 && trial ? (
                      <a className="plan_btn" href="#">
                        Start Free Trial
                      </a>
                    ) : (
                      ""
                    )}
                    {trial && trial == 1 ? (
                      <a className="plan_btn" href="#">
                        Buy Gold Plan
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="plan_div">
                  <div className="plan_div_inner">
                    <div className="plan_title">Platinum</div>
                    <div className="plan_price">
                      $55.85 <sub>\ month</sub>
                    </div>
                  </div>
                  <div className="plan_ul">
                    <ul class="info_span">
                      <li>
                        <span class="dot_span"></span>
                        <span class="privcay_content_span">
                          Open unlimited charts in one window
                        </span>
                      </li>
                      <li>
                        <span class="dot_span"></span>
                        <span class="privcay_content_span">
                          Apply up to 30 indicators per chart
                        </span>
                      </li>
                      <li>
                        <span class="dot_span"></span>
                        <span class="privcay_content_span">
                          Exclusive package of indicators
                        </span>
                      </li>
                      <li>
                        <span class="dot_span"></span>
                        <span class="privcay_content_span">
                          Custom timeframe intervals starting from one-second
                        </span>
                      </li>
                      <li>
                        <span class="dot_span"></span>
                        <span class="privcay_content_span">
                          Up to 20 years of historical data
                        </span>
                      </li>
                      <li>
                        <span class="dot_span"></span>
                        <span class="privcay_content_span">
                          Save unlimited chart templates
                        </span>
                      </li>
                      <li>
                        <span class="dot_span"></span>
                        <span class="privcay_content_span">
                          Up to 500 notifications per month
                        </span>
                      </li>
                      <li>
                        <span class="dot_span"></span>
                        <span class="privcay_content_span">
                          Get highest priority support
                        </span>
                      </li>
                    </ul>
                    {trial && trial == 0 ? (
                      <a className="plan_btn" href="#">
                        Start Free Trial
                      </a>
                    ) : (
                      ""
                    )}
                    {trial && trial == 1 ? (
                      <a className="plan_btn" href="#">
                        Buy Platinum Plan
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>

            {trial && trial == 1 ? (
              <b>
                <p className="text-danger mt-2">
                  Your Free trial over. Please buy subscription plan to get more
                  features now
                </p>
              </b>
            ) : trial && trial == 0 ? (
              <b>
                {" "}
                <p className="text-success mt-2">
                  Your Free trial running. Remaning Days 14
                </p>
              </b>
            ) : (
              <b>
                <p className="text-primary mt-2">
                  Please do Login for Subscription Plan. Get 2 week free trial
                  for new registration.
                </p>
              </b>
            )}
          </div>
        </section>

        {/* <section className="technical_assistance">
             <div className="custom_container">
               <div className="row">
                   <div className="col-md-8 offset-md-2 col-sm-12">
                        <h3 className="title_main">
                        How to <b>Sign up</b>
                        </h3>
                        <p className="p_text">We’re offering a two-week free trial for our premium trader package until the end of April 2022. If you’re like many other traders and decide this service has empowered you, the monthly subscription costs just $25. Our subscribers agree it’s an investment worth making and ultimately pays for itself. </p>
                        <Link to={"/signup"}><button type="button" class="btn btn-dark signup_btn signup">Subscribe now</button></Link>
                   </div>
               </div>
             </div>
          </section>
        
          <section className="review_sec">
             <div className="custom_container">
               <div className="row">
                   <div className="col-md-8 offset-md-2 col-sm-12">
                        <h3 className="title_main">
                        What other <b>traders say</b>
                        </h3>
                        <p className="p_text">We have hundreds of active members benefiting from our premium signals, reports, news, and insights. Here is what some of them have to say.
</p>
                   </div>
               </div>
               <div className="row">
                   <div className="col-md-12">
                      <div className="review_outer">
                      <div className="swiper mySwiperrr">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="review_outer_inner">
                              <img src={R1} />
                              <div className="summary_title">Matt Corbett</div>
                              <p>I’ve been struggling with Forex for a long time. I’ve tried so many tools and they all fail eventually. I was recommended by a friend to try this and so far, it has been three months and I am up. Not a millionaire yet lol but I am doing much better than before and I am sure it is because of this package.</p>
                            </div>
                            </div>
                            <div className="col-md-4">
                            <div className="review_outer_inner">
                              <img src={R2} />
                              <div className="summary_title">Semir Samet</div>
                              <p>Sorry for English but need to tell that this product help me win at forex a lot. If you find forex hard don’t give up because this might be what will help you be a winner in forex.</p>
                            </div>
                            </div>
                            <div className="col-md-4">
                            <div className="review_outer_inner">
                              <img src={R1} />
                              <div className="summary_title">Ajeet Gulshan</div>
                              <p>Saves me so much time having all this information in one place. There are just so many finance and investing journals giving different information and it’s hard to know what you can trust. I get all my news from here and it’s a huge time saver and allows me to focus on my trading. Highly recommended if you’re serious about trading!</p>
                            </div>
                          </div>
                         </div>
                      </div>
                      </div>
                   </div>
               </div>
             </div>
          </section>

          <section className="most_polupar">
                <div className="custom_container">
                       <div className="row align-items-center">
                           <div className="col-md-6">
                           <div className="img_part">
                           <img src={heroImg} /> 
                           </div>   
                           </div>
                           <div className="col-md-6">
                              <div className="description_part">
                               <div className="title_part">
                               <h3 className="title_main">Why should I follow <b>your signals?</b></h3>
                               </div>
                               <div className="detail_part">
                               <ul class="info_span">
                                 <li><span class="dot_span"></span><span class="privcay_content_span">We’ve done hours of analysis and due diligence on every trade signal we publish so that you don’t have to.</span></li>
                                 <li><span class="dot_span"></span><span class="privcay_content_span">Reduce self-doubt and stress by feeling confident that you’re receiving trade ideas from investment experts.</span></li>
                                 <li><span class="dot_span"></span><span class="privcay_content_span">Unlike other signal services, we focus on giving you one or two high-quality trades each day. Fewer trades mean less risk.
</span></li>
                                 <li><span class="dot_span"></span><span class="privcay_content_span">83% of our signals are correct, and if followed precisely, they make on average 59 pips per trade.</span></li>
                               </ul>
                               </div>
                               </div>
                           </div>
                       </div>
                </div>
          </section>

        <section className="technical_assistance">
             <div className="custom_container">
               <div className="row">
                   <div className="col-md-8 offset-md-2 col-sm-12">
                        <h3 className="title_main">
                        Kick-start your trading strategy <br />with premium Forex signals
                        </h3>
                        <Link to={"/signup"}><button type="button" class="btn btn-dark signup_btn signup">Subscribe Today</button></Link>
                   </div>
               </div>
             </div>
          </section> */}
        <section className="most_polupar">
          <div className="custom_container">
            <div className="row">
              <div className="col-md-8 offset-md-2 col-sm-12 second_div text-center">
                <h3 className="title_main">
                  Frequently asked <b>Questions</b>
                </h3>
              </div>
            </div>

            <div className="row">
              <div className="col-md-8 offset-md-2 col-sm-12">
                <div class="custom_accordian">
                  <div class="accordion" id="accordionExample">
                    <div class="card">
                      <div class="card-header" id="headingOne">
                        <h2 class="mb-0">
                          <button
                            type="button"
                            class="btn btn-link"
                            data-toggle="collapse"
                            data-target="#collapseOne"
                          >
                            <span>
                              What markets do you provide in your application?
                            </span>
                            <i class="fa fa-angle-down"></i>
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapseOne"
                        class="collapse"
                        aria-labelledby="headingOne"
                        data-parent="#accordionExample"
                      >
                        <div class="card-body">
                          <p>
                            The application shows thousands of markets. You can
                            find the following products listed:
                          </p>
                          <ul class="info_span">
                            <li>
                              <span class="dot_span"></span>
                              <span class="privcay_content_span">
                                All US stocks from the NYSE and NASDAQ
                              </span>
                            </li>
                            <li>
                              <span class="dot_span"></span>
                              <span class="privcay_content_span">
                                All major, minor and exotic forex currency pairs
                              </span>
                            </li>
                            <li>
                              <span class="dot_span"></span>
                              <span class="privcay_content_span">
                                All cryptocurrencies
                              </span>
                            </li>
                            <li>
                              <span class="dot_span"></span>
                              <span class="privcay_content_span">
                                Stocks from China, Asia, India, and the Middle
                                East
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header" id="headingTwo">
                        <h2 class="mb-0">
                          <button
                            type="button"
                            class="btn btn-link collapsed"
                            data-toggle="collapse"
                            data-target="#collapseTwo"
                          >
                            <span>Can I trade from the application?</span>
                            <i class="fa fa-angle-down"></i>
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapseTwo"
                        class="collapse show"
                        aria-labelledby="headingTwo"
                        data-parent="#accordionExample"
                      >
                        <div class="card-body">
                          <p>Second Tab Content</p>
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header" id="headingThree">
                        <h2 class="mb-0">
                          <button
                            type="button"
                            class="btn btn-link collapsed"
                            data-toggle="collapse"
                            data-target="#collapseThree"
                          >
                            <span>Why do you offer different plans?</span>
                            <i class="fa fa-angle-down"></i>
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapseThree"
                        class="collapse"
                        aria-labelledby="headingThree"
                        data-parent="#accordionExample"
                      >
                        <div class="card-body">
                          <p>Third Tab Content</p>
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header" id="heading4">
                        <h2 class="mb-0">
                          <button
                            type="button"
                            class="btn btn-link collapsed"
                            data-toggle="collapse"
                            data-target="#collapse4"
                          >
                            <span>Do you offer free trials?</span>
                            <i class="fa fa-angle-down"></i>
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapse4"
                        class="collapse"
                        aria-labelledby="heading4"
                        data-parent="#accordionExample"
                      >
                        <div class="card-body">
                          <p>Fourth Tab Content</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-8 offset-md-2 col-sm-12 second_div text-center">
                <button type="button" class="btn btn-dark signup_btn">
                  View All
                </button>
              </div>
            </div>
          </div>
        </section>

        <footer>
          <Footer />
        </footer>
      </div>
    );
  }
}
