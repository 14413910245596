import React, { Component } from "react";
import Footer from "../layout/footer/Footer.component";
import Header from "../layout/header/Header.component";
import './Terms.component.css'
import { Link } from "react-router-dom";

let imgPath="../../../assests/"
export default class TermsPage extends Component {
    render() {
        return (
            <div>
          <header>
            <div className="custom_container">
              <div className="row">
                <div className="col-md-12">
                  <Header />
                </div>
              </div>
            </div>
          </header>

          <section className="banner_outer">
            <div className="overlay"></div>
                <div className="custom_container">
                <div className="row" >
                    <div className="col-md-8 offset-md-2 col-sm-12 first_div text-center">
                      <div class="banner_content">
                        <h2>Terms of Use</h2>
                         <hr class="banner_hr news_hr" /><h3>
                         <ul className="news_ul">
                           <li><Link to={"/home"} >Home</Link></li>
                           <li><i class="fal fa-angle-right"></i></li>
                           <li><Link to={"/news"} className="active">Terms of Use</Link></li>
                         </ul>
                        </h3>
                      </div>
                    </div>
                </div>
            </div>
          </section>
          <section>
            <div className="custom_container">
                <div className="row" >
                    <div className="col-md-8 offset-md-2 col-sm-12 second_div text-center">
                        <p className="p_text newS_p">Finmaxfinancial.com is owned and operated by Finmax Financial LTD. Kindly read the following Terms before you start using our services. By using our services and any products on our site, you hereby agree to all terms and conditions specified below.</p>
                    </div>
                </div>
            </div>
          </section>
          <section className="most_polupar">
            <div className="custom_container">
                    <div className="row">
                    <div className="col-md-12 second_div text-left">
                        <h3 className="title_main user_info">
                        Third-party <b>Products and Services</b>
                        </h3>
                        <p className="p_text">This website may direct or refer you to other websites with products and services NOT provided by us. FinMax does not take responsibility for those third-party products, services, privacy, or information sharing. By agreeing to our terms, you grant us permission to transmit your necessary information to other networks, which may be required to use certain products and services.</p> 

                        <p className="p_text">Furthermore, your usage of third-party products and services is your own responsibility. FinMax will not be held responsible for any liabilities, damages, or expenses after your purchase from a third party referred through our website.</p>
                    </div>
                </div>
            </div>
          </section>

          <section className="user_information_outer">
            <div className="custom_container">
                    <div className="row">
                    <div className="col-md-12 second_div text-left">
                        <h3 className="title_main user_info">
                        Website <b>Data</b>
                        </h3>
                        <p className="p_text"><b>Regarding the data displayed on this website;</b></p>
                        <ul className="info_span">
                          <li><span className="dot_span"></span><span className="privcay_content_span">We do not produce this data; instead, we just display it. Thus, we're not responsible or liable if any such data or information is inaccurate or incomplete.</span></li>
                          <li><span className="dot_span"></span><span className="privcay_content_span">Any actions you carry out based on data or information displayed on our platform are wholly your own responsibility. We, as service providers, cannot be held responsible for it.</span></li>
                          <li><span className="dot_span"></span><span className="privcay_content_span">You do not have the right to retransmit, redistribute, publish, disclose or display any information or data displayed on our website in part or in entirety, as this is proprietary data unless it is a legal requirement in some situation.</span></li>
                        </ul>
                    </div>
                </div>
            </div>
          </section>


          <section className="most_polupar user_information_outer">
            <div className="custom_container">
                    <div className="row">
                    <div className="col-md-12 second_div text-left">
                        <h3 className="title_main user_info">
                        Confidentiality
                        </h3>
                        <p className="p_text">Confidentiality on our portal is assured by adhering to standard practices as outlined by Data Protection legislation. </p>
                        <ul className="info_span">
                          <li><span className="dot_span"></span><span className="privcay_content_span">By agreeing to our terms, you take full responsibility for any instruction or communication transmitted by you or on your behalf to this website. 
</span></li>
                          <li><span className="dot_span"></span><span className="privcay_content_span">You allow us to carry out any instruction given to us that we believe to have been authorized by you.</span></li>
                        </ul>
                    </div>
                </div>
            </div>
          </section>

          <section className="user_information_outer">
            <div className="custom_container">
                    <div className="row">
                    <div className="col-md-12 second_div text-left">
                        <h3 className="title_main user_info">
                        Modification <b>to Terms</b>
                        </h3>
                        <p className="p_text">While we strive our best to make sure that every bit of information displayed on this website is correct and accurate, every piece of the information featured is subject to change, sometimes even without any prior notice.</p>

                        <p className="p_text">Furthermore, the terms and conditions outlined here may be altered, changed, modified, or excluded in part or in entirety at any time by FinMax Financial. You agree to continue to be bound by these terms and conditions as modified. To keep abreast of any changes made to terms and conditions, we advise you to keep checking this page periodically and read the entire policy before using the website. </p>
                    </div>
                </div>
            </div>
          </section>

          <section className="most_polupar user_information_outer">
            <div className="custom_container">
                    <div className="row">
                    <div className="col-md-12 second_div text-left">
                        <h3 className="title_main user_info">
                        Under-age <b>Users</b> 
                        </h3>
                        <p className="p_text">We do not allow access to this website to under-age users. The use of this portal is prohibited to minors under 18 years of age.</p>

                        <p className="p_text">It is the responsibility of users in charge of minors to check if under-age users are active on the portal.</p>
                    </div>
                </div>
            </div>
          </section>

          <section className="user_information_outer">
            <div className="custom_container">
                    <div className="row">
                    <div className="col-md-12 second_div text-left">
                        <h3 className="title_main user_info">
                        Change of <b>Email Address</b>
                        </h3>
                        <p className="p_text">Notices regarding important information and use of our website's services will be sent to the users' email addresses. It is users' responsibility to notify the company of any changes to their email address on time since notices sent by the website shall be deemed delivered, received and read, at the time of sending.</p>
                    </div>
                </div>
            </div>
          </section>

          <section className="most_polupar user_information_outer">
            <div className="custom_container">
                    <div className="row">
                    <div className="col-md-12 second_div text-left">
                        <h3 className="title_main user_info">
                        Termination <b>of Service</b> 
                        </h3>
                        <p className="p_text">FinMax Financial reserves the right to terminate your account if we consider in our sole discretion that you have in any way been involved in breaching these terms and conditions or abuse any of our products or services. </p>
                    </div>
                </div>
            </div>
          </section>

          <section className="user_information_outer">
            <div className="custom_container">
                    <div className="row">
                    <div className="col-md-12 second_div text-left">
                        <h3 className="title_main user_info">
                        Intellectual <b>Property</b>
                        </h3>
                        <p className="p_text">All copyright, database rights, trademarks, and other intellectual property rights in this website's content belong to a third party or us, including our licensors. </p>

                        <p className="p_text">Whether marked or not, the content, terms, or data is under our intellectual property rights. It may include names, terms, and/or data without any registered symbol or trademark.</p>

                        <p className="p_text">The third parties' content or intellectual property used by us does not mean that these parties or the sponsors on our website endorse or are in any other way affiliated with us or our business. Nor do they urge or advise users to trade on our platform. </p>

                        <p className="p_text">The information on this website cannot be used, reproduced, or copied without our written consent. </p>
                    </div>
                </div>
            </div>
          </section>

      
          <footer>
            <Footer />       
          </footer>
            </div>
        )
    }
}
