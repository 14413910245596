import React, { Component } from "react";
import Footer from "../layout/footer/Footer.component";
import Header from "../layout/header/Header.component";
import './Broker.component.css'
import Logo from '../../../assests/FinMax-Financial.png';
import { Link } from "react-router-dom";
import axios from "axios";
import { CONSTANT } from "../../../services/constants.js";

let imgPath = "../../../assests/"
export default class BrokerPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stockData: [],
      stockLoad: false,
      stockError: "",
      allData: [],
      reviews: [],
      rating: "0"
    };
  }
  componentDidMount() {
    axios.get(CONSTANT.API_BACKEND + '/broker/')
      .then(res => {
        if (res.data) {
          console.log('-----', res.data.data);
          const data = res.data;
          this.setState({ allData: data.data });
        }
      })
  }
  getReviews(id) {


    axios.get(CONSTANT.API_BACKEND + '/review/?broker=' + id)
      .then(res => {
        if (res.data) {

          const data = res.data;
          this.setState({ reviews: data.data, brokerId: id });
        }
      })

  }

  handleChange(e) {
    console.log('this.state')
    this.setState({ rating: e })
  }
  submitReview() {
    const data = {
      "id": 1,
      "broker": 3,
      "content": "Test content",
      "start_rating": 4.0
    }
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Token " + localStorage.getItem('accessToken'),
    };
    axios.post(CONSTANT.API_BACKEND + '/review/',
      data,
      {
        headers: headers,
      }
    )
      .then(res => {

        alert('review submitted')
        if (res.data) {

          // const data = res.data;
          //this.setState({ reviews:data });
        }
      }).catch(error => {
        if (error.response.data.error === undefined || error.response.data.error === 'undefined') {
          alert('Please login first to post the review.')
        } else {
          alert(error.response.data.error)
        }
        // alert(error.response.data.error)
        console.log(error.response.data.error)
      })

  }
  render() {
    return (this.state.allData.length > 0) ?
      <div>
        <header>
          <div className="custom_container">
            <div className="row">
              <div className="col-md-12">
                <Header />
              </div>
            </div>
          </div>
        </header>

        <section className="banner_outer news_banner">
          <div className="overlay"></div>
          <div className="custom_container">
            <div className="row" >
              <div className="col-md-8 offset-md-2 col-sm-12 first_div text-center">
                <div class="banner_content">
                  <h2>Brokers</h2>
                  <hr class="banner_hr news_hr" /><h3>
                    <ul className="news_ul">
                      <li><Link to={"/home"} >Home</Link></li>
                      <li><i class="fa fa-angle-right"></i></li>
                      <li><Link to={"/brokers"} className="active">Brokers</Link></li>
                    </ul>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="custom_container">
            <div className="row" >
              <div className="col-md-8 offset-md-2 col-sm-12 second_div text-center">
                <p className="p_text newS_p">Find the right Forex and CFD broker for you. Our in-depth broker analysis and reviews help traders anywhere in the world find a suitable broker to trade or invest in the markets. We cover brokers operating under a variety of
                  regulatory jurisdictions, offering various asset classes and providing different platforms. </p>
              </div>
            </div>
          </div>
        </section>
        <section className="most_polupar">
          <div className="custom_container">
            <div className="row">
              <div className="col-md-12">
                <div className="broker_upper">
                  <h3 class="title_main"><b>Brokers List</b></h3>
                  {/* <button type="button" class="btn btn-dark signup_btn" data-toggle="modal" data-target="#brokerModal">Add Broker</button> */}
                </div>
                <div className="broker_div_custom">
                  <div className="table-responsive">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">Broker</th>
                          <th scope="col">Regulations</th>
                          <th scope="col">Minimum deposit </th>
                          <th scope="col">Reviews</th>
                          <th scope="col">&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>

                        {this.state.allData.map((row, i) => (

                          <tr>
                            <td><img className="logo_img_table" src={Logo} /></td>
                            <td>{row.regulations}</td>
                            <td>{row.minimum_deposit}</td>
                            <td>
                              <div className="rating_div">
                                <div id="full-stars-example">
                                  <div class="rating-group">
                                    <label aria-label="1 star" class="rating__label" for="rating-1"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                                    <input class="rating__input" name="rating" id="rating-1" value="1" type="radio" />
                                    <label aria-label="2 stars" class="rating__label" for="rating-2"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                                    <input class="rating__input" name="rating" id="rating-2" value="2" type="radio" />
                                    <label aria-label="3 stars" class="rating__label" for="rating-3"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                                    <input class="rating__input" name="rating" id="rating-3" value="3" type="radio" checked />
                                    <label aria-label="4 stars" class="rating__label" for="rating-4"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                                    <input class="rating__input" name="rating" id="rating-4" value="4" type="radio" />
                                    <label aria-label="5 stars" class="rating__label" for="rating-5"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                                    <input class="rating__input" name="rating" id="rating-5" value="5" type="radio" />
                                    <span>{row.total_rating}</span>
                                  </div>
                                </div>
                                <span>({row.no_of_user_rated})</span>
                              </div>
                            </td>
                            <td><button type="button" onClick={() => this.getReviews(row.id)} class="btn btn-dark signup_btn" data-toggle="modal" data-target="#brokerModal">Register</button></td>
                          </tr>
                        ))}


                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>



        <div className="modal custom_modal fade" id="brokerModal" tabindex="-1" aria-labelledby="brokerModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="outlookModalLabel">Register</h5>
                <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <h5 className="modal-title review_t">Reviews</h5>


                <div className="add_review_div_main">
                  {this.state.reviews.map((row, i) => (
                    <div className="add_r_inner">
                      <div className="add_rwvie_upper">
                        <div className="add_review_left">
                          <div className="r_name">John Doe</div>
                          <div id="full-stars-example">
                            <div class="rating-group">
                              <label aria-label="1 star" class="rating__label" for="rating-1" onChange={() => this.handleChange(1)}><i onClick={() => this.handleChange(1)} class="rating__icon rating__icon--star fa fa-star"></i></label>
                              <input class="rating__input" name="rating" id="rating-1" value="1" type="radio" />
                              <label aria-label="2 stars" class="rating__label" for="rating-2" onChange={() => this.handleChange(2)}><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                              <input class="rating__input" name="rating" id="rating-2" value="2" type="radio" />
                              <label aria-label="3 stars" class="rating__label" for="rating-3" onChange={() => this.handleChange(3)}><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                              <input class="rating__input" name="rating" id="rating-3" value="3" type="radio" checked />
                              <label aria-label="4 stars" class="rating__label" for="rating-4" onChange={() => this.handleChange(4)}><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                              <input class="rating__input" name="rating" id="rating-4" value="4" type="radio" />
                              <label aria-label="5 stars" class="rating__label" for="rating-5" onChange={() => this.handleChange(5)}><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                              <input class="rating__input" name="rating" id="rating-5" value="5" type="radio" />
                              <span>{row.start_rating}</span>
                            </div>
                          </div>
                        </div>
                        <div className="add_review_right">
                          2 days ago
                        </div>
                      </div>
                      <p>{row.content}</p>
                    </div>

                  ))}
                </div>


                <div className="row">
                  <div className="col-md-12">
                    <div className="add_rating">
                      <div className="add_rate_title">Add Rating</div>
                      <p>Tap a star to rate it on</p>
                      <div id="full-stars-example-two">
                        <div class="rating-group">
                          <input disabled checked class="rating__input rating__input--none" name="rating3" id="rating3-none" value="0" type="radio" />
                          <label aria-label="1 star" class="rating__label" for="rating3-1"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                          <input class="rating__input" onChange={() => this.handleChange(1)} name="rating3" id="rating3-1" value="1" type="radio" />
                          <label aria-label="2 stars" class="rating__label" for="rating3-2"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                          <input class="rating__input" onChange={() => this.handleChange(2)} name="rating3" id="rating3-2" value="2" type="radio" />
                          <label aria-label="3 stars" class="rating__label" for="rating3-3"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                          <input class="rating__input" onChange={() => this.handleChange(3)} name="rating3" id="rating3-3" value="3" type="radio" />
                          <label aria-label="4 stars" class="rating__label" for="rating3-4"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                          <input class="rating__input" onChange={() => this.handleChange(4)} name="rating3" id="rating3-4" value="4" type="radio" />
                          <label aria-label="5 stars" class="rating__label" for="rating3-5"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                          <input class="rating__input" onChange={() => this.handleChange(5)} name="rating3" id="rating3-5" value="5" type="radio" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-8 offset-md-2 col-sm-12">
                    <a className="signin_btn" onClick={this.submitReview} type="submit">Save</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer>
          <Footer />
        </footer>
      </div> : <div className="spinner m-auto">
        {/* <img src="img/loading-buffering.gif" alt="loading-buffering" /> */}
      </div>;

  }
}
