import React, { Component } from "react";
import Footer from "../layout/footer/Footer.component";
import Header from "../layout/header/Header.component";
import './Expertadvisor.component.css'
import Book from '../../../assests/book.jpg';
import Meta from '../../../assests/meta.png';
import Book1 from '../../../assests/book1.jpg';
import Video from '../../../assests/video.png';
import Cart from '../../../assests/cart.png';
import Edit from '../../../assests/edit.png';
import Photo from '../../../assests/photo.png';

import { Link } from "react-router-dom";
import { fi } from "date-fns/locale";
import $ from "jquery";
import { Button } from "bootstrap";
import axios from "axios";
import constants from "../../../constants/constants";

let imgPath="../../../assests/"
 
export default class ExpertAdvisorInput extends Component {
   
    constructor(props){
        super(props);

        this.state={
            image:"",
            title:"",
            description:"",
            price:"",
            refundPolicy:"",
            license:"",
            support:"",
            compatibleBrokers:"",
            minDeposit:0,
            minDepositType:"MT4",
            currency:"USD",
            chartTimeFrame:"",
            module_label:"Expert",
            module_type:2,
            isUpdating:false,
            eaid:0,
            imgUpd:"",
            attachmentCount:0

            
        }

        this.imageHandleChange.bind();
        this.addMore = this.addMore.bind(this);
    }

    handeInput(e){  
        this.setState({
            [e.name]:e.value
        })
    }
    imageHandleChange = (file) => {  
        if(file.length>0)
        {
            
        var fname = file[0].name;
        var re = /(\.jpg|\.jpeg|\.png)$/i;
        if (!re.exec(fname)) {
            alert("File extension not supported. You must use .png, .jpeg or .jpg");
            $('.image').val('');
        }else{
            console.log('result',file[0])
            this.setState({image:file[0]});
            this.setState({isUpdating:true});
            
            var imageFile = file[0];
            if(imageFile){
                var reader = new FileReader();
                reader.onload = function(){
                    console.log(reader.result)
                    $("#previewImg").attr("src", reader.result);
                }
                reader.readAsDataURL(imageFile);
            }
        }
      }
    
    } 
    addMore(){
        if(this.state.attachmentCount==10)
            {
                alert("you can add upto 10 attachments only.");
                return;
            }
        var row='<div class="row">'
        +'<div class="col-6"> '
        +'    <div class="input67">'
        +'            <label>Additional Attachment</label>'
        +'            <input class="chart_time_frame" name="attachment"  type="file"  />'
        +'            <p style={{color:"red"}}></p>'
        +'        </div>'
        +'    </div>'
        +'    <div class="col-6"> '
        +'    <div class="input67">'
        +'        <br></br>'
        +'           <button class="btn btn-danger removeMore" ><i class="fa fa-minus-circle"></i></button>'
        +'        </div>'
        +'    </div>'
        +'</div>';
        $("#attachment").append(row);
        this.setState({
            attachmentCount:this.state.attachmentCount+1
        })
        $(".removeMore").click(function(){
            $(this).parent().parent().parent().remove();
            this.setState({
                attachmentCount:this.state.attachmentCount-1
            })
        });
    }
    componentDidMount(){
        
        $("#mt4").prop("checked",true);
        const queryParams=new URLSearchParams(window.location.search);
        const mod_type=queryParams.get("mod");
        const eaid=queryParams.get("eaid");
        if(eaid=='' || eaid=='undefined' ||  eaid==null)
        {
            this.setState({isUpdating:false,eaid:0});
        }
        else
        {
            this.setState({isUpdating:true,eaid:eaid});
            this.getDetails(eaid);
        }
        if(mod_type=='' || mod_type=='undefined' ||  mod_type==null)
        {
          this.setState({module_label:"Expert",module_type:1});
        }
        else
        {
            if(mod_type==2)
            {
                this.setState({module_label:"Expert",module_type:2});
            }
            else
            {
                this.setState({module_label:"Indicator",module_type:1});
            }
    
        } 
        
    }
    getDetails(eaid){
        axios.get(constants.API_URL+"/advisor/"+eaid+"/",{
            headers:{
                "Content-Type": "application/json",
                Authorization: "Token "+localStorage.getItem('accessToken'),
              }
        }).then((response)=>{

            if(response.status==200)
            {
                this.setState({
                    title:response.data.title,
                    description:response.data.description,
                    price:response.data.price,
                    refundPolicy:response.data.refund_policy,
                    license:response.data.license_no,
                    support:response.data.support,
                    compatibleBrokers:response.data.compatible_broker,
                    minDeposit:response.data.minimum_deposit,
                    minDepositType:response.data.category,
                    currency:response.data.currency_pair,
                    chartTimeFrame:response.data.chart_time_frame,
                    imgUpd:response.data.image,
                    image:""
                });
                if(response.data.category==1)
                    $("#mt4").prop("checked",true);
                else
                    $("#mt5").prop("checked",true);
                $(".description").val(response.data.description);
            }
              

        }).catch((error)=> {  
            Object.entries(error.response.data).map((key,value) => {
                $("."+key[0]).addClass("invalid");
                $("."+key[0]).parent().find("p").html(key[1]);
               console.log(key[0]);
            })
        });
    }

    addExpert(){
        const min_type = $('input[name="minType"]:checked').val(); 
        var formData=new FormData();
        formData.append("expert_type","test");
        formData.append("title",this.state.title);
        formData.append("category",min_type); // 1 for mt4 2 for mt5 
        formData.append("module_type",this.state.module_type); // 1 for indicator  2 for expert
        formData.append("description",this.state.description); 
        formData.append("price",this.state.price); 
        formData.append("refund_policy",this.state.refundPolicy); 
        formData.append("license_no",this.state.license); 
        formData.append("compatible_broker",this.state.compatibleBrokers); 
        formData.append("minimum_deposit",this.state.minDeposit); 
        formData.append("currency_pair",this.state.currency); 
        formData.append("chart_time_frame",this.state.chartTimeFrame); 
        formData.append("support",this.state.support); 

        if(this.state.title==""){
            alert("Please select title.");
            return;
        }
        if(this.state.isUpdating && this.state.image!="")
        {
         
            formData.append("image",this.state.image); 
        }
        if(!this.state.isUpdating)
        {
            if(this.state.image=="")
            {
                alert("Please select image.");
                return;
            }
            else
            {
                formData.append("image",this.state.image); 
            }
        }
      
        formData.append("status",0); 
var url="";
if(this.state.isUpdating)
{
    url=constants.API_URL+"/advisor/"+this.state.eaid+"/";
    axios.put(url,formData,{
        headers:{
            "Content-Type": "application/json",
            Authorization: "Token "+localStorage.getItem('accessToken'),
          }
    }).then((response)=>{
        if(response.status==200)
        {
            alert(this.state.module_label+" updated successfully");
            document.location.href="/expert-advisor";
        }
    }).catch((error)=> {  
        
        Object.entries(error.response.data).map((key,value) => {
            $("."+key[0]).addClass("invalid");
            $("."+key[0]).parent().find("p").html(key[1]);
           console.log(key[0]);
        })
    });
}
else
{
    url=constants.API_URL+"/advisor/";
    axios.post(url,formData,{
        headers:{
            "Content-Type": "application/json",
            Authorization: "Token "+localStorage.getItem('accessToken'),
          }
    }).then((response)=>{
        if(response.status==201)
        {
            alert(this.state.module_label+" created successfully");
            document.location.href="/expert-advisor";
        }
    }).catch((error)=> {  
        Object.entries(error.response.data).map((key,value) => {
            $("."+key[0]).addClass("invalid");
            $("."+key[0]).parent().find("p").html(key[1]);
           console.log(key[0]);
        })
    });
}

       
       
    }
    uploadImage()
    {
        $("#upimage").trigger("click");
    }
   
    render() {
        return (
            <div>
          <header>
            <div className="custom_container">
              <div className="row">
                <div className="col-md-12">
                  <Header />
                </div>
              </div>
            </div>
          </header>

<section className="exper_inpu6">
<div className="custom_container">
    <div className="headiny6">
        <h3>{!this.state.isUpdating?"Add an":"Update an "} <b>{this.state.module_label}</b></h3>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nunc sem, suscipit vel odio vel,<br /> pharetra iaculis dolor. Cras vitae facilisis tortor. </p>
    </div>
    <div className="row">
      <div className="col-md-6">  
        <div className="upload">
            {this.state.isUpdating?(
                <img src={this.state.imgUpd} style={{width:"100%",height:"100%",objectFit:"contain"}} id="previewImg"/> 
            ):""}
             <div style={{display:this.state.isUpdating?"none":"block"}}>
                <input className="image"  id="upimage" type="file" onChange={(e)=>this.imageHandleChange(e.target.files)}  />
            <div className="ulo_inner">
             <img  src={Photo} />
            <p>Upload Cover Picture</p>
            </div>
                </div>
           
        </div>
         <div className="edit_outer" for="image" onClick={()=>this.uploadImage()}>
              <img src={Edit} for="image"  id="expert_img"/>
            </div>
      </div>
      <div className="col-md-6">
      <div className="rihjt_input">
          <div className="input67">
              <label>Title Name</label>
              <input className="title" name="title" value={this.state.title} onChange={(e)=>this.handeInput(e.target)} type="text" placeholder="Book Title" />
              <p style={{color:"red"}}></p>
          </div>
          <div className="input67">
              <label>Description </label>
             <textarea className="description" name="description"  onChange={(e)=>this.handeInput(e.target)}  >{this.state.description}</textarea>
             <p style={{color:"red"}}></p>
          </div>
          <div className="input67">
              <label>Price</label>
              <input className="price" name="price" value={this.state.price} type="number" min="0"  placeholder="$20.5" onChange={(e)=>this.handeInput(e.target)} />
              <p style={{color:"red"}}></p>
          </div>
      </div>
      </div>
<div className="row custom_specr5">
      <div className="col-md-6"> 
       <div className="input67">
              <label>Refund Policy</label>
              <input className="refund_policy" name="refundPolicy" value={this.state.refundPolicy} onChange={(e)=>this.handeInput(e.target)} type="text" placeholder="lorum ipusm" />
              <p style={{color:"red"}}></p>
          </div>
      </div>
 <div className="col-md-6"> 
       <div className="input67">
              <label>License</label>
              <input className="license_no" name="license" value={this.state.license} onChange={(e)=>this.handeInput(e.target)} type="text" placeholder="lorum ipusm" />
              <p style={{color:"red"}}></p>
          </div>
      </div>

      </div>
      <div className="row custom_specr5">
      <div className="col-md-6"> 
       <div className="input67">
              <label>Support</label>
              <input className="support" name="support" value={this.state.support} onChange={(e)=>this.handeInput(e.target)} type="text" placeholder="lorum ipusm" />
              <p style={{color:"red"}}></p>
          </div>
      </div>
 <div className="col-md-6"> 
       <div className="input67">
              <label>Compatible Brokers</label>
              <input className="compatible_broker" name="compatibleBrokers" value={this.state.compatibleBrokers} onChange={(e)=>this.handeInput(e.target)} type="text" placeholder="lorum ipusm" />
              <p style={{color:"red"}}></p>
          </div>
      </div>
      
      </div>
       <div className="row custom_specr5">
      <div className="col-md-6"> 
       <div className="input67">
              <label>Minimum Deposit</label>
              <input className="minimum_deposit" name="minDeposit" value={this.state.minDeposit} onChange={(e)=>this.handeInput(e.target)} type="text" placeholder="$" />
              <p style={{color:"red"}}></p>
          </div>
      </div>
 <div className="col-md-6"> 
       <div className="input67 custom_897">
	   <label className="minyt6">Minimum Deposit</label>
               <input type="radio" id="mt4" name="minType"  value="1" />
  <label for="age1">MT4 Compatibility</label>
 <span className="cut67"> <input type="radio" id="mt5" name="minType" value="2" />
  <label for="age2">MT5 Compatibility</label> </span>
          </div>
      </div>
      
      </div>
  <div className="row custom_specr5">
      <div className="col-md-6"> 
       <div className="input67">
              <label>Supported Currency Pairs</label>
              <input className="currency_pair" name="currency" value={this.state.currency} onChange={(e)=>this.handeInput(e.target)} type="text" placeholder="EURUSD" />
              <p style={{color:"red"}}></p>
          </div>
      </div>
 <div className="col-md-6"> 
       <div className="input67">
              <label>Chart Timeframe</label>
              <input className="chart_time_frame" name="chartTimeFrame" value={this.state.chartTimeFrame} onChange={(e)=>this.handeInput(e.target)} type="text" placeholder="H1" />
              <p style={{color:"red"}}></p>
          </div>
      </div>
      <div className="col-12" id="attachment">
        
<div className="row">
    <div className="col-6"> 
        <div className="input67">
                <label>Additional Attachment</label>
                <input className="chart_time_frame" name="attachment"  type="file"  />
                <p style={{color:"red"}}></p>
            </div>
        </div>
        <div className="col-6"> 
        <div className="input67">
            <br></br>
            <br></br>
               <button className="btn btn-primary addMore" onClick={()=>this.addMore()}><i class="fa fa-plus-circle"></i></button>
            </div>
        </div>
</div>

</div>
      </div>
    
  <div className="row custom_specr5">
      {/* <div className="col-md-6"> 
       <div className="input67 dottred">
              <label>Upload Additional Image</label>
              <input type="text" placeholder="Upload Image" />
              
			  <i className="fas fa-link"></i>
              <p style={{color:"red"}}></p>
          </div>
      </div>
 <div className="col-md-6"> 
       <div className="input67 dottred">
              <label className="hide_outer">Chart Timeframe</label>
              <input type="text" placeholder="Upload Image" />
			  <i className="fas fa-link"></i>
              <p style={{color:"red"}}></p>
          </div>
      </div> */}
      <button className="btn btn-primary" type="button" onClick={()=>this.addExpert()}>{ !this.state.isUpdating?"Add "+this.state.module_label:"Update "+this.state.module_label }</button>
      </div>
    </div>
</div>

</section>        
      
          <footer>
            <Footer />       
          </footer>
            </div>
        )
    }
}
