import React, { Component } from "react";
import Footer from "../layout/footer/Footer.component";
import Header from "../layout/header/Header.component";
import './Chatsingle.component.css'
import DP from '../../../assests/dp.jpg'
import analyticsImg from '../../../assests/advanced-analytics.jpg';
//import { API_BASE_URL,SOCKET_URL } from "../constants/AppConstants";
import { Link } from "react-router-dom";
import io from "socket.io-client"; 
import axios from "axios";
import constants from "../../../constants/constants";
var globalSocket;
const socketUrl = constants.SOCKET_URL;
const nodeUrl = constants.NODE_URL;
let imgPath="../../../assests/"
export default class SinglePage extends Component {

  constructor(props) {
    super(props)
    this.state = {
        transcode: ''
    }
  }

  componentDidMount() {
      let id = localStorage.getItem("userId");
       
      this.setState({
        transcode: '1'
      })
      this.initSocket()


      //https://stock-trading-backend.itechnolabs.tech/chat/user-list/gold
  }

  //we have to send data
  // globalSocket.emit( 'create_debate', payload )
 
  initSocket = async () => {
    let socket = io.connect(socketUrl)
    globalSocket=socket;
    this.setState({ socket })
    socket.on('connect', () => console.log( 'Connected')) 
    
      //when we recieve data from server
      socket.on('create_debate',(data)=>{ // create_debate fn will be in backend 
        
        //console.log(data)


        // socket.emit('chat-message-to-user', { userid: ids }, function(dataFromServer) {
        //     console.log(dataFromServer);
        // });
        
      
      })
  }
    render() {
        return (
            <div>
          <header>
            <div className="custom_container">
              <div className="row">
                <div className="col-md-12">
                  <Header />
                </div>
              </div>
            </div>
          </header>

          <section className="single_chat_outer">
            <div className="custom_container">
              <div className="row chat_row">
                <div className="col-md-4 chat_four_col">
                  <div className="single_chat_left">
                    <div className="single_chat_upper">
                      <input className="serach_input" type="text" placeholder="Search" />
                    </div>
                    <div className="chat_listing">
                      <ul className="chat_listing_ul">
                        <li>
                          <div className="chat_li_inner">
                            <div className="chat_li_left">
                              <img src={DP} />
                              <span className="away"></span>
                            </div>
                            <div className="chat_li_center">
                              <div className="message_t"><b>Franky Schmidt, Annie …</b></div>
                              <p>Me: What does this dummy…</p>
                            </div>
                            <div className="chat_li_right">
                              <span className="time_span">08:05</span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="chat_li_inner">
                            <div className="chat_li_left">
                              <img src={DP} />
                              <span className="away"></span>
                            </div>
                            <div className="chat_li_center">
                              <div className="message_t"><b>Franky Schmidt, Annie …</b></div>
                              <p>Me: What does this dummy…</p>
                            </div>
                            <div className="chat_li_right">
                              <span className="time_span">08:05</span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="chat_li_inner">
                            <div className="chat_li_left">
                              <img src={DP} />
                              <span className="away"></span>
                            </div>
                            <div className="chat_li_center">
                              <div className="message_t"><b>Franky Schmidt, Annie …</b></div>
                              <p>Me: What does this dummy…</p>
                            </div>
                            <div className="chat_li_right">
                              <span className="time_span">08:05</span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="chat_li_inner">
                            <div className="chat_li_left">
                              <img src={DP} />
                              <span className="away"></span>
                            </div>
                            <div className="chat_li_center">
                              <div className="message_t"><b>Franky Schmidt, Annie …</b></div>
                              <p>Me: What does this dummy…</p>
                            </div>
                            <div className="chat_li_right">
                              <span className="time_span">08:05</span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="chat_li_inner">
                            <div className="chat_li_left">
                              <img src={DP} />
                              <span className="away"></span>
                            </div>
                            <div className="chat_li_center">
                              <div className="message_t"><b>Franky Schmidt, Annie …</b></div>
                              <p>Me: What does this dummy…</p>
                            </div>
                            <div className="chat_li_right">
                              <span className="time_span">08:05</span>
                            </div>
                          </div>
                        </li><li>
                          <div className="chat_li_inner">
                            <div className="chat_li_left">
                              <img src={DP} />
                              <span className="away"></span>
                            </div>
                            <div className="chat_li_center">
                              <div className="message_t"><b>Franky Schmidt, Annie …</b></div>
                              <p>Me: What does this dummy…</p>
                            </div>
                            <div className="chat_li_right">
                              <span className="time_span">08:05</span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="chat_li_inner">
                            <div className="chat_li_left">
                              <img src={DP} />
                              <span className="away"></span>
                            </div>
                            <div className="chat_li_center">
                              <div className="message_t"><b>Franky Schmidt, Annie …</b></div>
                              <p>Me: What does this dummy…</p>
                            </div>
                            <div className="chat_li_right">
                              <span className="time_span">08:05</span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="chat_li_inner">
                            <div className="chat_li_left">
                              <img src={DP} />
                              <span className="away"></span>
                            </div>
                            <div className="chat_li_center">
                              <div className="message_t"><b>Franky Schmidt, Annie …</b></div>
                              <p>Me: What does this dummy…</p>
                            </div>
                            <div className="chat_li_right">
                              <span className="time_span">08:05</span>
                            </div>
                          </div>
                        </li>

                        <li>
                          <div className="chat_li_inner">
                            <div className="chat_li_left">
                              <img src={DP} />
                              <span className="away"></span>
                            </div>
                            <div className="chat_li_center">
                              <div className="message_t"><b>Franky Schmidt, Annie …</b></div>
                              <p>Me: What does this dummy…</p>
                            </div>
                            <div className="chat_li_right">
                              <span className="time_span">08:05</span>
                            </div>
                          </div>
                        </li><li>
                          <div className="chat_li_inner">
                            <div className="chat_li_left">
                              <img src={DP} />
                              <span className="away"></span>
                            </div>
                            <div className="chat_li_center">
                              <div className="message_t"><b>Franky Schmidt, Annie …</b></div>
                              <p>Me: What does this dummy…</p>
                            </div>
                            <div className="chat_li_right">
                              <span className="time_span">08:05</span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="chat_li_inner">
                            <div className="chat_li_left">
                              <img src={DP} />
                              <span className="away"></span>
                            </div>
                            <div className="chat_li_center">
                              <div className="message_t"><b>Franky Schmidt, Annie …</b></div>
                              <p>Me: What does this dummy…</p>
                            </div>
                            <div className="chat_li_right">
                              <span className="time_span">08:05</span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="chat_li_inner">
                            <div className="chat_li_left">
                              <img src={DP} />
                              <span className="away"></span>
                            </div>
                            <div className="chat_li_center">
                              <div className="message_t"><b>Franky Schmidt, Annie …</b></div>
                              <p>Me: What does this dummy…</p>
                            </div>
                            <div className="chat_li_right">
                              <span className="time_span">08:05</span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="chat_li_inner">
                            <div className="chat_li_left">
                              <img src={DP} />
                              <span className="away"></span>
                            </div>
                            <div className="chat_li_center">
                              <div className="message_t"><b>Franky Schmidt, Annie …</b></div>
                              <p>Me: What does this dummy…</p>
                            </div>
                            <div className="chat_li_right">
                              <span className="time_span">08:05</span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="chat_li_inner">
                            <div className="chat_li_left">
                              <img src={DP} />
                              <span className="away"></span>
                            </div>
                            <div className="chat_li_center">
                              <div className="message_t"><b>Franky Schmidt, Annie …</b></div>
                              <p>Me: What does this dummy…</p>
                            </div>
                            <div className="chat_li_right">
                              <span className="time_span">08:05</span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="chat_li_inner">
                            <div className="chat_li_left">
                              <img src={DP} />
                              <span className="away"></span>
                            </div>
                            <div className="chat_li_center">
                              <div className="message_t"><b>Franky Schmidt, Annie …</b></div>
                              <p>Me: What does this dummy…</p>
                            </div>
                            <div className="chat_li_right">
                              <span className="time_span">08:05</span>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-md-8 chat_eight_col">
                  <div className="single_chat_right">
                    <div className="single_chat_upper">
                      <div className="signle_chat_title">Cairistiona</div>
                      <p className="Forex_title">Forex</p>
                    </div>
                    <div className="message_right">
                  <div className="message_upper">
                    <div className="message_first">
                      <div className="message_first_left">
                        <ul>
                          <li><span>Hello Franky!</span></li>
                          <li><span>How are you doing Today:</span></li>
                          <span className="message_time">9:35</span>
                        </ul>
                      </div>
                      <img src={DP} />
                    </div>
                    <div className="message_first message_second">
                      <div className="message_first_left">
                        <ul>
                          <li><span>Hello Franky!</span></li>
                          <li><span>How are you doing Today:</span></li>
                          <span className="message_time">9:35</span>
                      </ul>
                      </div>
                      <img src={DP} />
                    </div>
                    <div className="message_first">
                      <div className="message_first_left">
                        <ul>
                          <li><span>Yep, thanks!</span></li>
                          <li><span>May I ask you a favour?</span></li>
                          <li><span>What is Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s when an unknown printer took a galley of type and scrambled it to make a type specimen book it has?</span></li>
                          <span className="message_time">9:35</span>
                        </ul>
                      </div>
                      <img src={DP} />
                    </div>
                    <div className="message_first message_second">
                      <div className="message_first_left">
                        <ul>
                          <li><img src={analyticsImg} /></li>
                          <span className="message_time">9:35</span>
                        </ul>
                      </div>
                      <img src={DP} />
                    </div>
                  </div>
                  
                </div>

                <div className="message_bottom">
                    <div className="text_message_outer">
                      <input type="text" placeholder="Typing..." name="" />
                      <div className="text_message_right">
                        <ul>
                          <li><a href="#"><i className="fal fa-paperclip"></i></a></li>
                          <li><a href="#"><i className="fal fa-paper-plane send_i"></i></a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
      
          <footer>
            <Footer />       
          </footer>
            </div>
        )
    }
}
