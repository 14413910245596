import { CONSTANT } from "../constants";
import axios from "axios";

export const getSymbolList = (stock, pgid) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${CONSTANT.API_BASE}/symbol-list/${stock}?page=${pgid}&apikey=${process.env.REACT_APP_FINAGE_API_KEY}`
    );
    return dispatch({
      type: CONSTANT.GET_SYMBOL_LIST,
      data: res.data,
      error: null,
    });
  } catch (error) {
    dispatch({
      type: CONSTANT.GET_SYMBOL_LIST,
      data: null,
      error: "api not working due to " + error,
    });
  }
};

export const getCompareSymbol =
  (symbol, stock, time, mult, startd, endd) => async (dispatch) => {
    try {
      const symbolData = [];
      for (var i = 0; i < symbol.length; i++) {
        const res = await axios.get(
          `${CONSTANT.API_BASE}/agg/${stock}/${symbol[i]}/${mult}/${time}/${startd}/${endd}?limit=50000&apikey=${process.env.REACT_APP_FINAGE_API_KEY}`
        );
        symbolData.push(res.data);
      }
      return dispatch({
        type: CONSTANT.GET_COMP_STOCK_LIST,
        data: symbolData,
        error: null,
      });
    } catch (err) {
      return dispatch({
        type: CONSTANT.GET_COMP_STOCK_LIST,
        data: null,
        error:
          "Api not Working due to " + err + " .Please select another symbol.",
      });
    }
  };

export const getSearchSymb = (stock, search) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${CONSTANT.API_BASE}/search/${stock}/${search}?limit=10&apikey=${process.env.REACT_APP_FINAGE_API_KEY}`
    );

    return dispatch({
      type: CONSTANT.GET_SEARCH_SYMBOL,
      data: res.data.results,
      error: null,
    });
  } catch (error) {
    dispatch({
      type: CONSTANT.GET_SEARCH_SYMBOL,
      data: null,
      error: "api not working due to " + error,
    });
  }
};

export const getUserIp = () => async (dispatch) => {
  try {
    const res = await axios.get(`${CONSTANT.API_BACKEND}/auth/getip/`);
    return dispatch({
      type: CONSTANT.GET_USER_IP,
      data: res.data,
      error: null,
    });
  } catch (err) {
    return dispatch({
      type: CONSTANT.GET_USER_IP,
      data: null,
      error:
        "Api not Working due to " + err + " .Please select another symbol.",
    });
  }
};

export const createAlertNotify =
  (
    token,
    cond_name,
    cond_value,
    cond_check,
    option,
    time_stamp,
    action_method,
    alert_name,
    alert_msg,
    symbol,
    web_hook_url
  ) =>
    async (dispatch) => {
      try {
        const headers = {
          "Content-Type": "application/json",
          Authorization: token,
        };
        const data = {
          cond_name,
          cond_value,
          cond_check,
          option,
          time_stamp,
          action_method,
          alert_name,
          alert_msg,
          symbol,
          web_hook_url
        };
        const res = await axios.post(
          `${CONSTANT.API_BACKEND}/alert_notification/`,
          data,
          {
            headers: headers,
          }
        );
        return dispatch({
          type: CONSTANT.GET_ALERT_LIST,
          data: res.data,
          error: null,
        });
      } catch (err) {
        return dispatch({
          type: CONSTANT.GET_ALERT_LIST,
          data: null,
          error:
            "Api not Working due to " + err + " .Please select another symbol.",
        });
      }
    };

export const getUserFavList = (userip) => async (dispatch) => {
  try {
    const headers = {
      "Content-Type": "multipart/form-data",
    };

    const res = await axios.post(
      `${CONSTANT.API_BACKEND}/auth/show-favorite/`,

      userip
    );
    return dispatch({
      type: CONSTANT.GET_FAV_LIST,
      data: res.data,
      error: null,
    });
  } catch (err) {
    return dispatch({
      type: CONSTANT.GET_FAV_LIST,
      data: null,
      error:
        "Api not Working due to " + err + " .Please select another symbol.",
    });
  }
};

export const getStockValue =
  (symbol, stock, time, mult, startd, endd) => async (dispatch) => {
    try {
      const res = await axios.get(
        `${CONSTANT.API_BASE}/agg/${stock}/${symbol}/${mult}/${time}/${startd}/${endd}?limit=50000&apikey=${process.env.REACT_APP_FINAGE_API_KEY}`
      );
      return dispatch({
        type: CONSTANT.GET_STOCK_LIST,
        data: res.data,
        error: null,
      });
    } catch (err) {
      return dispatch({
        type: CONSTANT.GET_STOCK_LIST,
        data: null,
        error:
          "Api not Working due to " + err + " .Please select another symbol.",
      });
    }
  };

export const returnErrors = () => {
  return {
    type: CONSTANT.ERROR_SHOW,
    data: { error: "api not worked" },
  };
};
