export const CONSTANT = {
  API_BASE: "https://api.finage.co.uk",
  GET_SYMBOL_LIST: "GET_SYMBOL_LIST",
  API_BACKEND: "https://stock-trading-backend.itechnolabs.tech",
  ERROR_SHOW: "ERROR_SHOW",
  GET_STOCK_LIST: "GET_STOCK_LIST",
  GET_SEARCH_SYMBOL: "GET_SEARCH_SYMBOL",
  GET_USER_IP: "GET_USER_IP",
  GET_FAV_LIST: "GET_FAV_LIST",
  GET_COMP_STOCK_LIST: "GET_COMP_STOCK_LIST",
  GET_ALERT_LIST: "GET_ALERT_LIST",
};
