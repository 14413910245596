import React, { Component } from "react";
import Footer from "../layout/footer/Footer.component";
import Header from "../layout/header/Header.component";
import './Outlook.component.css'
import B1 from '../../../assests/b1.png';
import B2 from '../../../assests/b2.png';
import B3 from '../../../assests/b3.png';
import Explore from '../../../assests/explore.jpg';
import analyticsImg from '../../../assests/advanced-analytics.jpg';
import accountImg from '../../../assests/account_statement.jpg';
import Graph from '../../../assests/graph.jpg';
import F1 from '../../../assests/f1.png';
import E1 from '../../../assests/e1.jpg';
import E2 from '../../../assests/e2.jpg';
import E3 from '../../../assests/e3.jpg';
import E4 from '../../../assests/e4.jpg';
import S1 from '../../../assests/s1.jpg';
import S2 from '../../../assests/s2.jpg';
import S3 from '../../../assests/s3.jpg';
import S4 from '../../../assests/s4.jpg';
import R1 from '../../../assests/r1.jpg';
import R2 from '../../../assests/r2.jpg';
import O1 from '../../../assests/o1.jpg';
import { Link } from "react-router-dom";
 import { CONSTANT } from "../../../services/constants.js";
import axios from "axios";
import {
  EmailShareButton,
  FacebookShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton
} from "react-share";
import { FacebookIcon, TwitterIcon, LinkedinIcon  } from "react-share";
import $ from 'jquery';
import { isThisMonth } from "date-fns/esm";
import authService from "../../../services/auth.service";

let imgPath="../../../assests/"
export default class OutlookPage extends Component {

    displayData(){
      var filter='';
      var searchParam=$('#searchBox').val();
      var date=$("#searchDate").val();
      var sort=$("#searchSort").val();
      var type=$("#searchtype").val();
      if(date!='' && date)
      {
        if(filter=='')
        {
          filter='?date='+date;
        }
        else
        {
          filter+='&date='+date;
        }

      }
      if(type!='0' && type)
      {
        if(filter=='')
        {
          filter='?search='+type;
        }
        else
        {
          filter+='&search='+type;
        }

      }
      if(sort!='' && sort)
      {
          if(filter=='')
          {
            filter='?sortby='+sort;
          }
          else
          {
            filter+='&sortby='+sort;
          }
      }
      if(searchParam!='' && searchParam)
      {
        if(filter=='')
          {
            filter='?search='+searchParam;
          }
          else
          {
            filter+='&search='+searchParam;
          }
      }
      console.log(CONSTANT.API_BACKEND+'/post'+filter)
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Token "+localStorage.getItem('accessToken'),
      };
      axios.get(CONSTANT.API_BACKEND+'/post'+filter,{
        headers: headers,
      }
      ).then(res => {
          if(res.data){ 
            const data = res.data.data;
            //console.log(data.length)
            this.setState({ allData:data });
            this.setState({ totalData:data.length });
            $('.custom_inputImage').val('');
            $('#description').html('');
            $('#title').val('');
            $("#radioForex").prop("checked", true);
          }
      })
    }
    
    getUserInfo(id, accessToken) {
      authService
        .getUser(accessToken, id)
        .then((response) => {
         // console.log('data' + response);
         
          if (
            response.data.first_name !== null &&
            response.data.first_name !== "" &&
            response.data.first_name !== undefined
          ) {
            this.setState({userid:response.data.id});
          }
  
          
        })
        .catch((error) => {
          this.setState({userid:0});
          // if (
          //   error &&
          //   error.response &&
          //   error.response.data &&
          //   error.response.data.error !== undefined &&
          //   error.response.data.error !== null &&
          //   error.response.data.error !== ""
          // ) {
          //   toast.error(error.response.data.error, {
          //     autoClose: 6000,
          //   });
          // }
          // if (
          //   error.response.data.detail !== undefined &&
          //   error.response.data.detail !== null &&
          //   error.response.data.detail !== ""
          // ) {
          //   if (error.response.data.detail === "Invalid token.") {
          //     //  window.location.href = `${window.location.origin}/login`
          //     this.setState({ accessToken: null });
          //     // toast.error(error.response.data.detail + "Please login.", {
          //     //   autoClose: 6000,
          //     // })
          //   }
          // }
        });
    }
  

    constructor(props) {
      super(props);

      this.state = {
        stockData: [],
        stockLoad: false,
        stockError: "",
        allData:[],
        reviews:[],
        rating:"0",
        title:"",
        pickdate:"",
        sort:"",
        comment:"",
        imageData:"",
        totalData:0,
        isUpdating:false,
        postid:0,
        userid:0,
        commentList:[]
      };

      this.displayData()

      let id = localStorage.getItem("userId");

      let accessToken = localStorage.getItem("accessToken");
      this.setState({
        accessToken: accessToken,
      });
      this.getUserInfo(id,accessToken);
    }
    stock_type = [null,'Forex','Stock Market','Cryptocurrency'];
 
    componentDidMount() {
      
    }
    likeThis(id){ 
        const headers = {
          "Content-Type": "application/json",
          Authorization: "Token "+localStorage.getItem('accessToken'),
        };
        axios.post(CONSTANT.API_BACKEND+'/like/',
          {'post':id},
          {
            headers: headers,
          }
        )
        .then(res => {
          console.log(res.data)
          if(res.data){
            const data = res.data;
            $("#like"+id).html("("+res.data.post_like+")");
            console.log(data)
            this.setState({ reviews:data,brokerId:id });
           
          }
        }).catch(error => {
         alert(error.response.data.error)
         console.log(error.response.data.error)
      })
    }
    showComments(id)
    {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Token "+localStorage.getItem('accessToken'),
      };
      axios.get(CONSTANT.API_BACKEND+'/post/'+id+'/',
        {
          headers: headers,
        }
      )
      .then(res => {
        console.log(res.data)
        if(res.data){
          this.setState({commentList:res.data.comments});
          $("#commentModal").trigger({ type: "click" });
         
        }
      }).catch(error => {
       alert(error.response.data.error)
       console.log(error.response.data.error)
    })
    }
    shareThis(id){ 
        const headers = {
          "Content-Type": "application/json",
          Authorization: "Token "+localStorage.getItem('accessToken'),
        };
        axios.post(CONSTANT.API_BACKEND+'/share/',
          {'post':id},
          {
            headers: headers,
          }
        )
        .then(res => {
          console.log(res.data)
          if(res.data){
            const data = res.data;
            console.log(data)
            //this.setState({ reviews:data,brokerId:id });
          }
        }).catch(error => {
        alert(error.response.data.error)
        console.log(error.response.data.error)
      })
    }
    postComment(id){
      if(this.state.comment=='')
      {
        alert("Please enter your comment");
        return;
      }
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Token "+localStorage.getItem('accessToken'),
      };
      axios.post(CONSTANT.API_BACKEND+'/comment/',
        {
            "post":id,
            "comment":this.state.comment
        },
        {
          headers: headers,
        }
      )
        .then(res => {
          if(res.data){
            alert('Comment Success')
            $("#comment"+id).html("("+res.data.post_comments+")");
           
            this.setState({ comment:"" });
            $('.comment_box').val('')

          }
        }).catch(error => {
         alert(error.response.data.error)
         console.log(error.response.data.error)
      })

    }
    deletePost(id){
      if(!window.confirm("Are you sure to delete this post ?"))
      {
        return;
      }
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Token "+localStorage.getItem('accessToken'),
      };
      axios.delete(CONSTANT.API_BACKEND+'/post/'+id+'/',
        {
          headers: headers,
        }
      )
        .then(res => {
          if(res.status==204){
            alert('Post deleted successfully')
           this.displayData();
          }
        }).catch(error => {
         alert(error.response.data.error)
         console.log(error.response.data.error)
      })

    }

    imageHandleChange = (file) => { 
        var fname = file[0].name;
        var re = /(\.jpg|\.jpeg|\.png|\.mp4|\.mov|\.wmv|\.avi|\.mp3|\.aac|\.wav)$/i;
        if (!re.exec(fname)) {
            alert("File extension not supported. You must use .png, .jpeg .jpg .mp4 .mov .wmv .avi .mp3 .aac or .wav");
            $('.custom_inputImage').val('');
        }else{
            this.setState({imageData:file[0]})
        }
    } 

    addOutLook = () =>{ 
      const stock_type = $('input[name="radio"]:checked').val(); 
      const post_title = $('#title').val(); 
      const description = $('#description').val(); 

      const joinData = new FormData();
      if(stock_type=='undefined' || stock_type=='')
      {
        alert('Select stock type');
        return;
      }
      if(post_title=='')
      {
        alert("Please enter the post title");
        return;
      }
      if(!this.state.isUpdating && this.state.imageData=='')
      {
        alert('Please upload a file.');
        return;
      }
      joinData.append("title", stock_type); 
      joinData.append("post_title", post_title); 
    
      joinData.append("content", description);
      if(this.state.imageData!='')
      {
        joinData.append("image", this.state.imageData);
      }
       
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Token "+localStorage.getItem('accessToken'),
      };

      if(this.state.isUpdating)
      {
        axios.put(CONSTANT.API_BACKEND+'/post/'+this.state.postid+'/',joinData,{
          headers: headers,
        }).then((response) => {
            console.log(response);
            if(response.status == 200){ 
              alert("Post updated successfully.");
              this.displayData(); 
             
              this.setState({postid:0});
              this.setState({isUpdating:false});
              $("[data-dismiss=modal]").trigger({ type: "click" });
  
            } 
        }).catch((error)=> {    
        }); 
      }
      else
      {
        axios.post(CONSTANT.API_BACKEND+'/post/',joinData,{
          headers: headers,
        }).then((response) => {
            console.log(response);
            if(response.status == 201){ 
              this.displayData(); 
             
  
              $("[data-dismiss=modal]").trigger({ type: "click" });
  
            } 
        }).catch((error)=> {    
        }); 
      }
     
      $('#description').trigger("reset"); 
      $('#image').trigger("reset"); 
    }

    handleChange = (e) => { 
      if(e !== undefined){
      // console.log(e.target.value); return;
        this.setState({[e.target.name]:e.target.value})
        // this.searchPosts();
        this.displayData();
      }
    }

    searchPosts=(e)=>{
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Token "+localStorage.getItem('accessToken'),
      };
      axios.get(CONSTANT.API_BACKEND+'/post/',
          {
            headers: headers,
          }
      ).then(res => {
          if(res.data){

            const data = res.data.data;
            this.setState({ allData:data });
          }
      })
    }
    submitReview(){
      const data= {
        "id":1,
        "broker":3,
        "content":"Test content",
        "start_rating":4.0
      }
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Token "+localStorage.getItem('accessToken'),
      };
      axios.post(CONSTANT.API_BACKEND+'/review/',
        data,
          {
            headers: headers,
          }
        )
        .then(res => {

          alert('review submitted')
          if(res.data){

            // const data = res.data;
            //this.setState({ reviews:data });
          }
      }).catch(error => {
         alert(error.response.data.error)
         console.log(error.response.data.error)
      })

    }
    showData(row)
    {
      $("#detailCategory").html(this.stock_type[row.title]);
      $("#detailImage").attr("src",row.image);
      $("#detailDescription").html(row.content);
      $("#detailModal").trigger({ type: "click" });
    }
    editData(row)
    {
      this.setState({isUpdating:true});
      if(row.title==1)
      {
        $("#radioForex").prop("checked",true);
      }
      else if(row.title==2)
      {
        $("#radioStock").prop("checked",true);
      }
      else
      {
        $("#radioCryptocurrency").prop("checked",true);
      }
      $("#description").html(row.content);
      $("#description").val(row.content);
      $("#title").val(row.post_title);
      $("#addImage").attr('src',row.image);
      this.setState({postid:row.id});
      $("[data-target='#outlookModal']").trigger({type:'click'});

    }
    addPost()
    {
      this.setState({isUpdating:false});
      $('.custom_inputImage').val('');
      $('#description').html('');
      $("#radioForex").prop("checked", true);
      $("#title").val('');
      $("[data-target='#outlookModal']").trigger({type:'click'});

    }
    render() {
        return (
            <div>
          <header>
            <div className="custom_container">
              <div className="row">
                <div className="col-md-12">
                  <Header />
                </div>
              </div>
            </div>
          </header>

          <section className="banner_outer news_banner">
            <div className="overlay"></div>
                <div className="custom_container">
                <div className="row" >
                    <div className="col-md-8 offset-md-2 col-sm-12 first_div text-center">
                      <div class="banner_content">
                        <h2>My Outlook</h2>
                         <hr class="banner_hr news_hr" /><h3>
                         <ul className="news_ul">
                           <li><Link to={"/home"} >Home</Link></li>
                           <li><i class="fa fa-angle-right"></i></li>
                           <li><Link to={"/outlook"} className="active" >My Outlook</Link></li>
                         </ul>
                        </h3>
                      </div>
                    </div>
                </div>
            </div>
          </section>
          
          <section>
            <div className="custom_container">
                <div className="row" >
                <div className="col-md-8 offset-md-2 col-sm-12 second_div text-center">
                <p className="p_text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius, diam id pharetra egestas, 
                felis neque vulputate quam, bibendum iaculis mauris justo id tellus. Nulla consequat metus ac quam eleifend, nec dignissim justo egestas. </p>
                <button type="button" className="btn btn-dark signup_btn" onClick={()=>this.addPost()}>Create New Post</button></div>
                <button type="button" style={{display:"none"}} className="btn btn-dark signup_btn" data-toggle="modal" data-target="#outlookModal">dont remove</button>
                </div>
            </div>
          </section>
          <section className="outlook_section most_polupar">
            <div className="custom_container">
                <div className="row filter_row">
                   <div className="col-md-5">
                     <input class="custom_input search_input" onChange={this.handleChange} name="title" id="searchBox" onKeyUp={()=>this.displayData()} type="text" placeholder="Search" value={this.state.title} />
                   </div>
                   <div className="col-md-2">
                     <input class="custom_input" type="date" onChange={this.handleChange} id="searchDate" name="pickdate" placeholder="Email Address" value={this.state.pickdate} />
                   </div>
                   <div className="col-md-2">
                     <select class="custom_input" id="searchSort" onChange={this.handleChange} name="sort">
                         <option value="0">Sort by </option>
                         <option value="new">Latest - Old</option>
                         <option value="old">Old - Latest</option>
                     </select>
                   </div>
                   <div className="col-md-2">
                     <select class="custom_input" id="searchtype" onChange={this.handleChange} name="searchtype">
                         <option value="0">Select type </option>
                         <option value="1">Forex</option>
                         <option value="2">Stock Market</option>
                         <option value="3">Cryptocurrency</option>
                     </select>
                   </div>
                </div>

                <div className="row">
                  {   this.state.allData.map((row,i) =>(   
                  <div className="col-md-4">
				  <div className="out_out6">
                    
                    <div className="advance_analytic" onClick={()=>this.showData(row)}>
                      <img src={row.image} />
                      <p>{this.stock_type[row.title]}</p>
                    </div>
                    <h2>{row.post_title} &nbsp;</h2>
                    <ul className="outlook_ul">
                      <li onClick={()=>this.likeThis(row.id)}><i class="fa fa-heart"></i>Like<span id={"like"+row.id}>({row.total_likes})</span></li>
                      <li onClick={()=>this.showComments(row.id)} ><i class="fa fa-comment"></i>Comment<span id={"comment"+row.id}>({row.total_comments})</span></li>
                      
                      <li>
                   
                    	<div className="soical_i89">
					
                      <FacebookShareButton
                        url={"https://stock.itechnolabs.tech/outlook"}
                        //quote={"フェイスブックはタイトルが付けれるようです"}
                        //hashtag={"#hashtag"}
                        description={row.content}
                        className="Demo__some-network__share-button"
                        onClick={()=>this.shareThis(row.id)}
                      >
                        <FacebookIcon size={32} round />
                      </FacebookShareButton>
 
                      <TwitterShareButton
                        title={"test"}
                        url={"https://stock.itechnolabs.tech/outlook"}
                        description={row.content}
                        //hashtags={["hashtag1", "hashtag2"]}
                        onClick={()=>this.shareThis(row.id)}
                      >
                        <TwitterIcon size={32} round />
                        
                      </TwitterShareButton>
                      <LinkedinShareButton
                        title={"test"}
                        url={"https://stock.itechnolabs.tech/outlook"}
                        description={row.content}
                        //hashtags={["hashtag1", "hashtag2"]}
                        onClick={()=>this.shareThis(row.id)}
                      >
                        <LinkedinIcon size={32} round />

                        
                      </LinkedinShareButton>
                      

					</div></li>
                    </ul>
				
				
                    <div className="row">
                      <div className="col-md-12">
                       <div className="custom_input2"> 
					   <input   type="text" className="form-control comment_box" value={this.state.name} onChange={this.handleChange} name="comment" style={{width: '80%'}}/> 
	

<button type="button" id="detailModal" style={{display:'none'}} className="btn btn-info btn-lg" data-toggle="modal" data-target="#myModal017">Open Modal</button>
<div id="myModal017" className="modal fade" role="dialog">
  <div className="modal-dialog">

    <div className="modal-content">
      <div className="modal-header">
        <button type="button" className="close" data-dismiss="modal">&times;</button>
        <h4 className="modal-title" id="detailCategory">Category</h4>
      </div>
      <div className="modal-body">
	  <div className="custom_body">
	  <img id="detailImage" src="/static/media/o1.26bf363e.jpg" />
			 <p id="detailDescription">Description</p>
	  </div>
       
      </div>
     
    </div>

  </div>
</div>
                      </div>
					  </div>
                      <div className="btn_connevt">
                        <input type="button" className="btn btn-primary comment_outer4 " onClick={()=>this.postComment(row.id)} value="Comment" name="comment"/>
                        <input type="button" className="btn btn-primary delete_outer" onClick={()=>this.deletePost(row.id)} value="Delete Post"/>
                        {
                          this.state.userid==row.author.id?(<input type="button" className="btn btn-primary edit_outer" onClick={()=>this.editData(row)}  value="Edit Post"/>):('')
                        }
                        
                      </div>
                    </div>
                  </div>
				  </div>
                  ))}   
                </div>
                <button type="button" style={{display:'none'}} data-toggle="modal" data-target="#myModal_comment" id="commentModal"> </button>
					  		<div id="myModal_comment" className="modal fade" role="dialog">
  <div className="modal-dialog">

    <div className="modal-content">
      <div className="modal-header">
        <button type="button" className="close" data-dismiss="modal">&times;</button>
        <h4 className="modal-title">Comments</h4>
      </div>
      <div className="modal-body custom_bosy7">
        {this.state.commentList.map((row,index)=>(



       
		<div className="custom_comment">
			 <img  src={row.author.image==null?'https://cdn-icons-png.flaticon.com/512/149/149071.png':row.author.image} /> 
			<div className="inner_comment">
				<h4>{row.author.first_name}</h4>
				<p>{row.comment}</p>
			</div>
			<hr />
			
		</div>
		
  ))}
       
      </div>
  
    </div>

  </div>
</div>
            </div>
          </section>
          <div className="modal custom_modal fade" id="outlookModal" tabindex="-1" aria-labelledby="outlookModalLabel" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="outlookModalLabel">{this.state.isUpdating?"Update post":"Add New Post"}</h5>
                  <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                <div className="row">
                    <div className="col-md-12">
                       <label className="custom_label">Title</label>
                       <input type="text" placeholder="Post Title" className="custom_input" id="title"/>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                       <label className="custom_label">Select Type</label>
                       <div className="radio_custom">
                       <label className="radio_container">Forex
                         <input type="radio" id="radioForex" name="radio" value="1" />
                         <span className="checkmark stock_type"></span>
                       </label>
                       <label className="radio_container">Stock Market
                         <input type="radio" id="radioStock" name="radio" value="2" />
                         <span className="checkmark stock_type"></span>
                       </label>
                       <label className="radio_container">Cryptocurrency
                         <input type="radio" id="radioCryptocurrency" name="radio" value="3" />
                         <span className="checkmark stock_type"></span>
                       </label>
                       </div>
                       
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                       <label className="custom_label">Description</label>
                       <textarea placeholder="Description here.." className="custom_input" id="description"></textarea>
                    </div>
                  </div>
                  <div className="row">
                    <img id="addImage"  style={{width:'100px',display:this.state.isUpdating?'block':'none'}} />
                    <div className="col-md-12">
                       <label className="custom_label">Upload File</label>
                       <div className="password_input upload_d">
                          <input className="custom_input custom_inputImage" type="file" placeholder="Your Password"   id="image" onChange={(e)=>this.imageHandleChange(e.target.files)}/>
                          <i class="fa fa-paperclip"></i>         
                        </div>
                        <span className="upload_span">*Upload only photos, videos and voice recordings</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-8 offset-md-2 col-sm-12">
                    <a className="signin_btn" type="submit" onClick={()=>this.addOutLook()}>{this.state.isUpdating?'Update':'Save'}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer>
            <Footer />       
          </footer>
            </div>
        )
    }
}
