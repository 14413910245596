import React, { Component } from "react";
// import FacebookLogin from 'react-facebook-login';

import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import "./Login.component.css";
import Header from "../layout/header/Header.component";

import { LinkedIn, LinkedInPopUp } from "react-linkedin-login-oauth2";

import linkedin from "react-linkedin-login-oauth2/assets/linkedin.png";
import GoogleLogin from "react-google-login";

import { render } from "react-dom";
import {
  BrowserRouter as Router,
  Link,
  Route,
  Routes,
  useNavigate,
  Navigate,
} from "react-router-dom";
import Constants from "../../../constants/constants";
import axios from "axios";
import ForgotPassword from "../forgot_password/ForgotPassword.component";
import authService from "../../../services/auth.service";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { ToastContainer, toast } from "react-toastify";
import { isEmail } from "validator";
// import Modal from 'react-modal';
import { Button, Modal } from "react-bootstrap";
import Select from "react-validation/build/select";

require("dotenv").config();
export default class Employeelogin extends Component {
  state = {
    code: "",
    errorMessage: "",
    accessToken: "",
    email: "",
    socialMediaAccount: "",
    uniqueId: "",
    name: "",
    password: "",
    showPassword: false,
    country: "",
    showModal: false,
    firstName: "",
    lastName: "",
    gender: "",
    userType: "investor",
    experience: "",
    isShowFillExperience: false,
    isEmailExist: false,
    isFirstNameExist: false,
    isLastNameExist: false,
    isGenderExist: false,
    agreedTermErrorShow: false,
    agreedTerm: false,
    accessToken: "",
  };
  constructor(props) {
    super(props);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onLogin = this.onLogin.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
    this.handleShowPassword = this.handleShowPassword.bind(this);
    this.onChangeUserType = this.onChangeUserType.bind(this);
    this.onChangeFirstName = this.onChangeFirstName.bind(this);
    this.onChangeLastName = this.onChangeLastName.bind(this);
    this.onChangeGender = this.onChangeGender.bind(this);
    this.onChangeExperience = this.onChangeExperience.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.formRef = React.createRef();
    this.loginFormRef = React.createRef();
    this.checkBtnRef = React.createRef();
    this.onCheckedTerm = this.onCheckedTerm.bind(this);
  }
  componentDidMount() {
    this.getLocation();
  }

  getLocation() {
    axios
      .get("https://ipapi.co/json")
      .then((response) => {
        if (
          response.data.country_name !== "" &&
          response.data.country_name !== undefined &&
          response.data.country_name !== null
        ) {
          let country = response.data.country_name;
          this.setState({
            country: country,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  handleSuccess = (data) => {
    this.setState({
      code: data,
      errorMessage: "",
      socialMediaAccount: "linkedin",
    });

    this.socialLogin(
      this.state.socialMediaAccount,
      this.state.email,
      this.state.code
    );
  };

  handleFailure = (error) => {
    this.setState({
      code: "",
      errorMessage: error.errorMessage,
    });
  };

  onChangeEmail(e) {
    this.setState({ email: e.target.value });
  }
  onChangePassword(e) {
    this.setState({ password: e.target.value });
  }
  onChangeUserType(event) {
    this.setState({
      userType: event.currentTarget.value,
    });
  }
  onChangeFirstName(e) {
    this.setState({ firstName: e.target.value });
  }
  onChangeLastName(e) {
    this.setState({ lastName: e.target.value });
  }
  onChangeGender(e) {
    this.setState({ gender: e.target.value });
  }
  onChangeExperience(e) {
    this.setState({ experience: e.target.value });
  }
  onCheckedTerm(e) {
    if (e.target.checked === true) {
      this.setState({ agreedTerm: true });
      this.setState({ agreedTermErrorShow: false });
    } else {
      this.setState({ agreedTerm: false });
    }
  }
  onLogin(e) {
    e.preventDefault();
    let email = this.state.email;
    let password = this.state.password;
    if (email && password !== "") {
      let payload = {
        email: email,
        password: password,
      };
      authService
        .employeeLogin(payload)
        .then((response) => {
          console.log("login", response);
          if (response.status === 200) {
            localStorage.setItem("accessToken", response.data.key);
            localStorage.setItem("isSocialLogin", false);
            localStorage.setItem("userId", response.data.id);
            localStorage.setItem("userTrial", response.data.userTrial); //static
            localStorage.setItem("remainDay", response.data.remainDay); //static
            //change this to name in future 
            localStorage.setItem("name", response.data.name);

            let loginMsg = "Employee Login Successful. ";
            
            toast.success(loginMsg, {
              autoClose: 3000,
            });
            setInterval(function () {
              window.location.href = `${window.location.origin}/employee-home`;
            }, 3000);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.error, {
            autoClose: 6000,
          });
        });
    }
  }

  onUpdate(e) {
    e.preventDefault();
    //update api call
    this.formRef.current.validateAll();
    if (this.state.agreedTerm !== true) {
      this.setState({
        agreedTermErrorShow: true,
      });
    }
    if (
      this.checkBtnRef.current.context._errors.length === 0 &&
      this.state.agreedTerm === true
    ) {
      //  let payload={}
      let payload = new FormData();
      if (
        this.state.email !== "" &&
        this.state.email !== null &&
        this.state.email !== undefined
      ) {
        payload.append("email", this.state.email);
        // payload.email=this.state.email
      }
      if (
        this.state.firstName !== "" &&
        this.state.firstName !== null &&
        this.state.firstName !== undefined
      ) {
        payload.append("first_name", this.state.firstName);
        // payload.first_name=this.state.firstName
      }
      if (
        this.state.lastName !== "" &&
        this.state.lastName !== null &&
        this.state.lastName !== undefined
      ) {
        payload.append("last_name", this.state.lastName);
        // payload.last_name=this.state.lastName
      }
      if (
        this.state.userType !== "" &&
        this.state.userType !== null &&
        this.state.userType !== undefined
      ) {
        payload.append("user_type", this.state.userType);
        // payload.user_type=this.state.userType
      }
      if (
        this.state.gender !== "" &&
        this.state.gender !== null &&
        this.state.gender !== undefined
      ) {
        payload.append("gender", this.state.gender);
        // payload.gender=this.state.gender
      }
      if (
        this.state.country !== "" &&
        this.state.country !== null &&
        this.state.country !== undefined
      ) {
        payload.append("country", this.state.country);
        // payload.country=this.state.country
      }
      if (
        this.state.experience !== "" &&
        this.state.experience !== null &&
        this.state.experience !== undefined
      ) {
        payload.append("experience", this.state.experience);
        // payload.experience=this.state.experience
      }
      let id = localStorage.getItem("userId");
      authService
        .updateUser(payload, this.state.accessToken, id)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            localStorage.setItem("accessToken", this.state.accessToken);
            toast.success("Profile Update Successfull", {
              autoClose: 6000,
            });
            window.location.href = `${window.location.origin}/home`;
          }
        })
        .catch((error) => {
          if (
            error.response.data.error !== "" &&
            error.response.data.error !== undefined &&
            error.response.data.error !== null
          ) {
            toast.error(error.response.data.error, {
              autoClose: 6000,
            });
          }

          if (
            error.response.data.detail !== undefined &&
            error.response.data.detail !== null &&
            error.response.data.detail !== ""
          ) {
            if (error.response.data.detail === "Invalid token.") {
              this.setState({ accessToken: null });
              toast.error(error.response.data.detail + "Please login.", {
                autoClose: 6000,
              });
              window.location.href = `${window.location.origin}/login`;
            }
          }
        });
    }
  }

  socialLogin(login_type, email, social_id) {
    let payload = {};
    if (login_type === "linkedin") {
      payload.login_type = login_type;

      payload.social_id = social_id;
      payload.redirect_uri = `${window.location.origin}/linkedin`;
      payload.client_secret = process.env.REACT_APP_LINKEDIN_CLIENT_SECRETE;
      payload.client_id = process.env.REACT_APP_LINKEDIN_CLIENTID;
    } else {
      payload.login_type = login_type;
      payload.email = email;
      payload.social_id = social_id;
    }

    authService
      .socialLogin(payload)
      .then((response) => {
        if (response.status === 200) {
          // localStorage.setItem('accessToken', response.data.key)
          this.setState({
            accessToken: response.data.key,
          });
          localStorage.setItem("userId", response.data.id);
          localStorage.setItem("isSocialLogin", true);

          this.getUserInfo(response.data.id, response.data.key);
          // window.location.href = `${window.location.origin}/home`
        }
      })
      .catch((error) => {
        toast.error(error.response.data.error, {
          autoClose: 6000,
        });
      });
  }
  getUserInfo(id, accessToken) {
    authService
      .getUser(accessToken, id)
      .then((response) => {
        if (
          response.data.country !== null &&
          response.data.country !== "" &&
          response.data.country !== undefined
        ) {
          this.setState({
            country: response.data.country,
          });
        }
        if (
          response.data.first_name !== null &&
          response.data.first_name !== "" &&
          response.data.first_name !== undefined
        ) {
          this.setState({
            firstName: response.data.first_name,
            isFirstNameExist: true,
          });
        }

        if (
          response.data.last_name !== null &&
          response.data.last_name !== "" &&
          response.data.last_name !== undefined
        ) {
          this.setState({
            lastName: response.data.last_name,
            isLastNameExist: true,
          });
        }

        if (
          response.data.gender !== null &&
          response.data.gender !== "" &&
          response.data.gender !== undefined
        ) {
          this.setState({
            gender: response.data.gender,
          });
        }

        if (
          response.data.user_type !== null &&
          response.data.user_type !== "" &&
          response.data.user_type !== undefined
        ) {
          this.setState({
            userType: response.data.user_type,
          });
        }

        if (
          response.data.email !== null &&
          response.data.email !== "" &&
          response.data.email !== undefined
        ) {
          this.setState({
            email: response.data.email,
            isEmailExist: true,
          });
        }

        if (
          response.data.experience !== null &&
          response.data.experience !== "" &&
          response.data.experience !== undefined
        ) {
          this.setState({
            experience: response.data.experience,
          });
        }

        if (
          response.data.first_name !== null &&
          response.data.first_name !== "" &&
          response.data.first_name !== undefined &&
          response.data.gender !== null &&
          response.data.gender !== "" &&
          response.data.gender !== undefined &&
          response.data.user_type !== null &&
          response.data.user_type !== "" &&
          response.data.user_type !== undefined &&
          response.data.email !== null &&
          response.data.email !== "" &&
          response.data.email !== undefined
        ) {
          localStorage.setItem("accessToken", this.state.accessToken);
          toast.success("Login Successful.", {
            autoClose: 6000,
          });
          window.location.href = `${window.location.origin}/home`;
        } else {
          toast.success("Login Successful . Please complete registration.", {
            autoClose: 6000,
          });
          this.setState({
            showModal: true,
          });
        }
      })
      .catch((error) => {
        if (
          error.response.data.error !== "" &&
          error.response.data.error !== undefined &&
          error.response.data.error !== null
        ) {
          toast.error(error.response.data.error, {
            autoClose: 6000,
          });
        }

        if (
          error.response.data.detail !== undefined &&
          error.response.data.detail !== null &&
          error.response.data.detail !== ""
        ) {
          if (error.response.data.detail === "Invalid token.") {
            this.setState({ accessToken: null });
            toast.error(error.response.data.detail + "Please login.", {
              autoClose: 6000,
            });
            window.location.href = `${window.location.origin}/login`;
          }
        }
      });
  }

  handleShowPassword() {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  }
  closeModal() {
    this.setState({
      showModal: false,
    });
  }
  render() {
    const required = (value) => {
      if (!value) {
        return <p style={{ color: "red" }}>This field is required</p>;
      }
    };

    const email = (value) => {
      if (!isEmail(value)) {
        return <p style={{ color: "red" }}>{value} is not a valid email.</p>;
      }
    };
    const checkPassword = (value) => {
      if (value.toString().trim().length < 8) {
        return (
          <p style={{ color: "red" }}>
            Password Should contain minimum 8 characters.
          </p>
        );
      } else {
        var regularExpression = Constants.PASSWORD_VALIDATION_REGEX;
        let result = regularExpression.test(value);
        if (result === false) {
          return (
            <p style={{ color: "red" }}>
              Password must have atleast one digit,one special character like
              $,@,%,#,-,_,^,!, one small letter and one capital letter.
            </p>
          );
        }
      }
    };

    const responseFacebook = (response) => {
      if (response.error) {
        this.setState({
          errorMessage: response.error.message,
        });
      } else if (response.accessToken) {
        this.setState({
          accessToken: response.accessToken,
          socialMediaAccount: "facebook",
          uniqueId: response.userID,
        });
        if (
          response.email !== undefined &&
          response.email !== null &&
          response.email !== ""
        ) {
          this.setState({ email: response.email });
        }
        if (
          response.name !== undefined &&
          response.name !== null &&
          response.name !== ""
        ) {
          this.setState({ name: response.name });
          let fullName = this.state.name.split(" ");

          this.setState({
            firstName: fullName[0],
            isFirstNameExist: true,
          });
          if (fullName.length > 1) {
            this.setState({
              lastName: fullName[fullName.length - 1],
              isLastNameExist: true,
            });
          }
        }
        this.socialLogin(
          this.state.socialMediaAccount,
          this.state.email,
          this.state.uniqueId
        );
      }
    };

    const responseGoogle = (response) => {
      if (response.error) {
        this.setState({
          errorMessage: response.error,
        });
      } else if (response.accessToken) {
        this.setState({
          accessToken: response.accessToken,
          socialMediaAccount: "google",
          uniqueId: response.googleId,
        });
        if (
          response.profileObj.email !== undefined &&
          response.profileObj.email !== null &&
          response.profileObj.email !== ""
        ) {
          this.setState({ email: response.profileObj.email });
        }
        if (
          response.profileObj.name !== undefined &&
          response.profileObj.name !== null &&
          response.profileObj.name !== ""
        ) {
          this.setState({ name: response.profileObj.name });

          let fullName = this.state.name.split(" ");

          this.setState({
            firstName: fullName[0],
            isFirstNameExist: true,
          });
          if (fullName.length > 1) {
            this.setState({
              lastName: fullName[fullName.length - 1],
              isLastNameExist: true,
            });
          }
        }
        this.socialLogin(
          this.state.socialMediaAccount,
          this.state.email,
          this.state.uniqueId
        );
      }
    };

    return (
      <div>
        <header>
          <div className="custom_container">
            <div className="row">
              <div className="col-md-12">
                <Header />
              </div>
            </div>
          </div>
        </header>

        <section>
          <div className="custom_container">
            <div className="row">
              <div className="col-md-12">
                <div className="login_outer">
                  <div className="row">
                    <div className="col-md-6 offset-md-3 col-sm-12">
                      <Form onSubmit={this.onLogin} ref={this.loginFormRef}>
                        <div className="login_inner">
                          <div className="sign_in_title">Sign In</div>
                          <label className="custom_label">Email Address</label>
                          <Input
                            className="custom_input"
                            type="text"
                            placeholder="Email Address"
                            value={this.state.email}
                            onChange={this.onChangeEmail}
                            validations={[required, email]}
                          />

                          <label className="custom_label">Password</label>
                          <div className="password_input">
                            <Input
                              className="custom_input"
                              type={
                                this.state.showPassword ? "text" : "password"
                              }
                              placeholder="Your Password"
                              value={this.state.password}
                              onChange={this.onChangePassword}
                              validations={[required, checkPassword]}
                            />
                            {this.state.showPassword == false ? (
                              <i
                                className="fal fa-eye"
                                onClick={this.handleShowPassword}
                              ></i>
                            ) : (
                              <i
                                className="fal fa-eye-slash"
                                onClick={this.handleShowPassword}
                              ></i>
                            )}
                          </div>
                          <div className="remember_outer">
                            <div className="remember_left">
                              <label className="remebercontainer">
                                Remember me
                                <input type="checkbox" />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                            <div className="remember_right">
                              <Link className="a_forgot" to={"/forgotpassword"}>
                                Forgot password?
                              </Link>
                            </div>
                          </div>
                          {/* <a className="signin_btn" type="submit" onClick={this.onLogin}>Sign In</a> */}
                          <button
                            className="signin_btn"
                            type="submit"
                            onClick={this.onLogin}
                          >
                            Sign In
                          </button>
                          <div className="not_account">
                            Don't have account? &nbsp;
                            <a className="a_forgot" href="/signup">
                              Create new account
                            </a>
                          </div>
                          <div className="or_outer">
                            <span className="or_span">OR</span>
                            <div className="not_account">
                            <a className="a_forgot" href="/employee_login">
                              Employee Login
                            </a>
                          </div>

                            <hr />

                          </div>

                          

                          <ul className="login_ul">
                            <li>
                              {" "}
                              <GoogleLogin
                                clientId={process.env.REACT_APP_GOOGLE_CLIENTID}
                                render={(renderProps) => (
                                  <a href="#">
                                    <i
                                      className="fab fa-google-plus-g"
                                      onClick={renderProps.onClick}
                                    ></i>
                                  </a>
                                )}
                                onSuccess={responseGoogle}
                                onFailure={responseGoogle}
                              />
                            </li>

                            <li>
                              <FacebookLogin
                                appId={process.env.REACt_APP_FACEBOOK_APPID} //APP ID NOT CREATED YET
                                fields="name,email,picture"
                                callback={responseFacebook}
                                render={(renderProps) => (
                                  <a href="#">
                                    <i
                                      className="fab fa-facebook-f"
                                      onClick={renderProps.onClick}
                                    ></i>
                                  </a>
                                )}
                              />
                            </li>

                            <li>
                              <LinkedIn
                                clientId={
                                  process.env.REACT_APP_LINKEDIN_CLIENTID
                                }
                                redirectUri={`${window.location.origin}/linkedin`}
                                onFailure={this.handleFailure}
                                onSuccess={this.handleSuccess}
                                scope="r_liteprofile r_emailaddress"
                                state="DCEeFWf45A53sdfKef424"
                              >
                                {({ linkedInLogin }) => (
                                  <li>
                                    <a href="#">
                                      <i
                                        className="fab fa-linkedin-in"
                                        onClick={linkedInLogin}
                                      ></i>
                                    </a>
                                  </li>
                                )}
                              </LinkedIn>
                            </li>
                          </ul>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>

                <Modal
                  show={this.state.showModal}
                  onHide={this.closeModal}
                  backdrop="static"
                  className="custom_modal"
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Enter Details:</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div>
                      <Form ref={this.formRef} onSubmit={this.onUpdate}>
                        <div className="row">
                          <div className="col-md-6">
                            <label className="custom_label">
                              Email Address
                            </label>
                            <Input
                              className="custom_input"
                              type="text"
                              placeholder="Email Address"
                              value={this.state.email}
                              onChange={this.onChangeEmail}
                              validations={[required, email]}
                              readOnly={
                                this.state.isEmailExist === false ? false : true
                              }
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-6">
                                <label className="custom_label">
                                  First Name
                                </label>
                                <Input
                                  className="custom_input"
                                  type="text"
                                  placeholder="First Name"
                                  value={this.state.firstName}
                                  onChange={this.onChangeFirstName}
                                  validations={[required]}
                                  readOnly={
                                    this.state.isFirstNameExist === false
                                      ? false
                                      : true
                                  }
                                />
                              </div>

                              <div className="col-md-6">
                                <label className="custom_label">
                                  Last Name
                                </label>
                                <Input
                                  className="custom_input"
                                  type="text"
                                  placeholder="Last Name"
                                  value={this.state.lastName}
                                  onChange={this.onChangeLastName}
                                  readOnly={
                                    this.state.isLastNameExist === false
                                      ? false
                                      : true
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <label className="custom_label">I am</label>
                            <div className="radio_custom">
                              <label className="radio_container">
                                Investor
                                <input
                                  type="radio"
                                  checked="checked"
                                  name="radio"
                                  value="investor"
                                  onChange={this.onChangeUserType}
                                  checked={this.state.userType === "investor"}
                                />
                                <span className="checkmark"></span>
                              </label>
                              <label className="radio_container">
                                Trader
                                <input
                                  type="radio"
                                  checked="checked"
                                  name="radio"
                                  value="trader"
                                  onChange={this.onChangeUserType}
                                  checked={this.state.userType === "trader"}
                                />
                                <span className="checkmark"></span>
                              </label>
                              <label className="radio_container">
                                Technical
                                <input
                                  type="radio"
                                  checked="checked"
                                  name="radio"
                                  value="technical"
                                  onChange={this.onChangeUserType}
                                  checked={this.state.userType === "technical"}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <label className="custom_label">Gender</label>
                            <Select
                              className="custom_input"
                              value={this.state.gender}
                              onChange={this.onChangeGender}
                            >
                              <option disabled value={""}>
                                Select gender
                              </option>
                              <option value="male">Male</option>
                              <option value="female">Female</option>
                              <option value="other">Other</option>
                            </Select>
                          </div>
                          {this.state.userType !== "investor" ? (
                            <div className="col-md-6">
                              <label className="custom_label">Experience</label>
                              <Select
                                className="custom_input"
                                value={this.state.experience}
                                validations={[required]}
                                onChange={this.onChangeExperience}
                              >
                                <option disabled value={""}>
                                  Years of experience
                                </option>
                                <option value={"0-1"}>0 month to 1 year</option>
                                <option value={"1-5"}>1 to 5 years</option>
                                <option value={"5-10"}>5 to 10 years</option>
                                <option value={"10-15"}>10 to 15 years</option>
                                <option value={"15-20"}>
                                  15 month to 20 years
                                </option>
                                <option value={"20-30"}>
                                  More than 20 years
                                </option>
                              </Select>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="remember_outer">
                          <div className="remember_left">
                            <label className="remebercontainer">
                              I have read and agree to the{" "}
                              <Link to={"/terms"}>Terms of Use</Link> as well as{" "}
                              <Link to={"/privacy"}>Privacy Policy</Link>
                              <input
                                type="checkbox"
                                onChange={this.onCheckedTerm}
                                checked={this.state.agreedTerm}
                              />
                              <span className="checkmark"></span>
                            </label>

                            {this.state.agreedTermErrorShow ? (
                              <p style={{ color: "red" }}>
                                Please accept term and conditions to move
                                forward.
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <CheckButton
                          style={{ display: "none" }}
                          ref={this.checkBtnRef}
                        />
                      </Form>
                    </div>
                  </Modal.Body>

                  <Modal.Footer>
                    <Button variant="secondary" onClick={this.closeModal}>
                      Close
                    </Button>
                    <Button
                      variant="primary"
                      type="submit"
                      onClick={this.onUpdate}
                    >
                      Submit
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
