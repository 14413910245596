import React, { Component } from "react";
import Footer from "../layout/footer/Footer.component";
import Header from "../layout/header/Header.component";
import './live-stream.component.css'
import Book from '../../../assests/book.jpg';
import Book1 from '../../../assests/book1.jpg';
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import io from 'socket.io-client';
import constants from "../../../constants/constants";
let imgPath="../../../assests/"; 



export default class MyStreams extends Component {

  constructor(props) {

    super(props);

    this.state = {
        title:'',
        cover_photo: null,
        books: null,
        author_name:'',
        book_type:'',
        selectedFile: null,
		myStreamsList:[]
    };
	
	this.loadMyStreams = this.loadMyStreams.bind(this);
	this.loadMyStreams();

  }
  

	loadMyStreams() {

		let accessToekn = localStorage.getItem('accessToken');
		var authData = 'Token ' + accessToekn;
	
		axios
		  .get(constants.API_URL+`/stream/`, {
			headers: {
			  "Content-Type": "application/json",
			  Authorization: authData
			}
		  })
		  .then((response) => {
			this.setState({ myStreamsList: response.data.data });
			console.log("response is "+JSON.stringify(this.state.myStreamsList));
	
		   
		  }).catch((error) => {

			console.log("error", JSON.stringify(this.state.myStreamsList));
		  });
	  }
    deleteStream(stream_id)
    {
        if(!window.confirm("Are you sure to delete this stream ?"))
        {
          return;
        }
        let accessToekn = localStorage.getItem('accessToken');
        var authData = 'Token ' + accessToekn;
      
        axios
        .delete(constants.API_URL+'/stream/'+stream_id, {
        headers: {
          "Content-Type": "application/json",
          Authorization: authData
        }
        })
        .then((response) => {
          alert("Stream deleted successfully");
          this.loadMyStreams();
         
        }).catch((error) => {
          alert("Unable to delete stream, Plase try after some time.");
        });

    }




    render() {
    const listItems = this.state.myStreamsList.map((item, index) => (
<div className="col-md-4">
<div className="strem_outer1">
	<img src={item.cover} className="img_res_new"/>
	<div className="fact57">
	<h5>{item.title}</h5>
	<p>{item.short_description} </p>
	<p>{item.long_description} </p>

	<div className="soicao_outer">
		<p><b>Stream Date:</b>{item.stream_date}</p>
		<h5><a href={"/community?stream="+item.id}><i className="fa fa-camera" aria-hidden="true"></i></a></h5>
	<ul>
	<li><a href={item.twitter}><i className="fa-brands fa-twitter-square"></i></a></li>
	<li><a href={item.youtube}><i className="fa-brands fa-youtube-square"></i></a></li>
	<li className="bg_color_website"><a href={item.website}><i className="fa-brands fa-internet-explorer text-dark internet_font_style"></i></a></li> 
	<li className="delete9"><a href="javascript:void(0)" onClick={()=>this.deleteStream(item.id)}><i className="fa-solid fa-trash"></i></a></li>
	<li className="edit9"><a href={"create-stream?stream="+item.id}><i className="fa fa-pencil" aria-hidden="true"></i></a></li>
	</ul>
	</div>
		</div>
</div>
</div>
    ))
        return (
            <div>
          <header>
            <div className="custom_container">
              <div className="row">
                <div className="col-md-12">
                  <Header />
                </div>
              </div>
            </div>
          </header>

          <section className="banner_outer news_banner">
            <div className="overlay"></div>
                <div className="custom_container">
                <div className="row" >
                    <div className="col-md-8 offset-md-2 col-sm-12 first_div text-center">
                      <div class="banner_content">
                        <h2>My Streams</h2>
                         <hr class="banner_hr news_hr" /><h3>
                         <ul className="news_ul">
                           <li><Link to={"/home"} >Home</Link></li>
                           <li><i class="fa fa-angle-right"></i></li>
                           <li><Link to={"/my-streams"} className="active" >My Streams</Link></li>
                         </ul>
                        </h3>
                      </div>
                    </div>
                </div>
            </div>
          </section>
		 
        
     
        
         <section class="table_outer">
			<div class="container">
			
			</div>
		 </section>
  <section className="create_stem">
			<div className="container">
				<div className="row">
					
				{listItems}
								
				</div>
				
			</div>
		 </section>
          <footer>
            <Footer />       
          </footer>
            </div>
        )
    }
}
