import React, { Component } from "react";
import "./LiveRates.component.css";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { connect } from "react-redux";
import { getStockValue } from "../../../services/actions/actions"; 
import { isThisISOWeek } from "date-fns";
import HCStock from "highcharts/modules/stock"; 
import HCDragPanes from "highcharts/modules/drag-panes";
//import HCTools from "./stock-tools"; 
import HCTools from "highcharts/modules/update-stock-tool";
import HCAnnot from "highcharts/modules/annotations";
import HCAnnotAdv from "highcharts/modules/annotations-advanced";
import HCPriceInd from "highcharts/modules/price-indicator";
import HCFullScreen from "highcharts/modules/full-screen";
import HCArrows from "highcharts/modules/arrow-symbols"; 
import HCBoost from "highcharts/modules/boost";
import HCBoostCanvas from "highcharts/modules/boost-canvas";
import HCData from "highcharts/modules/data";
import highchartsMore from 'highcharts/highcharts-more';
import CustomEvents from "highcharts-custom-events";
import reactCSS from 'reactcss';
import { SketchPicker, AlphaPicker , ChromePicker  } from 'react-color';
import $ from 'jquery';

import { array, func } from "prop-types";
import { id } from "date-fns/locale";
import { chart } from "highcharts";
HCAnnot(Highcharts);
HCAnnotAdv(Highcharts);
HCStock(Highcharts);
HCDragPanes(Highcharts);
HCTools(Highcharts);
HCPriceInd(Highcharts);
HCFullScreen(Highcharts);
HCArrows(Highcharts);
HCBoost(Highcharts);
HCBoostCanvas(Highcharts);
HCData(Highcharts);
highchartsMore(Highcharts);

require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);
// Load Highcharts modules
require('highcharts/indicators/indicators')(Highcharts)
require('highcharts/indicators/indicators-all')(Highcharts)
require('highcharts/indicators/ema')(Highcharts)
// require('highcharts/indicators/sma')(Highcharts) 
require('highcharts/indicators/zigzag')(Highcharts)
require('highcharts/indicators/dema')(Highcharts)
require('highcharts/indicators/rsi')(Highcharts)
require('highcharts/indicators/atr')(Highcharts)
require('highcharts/indicators/trendline')(Highcharts)
require('highcharts/indicators/pivot-points')(Highcharts)
require('highcharts/indicators/macd')(Highcharts)
require('highcharts/indicators/acceleration-bands')(Highcharts)
require('highcharts/indicators/heikinashi')(Highcharts)
require('highcharts/indicators/bollinger-bands')(Highcharts)
require('highcharts/indicators/ichimoku-kinko-hyo')(Highcharts)
require('highcharts/indicators/accumulation-distribution')(Highcharts)

require('highcharts/modules/accessibility')(Highcharts)
require('highcharts/modules/map')(Highcharts)
require("highcharts/modules/draggable-points")(Highcharts);
CustomEvents(Highcharts); 


// $(document).on('click','.highcharts-toggle-toolbar',function(){
//   alert('111')
// })

// require("highcharts/modules/annotations")(Highcharts);
class CandleChart extends Component {
  constructor(props) {
    super(props); 
    this.state = {
      stockData: [],
      volume: [],
      stockLoad: false,
      randomNumber:0,
      update_indicator_random:0,
      stockError: "",
      all_indicator_data:[],
      candleDown: "#F83535",
      candleup: "#6FCC5B",
      backgroundColor: "#F3D95C",
      hignlinevalue: "",
      lowerlinevalue: "",
      showHighLowerLine: false,
      allData: [],
      lastLineValue: "",
      background_color: 'rgba(241 ,112 , 19 ,1 )',
      backgroundColor:"#FFFFFF",
      lineColor:"#000000",
      hignlinevalue:"",
      bearBullArray:[],
      lowerlinevalue:"",
      showModal:false,
      background: '#fff',
      dateArray:[],
      displayColorPicker: false,
      displayCrossHairColorPicker:false,
      fontSize:2,
      seriesData:[],
      initialTime:1647963362298,
      seriArr:[],
      lock:true,
      showRandom:0,
      isIndicatorDivDisplay:false,
      color: {
        r: '241',
        g: '112',
        b: '19',
        a: '1',
      },
      showalert:false,
      icons: [
          'fa-500px',
      'fa-address-book',
      'fa-address-book-o',
      'fa-address-card',
      'fa-address-card-o',
      'fa-adjust',
      'fa-adn',
      'fa-align-center',
      'fa-align-justify',
      'fa-align-left',
      'fa-align-right',
      'fa-amazon',
      'fa-ambulance',
      'fa-american-sign-language-interpreting',
      'fa-anchor',
      'fa-android',
      'fa-angellist',
      'fa-angle-double-down',
      'fa-angle-double-left',
      'fa-angle-double-right',
      'fa-angle-double-up',
      'fa-angle-down',
      'fa-angle-left',
      'fa-angle-right',
      'fa-angle-up',
      'fa-apple',
      'fa-archive',
      'fa-area-chart',
      'fa-arrow-circle-down',
      'fa-arrow-circle-left',
      'fa-arrow-circle-o-down',
      'fa-arrow-circle-o-left',
      'fa-arrow-circle-o-right',
      'fa-arrow-circle-o-up',
      'fa-arrow-circle-right',
      'fa-arrow-circle-up',
      'fa-arrow-down',
      'fa-arrow-left',
      'fa-arrow-right',
      'fa-arrow-up',
      'fa-arrows',
      'fa-arrows-alt',
      'fa-arrows-h',
      'fa-arrows-v',
      'fa-asl-interpreting',
      'fa-assistive-listening-systems',
      'fa-asterisk',
      'fa-at',
      'fa-audio-description',
      'fa-automobile',
      'fa-backward',
      'fa-balance-scale',
      'fa-ban',
      'fa-bandcamp',
      'fa-bank',
      'fa-bar-chart',
      'fa-bar-chart-o',
      'fa-barcode',
      'fa-bars',
      'fa-bath',
      'fa-bathtub',
      'fa-battery',
      'fa-battery-0',
      'fa-battery-1',
      'fa-battery-2',
      'fa-battery-3',
      'fa-battery-4',
      'fa-battery-empty',
      'fa-battery-full',
      'fa-battery-half',
      'fa-battery-quarter',
      'fa-battery-three-quarters',
      'fa-bed',
      'fa-beer',
      'fa-behance',
      'fa-behance-square',
      'fa-bell',
      'fa-bell-o',
      'fa-bell-slash',
      'fa-bell-slash-o',
      'fa-bicycle',
      'fa-binoculars',
      'fa-birthday-cake',
      'fa-bitbucket',
      'fa-bitbucket-square',
      'fa-bitcoin',
      'fa-black-tie',
      'fa-blind',
      'fa-bluetooth',
      'fa-bluetooth-b',
      'fa-bold',
      'fa-bolt',
      'fa-bomb',
      'fa-book',
      'fa-bookmark',
      'fa-bookmark-o',
      'fa-braille',
      'fa-briefcase',
      'fa-btc',
      'fa-bug',
      'fa-building',
      'fa-building-o',
      'fa-bullhorn',
      'fa-bullseye',
      'fa-bus',
      'fa-buysellads',
      'fa-cab',
      'fa-calculator',
      'fa-calendar',
      'fa-calendar-check-o',
      'fa-calendar-minus-o',
      'fa-calendar-o',
      'fa-calendar-plus-o',
      'fa-calendar-times-o',
      'fa-camera',
      'fa-camera-retro',
      'fa-car',
      'fa-caret-down',
      'fa-caret-left',
      'fa-caret-right',
      'fa-caret-square-o-down',
      'fa-caret-square-o-left',
      'fa-caret-square-o-right',
      'fa-caret-square-o-up',
      'fa-caret-up',
      'fa-cart-arrow-down',
      'fa-cart-plus',
      'fa-cc',
      'fa-cc-amex',
      'fa-cc-diners-club',
      'fa-cc-discover',
      'fa-cc-jcb',
      'fa-cc-mastercard',
      'fa-cc-paypal',
      'fa-cc-stripe',
      'fa-cc-visa',
      'fa-certificate',
      'fa-chain',
      'fa-chain-broken',
      'fa-check',
      'fa-check-circle',
      'fa-check-circle-o',
      'fa-check-square',
      'fa-check-square-o',
      'fa-chevron-circle-down',
      'fa-chevron-circle-left',
      'fa-chevron-circle-right',
      'fa-chevron-circle-up',
      'fa-chevron-down',
      'fa-chevron-left',
      'fa-chevron-right',
      'fa-chevron-up',
      'fa-child',
      'fa-chrome',
      'fa-circle',
      'fa-circle-o',
      'fa-circle-o-notch',
      'fa-circle-thin',
      'fa-clipboard',
      'fa-clock-o',
      'fa-clone',
      'fa-close',
      'fa-cloud',
      'fa-cloud-download',
      'fa-cloud-upload',
      'fa-cny',
      'fa-code',
      'fa-code-fork',
      'fa-codepen',
      'fa-codiepie',
      'fa-coffee',
      'fa-cog',
      'fa-cogs',
      'fa-columns',
      'fa-comment',
      'fa-comment-o',
      'fa-commenting',
      'fa-commenting-o',
      'fa-comments',
      'fa-comments-o',
      'fa-compass',
      'fa-compress',
      'fa-connectdevelop',
      'fa-contao',
      'fa-copy',
      'fa-copyright',
      'fa-creative-commons',
      'fa-credit-card',
      'fa-credit-card-alt',
      'fa-crop',
      'fa-crosshairs',
      'fa-css3',
      'fa-cube',
      'fa-cubes',
      'fa-cut',
      'fa-cutlery',
      'fa-dashboard',
      'fa-dashcube',
      'fa-database',
      'fa-deaf',
      'fa-deafness',
      'fa-dedent',
      'fa-delicious',
      'fa-desktop',
      'fa-deviantart',
      'fa-diamond',
      'fa-digg',
      'fa-dollar',
      'fa-dot-circle-o',
      'fa-download',
      'fa-dribbble',
      'fa-drivers-license',
      'fa-drivers-license-o',
      'fa-dropbox',
      'fa-drupal',
      'fa-edge',
      'fa-edit',
      'fa-eercast',
      'fa-eject',
      'fa-ellipsis-h',
      'fa-ellipsis-v',
      'fa-empire',
      'fa-envelope',
      'fa-envelope-o',
      'fa-envelope-open',
      'fa-envelope-open-o',
      'fa-envelope-square',
      'fa-envira',
      'fa-eraser',
      'fa-etsy',
      'fa-eur',
      'fa-euro',
      'fa-exchange',
      'fa-exclamation',
      'fa-exclamation-circle',
      'fa-exclamation-triangle',
      'fa-expand',
      'fa-expeditedssl',
      'fa-external-link',
      'fa-external-link-square',
      'fa-eye',
      'fa-eye-slash',
      'fa-eyedropper',
      'fa-fa',
      'fa-facebook',
      'fa-facebook-f',
      'fa-facebook-official',
      'fa-facebook-square',
      'fa-fast-backward',
      'fa-fast-forward',
      'fa-fax',
      'fa-feed',
      'fa-female',
      'fa-fighter-jet',
      'fa-file',
      'fa-file-archive-o',
      'fa-file-audio-o',
      'fa-file-code-o',
      'fa-file-excel-o',
      'fa-file-image-o',
      'fa-file-movie-o',
      'fa-file-o',
      'fa-file-pdf-o',
      'fa-file-photo-o',
      'fa-file-picture-o',
      'fa-file-powerpoint-o',
      'fa-file-sound-o',
      'fa-file-text',
      'fa-file-text-o',
      'fa-file-video-o',
      'fa-file-word-o',
      'fa-file-zip-o',
      'fa-files-o',
      'fa-film',
      'fa-filter',
      'fa-fire',
      'fa-fire-extinguisher',
      'fa-firefox',
      'fa-first-order',
      'fa-flag',
      'fa-flag-checkered',
      'fa-flag-o',
      'fa-flash',
      'fa-flask',
      'fa-flickr',
      'fa-floppy-o',
      'fa-folder',
      'fa-folder-o',
      'fa-folder-open',
      'fa-folder-open-o',
      'fa-font',
      'fa-font-awesome',
      'fa-fonticons',
      'fa-fort-awesome',
      'fa-forumbee',
      'fa-forward',
      'fa-foursquare',
      'fa-free-code-camp',
      'fa-frown-o',
      'fa-futbol-o',
      'fa-gamepad',
      'fa-gavel',
      'fa-gbp',
      'fa-ge',
      'fa-gear',
      'fa-gears',
      'fa-genderless',
      'fa-get-pocket',
      'fa-gg',
      'fa-gg-circle',
      'fa-gift',
      'fa-git',
      'fa-git-square',
      'fa-github',
      'fa-github-alt',
      'fa-github-square',
      'fa-gitlab',
      'fa-gittip',
      'fa-glass',
      'fa-glide',
      'fa-glide-g',
      'fa-globe',
      'fa-google',
      'fa-google-plus',
      'fa-google-plus-circle',
      'fa-google-plus-official',
      'fa-google-plus-square',
      'fa-google-wallet',
      'fa-graduation-cap',
      'fa-gratipay',
      'fa-grav',
      'fa-group',
      'fa-h-square',
      'fa-hacker-news',
      'fa-hand-grab-o',
      'fa-hand-lizard-o',
      'fa-hand-o-down',
      'fa-hand-o-left',
      'fa-hand-o-right',
      'fa-hand-o-up',
      'fa-hand-paper-o',
      'fa-hand-peace-o',
      'fa-hand-pointer-o',
      'fa-hand-rock-o',
      'fa-hand-scissors-o',
      'fa-hand-spock-o',
      'fa-hand-stop-o',
      'fa-handshake-o',
      'fa-hard-of-hearing',
      'fa-hashtag',
      'fa-hdd-o',
      'fa-header',
      'fa-headphones',
      'fa-heart',
      'fa-heart-o',
      'fa-heartbeat',
      'fa-history',
      'fa-home',
      'fa-hospital-o',
      'fa-hotel',
      'fa-hourglass',
      'fa-hourglass-1',
      'fa-hourglass-2',
      'fa-hourglass-3',
      'fa-hourglass-end',
      'fa-hourglass-half',
      'fa-hourglass-o',
      'fa-hourglass-start',
      'fa-houzz',
      'fa-html5',
      'fa-i-cursor',
      'fa-id-badge',
      'fa-id-card',
      'fa-id-card-o',
      'fa-ils',
      'fa-image',
      'fa-imdb',
      'fa-inbox',
      'fa-indent',
      'fa-industry',
      'fa-info',
      'fa-info-circle',
      'fa-inr',
      'fa-instagram',
      'fa-institution',
      'fa-internet-explorer',
      'fa-intersex',
      'fa-ioxhost',
      'fa-italic',
      'fa-joomla',
      'fa-jpy',
      'fa-jsfiddle',
      'fa-key',
      'fa-keyboard-o',
      'fa-krw',
      'fa-language',
      'fa-laptop',
      'fa-lastfm',
      'fa-lastfm-square',
      'fa-leaf',
      'fa-leanpub',
      'fa-legal',
      'fa-lemon-o',
      'fa-level-down',
      'fa-level-up',
      'fa-life-bouy',
      'fa-life-buoy',
      'fa-life-ring',
      'fa-life-saver',
      'fa-lightbulb-o',
      'fa-line-chart',
      'fa-link',
      'fa-linkedin',
      'fa-linkedin-square',
      'fa-linode',
      'fa-linux',
      'fa-list',
      'fa-list-alt',
      'fa-list-ol',
      'fa-list-ul',
      'fa-location-arrow',
      'fa-lock',
      'fa-long-arrow-down',
      'fa-long-arrow-left',
      'fa-long-arrow-right',
      'fa-long-arrow-up',
      'fa-low-vision',
      'fa-magic',
      'fa-magnet',
      'fa-mail-forward',
      'fa-mail-reply',
      'fa-mail-reply-all',
      'fa-male',
      'fa-map',
      'fa-map-marker',
      'fa-map-o',
      'fa-map-pin',
      'fa-map-signs',
      'fa-mars',
      'fa-mars-double',
      'fa-mars-stroke',
      'fa-mars-stroke-h',
      'fa-mars-stroke-v',
      'fa-maxcdn',
      'fa-meanpath',
      'fa-medium',
      'fa-medkit',
      'fa-meetup',
      'fa-meh-o',
      'fa-mercury',
      'fa-microchip',
      'fa-microphone',
      'fa-microphone-slash',
      'fa-minus',
      'fa-minus-circle',
      'fa-minus-square',
      'fa-minus-square-o',
      'fa-mixcloud',
      'fa-mobile',
      'fa-mobile-phone',
      'fa-modx',
      'fa-money',
      'fa-moon-o',
      'fa-mortar-board',
      'fa-motorcycle',
      'fa-mouse-pointer',
      'fa-music',
      'fa-navicon',
      'fa-neuter',
      'fa-newspaper-o',
      'fa-object-group',
      'fa-object-ungroup',
      'fa-odnoklassniki',
      'fa-odnoklassniki-square',
      'fa-opencart',
      'fa-openid',
      'fa-opera',
      'fa-optin-monster',
      'fa-outdent',
      'fa-pagelines',
      'fa-paint-brush',
      'fa-paper-plane',
      'fa-paper-plane-o',
      'fa-paperclip',
      'fa-paragraph',
      'fa-paste',
      'fa-pause',
      'fa-pause-circle',
      'fa-pause-circle-o',
      'fa-paw',
      'fa-paypal',
      'fa-pencil',
      'fa-pencil-square',
      'fa-pencil-square-o',
      'fa-percent',
      'fa-phone',
      'fa-phone-square',
      'fa-photo',
      'fa-picture-o',
      'fa-pie-chart',
      'fa-pied-piper',
      'fa-pied-piper-alt',
      'fa-pied-piper-pp',
      'fa-pinterest',
      'fa-pinterest-p',
      'fa-pinterest-square',
      'fa-plane',
      'fa-play',
      'fa-play-circle',
      'fa-play-circle-o',
      'fa-plug',
      'fa-plus',
      'fa-plus-circle',
      'fa-plus-square',
      'fa-plus-square-o',
      'fa-podcast',
      'fa-power-off',
      'fa-print',
      'fa-product-hunt',
      'fa-puzzle-piece',
      'fa-qq',
      'fa-qrcode',
      'fa-question',
      'fa-question-circle',
      'fa-question-circle-o',
      'fa-quora',
      'fa-quote-left',
      'fa-quote-right',
      'fa-ra',
      'fa-random',
      'fa-ravelry',
      'fa-rebel',
      'fa-recycle',
      'fa-reddit',
      'fa-reddit-alien',
      'fa-reddit-square',
      'fa-refresh',
      'fa-registered',
      'fa-remove',
      'fa-renren',
      'fa-reorder',
      'fa-repeat',
      'fa-reply',
      'fa-reply-all',
      'fa-resistance',
      'fa-retweet',
      'fa-rmb',
      'fa-road',
      'fa-rocket',
      'fa-rotate-left',
      'fa-rotate-right',
      'fa-rouble',
      'fa-rss',
      'fa-rss-square',
      'fa-rub',
      'fa-ruble',
      'fa-rupee',
      'fa-s15',
      'fa-safari',
      'fa-save',
      'fa-scissors',
      'fa-scribd',
      'fa-search',
      'fa-search-minus',
      'fa-search-plus',
      'fa-sellsy',
      'fa-send',
      'fa-send-o',
      'fa-server',
      'fa-share',
      'fa-share-alt',
      'fa-share-alt-square',
      'fa-share-square',
      'fa-share-square-o',
      'fa-shekel',
      'fa-sheqel',
      'fa-shield',
      'fa-ship',
      'fa-shirtsinbulk',
      'fa-shopping-bag',
      'fa-shopping-basket',
      'fa-shopping-cart',
      'fa-shower',
      'fa-sign-in',
      'fa-sign-language',
      'fa-sign-out',
      'fa-signal',
      'fa-signing',
      'fa-simplybuilt',
      'fa-sitemap',
      'fa-skyatlas',
      'fa-skype',
      'fa-slack',
      'fa-sliders',
      'fa-slideshare',
      'fa-smile-o',
      'fa-snapchat',
      'fa-snapchat-ghost',
      'fa-snapchat-square',
      'fa-snowflake-o',
      'fa-soccer-ball-o',
      'fa-sort',
      'fa-sort-alpha-asc',
      'fa-sort-alpha-desc',
      'fa-sort-amount-asc',
      'fa-sort-amount-desc',
      'fa-sort-asc',
      'fa-sort-desc',
      'fa-sort-down',
      'fa-sort-numeric-asc',
      'fa-sort-numeric-desc',
      'fa-sort-up',
      'fa-soundcloud',
      'fa-space-shuttle',
      'fa-spinner',
      'fa-spoon',
      'fa-spotify',
      'fa-square',
      'fa-square-o',
      'fa-stack-exchange',
      'fa-stack-overflow',
      'fa-star',
      'fa-star-half',
      'fa-star-half-empty',
      'fa-star-half-full',
      'fa-star-half-o',
      'fa-star-o',
      'fa-steam',
      'fa-steam-square',
      'fa-step-backward',
      'fa-step-forward',
      'fa-stethoscope',
      'fa-sticky-note',
      'fa-sticky-note-o',
      'fa-stop',
      'fa-stop-circle',
      'fa-stop-circle-o',
      'fa-street-view',
      'fa-strikethrough',
      'fa-stumbleupon',
      'fa-stumbleupon-circle',
      'fa-subscript',
      'fa-subway',
      'fa-suitcase',
      'fa-sun-o',
      'fa-superpowers',
      'fa-superscript',
      'fa-support',
      'fa-table',
      'fa-tablet',
      'fa-tachometer',
      'fa-tag',
      'fa-tags',
      'fa-tasks',
      'fa-taxi',
      'fa-telegram',
      'fa-television',
      'fa-tencent-weibo',
      'fa-terminal',
      'fa-text-height',
      'fa-text-width',
      'fa-th',
      'fa-th-large',
      'fa-th-list',
      'fa-themeisle',
      'fa-thermometer',
      'fa-thermometer-0',
      'fa-thermometer-1',
      'fa-thermometer-2',
      'fa-thermometer-3',
      'fa-thermometer-4',
      'fa-thermometer-empty',
      'fa-thermometer-full',
      'fa-thermometer-half',
      'fa-thermometer-quarter',
      'fa-thermometer-three-quarters',
      'fa-thumb-tack',
      'fa-thumbs-down',
      'fa-thumbs-o-down',
      'fa-thumbs-o-up',
      'fa-thumbs-up',
      'fa-ticket',
      'fa-times',
      'fa-times-circle',
      'fa-times-circle-o',
      'fa-times-rectangle',
      'fa-times-rectangle-o',
      'fa-tint',
      'fa-toggle-down',
      'fa-toggle-left',
      'fa-toggle-off',
      'fa-toggle-on',
      'fa-toggle-right',
      'fa-toggle-up',
      'fa-trademark',
      'fa-train',
      'fa-transgender',
      'fa-transgender-alt',
      'fa-trash',
      'fa-trash-o',
      'fa-tree',
      'fa-trello',
      'fa-tripadvisor',
      'fa-trophy',
      'fa-truck',
      'fa-try',
      'fa-tty',
      'fa-tumblr',
      'fa-tumblr-square',
      'fa-turkish-lira',
      'fa-tv',
      'fa-twitch',
      'fa-twitter',
      'fa-twitter-square',
      'fa-umbrella',
      'fa-underline',
      'fa-undo',
      'fa-universal-access',
      'fa-university',
      'fa-unlink',
      'fa-unlock',
      'fa-unlock-alt',
      'fa-unsorted',
      'fa-upload',
      'fa-usb',
      'fa-usd',
      'fa-user',
      'fa-user-circle',
      'fa-user-circle-o',
      'fa-user-md',
      'fa-user-o',
      'fa-user-plus',
      'fa-user-secret',
      'fa-user-times',
      'fa-users',
      'fa-vcard',
      'fa-vcard-o',
      'fa-venus',
      'fa-venus-double',
      'fa-venus-mars',
      'fa-viacoin',
      'fa-viadeo',
      'fa-viadeo-square',
      'fa-video-camera',
      'fa-vimeo',
      'fa-vimeo-square',
      'fa-vine',
      'fa-vk',
      'fa-volume-control-phone',
      'fa-volume-down',
      'fa-volume-off',
      'fa-volume-up',
      'fa-warning',
      'fa-wechat',
      'fa-weibo',
      'fa-weixin',
      'fa-whatsapp',
      'fa-wheelchair',
      'fa-wheelchair-alt',
      'fa-wifi',
      'fa-wikipedia-w',
      'fa-window-close',
      'fa-window-close-o',
      'fa-window-maximize',
      'fa-window-minimize',
      'fa-window-restore',
      'fa-windows',
      'fa-won',
      'fa-wordpress',
      'fa-wpbeginner',
      'fa-wpexplorer',
      'fa-wpforms',
      'fa-wrench',
      'fa-xing',
      'fa-xing-square',
      'fa-y-combinator',
      'fa-y-combinator-square',
      'fa-yahoo',
      'fa-yc',
      'fa-yc-square',
      'fa-yelp',
      'fa-yen',
      'fa-yoast',
      'fa-youtube',
      'fa-youtube-play',
      'fa-youtube-square'
    ],
    newIcons: [
      'fa-500px',
      'fa-address-book',
      'fa-address-book-o',
      'fa-address-card',
      'fa-address-card-o',
      'fa-adjust',
      'fa-adn',
      'fa-align-center',
      'fa-align-justify',
      'fa-align-left',
      'fa-align-right',
      'fa-amazon',
      'fa-ambulance',
      'fa-american-sign-language-interpreting',
      'fa-anchor',
      'fa-android',
      'fa-angellist',
      'fa-angle-double-down',
      'fa-angle-double-left',
      'fa-angle-double-right',
      'fa-angle-double-up',
      'fa-angle-down',
      'fa-angle-left',
      'fa-angle-right',
      'fa-angle-up',
      'fa-apple',
      'fa-archive',
      'fa-area-chart',
      'fa-arrow-circle-down',
      'fa-arrow-circle-left',
      'fa-arrow-circle-o-down',
      'fa-arrow-circle-o-left',
      'fa-arrow-circle-o-right',
      'fa-arrow-circle-o-up',
      'fa-arrow-circle-right',
      'fa-arrow-circle-up',
      'fa-arrow-down',
      'fa-arrow-left',
      'fa-arrow-right',
      'fa-arrow-up',
      'fa-arrows',
      'fa-arrows-alt',
      'fa-arrows-h',
      'fa-arrows-v',
      'fa-asl-interpreting',
      'fa-assistive-listening-systems',
      'fa-asterisk',
      'fa-at',
      'fa-audio-description',
      'fa-automobile',
      'fa-backward',
      'fa-balance-scale',
      'fa-ban',
      'fa-bandcamp',
      'fa-bank',
      'fa-bar-chart',
      'fa-bar-chart-o',
      'fa-barcode',
      'fa-bars',
      'fa-bath',
      'fa-bathtub',
      'fa-battery',
      'fa-battery-0',
      'fa-battery-1',
      'fa-battery-2',
      'fa-battery-3',
      'fa-battery-4',
      'fa-battery-empty',
      'fa-battery-full',
      'fa-battery-half',
      'fa-battery-quarter',
      'fa-battery-three-quarters',
      'fa-bed',
      'fa-beer',
      'fa-behance',
      'fa-behance-square',
      'fa-bell',
      'fa-bell-o',
      'fa-bell-slash',
      'fa-bell-slash-o',
      'fa-bicycle',
      'fa-binoculars',
      'fa-birthday-cake',
      'fa-bitbucket',
      'fa-bitbucket-square',
      'fa-bitcoin',
      'fa-black-tie',
      'fa-blind',
      'fa-bluetooth',
      'fa-bluetooth-b',
      'fa-bold',
      'fa-bolt',
      'fa-bomb',
      'fa-book',
      'fa-bookmark',
      'fa-bookmark-o',
      'fa-braille',
      'fa-briefcase',
      'fa-btc',
      'fa-bug',
      'fa-building',
      'fa-building-o',
      'fa-bullhorn',
      'fa-bullseye',
      'fa-bus',
      'fa-buysellads',
      'fa-cab',
      'fa-calculator',
      'fa-calendar',
      'fa-calendar-check-o',
      'fa-calendar-minus-o',
      'fa-calendar-o',
      'fa-calendar-plus-o',
      'fa-calendar-times-o',
      'fa-camera',
      'fa-camera-retro',
      'fa-car',
      'fa-caret-down',
      'fa-caret-left',
      'fa-caret-right',
      'fa-caret-square-o-down',
      'fa-caret-square-o-left',
      'fa-caret-square-o-right',
      'fa-caret-square-o-up',
      'fa-caret-up',
      'fa-cart-arrow-down',
      'fa-cart-plus',
      'fa-cc',
      'fa-cc-amex',
      'fa-cc-diners-club',
      'fa-cc-discover',
      'fa-cc-jcb',
      'fa-cc-mastercard',
      'fa-cc-paypal',
      'fa-cc-stripe',
      'fa-cc-visa',
      'fa-certificate',
      'fa-chain',
      'fa-chain-broken',
      'fa-check',
      'fa-check-circle',
      'fa-check-circle-o',
      'fa-check-square',
      'fa-check-square-o',
      'fa-chevron-circle-down',
      'fa-chevron-circle-left',
      'fa-chevron-circle-right',
      'fa-chevron-circle-up',
      'fa-chevron-down',
      'fa-chevron-left',
      'fa-chevron-right',
      'fa-chevron-up',
      'fa-child',
      'fa-chrome',
      'fa-circle',
      'fa-circle-o',
      'fa-circle-o-notch',
      'fa-circle-thin',
      'fa-clipboard',
      'fa-clock-o',
      'fa-clone',
      'fa-close',
      'fa-cloud',
      'fa-cloud-download',
      'fa-cloud-upload',
      'fa-cny',
      'fa-code',
      'fa-code-fork',
      'fa-codepen',
      'fa-codiepie',
      'fa-coffee',
      'fa-cog',
      'fa-cogs',
      'fa-columns',
      'fa-comment',
      'fa-comment-o',
      'fa-commenting',
      'fa-commenting-o',
      'fa-comments',
      'fa-comments-o',
      'fa-compass',
      'fa-compress',
      'fa-connectdevelop',
      'fa-contao',
      'fa-copy',
      'fa-copyright',
      'fa-creative-commons',
      'fa-credit-card',
      'fa-credit-card-alt',
      'fa-crop',
      'fa-crosshairs',
      'fa-css3',
      'fa-cube',
      'fa-cubes',
      'fa-cut',
      'fa-cutlery',
      'fa-dashboard',
      'fa-dashcube',
      'fa-database',
      'fa-deaf',
      'fa-deafness',
      'fa-dedent',
      'fa-delicious',
      'fa-desktop',
      'fa-deviantart',
      'fa-diamond',
      'fa-digg',
      'fa-dollar',
      'fa-dot-circle-o',
      'fa-download',
      'fa-dribbble',
      'fa-drivers-license',
      'fa-drivers-license-o',
      'fa-dropbox',
      'fa-drupal',
      'fa-edge',
      'fa-edit',
      'fa-eercast',
      'fa-eject',
      'fa-ellipsis-h',
      'fa-ellipsis-v',
      'fa-empire',
      'fa-envelope',
      'fa-envelope-o',
      'fa-envelope-open',
      'fa-envelope-open-o',
      'fa-envelope-square',
      'fa-envira',
      'fa-eraser',
      'fa-etsy',
      'fa-eur',
      'fa-euro',
      'fa-exchange',
      'fa-exclamation',
      'fa-exclamation-circle',
      'fa-exclamation-triangle',
      'fa-expand',
      'fa-expeditedssl',
      'fa-external-link',
      'fa-external-link-square',
      'fa-eye',
      'fa-eye-slash',
      'fa-eyedropper',
      'fa-fa',
      'fa-facebook',
      'fa-facebook-f',
      'fa-facebook-official',
      'fa-facebook-square',
      'fa-fast-backward',
      'fa-fast-forward',
      'fa-fax',
      'fa-feed',
      'fa-female',
      'fa-fighter-jet',
      'fa-file',
      'fa-file-archive-o',
      'fa-file-audio-o',
      'fa-file-code-o',
      'fa-file-excel-o',
      'fa-file-image-o',
      'fa-file-movie-o',
      'fa-file-o',
      'fa-file-pdf-o',
      'fa-file-photo-o',
      'fa-file-picture-o',
      'fa-file-powerpoint-o',
      'fa-file-sound-o',
      'fa-file-text',
      'fa-file-text-o',
      'fa-file-video-o',
      'fa-file-word-o',
      'fa-file-zip-o',
      'fa-files-o',
      'fa-film',
      'fa-filter',
      'fa-fire',
      'fa-fire-extinguisher',
      'fa-firefox',
      'fa-first-order',
      'fa-flag',
      'fa-flag-checkered',
      'fa-flag-o',
      'fa-flash',
      'fa-flask',
      'fa-flickr',
      'fa-floppy-o',
      'fa-folder',
      'fa-folder-o',
      'fa-folder-open',
      'fa-folder-open-o',
      'fa-font',
      'fa-font-awesome',
      'fa-fonticons',
      'fa-fort-awesome',
      'fa-forumbee',
      'fa-forward',
      'fa-foursquare',
      'fa-free-code-camp',
      'fa-frown-o',
      'fa-futbol-o',
      'fa-gamepad',
      'fa-gavel',
      'fa-gbp',
      'fa-ge',
      'fa-gear',
      'fa-gears',
      'fa-genderless',
      'fa-get-pocket',
      'fa-gg',
      'fa-gg-circle',
      'fa-gift',
      'fa-git',
      'fa-git-square',
      'fa-github',
      'fa-github-alt',
      'fa-github-square',
      'fa-gitlab',
      'fa-gittip',
      'fa-glass',
      'fa-glide',
      'fa-glide-g',
      'fa-globe',
      'fa-google',
      'fa-google-plus',
      'fa-google-plus-circle',
      'fa-google-plus-official',
      'fa-google-plus-square',
      'fa-google-wallet',
      'fa-graduation-cap',
      'fa-gratipay',
      'fa-grav',
      'fa-group',
      'fa-h-square',
      'fa-hacker-news',
      'fa-hand-grab-o',
      'fa-hand-lizard-o',
      'fa-hand-o-down',
      'fa-hand-o-left',
      'fa-hand-o-right',
      'fa-hand-o-up',
      'fa-hand-paper-o',
      'fa-hand-peace-o',
      'fa-hand-pointer-o',
      'fa-hand-rock-o',
      'fa-hand-scissors-o',
      'fa-hand-spock-o',
      'fa-hand-stop-o',
      'fa-handshake-o',
      'fa-hard-of-hearing',
      'fa-hashtag',
      'fa-hdd-o',
      'fa-header',
      'fa-headphones',
      'fa-heart',
      'fa-heart-o',
      'fa-heartbeat',
      'fa-history',
      'fa-home',
      'fa-hospital-o',
      'fa-hotel',
      'fa-hourglass',
      'fa-hourglass-1',
      'fa-hourglass-2',
      'fa-hourglass-3',
      'fa-hourglass-end',
      'fa-hourglass-half',
      'fa-hourglass-o',
      'fa-hourglass-start',
      'fa-houzz',
      'fa-html5',
      'fa-i-cursor',
      'fa-id-badge',
      'fa-id-card',
      'fa-id-card-o',
      'fa-ils',
      'fa-image',
      'fa-imdb',
      'fa-inbox',
      'fa-indent',
      'fa-industry',
      'fa-info',
      'fa-info-circle',
      'fa-inr',
      'fa-instagram',
      'fa-institution',
      'fa-internet-explorer',
      'fa-intersex',
      'fa-ioxhost',
      'fa-italic',
      'fa-joomla',
      'fa-jpy',
      'fa-jsfiddle',
      'fa-key',
      'fa-keyboard-o',
      'fa-krw',
      'fa-language',
      'fa-laptop',
      'fa-lastfm',
      'fa-lastfm-square',
      'fa-leaf',
      'fa-leanpub',
      'fa-legal',
      'fa-lemon-o',
      'fa-level-down',
      'fa-level-up',
      'fa-life-bouy',
      'fa-life-buoy',
      'fa-life-ring',
      'fa-life-saver',
      'fa-lightbulb-o',
      'fa-line-chart',
      'fa-link',
      'fa-linkedin',
      'fa-linkedin-square',
      'fa-linode',
      'fa-linux',
      'fa-list',
      'fa-list-alt',
      'fa-list-ol',
      'fa-list-ul',
      'fa-location-arrow',
      'fa-lock',
      'fa-long-arrow-down',
      'fa-long-arrow-left',
      'fa-long-arrow-right',
      'fa-long-arrow-up',
      'fa-low-vision',
      'fa-magic',
      'fa-magnet',
      'fa-mail-forward',
      'fa-mail-reply',
      'fa-mail-reply-all',
      'fa-male',
      'fa-map',
      'fa-map-marker',
      'fa-map-o',
      'fa-map-pin',
      'fa-map-signs',
      'fa-mars',
      'fa-mars-double',
      'fa-mars-stroke',
      'fa-mars-stroke-h',
      'fa-mars-stroke-v',
      'fa-maxcdn',
      'fa-meanpath',
      'fa-medium',
      'fa-medkit',
      'fa-meetup',
      'fa-meh-o',
      'fa-mercury',
      'fa-microchip',
      'fa-microphone',
      'fa-microphone-slash',
      'fa-minus',
      'fa-minus-circle',
      'fa-minus-square',
      'fa-minus-square-o',
      'fa-mixcloud',
      'fa-mobile',
      'fa-mobile-phone',
      'fa-modx',
      'fa-money',
      'fa-moon-o',
      'fa-mortar-board',
      'fa-motorcycle',
      'fa-mouse-pointer',
      'fa-music',
      'fa-navicon',
      'fa-neuter',
      'fa-newspaper-o',
      'fa-object-group',
      'fa-object-ungroup',
      'fa-odnoklassniki',
      'fa-odnoklassniki-square',
      'fa-opencart',
      'fa-openid',
      'fa-opera',
      'fa-optin-monster',
      'fa-outdent',
      'fa-pagelines',
      'fa-paint-brush',
      'fa-paper-plane',
      'fa-paper-plane-o',
      'fa-paperclip',
      'fa-paragraph',
      'fa-paste',
      'fa-pause',
      'fa-pause-circle',
      'fa-pause-circle-o',
      'fa-paw',
      'fa-paypal',
      'fa-pencil',
      'fa-pencil-square',
      'fa-pencil-square-o',
      'fa-percent',
      'fa-phone',
      'fa-phone-square',
      'fa-photo',
      'fa-picture-o',
      'fa-pie-chart',
      'fa-pied-piper',
      'fa-pied-piper-alt',
      'fa-pied-piper-pp',
      'fa-pinterest',
      'fa-pinterest-p',
      'fa-pinterest-square',
      'fa-plane',
      'fa-play',
      'fa-play-circle',
      'fa-play-circle-o',
      'fa-plug',
      'fa-plus',
      'fa-plus-circle',
      'fa-plus-square',
      'fa-plus-square-o',
      'fa-podcast',
      'fa-power-off',
      'fa-print',
      'fa-product-hunt',
      'fa-puzzle-piece',
      'fa-qq',
      'fa-qrcode',
      'fa-question',
      'fa-question-circle',
      'fa-question-circle-o',
      'fa-quora',
      'fa-quote-left',
      'fa-quote-right',
      'fa-ra',
      'fa-random',
      'fa-ravelry',
      'fa-rebel',
      'fa-recycle',
      'fa-reddit',
      'fa-reddit-alien',
      'fa-reddit-square',
      'fa-refresh',
      'fa-registered',
      'fa-remove',
      'fa-renren',
      'fa-reorder',
      'fa-repeat',
      'fa-reply',
      'fa-reply-all',
      'fa-resistance',
      'fa-retweet',
      'fa-rmb',
      'fa-road',
      'fa-rocket',
      'fa-rotate-left',
      'fa-rotate-right',
      'fa-rouble',
      'fa-rss',
      'fa-rss-square',
      'fa-rub',
      'fa-ruble',
      'fa-rupee',
      'fa-s15',
      'fa-safari',
      'fa-save',
      'fa-scissors',
      'fa-scribd',
      'fa-search',
      'fa-search-minus',
      'fa-search-plus',
      'fa-sellsy',
      'fa-send',
      'fa-send-o',
      'fa-server',
      'fa-share',
      'fa-share-alt',
      'fa-share-alt-square',
      'fa-share-square',
      'fa-share-square-o',
      'fa-shekel',
      'fa-sheqel',
      'fa-shield',
      'fa-ship',
      'fa-shirtsinbulk',
      'fa-shopping-bag',
      'fa-shopping-basket',
      'fa-shopping-cart',
      'fa-shower',
      'fa-sign-in',
      'fa-sign-language',
      'fa-sign-out',
      'fa-signal',
      'fa-signing',
      'fa-simplybuilt',
      'fa-sitemap',
      'fa-skyatlas',
      'fa-skype',
      'fa-slack',
      'fa-sliders',
      'fa-slideshare',
      'fa-smile-o',
      'fa-snapchat',
      'fa-snapchat-ghost',
      'fa-snapchat-square',
      'fa-snowflake-o',
      'fa-soccer-ball-o',
      'fa-sort',
      'fa-sort-alpha-asc',
      'fa-sort-alpha-desc',
      'fa-sort-amount-asc',
      'fa-sort-amount-desc',
      'fa-sort-asc',
      'fa-sort-desc',
      'fa-sort-down',
      'fa-sort-numeric-asc',
      'fa-sort-numeric-desc',
      'fa-sort-up',
      'fa-soundcloud',
      'fa-space-shuttle',
      'fa-spinner',
      'fa-spoon',
      'fa-spotify',
      'fa-square',
      'fa-square-o',
      'fa-stack-exchange',
      'fa-stack-overflow',
      'fa-star',
      'fa-star-half',
      'fa-star-half-empty',
      'fa-star-half-full',
      'fa-star-half-o',
      'fa-star-o',
      'fa-steam',
      'fa-steam-square',
      'fa-step-backward',
      'fa-step-forward',
      'fa-stethoscope',
      'fa-sticky-note',
      'fa-sticky-note-o',
      'fa-stop',
      'fa-stop-circle',
      'fa-stop-circle-o',
      'fa-street-view',
      'fa-strikethrough',
      'fa-stumbleupon',
      'fa-stumbleupon-circle',
      'fa-subscript',
      'fa-subway',
      'fa-suitcase',
      'fa-sun-o',
      'fa-superpowers',
      'fa-superscript',
      'fa-support',
      'fa-table',
      'fa-tablet',
      'fa-tachometer',
      'fa-tag',
      'fa-tags',
      'fa-tasks',
      'fa-taxi',
      'fa-telegram',
      'fa-television',
      'fa-tencent-weibo',
      'fa-terminal',
      'fa-text-height',
      'fa-text-width',
      'fa-th',
      'fa-th-large',
      'fa-th-list',
      'fa-themeisle',
      'fa-thermometer',
      'fa-thermometer-0',
      'fa-thermometer-1',
      'fa-thermometer-2',
      'fa-thermometer-3',
      'fa-thermometer-4',
      'fa-thermometer-empty',
      'fa-thermometer-full',
      'fa-thermometer-half',
      'fa-thermometer-quarter',
      'fa-thermometer-three-quarters',
      'fa-thumb-tack',
      'fa-thumbs-down',
      'fa-thumbs-o-down',
      'fa-thumbs-o-up',
      'fa-thumbs-up',
      'fa-ticket',
      'fa-times',
      'fa-times-circle',
      'fa-times-circle-o',
      'fa-times-rectangle',
      'fa-times-rectangle-o',
      'fa-tint',
      'fa-toggle-down',
      'fa-toggle-left',
      'fa-toggle-off',
      'fa-toggle-on',
      'fa-toggle-right',
      'fa-toggle-up',
      'fa-trademark',
      'fa-train',
      'fa-transgender',
      'fa-transgender-alt',
      'fa-trash',
      'fa-trash-o',
      'fa-tree',
      'fa-trello',
      'fa-tripadvisor',
      'fa-trophy',
      'fa-truck',
      'fa-try',
      'fa-tty',
      'fa-tumblr',
      'fa-tumblr-square',
      'fa-turkish-lira',
      'fa-tv',
      'fa-twitch',
      'fa-twitter',
      'fa-twitter-square',
      'fa-umbrella',
      'fa-underline',
      'fa-undo',
      'fa-universal-access',
      'fa-university',
      'fa-unlink',
      'fa-unlock',
      'fa-unlock-alt',
      'fa-unsorted',
      'fa-upload',
      'fa-usb',
      'fa-usd',
      'fa-user',
      'fa-user-circle',
      'fa-user-circle-o',
      'fa-user-md',
      'fa-user-o',
      'fa-user-plus',
      'fa-user-secret',
      'fa-user-times',
      'fa-users',
      'fa-vcard',
      'fa-vcard-o',
      'fa-venus',
      'fa-venus-double',
      'fa-venus-mars',
      'fa-viacoin',
      'fa-viadeo',
      'fa-viadeo-square',
      'fa-video-camera',
      'fa-vimeo',
      'fa-vimeo-square',
      'fa-vine',
      'fa-vk',
      'fa-volume-control-phone',
      'fa-volume-down',
      'fa-volume-off',
      'fa-volume-up',
      'fa-warning',
      'fa-wechat',
      'fa-weibo',
      'fa-weixin',
      'fa-whatsapp',
      'fa-wheelchair',
      'fa-wheelchair-alt',
      'fa-wifi',
      'fa-wikipedia-w',
      'fa-window-close',
      'fa-window-close-o',
      'fa-window-maximize',
      'fa-window-minimize',
      'fa-window-restore',
      'fa-windows',
      'fa-won',
      'fa-wordpress',
      'fa-wpbeginner',
      'fa-wpexplorer',
      'fa-wpforms',
      'fa-wrench',
      'fa-xing',
      'fa-xing-square',
      'fa-y-combinator',
      'fa-y-combinator-square',
      'fa-yahoo',
      'fa-yc',
      'fa-yc-square',
      'fa-yelp',
      'fa-yen',
      'fa-yoast',
      'fa-youtube',
      'fa-youtube-play',
      'fa-youtube-square'
],
    indicator_setting_name:'',
    add_series_data : 0,
    is_indicator_delete : 0,
    store_chart : {},
    allowChartUpdate : ''

    };

    this.searchIcon = this.searchIcon.bind(this);
    this.handleFontChange = this.handleFontChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleUnlock = this.handleUnlock.bind(this);
    this.handleLock = this.handleLock.bind(this);
    // if(this.props.get_indicator_prop_data){ 
    //   //this.render();
    //   console.log('length',JSON.parse(localStorage.getItem('indicator'))?.length)
    //   if(JSON.parse(localStorage.getItem('indicator'))?.length){
    //     this.setState({isIndicatorDivDisplay:true}) 
    //   }
    //   //this.setState({showRandom:Math.random()}) 
    //   console.log('random_number',Math.random())
    //   //alert('as');
    // }
    
  }

  showCode = (indicator_code) =>{
    $('#python_code').text(indicator_code)  
    $('.python_code_editor').toggle()
  }

  removeIndicator = (index) =>{  
    // let existing_indicator_arr = JSON.parse(localStorage.getItem('indicator')) 
    // existing_indicator_arr.splice(index, 1);  
    // if(existing_indicator_arr?.length>0){
    //   localStorage.setItem("indicator", JSON.stringify(existing_indicator_arr));
    // }else{
    //   localStorage.setItem("indicator", JSON.stringify(existing_indicator_arr)); 
    // }
    // $(`#indicator_tool_tip_${index}`).remove(); 
    //alert('1')
    this.setState({is_indicator_delete:2}) 
  }
  showHideIndicator = (index)=>{
      this.setState({allowChartUpdate:true})
       let existing_indicator_arr = JSON.parse(localStorage.getItem('indicator')) 
    existing_indicator_arr.splice(index, 1); 
    localStorage.setItem("indicator", JSON.stringify(existing_indicator_arr)); 
    this.setState({is_indicator_delete:true})
  }

  removeMaTemplate = (index) =>{ 
    localStorage.removeItem("ma_template"); 
    this.setState({is_indicator_delete:true})
  }
  removeBillTemplate = (index) =>{ 
    localStorage.removeItem("bill_template"); 
    this.setState({is_indicator_delete:true})
  }
  removeBillTemplate = (index) =>{ 
    localStorage.removeItem("aligator_template"); 
    this.setState({is_indicator_delete:true})
  }
  removeDisplacedTemplate = (index) =>{ 
    localStorage.removeItem("displaced_template"); 
    this.setState({is_indicator_delete:true})
  }

  showIndicatorSetting = (e) =>{  
    // Show hide indicator tooltip start  
      let is_active_class  = $(e.target).parent().prop('className').split(' ')[1]
      console.log('is_active_class',is_active_class)
      if(is_active_class == undefined){
        $('.tooltip_custom').removeClass('active')
        $(e.target).parent().addClass('active')  
      }else{
        $('.tooltip_custom').removeClass('active')
        $(e.target).parent().removeClass('active')
      }
    // Show hide indicator tooltip end
  }
  handleUnlock = () => {
    this.setState({
      lock: false
    })
  // alert('dd');
  //   var id = '#'+$('#exmModel').val()+' '+'.unlock';
  //   $(id).css('display', 'none');
  };

  handleLock = () => {

      this.setState({
        lock: true
      })

    // alert('dd++');
    //   var id = '#'+$('#exmModel').val()+' '+'.lockk';
    //   $(id).css('display', 'none');
  };



  handleDelete = () => {
      alert('You have clicked on delete button.');
      var id = '#'+$('#exmModel').val()+' '+'.extraIcon';
      $(id).css('display', 'none');
  };

  searchIcon = event => {

      let iconsArr = this.state.icons; 
      this.setState({
          icons: iconsArr.filter(iconsArr => iconsArr.includes(event.target.value))
      })

  }

  handleFontChange = (event) => {
      var id = '#'+$('#exmModel').val()+' '+'.extraIcon';
      $(id).css('font-size', event.target.value+'px');
  };

  handleClick = () => {
      this.setState({ displayColorPicker: !this.state.displayColorPicker })
  };
  hideBullIndicator()
    {
      //this.setState({emaTest:[]});
     // chart.get('series-2').remove();
    }
  handleClose = () => {
      this.setState({ displayColorPicker: false });
  };
  handleChange = (color) => {
     console.log("color is",color.target.value);
     // this.setState({ color: color.target.value });
      var id = '#'+$('#exmModel').val()+' '+'.extraIcon';
      $("#color_modal").css("background",color.target.value);
      $(id).css('color', this.state.background);
      $(".icon_color_change").val(color.target.value);
      $(".icon_color_change").change();
     
  };
  showIndicatorDiv = () => {
    $('.custom_indicator_div').show(); 
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
    //$('.main_indicator_view').hide();
  }
   calculateEMA(dps,count) {
    var k = 2/(count + 1);
    var emaDps = [{x: dps[0].x, y: dps[0].y.length ? dps[0].y[3] : dps[0].y}];
    for (var i = 1; i < dps.length; i++) {
      emaDps.push({x: dps[i].x, y: (dps[i].y.length ? dps[i].y[3] : dps[i].y) * k + emaDps[i - 1].y * (1 - k),h:dps[i].h});
    }
    return emaDps;
  }
  showTemplateDiv = () => {
    var template_list = JSON.parse(localStorage.getItem('default_template'));
    template_list.map((data)=>{
      if(data.template == 'displaced_template'){
        $('.displaced_template_div').show();
      }else if(data.template == 'bill_template'){
        $('.bill_template_div').show()
      }else if(data.template == 'ma_template'){
        $('.ma_template_div').show();
      }else if(data.template == 'oscillater_template'){
        $('.oscillater_template_div').show();
      }else if(data.template == 'ma_template'){
        $('.ma_template_div').show();
      }else if(data.template == 'swing_template'){
        $('.swing_template_div').show();
      }else if(data.template == 'aligator_template'){
        $('.aligator_template_div').show();
      }
    })
    
    this.setState({ displayColorPicker: false });
  }

  


  componentDidMount() {
    (function (H) {
      function symbolWrap(proceed, symbol, x, y, w, h, options) {
          if (symbol.indexOf('text:') === 0) {
              var text = symbol.split(':')[1],
                  svgElem = this.text(text, x, y + h)
                  .css({
                      fontFamily: 'FontAwesome',
                      fontSize: 20
                  });
              
              if (svgElem.renderer.isVML) {
                  svgElem.fillSetter = function (value, key, element) {
                      element.style.color = H.Color(value).get('rgb');
                  };
              }
              return svgElem;
          }
          return proceed.apply(this, [].slice.call(arguments, 1));
      }
      H.wrap(H.SVGRenderer.prototype, 'symbol', symbolWrap);
      if (H.VMLRenderer) {
          H.wrap(H.VMLRenderer.prototype, 'symbol', symbolWrap);
      }
      
      // Load the font for SVG files also
      H.wrap(H.Chart.prototype, 'getSVG', function (proceed) {
          var svg = proceed.call(this);
          svg = '<?xml-stylesheet type="text/css" ' +
              'href="http://netdna.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css" ?>' + 
              svg;
          return svg;
      });
  }(Highcharts));
      console.log('props',this.props)
      this.getResult();
      let ohlcValue = this.props.ohlcValue
      let barChangeVal = this.props.bar_change_val
      let marketStatus = this.props.market_status
      let volValue = this.props.volValue
      let prePostMktPrice = (this.props.pre_post_mkt_price === 'true' || this.props.pre_post_mkt_price === true) ? '120' : '';
      let loOverlappingLabel = (this.props.lo_overlapping_label === 'true' || this.props.lo_overlapping_label === true) ? false : true;
      
      let plusButton = (this.props.addPlusAlert === 'true' || this.props.addPlusAlert === true) ? true : false;
      var myElemOHLC = document.getElementById('ohlc_vals');
      var myElemVol = document.getElementById('vol_vals');
      var l_change = document.getElementById('l_change');
      var market_status = document.getElementById('market_status');

      let rangeSelected = 1;
      if (this.props.time == "minute" || this.props.time == "hour") {
          rangeSelected = 0;
      }
  
      if (market_status !== null) {
        if (marketStatus === 'true' || marketStatus === true) {
          market_status.style.display = 'block';
        } else {
          market_status.style.display = 'none';
        }
      }
  
      if (l_change !== null) {
        if (barChangeVal === 'true' || barChangeVal === true) {
          l_change.style.display = 'block';
        } else {
          l_change.style.display = 'none';
        }
      }
  
      if (myElemOHLC !== null) {
        if (ohlcValue === 'true' || ohlcValue === true) {
          myElemOHLC.style.display = 'block';
        } else {
          myElemOHLC.style.display = 'none';
        }
      }
  
      if (myElemVol !== null) {
        if (volValue === 'true' || volValue === true) {
          myElemVol.style.display = 'block';
        } else {
          myElemVol.style.display = 'none';
        }
      }
  
      console.log(this.state.background_color)
      console.log('this.props.alpha_color')
      const goldmine = this.props.date_format_val;
      const alphaMine = 'rgba(' + this.props.alpha_color.r + ',' + this.props.alpha_color.g + ',' + this.props.alpha_color.b + ',' + this.props.alpha_color.a + ')';
      let barChangeValue = (this.props.bar_change_val === 'true' || this.props.bar_change_val === true) ? '−0.40 (−0.81%)' : '';
      const options = {
          chart: {
          type: "candlestick",
          pointPadding:0.04,
          groupPadding:0,
        credits:false
          //height: 1700,
          //backgroundColor: this.state.backgroundColor,
        },
        
          title: {
            text: (this.props.statusLineSymbol === true || this.props.statusLineSymbol === 'true') ? this.getConditionalSymbolData(this.props.symbolVariation) : '',
          },
          xAxis: {
              crosshair: {
                text: 'asdasdas',
                snap: false,
                value: '3434',
                label: {
                    enabled: true,
                    format: '{value:.1f}' // One decimal
                }
              },
              labels: {
                allowOverlap: loOverlappingLabel
              },
              type: 'datetime',
              dateTimeLabelFormats: {
                day: '%b %e'
              }
          },
          rangeSelector: {
            enabled: false,
          //   allButtonsEnabled: true,
          //   buttons: [{
          //     type: 'month',
          //     count: 1,
          //     text: '1m',
          //     events: {
          //         click: function() {
          //             //alert('Clicked button');
          //         }
          //     }
          // }, {
          //     type: 'month',
          //     count: 3,
          //     text: '3m'
          // }, {
          //     type: 'month',
          //     count: 6,
          //     text: '6m'
          // }, {
          //     type: 'ytd',
          //     text: 'YTD'
          // }, {
          //     type: 'year',
          //     count: 1,
          //     text: '1y'
          // }, {
          //     type: 'all',
          //     text: 'All'
          // }],
          //   inputEnabled: false,
          //   buttonTheme: {
          //     width: 40,
          //   },
          //   selected: rangeSelected,
          },
          // rangeSelector: {
          //   selected: 1
          // },
          plotOptions: {
            scatter: {
              marker: {
                  states: {
                      hover: {
                          lineWidth: 0.1
                      }
                  }
              }
          },
            candlestick: {
              color: this.props.up_color_color,
              upColor: this.props.down_color_color,
              upLineColor:this.props.down_color_color,
              lineColor:this.props.up_color_color
            },
            series: {
              states: {
                inactive: {
                    opacity: 1
                }
            },
              color: '#FF0000',
              groupPadding: 0,
              pointPadding: 0.2,
              borderWidth: 0,
          allowPointSelect: true,
         

              //name: (this.props.statusLineSymbol === true || this.props.statusLineSymbol === 'true') ? this.getConditionalSymbolData(this.props.symbolVariation) : '',
              point: {
                events: {
                  click:function(e)
                  {
                    //todo: old click for icon s
                    // if(!e.point.hasOwnProperty('high'))
                    // {
                    //   $(e.point.graphic.element).attr('fill','black');
                    //   $(e.point.graphic.element).removeAttr('stroke');
                    //   // $("#"+e.point.id).hide();
                    //   $('#exmModel').val(e.point.id);
                    //   window.$('#exampleModal').modal('show');
                    // }
                  },
                  drag: function (e) {
                    // Returning false stops the drag and drops. Example:
                    /*
                    if (e.newY > 300) {
                        this.y = 300;
                        return false;
                    }
                    */
                    $('#drag').html(
                        'Dragging ' + this.series.name + ' point to <b>[' + Highcharts.numberFormat(e.x, 2)  + ', ' + Highcharts.numberFormat(e.y, 2) + ']</b>');
                },
                drop: function () {
                    $('#drop').html(
                       'Dropped ' + this.series.name + ' at <b>[' + Highcharts.numberFormat(this.x, 2)  + ', ' + Highcharts.numberFormat(this.y, 2) + ']</b>');
                },
                  mouseOver: function () {
                   
                    var chart = this.series.chart;
                    if (!chart.lbl) {
                      chart.lbl = chart.renderer.label('').attr({
                        padding: 10,
                        r: 10,
                        fill: this.state.background_color
                      }).css({
                        color: 'black',
                        background: "#000000"
                      }).add();
                    } else {
                      chart.lbl.fill = "green";
                    }
                    // console.log('this');
                    // console.log(chart.lbl.fill);
                    // console.log(this);
                    // console.log('this');
                    if (this.options.high) {
                      // console.log('object----' + ohlcValue);
                      if (ohlcValue === 'true' || ohlcValue === true) {
                        // console.log('object----' + JSON.stringify(this));
                        chart.lbl
                          .show()
                          .attr({
                            text: '<div style="background-color:"blue !important;right:0" class="stock_entry">&nbsp;&nbsp;&nbsp;<span id="free_space" style="display:none;padding-left:25px">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span style="left:130px;fill:green;background-color:grey;" class="top_line_status" id="market_status">●</span>  <div id="ohlc_vals">O: <div class="l_open">' + this.open + '</div>  H: <div class="l_high">' + this.high + '</div>  L: <div class="l_low">' + this.low + '</div>  C: <div class="l_close">' + this.y + '</div><br> &nbsp; &nbsp; <div class="v_close">V:' + this.high + '</div>  <div id="l_change">' +barChangeValue+'</div></div></div>'
                          });
                      } 
                    } else {
                      if (volValue === 'true' || volValue === true) {
                        chart.lbl
                          .show()
                          .attr({
                            text: 'Vol: <div class="l_volume">' + this.y
                          });
                      }
                    }
                    
                  }
                }
              },
              events: {
                mouseOut: function () {
                  if (this.chart.lbl) {
                    // this.chart.lbl.hide();
                  }
                }
              }
            }
          },
          tooltip: {
            enabled: true,
            formatter: function () {
              const selectedFormat = document.getElementById("dateFormat").value;
              return ['<b>' + Highcharts.dateFormat('' + selectedFormat + ' %H:%M', this.x) + '</b>'].concat();
            }
          },
          stockTools: {
            gui: {
              enabled: true, 
              buttons: [ 'separator', 'simpleShapes', 'lines', 'crookedLines', 'measure', 'advanced', 'toggleAnnotations', 'separator', 'verticalLabels', 'flags', 'separator', 'zoomChange', 'fullScreen', 'typeChange', 'separator', 'currentPriceIndicator', 'saveChart'],
              definitions: {
                inputText: {
                  className: 'highcharts-inputText-annotation',
                  symbol: 'label.svg'
                }
              }
            }
          },
          navigation: {
            annotationsOptions: {
              shapeOptions: {
                fill: 'rgba(0, 0, 0, 0)',
                stroke: 'rgba(0, 0, 0, 1)',
                strokeWidth: 2,
                borderColor: 'gray',
                type: 'rect',
                borderWidth: 2
              }
            },
            bindingsClassName: 'annotation-container',

            bindings: {
              inputText: {
                className: 'highcharts-inputText-annotation',
                start: function (e) {
                    alert("hrllo");
                  const xvalueAn = e.chartX - this.chart.plotLeft;
                  const yvalueAn = e.chartY - this.chart.plotTop;
                  var coords = this.chart.pointer.getCoordinates(e),
                    x = coords.xAxis[0].value,
                    y = coords.yAxis[0].value,
                    navigation = this.chart.options.navigation,
                    controlPoints = [{
                      positioner: function (target) {
                        if (!target.graphic.placed) {
                          return {
                            x: 0,
                            y: -9e7
                          };
                        }
                        return {
                          x: x -
                            this.graphic.width / 2,
                          y: y -
                            this.graphic.height / 2
                        };
                      },
                      events: {
                        drag: function (e, target) {
                          var xy = this.mouseMoveToTranslation(e);
                          target.translate(xy.x, xy.y);
                          target.annotation.labels[0].options =
                            target.options;
                          target.redraw(false);
                        }
                      }
                    }];
                  var _self = this;
                  var labelTextForm = document.getElementById('labelTextForm');
                  var onclick = function () { 
                    _self.chart.addAnnotation(Highcharts.merge({
                      langKey: 'label',
                      labelOptions: {
                        format: labelTextForm.querySelector('#labelTextInput').value,
                        shape: 'rect'
                      },
                      labels: [{
                        point: {
                          xAxis: 0,
                          yAxis: 0,
                          x: x,
                          y: y
                        },
                        overflow: 'none',
                        crop: false,
                        text: labelTextForm.querySelector('#labelTextInput').value,
                        controlPoints: controlPoints
                      }]
                    }, navigation
                      .annotationsOptions, navigation
                        .bindings
                        .labelAnnotation
                      .annotationsOptions));
                  };
                  
                  Highcharts.css(
                    labelTextForm, {
                    top: (e.pageY + 15) + 'px',
                    left: e.pageX + 'px',
                    display: 'block'
                  }
                  );

                  var unbinder = Highcharts.addEvent(
                    labelTextForm.querySelector('#labelTextButton'),
                    'click',
                    function () {
                      onclick();

                      Highcharts.css(labelTextForm, {
                        display: 'none'
                      });

                      unbinder();
                    }
                  )

                  return true;
                }
              },
              triangleAnnotation: {
                className: 'highcharts-triangle-annotation',
                start: function(e) {         
                  var coords = this.chart.pointer.getCoordinates(e),
                    navigation = this.chart.options.navigation
                    console.log(navigation)
                    return this.chart.addAnnotation(
                    Highcharts.merge({
                        langKey: 'Triangle',
                        shapes: [{
                          type: 'path',
                          points: [
                            {
                              x: 440,
                              y: 72
                            }, 
                            {
                              x: 410,
                              y: 45
                            }, 
                            {
                              x: 470,
                              y: 45
                            }, 
                            {
                              x: 440,
                              y: 72
                            }
                          ],
                          fill: 'transparent',                   
                          stroke: "black",
                          strokeWidth: 2,
                        }],                 
                      },
                      navigation
                      .annotationsOptions                
                    )
                  );
                }
              }
             
            }
          },
          yAxis1: [{
            height: '60%'
          }, {
              top: '60%',
              height: '20%'
          }, {
              top: '80%',
              height: '10%'
          }],
          navigator: {
            enabled: false
        },

        yAxis: [
          {
          maxPadding: this.props.lock_price_to_bar_ratio_val,
          labels: {
            allowOverlap: loOverlappingLabel
          },
          clickOnCrosshair: function () {
            console.log(this);
          },
          crosshair: {

            snap: false,
            width: 0.5,
            color: '#0C792A',
            style: 'dotted',
            label: {
            style: 'dotted',
              backgroundColor: '#0C792A',
              useHTML: true,
              events: {
                dblclick: function () {
                  console.log('a')
                },
                click: function () {
                  console.log('b')
                },
                contextmenu: function () {
                  console.log('c')
                }
              },
              formatter: function (value) {
                
                return (plusButton === 'true' || plusButton === true) ? '<span id="' + value + '" class="plusAddAlert fa fa-solid fa-eye">Add Alert  ' + Number(value).toFixed(2)  + '</span> <span id="' + Number(value).toFixed(2)  + '" class="plusAddAlert2 fa fa-solid fa-eye" style="background-color:#0C792A !important"></span><div id="myDIV">This is my DIV element.</div >' : '';
              },
              enabled: plusButton,
              padding: 8
            }
          },
          plotLines: [
           {
            dashStyle: 'dot',
              color: 'red',
              width: 1,
              value: this.state.hignlinevalue,
              label: {
                style: {
                  width: '400px',
                  'min-width': '350px'
                },
                align: this.props.scale_placement,
                x: -20,
                text: '<div class="high_value_plot plot_line_dyn">' + this.toggleHighLine(this.props.sym_highlowlast_label) + '</div>'
              }
            },
            {
              color: 'red',
              width: 2,
              value: this.state.lowerlinevalue,
              label: {
                align: this.props.scale_placement,
                x: -20,
                text: '<div class="high_value_plot plot_line_dyn">' + this.toggleLowerLine(this.props.sym_highlowlast_label) + '</div>'
              }
            },
            {
  
              color: 'red',
              width: 2,
              value: this.state.lastLineValue,
              label: {
                align: this.props.scale_placement,
                x: -20,
                text: '<div class="high_value_plot plot_line_dyn">' + this.toggleLastLine(this.props.sym_highlowlast_label) + '</div>'
              }
            },
            //@todo - Rohit Saini- Make it dynamic
            {
              id:'lastpriceline',
              color: 'red',
              width: 2,
              value: 135,
              label: {
                style: {
                  backgroundColor: 'red',
                  color: 'blue',
                  fontWeight: 'bold',
                  dashStyle: 'longdash',
                },
                align: this.props.scale_placement,
                x: -40,
                text: '<div class="high_value_plot plot_line_dyn line_last_price"><span class="chk_sym_name_label_trigger">' + this.toggleTicker(this.props.sym_name_label) + ':' + this.toggleLastPrice(this.props.sym_last_price_label) + '</span><br/><span class="chk_label_countdown_trigger">' + this.toggleLastPricePer(true) + '<span><br/><span class="sym_highlowlast_label_trigger">' + this.toggleLastPriceCountDown(this.props.countdownBar) + '</span></div>'
              }
            },
            {
              color: 'red',
              width: 0,
              value: 120,
              label: {
                align: this.props.scale_placement,
                x: -40,
                text: '<div class="high_value_plot plot_line_dyn chk_pre_post_mkt_lbl_trigger">' + prePostMktPrice + '</div>'
              }
            },
            {
              color: 'red',
              width: 0,
              value: 140,
              label: {
                align: this.props.scale_placement,
                x: -40,
                text: (this.props.askBid) ? '<div class="high_value_plot plot_line_dyn  ch_bid_ask_label_trigger" style="margin-right:40px">Ask 140 </div>' : '',
              }
            },
            {
              color: 'red',
              width: 0,
              value: 130,
              label: {
                align: this.props.scale_placement,
                x: -40,
                text: (this.props.askBid) ? '<div class="high_value_plot plot_line_dyn ch_bid_ask_label_trigger">Bid 130</div>' : '',
              }

            }
  
          ],  
          lineWidth: 1,
          opposite: this.returnScalePosition(),
          minRange: 0.001,
          gridLineWidth: this.props.grid,

          labels: {
            align: 'right',
            x: -3
          },
          title: {
            text: ''
          },
          height: '60%',
          resize: {
            enabled: false
          }
        }, 
        {
          lineWidth: 1,
          opposite: this.returnScalePosition(),
          minRange: 0.001,
          gridLineWidth: this.props.grid,
          labels: {
            align: 'right',
            x: -3
          },
          title: {
            text: ''
          },
          top: '65%',
          height: '35%',
          offset: 0
        },{
          top: '80%',
          height: '10%'
        }
        ], 
          exporting: {
            enabled: false,
          },
        
          series: [
            {
              id: 'aapl',
              type: this.props.graph,
              name: (this.props.statusLineSymbol === true || this.props.statusLineSymbol === 'true') ? this.getConditionalSymbolData(this.props.symbolVariation) : '',
              data: this.state.stockData,
              navigator: {
                enabled: false
              },
              // data: data,
              useOhlcData: false,
              tooltip: {
                  valueDecimals: 2,
              },
              dataGrouping: {},
            }
          ],
    };

    this.setState({
        seriesData : options
    })

    
    this.setState({all_indicator_data:localStorage.getItem('indicator')?localStorage.getItem('indicator'):[]}) 
    setTimeout(() => { 
      this.getResult();   
      console.log('this.state.stockData '+ this.state.stockData);


      let ohlcValue = this.props.ohlcValue
      let barChangeVal = this.props.bar_change_val
      let marketStatus = this.props.market_status
      let volValue = this.props.volValue
      let prePostMktPrice = (this.props.pre_post_mkt_price === 'true' || this.props.pre_post_mkt_price === true) ? '120' : '';
      let loOverlappingLabel = (this.props.lo_overlapping_label === 'true' || this.props.lo_overlapping_label === true) ? false : true;
      
      let plusButton = (this.props.addPlusAlert === 'true' || this.props.addPlusAlert === true) ? true : false;
      var myElemOHLC = document.getElementById('ohlc_vals');
      var myElemVol = document.getElementById('vol_vals');
      var l_change = document.getElementById('l_change');
      var market_status = document.getElementById('market_status');

      let rangeSelected = 1;
      if (this.props.time == "minute" || this.props.time == "hour") {
          rangeSelected = 0;
      } 
      let { stockError, background_color } = this.state;
      background_color="grey";
      //console.log('123_45',localStorage.getItem("enable_hekashi"))
      const options = {  
          title: {
            text: (this.props.statusLineSymbol === true || this.props.statusLineSymbol === 'true') ? this.getConditionalSymbolData(this.props.symbolVariation) : '',
          },
          // xAxis1: {

          //   crosshair: {
          //     text: 'asdasdas',
          //     snap: false,
          //     value: '3434',
          //     label: {
          //       enabled: true,
          //       format: '{value:.1f}' // One decimal
          //     }
          //   },

          //   labels: {
          //     allowOverlap: loOverlappingLabel
          //   },
          //   type: 'datetime',
          //   dateTimeLabelFormats: {
          //     day: '%e of %b'
          //   }
          // },
          // rangeSelector: {
          //   allButtonsEnabled: true,
          //   buttons: [{
          //     type: 'month',
          //     count: 1,
          //     text: '1m',
          //     events: {
          //         click: function() {
          //             //alert('Clicked button');
          //         }
          //     }
          // }, {
          //     type: 'month',
          //     count: 3,
          //     text: '3m'
          // }, {
          //     type: 'month',
          //     count: 6,
          //     text: '6m'
          // }, {
          //     type: 'ytd',
          //     text: 'YTD'
          // }, {
          //     type: 'year',
          //     count: 1,
          //     text: '1y'
          // }, {
          //     type: 'all',
          //     text: 'All'
          // }],
          //   inputEnabled: false,
          //   buttonTheme: {
          //     width: 40,
          //   },
          //   selected: 1,
          // },
          // plotOptions1: {
          //   candlestick: {
          //     color: this.props.up_color_color,
          //     upColor: this.props.down_color_color
          //   },
          //   series: {
          //     color: '#FF0000',
          //     //name: (this.props.statusLineSymbol === true || this.props.statusLineSymbol === 'true') ? this.getConditionalSymbolData(this.props.symbolVariation) : '',
          //     point: {
          //       events: {
          //         mouseOver: function () {
          //           // console.log('ji');
          //           var chart = this.series.chart;
          //           if (!chart.lbl) {
          //             chart.lbl = chart.renderer.label('').attr({
          //               padding: 10,
          //               r: 10,
          //               fill: this.state.background_color
          //             }).css({
          //               color: '#FFFFFF',
          //               background: "#000000"
          //             }).add();
          //           } else {



          //             chart.lbl.fill = "green";
          //           }
          //           // console.log('this');
          //           // console.log(chart.lbl.fill);
          //           console.log('ddddd', chart);
          //           // console.log(this);
          //           // console.log('this');
          //           if (this.options.high) {
          //             // console.log('object----' + ohlcValue);
          //             if (ohlcValue === 'true' || ohlcValue === true) {
          //               // console.log('object----' + JSON.stringify(this));
          //               chart.lbl
          //                 .show()
          //                 .attr({
          //                   text: '<span style="fill:green" id="market_status"> </span>  <div id="ohlc_vals">O: <div class="l_open">' + this.open + '</div>  H: <div class="l_high">' + this.high + '</div>  L: <div class="l_low">' + this.low + '</div>  C: <div class="l_close">' + this.y + '</div>  <div id="l_change">âˆ’0.40 (âˆ’0.81%)</div></div>'
          //                 });
          //             }
          //           } else {
          //             if (volValue === 'true' || volValue === true) {
          //               chart.lbl
          //                 .show()
          //                 .attr({
          //                   text: 'Vol: <div class="l_volume">' + this.y
          //                 });
          //             }
          //           }

          //         }
          //       }
          //     },
          //     events: {
          //       mouseOut: function () {
          //         if (this.chart.lbl) {
          //           // this.chart.lbl.hide();
          //         }
          //       }
          //     }
          //   } 
          // },
          // tooltip: {
          //   enabled: true,
          //   formatter: function () {
          //     const selectedFormat = document.getElementById("dateFormat").value;
          //     return ['<b>' + Highcharts.dateFormat('' + selectedFormat + ' %H:%M', this.x) + '</b>'].concat();
          //   }
          // },
          // stockTools: {
          //   gui: {
          //     enabled: true,
          //     //buttons: ['indicators', 'separator', 'simpleShapes', 'lines', 'crookedLines', 'measure', 'advanced', 'toggleAnnotations', 'separator', 'verticalLabels', 'flags', 'separator', 'zoomChange', 'fullScreen', 'typeChange', 'separator', 'currentPriceIndicator', 'saveChart'],
          //     definitions: {
          //       inputText: {
          //         className: 'highcharts-inputText-annotation',
          //         symbol: 'label.svg'
          //       }
          //     }
          //   }
          // },
          // navigation1: {
          //   annotationsOptions: {
          //     shapeOptions: {
          //       fill: 'rgba(0, 0, 0, 0)',
          //       stroke: 'rgba(0, 0, 0, 1)',
          //       strokeWidth: 2,
          //       borderColor: 'gray',
          //       type: 'rect',
          //       borderWidth: 2
          //     }
          //   },
          //   bindingsClassName: 'annotation-container',

          //   bindings: {
          //     inputText: {
          //       className: 'highcharts-inputText-annotation',
          //       start: function (e) {

          //         const xvalueAn = e.chartX - this.chart.plotLeft;
          //         const yvalueAn = e.chartY - this.chart.plotTop;
          //         var coords = this.chart.pointer.getCoordinates(e),
          //           x = coords.xAxis[0].value,
          //           y = coords.yAxis[0].value,
          //           navigation = this.chart.options.navigation,
          //           controlPoints = [{
          //             positioner: function (target) {
          //               if (!target.graphic.placed) {
          //                 return {
          //                   x: 0,
          //                   y: -9e7
          //                 };
          //               }
          //               return {
          //                 x: x -
          //                   this.graphic.width / 2,
          //                 y: y -
          //                   this.graphic.height / 2
          //               };
          //             },
          //             events: {
          //               drag: function (e, target) {
          //                 var xy = this.mouseMoveToTranslation(e);
          //                 target.translate(xy.x, xy.y);
          //                 target.annotation.labels[0].options =
          //                   target.options;
          //                 target.redraw(false);
          //               }
          //             }
          //           }];
          //         var _self = this;
          //         var labelTextForm = document.getElementById('labelTextForm');
          //         var onclick = function () {
          //           _self.chart.addAnnotation(Highcharts.merge({
          //             langKey: 'label',
          //             labelOptions: {
          //               format: labelTextForm.querySelector('#labelTextInput').value,
          //               shape: 'rect'
          //             },
          //             labels: [{
          //               point: {
          //                 xAxis: 0,
          //                 yAxis: 0,
          //                 x: x,
          //                 y: y
          //               },
          //               overflow: 'none',
          //               crop: false,
          //               text: labelTextForm.querySelector('#labelTextInput').value,
          //               controlPoints: controlPoints
          //             }]
          //           }, navigation
          //             .annotationsOptions, navigation
          //               .bindings
          //               .labelAnnotation
          //             .annotationsOptions));
          //         };

          //         Highcharts.css(
          //           labelTextForm, {
          //           top: (e.pageY + 15) + 'px',
          //           left: e.pageX + 'px',
          //           display: 'block'
          //         }
          //         );

          //         var unbinder = Highcharts.addEvent(
          //           labelTextForm.querySelector('#labelTextButton'),
          //           'click',
          //           function () {
          //             onclick();

          //             Highcharts.css(labelTextForm, {
          //               display: 'none'
          //             });

          //             unbinder();
          //           }
          //         )

          //         return true;
          //       }
          //     },
          //     rectangleAnnotation: {
          //       start: function (e) {

          //         var coords = this.chart.pointer.getCoordinates(e),
          //           navigation = this.chart.options.navigation,
          //           x = coords.xAxis[0].value,
          //           y = coords.yAxis[0].value,
          //           controlPoints = [{
          //             positioner: function (annotation) {
          //               var xy = Highcharts.Annotation.MockPoint
          //                 .pointToPixels(
          //                   annotation.shapes[0].points[2]
          //                 );

          //               return {
          //                 x: xy.x - 4,
          //                 y: xy.y - 4
          //               };
          //             },
          //             events: {
          //               drag: function (
          //                 target
          //               ) {
          //                 var coords = this.chart.pointer.getCoordinates(e),
          //                   x = coords.xAxis[0].value,
          //                   y = coords.yAxis[0].value,
          //                   shape = target.options.shapes[0],
          //                   points = shape.points;

          //                 // Top right point
          //                 points[1].x = x;
          //                 // Bottom right point (cursor position)
          //                 points[2].x = x;
          //                 points[2].y = y;
          //                 // Bottom left
          //                 points[3].y = y;

          //                 target.options.shapes[0].points = points;

          //                 target.redraw(false);
          //               }
          //             }
          //           }];

          //         return this.chart.addAnnotation(
          //           Highcharts.merge({
          //             langKey: 'rectangle',
          //             shapes: [{
          //               type: 'path',
          //               points: [{
          //                 xAxis: 0,
          //                 yAxis: 0,
          //                 x: x,
          //                 y: y
          //               }, {
          //                 xAxis: 0,
          //                 yAxis: 0,
          //                 x: x,
          //                 y: y
          //               }, {
          //                 xAxis: 0,
          //                 yAxis: 0,
          //                 x: x,
          //                 y: y
          //               }, {
          //                 xAxis: 0,
          //                 yAxis: 0,
          //                 x: x,
          //                 y: y
          //               }, {
          //                 // workaround: add one more point:
          //                 xAxis: 0,
          //                 yAxis: 0,
          //                 x: x,
          //                 y: y
          //               }]
          //             }],
          //             controlPoints: controlPoints
          //           },
          //             navigation
          //               .annotationsOptions,
          //             navigation.bindings
          //               .rectangleAnnotation
          //               .annotationsOptions
          //           )
          //         );
          //       }
          //     }
          //   }
          // },
          // yAxis1: [{
          //   height: '60%'
          // }, {
          //     top: '60%',
          //     height: '20%'
          // }, {
          //     top: '80%',
          //     height: '10%'
          // }],
        
          yAxis1: [{
            maxPadding: this.props.lock_price_to_bar_ratio_val,
            labels: {
              allowOverlap: loOverlappingLabel
            },
            clickOnCrosshair: function () {
              console.log(this);
            },
            crosshair: {

              snap: false,
              width: 0.5,
              color: 'black',
              label: {
                backgroundColor: 'rgb(255,0,0)',
                events: {
                  dblclick: function () {
                    console.log('a')
                  },
                  click: function () {
                    console.log('b')
                  },
                  contextmenu: function () {
                    console.log('c')
                  }
                },
                formatter: function (value) {

                  return (plusButton === 'true' || plusButton === true) ? '<span id="' + Number(value).toFixed(2) + '" class="plusAddAlert fa fa-solid fa-eye">Add Alert  ' + Number(value).toFixed(2) + '</span><div id="myDIV">This is my DIV element.</div >' : '';
                },
                enabled: plusButton,
                padding: 8
              }
            },
            plotLines: [{
              color: 'red',
              width: 2,
              value: this.state.hignlinevalue,
              label: {
                align: this.props.scale_placement,
                x: -20,
                text: '<div class="high_value_plot plot_line_dyn">' + this.toggleHighLine(this.props.sym_highlowlast_label) + '</div>'
              }
            },
            {
              color: 'red',
              width: 2,
              value: this.state.lowerlinevalue,
              label: {
                align: this.props.scale_placement,
                x: -20,
                text: '<div class="high_value_plot plot_line_dyn">' + this.toggleLowerLine(this.props.sym_highlowlast_label) + '</div>'
              }
            },
            {
              color: 'red',
              width: 2,
              value: this.state.lastLineValue,
              label: {
                align: this.props.scale_placement,
                x: -20,
                text: '<div class="high_value_plot plot_line_dyn">' + this.toggleLastLine(this.props.sym_highlowlast_label) + '</div>'
              }
            },
            //@todo - Rohit Saini- Make it dynamic
           

            ],
            lineWidth: 1,
            opposite: this.returnScalePosition(),
            minRange: 0.001,
            gridLineWidth: this.props.grid,

            labels: {
              align: 'right',
              x: -3
            },
            title: {
              text: ''
            },
            height: '60%',
            resize: {
              enabled: true
            }
          }, {
            lineWidth: 1,
            opposite: this.returnScalePosition(),
            minRange: 0.001,

            gridLineWidth: this.props.grid,
            labels: {
              align: 'right',
              x: -3
            },
            title: {
              text: ''
            },
            top: '65%',
            height: '35%',
            offset: 0
          }
          ],
         
          navigator: {
            adaptToUpdatedData: false,
            enabled: false
          },
          
          xAxis: { 
            type: 'datetime',
            min: new Date().setDate(new Date().getDate() - 90),
            max: new Date().getTime(new Date().getDate() -1)
 
          },
          yAxis: [{
              top: '0%',
              height: '80%', 
          },{
              top: '60%',
              height: '40%', 
              labels: {
                enabled: false
              }, 
              gridLineWidth: 0 
          },{
              top: '80%',
              height: '20%'
          },{
             top: '60%',  
             height: '25%'
          },{
             top: '60%',  
             height: '25%'
          },{
             top: '60%',  
             height: '25%'
          },{
             top: '60%',  
             height: '25%'
          },{
            top: '70%',
            height: '30%',
            offset: 0
          }],
          plotOptions: {
            series: {
                point: {
                    events: {
                      click: function(e){
                        //icon click work by bhupender
                        if(!e.point.hasOwnProperty('high'))
                        {
                          var attr = $(e.point.graphic.element).attr('fill');
                          if (typeof attr !== 'undefined' && attr !== false) {
                            // ...
                        }
                        else
                        {
                          $(e.point.graphic.element).attr('fill','black');
                          $(e.point.graphic.element).removeAttr('stroke');
                        }

                          
                          // $("#"+e.point.id).hide();
                          $('#exmModel').val(e.point.id);
                          var id=e.point.id.replace(/['"]+/g, '');
                          console.log("id is",id);
                          var textCode=localStorage.getItem('textcode');
                          $('.icon_text_change').css("display",'none');
                          if(textCode!=null && textCode!='unset')
                          {
                            var textCode=JSON.parse(textCode);
                            textCode.forEach(element => {
                              console.log("element  is",element);
                              if(id==element)
                              {
                               
                                $('.icon_text_change').val('');
                                $('.icon_text_change').css("display",'block');
                              }
                            });
                          }
                          else
                          {
                            $('.icon_text_change').css("display",'none');
                          }
                          window.$('#exampleModal').modal('show');
                        }
                        else
                        {
                          var series_name = this.series.name;
                          console.log('series_name',series_name)
                          if(series_name !== 'volume' && series_name != ''){ 
                            $('.display_indicator_name').slideDown().html(series_name).css({ "backgroundColor": this.series.color}); 
                          }
                        }
                            
                        }
                    },
                },
                events: {
                    mouseOut: function () { 
                      $('.display_indicator_name').slideUp()
                    }
                }
            }
            // series: {
            //   color: 'red',
            //   groupPadding: 0,
            //   pointPadding: 0.2,
            //   borderWidth: 0,
            //   name: (this.props.statusLineSymbol === true || this.props.statusLineSymbol === 'true') ? this.getConditionalSymbolData(this.props.symbolVariation) : '',
            //   point: {
            //     events: {
            //       mouseOver: function () {
            //          //alert('ji');
            //         // console.log('this_',this)
            //         var chart = this.series.chart;
            //         if (!chart.lbl) {
            //           chart.lbl = chart.renderer.label('').attr({
            //             padding: 10,
            //             r: 10,
            //             fill: "green"
            //           }).css({
            //             color: 'red',
            //             background: "#000000"
            //           }).add();
            //         } else {
            //           chart.lbl.fill = "green";
            //         }
            //         // console.log('this');
            //         // console.log(chart.lbl.fill);
            //         //console.log('ddddd', chart);
            //         // console.log(this);
            //         // console.log('this');
            //         if (this.options.high) { 
            //           // console.log('object----' + ohlcValue);
            //           if (ohlcValue === 'true' || ohlcValue === true) {
            //             //alert('1')
            //             // console.log('object----' + JSON.stringify(this));
            //             chart.lbl
            //               .show()
            //               .attr({
            //                 text: '<span style="fill:green;z-index:100" id="market_status">●</span>  <div id="">O: <div class="l_open">' + this.open + '</div>  H: <div class="l_high">' + this.high + '</div>  L: <div class="l_low">' + this.low + '</div>  C1: <div class="l_close">' + this.y + '</div>  <div id="l_change">' + barChangeValue + '</div></div>'
            //               });
            //           }
            //         } else {   
            //           if (volValue === 'true' || volValue === true) {
            //             chart.lbl.show().attr({ 
            //               text: 'Vol: <div class="l_volume" style="z-index:1000">' + this.y
            //             });
            //           }
            //         }

            //       }
            //     }
            //   },
            //   // ,
            //   // events: {
            //   //   mouseOut: function () {
            //   //     if (this.chart.lbl) {
            //   //       alert('11')
            //   //       // this.chart.lbl.hide();
            //   //     }
            //   //   }
            //   // }
            // }
        },
          series: [
            {
              id: 'aapl',
              type: this.props.graph,
              name: (this.props.statusLineSymbol === true || this.props.statusLineSymbol === 'true') ? this.getConditionalSymbolData(this.props.symbolVariation) : '',
              data: this.state.stockData, 
              tooltip: {
                valueDecimals: 2
              },
              useOhlcData: false, 
              dataGrouping: {} 
            },
            {
                type: 'column',
                name: '',
                yAxis: 1,
                color:'#8087E8',
                data: this.state.volume,
                id: 'volume', 
                //yAxis1:1 
                //dataGrouping: {},
                //yAxis: (localStorage.getItem("enable_hekashi") != null)?2:'',
                //visible: (localStorage.getItem("enable_hekashi") != null)?false:true
            },
            
            // {
            //   type:'line',
            //   linkedTo:'aapl',
            //   data:[0, 0, 119.57666666666667, 119.57333333333334, 119.47666666666667, 119.53666666666668, 119.60333333333334, 119.93, 122.01, 122.39666666666666, 121.54333333333334, 118.48333333333333, 116.75666666666666, 114.91666666666667, 115.56333333333333, 116.87333333333333, 119.90333333333332, 121.17333333333333, 121.39999999999999, 121.84333333333332, 123.59666666666668, 125.26666666666667, 126.32666666666667, 124.42666666666666, 124.29333333333334, 124.86000000000001, 127.23, 126.57666666666667, 126.14666666666669, 126.65333333333335, 127.85666666666668, 127.94333333333334, 126.84666666666668, 125.2, 121.40000000000002, 118.03333333333335, 116.57000000000001, 115.67666666666666, 115.27, 114.32666666666667, 113.94666666666667, 113.89]
            // } 
          ],
    };

    this.setState({
        seriesData : options
    })

    
    }, 5000);

  
    $('body').on('click', '.extraIcon', function() {
        $('#exmModel').val($(this).parent().attr('id'));
        window.$('#exampleModal').modal('show');
    });


    $('body').on('click', '.plusAddAlert', function () {
      let value = $(this).attr('id');

      $("input[name='cond_value']").val(value);
      window.$('#alertModal').modal('show');
      // this.iconClicked();
      //   // alert('nhjj');
      //   // this.setState({
      //   //   showModal:true
      //   // })
    });

  }
  AddIndicatorName(indicator_name,indicator_color){
      this.setState({
          indicator_setting_name:indicator_name,
          indicator_line_color:indicator_color
      });
  }

  handleCrossWidthChange = (event) => {
    //handle cross width change 
    $('.highcharts-crosshair').css('stroke-width',event.target.value);
  }
  handleCrossHairClick = () => {
    this.setState({ displayCrossHairColorPicker: !this.state.displayCrossHairColorPicker })
  };
  handleCrossHairClose = () => {
  this.setState({ displayCrossHairColorPicker: false });
  };

  handleCrossHairColorChange = (color) => {
    
this.setState({ displayCrossHairColorPicker: color.rgb });

  setTimeout(function () {
    $(".highcharts-crosshair").each(function() {
        $( this ).css('stroke', color.hex);
    });
  }, 3000); 

};
handleCrossWidthColorChange = (event) => {            
  //handle cross width change 
  $('.highcharts-crosshair path').css('stroke','#FFA500');

}

  handleCrossDelete = (event) => {
  $('.highcharts-crosshair').css('display','none');
  $('.highcharts-crosshair').each(function(){
      $(this).remove();
  });
  }
  componentDidUpdate(prevProps){
    //console.log('props',this.props) ddd
    //console.log('1111__',this.props.showTool)
    if(this.props.showTool == true){  
        $('.highcharts-toggle-toolbar').trigger('click'); 
        $('.highcharts-toggle-toolbar').hide();  
        $('#free_space').show();
    }
    if(this.props.showTool == false){ 
        $('.highcharts-toggle-toolbar').trigger('click');
        $('.highcharts-toggle-toolbar').hide(); 
        $('#free_space').hide();
    }
    if(this.props.get_indicator_prop_data !== 0){   
      console.log('123',this.props.get_indicator_prop_data);
      if(JSON.parse(localStorage.getItem('default_template'))?.length > 0){
        $('#template_display_div').show();
        $('.template_div_text').text(`Click to apply ${(JSON.parse(localStorage.getItem('default_template')))?JSON.parse(localStorage.getItem('default_template'))?.length:0} default template`) 
        $('.show_template_div').trigger('click');
      }
      if(JSON.parse(localStorage.getItem('indicator'))?.length > 0){ 
        $('#indicator_display_div').show();
        $('.indicator_div_text').text(`Click to apply ${(JSON.parse(localStorage.getItem('indicator')))?JSON.parse(localStorage.getItem('indicator'))?.length:0} Indicator`) 
        $('.show_indicator_div').trigger('click');
      } 
      if(this.state.is_indicator_delete != 3){
        this.setState({is_indicator_delete:3})
      } 
      this.render(); 
    }

    /** Add cross Hair **/
    if(this.props.crossHair){

      $('#isCallout').val('0');

      if(prevProps.crossHair !== this.props.crossHair) {
          var xAxis = this.state.seriesData['xAxis']; 
          var yAxis = this.state.seriesData['yAxis'];
          xAxis = {
              gridLineWidth: this.props.grid,
              crosshair: {
                  width: 3,
                  color: 'green',
                  enabled: true,
                  events: {
                      click: function () {
                          $("#showModalCrossHair").trigger('click');
                      }
                  }
              }
          };
          yAxis = {
              gridLineWidth: this.props.grid,
              crosshair: {
                  width: 3,
                  color: 'green',
                  events: {
                      click: function () {
                          $("#showModalCrossHair").trigger('click');
                      }
                  }
              },
              plotLines: [{
                  color: 'green',
                  width: 2,
                  value: this.state.hignlinevalue
              },
              {
                  color: 'red',
                  width: 2,
                  value: this.state.lowerlinevalue
              },
              {
                  color: 'red',
                  width: 2,
                  value: 133
              }]
          };
          var optionsArray = this.state.seriesData ; 
          optionsArray['xAxis'] = xAxis;
          optionsArray['yAxis'] = yAxis;
          //console.log('cross hairs :'+JSON.stringify(optionsArray));
          this.setState({
              seriesData : optionsArray
          });
      }
  }
  /** end add cross hair **/

    //console.log(this.props.newSeriesIndicator, 'sssssseeeeccccc');
    const alphaMines = 'rgba(' + this.props.alpha_color.r + ',' + this.props.alpha_color.g + ',' + this.props.alpha_color.b + ',' + this.props.alpha_color.a + ')';
    
    //const alphaMines = 'rgb(241,112,19,1)';

    $(".highcharts-label-box").attr("fill", alphaMines);
    if (this.state.background_color != alphaMines) {
      // console.log('hi')
      this.setState({ background_color: alphaMines })
    }
    var array = this.state.allData
    var arrayColumn = (array, column) => {
      return array.map(item => item[column]);
    };
    var highValues = arrayColumn(array, 'h');
    highValues.sort(function (a, b) {
      return b - a;
    });

    var array1 = this.state.allData
    var arrayColumn1 = (array1, column) => {
      return array.map(item => item[column]);
    };
    var lowerValues = arrayColumn1(array1, 'l');
    lowerValues.sort(function (a, b) {
      return a - b;
    });


    if (this.props.showHighLowerLine == true) {
      if (this.state.hignlinevalue == "") {
        this.setState({ hignlinevalue: highValues[0], lowerlinevalue: lowerValues[0], lastLineValue: highValues[highValues?.length - 1] })
      }


    } else if (this.props.showlastPriceLine == true) {

      if (this.state.lastLineValue == "") {
        this.setState({ lastLineValue: highValues[highValues?.length - 1] })
      }

    } else {
      if (this.state.hignlinevalue != "") {
        this.setState({ hignlinevalue: '', lowerlinevalue: '' })

      }
    }


    /* Arrow mark (slant line) */

      if(this.props.arrowMark){

          if(prevProps.arrowMark !== this.props.arrowMark) {

            var seriesArry = this.state.seriesData['series']; 
          var time = [this.state.initialTime + 59045738];

          for(var i = 1; i < 10; i++) {
              time[i] = time[i-1] + 1500;
          }

          // console.log("state initialTime time " + this.state.initialTime);
          // console.log("initial time " + time);

          var data1 = [[time[0], 10],[time[1], 20],[time[2], 30],[time[3], 40],[time[4], 50],[time[5], 40],[time[6], 30],[time[7], 20],[time[8], 10],[time[9], 0]];
          for(var i = 1; i < 10; i++) {
              time[i] = time[i-1] + 1500;
          }

          //console.log(data1);

          this.setState({
              initialTime: this.state.initialTime + 59045738  
          })

            seriesArry.push({
                type: 'scatter',
                cursor: 'move',
                draggableX: true,
                draggableY: true,
                dragDrop: {
                  draggableY: true,
                  draggableX: true
                },
                data: data1,
                name:'data'+this.props.count,
                pointStart: time[0],
                yAxis: 0,
                dataLabels: {
                  enabled: true,
                  useHTML: true,
                  format: "<div id='data"+this.props.count+"' class='left_arrow down_triger myDisv data"+this.props.count+"' data-id='"+this.props.count+"'><i class='extraIcon fa fa-long-arrow-down' aria-hidden='true'></i></div>",
                  y: 15
                },
                marker: {
                    enabled: false
                }
            })
         
            var optionsArray = this.state.seriesData ; 
            optionsArray['series'] = seriesArry;

            this.setState({
                seriesData : optionsArray
            });

          }

      }

      /* end arrow mark (slant line) */


      /* flag mark **/
      if(this.props.flagMark){

          if(prevProps.flagMark !== this.props.flagMark) {

            var seriesArry = this.state.seriesData['series']; 
          var time = [this.state.initialTime + 59045738];

          for(var i = 1; i < 10; i++) {
              time[i] = time[i-1] + 1500;
          }

          // console.log("state initialTime time " + this.state.initialTime);
          // console.log("initial time " + time);

          var data1 = [[time[0], 10],[time[1], 20],[time[2], 30],[time[3], 40],[time[4], 50],[time[5], 40],[time[6], 30],[time[7], 20],[time[8], 10],[time[9], 0]];
          for(var i = 1; i < 10; i++) {
              time[i] = time[i-1] + 1500;
          }

          //console.log(data1);

          this.setState({
              initialTime: this.state.initialTime + 59045738  
          })

            seriesArry.push({
                type: 'scatter',
                cursor: 'move',
                draggableX: true,
                draggableY: true,
                dragDrop: {
                  draggableY: true,
                  draggableX: true
                },
                data: data1,
                name:'data'+this.props.count,
                pointStart: time[0],
                yAxis: 0,
                dataLabels: {
                  enabled: true,
                  useHTML: true,
                  format: "<div id='data"+this.props.count+"' class=' flag_aarow myDisv data"+this.props.count+"' data-id='"+this.props.count+"' ><i class='extraIcon fa fa-flag' aria-hidden='true'></i></div>",
                  y: 15
                },
                marker: {
                    enabled: false
                }
            })
         
            var optionsArray = this.state.seriesData ; 
            optionsArray['series'] = seriesArry;

            this.setState({
                seriesData : optionsArray
            });

            //console.log('seriesData '+ this.state.seriesData);
          }

      }
      /* end flag mark  **/


      /* Balloon **/
      if(this.props.balloon){

          if(prevProps.balloon !== this.props.balloon) {

            var seriesArry = this.state.seriesData['series']; 
          var time = [this.state.initialTime + 59045738];

          for(var i = 1; i < 10; i++) {
              time[i] = time[i-1] + 1500;
          }

          // console.log("state initialTime time " + this.state.initialTime);
          // console.log("initial time " + time);

          var data1 = [[time[0], 10],[time[1], 20],[time[2], 30],[time[3], 40],[time[4], 50],[time[5], 40],[time[6], 30],[time[7], 20],[time[8], 10],[time[9], 0]];
          for(var i = 1; i < 10; i++) {
              time[i] = time[i-1] + 1500;
          }

          //console.log(data1);

          this.setState({
              initialTime: this.state.initialTime + 59045738  
          })

            seriesArry.push({
                type: 'scatter',
                cursor: 'move',
                draggableX: true,
                draggableY: true,
                dragDrop: {
                  draggableY: true,
                  draggableX: true
                },
                data: data1,
                name:'data'+this.props.count,
                pointStart: time[0],
                yAxis: 0,
                dataLabels: {
                  enabled: true,
                  useHTML: true,
                  format: "<div id='data"+this.props.count+"' class=' comment_outer myDisv data"+this.props.count+"' data-id='"+this.props.count+"' ><p class='extraIcon balloon_text'>Comment</p></div>",
                  y: 15
                },
                marker: {
                    enabled: false
                }
            })
         
            var optionsArray = this.state.seriesData ; 
            optionsArray['series'] = seriesArry;

            this.setState({
                seriesData : optionsArray
            });

            //console.log('seriesData '+ this.state.seriesData);
          }

      }
      /* end ballon **/
      
      /*  arrow left */
      if(this.props.arrowLeft){

          if(prevProps.arrowLeft !== this.props.arrowLeft) {

            var seriesArry = this.state.seriesData['series']; 
          var time = [this.state.initialTime + 59045738];

          for(var i = 1; i < 10; i++) {
              time[i] = time[i-1] + 1500;
          }

          // console.log("state initialTime time " + this.state.initialTime);
          // console.log("initial time " + time);

          var data1 = [[time[0], 10],[time[1], 20],[time[2], 30],[time[3], 40],[time[4], 50],[time[5], 40],[time[6], 30],[time[7], 20],[time[8], 10],[time[9], 0]];
          for(var i = 1; i < 10; i++) {
              time[i] = time[i-1] + 1500;
          }

          //console.log(data1);

          this.setState({
              initialTime: this.state.initialTime + 59045738  
          })

            seriesArry.push({
                type: 'scatter',
                cursor: 'move',
                draggableX: true,
                draggableY: true,
                dragDrop: {
                  draggableY: true,
                  draggableX: true
                },
                data: data1,
                name:'data'+this.props.count,
                pointStart: time[0],
                yAxis: 0,
                dataLabels: {
                  enabled: true,
                  useHTML: true,
                  format: "<div id='data"+this.props.count+"' class=' left_arrow data"+this.props.count+"' ><i class='fa fa-long-arrow-left extraIcon' aria-hidden='true'></i></div> ",
                  y: 15
                },
                marker: {
                    enabled: false
                }
            })
         
            var optionsArray = this.state.seriesData ; 
            optionsArray['series'] = seriesArry;

            this.setState({
                seriesData : optionsArray
            });

            //console.log('seriesData '+ this.state.seriesData);
          }

      }
      /* end arrow left */


      /* Arrow up **/
      if(this.props.arrowUp){

          if(prevProps.arrowUp !== this.props.arrowUp) {

            var seriesArry = this.state.seriesData['series']; 
          var time = [this.state.initialTime + 59045738];

          for(var i = 1; i < 10; i++) {
              time[i] = time[i-1] + 1500;
          }

          // console.log("state initialTime time " + this.state.initialTime);
          // console.log("initial time " + time);

          var data1 = [[time[0], 10],[time[1], 20],[time[2], 30],[time[3], 40],[time[4], 50],[time[5], 40],[time[6], 30],[time[7], 20],[time[8], 10],[time[9], 0]];
          for(var i = 1; i < 10; i++) {
              time[i] = time[i-1] + 1500;
          }

          //console.log(data1);

          this.setState({
              initialTime: this.state.initialTime + 59045738  
          })

            seriesArry.push({
                type: 'scatter',
                cursor: 'move',
                draggableX: true,
                draggableY: true,
                dragDrop: {
                  draggableY: true,
                  draggableX: true
                },
                data: data1,
                name:'data'+this.props.count,
                pointStart: time[0],
                yAxis: 0,
                dataLabels: {
                  enabled: true,
                  useHTML: true,
                  format: "<div id='data"+this.props.count+"' class='left_arrow top_arrow data"+this.props.count+"' data-id='"+this.props.count+"' ><i class='fa fa-long-arrow-up extraIcon' aria-hidden='true'></i></div>",
                  y: 15
                },
                marker: {
                    enabled: false
                }
            })
         
            var optionsArray = this.state.seriesData ; 
            optionsArray['series'] = seriesArry;

            this.setState({
                seriesData : optionsArray
            });

            //console.log('seriesData '+ this.state.seriesData);
          }

      }

      /* end arrow up **/



      /*  arrow right */
      if(this.props.arrowRight){

          if(prevProps.arrowRight !== this.props.arrowRight) {

           var seriesArry = this.state.seriesData['series']; 
          var time = [this.state.initialTime + 59045738];

          for(var i = 1; i < 10; i++) {
              time[i] = time[i-1] + 1500;
          }

          // console.log("state initialTime time " + this.state.initialTime);
          // console.log("initial time " + time);

          var data1 = [[time[0], 10],[time[1], 20],[time[2], 30],[time[3], 40],[time[4], 50],[time[5], 40],[time[6], 30],[time[7], 20],[time[8], 10],[time[9], 0]];
          for(var i = 1; i < 10; i++) {
              time[i] = time[i-1] + 1500;
          }

          //console.log(data1);

          this.setState({
              initialTime: this.state.initialTime + 59045738  
          })

            seriesArry.push({
                type: 'scatter',
                cursor: 'move',
                draggableX: true,
                draggableY: true,
                dragDrop: {
                  draggableY: true,
                  draggableX: true
                },
                data: data1,
                name:'data'+this.props.count,
                pointStart: time[0],
                yAxis: 0,
                dataLabels: {
                  enabled: true,
                  useHTML: true,
                  format: "<div id='data"+this.props.count+"' class=' left_arrow right_arrow data"+this.props.count+"'><i class='extraIcon fa fa-long-arrow-right' aria-hidden='true'></i></div>",
                  y: 15
                },
                marker: {
                    enabled: false
                }
            })
         
            var optionsArray = this.state.seriesData ; 
            optionsArray['series'] = seriesArry;

            this.setState({
                seriesData : optionsArray
            });

            //console.log('seriesData '+ this.state.seriesData);
          }

      }
      /*  end arrow right */

      /*  start arrow down */
      if(this.props.arrowDown){

          if(prevProps.arrowDown !== this.props.arrowDown) {

            var seriesArry = this.state.seriesData['series']; 
          var time = [this.state.initialTime + 59045738];

          for(var i = 1; i < 10; i++) {
              time[i] = time[i-1] + 1500;
          }

          // console.log("state initialTime time " + this.state.initialTime);
          // console.log("initial time " + time);

          var data1 = [[time[0], 10],[time[1], 20],[time[2], 30],[time[3], 40],[time[4], 50],[time[5], 40],[time[6], 30],[time[7], 20],[time[8], 10],[time[9], 0]];
          for(var i = 1; i < 10; i++) {
              time[i] = time[i-1] + 1500;
          }

          //console.log(data1);

          this.setState({
              initialTime: this.state.initialTime + 59045738  
          })

            seriesArry.push({
                type: 'scatter',
                cursor: 'move',
                draggableX: true,
                draggableY: true,
                dragDrop: {
                  draggableY: true,
                  draggableX: true
                },
                data: data1,
                name:'data'+this.props.count,
                pointStart: time[0],
                yAxis: 0,
                dataLabels: {
                  enabled: true,
                  useHTML: true,
                  format: "<div id='data"+this.props.count+"' class=' left_arrow down_arrow data"+this.props.count+"'><i class='fa fa-long-arrow-down extraIcon' aria-hidden='true'></i></div>",
                  y: 15
                },
                marker: {
                    enabled: false
                }
            });
         
            var optionsArray = this.state.seriesData ; 
            optionsArray['series'] = seriesArry;

            this.setState({
                seriesData : optionsArray
            });

            //console.log('seriesData '+ this.state.seriesData);
          }

      }
      /*  end arrow down */

      if(this.props.callout){

          if(prevProps.callout !== this.props.callout) {


            var seriesArry = this.state.seriesData['series']; 
          var time = [this.state.initialTime + 59045738];

          for(var i = 1; i < 10; i++) {
              time[i] = time[i-1] + 1500;
          }

          // console.log("state initialTime time " + this.state.initialTime);
          // console.log("initial time " + time);

          var data1 = [[time[0], 10],[time[1], 20],[time[2], 30],[time[3], 40],[time[4], 50],[time[5], 40],[time[6], 30],[time[7], 20],[time[8], 10],[time[9], 0]];
          for(var i = 1; i < 10; i++) {
              time[i] = time[i-1] + 1500;
          }

          //console.log(data1);

          this.setState({
              initialTime: this.state.initialTime + 59045738  
          })

            seriesArry.push({
                type: 'scatter',
                cursor: 'move',
                draggableX: true,
                draggableY: true,
                dragDrop: {
                  draggableY: true,
                  draggableX: true
                },
                data: data1,
                name:'data'+this.props.count,
                pointStart: time[0],
                yAxis: 0,
                dataLabels: {
                  enabled: true,
                  useHTML: true,
                  format: "<div id='data"+this.props.count+"' class=' left_arrow map_outer myDisv data"+this.props.count+"' data-id='"+this.props.count+"' ><i class='extraIcon fa fa-map-marker' aria-hidden='true'></i><div class='hover_show'><p>the readable content of a page when looking at its layout.</p></div></div>",
                  y: 15
                },
                marker: {
                    enabled: false
                }
            })
         
            var optionsArray = this.state.seriesData ; 
            optionsArray['series'] = seriesArry;

            this.setState({
                seriesData : optionsArray
            });

            //console.log('seriesData '+ this.state.seriesData);
          }

      }else{


    if (prevProps.marker !== this.props.marker) {

          console.log('nextProps.marker'+ this.props.marker + 'prevState.marker'+ prevProps.marker);

          // console.log('series -- dfsdfs' + JSON.stringify(this.state.seriesData)); 

          // console.log('series data' + JSON.stringify(this.state.seriesData['series'])); 

          var seriesArry = this.state.seriesData['series']; 
          var time = [this.state.initialTime + 59045738];

          for(var i = 1; i < 10; i++) {
              time[i] = time[i-1] + 1500;
          }

          // console.log("state initialTime time " + this.state.initialTime);
          // console.log("initial time " + time);

          var data1 = [[time[0], 10],[time[1], 20],[time[2], 30],[time[3], 40],[time[4], 50],[time[5], 40],[time[6], 30],[time[7], 20],[time[8], 10],[time[9], 0]];
          for(var i = 1; i < 10; i++) {
              time[i] = time[i-1] + 1500;
          }

          //console.log(data1);

          this.setState({
              initialTime: this.state.initialTime + 59045738  
          })

          seriesArry.push({
            zIndex: 11,
              type: 'scatter',
              dragDrop: {
                draggableY: true,
                draggableX: true
              },
              start:time[0],
              data: [
                [174.0, 65.6]
              ],
              name:'data'+this.props.count,
             // pointStart: time[0],
              states: { hover: { enabled: false } },
              dataLabels: {
                enabled: true,
                useHTML: true,
                format: "<div id='data"+this.props.count+"' class='myDisv data"+this.props.count+"'  data-id='"+this.props.count+"' data-toggle='modal' data-target='#exampleModal'><i class='extraIcon fa fa-solid  "+ this.props.marker +" ' ></i></div>",
                y: 15
              },
              marker: {
                  enabled: false
              }
          })

          var optionsArray = this.state.seriesData ; 
          optionsArray['series'] = seriesArry;

          this.setState({
            seriesData : optionsArray
          });

          console.log('New series data' + JSON.stringify(this.state.seriesData));

      }

    }
  }
 
 
  drawHighLine(stockData) {

    var arr = stockData;
    const groupAndSort = arr => {
      const res = [];
      const map = Object.create(null);
      Array.prototype.forEach.call(arr, item => {
        item.forEach(el => {
          if (!(el in map)) {
            map[el] = [];
            res.push(map[el]);
          };
          map[el].push(el);
        });
      });
      res.sort((a, b) => {
        return a[0] - b[0];
      });
      return res;
    };
    //console.log('jj')
    var alldata = groupAndSort(arr);
    var mid = parseInt(alldata?.length) / 2
    // console.log(alldata)
    var high = alldata[mid - 1];
    var low = alldata[0];
    // alert(high)

    this.setState({ hignlinevalue: high, lowerlinevalue: low })
    //console.log(groupAndSort(arr));
  }

  addParamInIndicator = (e) =>{ 
    if(e.target.id){ 
      var indicator_type = e.target.id.split('|')[0]
      var indicator_field_name = e.target.id.split('|')[1] 
      var indicator_field_value = e.target.value

      var existingData = [];
      existingData = JSON.parse(localStorage.getItem('indicator'));
      existingData.map((indicator_data,i) => { 
         if(indicator_data.type === indicator_type){
            if(indicator_field_name == 'width'){
                existingData[i].lineWidth = indicator_field_value 
                this.setState({line_width: e.target.value});
            }
            if(indicator_field_name == 'color'){
              existingData[i].color = indicator_field_value 
              this.setState({indicator_line_color: e.target.value});
          }
         } 
      });
      localStorage.setItem('indicator',JSON.stringify(existingData))
    }  
  }

  getConditionalSymbolData(symbolVariation) {
    switch (symbolVariation) {
      case '0':
        return this.props.symbolname;

      case '1':
        return this.props.symbol;

      case '2':
        return this.props.symbol + "(" + this.props.symbolname + ")"


      default:
        return this.props.symbol + "(" + this.props.symbolname + ")"

    }
  }


  toggleTicker(syb) {
    return (syb === true || syb === 'true') ? this.props.symbol : ''
  }

  toggleLastPrice(syb) {
    return (syb === true || syb === 'true') ? (this.props.last_price) ? this.props.last_price : '130' : ''
  }

  toggleLastPricePer(syb) {
    return (syb === true || syb === 'true') ? '-3.70%' : ''
  }

  toggleLastPriceCountDown(syb) {
    return (syb === true || syb === 'true') ? '130' : ''
  }

  toggleHighLine(syb) {
    return (syb === true || syb === 'true') ? 'High: ' + this.state.hignlinevalue : ''
  }
  toggleLowerLine(syb) {
    return (syb === true || syb === 'true') ? 'Low: ' + this.state.lowerlinevalue : ''
  }
  toggleLastLine(syb) {
    return (syb === true || syb === 'true') ? 'Avg: ' + this.state.lastLineValue : ''
  }

  getDateFormat() {
    return this.props.date_format_val;
  }

  returnScalePosition() {
    return (this.props.scale_placement === 'left') ? false : true;
  }
  async getResult() { 
    this.props
      .getStockValue(
        this.props.symbol,
        this.props.stock,
        this.props.time,
        this.props.mult,
        this.props.startd,
        this.props.endd
      )
      .then(() => {
        if (this.props.stockValue.error == null) {
          const stockArray = [];
          const volumeArray = [];
          if (this.props.stockValue.stockList) {
            var count = 0;
            this.props.stockValue.stockList.results &&
              this.props.stockValue.stockList.results.map(
                ({ o, h, l, c, v, t },k) => {
                  //if(k<300){
                    stockArray.push([t, o, h, l, c,v]) 
                  //}
                }
              );
              const monthNames = ["Jan", "Feb", "March", "April", "May", "June",
  "July", "Aug", "Sep", "Oct", "Nov", "Dec"
];
                  var resultArray=[];
              stockArray.map((obj,i) =>{  
                resultArray.push(monthNames[new Date(obj[0]).getMonth()]+"/n"+new Date(obj[0]).getDate());

              });

              this.setState({
                dateArray:resultArray
              });
              console.log(resultArray);
            localStorage.setItem('stock_chart_data', JSON.stringify(stockArray))
            this.props.stockValue.stockList.results.map(
              ({ o, h, l, c, v, t }) => volumeArray.push([t, v])
            );

            localStorage.setItem('stock_volume_chart_data', JSON.stringify(volumeArray))
            const array = this.props.stockValue.stockList.results
            var dps1=[];
            var dps2=[];
              //console.log('close',array)
            console.log('11_22_33',array)
            /// SMMA   
            var i=0,sma=[],sma_default=0,period=10,smooth_smma=[],j=0,close_price=[],n=0,smoothed_sma=[]
                //console.log('11_21111',array.length)
              // Getting All close Price
              for(i=0;i< array.length;i+=1){
                //console.log(`close_data_${i}`,array[i]['c'])
                  if (array[i]['c']) {
                      close_price.push(array[i]['c'])
                  }else{
                      close_price.push(0)
                  }
              }
              console.log('close_price',close_price)
              while (n < close_price.length - period + 1){
                //console.log('price_price',close_price[1])
                var sum = 0,price=close_price.slice(n,n+period)
                if (price){
                    for (var k=0;k<price.length;k++){
                        sum +=price[k]
                    }
                    sma.push(parseFloat((sum/period).toFixed(2)))
                }
                n+=1
              }
            
            console.log("Simple Moving Avg",sma)
            for(j=0;j<sma.length;j++){
                if (sma[j-1]){
                  sma_default = sma[j-1]
                }
                smooth_smma[j]=parseFloat((((sma_default * (period - 1)) + close_price[j])/period).toFixed(2))
                console.log('11_22_33_44',j)
                console.log('22_99',array.length)
                smoothed_sma.push({x:array[j+9].t,y:parseFloat((((sma_default * (period - 1)) + close_price[j])/period).toFixed(2))});
            }
            console.log("Smooth Moving Avg",smoothed_sma)
            localStorage.setItem('smma_indicator',JSON.stringify(smoothed_sma))
            //// SMMA 1659672000000  1659672000000


            for(var i = 0; i < array.length; i++){
              dps1.push({h:array[i].h,x: new Date(array[i].t), y: [Number(array[i].o), Number(array[i].h), Number(array[i].l), Number(array[i].c)]});
              //dps2.push({x: new Date(array[i].t), y: Number(array[i].c)});
            }
            console.log("Candle chart data array is ",array);
            var ema = this.calculateEMA(dps1, 7);
            var emaTest=[];
            var bullPower=[];
            var bearPower=[];
            var bearbull=[];
            for(var i=0;i<ema.length;i++)
            {
              emaTest.push(ema[i].y);
              bullPower.push({x:ema[i].x,y:(ema[i].h-ema[i].y)});
              bearPower.push({x:ema[i].x,y:(ema[i].h+ema[i].y)});
              bearbull.push({x:array[i].t,y:((ema[i].h+ema[i].y)+(ema[i].h-ema[i].y))});
            }
            //alert('11')
              console.log("Calculated ema is",ema);
              console.log("Calculated ema test is",emaTest);
              console.log("Calculated ema bull is",bullPower);
              console.log("Calculated ema bear is",bearPower);
              console.log("Calculated ema bear+bull is",bearbull);

             this.setState({
              bearBullArray:bearbull
             })
             localStorage.setItem("bearbullseries", JSON.stringify(bearbull));


            const arrayColumn = (array, column) => {
              return array.map(item => item[column]);
            };
            var highValues = arrayColumn(array, 'h');
            // console.log(highValues)
            // console.log('highValues')
            highValues.sort(function (a, b) {
              return b - a;
            });
            const array1 = this.props.stockValue.stockList.results
            const arrayColumn1 = (array1, column) => {
              return array.map(item => item[column]);
            };
            var lowerValues = arrayColumn1(array1, 'l');
            lowerValues.sort(function (a, b) {
              return a - b;
            });

            if (this.props.showHighLowerLine == true) {
              // alert('hii'); 
              this.setState({ hignlinevalue: highValues[0], lowerlinevalue: lowerValues[0], lastLineValue: highValues[highValues?.length - 1] })

            } else {
              this.setState({ hignlinevalue: '', lowerlinevalue: '' })
            }
            // console.log('stockArray',stockArray);
            // console.log('volumeArray',volumeArray);
            // console.log('this.props.stockValue.stockList.results',this.props.stockValue.stockList.results);
            this.setState(
              {
                stockData: stockArray,
                allData: this.props.stockValue.stockList.results,
                volume: volumeArray
              },
              () => {
                this.setState({ stockLoad: true });
              }
            );
          }
        }
      })
     

   

  }
  render() { 
    const stylesCrossHair = reactCSS({
      'default': {
      color: {
          width: '36px',
          height: '14px',
          borderRadius: '2px',
          background: `rgba(${ this.state.displayCrossHairColorPicker.r }, ${ this.state.displayCrossHairColorPicker.g }, ${ this.state.displayCrossHairColorPicker.b }, ${ this.state.displayCrossHairColorPicker.a })`,
      },
      swatch: {
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
      },
      popover: {
          position: 'absolute',
          zIndex: '2',
      },
      cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
      },
      },
  });


    console.log('render')
    const { stockError, background_color } = this.state;
    
    let rangeSelected = 1;
    if (this.props.time == "minute" || this.props.time == "hour") {
      rangeSelected = 0;
    }
    const data = [[1642514400000, 142.71, 142.71, 142.71, 142.71]];
    Highcharts.theme = {
      // some styling
    };
    const options2 = this.state.seriesData;
    console.log('options2')
    console.log(options2)
    let ohlcValue = this.props.ohlcValue
    let barChangeVal = this.props.bar_change_val
    let marketStatus = this.props.market_status
    let volValue = this.props.volValue
    let prePostMktPrice = (this.props.pre_post_mkt_price === 'true' || this.props.pre_post_mkt_price === true) ? '120' : '';
    let barChangeValue = (this.props.bar_change_val === 'true' || this.props.bar_change_val === true) ? '−0.40 (−0.81%)' : '';
    let loOverlappingLabel = (this.props.lo_overlapping_label === 'true' || this.props.lo_overlapping_label === true) ? false : true;
    let plusButton = (this.props.addPlusAlert === 'true' || this.props.addPlusAlert === true) ? true : false;
    var myElemOHLC = document.getElementById('ohlc_vals');
    var myElemVol = document.getElementById('vol_vals');
    var l_change = document.getElementById('l_change');
    var market_status = document.getElementById('market_status');
    const styles = reactCSS({
      'default': {
        color: {
          width: '36px',
          height: '14px',
          borderRadius: '2px',
          background: `rgba(${ this.state.color.r }, ${ this.state.color.g }, ${ this.state.color.b }, ${ this.state.color.a })`,
        },
        swatch: {
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
      },
    });

    if (market_status !== null) {
      if (marketStatus === 'true' || marketStatus === true) {
        market_status.style.display = 'block';
      } else {
        market_status.style.display = 'none';
      }
    }

    if (l_change !== null) {
      if (barChangeVal === 'true' || barChangeVal === true) {
        l_change.style.display = 'block';
      } else {
        l_change.style.display = 'none';
      }
    }

    if (myElemOHLC !== null) {
      if (ohlcValue === 'true' || ohlcValue === true) {
        myElemOHLC.style.display = 'block';
      } else {
        myElemOHLC.style.display = 'none';
      }
    }

    if (myElemVol !== null) {
      if (volValue === 'true' || volValue === true) {
        myElemVol.style.display = 'block';
      } else {
        myElemVol.style.display = 'none';
      }
    }

    console.log(this.state.background_color)
    console.log('this.props.alpha_color')
    const goldmine = this.props.date_format_val;
    const alphaMine = 'rgba(' + this.props.alpha_color.r + ',' + this.props.alpha_color.g + ',' + this.props.alpha_color.b + ',' + this.props.alpha_color.a + ')';
  
    Highcharts.Annotation.prototype.addClipPaths = function () { };
    Highcharts.Annotation.types.crookedLine.prototype.setClipAxes = function () { };
    
    Highcharts.setOptions({
      lang: {
        stockTools: {
          gui: {
            inputText: 'Text annotation from input'
          }
        },
        navigation: {
          popup: {
            inputText: 'Input text'
          }
        }
      }
    });

    if (market_status !== null) {
      if (marketStatus === 'true' || marketStatus === true) {
        market_status.style.display = 'block';
      } else {
        market_status.style.display = 'none';
      }
    }

    if (l_change !== null) {
      if (barChangeVal === 'true' || barChangeVal === true) {
        l_change.style.display = 'block';
      } else {
        l_change.style.display = 'none';
      }
    }

    if (myElemOHLC !== null) {
      if (ohlcValue === 'true' || ohlcValue === true) {
        myElemOHLC.style.display = 'block';
      } else {
        myElemOHLC.style.display = 'none';
      }
    }

    if (myElemVol !== null) {
      if (volValue === 'true' || volValue === true) {
        myElemVol.style.display = 'block';
      } else {
        myElemVol.style.display = 'none';
      }
    }

    
    //const goldmine = this.props.date_format_val;
    //const alphaMine = 'rgba(' + this.props.alpha_color.r + ',' + this.props.alpha_color.g + ',' + this.props.alpha_color.b + ',' + this.props.alpha_color.a + ')';
    // var chartSeries = this.state.add_series_data;
    const options = {
      chart: {
        type: "candlestick",
        height: 700,
        events: { 

          load: function (e) {
            var chart = this;
            chart.showLoading('Loading...');
            $('.abc').on('DOMSubtreeModified click', function(){
              setTimeout(function (chart_update){ 
              var seriesData = JSON.parse(localStorage.getItem('indicator'))
              if(seriesData !== null){
                if(seriesData?.length>0){
                  seriesData.forEach(function(data){
                    chart_update.addSeries({
                        type: data.type,
                        linkedTo: 'aapl',
                        //id: 'overlay',
                        lineWidth: 0.6,
                        color:'green',
                        marker: {
                          enabled: false
                        } 
                      });
                  });
                }
              }
            },1000,chart); 

            setTimeout(function (chart_update){ 
              var seriesData = JSON.parse(localStorage.getItem('oscilator'))
              if(seriesData != null){ 
                  seriesData.forEach(function(data){                   
                  chart_update.addSeries({ 
                      type: data.type,
                      linkedTo: 'aapl',
                      //id: 'overlay',
                      lineWidth: 0.6,
                      color:'green',
                      marker: {  
                        enabled: false
                      } 
                  });
                })
              }
            },1000,chart);
            
            setTimeout(function (chart_update){ 
              var seriesData = JSON.parse(localStorage.getItem('ma_template'))
              if(seriesData !== null){
                if(seriesData?.length>0){ 
                  seriesData.forEach(function(data,index){
                    if(index>0){
                      chart_update.addSeries(data) 
                    }
                  });
                }
              }
            },1000,chart);

            setTimeout(function (chart_update){ 
              var seriesData = JSON.parse(localStorage.getItem('bill_template'))
              if(seriesData !== null){
                if(seriesData?.length>0){ 
                  seriesData.forEach(function(data,index){
                    if(index>0){
                      chart_update.addSeries(data) 
                    }
                  });
                }
              }
            },1000,chart);

            setTimeout(function (chart_update){ 
              var seriesData = JSON.parse(localStorage.getItem('aligator_template'))
              if(seriesData !== null){
                if(seriesData?.length>0){ 
                  seriesData.forEach(function(data,index){
                    if(index>0){
                      chart_update.addSeries(data) 
                    }
                  });
                }
              }
            },1000,chart);

            setTimeout(function (chart_update){ 
              var seriesData = JSON.parse(localStorage.getItem('displaced_template'))
              console.log('displaced_template')
              console.log(seriesData)
              if(seriesData !== null){
                if(seriesData?.length>0){ 
                  seriesData.forEach(function(data,index){
                    if(index>0){
                      chart_update.addSeries(data) 
                    }
                  });
                }
              }
            },1000,chart);

            }); 
             
            $('.abc').trigger('click');
            document.getElementById('oscillators').addEventListener('change', function (e) {
                var series = chart.get('oscillator');
               alert('aa')
                //if (series) {
                    //series.remove(false);
                    chart.addSeries(
                      {
                        type: 'rsi',
                        id: 'oscillator',
                        linkedTo: 'aapl',
                        yAxis: 1
                    }
                  );
                    
                //}
            });
           // this.reflow()
          }
        }
        //backgroundColor: this.state.backgroundColor,
      },

      // plotOptions: {
      //   candlestick: {
      //     color: this.props.up_color_color,
      //     upColor: this.props.down_color_color
      //   },
      //   series: {
      //     color: '#FF0000'
      //   }
      // },
      title: {
        text: (this.props.status_line_symbol === true ) ? this.getConditionalSymbolData(this.props.symbolVariation) : '',
      },
      // xAxis: {
      //   gridLineWidth: this.props.grid,
      // },

      xAxis: {

        crosshair: {
          text: 'asdasdas',
          snap: false,
          value: '3434',
          label: {
            enabled: true,
            format: '{value:.1f}' // One decimal
          }
        },

        labels: {
          allowOverlap: loOverlappingLabel
        },
        type: 'datetime',
        dateTimeLabelFormats: {
          day: '%b %e'
        }
      },
      plotOptions: {
        candlestick: {
          color: this.props.up_color_color,
              upColor: this.props.down_color_color,
              upLineColor:this.props.down_color_color,
              lineColor:this.props.up_color_color
        },
        series: {
          color: '#FF0000',
          allowPointSelect: true,
          name: (this.props.statusLineSymbol === true || this.props.statusLineSymbol === 'true') ? this.getConditionalSymbolData(this.props.symbolVariation) : '',
          point: {

            events: {
                drag: function (e) {
                    // Returning false stops the drag and drops. Example:
                    /*
                    if (e.newY > 300) {
                        this.y = 300;
                        return false;
                    }
                    */
                    $('#drag').html(
                        'Dragging ' + this.series.name + ' point to <b>[' + Highcharts.numberFormat(e.x, 2)  + ', ' + Highcharts.numberFormat(e.y, 2) + ']</b>');
                },
                drop: function () {
                    $('#drop').html(
                       'Dropped ' + this.series.name + ' at <b>[' + Highcharts.numberFormat(this.x, 2)  + ', ' + Highcharts.numberFormat(this.y, 2) + ']</b>');
                },
              mouseOver: function () {
                // console.log('ji');

                var chart = this.series.chart;
                if (!chart.lbl) {
                  chart.lbl = chart.renderer.label('').attr({
                    padding: 10,
                    r: 10,
                    fill: background_color
                  }).css({
                    color: '#000000',
                    background: "#000000"
                  }).add();
                } else {



                  chart.lbl.fill = "green";
                }
                // console.log('this');
                // console.log(chart.lbl.fill);
                console.log('ddddd', chart);
                // console.log(this);
                // console.log('this');
                if (this.options.high) {
                  // console.log('object----' + ohlcValue);
                  if (ohlcValue === 'true' || ohlcValue === true) {
                    // console.log('object----' + JSON.stringify(this));
                    chart.lbl
                      .show()
                      .attr({
                        text: '<span style="fill:green" id="market_status1">●</span>  <div id="ohlc_vals">O: <div class="l_open">' + this.open + '</div>  H: <div class="l_high">' + this.high + '</div>  L: <div class="l_low">' + this.low + '</div>  C: <div class="l_close">' + this.y + '</div>  <div id="l_change">' + barChangeValue + '</div></div>'
                      });
                  }
                } else {
                  if (volValue === 'true' || volValue === true) {
                    chart.lbl
                      .show()
                      .attr({
                        text: 'Vol: <div class="l_volume">' + this.y
                      });
                  }
                }

              }
            }
          },
          events: {
           
            mouseOut: function () {
              if (this.chart.lbl) {
                // this.chart.lbl.hide();
              }
            }
          }
        }
      },
      rangeSelector: {
        enabled: false,
      },
      tooltip: {
        enabled: true,
        formatter: function () {
          const selectedFormat = document.getElementById("dateFormat").value;
          return ['<b>' + Highcharts.dateFormat('' + selectedFormat + ' %H:%M', this.x) + '</b>'].concat();
        }
      },
      stockTools: {
        gui: {
          enabled: false,
          //buttons: ['thresholds', 'separator', 'simpleShapes', 'lines', 'crookedLines', 'measure', 'advanced', 'toggleAnnotations', 'separator', 'verticalLabels', 'flags', 'separator', 'zoomChange', 'fullScreen', 'typeChange', 'separator', 'currentPriceIndicator', 'saveChart'],
          definitions: {
            inputText: {
                className: 'highcharts-inputText-annotation',
                symbol: 'label.svg'
            },
            thresholds: {
                className: 'highcharts-threshold-annotation',
                symbol: 'horizontal-line.svg'
            }
          }
        }
      },

      navigation: {
        annotationsOptions: {
          shapeOptions: {
            fill: 'rgba(0, 0, 0, 0)',
            stroke: 'rgba(0, 0, 0, 1)',
            strokeWidth: 2,
            borderColor: 'gray',
            type: 'rect',
            borderWidth: 2
          }
        },
        bindingsClassName: 'annotation-container',

        bindings: {
          thresholds: {
              className: 'highcharts-threshold-annotation',
              init: function(e) {
                  alert("test alert 2");
              },
              // start: function(event) {
              //   var chart = this.chart,
              //     x = chart.xAxis[0].toValue(event.chartX),
              //     y = chart.yAxis[0].toValue(event.chartY),
              //     colors = chart.options.colors,
              //     series = chart.series[0],
              //     zones = series.userOptions.zones || [];

              //   chart.customColorIndex = chart.customColorIndex || 1;

              //   chart.customColorIndex++;

              //   if (
              //     chart.customColorIndex === colors?.length
              //   ) {
              //     chart.customColorIndex = 1;
              //   }

              //   zones.push({
              //     color: colors[chart.customColorIndex],
              //     value: y
              //   });
              //   chart.addAnnotation({
              //     langKey: 'thresholds',
              //     zoneIndex: zones?.length - 1,
              //     type: 'infinityLine',
              //     draggable: 'y',
              //     events: {
              //       drag: function(e) {
              //         var newZones = series.userOptions.zones;
              //         newZones[this.userOptions.zoneIndex].value = chart.yAxis[0].toValue(e.chartY);
              //         chart.series[0].update({
              //             zones: newZones
              //         });
              //       }
              //     },
              //     typeOptions: {
              //       type: 'horizontalLine',
              //       points: [{
              //         x: x,
              //         y: y
              //       }]
              //     }
              //   });

              //   chart.series[0].update({
              //     zones: zones
              //   });
              // }
          },
          
          inputText: {
            className: 'highcharts-inputText-annotation',
            start: function (e) {

              const xvalueAn = e.chartX - this.chart.plotLeft;
              const yvalueAn = e.chartY - this.chart.plotTop;
              var coords = this.chart.pointer.getCoordinates(e),
                x = coords.xAxis[0].value,
                y = coords.yAxis[0].value,
                navigation = this.chart.options.navigation,
                controlPoints = [{
                  positioner: function (target) {
                    if (!target.graphic.placed) {
                      return {
                        x: 0,
                        y: -9e7
                      };
                    }
                    return {
                      x: x -
                        this.graphic.width / 2,
                      y: y -
                        this.graphic.height / 2
                    };
                  },
                  events: {
                    drag: function (e, target) {
                      var xy = this.mouseMoveToTranslation(e);
                      target.translate(xy.x, xy.y);
                      target.annotation.labels[0].options =
                        target.options;
                      target.redraw(false);
                    }
                  }
                }];
              var _self = this;
              var labelTextForm = document.getElementById('labelTextForm');
              var onclick = function () {
                _self.chart.addAnnotation(Highcharts.merge({
                  langKey: 'label',
                  labelOptions: {
                    format: labelTextForm.querySelector('#labelTextInput').value,
                    shape: 'rect'
                  },
                  labels: [{
                    point: {
                      xAxis: 0,
                      yAxis: 0,
                      x: x,
                      y: y
                    },
                    overflow: 'none',
                    crop: false,
                    text: labelTextForm.querySelector('#labelTextInput').value,
                    controlPoints: controlPoints
                  }]
                }, navigation
                  .annotationsOptions, navigation
                    .bindings
                    .labelAnnotation
                  .annotationsOptions));
              };

              Highcharts.css(
                labelTextForm, {
                top: (e.pageY + 15) + 'px',
                left: e.pageX + 'px',
                display: 'block'
              }
              );

              var unbinder = Highcharts.addEvent(
                labelTextForm.querySelector('#labelTextButton'),
                'click',
                function () {
                  onclick();

                  Highcharts.css(labelTextForm, {
                    display: 'none'
                  });

                  unbinder();
                }
              )

              return true;
            }
          }
        }
      },
      yAxis: [{
        maxPadding: this.props.lock_price_to_bar_ratio_val,
        labels: {
          allowOverlap: loOverlappingLabel
        },
        clickOnCrosshair: function () {
          console.log(this);
        },
        crosshair: {
          useHTML: true,
          snap: false,
          width: 0.5,
          color: 'black',
          label: {
            useHTML: true,
            backgroundColor: 'rgb(255,0,0)',
            events: {
              dblclick: function () {
                console.log('a')
              },
              click: function () {
                console.log('b')
              },
              contextmenu: function () {
                console.log('c')
              }
            },
            formatter: function (value) {
              return (plusButton === 'true' || plusButton === true) ? '<i class="fa-solid fa-square-plus"></i><span id="' + Number(value).toFixed(2) + '" class="plusAddAlert fa fa-solid fa-eye">Add Alert  ' + Number(value).toFixed(2) + '</span><div id="myDIV">This is my DIV element.</div >' : '';
            },
            enabled: plusButton,
            padding: 8
          }
        },
        plotLines: [
         {
            color: 'red',
            width: 2,
            value: this.state.hignlinevalue,
            label: {
              style: {
                width: '400px',
                'min-width': '350px'
              },
              align: this.props.scale_placement,
              x: -20,
              text: '<div class="high_value_plot plot_line_dyn">' + this.toggleHighLine(this.props.sym_highlowlast_label) + '</div>'
            }
          },
          {
            color: 'red',
            width: 2,
            value: this.state.lowerlinevalue,
            label: {
              align: this.props.scale_placement,
              x: -20,
              text: '<div class="high_value_plot plot_line_dyn">' + this.toggleLowerLine(this.props.sym_highlowlast_label) + '</div>'
            }
          },
          {

            color: 'red',
            width: 2,
            value: this.state.lastLineValue,
            label: {
              align: this.props.scale_placement,
              x: -20,
              text: '<div class="high_value_plot plot_line_dyn">' + this.toggleLastLine(this.props.sym_highlowlast_label) + '</div>'
            }
          },
          //@todo - Rohit Saini- Make it dynamic
          {
            color: 'red',
            width: 2,
            value: 137,
            dashStyle: 'Dot',
            zIndex:1000,
            label: {

              style: {
                backgroundColor: '#96DA95',
                color: 'blue',
                fontWeight: 'bold'
              },
              align: this.props.scale_placement,
              x: -10,
              text: '<div class="high_value_plot plot_line_dyn" style="background:red;z-index:10000">' + this.toggleTicker(this.props.sym_name_label) + ':' + this.toggleLastPrice(this.props.sym_last_price_label) + '<br/>' + this.toggleLastPricePer(true) + '<br/>' + this.toggleLastPriceCountDown(this.props.countdownBar) + '</div>'
            }
          },
          {
            color: 'red',
            width: 0,
            value: 120,
            label: {
              align: this.props.scale_placement,
              x: -10,
              text: '<div class="high_value_plot plot_line_dyn">' + prePostMktPrice + '</div>'
            }
          },
          {
            color: 'red',
            width: 0,
            value: 142,
            label: {
              align: this.props.scale_placement,
              x: -10,
              text: (this.props.askBid) ? '<div class="high_value_plot plot_line_dyn">Ask 140</div>' : '',
            }
          },
          {
            color: 'red',
            width: 0,
            value: 128,
            label: {
              align: this.props.scale_placement,
              x: -10,
              text: (this.props.askBid) ? '<div class="high_value_plot plot_line_dyn">Bid 130</div>' : '',
            }

          }

        ],
        lineWidth: 1,
        opposite: this.returnScalePosition(),
        minRange: 0.001,
        gridLineWidth: this.props.grid,

        labels: {
          align: 'right',
          x: -3
        },
        title: {
          text: ''
        },
        height: '60%',
        resize: {
          enabled: true
        }
      }, {
        lineWidth: 1,
        opposite: this.returnScalePosition(),
        minRange: 0.001,

        gridLineWidth: this.props.grid,
        labels: {
          align: 'right',
          x: -3
        },
        title: {
          text: ''
        },
        top: '65%',
        height: '35%',
        offset: 0
      }
      ],
      exporting: {
        enabled: false,
       
      },
      series: [
        {
          id: 'aapl',
          type: this.props.graph,
          name: (this.props.statusLineSymbol === true || this.props.statusLineSymbol === 'true') ? this.getConditionalSymbolData(this.props.symbolVariation) : '',
          data: this.state.stockData,
          // data: data,
          useOhlcData: false,
          tooltip: {
            valueDecimals: 2,
          },
          dataGrouping: {},
        },
        // {
        //   type: 'column',
        //   name: 'Volume', 
        //   yAxis: 1,
          
        //   data: this.state.volume,
        //   dataGrouping: {}
        // }
        
      ]
    };
    
    console.log('wwwwwwwwwwwwww')
    //console.log($('#highcharts-rkenjx4-0').highcharts())
       // if(this.props.get_indicator_prop_data != '')        
        // {
        //   var seriesData = this.props.get_indicator_prop_data
        //   var chart = Highcharts.Chart;
        //     options.series[
        //       {
        //       seriesData
        //     }];
        // }
    // console.log('options.chart')
    // console.log(Highcharts.Chart)
    Highcharts.Annotation.prototype.addClipPaths = function () { };
    Highcharts.Annotation.types.crookedLine.prototype.setClipAxes = function () { };
 
    Highcharts.setOptions({
      lang: {
        stockTools: {
          gui: {
            inputText: 'Text annotation from input'
          }
        },
        navigation: {
          popup: {
            inputText: 'Input text'
          }
        }
      }
    });
    console.log('options22222',options2)
    // delete options2.chart;
    // delete options2.exporting;
    // delete options2.stockTools;
    // delete options2.plotOptions;
    // delete options2.rangeSelector;
    // delete options2.stockTools;
    // delete options2.title;
    // delete options2.tooltip;
    // delete options2.xAxis; 
    // delete options2.yAxis1; 
    // delete options2.navigation;  
    // delete options2.navigator;   

   
    Highcharts.setOptions({
      legend: {
        enabled: true
      }, 
      yAxis: [{ 
         top: '0%',
         height: '40%', 
      }, { 
         top: '60%',
         height: '40%', 
      }, {
         height: '30%',
         top: '100%'
      }, {
          height: '25%',
          top: '75%'
     }],
    chart: {
      type: "candlestick",
      height: 700, 
      credits: {
        enabled: false
      }, 
      tooltip: {
          enabled: false
      },
      //zoomType: 'x',
      panning: true,
      panKey: 'shift', 
      events: {
        click: function(event) {
          var icon=localStorage.getItem('seticon');
          var icontype=localStorage.getItem('icontype');
          if(icon!=null && icon!='unset')
          {

            //empty the coockies values so that it will not be reused 
            localStorage.setItem('seticon','unset');
            localStorage.setItem('icontype','unset');
            //set the cursor of the window back to normal after work done
            $("#container").css('cursor','default');
                //get chart instance and the points where the user has clicked ni the graph             
                var chart=this;
                var xp= event.xAxis[0].value;
                var yp= event.yAxis[0].value;
                //random is the id which is assigned to the series we add in chart and after that we can modify them usinf this id
                var random=Math.random() * (100000 - 1) + 1;

                //if icontyp is icon :
                //todo: Work for the icons
                if(icontype=='icon')
                {
                chart.addSeries(
                  {
                    type: 'scatter',
                    cursor: 'move',
                    data: [
                      {id:"'"+random+"'",x:xp,y: yp}
                    ],
                    marker: { 
                      width:50,
                      height:50,
                      symbol: "text:"+icon,
                    fillColor: {
                      formatter: function () {
                        return 'black'
                      }
                    }, }, 
                    x: 1, 
                    y: 18,
                    dragDrop: {
                      draggableY: true,
                      draggableX: true
                    },
                }
                  );
                }
                else
                {
                  //if icon type is callout for eg. Text, callout,baloon , arrow marker etc.
                  var icon="";
                  switch(icontype)
                  {
                    case 'aml'://arrow marker left
                    icon="\uF177";
                        break;
                    case 'amr'://arrow marker right
                    icon="\uF178";
                        break;
                    case 'amu'://arrow marker up
                    icon="\uF176";
                        break;
                    case 'amd'://arrow marker down
                    icon="\uF175";
                        break;
                    case 'amf'://arrow marker flag
                    icon="\uF11D";
                        break;
                    case 'am'://arrow marker 
                    icon="\uF178";
                        break;
                    case 'baloon'://baloon 
                    icon="\uf0e5";
                        break;
                    case 'text'://baloon 
                    icon="Text Write Here";
                    var textCode=localStorage.getItem('textcode');
                    if(textCode!=null && textCode!='unset')
                    {
                        var textCode=JSON.parse(textCode);
                        textCode.push(random);
                        localStorage.setItem("textcode",JSON.stringify(textCode));
                    }
                    else
                    {
                      var textCode=[];
                      textCode.push(random);
                      localStorage.setItem("textcode",JSON.stringify(textCode));
                    }

                        break;
                   
                   

                  }

                  chart.addSeries(
                    {
                      type: 'scatter',
                      cursor: 'move',
                      data: [
                        {id:"'"+random+"'",x:xp,y: yp}
                      ],
                      marker: { 
                        width:50,
                        height:50,
                        symbol: "text:"+icon,
                      fillColor: {
                        formatter: function () {
                          return 'black'
                        }
                      }, }, 
                      x: 1, 
                      y: 18,
                      dragDrop: {
                        draggableY: true,
                        draggableX: true
                      },
                  }
                    );

                    
                }
                }
         
      },
        redraw: function(event) {
          var chart = this;  
          setTimeout(() => {
            //chart.redraw()
            chart.hideLoading();
          }, 2000);
        }, 
        load: function (e) { 
          //this.getResult();
          setTimeout(() => {
            if(JSON.parse(localStorage.getItem('indicator'))?.length > 0){
              $('.show_indicator_div').trigger('click'); 
            }
          }, 5000);
          setTimeout(() => {
            if(JSON.parse(localStorage.getItem('default_template'))?.length > 0){
              $('.show_template_div').trigger('click'); 
            }
          }, 5000);
 
          var chart = this;

          chart.stockTools.showhideBtn.click(); 

          setTimeout((hide_chart) => { 
            $('.highcharts-toggle-toolbar').hide(); 
          },1000,chart);
        //   svg.highcharts-root {
        //     padding-left: 17px;
        // }
        
          //class="highcharts-root"
          // setTimeout(() => {  
          //   $('.highcharts-toggle-toolbar').attr("style", "z-index:100000;background-color:#f7f7f7").prepend("<i class='fa-solid fa-bars'></i>"); 
          // },3000);
          //prepend("<i class='fa-solid fa-bars'></i>").
          // chart.xAxis[0].getExtremes().dataMin;<i class="fa-solid fa-bars"></i>
          var zoomRatio = 1;
          var lastX;
          var lastY;
          var mouseDown;

          var setZoom = function(chart) { 
            var xMin = chart.xAxis[0].getExtremes().dataMin;
            var xMax = chart.xAxis[0].getExtremes().dataMax;
            var yMin = chart.yAxis[0].getExtremes().dataMin;
            var yMax = chart.yAxis[0].getExtremes().dataMax;
           
            chart.xAxis[0].setExtremes(xMin + (1 - zoomRatio) * xMax, xMax * zoomRatio);
            chart.yAxis[0].setExtremes(yMin + (1 - zoomRatio) * yMax, yMax * zoomRatio);
         };
         $(document).on('change','.chk_symbol',function(e){ 
          var symbol_data=JSON.parse(localStorage.getItem("symbol_data"));
          console.log("symbol data is ",symbol_data);
            if($(e.target).prop("checked")==='true' || $(e.target).prop("checked") )
            {
              console.log("symbol_check","iam in true");
              chart.setTitle({text:symbol_data.symbol_name+" "});
            }
            else
            {
              chart.setTitle({text:" "});
            }
       });
        $(document).on('change','.template_select',function(e){ 
          var symbol_data=JSON.parse(localStorage.getItem("symbol_data"));
          console.log("symbol data is ",symbol_data);
            if(e.target.value==0 )
            {
              console.log("symbol_check","iam in true");
              chart.setTitle({text:symbol_data.symbol_name});
            }
            else if(e.target.value==1)
            {
              chart.setTitle({text:symbol_data.symbol});
            
            }
            else
            {
              chart.setTitle({text:symbol_data.symbol+" "+symbol_data.symbol_name+" "});
            }
       });

      
       $(document).on('change','.size_change',function(e){ 
        var val=e.target.value;
        var id=$("#exmModel").val();
        console.log(id);
        var point=chart.get(id);
        console.log(point);
        $(point.graphic.element).css('font-size',val);
        $(point.graphic.element).removeAttr('stroke');

   });
   $(document).on('change','.icon_color_change',function(e){ 
        var val=e.target.value;
       
        // val=JSON.parse(val);
        // // var color="rgb("+val.r+","+val.g+","+val.b+")";
        var id=$("#exmModel").val();
        // console.log(chart);
        var point=chart.get(id);
        $(point.graphic.element).attr('fill',val);
        $(point.graphic.element).removeAttr('stroke');
        console.log("color is",val);
   });

   $(document).on("keyup",".icon_text_change",function(e){
    var id=$("#exmModel").val();
    var point=chart.get(id);
    $(point.graphic.element).css("text-transform","none");
    $(point.graphic.element).css("font-family","");
    $(point.graphic.element).css("font-family","arial");
    $(point.graphic.element).css("color","black");
    $(point.graphic.element).html(e.target.value);
    $(point.graphic.element).attr('fill',"#000");
    $(point.graphic.element).removeAttr('stroke');
    // e.target.value="";
   });



   $(document).on("click",".delete_icon",function(){
    var id=$("#exmModel").val();
    // console.log(chart);
    chart.get(id).remove();
    window.$('#exampleModal').modal('hide');
    var textCode=localStorage.getItem('textcode');
    id=id.replace(/['"]+/g, '');
                    if(textCode!=null && textCode!='unset')
                    {
                        var textCode=JSON.parse(textCode);
                        const items =textCode.filter(item => item != id)
                        localStorage.setItem("textcode",JSON.stringify(items));
                    }
   
   });



       $(document).on('change','.sym_last_price_label',function(e){ 
        if($(e.target).prop("checked")==='true' || $(e.target).prop("checked") )
        {
          $('.chk_sym_name_label_trigger').show();
        }
        else
        {
          $('.chk_sym_name_label_trigger').hide();
        }
   });
      $(document).on('change','.ch_bid_ask_label',function(e){ 
        if($(e.target).prop("checked")==='true' || $(e.target).prop("checked") )
        {
          $('.ch_bid_ask_label_trigger').show();
        }
        else
        {
          $('.ch_bid_ask_label_trigger').hide();
        }
   });
   $(document).on('change','.chk_pre_post_mkt_lbl',function(e){ 
        if($(e.target).prop("checked")==='true' || $(e.target).prop("checked") )
        {
          $('.chk_pre_post_mkt_lbl_trigger').show();
        }
        else
        {
          $('.chk_pre_post_mkt_lbl_trigger').hide();
        }
   });
   $(document).on('change','.chk_sym_highlowlast_label',function(e){ 
        if($(e.target).prop("checked")==='true' || $(e.target).prop("checked") )
        {
          $('.sym_highlowlast_label_trigger').show();
        }
        else
        {
          $('.sym_highlowlast_label_trigger').hide();
        }
   });
     $(document).on('change','.chk_label_countdown',function(e){ 
        if($(e.target).prop("checked")==='true' || $(e.target).prop("checked") )
        {
          $('.chk_label_countdown_trigger').show();
        }
        else
        {
          $('.chk_label_countdown_trigger').hide();
        }
   });
   
   $(document).on('change','.vol_change',function(e){ 
        if($(e.target).prop("checked")==='true' || $(e.target).prop("checked") )
        {
          $('.v_close').show();
        }
        else
        {
          $('.v_close').hide();
        }
   });
   
   
   $(document).on('change','.chk_last_price_line',function(e){ 
    
        if($(e.target).prop("checked")==='true' || $(e.target).prop("checked") )
        {
          chart.yAxis[0].addPlotLine({
            id:'lastpriceline',
            color: 'red',
            width: 2,
            value: 135,
            label: {
              style: {
                backgroundColor: 'red',
                color: 'blue',
                fontWeight: 'bold',
                dashStyle: 'longdash',
              },
             align: 'right',
              x: -10,
              text: '<div class="high_value_plot plot_line_dyn line_last_price"><span class="chk_sym_name_label_trigger">A:130</span><br/><span class="chk_label_countdown_trigger">-3.70%<span><br/><span class="sym_highlowlast_label_trigger">130</span></div>'
            }
          });
        }
        else
        {
          chart.yAxis[0].removePlotLine('lastpriceline');
          
          
        }
   });
     
   var up_color="#96DA95";
   var down_color="#FF8B8B";


   $(document).on('change','.handle_price_line_color_change',function(e){ 
    
    var color=JSON.parse(e.target.value);
    color="rgb("+color.r+" "+color.g+" "+color.b+")";
 
    chart.yAxis[0].removePlotLine('lastpriceline');

    chart.yAxis[0].addPlotLine({
      id:'lastpriceline',
      color: color,
      width: 2,
      value: 135,
      label: {
        style: {
          backgroundColor: color,
          color: 'blue',
          fontWeight: 'bold',
          dashStyle: 'longdash',
        },
       align: 'right',
        x: -10,
        text: '<div class="high_value_plot plot_line_dyn line_last_price"><span class="chk_sym_name_label_trigger">A:130</span><br/><span class="chk_label_countdown_trigger">-3.70%<span><br/><span class="sym_highlowlast_label_trigger">130</span></div>'
      }
    });


});
 $(document).on('change','.handle_up_color_change',function(e){ 
    
    var color=JSON.parse(e.target.value);
    color="rgb("+color.r+" "+color.g+" "+color.b+")";
    up_color=color;
    console.log("chart is ",chart);
    const candlesticks = chart.series[0].points;
    for (let i = 0; i < candlesticks.length; i++) {
      const candle = candlesticks[i];
      if (candle.close > candle.open) {
        candle.graphic.css({
          color: up_color
        });
      } else if (candle.close < candle.open) {
        candle.graphic.css({
          color: down_color
        });
      }
  }


});
  $(document).on('change','.handle_down_color_change',function(e){ 
   
    var color=JSON.parse(e.target.value);
    color="rgb("+color.r+" "+color.g+" "+color.b+")";
    down_color=color;
console.log("color is",color)
    console.log("chart is ",chart);
    const candlesticks = chart.series[0].points;
    for (let i = 0; i < candlesticks.length; i++) {
      const candle = candlesticks[i];
      if (candle.close > candle.open) {
        candle.graphic.css({
          color: up_color
        });
      } else if (candle.close < candle.open) {
        candle.graphic.css({
          color: down_color
        });
      }
    }
  });









          $('.highcharts-root').bind('mousewheel', function(event) {
            event.preventDefault(); 
            if(event.deltaY > 0) {
              if (zoomRatio > 0.7) {
                  zoomRatio = zoomRatio - 0.1;
                  setZoom(chart);
              }
  
            }else if (event.deltaY < 0) {
              zoomRatio = zoomRatio + 0.1;
              setZoom(chart);
            }
            setZoom(chart);

            console.log('asdasdsaa', event.deltaY, event.deltaFactor, event.originalEvent.deltaMode, event.originalEvent.wheelDelta);
          });

          chart.showLoading("<div class='spinner-border' style='margin-top:-200px' role='status'><span class='sr-only'>Loading...</span></div>"); 

          $(document).on('click','.show_indicator_div',function(e){ 
            $('.custom_indicator_div').show();
            //this.setState({randomNumber:parseInt(Math.floor((Math.random() * 100) + 1))})
          }); 
          $(document).on('click','.show_hide_indicator_setting',function(e){ 
             window.$('#indicator_setting').modal('show');
          });
          $(document).on('click','.hide_indicator_setting_modal',function(e){ 
            window.$('#indicator_setting').modal('hide');
         }); 
          
          $(document).on('click','.show_template_div',function(e){ 
            var template_list = JSON.parse(localStorage.getItem('default_template'));
            template_list.map((data)=>{
              if(data.template == 'displaced_template'){
                $('.displaced_template_div').show();
              }else if(data.template == 'bill_template'){
                $('.bill_template_div').show()
              }else if(data.template == 'ma_template'){
                $('.ma_template_div').show();
              }else if(data.template == 'oscillater_template'){
                $('.oscillater_template_div').show();
              }else if(data.template == 'ma_template'){
                $('.ma_template_div').show();
              }else if(data.template == 'swing_template'){
                $('.swing_template_div').show();
              }else if(data.template == 'volume_template'){
                $('.volume_template_div').show();
              }else if(data.template == 'osma_template'){
                $('.osma_template_div').show();
              }else if(data.template == 'aligator_template'){
                $('.aligator_template_div').show();
              }
            })
          }); 
          $(document).on('click',".removebull",function(e){
            chart.addSeries({
              id: 'series-2',
              type:'line',
              linkedTo:'aapl',
              data:JSON.parse(localStorage.getItem("bearbullseries")) ,
               useOhlcData: false,
               dataGrouping: {},
               yAxis:7,
            } )
            // chart.get('series-2').remove();
          });
         
          $(document).on('click','.remove_template',function(e){  
            var template_series_id = parseInt( e.target.id.split('|')[0]);
            var template_series_name = e.target.id.split('|')[1];
            let existing_indicator_arr = JSON.parse(localStorage.getItem(template_series_name)) 
            
            existing_indicator_arr.splice(template_series_id, 1);  
            if(existing_indicator_arr?.length>0){
              localStorage.setItem(template_series_name, JSON.stringify(existing_indicator_arr));
            }else{
              localStorage.setItem(template_series_name, JSON.stringify(existing_indicator_arr)); 
            }
 
            $(`#template_tool_tip_${template_series_id}`).remove();  
            
            if(chart.series?.length){
              var seriesLength = chart.series?.length;  
              for(var i = seriesLength-1; i > 1; i--){
                chart.series[i].remove();
              }
            }

            if(template_series_name == 'ma_template'){
              
              // var remove_template_series_id = template_series_id+2; 

              // for(var i = 2; i < remove_template_series_id+8; i++){
              //   const remove_chart_series = chart.series[i];  
              //   remove_chart_series.setVisible(!remove_chart_series.visible) 
              // }
              localStorage.removeItem("ma_template");
            }
            if(template_series_name == 'bill_template'){
              // var remove_template_series_id = template_series_id+2; 
              // for(var i = 2; i < remove_template_series_id+3; i++){
              //   const remove_chart_series = chart.series[i]; 
              //   remove_chart_series.setVisible(!remove_chart_series.visible) 
              // }
              localStorage.removeItem("bill_template");
            }
            if(template_series_name == 'aligator_template'){
              // var remove_template_series_id = template_series_id+2; 
              // for(var i = 2; i < remove_template_series_id+3; i++){
              //   const remove_chart_series = chart.series[i]; 
              //   remove_chart_series.setVisible(!remove_chart_series.visible) 
              // }
              localStorage.removeItem("aligator_template");
            }
            if(template_series_name == 'displaced_template'){
              // var remove_template_series_id = template_series_id+2; 
              // for(var i = 2; i < remove_template_series_id+3; i++){
              //   const remove_chart_series = chart.series[i]; 
              //   remove_chart_series.setVisible(!remove_chart_series.visible) 
              // }
              localStorage.removeItem("displaced_template");
            }
            if(template_series_name == 'oscillater_template'){
              // var remove_template_series_id = template_series_id+2; 
              // for(var i = 2; i < remove_template_series_id+3; i++){
              //   const remove_chart_series = chart.series[i]; 
              //   remove_chart_series.setVisible(!remove_chart_series.visible) 
              // }
              localStorage.removeItem("oscillater_template");
            }
            if(template_series_name == 'swing_template'){
              // var remove_template_series_id = template_series_id+2; 
              // for(var i = 2; i < remove_template_series_id+3; i++){
              //   const remove_chart_series = chart.series[i]; 
              //   remove_chart_series.setVisible(!remove_chart_series.visible) 
              // }
              localStorage.removeItem("swing_template");
            }

            if(template_series_name == 'volume_template'){
              // var remove_template_series_id = template_series_id+2; 
              // for(var i = 2; i < remove_template_series_id+3; i++){
              //   const remove_chart_series = chart.series[i]; 
              //   remove_chart_series.setVisible(!remove_chart_series.visible) 
              // }
              localStorage.removeItem("volume_template");
            }

            if(template_series_name == 'osma_template'){
              // var remove_template_series_id = template_series_id+2; 
              // for(var i = 2; i < remove_template_series_id+3; i++){
              //   const remove_chart_series = chart.series[i]; 
              //   remove_chart_series.setVisible(!remove_chart_series.visible) 
              // }
              localStorage.removeItem("osma_template");
            }
 
            if(JSON.parse(localStorage.getItem(template_series_name))?.length == 0){
              $(`.oscillater_template_div`).hide(); 
              $(`#template_display_div`).hide();
              //location.reload();
            }
            $('.template_div_text').text(`Click to apply ${(JSON.parse(localStorage.getItem('default_template')))?JSON.parse(localStorage.getItem('default_template'))?.length:0} default template`) 
          });

          $(document).on('click','#clear_all_template',function(e){ 
            let existing_default_template = JSON.parse(localStorage.getItem('default_template')) 
            let existing_bill_template = JSON.parse(localStorage.getItem('bill_template')) 
            let existing_aligator_template = JSON.parse(localStorage.getItem('aligator_template')) 
            let existing_displaced_template = JSON.parse(localStorage.getItem('displaced_template')) 
            let existing_ma_template = JSON.parse(localStorage.getItem('ma_template')) 
            let existing_oscillater_template = JSON.parse(localStorage.getItem('oscillater_template')) 
            let existing_swing_template = JSON.parse(localStorage.getItem('swing_template')) 
            let existing_volume_template = JSON.parse(localStorage.getItem('volume_template')) 
            let existing_osma_template = JSON.parse(localStorage.getItem('osma_template')) 

            if(existing_default_template?.length>0){
              localStorage.removeItem("default_template");
            } 
            if(existing_bill_template?.length>0){
              localStorage.removeItem("bill_template");
            }
            if(existing_aligator_template?.length>0){
              localStorage.removeItem("aligator_template");
            }
            if(existing_displaced_template?.length>0){
              localStorage.removeItem("displaced_template");
            }
            if(existing_ma_template?.length>0){
              localStorage.removeItem("ma_template");
            }
            if(existing_oscillater_template?.length>0){
              localStorage.removeItem("oscillater_template");
            }
            if(existing_swing_template?.length>0){
              localStorage.removeItem("swing_template");
            }
            if(existing_volume_template?.length>0){
              localStorage.removeItem("volume_template");
            }
            if(existing_osma_template?.length>0){
              localStorage.removeItem("osma_template");
            }
            if(chart.series?.length>0){
              var seriesLength = chart.series?.length;  
              for(var i = seriesLength-1; i > 1; i--){
                chart.series[i].remove();
              }
            }   
            $(`#template_display_div`).hide();
            $(`.displaced_template_div`).hide();
            $(`.bill_template_div`).hide();
            $(`.aligator_template_div`).hide();
            $(`.ma_template_div`).hide(); 
            $(`.oscillater_template_div`).hide(); 
            $(`.swing_template_div`).hide(); 
            $(`.volume_template_div`).hide(); 
            $(`.osma_template_div`).hide(); 
          });
          
          $(document).on('click','.remove_volume',function(e){  
              const remove_chart_series = chart.series[1];  
              remove_chart_series.setVisible(!remove_chart_series.visible) 
              if(!remove_chart_series.visible){
                chart.yAxis[0].update({
                  height: '100%'
                })
                chart.yAxis[1].update({
                  height: '0%',
                  top: '100%',
                  title: {
                    text: null
                  }
                })  
              }else{ 
                chart.yAxis[0].update({
                  height: '80%'
                })
                chart.yAxis[1].update({
                  top: '60%',
                  height: '40%', 
                  labels: {
                    enabled: false
                  }, 
                  gridLineWidth: 0 
              }) 
              }
              
              console.log('remove_chart_series.visible',remove_chart_series.visible)
              if(remove_chart_series.visible == false){
                 $('#volume_style').html('<i class="fa-solid fa-eye-slash"></i>'); 
                 //$('#volume_style').removeClass('bg-danger').addClass('bg-success'); 
              }else{
                 $('#volume_style').html('<i class="fa-solid fa-eye"></i>');
                 //$('#volume_style').removeClass('bg-success').addClass('bg-danger'); 
              }
          });

          $(document).on('click','.remove_indicator',function(e){
            var indicator_series_id = parseInt(e.target.id.split('|')[0])
            var is_yaxis = e.target.id.split('|')[1]
            //var indicator_series_id = parseInt(e.target.id);
            let existing_indicator_arr = JSON.parse(localStorage.getItem('indicator')) 
            existing_indicator_arr.splice(indicator_series_id, 1);  
            if(existing_indicator_arr?.length>0){
              localStorage.setItem("indicator", JSON.stringify(existing_indicator_arr));
            }else{
              localStorage.setItem("indicator", JSON.stringify(existing_indicator_arr)); 
            }
            $(`#indicator_tool_tip_${indicator_series_id}`).remove(); 

            var remove_indicator_series_id = indicator_series_id+2;  
            const remove_chart_series = chart.series[remove_indicator_series_id]; 
            remove_chart_series.setVisible(!remove_chart_series.visible)  
 
            // find total visible series count
            // var visibleSeriesCount = 0;
            // chart.series.forEach(serie => {
            //   if (serie.visible) { 
            //     visibleSeriesCount++
            //   }
            // })

            // var updated_visible_series_count = (visibleSeriesCount==2)?3:visibleSeriesCount;
            // //console.log('11_222',updated_visible_series_count);
            // var no_indicator_apply = false;
            // if(visibleSeriesCount == 2){ 
            //   no_indicator_apply = true;
            // }
            //console.log('111',is_yaxis)

            if(is_yaxis !== 'undefined'){ 
              var seriesDataLength = JSON.parse(localStorage.getItem('indicator'))?.length
              var seriesData = JSON.parse(localStorage.getItem('indicator'))
              var y_axis_indicator_length = 0;   
              var y_axis_visible_indicator_length = 0;   
              var indicator_length = 0;   
              //var is_yaxis_length = 0;   
              if(seriesDataLength > 0){      
                  seriesData.forEach(data => {
                      indicator_length++ 
                      if(data.yAxis){  
                          y_axis_indicator_length++ 
                      } 
                      if(data.yAxis && data.isVisible){  
                        y_axis_visible_indicator_length++ 
                      }
                  })   
                  var no_indicator_apply = false;
                  if(y_axis_indicator_length == 0){
                      no_indicator_apply = true; 
                      updateYAxis(chart,1,no_indicator_apply);
                  }else{   
                    if(y_axis_visible_indicator_length == 0){
                      y_axis_visible_indicator_length = 1;
                    } 
                    updateYAxis(chart,y_axis_visible_indicator_length,false);
                  }
              }
            }

            // if(is_yaxis !== 'undefined'){ 
            //     var seriesDataLength = JSON.parse(localStorage.getItem('indicator'))?.length
            //     var seriesData = JSON.parse(localStorage.getItem('indicator'))
            //     var y_axis_indicator_length = 3;   
            //     var is_yaxis_length = 0;   
            //       if(seriesDataLength > 0){    
            //           var is_yaxis_length = 0;   
            //           var y_axis_indicator_length = 0;
            //           seriesData.forEach(data => {  
            //             if(data.yAxis){ 
            //               is_yaxis_length++; 
            //               y_axis_indicator_length++; 
            //             }
            //           })
            //       } 
            //       var no_indicator_apply = false;
            //       if(y_axis_indicator_length == 3 && is_yaxis_length == 0){ 
            //         no_indicator_apply = true
            //       }
            //       alert(y_axis_indicator_length)
            //       updateYAxis(chart,y_axis_indicator_length,no_indicator_apply);
            // }

            //updateYAxis(chart,visibleSeriesCount);

            if(JSON.parse(localStorage.getItem('indicator'))?.length == 0){
              $(`.custom_indicator_div`).hide(); 
              $(`#indicator_display_div`).hide();
              //location.reload();
            }
            $('.indicator_div_text').text(`Click to apply ${(JSON.parse(localStorage.getItem('indicator')))?JSON.parse(localStorage.getItem('indicator'))?.length:0} Indicator`)
          });
          
          $(document).on('click','#clear_all_indicator',function(e){
            let existing_indicator = JSON.parse(localStorage.getItem('indicator'))  
            if(existing_indicator && existing_indicator?.length>0){
               localStorage.removeItem("indicator");
            } 
            if(chart.series?.length){
              var seriesLength = chart.series?.length; 
              console.log('seriesLength',seriesLength)
              for(var i = seriesLength-1; i > 1; i--){
                chart.series[i].remove();
              }
            }   
            $(`.show_hide_volume_button`).show(); 
            $(`.custom_indicator_div`).hide(); 
            $(`#indicator_display_div`).hide(); 
            //alert('1')
            updateYAxis(chart,1,true);
          });
           
          function updateYAxis(chart,visibleSeriesCount=0,no_indicator_apply=false){
            //alert(no_indicator_apply)
            var update_visible_series_count = visibleSeriesCount
              //alert(update_visible_series_count)
            // By default when clear all
            console.log('dasdadsa',update_visible_series_count)
              var seriesDataLength = JSON.parse(localStorage.getItem('indicator'))?.length
              var seriesData = JSON.parse(localStorage.getItem('indicator'))  
              if(seriesDataLength > 0){ 
                seriesData.forEach(data => {  
                    if(data.yAxis){  
                      // condition for data align by indicator selection
                      //alert(update_visible_series_count)
                      if(update_visible_series_count == 1){
                        //alert('11')
                        chart.yAxis[0].update({
                          top: '0%',
                          height: '40%'
                        })
                        chart.yAxis[1].update({ 
                          height: '30%',
                          top: '40%'
                        }) 
                        chart.yAxis[2].update({
                          height: '30%',
                          top: '70%'
                        })  
                      }
                      if(update_visible_series_count == 2){
                        chart.yAxis[0].update({
                          top: '0%',
                          height: '35%'
                        })
                        chart.yAxis[1].update({ 
                          height: '15%',
                          top: '35%'
                        }) 
                        chart.yAxis[2].update({
                          height: '25%',
                          top: '55%'
                        }) 
                        chart.yAxis[3].update({
                          height: '25%', 
                          top: '70%'
                        })  
                      } 
                      if(update_visible_series_count == 3){
                        chart.yAxis[0].update({
                          top: '0%',
                          height: '30%'
                        })
                        chart.yAxis[1].update({ 
                          height: '0%',
                          top: '0%'
                        }) 
                        chart.yAxis[2].update({
                          height: '20%',
                          top: '35%'
                        }) 
                        chart.yAxis[3].update({
                          height: '20%', 
                          top: '55%'
                        }) 
                        chart.yAxis[4].update({
                          height: '20%', 
                          top: '75%'
                        })  
                      } 
                      if(update_visible_series_count == 4){
                        chart.yAxis[0].update({
                          top: '0%',
                          height: '20%'
                        })
                        chart.yAxis[1].update({ 
                          height: '0%',
                          top: '0%'
                        }) 
                        chart.yAxis[2].update({
                          height: '20%',
                          top: '20%'
                        }) 
                        chart.yAxis[3].update({
                          height: '20%', 
                          top: '44%'
                        }) 
                        chart.yAxis[4].update({
                          height: '20%', 
                          top: '60%'
                        }) 
                        chart.yAxis[5].update({
                          height: '20%', 
                          top: '80%'
                        })  
                      } 
                      if(update_visible_series_count == 5){
                        chart.yAxis[0].update({
                          top: '0%',
                          height: '15%'
                        })
                        chart.yAxis[1].update({ 
                          height: '0%',
                          top: '0%'
                        }) 
                        chart.yAxis[2].update({
                          height: '15%',
                          top: '20%'
                        }) 
                        chart.yAxis[3].update({
                          height: '15%', 
                          top: '35%'
                        }) 
                        chart.yAxis[4].update({
                          height: '15%', 
                          top: '50%'
                        }) 
                        chart.yAxis[5].update({
                          height: '15%', 
                          top: '65%'
                        }) 
                        chart.yAxis[6].update({
                          height: '15%', 
                          top: '80%'
                        })
                      }  
                  }
                });   
              }else{
                  // By default when clear all
                  if(update_visible_series_count == 1){
                    chart.yAxis[0].update({
                      top: '0%',
                      height: '50%'
                    })
                    chart.yAxis[1].update({ 
                      height: '35%',
                      top: '60%'
                    })  
                  }
              }  

              if(no_indicator_apply){
                // By default when clear all 
                if(update_visible_series_count == 1){
                  //alert('1111')
                  chart.yAxis[0].update({
                    top: '0%',
                    height: '50%'
                  })
                  chart.yAxis[1].update({ 
                    height: '35%',
                    top: '60%'
                  })  
                }
              }

          }

          $(document).on('click','.show_hide_indicator',function(e){
            var series_id = e.target.id.split('|')[0]
            var is_yaxis = e.target.id.split('|')[1]
            var indicator_type = e.target.id.split('|')[2]
 

            // Show hide eye icon in indicator 
            if($(this).hasClass('fa-eye')){
              $(this).removeClass('fa-eye');
              $(this).addClass('fa-eye-slash');
              var is_show_hide = 'hide'

              // update show hide field in localstorage
              var existingData = [];
              existingData = JSON.parse(localStorage.getItem('indicator'));
              existingData.map((indicator_data,i) => { 
                 if(indicator_data.type === indicator_type){ 
                    existingData[i].isVisible = false  
                 } 
              });
              localStorage.setItem('indicator',JSON.stringify(existingData))

            }else{
              $(this).removeClass('fa-eye-slash');
              $(this).addClass('fa-eye');
              var is_show_hide = 'show'

              // update show hide field in localstorage
              var existingData = [];
              existingData = JSON.parse(localStorage.getItem('indicator'));
              existingData.map((indicator_data,i) => { 
                 if(indicator_data.type === indicator_type){ 
                    existingData[i].isVisible = true  
                 } 
              });
              localStorage.setItem('indicator',JSON.stringify(existingData))

            } 
            //console.log('adas',e.target.id)
            

            var indicator_series_id = parseInt(series_id)+2;  
            const chart_series = chart.series[indicator_series_id]; 
            chart_series.setVisible(!chart_series.visible) 

            // find total visible series count
            // var visibleSeriesCount = 0;
            // chart.series.forEach(serie => {
            //   console.log('serie__',serie)
            //   if (serie.visible) { 
            //     visibleSeriesCount++
            //   }
            // })
            // var updated_visible_series_count = (visibleSeriesCount==2)?3:visibleSeriesCount;
            // //console.log('11_222',updated_visible_series_count);
            // var no_indicator_apply = false;
            // if(visibleSeriesCount == 2){ 
            //   no_indicator_apply = true;
            // }
            // //console.log('111',is_yaxis)
            // if(is_yaxis !== 'undefined'){ 
            //   updateYAxis(chart,updated_visible_series_count,no_indicator_apply);
            // } 
           // alert(is_yaxis)
            if(is_yaxis !== 'undefined'){ 
              var seriesDataLength = JSON.parse(localStorage.getItem('indicator'))?.length
              var seriesData = JSON.parse(localStorage.getItem('indicator'))
              var y_axis_indicator_length = 0;   
              var y_axis_visible_indicator_length = 0;   
              var indicator_length = 0;   
              //var is_yaxis_length = 0;   
              if(seriesDataLength > 0){      
                  seriesData.forEach(data => {  
                      indicator_length++ 
                      if(data.yAxis){  
                          y_axis_indicator_length++ 
                      } 
                      if(data.yAxis && data.isVisible){  
                        y_axis_visible_indicator_length++ 
                      }
                  })  
                  // alert(indicator_length)
                  // alert(y_axis_indicator_length)
                   //alert(y_axis_visible_indicator_length)

                  var no_indicator_apply = false;
                  if(y_axis_visible_indicator_length == 0){
                      no_indicator_apply = true; 
                      updateYAxis(chart,1,no_indicator_apply);
                  }else{   
                    if(y_axis_visible_indicator_length == 0){
                      y_axis_visible_indicator_length = 1;
                    } 
                    updateYAxis(chart,y_axis_visible_indicator_length,false);
                  }

                  // var no_indicator_apply = false;
                  // alert(y_axis_indicator_length)
                  // if(y_axis_visible_indicator_length == 1){ 
                  //     no_indicator_apply = true;
                  //     if(is_show_hide == 'hide'){
                  //       y_axis_indicator_length = 1
                  //     }else{
                  //       y_axis_indicator_length = indicator_length
                  //     }  
                  //     updateYAxis(chart,y_axis_indicator_length,no_indicator_apply);
                  // }else{  
                  //   //alert(y_axis_visible_indicator_length)
                  //   var no_indicator_apply = false;
                  //   if(y_axis_visible_indicator_length == 0){
                  //     y_axis_visible_indicator_length = 1;
                  //     no_indicator_apply = true;
                  //   }
                  //   //alert(y_axis_visible_indicator_length)
                  //   //console.log('123_',)
                  //   updateYAxis(chart,y_axis_visible_indicator_length,no_indicator_apply);
                  // }
              }
              // var no_indicator_apply = false;
              // alert(y_axis_indicator_length)
              // alert(is_yaxis_length)
              // if(y_axis_indicator_length == 3 && is_yaxis_length == 0){ 
              //   no_indicator_apply = true
              // }
              // updateYAxis(chart,y_axis_indicator_length,no_indicator_apply);
            }

          });

          $("#indicator_display_div_modal").on("click",function(e){ 
            function maintainIndicator(chart_chart){  
                // start - Delete all series expect candle and volume
                if(chart_chart.series?.length){
                  var seriesLength = chart_chart.series?.length; 
                  console.log('seriesLength',seriesLength)
                  for(var i = seriesLength-1; i > 1; i--){
                    chart_chart.series[i].remove();
                  }
                }
                // start - Delete all series expect candle and volume
                var seriesDataLength = JSON.parse(localStorage.getItem('indicator'))?.length
                var seriesData = JSON.parse(localStorage.getItem('indicator'))  
                if(seriesDataLength > 0){   
                  var count = -1;
                   seriesData.forEach(data => {
                    count++;  
                    console.log('data.data',data.data) 
                    if(data.yAxis){ 
                        // condition for data align by indicator selection
                        if(seriesData.length == 1){
                          chart.yAxis[0].update({
                            top: '0%',
                            height: '40%'
                          })
                          chart.yAxis[1].update({ 
                            height: '25%',
                            top: '40%'
                          }) 
                          chart.yAxis[2].update({
                            height: '20%',
                            top: '70%'
                          })  
                        }
                        if(seriesData.length == 2){
                          chart.yAxis[0].update({
                            top: '0%',
                            height: '35%'
                          })
                          chart.yAxis[1].update({ 
                            height: '15%',
                            top: '35%'
                          }) 
                          chart.yAxis[2].update({
                            height: '25%',
                            top: '55%'
                          }) 
                          chart.yAxis[3].update({
                            height: '25%', 
                            top: '70%'
                          })  
                        } 
                        if(seriesData.length == 3){
                          chart.yAxis[0].update({
                            top: '0%',
                            height: '30%'
                          })
                          chart.yAxis[1].update({ 
                            height: '0%',
                            top: '0%'
                          }) 
                          chart.yAxis[2].update({
                            height: '20%',
                            top: '35%'
                          }) 
                          chart.yAxis[3].update({
                            height: '20%', 
                            top: '55%'
                          }) 
                          chart.yAxis[4].update({
                            height: '20%', 
                            top: '75%'
                          })   
                        } 
                        if(seriesData.length == 4){
                          chart.yAxis[0].update({
                            top: '0%',
                            height: '20%'
                          })
                          chart.yAxis[1].update({ 
                            height: '0%',
                            top: '0%'
                          }) 
                          chart.yAxis[2].update({
                            height: '20%',
                            top: '20%'
                          }) 
                          chart.yAxis[3].update({
                            height: '20%', 
                            top: '44%'
                          }) 
                          chart.yAxis[4].update({
                            height: '20%', 
                            top: '60%'
                          }) 
                          chart.yAxis[5].update({
                            height: '20%', 
                            top: '80%'
                          }) 
                        } 
                        if(seriesData.length == 5){
                          chart.yAxis[0].update({
                            top: '0%',
                            height: '15%'
                          })
                          chart.yAxis[1].update({ 
                            height: '0%',
                            top: '0%'
                          }) 
                          chart.yAxis[2].update({
                            height: '15%',
                            top: '20%'
                          }) 
                          chart.yAxis[3].update({
                            height: '15%', 
                            top: '35%'
                          }) 
                          chart.yAxis[4].update({
                            height: '15%', 
                            top: '50%'
                          }) 
                          chart.yAxis[5].update({
                            height: '15%', 
                            top: '65%'
                          }) 
                          chart.yAxis[6].update({
                            height: '15%', 
                            top: '80%'
                          })  
                        } 
                        //console.log('dadasds_asdas',data.chartData)
                        chart_chart.addSeries({
                          type: data.type,
                          linkedTo: 'aapl',
                          data: JSON.parse(data.chartData),
                          yAxis: 2+count,
                          lineWidth: data.lineWidth,
                          color:data.color, 
                          name:data.name,
                          macdLine: {
                            zones: [{
                                value: -1,
                                color: data.color
                            }, {
                                value: 1,
                                color: data.color
                            }, {
                                color: data.color
                            }]
                          },
                          signalLine: {
                              zones: [{
                                  value: -1.5,
                                  color: data.color
                              }, {
                                  color: data.color
                              }]
                          },
                          algorithm: 'MACD' 
                        }); 
                    }else{ 
                        chart_chart.addSeries({
                          type: data.type,
                          linkedTo: 'aapl', 
                          //data:data.data,
                          name:data.name,
                          lineWidth: data.lineWidth,
                          color:data.color,
                          marker: {
                            enabled: false
                          } 
                        }); 
                    }
                   });   
                }  
                console.log('chart_chart.series',chart_chart.series)
            }
            
            setTimeout(() => {  
              maintainIndicator(chart); 
            }, 1000);
            
          });

          //$("#abc").on("DOMSubtreeModified",function(e){ 
          $("#indicator_display_div").on("click",function(e){
            //alert('1')
            // Visible all indicator in localstorage
            var existingData = [];
            existingData = JSON.parse(localStorage.getItem('indicator'));
            existingData.map((indicator_data,i) => {   
                existingData[i].isVisible = true 
            }); 
            localStorage.setItem('indicator',JSON.stringify(existingData))

            function maintainIndicator(chart_chart){  
                // start - Delete all series expect candle and volume
                if(chart_chart.series?.length){
                  var seriesLength = chart_chart.series?.length; 
                  console.log('seriesLength',seriesLength)
                  for(var i = seriesLength-1; i > 1; i--){
                    chart_chart.series[i].remove();
                  }
                }
                // start - Delete all series expect candle and volume
                var seriesDataLength = JSON.parse(localStorage.getItem('indicator'))?.length
                var seriesData = JSON.parse(localStorage.getItem('indicator'))  
                if(seriesDataLength > 0){ 
                  var count = -1;   
                  seriesData.forEach(data => {  
                      if(data.yAxis){
                        count++; 
                        var y_axis_indicator_length = 0; 
                        seriesData.forEach(data => { 
                          if(data.yAxis){
                            y_axis_indicator_length++
                          }
                        }) 
                       // condition for data align by indicator selection
                        if(y_axis_indicator_length == 1){
                          chart.yAxis[0].update({
                            top: '0%',
                            height: '40%'
                          })
                          chart.yAxis[1].update({ 
                            height: '25%',
                            top: '40%'
                          }) 
                          chart.yAxis[2].update({
                            height: '20%',
                            top: '70%'
                          })  
                        }
                        if(y_axis_indicator_length == 2){
                          chart.yAxis[0].update({
                            top: '0%',
                            height: '35%'
                          })
                          chart.yAxis[1].update({ 
                            height: '15%',
                            top: '35%'
                          }) 
                          chart.yAxis[2].update({
                            height: '25%',
                            top: '55%'
                          }) 
                          chart.yAxis[3].update({
                            height: '25%', 
                            top: '70%'
                          })  
                        } 
                        if(y_axis_indicator_length == 3){
                          chart.yAxis[0].update({
                            top: '0%',
                            height: '30%'
                          })
                          chart.yAxis[1].update({ 
                            height: '0%',
                            top: '0%'
                          }) 
                          chart.yAxis[2].update({
                            height: '20%',
                            top: '35%'
                          }) 
                          chart.yAxis[3].update({
                            height: '20%', 
                            top: '55%'
                          }) 
                          chart.yAxis[4].update({
                            height: '20%', 
                            top: '75%'
                          })  
                        } 
                        if(y_axis_indicator_length == 4){
                          chart.yAxis[0].update({
                            top: '0%',
                            height: '20%'
                          })
                          chart.yAxis[1].update({ 
                            height: '0%',
                            top: '0%'
                          }) 
                          chart.yAxis[2].update({
                            height: '20%',
                            top: '20%'
                          }) 
                          chart.yAxis[3].update({
                            height: '20%', 
                            top: '44%'
                          }) 
                          chart.yAxis[4].update({
                            height: '20%', 
                            top: '60%'
                          }) 
                          chart.yAxis[5].update({
                            height: '20%', 
                            top: '80%'
                          })  
                        } 
                        if(y_axis_indicator_length == 5){
                          chart.yAxis[0].update({
                            top: '0%',
                            height: '15%'
                          })
                          chart.yAxis[1].update({ 
                            height: '0%',
                            top: '0%'
                          }) 
                          chart.yAxis[2].update({
                            height: '15%',
                            top: '20%'
                          }) 
                          chart.yAxis[3].update({
                            height: '15%', 
                            top: '35%'
                          }) 
                          chart.yAxis[4].update({
                            height: '15%', 
                            top: '50%'
                          }) 
                          chart.yAxis[5].update({
                            height: '15%', 
                            top: '65%'
                          }) 
                          chart.yAxis[6].update({
                            height: '15%', 
                            top: '80%'
                          })  
                        } 
                        //console.log('1111111')
                        //alert('1')
                        //console.log('dasd_333',data.chartData)
                        if(localStorage.getItem("enable_hekashi") != null && data.type == 'heikinashi'){
                          //console.log('d',JSON.parse(data.chartData))
                          chart_chart.addSeries({
                            type: data.type,
                            linkedTo: 'aapl',
                            data:JSON.parse(data.chartData),
                            yAxis: 2+count,
                            lineWidth: data.lineWidth,
                            color:data.color,
                            name:data.name,
                            macdLine: {
                              zones: [{
                                  value: -1,
                                  color: 'yellow'
                              }, {
                                  value: 1,
                                  color: 'yellow'
                              }, {
                                  color: 'yellow'
                              }]
                            },
                            signalLine: {
                                zones: [{
                                    value: -1.5,
                                    color: 'yellow'
                                }, {
                                    color: 'yellow'
                                }]
                            },
                            algorithm: 'MACD'
                          }); 
                        }else if(localStorage.getItem("bearbullseries") != null && data.type == 'beapow'){
                          //console.log('d',JSON.parse(data.chartData))
                          chart_chart.addSeries({
                            // type:'line',
                            // linkedTo:'aapl',
                            // data:[0, 141.154, 140.628, 131.849, 127.754, 126.903, 516.666],
                            type: 'line',
                            linkedTo: 'aapl',
                            data:JSON.parse(data.chartData),
                            yAxis: 2+count,
                            lineWidth: data.lineWidth,
                            color:data.color,
                            name:data.name
                          }); 
                        }else{
                          chart_chart.addSeries({
                            type: data.type,
                            linkedTo: 'aapl',
                            yAxis: 2+count,
                            lineWidth: data.lineWidth,
                            color:data.color,
                            name:data.name,
                            macdLine: {
                              zones: [{
                                  value: -1,
                                  color: 'orange'
                              }, {
                                  value: 1,
                                  color: 'blue'
                              }, {
                                  color: 'orange'
                              }]
                            },
                            signalLine: {
                                zones: [{
                                    value: -1.5,
                                    color: 'blue'
                                }, {
                                    color: 'orange'
                                }]
                            },
                            algorithm: 'MACD'
                          }); 
                        }

                    }else{ 

                        if(localStorage.getItem("smma_indicator") != null && data.type == 'smma'){
                          //alert('1')
                          //console.log('d',JSON.parse(data.chartData))
                          chart_chart.addSeries({
                            // type:'line',
                            // linkedTo:'aapl',
                            // data:[0, 141.154, 140.628, 131.849, 127.754, 126.903, 516.666],
                            type: 'line',
                            linkedTo: 'aapl',
                            data:JSON.parse(data.chartData),
                            //yAxis: 2+count,
                            lineWidth: data.lineWidth,
                            color:data.color,
                            name:data.name
                          }); 
                        }else{ 
                            chart_chart.addSeries({
                              type: data.type,
                              linkedTo: 'aapl', 
                              //data:data.data,
                              lineWidth: data.lineWidth,
                              color:data.color, 
                              marker: {
                                enabled: false
                              }
                              // dataLabels: {
                              //   enabled: true,
                              //   x: -18,
                              //   y: 50,
                              //   formatter: function () {
                              //       if (this.x < 2050) return null;
                              //       return this.series.name;
                              //   }
                              // }, 
                            }); 
                        }
                        // chart_chart.addSeries({
                        //   name:'custom',
                        //   type: 'line',
                        //   linkedTo: 'aapl',
                        //   data: [110,120,130,140],
                        //   lineWidth: data.lineWidth,
                        //   color:data.color
                        // }); 
                    }
                   });   
                }  
                //console.log('chart_chart.series',chart_chart.series)
            }
            // function displayTemplate(){
            //     setTimeout(function (chart_update){ 
            //       var seriesData = JSON.parse(localStorage.getItem('ma_template'))
            //       if(seriesData !== null){
            //         if(seriesData?.length>0){ 
            //           seriesData.forEach(function(data,index){
            //             if(index>0){
            //               chart_update.addSeries(data) 
            //             }
            //           });
            //         }
            //       }
            //     },1000,chart);

            //     setTimeout(function (chart_update){ 
            //       var seriesData = JSON.parse(localStorage.getItem('bill_template'))
            //       if(seriesData !== null){
            //         if(seriesData?.length>0){ 
            //           seriesData.forEach(function(data,index){
            //             if(index>0){
            //               chart_update.addSeries(data) 
            //             }
            //           });
            //         }
            //       }
            //     },1000,chart);

            //     setTimeout(function (chart_update){ 
            //       var seriesData = JSON.parse(localStorage.getItem('displaced_template'))
            //       console.log('displaced_template')
            //       console.log(seriesData)
            //       if(seriesData !== null){
            //         if(seriesData?.length>0){
            //           seriesData.forEach(function(data,index){
            //             if(index>0){
            //               chart_update.addSeries(data) 
            //             }
            //           });
            //         }
            //       }
            //     },1000,chart);
            // }

            setTimeout(() => {  
              maintainIndicator(chart);
              //displayTemplate(chart);
            }, 1000);
            $('.show_hide_volume_button').hide()
            $('.custom_indicator_div').show()
          });

          $("#template_display_div").on("click",function(e){ 
            if(chart.series?.length){
              var seriesLength = chart.series?.length;  
              for(var i = seriesLength-1; i > 1; i--){
                chart.series[i].remove();
              }
            }  
             
            function displayTemplate(){
                setTimeout(function (chart_update){ 
                  var seriesData = JSON.parse(localStorage.getItem('ma_template'))
                  if(seriesData !== null){
                    if(seriesData?.length>0){ 
                      seriesData.forEach(function(data,index){
                        if(index>0){
                          chart_update.addSeries(data) 
                        }
                      });
                    }
                  }
                },1000,chart);

                setTimeout(function (chart_update){ 
                  var seriesData = JSON.parse(localStorage.getItem('bill_template'))
                  if(seriesData !== null){
                    if(seriesData?.length>0){ 
                      seriesData.forEach(function(data,index){
                        if(index>0){
                          chart_update.addSeries(data) 
                        }
                      });
                    }
                  }
                },1000,chart);

                setTimeout(function (chart_update){ 
                  var seriesData = JSON.parse(localStorage.getItem('displaced_template')) 
                  if(seriesData !== null){
                    if(seriesData?.length>0){ 
                      seriesData.forEach(function(data,index){
                        if(index>0){
                          chart_update.addSeries(data) 
                        }
                      });
                    }
                  }
                },1000,chart);

                setTimeout(function (chart_update){ 
                  var seriesData = JSON.parse(localStorage.getItem('oscillater_template')) 
                  if(seriesData !== null){
                    if(seriesData?.length>0){  
                      chart_update.yAxis[0].update({
                        top: '0%',
                        height: '20%'
                      })
                      chart_update.yAxis[1].update({ 
                        height: '20%',
                        top: '20%'
                      }) 
                      chart_update.yAxis[2].update({
                        height: '13%',
                        top: '44%',
                        // gridLineDashStyle: 'longdash',
                        // gridLineColor: 'black',
                        // gridLineWidth: 0.5,
                        // alternateGridColor: '#FDFFD5'
                      }) 
                      chart_update.yAxis[3].update({
                        height: '13%', 
                        top: '64%',
                        // gridLineDashStyle: 'longdash',
                        // gridLineColor: 'black',
                        // gridLineWidth: 0.5,
                        // alternateGridColor: '#ebf0e9'
                      }) 
                      chart_update.yAxis[4].update({
                        height: '13%', 
                        top: '80%',
                        // gridLineDashStyle: 'longdash',
                        // gridLineColor: 'black',
                        // gridLineWidth: 0.5,
                        // alternateGridColor: '#e0eced'
                      }) 
                      seriesData.forEach(function(data,index){
                        if(index>0){  
                          chart_update.addSeries(data) 
                        }
                      });
                      $('.oscillater_template_div').show()
                    }
                  }
                },1000,chart);

                setTimeout(function (chart_update){ 
                  var seriesData = JSON.parse(localStorage.getItem('swing_template')) 
                  if(seriesData !== null){
                    if(seriesData?.length>0){ 
                      seriesData.forEach(function(data,index){
                        if(index>0){
                          chart_update.addSeries(data) 
                        }
                      });
                    }
                  }
                },1000,chart);

                setTimeout(function (chart_update){ 
                  var seriesData = JSON.parse(localStorage.getItem('volume_template')) 
                  if(seriesData !== null){
                    if(seriesData?.length>0){ 
                      chart_update.yAxis[0].update({
                        top: '0%',
                        height: '20%'
                      })
                      chart_update.yAxis[1].update({ 
                        height: '20%',
                        top: '15%'
                      }) 
                      chart_update.yAxis[2].update({
                        height: '10%',
                        top: '40%',
                        // gridLineDashStyle: 'longdash',
                        // gridLineColor: 'black',
                        // gridLineWidth: 0.5,
                        // alternateGridColor: '#FDFFD5' 
                      }) 
                      chart_update.yAxis[3].update({
                        height: '10%', 
                        top: '55%',
                        // gridLineDashStyle: 'longdash',
                        // gridLineColor: 'black',
                        // gridLineWidth: 0.5,
                        // alternateGridColor: '#ebf0e9'
                      }) 
                      chart_update.yAxis[4].update({
                        height: '10%', 
                        top: '70%',
                        // gridLineDashStyle: 'longdash',
                        // gridLineColor: 'black',
                        // gridLineWidth: 0.5,
                        // alternateGridColor: '#e0eced'
                      })
                      chart_update.yAxis[5].update({
                        height: '10%', 
                        top: '85%',
                        // gridLineDashStyle: 'longdash',
                        // gridLineColor: 'black',
                        // gridLineWidth: 0.5,
                        // alternateGridColor: '#f3ecf4'
                      }) 
                      seriesData.forEach(function(data,index){
                        if(index>0){
                          chart_update.addSeries(data) 
                        }
                      });
                      $('.volume_template_div').show()
                    }
                  }
                },1000,chart);

                setTimeout(function (chart_update){ 
                  var seriesData = JSON.parse(localStorage.getItem('osma_template')) 
                  if(seriesData !== null){
 

                    var seriesDataLength = JSON.parse(localStorage.getItem('indicator'))?.length
                    var indicatorSeriesData = (JSON.parse(localStorage.getItem('osma_template')))?JSON.parse(localStorage.getItem('osma_template')):2  
                    
                    var count = seriesDataLength;  
                    
                    chart_update.yAxis[0].update({
                      top: '0%',
                      height: '35%'
                    })
                    chart_update.yAxis[1].update({ 
                      height: '15%',
                      top: '45%'
                    }) 
                    chart_update.yAxis[2].update({
                      height: '25%',
                      top: '70%'
                    }) 
                    chart_update.yAxis[3].update({
                      height: '25%', 
                      top: '65%'
                    }) 
                    chart_update.yAxis[4].update({
                      height: '20%', 
                      top: '70%'
                    })  
                      console.log('seriesData',seriesData)
                    if(seriesData?.length>0){ 
                      seriesData.forEach(function(data,index){
                        if(index>0){
                          chart_update.addSeries({
                            type: data.type,
                            linkedTo: 'aapl',
                            yAxis: 1+index,
                            lineWidth: data.lineWidth,
                            color:data.color,
                            zIndex: index+2, 
                            macdLine: {
                              zones: [{
                                  value: -1,
                                  color: 'blue'
                              }, {
                                  value: 1,
                                  color: 'blue'
                              }, {
                                  color: 'blue'
                              }]
                            },
                            signalLine: {
                                zones: [{
                                    value: -1.5,
                                    color: 'blue'
                                }, {
                                    color: 'blue'
                                }]
                            },
                            algorithm: 'MACD'
                          }) 
                          
                        }
                      });
                    }
                  }
                },1000,chart);

                setTimeout(function (chart_update){ 
                  var seriesData = JSON.parse(localStorage.getItem('aligator_template'))
                  if(seriesData !== null){
                    if(seriesData?.length>0){ 
                      seriesData.forEach(function(data,index){
                        if(index>0){
                          chart_update.addSeries(data) 
                        }
                      });
                    }
                  }
                },1000,chart);

            }

            setTimeout(() => {   
              displayTemplate(chart);
            }, 1000); 

            $('.show_hide_volume_button').hide()
          });

        }
      },
      // rangeSelector: {
      //   selected: 1,
      // },
      //backgroundColor: this.state.backgroundColor,
    }});
    Highcharts.setOptions(Highcharts.theme);
    //console.log('JSON.parse(localStorage.getItem())',typeof JSON.parse(localStorage.getItem('indicator')))
    //console.log('sdasda', JSON.parse(localStorage.getItem('indicator')) )
    //console.log('sdasda12',JSON.parse(localStorage.getItem('indicator'))?.length )
    //console.log('neel',JSON.parse(localStorage.getItem('default_template')))
    // Highcharts.getJSON('https://demo-live-data.highcharts.com/aapl-ohlc.json', function (data) {

    //   // create the chart
      // Highcharts.stockChart('container', {
      //     rangeSelector: {
      //         selected: 1
      //     },
  
      //     title: {
      //         text: 'AAPL Stock Price'
      //     },
  
      //     series: [{
      //         type: 'candlestick',
      //         name: 'AAPL Stock Price',
      //         data: data,
      //     } ]
      // });
    //});
     
    // delete options2.chart;
    // delete options2.exporting;
     //delete options2.stockTools;
     //delete options2.plotOptions;
    // delete options2.rangeSelector;
    // delete options2.stockTools;
    // delete options2.title;
    // delete options2.tooltip;
    // delete options2.xAxis; 
    // delete options2.yAxis1; 
    
    console.log('option2',typeof this.state.all_indicator_data)
    //alert('11')
    //$('.show_hide_volume_button').css({"right":"-57px"})
    // $('.show_hide_volume_button').on('mouseover',function(){
    //   console.log('hover')
    //   $('.show_hide_volume_button').animate({"right":"-10px"}, "slow")
    // });
    // $('.show_hide_volume_button').on('mouseleave',function(){
    //   console.log('out')
    //   $('.show_hide_volume_button').css({"right":"-157px"}, "slow") 
    // });



 
    
    return (
      <div> 
          <button type="button" class="btn btn-primary d-none" id="showModalCrossHair" data-toggle="modal" data-target="#exampleModalCrossHairModal">
              Launch demo modal
          </button>
          <div class="modal fade" id="exampleModalCrossHairModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <ul class="custom_bosy">
                   {/*<li>
                      <span>
                         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28" fill="none" stroke="currentColor">
                            <path stroke-linecap="round" d="M15.5 18.5h6m-3 3v-6"></path>
                            <rect width="6" height="6" rx="1.5" x="6.5" y="6.5"></rect>
                            <rect width="6" height="6" rx="1.5" x="15.5" y="6.5"></rect>
                            <rect width="6" height="6" rx="1.5" x="6.5" y="15.5"></rect>
                         </svg>
                      </span>
    </li>
                   <li class="blanku"></li>*/}
                 <li class="blanku custom_bng5">
                      <select className="size_change" onChange={this.handleCrossWidthChange}>
                         <option value="2">2 </option>
                         <option value="3">3 </option>
                         <option value="4">4 </option>
                         <option value="5">5 </option>
                         <option value="6">6 </option>
                         <option value="7">7 </option>
                         <option value="8">8  </option>
                         <option value="9">9 </option>
                         <option value="10">10 </option>
                      </select>
                   </li>
                {/*    <li class="blanku"></li> */}
                <li class="delete_icon">

                        <div style={ stylesCrossHair.swatch } onClick={ this.handleCrossHairClick }>
                           <div style={ stylesCrossHair.color } />
                        </div>

                        { this.state.displayCrossHairColorPicker ? 
                           <div style={ stylesCrossHair.popover }>
                              <div style={ stylesCrossHair.cover } onClick={ this.handleCrossHairClose }/>
                              <SketchPicker color={ this.state.color } onChange={ this.handleCrossHairColorChange } />
                            </div>
                        : null }
                    </li>
                   
                   <li onClick={ this.handleCrossDelete } ><span class="icon-9pA37sIi"><i class="fa fa-trash" aria-hidden="true"></i></span></li>
                </ul>
      </div>
    {/*   <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div> */}
    </div>
  </div>
</div>
        
        <div className="mb-4 mt-4"> 
        <div className="tooltip_custom showCursor main_indicator_view show_hide_volume_button d-none1">
          <span class="remove_volume" id="volume_style" onClick={this.props.handler}><i class="fa-solid fa-eye"></i> </span> 
          {/* <span class="indicator_view bg-danger removebull" >Bear Bull</span>  */}
        </div> 

        <div className={"tooltip_custom showCursor main_indicator_view " + (JSON.parse(localStorage.getItem('indicator')) === null || JSON.parse(localStorage.getItem('indicator'))?.length === 0 ? 'hidden_indicator_display_div' : 'show_indicator_display_div')} id="indicator_display_div">
          <span class="indicator_view bg-danger" id="clear_all_indicator" onClick={this.props.handler}>Clear all indicator </span>
          <span class="indicator_view bg-success show_indicator_div indicator_div_text d-none">Click to apply {(JSON.parse(localStorage.getItem('indicator')))?JSON.parse(localStorage.getItem('indicator'))?.length:0} Indicator</span>
          {/* {localStorage.getItem('indicator')} */}
        </div>

        <div className={"tooltip_custom showCursor main_indicator_view " + (JSON.parse(localStorage.getItem('default_template')) === null || JSON.parse(localStorage.getItem('default_template'))?.length === 0 ? 'hidden_indicator_display_div' : 'show_indicator_display_div')} id="template_display_div">
          <span class="indicator_view bg-danger d-none" id="clear_all_template" onClick={this.props.handler} >Clear all template</span>
          <span class="indicator_view bg-success show_template_div template_div_text d-none">Click to apply {(JSON.parse(localStorage.getItem('default_template')))?JSON.parse(localStorage.getItem('default_template'))?.length:0} default template</span>
        </div>
        {/* {localStorage.getItem('indicator')}
        {this.state.is_indicator_delete} */}
          {localStorage.getItem('indicator') !== null && JSON.parse(localStorage.getItem('indicator')).map((indicator,i) =>( 
            <span className="custom_indicator_div" style={{display:'none'}}>
              <div class="tooltip_custom" onClick={this.showIndicatorSetting.bind(this)} id={`indicator_tool_tip_${i}`}>
                <span class="indicator_view showCursor" style={{backgroundColor:indicator.color}}>{(indicator.yAxis)?'OSC:':'OL:'} {indicator.name.toUpperCase()}</span>
                <div class="tooltiptext" style={{backgroundColor:indicator.color}}> 
                  {indicator.name.toUpperCase()} : <i class="fa-solid fa-code indicator_icon" onClick={()=>this.showCode(indicator.indicatorCode)}></i>  <i class="fa-solid fa-trash indicator_icon remove_indicator" id={`${i}|${indicator.yAxis}`} onClick={this.removeIndicator.bind(this)}></i>  <i class="fa-solid fa-eye indicator_icon show_hide_indicator" id={`${i}|${indicator.yAxis}|${indicator.type}`} ></i>
                  <i class="fa-solid fa-gear indicator_icon show_hide_indicator_setting" onClick={()=>this.AddIndicatorName(indicator.type,indicator.color)}></i>
                </div>
              </div> 
            </span> 
          ))}  

          { localStorage.getItem('ma_template') != null && JSON.parse(localStorage.getItem('ma_template')).slice(0, 1).map((template,i) =>( 
            <span className="ma_template_div" style={{display:'none'}}>
            <div class="tooltip_custom" onClick={this.showIndicatorSetting.bind(this)} id={`template_tool_tip_${i}`}>
              <span class="indicator_view showCursor">{template.indicator_name.toUpperCase()}</span>
              <div class="tooltiptextTemplate"> 
                {template.indicator_name} : <i class="fa-solid fa-code indicator_icon" onClick={()=>this.showCode(template.indicatorCode)}></i>  <i class="fa-solid fa-trash indicator_icon remove_template" id={`${i}|ma_template`}></i>
              </div>
            </div>  
            </span>
          ))} 

          { localStorage.getItem('bill_template') != null && JSON.parse(localStorage.getItem('bill_template')).slice(0, 1).map((template,i) =>( 
             <span className="bill_template_div" style={{display:'none'}} >
              <div class="tooltip_custom" onClick={this.showIndicatorSetting.bind(this)} id={`template_tool_tip_${i}`}>
                <span class="indicator_view showCursor">{template.indicator_name.toUpperCase()}</span>
                <div class="tooltiptextTemplate"> 
                  {template.indicator_name} : <i class="fa-solid fa-code indicator_icon" onClick={()=>this.showCode(template.indicatorCode)}></i>  <i class="fa-solid fa-trash indicator_icon remove_template" id={`${i}|bill_template`}></i>
                </div>
              </div>  
            </span>
          ))} 

          { localStorage.getItem('aligator_template') != null && JSON.parse(localStorage.getItem('aligator_template')).slice(0, 1).map((template,i) =>( 
             <span className="aligator_template_div" style={{display:'none'}} >
              <div class="tooltip_custom" onClick={this.showIndicatorSetting.bind(this)} id={`template_tool_tip_${i}`}>
                <span class="indicator_view showCursor">{template.indicator_name.toUpperCase()}</span>
                <div class="tooltiptextTemplate"> 
                  {template.indicator_name} : <i class="fa-solid fa-code indicator_icon" onClick={()=>this.showCode(template.indicatorCode)}></i>  <i class="fa-solid fa-trash indicator_icon remove_template" id={`${i}|aligator_template`}></i>
                </div>
              </div>  
            </span>
          ))} 

          { localStorage.getItem('displaced_template') != null && JSON.parse(localStorage.getItem('displaced_template')).slice(0, 1).map((template,i) =>( 
            <span className="displaced_template_div" style={{display:'none'}}>
              <div class="tooltip_custom" onClick={this.showIndicatorSetting.bind(this)} id={`template_tool_tip_${i}`}>
                <span class="indicator_view showCursor">{template.indicator_name.toUpperCase()}</span>
                <div class="tooltiptextTemplate"> 
                  {template.indicator_name} : <i class="fa-solid fa-code indicator_icon" onClick={()=>this.showCode(template.indicatorCode)}></i>  <i class="fa-solid fa-trash indicator_icon remove_template" id={`${i}|displaced_template`} ></i>
                </div>
              </div>  
            </span>
          ))} 

          { localStorage.getItem('oscillater_template') != null && JSON.parse(localStorage.getItem('oscillater_template')).slice(0, 1).map((template,i) =>( 
            <span className="oscillater_template_div" style={{display:'none'}}>
            <div class="tooltip_custom" onClick={this.showIndicatorSetting.bind(this)} id={`template_tool_tip_${i}`}>
              <span class="indicator_view showCursor">{template.indicator_name.toUpperCase()}</span>
              <div class="tooltiptextTemplate"> 
                {template.indicator_name} : <i class="fa-solid fa-code indicator_icon" onClick={()=>this.showCode(template.indicatorCode)}></i>  <i class="fa-solid fa-trash indicator_icon remove_template" id={`${i}|oscillater_template`}></i>
              </div>
            </div>  
            </span>
          ))} 

          { localStorage.getItem('swing_template') !== null && JSON.parse(localStorage.getItem('swing_template')).slice(0, 1).map((template,i) =>( 
            <span className="swing_template_div" style={{display:'none'}}>
            <div class="tooltip_custom" onClick={this.showIndicatorSetting.bind(this)} id={`template_tool_tip_${i}`}>
              <span class="indicator_view showCursor">{template.indicator_name.toUpperCase()}</span>
              <div class="tooltiptextTemplate"> 
                {template.indicator_name} : <i class="fa-solid fa-code indicator_icon" onClick={()=>this.showCode(template.indicatorCode)}></i>  <i class="fa-solid fa-trash indicator_icon remove_template" id={`${i}|swing_template`} ></i>
              </div>
            </div>  
            </span>
          ))} 

          { localStorage.getItem('volume_template') !== null && JSON.parse(localStorage.getItem('volume_template')).slice(0, 1).map((template,i) =>( 
            <span className="volume_template_div" style={{display:'none'}}>
            <div class="tooltip_custom" onClick={this.showIndicatorSetting.bind(this)} id={`template_tool_tip_${i}`}>
              <span class="indicator_view showCursor">{template.indicator_name.toUpperCase()}</span>
              <div class="tooltiptextTemplate"> 
                {template.indicator_name} : <i class="fa-solid fa-code indicator_icon" onClick={()=>this.showCode(template.indicatorCode)}></i>  <i class="fa-solid fa-trash indicator_icon remove_template" id={`${i}|volume_template`} ></i>  
            
              </div>
            </div>  
            </span>
          ))}

          { localStorage.getItem('osma_template') !== null && JSON.parse(localStorage.getItem('osma_template')).slice(0, 1).map((template,i) =>( 
            <span className="osma_template_div" style={{display:'none'}}>
            <div class="tooltip_custom" onClick={this.showIndicatorSetting.bind(this)} id={`template_tool_tip_${i}`}>
              <span class="indicator_view showCursor">{template.indicator_name.toUpperCase()}</span>
              <div class="tooltiptextTemplate"> 
                {template.indicator_name} : <i class="fa-solid fa-code indicator_icon" onClick={()=>this.showCode(template.indicatorCode)}></i>  <i class="fa-solid fa-trash indicator_icon remove_template" id={`${i}|osma_template`} ></i>  
            
              </div>
            </div>  
            </span>
          ))} 

        </div> 
 
        {/* <div class="annotation-container">
          <button class="dropdown-item highcharts-arrow-segment">Line</button>
          <button class="dropdown-item highcharts-rectangle-annotation">Rectangle</button>
          <button class="dropdown-item highcharts-circle-annotation"> Circle</button>
          <button class="dropdown-item highcharts-inputText-annotation">Text</button>
        </div> */}
        <input type="hidden" id="dateFormat" value={goldmine} />
        <input type="hidden" id="alphaMine" value={alphaMine} /> 
        {this.state.stockLoad ? (
          <div id="container">
            <span className="display_indicator_name"></span>
          <HighchartsReact
            highcharts={Highcharts}
            constructorType={"stockChart"}
            options={options2}  
            allowChartUpdate = { true }
            immutable = { false }
            updateArgs = { [true, true, true] } 
          />
          </div>
        ) : (
          stockError
        )}

      <div class="python_code_editor" style={{ display:'none'}}>  
        <pre><code id="python_code"></code></pre>
      </div>

      <div class="modal" id="indicator_setting" >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body"> 
              <div className="col-md-12 border border-2 p-2 ml-1 indicator_setting_div" > 
                  <h3 className="text-center">{this.state.indicator_setting_name.toUpperCase()}</h3>
                  {/* <div className="form-group">
                    <label for="exampleInputEmail1">Index : </label>
                    <input type="test" className="form-control input_height" placeholder="index" value="1"/> 
                  </div>
                  <div className="form-group">
                    <label for="exampleInputPassword1">Period : </label>
                    <input type="text" className="form-control input_height" placeholder="period" value="20"/>
                  </div>  */}
                  <div className="form-group">
                    <label for="exampleInputPassword1">Line width : </label>
                    <input type="text" name="lineWidth" className="form-control input_height" placeholder="0.6" value={this.state.line_width} id={`${this.state.indicator_setting_name}|width`} onChange={(e) => this.addParamInIndicator(e)}/>
                  </div> 
                  <div className="form-group">
                    <label for="exampleInputPassword1">Line color : </label>
                    <input type="color" className="form-control input_height" id={`${this.state.indicator_setting_name}|color`} onChange={(e) => this.addParamInIndicator(e)} value={(this.state.indicator_line_color)?this.state.indicator_line_color:'#FF0000'}/>
                  </div>   
                  <button type="button" class="btn btn-dark btn-lg btn-block hide_indicator_setting_modal" id="indicator_display_div_modal" data-dismiss="modal" aria-label="Close">Add</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal" id="exampleModal" >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body">
            <input type="hidden"  id="exmModel" />
                <ul class="custom_bosy">
                    {/* <li>
                      <span>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28" fill="none" stroke="currentColor">
                          <path stroke-linecap="round" d="M15.5 18.5h6m-3 3v-6"></path>
                          <rect width="6" height="6" rx="1.5" x="6.5" y="6.5"></rect>
                          <rect width="6" height="6" rx="1.5" x="15.5" y="6.5"></rect>
                          <rect width="6" height="6" rx="1.5" x="6.5" y="15.5"></rect>
                          </svg>
                      </span>
                    </li>
                    <li class="blanku"></li> */}
                    <li>
                        <select className="size_change">
                          <option value="10">10</option>
                          <option value="14">14</option>
                          <option value="18">18</option>
                          <option value="20" selected>20</option>
                          <option value="24">24</option>
                          <option value="28">28</option>
                          <option value="30">30</option>
                          <option value="32">32</option>
                          <option value="34">34</option>
                          <option value="36">36</option>
                          <option value="40">40</option>
                        </select>
                    </li>
                  
                    <li class="blanku custom_bng5">
                        <div style={ styles.swatch } onClick={ this.handleClick }>
                          {/* <div id="color_modal" style={{width:'36px',height:'14px',borderRadius:'2px',backgroundColor:"#000"}} /> */}
                        </div>
                        <input type="text" style={{display:'none'}} placeholder="Enter text" class="icon_text_change"  />
                        <input type="color" class="icon_color_change"  onChange={this.handleChange} />
                        { this.state.displayColorPicker ? <div style={ styles.popover }>
                          <div style={ styles.cover } onClick={ this.handleClose }/>
                          <SketchPicker color={ this.state.color } onChange={ this.handleChange } />
                        </div> : null }</li>
                    {/* { this.state.lock ? 
                              <li onClick={ this.handleUnlock } ><span class="icon-9pA37sIi"><i class="lockk fa-solid fa-lock"></i></span></li>
                            :
                              <li onClick={ this.handleLock } ><span class="icon-9pA37sIi"><i class="unlock fa fa-unlock" aria-hidden="true"></i></span></li>
                            } */}

                              <li className="delete_icon" ><span class="icon-9pA37sIi"><i class="fa fa-trash" aria-hidden="true"></i></span></li>
                          </ul>
            </div>
          </div>
        </div>
      </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  stockValue: state.cardItems,
});

const mapDispatchToProps = (dispatch) => ({
  getStockValue: (symbol, stock, time, mult, startd, endd) =>
    dispatch(getStockValue(symbol, stock, time, mult, startd, endd)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CandleChart);