import React from 'react';
import { Navigate, Route,Outlet } from "react-router-dom";
import Login from '../components/common/login/Login.component';



const isUserLoggedin=()=>{
    let accessToken=localStorage.getItem('accessToken')
    if(accessToken !== undefined && accessToken !== null && accessToken !== ''){
       return true
    }else{
       return false
    }
}
const  PrivateRoute = () =>{
    const isAuth=isUserLoggedin();
    return isAuth ? <Outlet /> : <Navigate to={"/login"} />
}

//<Redirect to={{pathname:"/login", state:{from: props.location}  }}/>
export default PrivateRoute;