import React, { Component } from "react";
import Footer from "../layout/footer/Footer.component";
import Header from "../layout/header/Header.component";
import './Ebook.component.css'
import Book from '../../../assests/book.jpg';
import Book1 from '../../../assests/book1.jpg';
import { Link } from "react-router-dom";
import axios from "axios";
import $ from 'jquery';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
let imgPath="../../../assests/";



export default class EbookPage extends Component {

  constructor(props) {

    super(props);

    this.state = {
        ebooksList:[],
        title:'',
        cover_photo: null,
        books: null,
        author_name:'',
        book_type:'',
        selectedFile: null
    };

     this.handleChange = this.handleChange.bind(this);
     this.handleSubmit = this.handleSubmit.bind(this);
     //this.onImageChange  = this.onImageChange.bind(this);
     this.oneBookHandler = this.oneBookHandler.bind(this);
  }

  componentDidMount() {

      let accessToekn = localStorage.getItem('accessToken');
      var authData = 'Token '+accessToekn;

       //setTimeout(function() {
          axios
          .get(`https://stock-trading-backend.itechnolabs.tech/book/`,{headers: {
               "Content-Type": "application/json",
               Authorization: authData
            }})
          .then((response) => {

            //alert(JSON.stringify(response.data.results));

            this.setState({
              ebooksList : response.data.data
            })
             
          })
          .catch((error) => {
              console.log("error", error);
          });
      //}.bind(this), 1000);


  }


  //  onImageChange = (event) => {
  //   const data = new FormData();
  //   data.append('file', event.target.files[0]);
  //   data.append('name', 'some value user types');
  //   data.append('description', 'some value user types');

  //   alert(JSON.stringify(data));
  //   // // '/files' is your node.js route that triggers our middleware
  //   // axios.post('/files', data).then((response) => {
  //   //   console.log(response); // do something with the response
  //   // });

  // }

  // onImageChange  = (e) => {

  //   let files = e.target.files[0];
  //     this.setState({ 'cover_photo': files })

  //   //alert('event.target.files[0]' + JSON.stringify(e.target.files));
  //   // alert('hi' + JSON.stringify(event.target.files));
  //   //   if (event.target.files && event.target.files[0]) {
  //   //       let img = event.target.files[0];
  //   //       this.setState({
  //   //           cover_photo: event.target.files[0]
  //   //       });

  //   //       alert(JSON.stringify(this.state.cover_photo));
  //   //   }
  // };

oneBookHandler=event=>{
   //  this.setState({ 
   //    books : event.target.files[0] 
   //  })
   // alert('ficl'+ event.target.files[0]);

   this.setState({ books: event.target.files[0]  }, () => { console.log(this.state.books) });
    console.log(this.state.books);

}


onChangeHandler=event=>{ 
  if(event.target.files[0]['type'] != 'image/jpeg' && event.target.files[0]['type'] != 'image/png'){
    alert("Image formate for cover photo is not correct.");
    $('#cover_image').val(''); 
  }else{
    this.setState({ cover_photo: event.target.files[0]  }, () => { console.log(this.state.cover_photo) });
    console.log(this.state.cover_photo);
  }

}

  handleChange = event => {

      const target = event.target;
      const value = target.value;
      const name = target.name;

      this.setState({ 
         [name]: value
      });

     // alert(this.state.title);
  }

  handleSubmit = event => {


    //alert(JSON.stringify(this.state.cover_photo));

    const formData = new FormData();

    formData.append('File', this.state.cover_photo);

      event.preventDefault();

      let accessToekn = localStorage.getItem('accessToken');
      var authData = 'Token '+accessToekn;
      // const user = {
      //     title: this.state.title,
      //     author_name:this.state.author_name,
      //     book_type: this.state.book_type,
      //     cover_photo:this.state.cover_photo,
      //     books: this.state.books
      // }


      //const formData = new FormData();
    //formData.append('file', file);
    formData.append('cover_photo', this.state.cover_photo);
    formData.append('books', this.state.books);
    formData.append('author_name', this.state.author_name);
    formData.append('book_type', this.state.book_type);
    formData.append('title', this.state.title);
    
      axios.post('https://stock-trading-backend.itechnolabs.tech/book/', formData,
              {
                headers: {
                   Authorization: authData,
                    'content-type': 'multipart/form-data'
              }})
      .then(res=>{
              toast.success('Ebook Saved');
              document.getElementById("ebook_form").reset();
      });
       
  }

    render() {
         const listItems = this.state.ebooksList.map((item, index) => (
            <section  key={index} class="outlook_section most_polupar">
            <div class="custom_container">
            <div class="row">
            <div class="col-md-8 offset-md-2 col-sm-12 second_div text-center">
            <h3 class="title_main package_title">Published by {item.author_name}</h3>
            </div>
            </div>
            <div class="row">
            <div class="col-md-12">
            <div class="swiper mySwiperb">
            <div class="swiper-wrapper">
            <div class="swiper-slide">
            <div class="advance_analytic">
            <img src={item.cover_photo} />
            <p><a target="_blank" href={item.books} >{item.title}</a></p>
            </div>
            </div>
            <div class="swiper-slide">
            <div class="advance_analytic">
            <img src="/static/media/book1.cec84b3f.jpg" /><p>{item.title}</p></div>
            <ul class="outlook_ul">
            <li><i class="fal fa-heart" aria-hidden="true"></i>(15)</li>
            <li><i class="fal fa-arrow-to-bottom" aria-hidden="true"></i>(15)</li>
            <li><i class="fal fa-eye" aria-hidden="true"></i>(100)</li>
            </ul>
            </div>
            <div class="swiper-slide">
            <div class="advance_analytic">
            <img src="/static/media/book.3235d384.jpg" /><p>Lorem ipsum</p>
            </div><ul class="outlook_ul">
            <li><i class="fal fa-heart" aria-hidden="true"></i>(15)</li>
            <li><i class="fal fa-arrow-to-bottom" aria-hidden="true"></i>(15)</li>
            <li><i class="fal fa-eye" aria-hidden="true"></i>(100)</li>
            </ul>
            </div>
            <div class="swiper-slide">
            <div class="advance_analytic">
            <img src="/static/media/book1.cec84b3f.jpg" /><p>Lorem ipsum</p></div>
            <ul class="outlook_ul">
            <li><i class="fal fa-heart" aria-hidden="true"></i>(15)</li>
            <li><i class="fal fa-arrow-to-bottom" aria-hidden="true"></i>(15)</li>
            <li><i class="fal fa-eye" aria-hidden="true"></i>(100)</li>
            </ul></div><div class="swiper-slide">
            <div class="advance_analytic"><img src="/static/media/book.3235d384.jpg" /><p>Lorem ipsum</p></div>
            <ul class="outlook_ul"><li><i class="fal fa-heart" aria-hidden="true"></i>(15)</li>
            <li><i class="fal fa-arrow-to-bottom" aria-hidden="true"></i>(15)</li>
            <li><i class="fal fa-eye" aria-hidden="true"></i>(100)</li>
            </ul>
            </div>
            </div>
            <div class="swiper-button-next"></div><div class="swiper-button-prev"></div></div></div></div></div></section>
        )) 
        return (
            <div>
          <header>
            <div className="custom_container">
              <div className="row">
                <div className="col-md-12">
                  <Header />
                </div>
              </div>
            </div>
          </header>

          <section className="banner_outer news_banner">
            <div className="overlay"></div>
                <div className="custom_container">
                <div className="row" >
                    <div className="col-md-8 offset-md-2 col-sm-12 first_div text-center">
                      <div class="banner_content">
                        <h2>E-Books</h2>
                         <hr class="banner_hr news_hr" /><h3>
                         <ul className="news_ul">
                           <li><Link to={"/home"} >Home</Link></li>
                           <li><i class="fa fa-angle-right"></i></li>
                           <li><Link to={"/ebook"} className="active" >E-Books</Link></li>
                         </ul>
                        </h3>
                      </div>
                    </div>
                </div>
            </div>
          </section>
          <section>
            <div className="custom_container">
                <div className="row" >
                <div className="col-md-8 offset-md-2 col-sm-12 second_div text-center">
                <h3 className="title_main">
                Book <b>Published</b>
                </h3>
                <p className="p_text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius, diam id pharetra egestas, 
                felis neque vulputate quam, bibendum iaculis mauris justo id tellus. Nulla consequat metus ac quam eleifend, nec dignissim justo egestas. </p>
                <button type="button" className="btn btn-dark signup_btn" data-toggle="modal" data-target="#ebookModal">Upload Book</button></div>
                </div>
            </div>
          </section>
          {listItems}
  <div className="modal custom_modal fade" id="ebookModal" tabindex="-1" aria-labelledby="ebookModalLabel" aria-hidden="true">
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="ebookModalLabel">Book Publish</h5>
          <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close"></button>
        </div>
        <div className="modal-body">

        <form  onSubmit = { this.handleSubmit }  id="ebook_form">
          <div className="row">
            <div className="col-md-12">
               <label className="custom_label">Title</label>
               <input type="text" className="custom_input" placeholder="Book Title" name = "title" onChange= {this.handleChange} required />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="longitude">
                <label className="custom_label">Cover Photo</label>
                <span>Optional</span>
              </div>
               <div className="password_input upload_d">

                 <input className="custom_input" type="file" name="file" onChange={this.onChangeHandler} id="cover_image" />
               
                  <i class="fa fa-paperclip"></i>         
                </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="longitude">
                <label className="custom_label">Upload Book</label>
              </div>
               <div className="password_input upload_d">
                  <input className="custom_input" type="file" accept="application/pdf" placeholder="Upload any PDF or Word file" onChange={this.oneBookHandler} required/>
                  <i class="fa fa-paperclip"></i>         
                </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
               <label className="custom_label">Author Name</label>
               <input type="text" className="custom_input" placeholder="Author Name"  name = "author_name" onChange= {this.handleChange} />
            </div>
          </div>
          
          <div className="row">
            <div className="col-md-12"> 
                <label className="custom_label">Select Type</label>
                <div className="radio_custom">
                <label className="radio_container">Forex
                  <input type="radio" id="radioForex" name="radio" value="investor" />
                  <span className="checkmark stock_type"></span>
                </label>
                <label className="radio_container">Stock Market
                  <input type="radio" name="radio" id="radioStock" value="trader" />
                  <span className="checkmark stock_type"></span>
                </label>
                <label className="radio_container">Cryptocurrency
                  <input type="radio" name="radio" id="radioCryptocurrency" value="technical" />
                  <span className="checkmark stock_type"></span>
                </label>
                </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8 offset-md-2 col-sm-12">
          
            <button  className="signin_btn"  type = "submit"> Add </button>
            </div>
          </div>

          </form>
        </div>
      </div>
    </div>
  </div>
          <footer>
            <Footer />       
          </footer>
            </div>
        )
    }
}
