import React, { Component } from "react";
import Footer from "../layout/footer/Footer.component";
import Header from "../layout/header/Header.component";
import './Expertadvisor.component.css'
import Book from '../../../assests/book.jpg';
import Meta from '../../../assests/meta.png';
import Book1 from '../../../assests/book1.jpg';
import Video from '../../../assests/video.png';
import Cart from '../../../assests/cart.png';
import { Link } from "react-router-dom";
import axios from "axios";
import constants from "../../../constants/constants";
import Slider from 'react-rangeslider';
import authService from "../../../services/auth.service";
let imgPath="../../../assests/"
export default class ExpertPage extends Component {

  constructor(props) {

    

    super(props);

      this.state={
        list_experts:[],
        search_keyword:"",
        min_price:"",
        max_price:"",
        list_mt4:[],
        list_mt5:[],
        category:"",
        module_type:"",
        user:{}

      };
  }
componentDidMount(){
  this.getExperts();
  let id = localStorage.getItem("userId");
    let trial = localStorage.getItem("userTrial");
    let accessToken = localStorage.getItem("accessToken");
    this.setState({ accessToken: accessToken });
    this.getUserInfo(id, accessToken);
  
}
getUserInfo(id, accessToken) {
  authService
    .getUser(accessToken, id)
    .then((response) => {
      this.setState({user:response.data});
    
    })
    .catch((error) => {
     
    });
}
setSideFilter(category,module_type)
{
  this.setState({
    category:category,
    module_type:module_type
  })
  this.getExperts();
}
  getExperts()
  {
    var param="";

    if(this.state.min_price!="")
    {
        param+="?min_price="+this.state.min_price;
    }
    else
    {
      param+="?min_price=0";

    }


    if(this.state.search_keyword!="")
    {
        param+="&title="+this.state.search_keyword;
    }


    if(this.state.max_price!="")
    {
        param+="&max_price="+this.state.max_price;
    }

    if(this.state.category!="")
    {
        param+="&category="+this.state.category;
    }


    if(this.state.module_type!="")
    {
        param+="&module_type="+this.state.module_type;
    }


   

    let accessToekn = localStorage.getItem('accessToken');
    var authData = 'Token ' + accessToekn;
    axios.get(constants.API_URL+"/advisor/"+param,{
      headers:{
        "Content-Type": "application/json",
        Authorization: authData
      }
    })
    .then((response)=>{
      if(response.status==200 )
      {
        this.setState({list_experts:response.data.data});
        var list_mt4=[];
        var list_mt5=[];
        for(var i=0;i<response.data.data.length;i++)
        {
          if(response.data.data[i].category==1)
          list_mt4.push(response.data.data[i]);
          else
          list_mt5.push(response.data.data[i]);

         

        }
        this.setState({list_mt5:list_mt5});
        this.setState({list_mt4:list_mt4});


        console.log(this.state.list_mt5);
      }

    }).catch((error)=>{
      console.log("error", error);
    });
  }
  handelInput(e){
    this.setState({
        [e.name]:e.value
    })

    this.getExperts();
}
deleteExpert(eaid){
  if(!window.confirm("Are you sure to delete this ?"))
  {
    return;
  }
  let accessToekn = localStorage.getItem('accessToken');
    var authData = 'Token ' + accessToekn;
    axios.delete(constants.API_URL+"/advisor/"+eaid+"/",{
      headers:{
        "Content-Type": "application/json",
        Authorization: authData
      }
    })
    .then((response)=>{
      this.getExperts();
    }).catch((error)=>{
      console.log("error", error);
    });
}
    render() {
        return (
            <div>
          <header>
            <div className="custom_container">
              <div className="row">
                <div className="col-md-12">
                  <Header />
                </div>
              </div>
            </div>
          </header>

          <section className="banner_outer news_banner">
            <div className="overlay"></div>
                <div className="custom_container">
                <div className="row" >
                    <div className="col-md-8 offset-md-2 col-sm-12 first_div text-center">
                      <div class="banner_content">
                        <h2>Expert Advisor</h2>
                         <hr class="banner_hr news_hr" /><h3>
                         <ul className="news_ul">
                           <li><Link to={"/home"} >Home</Link></li>
                           <li><i class="fal fa-angle-right"></i></li>
                           <li><Link to={"/expert-advisor"} className="active">Expert Advisor</Link></li>
                         </ul>
                        </h3>
                      </div>
                    </div>
                </div>
            </div>
          </section>
          <section className="expert_ad_outer">
            <div className="custom_container advisor_container">
                <div className="row" >
                    <div className="col-md-3 three_col">
                      <div className="advisor_left">
                         <div className="meta_title"><img src={Meta} /> MetaTrader 5</div>
                         <ul className="expert_ul">
                         <li onClick={()=>this.setSideFilter(2,2)}><span>-</span>Experts</li>
                         <li onClick={()=>this.setSideFilter(1,2)}><span>-</span>Indicators</li>
                         </ul>
                         <div className="meta_title"><img src={Meta} /> MetaTrader 4</div>
                         <ul className="expert_ul">
                         <li onClick={()=>this.setSideFilter(2,1)}><span>-</span>Experts</li>
                         <li onClick={()=>this.setSideFilter(1,1)}><span>-</span>Indicators</li>
                         </ul>
                      </div>
                      <hr className="expert_hr" />
                      <div className="advisor_left">
                         <div className="meta_title">Price</div>
                         {/* <div className="range-slide">
                            <div className="slide">
                              <div className="line" id="line"></div>
                              <span className="thumb" id="thumbMin"></span>
                              <span className="thumb" id="thumbMax"></span>
                            </div>
                            <input id="rangeMin" type="range" max="100" min="10" step="5" value="0" />
                            <input id="rangeMax" type="range" max="100" min="10" step="5" value="100" />
                          </div>

                          <p className="usd_text">$0 – 800 +</p> */}
                          <div className="min_max_outer">
                            <div className="min_left">
                              <label className="min_label">min</label>
                              <div className="min_input">
                                 <span className="dollar"> &#36;</span>
                                <input type="number" name="min_price" value={this.state.min_price} placeholder="0" onChange={(e)=>this.handelInput(e.target)} />
                              </div>
                            </div>
                            <span>to</span>
                            <div className="min_left">
                              <label className="min_label">max</label>
                              <div className="min_input">
                             <span className="dollar"> &#36;</span>
                                <input type="number" name="max_price" value={this.state.max_price} placeholder="90"  onChange={(e)=>this.handelInput(e.target)} />
                              </div>
                            </div>
							
                          </div>
                          </div> 
                      <hr className="expert_hr" />
					  <div className="advisor_left1">
		<div className="meta_title">Type</div>
		<div className="select_ou6">
						<select>
						<option>Scalper Expert Advisor</option>
						</select>
						</div>
						</div>
					
                    
                    </div>
                    <div className="col-md-9 nine_col">
                        <div className="advisor_right">
                          <div className="expert_search">
                            <input type="text" name="search_keyword"  className="Search_input" value={this.state.search_keyword} onChange={(e)=>this.handelInput(e.target)} placeholder="Search Market" />
                            <button type="button" class="btn btn-dark signup_btn" onClick={()=>this.getExperts()}>Search</button>
                            {
                              this.state.user.user_type=="freelancer"?(
                                <>
                                 <button type="button" className="btn btn_add_expert signup_btn" onClick={()=>{document.location.href="expert-advisor-input?mod=2"}}>Add Expert </button>
                            <button type="button" className="btn btn_add_indicator signup_btn" onClick={()=>{document.location.href="expert-advisor-input?mod=1"}}>Add Indicator</button>
                                </>
                              ):""
                            }
                           
                          </div>
                          <div className="popular_expert_title">Popular Expert</div>
                          <div className="row">
                            {
                              this.state.list_experts.map((obj,i)=>(
                                <div className="col-md-3" >
                                      <div className="advance_analytic" onClick={()=>{document.location.href="expert-advisor-result/"+obj.id}}>
                                        <img src={obj.image} />
                                        <p>{obj.title} </p>
                                      </div>
                                        <div className="rate_d" id="full-stars-example">
                                        <div class="rating-group">
                                          { obj.advisor_ratings.map((obj2,i2)=>(
                                            <>
                                            <label aria-label="1 star" class="rating__label" for="rating-1"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                                                  <input class="rating__input" name="rating" id="rating-1" value="1" type="radio" />
                                            </>
                                          ))}
                                         </div>
                                        <p class="desc">$ {obj.price}</p>
                                      </div>
                                      {
                                        obj.user.id==localStorage.getItem("userId")?(
                                          <>
                                          <input type="button"  onClick={()=>this.deleteExpert(obj.id)}  class="btn btn-primary delete_outer" value="Delete Post"></input>
                                          <input type="button" onClick={()=>{document.location.href="expert-advisor-input/?mod="+obj.category+"&eaid="+obj.id}} class="btn btn-primary edit_outer" value="Edit Post"></input>
                                          <small style={{color:"red"}}>{obj.status==0?"Approoval Pending from admin":""}</small>
                                          </>
                                        ):""
                                      }
                                     
                                </div>
                              ))
                            }
                          
                            
                          </div>

                        
                        </div>
                    </div>
                </div>
            </div>
          </section>
          
      
          <footer>
            <Footer />       
          </footer>
            </div>
        )
    }
}
