import React,{Component} from "react";
import { Link } from "react-router-dom";
import Header from "../layout/header/Header.component";
import io from 'socket.io-client';



export default class HostStream extends Component  {
 

  constructor(props)
  {
    super(props);
    this.state={
      isVideoPlaying:false
    };

    this.stopVideo = this.stopVideo.bind(this);
    this.captureVideo = this.captureVideo.bind(this);
    this.captureScreen = this.captureScreen.bind(this);
  }

  stopVideo(){
    this.setState({
      isVideoPlaying:true
    });
  }
    captureVideo(){
      const peerConnections = {};
      const config = {
        iceServers: [
          { 
            "urls": "stun:stun.l.google.com:19302",
          },
          // { 
          //   "urls": "turn:TURN_IP?transport=tcp",
          //   "username": "TURN_USERNAME",
          //   "credential": "TURN_CREDENTIALS"
          // }
        ]
      };
      
      const socket = io.connect('http://localhost:9009');
      
      socket.on("answer", (id, description) => {
        peerConnections[id].setRemoteDescription(description);
      });
      
      socket.on("watcher", id => {
        const peerConnection = new RTCPeerConnection(config);
        peerConnections[id] = peerConnection;
      
        let stream = videoElement.srcObject;
        stream.getTracks().forEach(track => peerConnection.addTrack(track, stream));
      
        peerConnection.onicecandidate = event => {
          if (event.candidate) {
            socket.emit("candidate", id, event.candidate);
          }
        };
      
        peerConnection
          .createOffer()
          .then(sdp => peerConnection.setLocalDescription(sdp))
          .then(() => {
            socket.emit("offer", id, peerConnection.localDescription);
          });
      });
      
      socket.on("candidate", (id, candidate) => {
        peerConnections[id].addIceCandidate(new RTCIceCandidate(candidate));
      });
      
      socket.on("disconnectPeer", id => {
        // peerConnections[id].close();
        delete peerConnections[id];
      });
      
      window.onunload = window.onbeforeunload = () => {
        socket.close();
      };
      
      // Get camera and microphone
      const videoElement = document.querySelector("video");
      const audioSelect = document.querySelector("select#audioSource");
      const videoSelect = document.querySelector("select#videoSource");
      
      audioSelect.onchange = getStream;
      videoSelect.onchange = getStream;
      
      getStream()
        .then(getDevices)
        .then(gotDevices);
      
      function getDevices() {
        return navigator.mediaDevices.enumerateDevices();
      }
      
      function gotDevices(deviceInfos) {
        window.deviceInfos = deviceInfos;
        for (const deviceInfo of deviceInfos) {
          const option = document.createElement("option");
          option.value = deviceInfo.deviceId;
          if (deviceInfo.kind === "audioinput") {
            option.text = deviceInfo.label || `Microphone ${audioSelect.length + 1}`;
            audioSelect.appendChild(option);
          } else if (deviceInfo.kind === "videoinput") {
            option.text = deviceInfo.label || `Camera ${videoSelect.length + 1}`;
            videoSelect.appendChild(option);
          }
        }
      }
      
      function getStream() {
        if (window.stream) {
          window.stream.getTracks().forEach(track => {
            track.stop();
          });
        }
        // audio: { deviceId: audioSource ? { exact: audioSource } : undefined },
        const audioSource = audioSelect.value;
        const videoSource = videoSelect.value;
        const constraints = {
         
          video: { deviceId: videoSource ? { exact: videoSource } : undefined }
        };


        var displaymediastreamconstraints = {
          video: {
              displaySurface: 'monitor', // monitor, window, application, browser
              logicalSurface: true,
              cursor: 'always' // never, always, motion
          }
      };
  
      // above constraints are NOT supported YET
      // that's why overriding them
      displaymediastreamconstraints = {
          video: true
      };



        return navigator.mediaDevices
          .getDisplayMedia(displaymediastreamconstraints)
          .then(gotStream)
          .catch(handleError);
      }
      
      function gotStream(stream) {
        window.stream = stream;
        videoElement.srcObject = stream;
        socket.emit("broadcaster");
      }
      
      function handleError(error) {
        console.error("Error: ", error);
      }
    }
     captureScreen(){
      const peerConnections = {};
      const config = {
        iceServers: [
          { 
            "urls": "stun:stun.l.google.com:19302",
          },
          // { 
          //   "urls": "turn:TURN_IP?transport=tcp",
          //   "username": "TURN_USERNAME",
          //   "credential": "TURN_CREDENTIALS"
          // }
        ]
      };
      
      const socket = io.connect('http://localhost:9009');
      
      socket.on("answer", (id, description) => {
        peerConnections[id].setRemoteDescription(description);
      });
      
      socket.on("watcher", id => {
        const peerConnection = new RTCPeerConnection(config);
        peerConnections[id] = peerConnection;
      
        let stream = videoElement.srcObject;
        stream.getTracks().forEach(track => peerConnection.addTrack(track, stream));
      
        peerConnection.onicecandidate = event => {
          if (event.candidate) {
            socket.emit("candidate", id, event.candidate);
          }
        };
      
        peerConnection
          .createOffer()
          .then(sdp => peerConnection.setLocalDescription(sdp))
          .then(() => {
            socket.emit("offer", id, peerConnection.localDescription);
          });
      });
      
      socket.on("candidate", (id, candidate) => {
        peerConnections[id].addIceCandidate(new RTCIceCandidate(candidate));
      });
      
      socket.on("disconnectPeer", id => {
        // peerConnections[id].close();
        delete peerConnections[id];
      });
      
      window.onunload = window.onbeforeunload = () => {
        socket.close();
      };
      
      // Get camera and microphone
      const videoElement = document.querySelector("video");
      const audioSelect = document.querySelector("select#audioSource");
      const videoSelect = document.querySelector("select#videoSource");
      
      audioSelect.onchange = getStream;
      videoSelect.onchange = getStream;
      
      getStream()
        .then(getDevices)
        .then(gotDevices);
      
      function getDevices() {
        return navigator.mediaDevices.enumerateDevices();
      }
      
      function gotDevices(deviceInfos) {
        window.deviceInfos = deviceInfos;
        for (const deviceInfo of deviceInfos) {
          const option = document.createElement("option");
          option.value = deviceInfo.deviceId;
          if (deviceInfo.kind === "audioinput") {
            option.text = deviceInfo.label || `Microphone ${audioSelect.length + 1}`;
            audioSelect.appendChild(option);
          } else if (deviceInfo.kind === "videoinput") {
            option.text = deviceInfo.label || `Camera ${videoSelect.length + 1}`;
            videoSelect.appendChild(option);
          }
        }
      }
      
      function getStream() {
        if (window.stream) {
          window.stream.getTracks().forEach(track => {
            track.stop();
          });
        }
        // audio: { deviceId: audioSource ? { exact: audioSource } : undefined },
        const audioSource = audioSelect.value;
        const videoSource = videoSelect.value;
        const constraints = {
         
          video: { deviceId: videoSource ? { exact: videoSource } : undefined }
        };


        var displaymediastreamconstraints = {
          video: {
              displaySurface: 'monitor', // monitor, window, application, browser
              logicalSurface: true,
              cursor: 'always' // never, always, motion
          }
      };
  
      // above constraints are NOT supported YET
      // that's why overriding them
      displaymediastreamconstraints = {
          video: true
      };



        return navigator.mediaDevices
          .getDisplayMedia(displaymediastreamconstraints)
          .then(gotStream)
          .catch(handleError);
      }
      
      function gotStream(stream) {
        window.stream = stream;
        videoElement.srcObject = stream;
        socket.emit("broadcaster");
      }
      
      function handleError(error) {
        console.error("Error: ", error);
      }
    }

 


        render(){ 
           return (<>
            <header>
            <div className="custom_container">
                  <div className="row">
                     <div className="col-md-12">
                        <Header />
                     </div>
                  </div>
               </div>
               </header>
               <section className="banner_outer news_banner">
                  <div className="overlay"></div>
                  <div className="custom_container">
                     <div className="row" >
                        <div className="col-md-8 offset-md-2 col-sm-12 first_div text-center">
                           <div class="banner_content">
                              <h2>Community</h2>
                              <hr class="banner_hr news_hr" />
                              <h3>
                                 <ul className="news_ul">
                                    <li>
                                       <Link to={"/home"} >
                                       Home</Link>
                                    </li>
                                    <li><i class="fal fa-angle-right"></i></li>
                                    <li>
                                       <Link to={"/host-stream"} className="active">
                                       Community</Link>
                                    </li>
                                 </ul>
                              </h3>
                           </div>
                        </div>
                     </div>
                  </div>
               </section>
               <section>
                  <div className="custom_container">
                     <div className="row" >
                        <div className="col-md-8 offset-md-2 col-sm-12 second_div text-center">
                           <p className="p_text newS_p">The markets never sleep. As the sun sets in New York, 
                            Connecty with using the Live chat and the video support. 
                           </p>
                        </div>
                     </div>
                  </div>
               </section>
               <section>
                                  <div className="custom_container">
                     <div className="row">
                        <div className="col-md-12 offset-md-12 col-sm-12 second_div text-left chatBox" >
                           
                            {/* Live Video player Start  */}
                           {this.isVideoPlaying?(   <button onClick={this.stopVideo}>Stop Video</button>):(   <button onClick={this.captureVideo}>Start Video</button>)}
                        
                           <div style={{display:'none'}} >
                           <section class="select">
                           <label for="audioSource">Audio source: </label>
                           <select id="audioSource"></select>
                        </section>

                        <section class="select">
                           <label for="videoSource">Video source: </label>
                           <select id="videoSource"></select>
                        </section>
                           </div>
                          

                        <video playsInline autoPlay ></video>

           {/* Live Video player  End */}

                            
                            

                        </div>
                     </div>
                  </div>
               </section>
               </>
           )
        }
}

